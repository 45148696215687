@charset "UTF-8";
/*
Template Name: Steex - Admin & Dashboard Template
Author: Themesbrand
Version: 1.1.0
Website: https://Themesbrand.com/
Contact: Themesbrand@gmail.com
File: Icons Css File
*/
/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=6.5.95");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=6.5.95") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=6.5.95") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=6.5.95") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=6.5.95") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-ab-testing::before {
  content: "\f01c9";
}

.mdi-abacus::before {
  content: "\f16e0";
}

.mdi-abjad-arabic::before {
  content: "\f1328";
}

.mdi-abjad-hebrew::before {
  content: "\f1329";
}

.mdi-abugida-devanagari::before {
  content: "\f132a";
}

.mdi-abugida-thai::before {
  content: "\f132b";
}

.mdi-access-point::before {
  content: "\f0003";
}

.mdi-access-point-check::before {
  content: "\f1538";
}

.mdi-access-point-minus::before {
  content: "\f1539";
}

.mdi-access-point-network::before {
  content: "\f0002";
}

.mdi-access-point-network-off::before {
  content: "\f0be1";
}

.mdi-access-point-off::before {
  content: "\f1511";
}

.mdi-access-point-plus::before {
  content: "\f153a";
}

.mdi-access-point-remove::before {
  content: "\f153b";
}

.mdi-account::before {
  content: "\f0004";
}

.mdi-account-alert::before {
  content: "\f0005";
}

.mdi-account-alert-outline::before {
  content: "\f0b50";
}

.mdi-account-arrow-down::before {
  content: "\f1868";
}

.mdi-account-arrow-down-outline::before {
  content: "\f1869";
}

.mdi-account-arrow-left::before {
  content: "\f0b51";
}

.mdi-account-arrow-left-outline::before {
  content: "\f0b52";
}

.mdi-account-arrow-right::before {
  content: "\f0b53";
}

.mdi-account-arrow-right-outline::before {
  content: "\f0b54";
}

.mdi-account-arrow-up::before {
  content: "\f1867";
}

.mdi-account-arrow-up-outline::before {
  content: "\f186a";
}

.mdi-account-box::before {
  content: "\f0006";
}

.mdi-account-box-multiple::before {
  content: "\f0934";
}

.mdi-account-box-multiple-outline::before {
  content: "\f100a";
}

.mdi-account-box-outline::before {
  content: "\f0007";
}

.mdi-account-cancel::before {
  content: "\f12df";
}

.mdi-account-cancel-outline::before {
  content: "\f12e0";
}

.mdi-account-cash::before {
  content: "\f1097";
}

.mdi-account-cash-outline::before {
  content: "\f1098";
}

.mdi-account-check::before {
  content: "\f0008";
}

.mdi-account-check-outline::before {
  content: "\f0be2";
}

.mdi-account-child::before {
  content: "\f0a89";
}

.mdi-account-child-circle::before {
  content: "\f0a8a";
}

.mdi-account-child-outline::before {
  content: "\f10c8";
}

.mdi-account-circle::before {
  content: "\f0009";
}

.mdi-account-circle-outline::before {
  content: "\f0b55";
}

.mdi-account-clock::before {
  content: "\f0b56";
}

.mdi-account-clock-outline::before {
  content: "\f0b57";
}

.mdi-account-cog::before {
  content: "\f1370";
}

.mdi-account-cog-outline::before {
  content: "\f1371";
}

.mdi-account-convert::before {
  content: "\f000a";
}

.mdi-account-convert-outline::before {
  content: "\f1301";
}

.mdi-account-cowboy-hat::before {
  content: "\f0e9b";
}

.mdi-account-cowboy-hat-outline::before {
  content: "\f17f3";
}

.mdi-account-details::before {
  content: "\f0631";
}

.mdi-account-details-outline::before {
  content: "\f1372";
}

.mdi-account-edit::before {
  content: "\f06bc";
}

.mdi-account-edit-outline::before {
  content: "\f0ffb";
}

.mdi-account-eye::before {
  content: "\f0420";
}

.mdi-account-eye-outline::before {
  content: "\f127b";
}

.mdi-account-filter::before {
  content: "\f0936";
}

.mdi-account-filter-outline::before {
  content: "\f0f9d";
}

.mdi-account-group::before {
  content: "\f0849";
}

.mdi-account-group-outline::before {
  content: "\f0b58";
}

.mdi-account-hard-hat::before {
  content: "\f05b5";
}

.mdi-account-heart::before {
  content: "\f0899";
}

.mdi-account-heart-outline::before {
  content: "\f0be3";
}

.mdi-account-injury::before {
  content: "\f1815";
}

.mdi-account-injury-outline::before {
  content: "\f1816";
}

.mdi-account-key::before {
  content: "\f000b";
}

.mdi-account-key-outline::before {
  content: "\f0be4";
}

.mdi-account-lock::before {
  content: "\f115e";
}

.mdi-account-lock-open::before {
  content: "\f1960";
}

.mdi-account-lock-open-outline::before {
  content: "\f1961";
}

.mdi-account-lock-outline::before {
  content: "\f115f";
}

.mdi-account-minus::before {
  content: "\f000d";
}

.mdi-account-minus-outline::before {
  content: "\f0aec";
}

.mdi-account-multiple::before {
  content: "\f000e";
}

.mdi-account-multiple-check::before {
  content: "\f08c5";
}

.mdi-account-multiple-check-outline::before {
  content: "\f11fe";
}

.mdi-account-multiple-minus::before {
  content: "\f05d3";
}

.mdi-account-multiple-minus-outline::before {
  content: "\f0be5";
}

.mdi-account-multiple-outline::before {
  content: "\f000f";
}

.mdi-account-multiple-plus::before {
  content: "\f0010";
}

.mdi-account-multiple-plus-outline::before {
  content: "\f0800";
}

.mdi-account-multiple-remove::before {
  content: "\f120a";
}

.mdi-account-multiple-remove-outline::before {
  content: "\f120b";
}

.mdi-account-music::before {
  content: "\f0803";
}

.mdi-account-music-outline::before {
  content: "\f0ce9";
}

.mdi-account-network::before {
  content: "\f0011";
}

.mdi-account-network-outline::before {
  content: "\f0be6";
}

.mdi-account-off::before {
  content: "\f0012";
}

.mdi-account-off-outline::before {
  content: "\f0be7";
}

.mdi-account-outline::before {
  content: "\f0013";
}

.mdi-account-plus::before {
  content: "\f0014";
}

.mdi-account-plus-outline::before {
  content: "\f0801";
}

.mdi-account-question::before {
  content: "\f0b59";
}

.mdi-account-question-outline::before {
  content: "\f0b5a";
}

.mdi-account-reactivate::before {
  content: "\f152b";
}

.mdi-account-reactivate-outline::before {
  content: "\f152c";
}

.mdi-account-remove::before {
  content: "\f0015";
}

.mdi-account-remove-outline::before {
  content: "\f0aed";
}

.mdi-account-search::before {
  content: "\f0016";
}

.mdi-account-search-outline::before {
  content: "\f0935";
}

.mdi-account-settings::before {
  content: "\f0630";
}

.mdi-account-settings-outline::before {
  content: "\f10c9";
}

.mdi-account-star::before {
  content: "\f0017";
}

.mdi-account-star-outline::before {
  content: "\f0be8";
}

.mdi-account-supervisor::before {
  content: "\f0a8b";
}

.mdi-account-supervisor-circle::before {
  content: "\f0a8c";
}

.mdi-account-supervisor-circle-outline::before {
  content: "\f14ec";
}

.mdi-account-supervisor-outline::before {
  content: "\f112d";
}

.mdi-account-switch::before {
  content: "\f0019";
}

.mdi-account-switch-outline::before {
  content: "\f04cb";
}

.mdi-account-sync::before {
  content: "\f191b";
}

.mdi-account-sync-outline::before {
  content: "\f191c";
}

.mdi-account-tie::before {
  content: "\f0ce3";
}

.mdi-account-tie-hat::before {
  content: "\f1898";
}

.mdi-account-tie-hat-outline::before {
  content: "\f1899";
}

.mdi-account-tie-outline::before {
  content: "\f10ca";
}

.mdi-account-tie-voice::before {
  content: "\f1308";
}

.mdi-account-tie-voice-off::before {
  content: "\f130a";
}

.mdi-account-tie-voice-off-outline::before {
  content: "\f130b";
}

.mdi-account-tie-voice-outline::before {
  content: "\f1309";
}

.mdi-account-voice::before {
  content: "\f05cb";
}

.mdi-account-voice-off::before {
  content: "\f0ed4";
}

.mdi-account-wrench::before {
  content: "\f189a";
}

.mdi-account-wrench-outline::before {
  content: "\f189b";
}

.mdi-adjust::before {
  content: "\f001a";
}

.mdi-advertisements::before {
  content: "\f192a";
}

.mdi-advertisements-off::before {
  content: "\f192b";
}

.mdi-air-conditioner::before {
  content: "\f001b";
}

.mdi-air-filter::before {
  content: "\f0d43";
}

.mdi-air-horn::before {
  content: "\f0dac";
}

.mdi-air-humidifier::before {
  content: "\f1099";
}

.mdi-air-humidifier-off::before {
  content: "\f1466";
}

.mdi-air-purifier::before {
  content: "\f0d44";
}

.mdi-airbag::before {
  content: "\f0be9";
}

.mdi-airballoon::before {
  content: "\f001c";
}

.mdi-airballoon-outline::before {
  content: "\f100b";
}

.mdi-airplane::before {
  content: "\f001d";
}

.mdi-airplane-alert::before {
  content: "\f187a";
}

.mdi-airplane-check::before {
  content: "\f187b";
}

.mdi-airplane-clock::before {
  content: "\f187c";
}

.mdi-airplane-cog::before {
  content: "\f187d";
}

.mdi-airplane-edit::before {
  content: "\f187e";
}

.mdi-airplane-landing::before {
  content: "\f05d4";
}

.mdi-airplane-marker::before {
  content: "\f187f";
}

.mdi-airplane-minus::before {
  content: "\f1880";
}

.mdi-airplane-off::before {
  content: "\f001e";
}

.mdi-airplane-plus::before {
  content: "\f1881";
}

.mdi-airplane-remove::before {
  content: "\f1882";
}

.mdi-airplane-search::before {
  content: "\f1883";
}

.mdi-airplane-settings::before {
  content: "\f1884";
}

.mdi-airplane-takeoff::before {
  content: "\f05d5";
}

.mdi-airport::before {
  content: "\f084b";
}

.mdi-alarm::before {
  content: "\f0020";
}

.mdi-alarm-bell::before {
  content: "\f078e";
}

.mdi-alarm-check::before {
  content: "\f0021";
}

.mdi-alarm-light::before {
  content: "\f078f";
}

.mdi-alarm-light-off::before {
  content: "\f171e";
}

.mdi-alarm-light-off-outline::before {
  content: "\f171f";
}

.mdi-alarm-light-outline::before {
  content: "\f0bea";
}

.mdi-alarm-multiple::before {
  content: "\f0022";
}

.mdi-alarm-note::before {
  content: "\f0e71";
}

.mdi-alarm-note-off::before {
  content: "\f0e72";
}

.mdi-alarm-off::before {
  content: "\f0023";
}

.mdi-alarm-panel::before {
  content: "\f15c4";
}

.mdi-alarm-panel-outline::before {
  content: "\f15c5";
}

.mdi-alarm-plus::before {
  content: "\f0024";
}

.mdi-alarm-snooze::before {
  content: "\f068e";
}

.mdi-album::before {
  content: "\f0025";
}

.mdi-alert::before {
  content: "\f0026";
}

.mdi-alert-box::before {
  content: "\f0027";
}

.mdi-alert-box-outline::before {
  content: "\f0ce4";
}

.mdi-alert-circle::before {
  content: "\f0028";
}

.mdi-alert-circle-check::before {
  content: "\f11ed";
}

.mdi-alert-circle-check-outline::before {
  content: "\f11ee";
}

.mdi-alert-circle-outline::before {
  content: "\f05d6";
}

.mdi-alert-decagram::before {
  content: "\f06bd";
}

.mdi-alert-decagram-outline::before {
  content: "\f0ce5";
}

.mdi-alert-minus::before {
  content: "\f14bb";
}

.mdi-alert-minus-outline::before {
  content: "\f14be";
}

.mdi-alert-octagon::before {
  content: "\f0029";
}

.mdi-alert-octagon-outline::before {
  content: "\f0ce6";
}

.mdi-alert-octagram::before {
  content: "\f0767";
}

.mdi-alert-octagram-outline::before {
  content: "\f0ce7";
}

.mdi-alert-outline::before {
  content: "\f002a";
}

.mdi-alert-plus::before {
  content: "\f14ba";
}

.mdi-alert-plus-outline::before {
  content: "\f14bd";
}

.mdi-alert-remove::before {
  content: "\f14bc";
}

.mdi-alert-remove-outline::before {
  content: "\f14bf";
}

.mdi-alert-rhombus::before {
  content: "\f11ce";
}

.mdi-alert-rhombus-outline::before {
  content: "\f11cf";
}

.mdi-alien::before {
  content: "\f089a";
}

.mdi-alien-outline::before {
  content: "\f10cb";
}

.mdi-align-horizontal-center::before {
  content: "\f11c3";
}

.mdi-align-horizontal-distribute::before {
  content: "\f1962";
}

.mdi-align-horizontal-left::before {
  content: "\f11c2";
}

.mdi-align-horizontal-right::before {
  content: "\f11c4";
}

.mdi-align-vertical-bottom::before {
  content: "\f11c5";
}

.mdi-align-vertical-center::before {
  content: "\f11c6";
}

.mdi-align-vertical-distribute::before {
  content: "\f1963";
}

.mdi-align-vertical-top::before {
  content: "\f11c7";
}

.mdi-all-inclusive::before {
  content: "\f06be";
}

.mdi-all-inclusive-box::before {
  content: "\f188d";
}

.mdi-all-inclusive-box-outline::before {
  content: "\f188e";
}

.mdi-allergy::before {
  content: "\f1258";
}

.mdi-alpha::before {
  content: "\f002b";
}

.mdi-alpha-a::before {
  content: "\f0aee";
}

.mdi-alpha-a-box::before {
  content: "\f0b08";
}

.mdi-alpha-a-box-outline::before {
  content: "\f0beb";
}

.mdi-alpha-a-circle::before {
  content: "\f0bec";
}

.mdi-alpha-a-circle-outline::before {
  content: "\f0bed";
}

.mdi-alpha-b::before {
  content: "\f0aef";
}

.mdi-alpha-b-box::before {
  content: "\f0b09";
}

.mdi-alpha-b-box-outline::before {
  content: "\f0bee";
}

.mdi-alpha-b-circle::before {
  content: "\f0bef";
}

.mdi-alpha-b-circle-outline::before {
  content: "\f0bf0";
}

.mdi-alpha-c::before {
  content: "\f0af0";
}

.mdi-alpha-c-box::before {
  content: "\f0b0a";
}

.mdi-alpha-c-box-outline::before {
  content: "\f0bf1";
}

.mdi-alpha-c-circle::before {
  content: "\f0bf2";
}

.mdi-alpha-c-circle-outline::before {
  content: "\f0bf3";
}

.mdi-alpha-d::before {
  content: "\f0af1";
}

.mdi-alpha-d-box::before {
  content: "\f0b0b";
}

.mdi-alpha-d-box-outline::before {
  content: "\f0bf4";
}

.mdi-alpha-d-circle::before {
  content: "\f0bf5";
}

.mdi-alpha-d-circle-outline::before {
  content: "\f0bf6";
}

.mdi-alpha-e::before {
  content: "\f0af2";
}

.mdi-alpha-e-box::before {
  content: "\f0b0c";
}

.mdi-alpha-e-box-outline::before {
  content: "\f0bf7";
}

.mdi-alpha-e-circle::before {
  content: "\f0bf8";
}

.mdi-alpha-e-circle-outline::before {
  content: "\f0bf9";
}

.mdi-alpha-f::before {
  content: "\f0af3";
}

.mdi-alpha-f-box::before {
  content: "\f0b0d";
}

.mdi-alpha-f-box-outline::before {
  content: "\f0bfa";
}

.mdi-alpha-f-circle::before {
  content: "\f0bfb";
}

.mdi-alpha-f-circle-outline::before {
  content: "\f0bfc";
}

.mdi-alpha-g::before {
  content: "\f0af4";
}

.mdi-alpha-g-box::before {
  content: "\f0b0e";
}

.mdi-alpha-g-box-outline::before {
  content: "\f0bfd";
}

.mdi-alpha-g-circle::before {
  content: "\f0bfe";
}

.mdi-alpha-g-circle-outline::before {
  content: "\f0bff";
}

.mdi-alpha-h::before {
  content: "\f0af5";
}

.mdi-alpha-h-box::before {
  content: "\f0b0f";
}

.mdi-alpha-h-box-outline::before {
  content: "\f0c00";
}

.mdi-alpha-h-circle::before {
  content: "\f0c01";
}

.mdi-alpha-h-circle-outline::before {
  content: "\f0c02";
}

.mdi-alpha-i::before {
  content: "\f0af6";
}

.mdi-alpha-i-box::before {
  content: "\f0b10";
}

.mdi-alpha-i-box-outline::before {
  content: "\f0c03";
}

.mdi-alpha-i-circle::before {
  content: "\f0c04";
}

.mdi-alpha-i-circle-outline::before {
  content: "\f0c05";
}

.mdi-alpha-j::before {
  content: "\f0af7";
}

.mdi-alpha-j-box::before {
  content: "\f0b11";
}

.mdi-alpha-j-box-outline::before {
  content: "\f0c06";
}

.mdi-alpha-j-circle::before {
  content: "\f0c07";
}

.mdi-alpha-j-circle-outline::before {
  content: "\f0c08";
}

.mdi-alpha-k::before {
  content: "\f0af8";
}

.mdi-alpha-k-box::before {
  content: "\f0b12";
}

.mdi-alpha-k-box-outline::before {
  content: "\f0c09";
}

.mdi-alpha-k-circle::before {
  content: "\f0c0a";
}

.mdi-alpha-k-circle-outline::before {
  content: "\f0c0b";
}

.mdi-alpha-l::before {
  content: "\f0af9";
}

.mdi-alpha-l-box::before {
  content: "\f0b13";
}

.mdi-alpha-l-box-outline::before {
  content: "\f0c0c";
}

.mdi-alpha-l-circle::before {
  content: "\f0c0d";
}

.mdi-alpha-l-circle-outline::before {
  content: "\f0c0e";
}

.mdi-alpha-m::before {
  content: "\f0afa";
}

.mdi-alpha-m-box::before {
  content: "\f0b14";
}

.mdi-alpha-m-box-outline::before {
  content: "\f0c0f";
}

.mdi-alpha-m-circle::before {
  content: "\f0c10";
}

.mdi-alpha-m-circle-outline::before {
  content: "\f0c11";
}

.mdi-alpha-n::before {
  content: "\f0afb";
}

.mdi-alpha-n-box::before {
  content: "\f0b15";
}

.mdi-alpha-n-box-outline::before {
  content: "\f0c12";
}

.mdi-alpha-n-circle::before {
  content: "\f0c13";
}

.mdi-alpha-n-circle-outline::before {
  content: "\f0c14";
}

.mdi-alpha-o::before {
  content: "\f0afc";
}

.mdi-alpha-o-box::before {
  content: "\f0b16";
}

.mdi-alpha-o-box-outline::before {
  content: "\f0c15";
}

.mdi-alpha-o-circle::before {
  content: "\f0c16";
}

.mdi-alpha-o-circle-outline::before {
  content: "\f0c17";
}

.mdi-alpha-p::before {
  content: "\f0afd";
}

.mdi-alpha-p-box::before {
  content: "\f0b17";
}

.mdi-alpha-p-box-outline::before {
  content: "\f0c18";
}

.mdi-alpha-p-circle::before {
  content: "\f0c19";
}

.mdi-alpha-p-circle-outline::before {
  content: "\f0c1a";
}

.mdi-alpha-q::before {
  content: "\f0afe";
}

.mdi-alpha-q-box::before {
  content: "\f0b18";
}

.mdi-alpha-q-box-outline::before {
  content: "\f0c1b";
}

.mdi-alpha-q-circle::before {
  content: "\f0c1c";
}

.mdi-alpha-q-circle-outline::before {
  content: "\f0c1d";
}

.mdi-alpha-r::before {
  content: "\f0aff";
}

.mdi-alpha-r-box::before {
  content: "\f0b19";
}

.mdi-alpha-r-box-outline::before {
  content: "\f0c1e";
}

.mdi-alpha-r-circle::before {
  content: "\f0c1f";
}

.mdi-alpha-r-circle-outline::before {
  content: "\f0c20";
}

.mdi-alpha-s::before {
  content: "\f0b00";
}

.mdi-alpha-s-box::before {
  content: "\f0b1a";
}

.mdi-alpha-s-box-outline::before {
  content: "\f0c21";
}

.mdi-alpha-s-circle::before {
  content: "\f0c22";
}

.mdi-alpha-s-circle-outline::before {
  content: "\f0c23";
}

.mdi-alpha-t::before {
  content: "\f0b01";
}

.mdi-alpha-t-box::before {
  content: "\f0b1b";
}

.mdi-alpha-t-box-outline::before {
  content: "\f0c24";
}

.mdi-alpha-t-circle::before {
  content: "\f0c25";
}

.mdi-alpha-t-circle-outline::before {
  content: "\f0c26";
}

.mdi-alpha-u::before {
  content: "\f0b02";
}

.mdi-alpha-u-box::before {
  content: "\f0b1c";
}

.mdi-alpha-u-box-outline::before {
  content: "\f0c27";
}

.mdi-alpha-u-circle::before {
  content: "\f0c28";
}

.mdi-alpha-u-circle-outline::before {
  content: "\f0c29";
}

.mdi-alpha-v::before {
  content: "\f0b03";
}

.mdi-alpha-v-box::before {
  content: "\f0b1d";
}

.mdi-alpha-v-box-outline::before {
  content: "\f0c2a";
}

.mdi-alpha-v-circle::before {
  content: "\f0c2b";
}

.mdi-alpha-v-circle-outline::before {
  content: "\f0c2c";
}

.mdi-alpha-w::before {
  content: "\f0b04";
}

.mdi-alpha-w-box::before {
  content: "\f0b1e";
}

.mdi-alpha-w-box-outline::before {
  content: "\f0c2d";
}

.mdi-alpha-w-circle::before {
  content: "\f0c2e";
}

.mdi-alpha-w-circle-outline::before {
  content: "\f0c2f";
}

.mdi-alpha-x::before {
  content: "\f0b05";
}

.mdi-alpha-x-box::before {
  content: "\f0b1f";
}

.mdi-alpha-x-box-outline::before {
  content: "\f0c30";
}

.mdi-alpha-x-circle::before {
  content: "\f0c31";
}

.mdi-alpha-x-circle-outline::before {
  content: "\f0c32";
}

.mdi-alpha-y::before {
  content: "\f0b06";
}

.mdi-alpha-y-box::before {
  content: "\f0b20";
}

.mdi-alpha-y-box-outline::before {
  content: "\f0c33";
}

.mdi-alpha-y-circle::before {
  content: "\f0c34";
}

.mdi-alpha-y-circle-outline::before {
  content: "\f0c35";
}

.mdi-alpha-z::before {
  content: "\f0b07";
}

.mdi-alpha-z-box::before {
  content: "\f0b21";
}

.mdi-alpha-z-box-outline::before {
  content: "\f0c36";
}

.mdi-alpha-z-circle::before {
  content: "\f0c37";
}

.mdi-alpha-z-circle-outline::before {
  content: "\f0c38";
}

.mdi-alphabet-aurebesh::before {
  content: "\f132c";
}

.mdi-alphabet-cyrillic::before {
  content: "\f132d";
}

.mdi-alphabet-greek::before {
  content: "\f132e";
}

.mdi-alphabet-latin::before {
  content: "\f132f";
}

.mdi-alphabet-piqad::before {
  content: "\f1330";
}

.mdi-alphabet-tengwar::before {
  content: "\f1337";
}

.mdi-alphabetical::before {
  content: "\f002c";
}

.mdi-alphabetical-off::before {
  content: "\f100c";
}

.mdi-alphabetical-variant::before {
  content: "\f100d";
}

.mdi-alphabetical-variant-off::before {
  content: "\f100e";
}

.mdi-altimeter::before {
  content: "\f05d7";
}

.mdi-ambulance::before {
  content: "\f002f";
}

.mdi-ammunition::before {
  content: "\f0ce8";
}

.mdi-ampersand::before {
  content: "\f0a8d";
}

.mdi-amplifier::before {
  content: "\f0030";
}

.mdi-amplifier-off::before {
  content: "\f11b5";
}

.mdi-anchor::before {
  content: "\f0031";
}

.mdi-android::before {
  content: "\f0032";
}

.mdi-android-messages::before {
  content: "\f0d45";
}

.mdi-android-studio::before {
  content: "\f0034";
}

.mdi-angle-acute::before {
  content: "\f0937";
}

.mdi-angle-obtuse::before {
  content: "\f0938";
}

.mdi-angle-right::before {
  content: "\f0939";
}

.mdi-angular::before {
  content: "\f06b2";
}

.mdi-angularjs::before {
  content: "\f06bf";
}

.mdi-animation::before {
  content: "\f05d8";
}

.mdi-animation-outline::before {
  content: "\f0a8f";
}

.mdi-animation-play::before {
  content: "\f093a";
}

.mdi-animation-play-outline::before {
  content: "\f0a90";
}

.mdi-ansible::before {
  content: "\f109a";
}

.mdi-antenna::before {
  content: "\f1119";
}

.mdi-anvil::before {
  content: "\f089b";
}

.mdi-apache-kafka::before {
  content: "\f100f";
}

.mdi-api::before {
  content: "\f109b";
}

.mdi-api-off::before {
  content: "\f1257";
}

.mdi-apple::before {
  content: "\f0035";
}

.mdi-apple-finder::before {
  content: "\f0036";
}

.mdi-apple-icloud::before {
  content: "\f0038";
}

.mdi-apple-ios::before {
  content: "\f0037";
}

.mdi-apple-keyboard-caps::before {
  content: "\f0632";
}

.mdi-apple-keyboard-command::before {
  content: "\f0633";
}

.mdi-apple-keyboard-control::before {
  content: "\f0634";
}

.mdi-apple-keyboard-option::before {
  content: "\f0635";
}

.mdi-apple-keyboard-shift::before {
  content: "\f0636";
}

.mdi-apple-safari::before {
  content: "\f0039";
}

.mdi-application::before {
  content: "\f08c6";
}

.mdi-application-array::before {
  content: "\f10f5";
}

.mdi-application-array-outline::before {
  content: "\f10f6";
}

.mdi-application-braces::before {
  content: "\f10f7";
}

.mdi-application-braces-outline::before {
  content: "\f10f8";
}

.mdi-application-brackets::before {
  content: "\f0c8b";
}

.mdi-application-brackets-outline::before {
  content: "\f0c8c";
}

.mdi-application-cog::before {
  content: "\f0675";
}

.mdi-application-cog-outline::before {
  content: "\f1577";
}

.mdi-application-edit::before {
  content: "\f00ae";
}

.mdi-application-edit-outline::before {
  content: "\f0619";
}

.mdi-application-export::before {
  content: "\f0dad";
}

.mdi-application-import::before {
  content: "\f0dae";
}

.mdi-application-outline::before {
  content: "\f0614";
}

.mdi-application-parentheses::before {
  content: "\f10f9";
}

.mdi-application-parentheses-outline::before {
  content: "\f10fa";
}

.mdi-application-settings::before {
  content: "\f0b60";
}

.mdi-application-settings-outline::before {
  content: "\f1555";
}

.mdi-application-variable::before {
  content: "\f10fb";
}

.mdi-application-variable-outline::before {
  content: "\f10fc";
}

.mdi-approximately-equal::before {
  content: "\f0f9e";
}

.mdi-approximately-equal-box::before {
  content: "\f0f9f";
}

.mdi-apps::before {
  content: "\f003b";
}

.mdi-apps-box::before {
  content: "\f0d46";
}

.mdi-arch::before {
  content: "\f08c7";
}

.mdi-archive::before {
  content: "\f003c";
}

.mdi-archive-alert::before {
  content: "\f14fd";
}

.mdi-archive-alert-outline::before {
  content: "\f14fe";
}

.mdi-archive-arrow-down::before {
  content: "\f1259";
}

.mdi-archive-arrow-down-outline::before {
  content: "\f125a";
}

.mdi-archive-arrow-up::before {
  content: "\f125b";
}

.mdi-archive-arrow-up-outline::before {
  content: "\f125c";
}

.mdi-archive-cancel::before {
  content: "\f174b";
}

.mdi-archive-cancel-outline::before {
  content: "\f174c";
}

.mdi-archive-check::before {
  content: "\f174d";
}

.mdi-archive-check-outline::before {
  content: "\f174e";
}

.mdi-archive-clock::before {
  content: "\f174f";
}

.mdi-archive-clock-outline::before {
  content: "\f1750";
}

.mdi-archive-cog::before {
  content: "\f1751";
}

.mdi-archive-cog-outline::before {
  content: "\f1752";
}

.mdi-archive-edit::before {
  content: "\f1753";
}

.mdi-archive-edit-outline::before {
  content: "\f1754";
}

.mdi-archive-eye::before {
  content: "\f1755";
}

.mdi-archive-eye-outline::before {
  content: "\f1756";
}

.mdi-archive-lock::before {
  content: "\f1757";
}

.mdi-archive-lock-open::before {
  content: "\f1758";
}

.mdi-archive-lock-open-outline::before {
  content: "\f1759";
}

.mdi-archive-lock-outline::before {
  content: "\f175a";
}

.mdi-archive-marker::before {
  content: "\f175b";
}

.mdi-archive-marker-outline::before {
  content: "\f175c";
}

.mdi-archive-minus::before {
  content: "\f175d";
}

.mdi-archive-minus-outline::before {
  content: "\f175e";
}

.mdi-archive-music::before {
  content: "\f175f";
}

.mdi-archive-music-outline::before {
  content: "\f1760";
}

.mdi-archive-off::before {
  content: "\f1761";
}

.mdi-archive-off-outline::before {
  content: "\f1762";
}

.mdi-archive-outline::before {
  content: "\f120e";
}

.mdi-archive-plus::before {
  content: "\f1763";
}

.mdi-archive-plus-outline::before {
  content: "\f1764";
}

.mdi-archive-refresh::before {
  content: "\f1765";
}

.mdi-archive-refresh-outline::before {
  content: "\f1766";
}

.mdi-archive-remove::before {
  content: "\f1767";
}

.mdi-archive-remove-outline::before {
  content: "\f1768";
}

.mdi-archive-search::before {
  content: "\f1769";
}

.mdi-archive-search-outline::before {
  content: "\f176a";
}

.mdi-archive-settings::before {
  content: "\f176b";
}

.mdi-archive-settings-outline::before {
  content: "\f176c";
}

.mdi-archive-star::before {
  content: "\f176d";
}

.mdi-archive-star-outline::before {
  content: "\f176e";
}

.mdi-archive-sync::before {
  content: "\f176f";
}

.mdi-archive-sync-outline::before {
  content: "\f1770";
}

.mdi-arm-flex::before {
  content: "\f0fd7";
}

.mdi-arm-flex-outline::before {
  content: "\f0fd6";
}

.mdi-arrange-bring-forward::before {
  content: "\f003d";
}

.mdi-arrange-bring-to-front::before {
  content: "\f003e";
}

.mdi-arrange-send-backward::before {
  content: "\f003f";
}

.mdi-arrange-send-to-back::before {
  content: "\f0040";
}

.mdi-arrow-all::before {
  content: "\f0041";
}

.mdi-arrow-bottom-left::before {
  content: "\f0042";
}

.mdi-arrow-bottom-left-bold-box::before {
  content: "\f1964";
}

.mdi-arrow-bottom-left-bold-box-outline::before {
  content: "\f1965";
}

.mdi-arrow-bottom-left-bold-outline::before {
  content: "\f09b7";
}

.mdi-arrow-bottom-left-thick::before {
  content: "\f09b8";
}

.mdi-arrow-bottom-left-thin::before {
  content: "\f19b6";
}

.mdi-arrow-bottom-left-thin-circle-outline::before {
  content: "\f1596";
}

.mdi-arrow-bottom-right::before {
  content: "\f0043";
}

.mdi-arrow-bottom-right-bold-box::before {
  content: "\f1966";
}

.mdi-arrow-bottom-right-bold-box-outline::before {
  content: "\f1967";
}

.mdi-arrow-bottom-right-bold-outline::before {
  content: "\f09b9";
}

.mdi-arrow-bottom-right-thick::before {
  content: "\f09ba";
}

.mdi-arrow-bottom-right-thin::before {
  content: "\f19b7";
}

.mdi-arrow-bottom-right-thin-circle-outline::before {
  content: "\f1595";
}

.mdi-arrow-collapse::before {
  content: "\f0615";
}

.mdi-arrow-collapse-all::before {
  content: "\f0044";
}

.mdi-arrow-collapse-down::before {
  content: "\f0792";
}

.mdi-arrow-collapse-horizontal::before {
  content: "\f084c";
}

.mdi-arrow-collapse-left::before {
  content: "\f0793";
}

.mdi-arrow-collapse-right::before {
  content: "\f0794";
}

.mdi-arrow-collapse-up::before {
  content: "\f0795";
}

.mdi-arrow-collapse-vertical::before {
  content: "\f084d";
}

.mdi-arrow-decision::before {
  content: "\f09bb";
}

.mdi-arrow-decision-auto::before {
  content: "\f09bc";
}

.mdi-arrow-decision-auto-outline::before {
  content: "\f09bd";
}

.mdi-arrow-decision-outline::before {
  content: "\f09be";
}

.mdi-arrow-down::before {
  content: "\f0045";
}

.mdi-arrow-down-bold::before {
  content: "\f072e";
}

.mdi-arrow-down-bold-box::before {
  content: "\f072f";
}

.mdi-arrow-down-bold-box-outline::before {
  content: "\f0730";
}

.mdi-arrow-down-bold-circle::before {
  content: "\f0047";
}

.mdi-arrow-down-bold-circle-outline::before {
  content: "\f0048";
}

.mdi-arrow-down-bold-hexagon-outline::before {
  content: "\f0049";
}

.mdi-arrow-down-bold-outline::before {
  content: "\f09bf";
}

.mdi-arrow-down-box::before {
  content: "\f06c0";
}

.mdi-arrow-down-circle::before {
  content: "\f0cdb";
}

.mdi-arrow-down-circle-outline::before {
  content: "\f0cdc";
}

.mdi-arrow-down-drop-circle::before {
  content: "\f004a";
}

.mdi-arrow-down-drop-circle-outline::before {
  content: "\f004b";
}

.mdi-arrow-down-left::before {
  content: "\f17a1";
}

.mdi-arrow-down-left-bold::before {
  content: "\f17a2";
}

.mdi-arrow-down-right::before {
  content: "\f17a3";
}

.mdi-arrow-down-right-bold::before {
  content: "\f17a4";
}

.mdi-arrow-down-thick::before {
  content: "\f0046";
}

.mdi-arrow-down-thin::before {
  content: "\f19b3";
}

.mdi-arrow-down-thin-circle-outline::before {
  content: "\f1599";
}

.mdi-arrow-expand::before {
  content: "\f0616";
}

.mdi-arrow-expand-all::before {
  content: "\f004c";
}

.mdi-arrow-expand-down::before {
  content: "\f0796";
}

.mdi-arrow-expand-horizontal::before {
  content: "\f084e";
}

.mdi-arrow-expand-left::before {
  content: "\f0797";
}

.mdi-arrow-expand-right::before {
  content: "\f0798";
}

.mdi-arrow-expand-up::before {
  content: "\f0799";
}

.mdi-arrow-expand-vertical::before {
  content: "\f084f";
}

.mdi-arrow-horizontal-lock::before {
  content: "\f115b";
}

.mdi-arrow-left::before {
  content: "\f004d";
}

.mdi-arrow-left-bold::before {
  content: "\f0731";
}

.mdi-arrow-left-bold-box::before {
  content: "\f0732";
}

.mdi-arrow-left-bold-box-outline::before {
  content: "\f0733";
}

.mdi-arrow-left-bold-circle::before {
  content: "\f004f";
}

.mdi-arrow-left-bold-circle-outline::before {
  content: "\f0050";
}

.mdi-arrow-left-bold-hexagon-outline::before {
  content: "\f0051";
}

.mdi-arrow-left-bold-outline::before {
  content: "\f09c0";
}

.mdi-arrow-left-bottom::before {
  content: "\f17a5";
}

.mdi-arrow-left-bottom-bold::before {
  content: "\f17a6";
}

.mdi-arrow-left-box::before {
  content: "\f06c1";
}

.mdi-arrow-left-circle::before {
  content: "\f0cdd";
}

.mdi-arrow-left-circle-outline::before {
  content: "\f0cde";
}

.mdi-arrow-left-drop-circle::before {
  content: "\f0052";
}

.mdi-arrow-left-drop-circle-outline::before {
  content: "\f0053";
}

.mdi-arrow-left-right::before {
  content: "\f0e73";
}

.mdi-arrow-left-right-bold::before {
  content: "\f0e74";
}

.mdi-arrow-left-right-bold-outline::before {
  content: "\f09c1";
}

.mdi-arrow-left-thick::before {
  content: "\f004e";
}

.mdi-arrow-left-thin::before {
  content: "\f19b1";
}

.mdi-arrow-left-thin-circle-outline::before {
  content: "\f159a";
}

.mdi-arrow-left-top::before {
  content: "\f17a7";
}

.mdi-arrow-left-top-bold::before {
  content: "\f17a8";
}

.mdi-arrow-projectile::before {
  content: "\f1840";
}

.mdi-arrow-projectile-multiple::before {
  content: "\f183f";
}

.mdi-arrow-right::before {
  content: "\f0054";
}

.mdi-arrow-right-bold::before {
  content: "\f0734";
}

.mdi-arrow-right-bold-box::before {
  content: "\f0735";
}

.mdi-arrow-right-bold-box-outline::before {
  content: "\f0736";
}

.mdi-arrow-right-bold-circle::before {
  content: "\f0056";
}

.mdi-arrow-right-bold-circle-outline::before {
  content: "\f0057";
}

.mdi-arrow-right-bold-hexagon-outline::before {
  content: "\f0058";
}

.mdi-arrow-right-bold-outline::before {
  content: "\f09c2";
}

.mdi-arrow-right-bottom::before {
  content: "\f17a9";
}

.mdi-arrow-right-bottom-bold::before {
  content: "\f17aa";
}

.mdi-arrow-right-box::before {
  content: "\f06c2";
}

.mdi-arrow-right-circle::before {
  content: "\f0cdf";
}

.mdi-arrow-right-circle-outline::before {
  content: "\f0ce0";
}

.mdi-arrow-right-drop-circle::before {
  content: "\f0059";
}

.mdi-arrow-right-drop-circle-outline::before {
  content: "\f005a";
}

.mdi-arrow-right-thick::before {
  content: "\f0055";
}

.mdi-arrow-right-thin::before {
  content: "\f19b0";
}

.mdi-arrow-right-thin-circle-outline::before {
  content: "\f1598";
}

.mdi-arrow-right-top::before {
  content: "\f17ab";
}

.mdi-arrow-right-top-bold::before {
  content: "\f17ac";
}

.mdi-arrow-split-horizontal::before {
  content: "\f093b";
}

.mdi-arrow-split-vertical::before {
  content: "\f093c";
}

.mdi-arrow-top-left::before {
  content: "\f005b";
}

.mdi-arrow-top-left-bold-box::before {
  content: "\f1968";
}

.mdi-arrow-top-left-bold-box-outline::before {
  content: "\f1969";
}

.mdi-arrow-top-left-bold-outline::before {
  content: "\f09c3";
}

.mdi-arrow-top-left-bottom-right::before {
  content: "\f0e75";
}

.mdi-arrow-top-left-bottom-right-bold::before {
  content: "\f0e76";
}

.mdi-arrow-top-left-thick::before {
  content: "\f09c4";
}

.mdi-arrow-top-left-thin::before {
  content: "\f19b5";
}

.mdi-arrow-top-left-thin-circle-outline::before {
  content: "\f1593";
}

.mdi-arrow-top-right::before {
  content: "\f005c";
}

.mdi-arrow-top-right-bold-box::before {
  content: "\f196a";
}

.mdi-arrow-top-right-bold-box-outline::before {
  content: "\f196b";
}

.mdi-arrow-top-right-bold-outline::before {
  content: "\f09c5";
}

.mdi-arrow-top-right-bottom-left::before {
  content: "\f0e77";
}

.mdi-arrow-top-right-bottom-left-bold::before {
  content: "\f0e78";
}

.mdi-arrow-top-right-thick::before {
  content: "\f09c6";
}

.mdi-arrow-top-right-thin::before {
  content: "\f19b4";
}

.mdi-arrow-top-right-thin-circle-outline::before {
  content: "\f1594";
}

.mdi-arrow-u-down-left::before {
  content: "\f17ad";
}

.mdi-arrow-u-down-left-bold::before {
  content: "\f17ae";
}

.mdi-arrow-u-down-right::before {
  content: "\f17af";
}

.mdi-arrow-u-down-right-bold::before {
  content: "\f17b0";
}

.mdi-arrow-u-left-bottom::before {
  content: "\f17b1";
}

.mdi-arrow-u-left-bottom-bold::before {
  content: "\f17b2";
}

.mdi-arrow-u-left-top::before {
  content: "\f17b3";
}

.mdi-arrow-u-left-top-bold::before {
  content: "\f17b4";
}

.mdi-arrow-u-right-bottom::before {
  content: "\f17b5";
}

.mdi-arrow-u-right-bottom-bold::before {
  content: "\f17b6";
}

.mdi-arrow-u-right-top::before {
  content: "\f17b7";
}

.mdi-arrow-u-right-top-bold::before {
  content: "\f17b8";
}

.mdi-arrow-u-up-left::before {
  content: "\f17b9";
}

.mdi-arrow-u-up-left-bold::before {
  content: "\f17ba";
}

.mdi-arrow-u-up-right::before {
  content: "\f17bb";
}

.mdi-arrow-u-up-right-bold::before {
  content: "\f17bc";
}

.mdi-arrow-up::before {
  content: "\f005d";
}

.mdi-arrow-up-bold::before {
  content: "\f0737";
}

.mdi-arrow-up-bold-box::before {
  content: "\f0738";
}

.mdi-arrow-up-bold-box-outline::before {
  content: "\f0739";
}

.mdi-arrow-up-bold-circle::before {
  content: "\f005f";
}

.mdi-arrow-up-bold-circle-outline::before {
  content: "\f0060";
}

.mdi-arrow-up-bold-hexagon-outline::before {
  content: "\f0061";
}

.mdi-arrow-up-bold-outline::before {
  content: "\f09c7";
}

.mdi-arrow-up-box::before {
  content: "\f06c3";
}

.mdi-arrow-up-circle::before {
  content: "\f0ce1";
}

.mdi-arrow-up-circle-outline::before {
  content: "\f0ce2";
}

.mdi-arrow-up-down::before {
  content: "\f0e79";
}

.mdi-arrow-up-down-bold::before {
  content: "\f0e7a";
}

.mdi-arrow-up-down-bold-outline::before {
  content: "\f09c8";
}

.mdi-arrow-up-drop-circle::before {
  content: "\f0062";
}

.mdi-arrow-up-drop-circle-outline::before {
  content: "\f0063";
}

.mdi-arrow-up-left::before {
  content: "\f17bd";
}

.mdi-arrow-up-left-bold::before {
  content: "\f17be";
}

.mdi-arrow-up-right::before {
  content: "\f17bf";
}

.mdi-arrow-up-right-bold::before {
  content: "\f17c0";
}

.mdi-arrow-up-thick::before {
  content: "\f005e";
}

.mdi-arrow-up-thin::before {
  content: "\f19b2";
}

.mdi-arrow-up-thin-circle-outline::before {
  content: "\f1597";
}

.mdi-arrow-vertical-lock::before {
  content: "\f115c";
}

.mdi-artstation::before {
  content: "\f0b5b";
}

.mdi-aspect-ratio::before {
  content: "\f0a24";
}

.mdi-assistant::before {
  content: "\f0064";
}

.mdi-asterisk::before {
  content: "\f06c4";
}

.mdi-at::before {
  content: "\f0065";
}

.mdi-atlassian::before {
  content: "\f0804";
}

.mdi-atm::before {
  content: "\f0d47";
}

.mdi-atom::before {
  content: "\f0768";
}

.mdi-atom-variant::before {
  content: "\f0e7b";
}

.mdi-attachment::before {
  content: "\f0066";
}

.mdi-audio-input-rca::before {
  content: "\f186b";
}

.mdi-audio-input-stereo-minijack::before {
  content: "\f186c";
}

.mdi-audio-input-xlr::before {
  content: "\f186d";
}

.mdi-audio-video::before {
  content: "\f093d";
}

.mdi-audio-video-off::before {
  content: "\f11b6";
}

.mdi-augmented-reality::before {
  content: "\f0850";
}

.mdi-auto-download::before {
  content: "\f137e";
}

.mdi-auto-fix::before {
  content: "\f0068";
}

.mdi-auto-upload::before {
  content: "\f0069";
}

.mdi-autorenew::before {
  content: "\f006a";
}

.mdi-av-timer::before {
  content: "\f006b";
}

.mdi-aws::before {
  content: "\f0e0f";
}

.mdi-axe::before {
  content: "\f08c8";
}

.mdi-axe-battle::before {
  content: "\f1842";
}

.mdi-axis::before {
  content: "\f0d48";
}

.mdi-axis-arrow::before {
  content: "\f0d49";
}

.mdi-axis-arrow-info::before {
  content: "\f140e";
}

.mdi-axis-arrow-lock::before {
  content: "\f0d4a";
}

.mdi-axis-lock::before {
  content: "\f0d4b";
}

.mdi-axis-x-arrow::before {
  content: "\f0d4c";
}

.mdi-axis-x-arrow-lock::before {
  content: "\f0d4d";
}

.mdi-axis-x-rotate-clockwise::before {
  content: "\f0d4e";
}

.mdi-axis-x-rotate-counterclockwise::before {
  content: "\f0d4f";
}

.mdi-axis-x-y-arrow-lock::before {
  content: "\f0d50";
}

.mdi-axis-y-arrow::before {
  content: "\f0d51";
}

.mdi-axis-y-arrow-lock::before {
  content: "\f0d52";
}

.mdi-axis-y-rotate-clockwise::before {
  content: "\f0d53";
}

.mdi-axis-y-rotate-counterclockwise::before {
  content: "\f0d54";
}

.mdi-axis-z-arrow::before {
  content: "\f0d55";
}

.mdi-axis-z-arrow-lock::before {
  content: "\f0d56";
}

.mdi-axis-z-rotate-clockwise::before {
  content: "\f0d57";
}

.mdi-axis-z-rotate-counterclockwise::before {
  content: "\f0d58";
}

.mdi-babel::before {
  content: "\f0a25";
}

.mdi-baby::before {
  content: "\f006c";
}

.mdi-baby-bottle::before {
  content: "\f0f39";
}

.mdi-baby-bottle-outline::before {
  content: "\f0f3a";
}

.mdi-baby-buggy::before {
  content: "\f13e0";
}

.mdi-baby-carriage::before {
  content: "\f068f";
}

.mdi-baby-carriage-off::before {
  content: "\f0fa0";
}

.mdi-baby-face::before {
  content: "\f0e7c";
}

.mdi-baby-face-outline::before {
  content: "\f0e7d";
}

.mdi-backburger::before {
  content: "\f006d";
}

.mdi-backspace::before {
  content: "\f006e";
}

.mdi-backspace-outline::before {
  content: "\f0b5c";
}

.mdi-backspace-reverse::before {
  content: "\f0e7e";
}

.mdi-backspace-reverse-outline::before {
  content: "\f0e7f";
}

.mdi-backup-restore::before {
  content: "\f006f";
}

.mdi-bacteria::before {
  content: "\f0ed5";
}

.mdi-bacteria-outline::before {
  content: "\f0ed6";
}

.mdi-badge-account::before {
  content: "\f0da7";
}

.mdi-badge-account-alert::before {
  content: "\f0da8";
}

.mdi-badge-account-alert-outline::before {
  content: "\f0da9";
}

.mdi-badge-account-horizontal::before {
  content: "\f0e0d";
}

.mdi-badge-account-horizontal-outline::before {
  content: "\f0e0e";
}

.mdi-badge-account-outline::before {
  content: "\f0daa";
}

.mdi-badminton::before {
  content: "\f0851";
}

.mdi-bag-carry-on::before {
  content: "\f0f3b";
}

.mdi-bag-carry-on-check::before {
  content: "\f0d65";
}

.mdi-bag-carry-on-off::before {
  content: "\f0f3c";
}

.mdi-bag-checked::before {
  content: "\f0f3d";
}

.mdi-bag-personal::before {
  content: "\f0e10";
}

.mdi-bag-personal-off::before {
  content: "\f0e11";
}

.mdi-bag-personal-off-outline::before {
  content: "\f0e12";
}

.mdi-bag-personal-outline::before {
  content: "\f0e13";
}

.mdi-bag-suitcase::before {
  content: "\f158b";
}

.mdi-bag-suitcase-off::before {
  content: "\f158d";
}

.mdi-bag-suitcase-off-outline::before {
  content: "\f158e";
}

.mdi-bag-suitcase-outline::before {
  content: "\f158c";
}

.mdi-baguette::before {
  content: "\f0f3e";
}

.mdi-balcony::before {
  content: "\f1817";
}

.mdi-balloon::before {
  content: "\f0a26";
}

.mdi-ballot::before {
  content: "\f09c9";
}

.mdi-ballot-outline::before {
  content: "\f09ca";
}

.mdi-ballot-recount::before {
  content: "\f0c39";
}

.mdi-ballot-recount-outline::before {
  content: "\f0c3a";
}

.mdi-bandage::before {
  content: "\f0daf";
}

.mdi-bank::before {
  content: "\f0070";
}

.mdi-bank-check::before {
  content: "\f1655";
}

.mdi-bank-minus::before {
  content: "\f0db0";
}

.mdi-bank-off::before {
  content: "\f1656";
}

.mdi-bank-off-outline::before {
  content: "\f1657";
}

.mdi-bank-outline::before {
  content: "\f0e80";
}

.mdi-bank-plus::before {
  content: "\f0db1";
}

.mdi-bank-remove::before {
  content: "\f0db2";
}

.mdi-bank-transfer::before {
  content: "\f0a27";
}

.mdi-bank-transfer-in::before {
  content: "\f0a28";
}

.mdi-bank-transfer-out::before {
  content: "\f0a29";
}

.mdi-barcode::before {
  content: "\f0071";
}

.mdi-barcode-off::before {
  content: "\f1236";
}

.mdi-barcode-scan::before {
  content: "\f0072";
}

.mdi-barley::before {
  content: "\f0073";
}

.mdi-barley-off::before {
  content: "\f0b5d";
}

.mdi-barn::before {
  content: "\f0b5e";
}

.mdi-barrel::before {
  content: "\f0074";
}

.mdi-baseball::before {
  content: "\f0852";
}

.mdi-baseball-bat::before {
  content: "\f0853";
}

.mdi-baseball-diamond::before {
  content: "\f15ec";
}

.mdi-baseball-diamond-outline::before {
  content: "\f15ed";
}

.mdi-bash::before {
  content: "\f1183";
}

.mdi-basket::before {
  content: "\f0076";
}

.mdi-basket-check::before {
  content: "\f18e5";
}

.mdi-basket-check-outline::before {
  content: "\f18e6";
}

.mdi-basket-fill::before {
  content: "\f0077";
}

.mdi-basket-minus::before {
  content: "\f1523";
}

.mdi-basket-minus-outline::before {
  content: "\f1524";
}

.mdi-basket-off::before {
  content: "\f1525";
}

.mdi-basket-off-outline::before {
  content: "\f1526";
}

.mdi-basket-outline::before {
  content: "\f1181";
}

.mdi-basket-plus::before {
  content: "\f1527";
}

.mdi-basket-plus-outline::before {
  content: "\f1528";
}

.mdi-basket-remove::before {
  content: "\f1529";
}

.mdi-basket-remove-outline::before {
  content: "\f152a";
}

.mdi-basket-unfill::before {
  content: "\f0078";
}

.mdi-basketball::before {
  content: "\f0806";
}

.mdi-basketball-hoop::before {
  content: "\f0c3b";
}

.mdi-basketball-hoop-outline::before {
  content: "\f0c3c";
}

.mdi-bat::before {
  content: "\f0b5f";
}

.mdi-bathtub::before {
  content: "\f1818";
}

.mdi-bathtub-outline::before {
  content: "\f1819";
}

.mdi-battery::before {
  content: "\f0079";
}

.mdi-battery-10::before {
  content: "\f007a";
}

.mdi-battery-10-bluetooth::before {
  content: "\f093e";
}

.mdi-battery-20::before {
  content: "\f007b";
}

.mdi-battery-20-bluetooth::before {
  content: "\f093f";
}

.mdi-battery-30::before {
  content: "\f007c";
}

.mdi-battery-30-bluetooth::before {
  content: "\f0940";
}

.mdi-battery-40::before {
  content: "\f007d";
}

.mdi-battery-40-bluetooth::before {
  content: "\f0941";
}

.mdi-battery-50::before {
  content: "\f007e";
}

.mdi-battery-50-bluetooth::before {
  content: "\f0942";
}

.mdi-battery-60::before {
  content: "\f007f";
}

.mdi-battery-60-bluetooth::before {
  content: "\f0943";
}

.mdi-battery-70::before {
  content: "\f0080";
}

.mdi-battery-70-bluetooth::before {
  content: "\f0944";
}

.mdi-battery-80::before {
  content: "\f0081";
}

.mdi-battery-80-bluetooth::before {
  content: "\f0945";
}

.mdi-battery-90::before {
  content: "\f0082";
}

.mdi-battery-90-bluetooth::before {
  content: "\f0946";
}

.mdi-battery-alert::before {
  content: "\f0083";
}

.mdi-battery-alert-bluetooth::before {
  content: "\f0947";
}

.mdi-battery-alert-variant::before {
  content: "\f10cc";
}

.mdi-battery-alert-variant-outline::before {
  content: "\f10cd";
}

.mdi-battery-arrow-down::before {
  content: "\f17de";
}

.mdi-battery-arrow-down-outline::before {
  content: "\f17df";
}

.mdi-battery-arrow-up::before {
  content: "\f17e0";
}

.mdi-battery-arrow-up-outline::before {
  content: "\f17e1";
}

.mdi-battery-bluetooth::before {
  content: "\f0948";
}

.mdi-battery-bluetooth-variant::before {
  content: "\f0949";
}

.mdi-battery-charging::before {
  content: "\f0084";
}

.mdi-battery-charging-10::before {
  content: "\f089c";
}

.mdi-battery-charging-100::before {
  content: "\f0085";
}

.mdi-battery-charging-20::before {
  content: "\f0086";
}

.mdi-battery-charging-30::before {
  content: "\f0087";
}

.mdi-battery-charging-40::before {
  content: "\f0088";
}

.mdi-battery-charging-50::before {
  content: "\f089d";
}

.mdi-battery-charging-60::before {
  content: "\f0089";
}

.mdi-battery-charging-70::before {
  content: "\f089e";
}

.mdi-battery-charging-80::before {
  content: "\f008a";
}

.mdi-battery-charging-90::before {
  content: "\f008b";
}

.mdi-battery-charging-high::before {
  content: "\f12a6";
}

.mdi-battery-charging-low::before {
  content: "\f12a4";
}

.mdi-battery-charging-medium::before {
  content: "\f12a5";
}

.mdi-battery-charging-outline::before {
  content: "\f089f";
}

.mdi-battery-charging-wireless::before {
  content: "\f0807";
}

.mdi-battery-charging-wireless-10::before {
  content: "\f0808";
}

.mdi-battery-charging-wireless-20::before {
  content: "\f0809";
}

.mdi-battery-charging-wireless-30::before {
  content: "\f080a";
}

.mdi-battery-charging-wireless-40::before {
  content: "\f080b";
}

.mdi-battery-charging-wireless-50::before {
  content: "\f080c";
}

.mdi-battery-charging-wireless-60::before {
  content: "\f080d";
}

.mdi-battery-charging-wireless-70::before {
  content: "\f080e";
}

.mdi-battery-charging-wireless-80::before {
  content: "\f080f";
}

.mdi-battery-charging-wireless-90::before {
  content: "\f0810";
}

.mdi-battery-charging-wireless-alert::before {
  content: "\f0811";
}

.mdi-battery-charging-wireless-outline::before {
  content: "\f0812";
}

.mdi-battery-check::before {
  content: "\f17e2";
}

.mdi-battery-check-outline::before {
  content: "\f17e3";
}

.mdi-battery-heart::before {
  content: "\f120f";
}

.mdi-battery-heart-outline::before {
  content: "\f1210";
}

.mdi-battery-heart-variant::before {
  content: "\f1211";
}

.mdi-battery-high::before {
  content: "\f12a3";
}

.mdi-battery-lock::before {
  content: "\f179c";
}

.mdi-battery-lock-open::before {
  content: "\f179d";
}

.mdi-battery-low::before {
  content: "\f12a1";
}

.mdi-battery-medium::before {
  content: "\f12a2";
}

.mdi-battery-minus::before {
  content: "\f17e4";
}

.mdi-battery-minus-outline::before {
  content: "\f17e5";
}

.mdi-battery-minus-variant::before {
  content: "\f008c";
}

.mdi-battery-negative::before {
  content: "\f008d";
}

.mdi-battery-off::before {
  content: "\f125d";
}

.mdi-battery-off-outline::before {
  content: "\f125e";
}

.mdi-battery-outline::before {
  content: "\f008e";
}

.mdi-battery-plus::before {
  content: "\f17e6";
}

.mdi-battery-plus-outline::before {
  content: "\f17e7";
}

.mdi-battery-plus-variant::before {
  content: "\f008f";
}

.mdi-battery-positive::before {
  content: "\f0090";
}

.mdi-battery-remove::before {
  content: "\f17e8";
}

.mdi-battery-remove-outline::before {
  content: "\f17e9";
}

.mdi-battery-sync::before {
  content: "\f1834";
}

.mdi-battery-sync-outline::before {
  content: "\f1835";
}

.mdi-battery-unknown::before {
  content: "\f0091";
}

.mdi-battery-unknown-bluetooth::before {
  content: "\f094a";
}

.mdi-beach::before {
  content: "\f0092";
}

.mdi-beaker::before {
  content: "\f0cea";
}

.mdi-beaker-alert::before {
  content: "\f1229";
}

.mdi-beaker-alert-outline::before {
  content: "\f122a";
}

.mdi-beaker-check::before {
  content: "\f122b";
}

.mdi-beaker-check-outline::before {
  content: "\f122c";
}

.mdi-beaker-minus::before {
  content: "\f122d";
}

.mdi-beaker-minus-outline::before {
  content: "\f122e";
}

.mdi-beaker-outline::before {
  content: "\f0690";
}

.mdi-beaker-plus::before {
  content: "\f122f";
}

.mdi-beaker-plus-outline::before {
  content: "\f1230";
}

.mdi-beaker-question::before {
  content: "\f1231";
}

.mdi-beaker-question-outline::before {
  content: "\f1232";
}

.mdi-beaker-remove::before {
  content: "\f1233";
}

.mdi-beaker-remove-outline::before {
  content: "\f1234";
}

.mdi-bed::before {
  content: "\f02e3";
}

.mdi-bed-double::before {
  content: "\f0fd4";
}

.mdi-bed-double-outline::before {
  content: "\f0fd3";
}

.mdi-bed-empty::before {
  content: "\f08a0";
}

.mdi-bed-king::before {
  content: "\f0fd2";
}

.mdi-bed-king-outline::before {
  content: "\f0fd1";
}

.mdi-bed-outline::before {
  content: "\f0099";
}

.mdi-bed-queen::before {
  content: "\f0fd0";
}

.mdi-bed-queen-outline::before {
  content: "\f0fdb";
}

.mdi-bed-single::before {
  content: "\f106d";
}

.mdi-bed-single-outline::before {
  content: "\f106e";
}

.mdi-bee::before {
  content: "\f0fa1";
}

.mdi-bee-flower::before {
  content: "\f0fa2";
}

.mdi-beehive-off-outline::before {
  content: "\f13ed";
}

.mdi-beehive-outline::before {
  content: "\f10ce";
}

.mdi-beekeeper::before {
  content: "\f14e2";
}

.mdi-beer::before {
  content: "\f0098";
}

.mdi-beer-outline::before {
  content: "\f130c";
}

.mdi-bell::before {
  content: "\f009a";
}

.mdi-bell-alert::before {
  content: "\f0d59";
}

.mdi-bell-alert-outline::before {
  content: "\f0e81";
}

.mdi-bell-badge::before {
  content: "\f116b";
}

.mdi-bell-badge-outline::before {
  content: "\f0178";
}

.mdi-bell-cancel::before {
  content: "\f13e7";
}

.mdi-bell-cancel-outline::before {
  content: "\f13e8";
}

.mdi-bell-check::before {
  content: "\f11e5";
}

.mdi-bell-check-outline::before {
  content: "\f11e6";
}

.mdi-bell-circle::before {
  content: "\f0d5a";
}

.mdi-bell-circle-outline::before {
  content: "\f0d5b";
}

.mdi-bell-minus::before {
  content: "\f13e9";
}

.mdi-bell-minus-outline::before {
  content: "\f13ea";
}

.mdi-bell-off::before {
  content: "\f009b";
}

.mdi-bell-off-outline::before {
  content: "\f0a91";
}

.mdi-bell-outline::before {
  content: "\f009c";
}

.mdi-bell-plus::before {
  content: "\f009d";
}

.mdi-bell-plus-outline::before {
  content: "\f0a92";
}

.mdi-bell-remove::before {
  content: "\f13eb";
}

.mdi-bell-remove-outline::before {
  content: "\f13ec";
}

.mdi-bell-ring::before {
  content: "\f009e";
}

.mdi-bell-ring-outline::before {
  content: "\f009f";
}

.mdi-bell-sleep::before {
  content: "\f00a0";
}

.mdi-bell-sleep-outline::before {
  content: "\f0a93";
}

.mdi-beta::before {
  content: "\f00a1";
}

.mdi-betamax::before {
  content: "\f09cb";
}

.mdi-biathlon::before {
  content: "\f0e14";
}

.mdi-bicycle::before {
  content: "\f109c";
}

.mdi-bicycle-basket::before {
  content: "\f1235";
}

.mdi-bicycle-cargo::before {
  content: "\f189c";
}

.mdi-bicycle-electric::before {
  content: "\f15b4";
}

.mdi-bicycle-penny-farthing::before {
  content: "\f15e9";
}

.mdi-bike::before {
  content: "\f00a3";
}

.mdi-bike-fast::before {
  content: "\f111f";
}

.mdi-billboard::before {
  content: "\f1010";
}

.mdi-billiards::before {
  content: "\f0b61";
}

.mdi-billiards-rack::before {
  content: "\f0b62";
}

.mdi-binoculars::before {
  content: "\f00a5";
}

.mdi-bio::before {
  content: "\f00a6";
}

.mdi-biohazard::before {
  content: "\f00a7";
}

.mdi-bird::before {
  content: "\f15c6";
}

.mdi-bitbucket::before {
  content: "\f00a8";
}

.mdi-bitcoin::before {
  content: "\f0813";
}

.mdi-black-mesa::before {
  content: "\f00a9";
}

.mdi-blender::before {
  content: "\f0ceb";
}

.mdi-blender-outline::before {
  content: "\f181a";
}

.mdi-blender-software::before {
  content: "\f00ab";
}

.mdi-blinds::before {
  content: "\f00ac";
}

.mdi-blinds-open::before {
  content: "\f1011";
}

.mdi-block-helper::before {
  content: "\f00ad";
}

.mdi-blood-bag::before {
  content: "\f0cec";
}

.mdi-bluetooth::before {
  content: "\f00af";
}

.mdi-bluetooth-audio::before {
  content: "\f00b0";
}

.mdi-bluetooth-connect::before {
  content: "\f00b1";
}

.mdi-bluetooth-off::before {
  content: "\f00b2";
}

.mdi-bluetooth-settings::before {
  content: "\f00b3";
}

.mdi-bluetooth-transfer::before {
  content: "\f00b4";
}

.mdi-blur::before {
  content: "\f00b5";
}

.mdi-blur-linear::before {
  content: "\f00b6";
}

.mdi-blur-off::before {
  content: "\f00b7";
}

.mdi-blur-radial::before {
  content: "\f00b8";
}

.mdi-bolt::before {
  content: "\f0db3";
}

.mdi-bomb::before {
  content: "\f0691";
}

.mdi-bomb-off::before {
  content: "\f06c5";
}

.mdi-bone::before {
  content: "\f00b9";
}

.mdi-book::before {
  content: "\f00ba";
}

.mdi-book-account::before {
  content: "\f13ad";
}

.mdi-book-account-outline::before {
  content: "\f13ae";
}

.mdi-book-alert::before {
  content: "\f167c";
}

.mdi-book-alert-outline::before {
  content: "\f167d";
}

.mdi-book-alphabet::before {
  content: "\f061d";
}

.mdi-book-arrow-down::before {
  content: "\f167e";
}

.mdi-book-arrow-down-outline::before {
  content: "\f167f";
}

.mdi-book-arrow-left::before {
  content: "\f1680";
}

.mdi-book-arrow-left-outline::before {
  content: "\f1681";
}

.mdi-book-arrow-right::before {
  content: "\f1682";
}

.mdi-book-arrow-right-outline::before {
  content: "\f1683";
}

.mdi-book-arrow-up::before {
  content: "\f1684";
}

.mdi-book-arrow-up-outline::before {
  content: "\f1685";
}

.mdi-book-cancel::before {
  content: "\f1686";
}

.mdi-book-cancel-outline::before {
  content: "\f1687";
}

.mdi-book-check::before {
  content: "\f14f3";
}

.mdi-book-check-outline::before {
  content: "\f14f4";
}

.mdi-book-clock::before {
  content: "\f1688";
}

.mdi-book-clock-outline::before {
  content: "\f1689";
}

.mdi-book-cog::before {
  content: "\f168a";
}

.mdi-book-cog-outline::before {
  content: "\f168b";
}

.mdi-book-cross::before {
  content: "\f00a2";
}

.mdi-book-edit::before {
  content: "\f168c";
}

.mdi-book-edit-outline::before {
  content: "\f168d";
}

.mdi-book-education::before {
  content: "\f16c9";
}

.mdi-book-education-outline::before {
  content: "\f16ca";
}

.mdi-book-information-variant::before {
  content: "\f106f";
}

.mdi-book-lock::before {
  content: "\f079a";
}

.mdi-book-lock-open::before {
  content: "\f079b";
}

.mdi-book-lock-open-outline::before {
  content: "\f168e";
}

.mdi-book-lock-outline::before {
  content: "\f168f";
}

.mdi-book-marker::before {
  content: "\f1690";
}

.mdi-book-marker-outline::before {
  content: "\f1691";
}

.mdi-book-minus::before {
  content: "\f05d9";
}

.mdi-book-minus-multiple::before {
  content: "\f0a94";
}

.mdi-book-minus-multiple-outline::before {
  content: "\f090b";
}

.mdi-book-minus-outline::before {
  content: "\f1692";
}

.mdi-book-multiple::before {
  content: "\f00bb";
}

.mdi-book-multiple-outline::before {
  content: "\f0436";
}

.mdi-book-music::before {
  content: "\f0067";
}

.mdi-book-music-outline::before {
  content: "\f1693";
}

.mdi-book-off::before {
  content: "\f1694";
}

.mdi-book-off-outline::before {
  content: "\f1695";
}

.mdi-book-open::before {
  content: "\f00bd";
}

.mdi-book-open-blank-variant::before {
  content: "\f00be";
}

.mdi-book-open-outline::before {
  content: "\f0b63";
}

.mdi-book-open-page-variant::before {
  content: "\f05da";
}

.mdi-book-open-page-variant-outline::before {
  content: "\f15d6";
}

.mdi-book-open-variant::before {
  content: "\f14f7";
}

.mdi-book-outline::before {
  content: "\f0b64";
}

.mdi-book-play::before {
  content: "\f0e82";
}

.mdi-book-play-outline::before {
  content: "\f0e83";
}

.mdi-book-plus::before {
  content: "\f05db";
}

.mdi-book-plus-multiple::before {
  content: "\f0a95";
}

.mdi-book-plus-multiple-outline::before {
  content: "\f0ade";
}

.mdi-book-plus-outline::before {
  content: "\f1696";
}

.mdi-book-refresh::before {
  content: "\f1697";
}

.mdi-book-refresh-outline::before {
  content: "\f1698";
}

.mdi-book-remove::before {
  content: "\f0a97";
}

.mdi-book-remove-multiple::before {
  content: "\f0a96";
}

.mdi-book-remove-multiple-outline::before {
  content: "\f04ca";
}

.mdi-book-remove-outline::before {
  content: "\f1699";
}

.mdi-book-search::before {
  content: "\f0e84";
}

.mdi-book-search-outline::before {
  content: "\f0e85";
}

.mdi-book-settings::before {
  content: "\f169a";
}

.mdi-book-settings-outline::before {
  content: "\f169b";
}

.mdi-book-sync::before {
  content: "\f169c";
}

.mdi-book-sync-outline::before {
  content: "\f16c8";
}

.mdi-book-variant::before {
  content: "\f00bf";
}

.mdi-book-variant-multiple::before {
  content: "\f00bc";
}

.mdi-bookmark::before {
  content: "\f00c0";
}

.mdi-bookmark-box-multiple::before {
  content: "\f196c";
}

.mdi-bookmark-box-multiple-outline::before {
  content: "\f196d";
}

.mdi-bookmark-check::before {
  content: "\f00c1";
}

.mdi-bookmark-check-outline::before {
  content: "\f137b";
}

.mdi-bookmark-minus::before {
  content: "\f09cc";
}

.mdi-bookmark-minus-outline::before {
  content: "\f09cd";
}

.mdi-bookmark-multiple::before {
  content: "\f0e15";
}

.mdi-bookmark-multiple-outline::before {
  content: "\f0e16";
}

.mdi-bookmark-music::before {
  content: "\f00c2";
}

.mdi-bookmark-music-outline::before {
  content: "\f1379";
}

.mdi-bookmark-off::before {
  content: "\f09ce";
}

.mdi-bookmark-off-outline::before {
  content: "\f09cf";
}

.mdi-bookmark-outline::before {
  content: "\f00c3";
}

.mdi-bookmark-plus::before {
  content: "\f00c5";
}

.mdi-bookmark-plus-outline::before {
  content: "\f00c4";
}

.mdi-bookmark-remove::before {
  content: "\f00c6";
}

.mdi-bookmark-remove-outline::before {
  content: "\f137a";
}

.mdi-bookshelf::before {
  content: "\f125f";
}

.mdi-boom-gate::before {
  content: "\f0e86";
}

.mdi-boom-gate-alert::before {
  content: "\f0e87";
}

.mdi-boom-gate-alert-outline::before {
  content: "\f0e88";
}

.mdi-boom-gate-arrow-down::before {
  content: "\f0e89";
}

.mdi-boom-gate-arrow-down-outline::before {
  content: "\f0e8a";
}

.mdi-boom-gate-arrow-up::before {
  content: "\f0e8c";
}

.mdi-boom-gate-arrow-up-outline::before {
  content: "\f0e8d";
}

.mdi-boom-gate-outline::before {
  content: "\f0e8b";
}

.mdi-boom-gate-up::before {
  content: "\f17f9";
}

.mdi-boom-gate-up-outline::before {
  content: "\f17fa";
}

.mdi-boombox::before {
  content: "\f05dc";
}

.mdi-boomerang::before {
  content: "\f10cf";
}

.mdi-bootstrap::before {
  content: "\f06c6";
}

.mdi-border-all::before {
  content: "\f00c7";
}

.mdi-border-all-variant::before {
  content: "\f08a1";
}

.mdi-border-bottom::before {
  content: "\f00c8";
}

.mdi-border-bottom-variant::before {
  content: "\f08a2";
}

.mdi-border-color::before {
  content: "\f00c9";
}

.mdi-border-horizontal::before {
  content: "\f00ca";
}

.mdi-border-inside::before {
  content: "\f00cb";
}

.mdi-border-left::before {
  content: "\f00cc";
}

.mdi-border-left-variant::before {
  content: "\f08a3";
}

.mdi-border-none::before {
  content: "\f00cd";
}

.mdi-border-none-variant::before {
  content: "\f08a4";
}

.mdi-border-outside::before {
  content: "\f00ce";
}

.mdi-border-right::before {
  content: "\f00cf";
}

.mdi-border-right-variant::before {
  content: "\f08a5";
}

.mdi-border-style::before {
  content: "\f00d0";
}

.mdi-border-top::before {
  content: "\f00d1";
}

.mdi-border-top-variant::before {
  content: "\f08a6";
}

.mdi-border-vertical::before {
  content: "\f00d2";
}

.mdi-bottle-soda::before {
  content: "\f1070";
}

.mdi-bottle-soda-classic::before {
  content: "\f1071";
}

.mdi-bottle-soda-classic-outline::before {
  content: "\f1363";
}

.mdi-bottle-soda-outline::before {
  content: "\f1072";
}

.mdi-bottle-tonic::before {
  content: "\f112e";
}

.mdi-bottle-tonic-outline::before {
  content: "\f112f";
}

.mdi-bottle-tonic-plus::before {
  content: "\f1130";
}

.mdi-bottle-tonic-plus-outline::before {
  content: "\f1131";
}

.mdi-bottle-tonic-skull::before {
  content: "\f1132";
}

.mdi-bottle-tonic-skull-outline::before {
  content: "\f1133";
}

.mdi-bottle-wine::before {
  content: "\f0854";
}

.mdi-bottle-wine-outline::before {
  content: "\f1310";
}

.mdi-bow-arrow::before {
  content: "\f1841";
}

.mdi-bow-tie::before {
  content: "\f0678";
}

.mdi-bowl::before {
  content: "\f028e";
}

.mdi-bowl-mix::before {
  content: "\f0617";
}

.mdi-bowl-mix-outline::before {
  content: "\f02e4";
}

.mdi-bowl-outline::before {
  content: "\f02a9";
}

.mdi-bowling::before {
  content: "\f00d3";
}

.mdi-box::before {
  content: "\f00d4";
}

.mdi-box-cutter::before {
  content: "\f00d5";
}

.mdi-box-cutter-off::before {
  content: "\f0b4a";
}

.mdi-box-shadow::before {
  content: "\f0637";
}

.mdi-boxing-glove::before {
  content: "\f0b65";
}

.mdi-braille::before {
  content: "\f09d0";
}

.mdi-brain::before {
  content: "\f09d1";
}

.mdi-bread-slice::before {
  content: "\f0cee";
}

.mdi-bread-slice-outline::before {
  content: "\f0cef";
}

.mdi-bridge::before {
  content: "\f0618";
}

.mdi-briefcase::before {
  content: "\f00d6";
}

.mdi-briefcase-account::before {
  content: "\f0cf0";
}

.mdi-briefcase-account-outline::before {
  content: "\f0cf1";
}

.mdi-briefcase-check::before {
  content: "\f00d7";
}

.mdi-briefcase-check-outline::before {
  content: "\f131e";
}

.mdi-briefcase-clock::before {
  content: "\f10d0";
}

.mdi-briefcase-clock-outline::before {
  content: "\f10d1";
}

.mdi-briefcase-download::before {
  content: "\f00d8";
}

.mdi-briefcase-download-outline::before {
  content: "\f0c3d";
}

.mdi-briefcase-edit::before {
  content: "\f0a98";
}

.mdi-briefcase-edit-outline::before {
  content: "\f0c3e";
}

.mdi-briefcase-eye::before {
  content: "\f17d9";
}

.mdi-briefcase-eye-outline::before {
  content: "\f17da";
}

.mdi-briefcase-minus::before {
  content: "\f0a2a";
}

.mdi-briefcase-minus-outline::before {
  content: "\f0c3f";
}

.mdi-briefcase-off::before {
  content: "\f1658";
}

.mdi-briefcase-off-outline::before {
  content: "\f1659";
}

.mdi-briefcase-outline::before {
  content: "\f0814";
}

.mdi-briefcase-plus::before {
  content: "\f0a2b";
}

.mdi-briefcase-plus-outline::before {
  content: "\f0c40";
}

.mdi-briefcase-remove::before {
  content: "\f0a2c";
}

.mdi-briefcase-remove-outline::before {
  content: "\f0c41";
}

.mdi-briefcase-search::before {
  content: "\f0a2d";
}

.mdi-briefcase-search-outline::before {
  content: "\f0c42";
}

.mdi-briefcase-upload::before {
  content: "\f00d9";
}

.mdi-briefcase-upload-outline::before {
  content: "\f0c43";
}

.mdi-briefcase-variant::before {
  content: "\f1494";
}

.mdi-briefcase-variant-off::before {
  content: "\f165a";
}

.mdi-briefcase-variant-off-outline::before {
  content: "\f165b";
}

.mdi-briefcase-variant-outline::before {
  content: "\f1495";
}

.mdi-brightness-1::before {
  content: "\f00da";
}

.mdi-brightness-2::before {
  content: "\f00db";
}

.mdi-brightness-3::before {
  content: "\f00dc";
}

.mdi-brightness-4::before {
  content: "\f00dd";
}

.mdi-brightness-5::before {
  content: "\f00de";
}

.mdi-brightness-6::before {
  content: "\f00df";
}

.mdi-brightness-7::before {
  content: "\f00e0";
}

.mdi-brightness-auto::before {
  content: "\f00e1";
}

.mdi-brightness-percent::before {
  content: "\f0cf2";
}

.mdi-broadcast::before {
  content: "\f1720";
}

.mdi-broadcast-off::before {
  content: "\f1721";
}

.mdi-broom::before {
  content: "\f00e2";
}

.mdi-brush::before {
  content: "\f00e3";
}

.mdi-brush-off::before {
  content: "\f1771";
}

.mdi-brush-variant::before {
  content: "\f1813";
}

.mdi-bucket::before {
  content: "\f1415";
}

.mdi-bucket-outline::before {
  content: "\f1416";
}

.mdi-buffet::before {
  content: "\f0578";
}

.mdi-bug::before {
  content: "\f00e4";
}

.mdi-bug-check::before {
  content: "\f0a2e";
}

.mdi-bug-check-outline::before {
  content: "\f0a2f";
}

.mdi-bug-outline::before {
  content: "\f0a30";
}

.mdi-bugle::before {
  content: "\f0db4";
}

.mdi-bulldozer::before {
  content: "\f0b22";
}

.mdi-bullet::before {
  content: "\f0cf3";
}

.mdi-bulletin-board::before {
  content: "\f00e5";
}

.mdi-bullhorn::before {
  content: "\f00e6";
}

.mdi-bullhorn-outline::before {
  content: "\f0b23";
}

.mdi-bullhorn-variant::before {
  content: "\f196e";
}

.mdi-bullhorn-variant-outline::before {
  content: "\f196f";
}

.mdi-bullseye::before {
  content: "\f05dd";
}

.mdi-bullseye-arrow::before {
  content: "\f08c9";
}

.mdi-bulma::before {
  content: "\f12e7";
}

.mdi-bunk-bed::before {
  content: "\f1302";
}

.mdi-bunk-bed-outline::before {
  content: "\f0097";
}

.mdi-bus::before {
  content: "\f00e7";
}

.mdi-bus-alert::before {
  content: "\f0a99";
}

.mdi-bus-articulated-end::before {
  content: "\f079c";
}

.mdi-bus-articulated-front::before {
  content: "\f079d";
}

.mdi-bus-clock::before {
  content: "\f08ca";
}

.mdi-bus-double-decker::before {
  content: "\f079e";
}

.mdi-bus-electric::before {
  content: "\f191d";
}

.mdi-bus-marker::before {
  content: "\f1212";
}

.mdi-bus-multiple::before {
  content: "\f0f3f";
}

.mdi-bus-school::before {
  content: "\f079f";
}

.mdi-bus-side::before {
  content: "\f07a0";
}

.mdi-bus-stop::before {
  content: "\f1012";
}

.mdi-bus-stop-covered::before {
  content: "\f1013";
}

.mdi-bus-stop-uncovered::before {
  content: "\f1014";
}

.mdi-butterfly::before {
  content: "\f1589";
}

.mdi-butterfly-outline::before {
  content: "\f158a";
}

.mdi-cabin-a-frame::before {
  content: "\f188c";
}

.mdi-cable-data::before {
  content: "\f1394";
}

.mdi-cached::before {
  content: "\f00e8";
}

.mdi-cactus::before {
  content: "\f0db5";
}

.mdi-cake::before {
  content: "\f00e9";
}

.mdi-cake-layered::before {
  content: "\f00ea";
}

.mdi-cake-variant::before {
  content: "\f00eb";
}

.mdi-cake-variant-outline::before {
  content: "\f17f0";
}

.mdi-calculator::before {
  content: "\f00ec";
}

.mdi-calculator-variant::before {
  content: "\f0a9a";
}

.mdi-calculator-variant-outline::before {
  content: "\f15a6";
}

.mdi-calendar::before {
  content: "\f00ed";
}

.mdi-calendar-account::before {
  content: "\f0ed7";
}

.mdi-calendar-account-outline::before {
  content: "\f0ed8";
}

.mdi-calendar-alert::before {
  content: "\f0a31";
}

.mdi-calendar-arrow-left::before {
  content: "\f1134";
}

.mdi-calendar-arrow-right::before {
  content: "\f1135";
}

.mdi-calendar-blank::before {
  content: "\f00ee";
}

.mdi-calendar-blank-multiple::before {
  content: "\f1073";
}

.mdi-calendar-blank-outline::before {
  content: "\f0b66";
}

.mdi-calendar-check::before {
  content: "\f00ef";
}

.mdi-calendar-check-outline::before {
  content: "\f0c44";
}

.mdi-calendar-clock::before {
  content: "\f00f0";
}

.mdi-calendar-clock-outline::before {
  content: "\f16e1";
}

.mdi-calendar-collapse-horizontal::before {
  content: "\f189d";
}

.mdi-calendar-cursor::before {
  content: "\f157b";
}

.mdi-calendar-edit::before {
  content: "\f08a7";
}

.mdi-calendar-end::before {
  content: "\f166c";
}

.mdi-calendar-expand-horizontal::before {
  content: "\f189e";
}

.mdi-calendar-export::before {
  content: "\f0b24";
}

.mdi-calendar-heart::before {
  content: "\f09d2";
}

.mdi-calendar-import::before {
  content: "\f0b25";
}

.mdi-calendar-lock::before {
  content: "\f1641";
}

.mdi-calendar-lock-outline::before {
  content: "\f1642";
}

.mdi-calendar-minus::before {
  content: "\f0d5c";
}

.mdi-calendar-month::before {
  content: "\f0e17";
}

.mdi-calendar-month-outline::before {
  content: "\f0e18";
}

.mdi-calendar-multiple::before {
  content: "\f00f1";
}

.mdi-calendar-multiple-check::before {
  content: "\f00f2";
}

.mdi-calendar-multiselect::before {
  content: "\f0a32";
}

.mdi-calendar-outline::before {
  content: "\f0b67";
}

.mdi-calendar-plus::before {
  content: "\f00f3";
}

.mdi-calendar-question::before {
  content: "\f0692";
}

.mdi-calendar-range::before {
  content: "\f0679";
}

.mdi-calendar-range-outline::before {
  content: "\f0b68";
}

.mdi-calendar-refresh::before {
  content: "\f01e1";
}

.mdi-calendar-refresh-outline::before {
  content: "\f0203";
}

.mdi-calendar-remove::before {
  content: "\f00f4";
}

.mdi-calendar-remove-outline::before {
  content: "\f0c45";
}

.mdi-calendar-search::before {
  content: "\f094c";
}

.mdi-calendar-star::before {
  content: "\f09d3";
}

.mdi-calendar-start::before {
  content: "\f166d";
}

.mdi-calendar-sync::before {
  content: "\f0e8e";
}

.mdi-calendar-sync-outline::before {
  content: "\f0e8f";
}

.mdi-calendar-text::before {
  content: "\f00f5";
}

.mdi-calendar-text-outline::before {
  content: "\f0c46";
}

.mdi-calendar-today::before {
  content: "\f00f6";
}

.mdi-calendar-week::before {
  content: "\f0a33";
}

.mdi-calendar-week-begin::before {
  content: "\f0a34";
}

.mdi-calendar-weekend::before {
  content: "\f0ed9";
}

.mdi-calendar-weekend-outline::before {
  content: "\f0eda";
}

.mdi-call-made::before {
  content: "\f00f7";
}

.mdi-call-merge::before {
  content: "\f00f8";
}

.mdi-call-missed::before {
  content: "\f00f9";
}

.mdi-call-received::before {
  content: "\f00fa";
}

.mdi-call-split::before {
  content: "\f00fb";
}

.mdi-camcorder::before {
  content: "\f00fc";
}

.mdi-camcorder-off::before {
  content: "\f00ff";
}

.mdi-camera::before {
  content: "\f0100";
}

.mdi-camera-account::before {
  content: "\f08cb";
}

.mdi-camera-burst::before {
  content: "\f0693";
}

.mdi-camera-control::before {
  content: "\f0b69";
}

.mdi-camera-document::before {
  content: "\f1871";
}

.mdi-camera-document-off::before {
  content: "\f1872";
}

.mdi-camera-enhance::before {
  content: "\f0101";
}

.mdi-camera-enhance-outline::before {
  content: "\f0b6a";
}

.mdi-camera-flip::before {
  content: "\f15d9";
}

.mdi-camera-flip-outline::before {
  content: "\f15da";
}

.mdi-camera-front::before {
  content: "\f0102";
}

.mdi-camera-front-variant::before {
  content: "\f0103";
}

.mdi-camera-gopro::before {
  content: "\f07a1";
}

.mdi-camera-image::before {
  content: "\f08cc";
}

.mdi-camera-iris::before {
  content: "\f0104";
}

.mdi-camera-marker::before {
  content: "\f19a7";
}

.mdi-camera-marker-outline::before {
  content: "\f19a8";
}

.mdi-camera-metering-center::before {
  content: "\f07a2";
}

.mdi-camera-metering-matrix::before {
  content: "\f07a3";
}

.mdi-camera-metering-partial::before {
  content: "\f07a4";
}

.mdi-camera-metering-spot::before {
  content: "\f07a5";
}

.mdi-camera-off::before {
  content: "\f05df";
}

.mdi-camera-off-outline::before {
  content: "\f19bf";
}

.mdi-camera-outline::before {
  content: "\f0d5d";
}

.mdi-camera-party-mode::before {
  content: "\f0105";
}

.mdi-camera-plus::before {
  content: "\f0edb";
}

.mdi-camera-plus-outline::before {
  content: "\f0edc";
}

.mdi-camera-rear::before {
  content: "\f0106";
}

.mdi-camera-rear-variant::before {
  content: "\f0107";
}

.mdi-camera-retake::before {
  content: "\f0e19";
}

.mdi-camera-retake-outline::before {
  content: "\f0e1a";
}

.mdi-camera-switch::before {
  content: "\f0108";
}

.mdi-camera-switch-outline::before {
  content: "\f084a";
}

.mdi-camera-timer::before {
  content: "\f0109";
}

.mdi-camera-wireless::before {
  content: "\f0db6";
}

.mdi-camera-wireless-outline::before {
  content: "\f0db7";
}

.mdi-campfire::before {
  content: "\f0edd";
}

.mdi-cancel::before {
  content: "\f073a";
}

.mdi-candelabra::before {
  content: "\f17d2";
}

.mdi-candelabra-fire::before {
  content: "\f17d3";
}

.mdi-candle::before {
  content: "\f05e2";
}

.mdi-candy::before {
  content: "\f1970";
}

.mdi-candy-off::before {
  content: "\f1971";
}

.mdi-candy-off-outline::before {
  content: "\f1972";
}

.mdi-candy-outline::before {
  content: "\f1973";
}

.mdi-candycane::before {
  content: "\f010a";
}

.mdi-cannabis::before {
  content: "\f07a6";
}

.mdi-cannabis-off::before {
  content: "\f166e";
}

.mdi-caps-lock::before {
  content: "\f0a9b";
}

.mdi-car::before {
  content: "\f010b";
}

.mdi-car-2-plus::before {
  content: "\f1015";
}

.mdi-car-3-plus::before {
  content: "\f1016";
}

.mdi-car-arrow-left::before {
  content: "\f13b2";
}

.mdi-car-arrow-right::before {
  content: "\f13b3";
}

.mdi-car-back::before {
  content: "\f0e1b";
}

.mdi-car-battery::before {
  content: "\f010c";
}

.mdi-car-brake-abs::before {
  content: "\f0c47";
}

.mdi-car-brake-alert::before {
  content: "\f0c48";
}

.mdi-car-brake-fluid-level::before {
  content: "\f1909";
}

.mdi-car-brake-hold::before {
  content: "\f0d5e";
}

.mdi-car-brake-low-pressure::before {
  content: "\f190a";
}

.mdi-car-brake-parking::before {
  content: "\f0d5f";
}

.mdi-car-brake-retarder::before {
  content: "\f1017";
}

.mdi-car-brake-temperature::before {
  content: "\f190b";
}

.mdi-car-brake-worn-linings::before {
  content: "\f190c";
}

.mdi-car-child-seat::before {
  content: "\f0fa3";
}

.mdi-car-clock::before {
  content: "\f1974";
}

.mdi-car-clutch::before {
  content: "\f1018";
}

.mdi-car-cog::before {
  content: "\f13cc";
}

.mdi-car-connected::before {
  content: "\f010d";
}

.mdi-car-convertible::before {
  content: "\f07a7";
}

.mdi-car-coolant-level::before {
  content: "\f1019";
}

.mdi-car-cruise-control::before {
  content: "\f0d60";
}

.mdi-car-defrost-front::before {
  content: "\f0d61";
}

.mdi-car-defrost-rear::before {
  content: "\f0d62";
}

.mdi-car-door::before {
  content: "\f0b6b";
}

.mdi-car-door-lock::before {
  content: "\f109d";
}

.mdi-car-electric::before {
  content: "\f0b6c";
}

.mdi-car-electric-outline::before {
  content: "\f15b5";
}

.mdi-car-emergency::before {
  content: "\f160f";
}

.mdi-car-esp::before {
  content: "\f0c49";
}

.mdi-car-estate::before {
  content: "\f07a8";
}

.mdi-car-hatchback::before {
  content: "\f07a9";
}

.mdi-car-info::before {
  content: "\f11be";
}

.mdi-car-key::before {
  content: "\f0b6d";
}

.mdi-car-lifted-pickup::before {
  content: "\f152d";
}

.mdi-car-light-alert::before {
  content: "\f190d";
}

.mdi-car-light-dimmed::before {
  content: "\f0c4a";
}

.mdi-car-light-fog::before {
  content: "\f0c4b";
}

.mdi-car-light-high::before {
  content: "\f0c4c";
}

.mdi-car-limousine::before {
  content: "\f08cd";
}

.mdi-car-multiple::before {
  content: "\f0b6e";
}

.mdi-car-off::before {
  content: "\f0e1c";
}

.mdi-car-outline::before {
  content: "\f14ed";
}

.mdi-car-parking-lights::before {
  content: "\f0d63";
}

.mdi-car-pickup::before {
  content: "\f07aa";
}

.mdi-car-seat::before {
  content: "\f0fa4";
}

.mdi-car-seat-cooler::before {
  content: "\f0fa5";
}

.mdi-car-seat-heater::before {
  content: "\f0fa6";
}

.mdi-car-select::before {
  content: "\f1879";
}

.mdi-car-settings::before {
  content: "\f13cd";
}

.mdi-car-shift-pattern::before {
  content: "\f0f40";
}

.mdi-car-side::before {
  content: "\f07ab";
}

.mdi-car-speed-limiter::before {
  content: "\f190e";
}

.mdi-car-sports::before {
  content: "\f07ac";
}

.mdi-car-tire-alert::before {
  content: "\f0c4d";
}

.mdi-car-traction-control::before {
  content: "\f0d64";
}

.mdi-car-turbocharger::before {
  content: "\f101a";
}

.mdi-car-wash::before {
  content: "\f010e";
}

.mdi-car-windshield::before {
  content: "\f101b";
}

.mdi-car-windshield-outline::before {
  content: "\f101c";
}

.mdi-car-wireless::before {
  content: "\f1878";
}

.mdi-car-wrench::before {
  content: "\f1814";
}

.mdi-carabiner::before {
  content: "\f14c0";
}

.mdi-caravan::before {
  content: "\f07ad";
}

.mdi-card::before {
  content: "\f0b6f";
}

.mdi-card-account-details::before {
  content: "\f05d2";
}

.mdi-card-account-details-outline::before {
  content: "\f0dab";
}

.mdi-card-account-details-star::before {
  content: "\f02a3";
}

.mdi-card-account-details-star-outline::before {
  content: "\f06db";
}

.mdi-card-account-mail::before {
  content: "\f018e";
}

.mdi-card-account-mail-outline::before {
  content: "\f0e98";
}

.mdi-card-account-phone::before {
  content: "\f0e99";
}

.mdi-card-account-phone-outline::before {
  content: "\f0e9a";
}

.mdi-card-bulleted::before {
  content: "\f0b70";
}

.mdi-card-bulleted-off::before {
  content: "\f0b71";
}

.mdi-card-bulleted-off-outline::before {
  content: "\f0b72";
}

.mdi-card-bulleted-outline::before {
  content: "\f0b73";
}

.mdi-card-bulleted-settings::before {
  content: "\f0b74";
}

.mdi-card-bulleted-settings-outline::before {
  content: "\f0b75";
}

.mdi-card-minus::before {
  content: "\f1600";
}

.mdi-card-minus-outline::before {
  content: "\f1601";
}

.mdi-card-multiple::before {
  content: "\f17f1";
}

.mdi-card-multiple-outline::before {
  content: "\f17f2";
}

.mdi-card-off::before {
  content: "\f1602";
}

.mdi-card-off-outline::before {
  content: "\f1603";
}

.mdi-card-outline::before {
  content: "\f0b76";
}

.mdi-card-plus::before {
  content: "\f11ff";
}

.mdi-card-plus-outline::before {
  content: "\f1200";
}

.mdi-card-remove::before {
  content: "\f1604";
}

.mdi-card-remove-outline::before {
  content: "\f1605";
}

.mdi-card-search::before {
  content: "\f1074";
}

.mdi-card-search-outline::before {
  content: "\f1075";
}

.mdi-card-text::before {
  content: "\f0b77";
}

.mdi-card-text-outline::before {
  content: "\f0b78";
}

.mdi-cards::before {
  content: "\f0638";
}

.mdi-cards-club::before {
  content: "\f08ce";
}

.mdi-cards-club-outline::before {
  content: "\f189f";
}

.mdi-cards-diamond::before {
  content: "\f08cf";
}

.mdi-cards-diamond-outline::before {
  content: "\f101d";
}

.mdi-cards-heart::before {
  content: "\f08d0";
}

.mdi-cards-heart-outline::before {
  content: "\f18a0";
}

.mdi-cards-outline::before {
  content: "\f0639";
}

.mdi-cards-playing::before {
  content: "\f18a1";
}

.mdi-cards-playing-club::before {
  content: "\f18a2";
}

.mdi-cards-playing-club-multiple::before {
  content: "\f18a3";
}

.mdi-cards-playing-club-multiple-outline::before {
  content: "\f18a4";
}

.mdi-cards-playing-club-outline::before {
  content: "\f18a5";
}

.mdi-cards-playing-diamond::before {
  content: "\f18a6";
}

.mdi-cards-playing-diamond-multiple::before {
  content: "\f18a7";
}

.mdi-cards-playing-diamond-multiple-outline::before {
  content: "\f18a8";
}

.mdi-cards-playing-diamond-outline::before {
  content: "\f18a9";
}

.mdi-cards-playing-heart::before {
  content: "\f18aa";
}

.mdi-cards-playing-heart-multiple::before {
  content: "\f18ab";
}

.mdi-cards-playing-heart-multiple-outline::before {
  content: "\f18ac";
}

.mdi-cards-playing-heart-outline::before {
  content: "\f18ad";
}

.mdi-cards-playing-outline::before {
  content: "\f063a";
}

.mdi-cards-playing-spade::before {
  content: "\f18ae";
}

.mdi-cards-playing-spade-multiple::before {
  content: "\f18af";
}

.mdi-cards-playing-spade-multiple-outline::before {
  content: "\f18b0";
}

.mdi-cards-playing-spade-outline::before {
  content: "\f18b1";
}

.mdi-cards-spade::before {
  content: "\f08d1";
}

.mdi-cards-spade-outline::before {
  content: "\f18b2";
}

.mdi-cards-variant::before {
  content: "\f06c7";
}

.mdi-carrot::before {
  content: "\f010f";
}

.mdi-cart::before {
  content: "\f0110";
}

.mdi-cart-arrow-down::before {
  content: "\f0d66";
}

.mdi-cart-arrow-right::before {
  content: "\f0c4e";
}

.mdi-cart-arrow-up::before {
  content: "\f0d67";
}

.mdi-cart-check::before {
  content: "\f15ea";
}

.mdi-cart-heart::before {
  content: "\f18e0";
}

.mdi-cart-minus::before {
  content: "\f0d68";
}

.mdi-cart-off::before {
  content: "\f066b";
}

.mdi-cart-outline::before {
  content: "\f0111";
}

.mdi-cart-plus::before {
  content: "\f0112";
}

.mdi-cart-remove::before {
  content: "\f0d69";
}

.mdi-cart-variant::before {
  content: "\f15eb";
}

.mdi-case-sensitive-alt::before {
  content: "\f0113";
}

.mdi-cash::before {
  content: "\f0114";
}

.mdi-cash-100::before {
  content: "\f0115";
}

.mdi-cash-check::before {
  content: "\f14ee";
}

.mdi-cash-fast::before {
  content: "\f185c";
}

.mdi-cash-lock::before {
  content: "\f14ea";
}

.mdi-cash-lock-open::before {
  content: "\f14eb";
}

.mdi-cash-marker::before {
  content: "\f0db8";
}

.mdi-cash-minus::before {
  content: "\f1260";
}

.mdi-cash-multiple::before {
  content: "\f0116";
}

.mdi-cash-plus::before {
  content: "\f1261";
}

.mdi-cash-refund::before {
  content: "\f0a9c";
}

.mdi-cash-register::before {
  content: "\f0cf4";
}

.mdi-cash-remove::before {
  content: "\f1262";
}

.mdi-cassette::before {
  content: "\f09d4";
}

.mdi-cast::before {
  content: "\f0118";
}

.mdi-cast-audio::before {
  content: "\f101e";
}

.mdi-cast-audio-variant::before {
  content: "\f1749";
}

.mdi-cast-connected::before {
  content: "\f0119";
}

.mdi-cast-education::before {
  content: "\f0e1d";
}

.mdi-cast-off::before {
  content: "\f078a";
}

.mdi-cast-variant::before {
  content: "\f001f";
}

.mdi-castle::before {
  content: "\f011a";
}

.mdi-cat::before {
  content: "\f011b";
}

.mdi-cctv::before {
  content: "\f07ae";
}

.mdi-cctv-off::before {
  content: "\f185f";
}

.mdi-ceiling-fan::before {
  content: "\f1797";
}

.mdi-ceiling-fan-light::before {
  content: "\f1798";
}

.mdi-ceiling-light::before {
  content: "\f0769";
}

.mdi-ceiling-light-multiple::before {
  content: "\f18dd";
}

.mdi-ceiling-light-multiple-outline::before {
  content: "\f18de";
}

.mdi-ceiling-light-outline::before {
  content: "\f17c7";
}

.mdi-cellphone::before {
  content: "\f011c";
}

.mdi-cellphone-arrow-down::before {
  content: "\f09d5";
}

.mdi-cellphone-basic::before {
  content: "\f011e";
}

.mdi-cellphone-charging::before {
  content: "\f1397";
}

.mdi-cellphone-check::before {
  content: "\f17fd";
}

.mdi-cellphone-cog::before {
  content: "\f0951";
}

.mdi-cellphone-dock::before {
  content: "\f011f";
}

.mdi-cellphone-information::before {
  content: "\f0f41";
}

.mdi-cellphone-key::before {
  content: "\f094e";
}

.mdi-cellphone-link::before {
  content: "\f0121";
}

.mdi-cellphone-link-off::before {
  content: "\f0122";
}

.mdi-cellphone-lock::before {
  content: "\f094f";
}

.mdi-cellphone-marker::before {
  content: "\f183a";
}

.mdi-cellphone-message::before {
  content: "\f08d3";
}

.mdi-cellphone-message-off::before {
  content: "\f10d2";
}

.mdi-cellphone-nfc::before {
  content: "\f0e90";
}

.mdi-cellphone-nfc-off::before {
  content: "\f12d8";
}

.mdi-cellphone-off::before {
  content: "\f0950";
}

.mdi-cellphone-play::before {
  content: "\f101f";
}

.mdi-cellphone-remove::before {
  content: "\f094d";
}

.mdi-cellphone-screenshot::before {
  content: "\f0a35";
}

.mdi-cellphone-settings::before {
  content: "\f0123";
}

.mdi-cellphone-sound::before {
  content: "\f0952";
}

.mdi-cellphone-text::before {
  content: "\f08d2";
}

.mdi-cellphone-wireless::before {
  content: "\f0815";
}

.mdi-centos::before {
  content: "\f111a";
}

.mdi-certificate::before {
  content: "\f0124";
}

.mdi-certificate-outline::before {
  content: "\f1188";
}

.mdi-chair-rolling::before {
  content: "\f0f48";
}

.mdi-chair-school::before {
  content: "\f0125";
}

.mdi-chandelier::before {
  content: "\f1793";
}

.mdi-charity::before {
  content: "\f0c4f";
}

.mdi-chart-arc::before {
  content: "\f0126";
}

.mdi-chart-areaspline::before {
  content: "\f0127";
}

.mdi-chart-areaspline-variant::before {
  content: "\f0e91";
}

.mdi-chart-bar::before {
  content: "\f0128";
}

.mdi-chart-bar-stacked::before {
  content: "\f076a";
}

.mdi-chart-bell-curve::before {
  content: "\f0c50";
}

.mdi-chart-bell-curve-cumulative::before {
  content: "\f0fa7";
}

.mdi-chart-box::before {
  content: "\f154d";
}

.mdi-chart-box-outline::before {
  content: "\f154e";
}

.mdi-chart-box-plus-outline::before {
  content: "\f154f";
}

.mdi-chart-bubble::before {
  content: "\f05e3";
}

.mdi-chart-donut::before {
  content: "\f07af";
}

.mdi-chart-donut-variant::before {
  content: "\f07b0";
}

.mdi-chart-gantt::before {
  content: "\f066c";
}

.mdi-chart-histogram::before {
  content: "\f0129";
}

.mdi-chart-line::before {
  content: "\f012a";
}

.mdi-chart-line-stacked::before {
  content: "\f076b";
}

.mdi-chart-line-variant::before {
  content: "\f07b1";
}

.mdi-chart-multiline::before {
  content: "\f08d4";
}

.mdi-chart-multiple::before {
  content: "\f1213";
}

.mdi-chart-pie::before {
  content: "\f012b";
}

.mdi-chart-ppf::before {
  content: "\f1380";
}

.mdi-chart-sankey::before {
  content: "\f11df";
}

.mdi-chart-sankey-variant::before {
  content: "\f11e0";
}

.mdi-chart-scatter-plot::before {
  content: "\f0e92";
}

.mdi-chart-scatter-plot-hexbin::before {
  content: "\f066d";
}

.mdi-chart-timeline::before {
  content: "\f066e";
}

.mdi-chart-timeline-variant::before {
  content: "\f0e93";
}

.mdi-chart-timeline-variant-shimmer::before {
  content: "\f15b6";
}

.mdi-chart-tree::before {
  content: "\f0e94";
}

.mdi-chart-waterfall::before {
  content: "\f1918";
}

.mdi-chat::before {
  content: "\f0b79";
}

.mdi-chat-alert::before {
  content: "\f0b7a";
}

.mdi-chat-alert-outline::before {
  content: "\f12c9";
}

.mdi-chat-minus::before {
  content: "\f1410";
}

.mdi-chat-minus-outline::before {
  content: "\f1413";
}

.mdi-chat-outline::before {
  content: "\f0ede";
}

.mdi-chat-plus::before {
  content: "\f140f";
}

.mdi-chat-plus-outline::before {
  content: "\f1412";
}

.mdi-chat-processing::before {
  content: "\f0b7b";
}

.mdi-chat-processing-outline::before {
  content: "\f12ca";
}

.mdi-chat-question::before {
  content: "\f1738";
}

.mdi-chat-question-outline::before {
  content: "\f1739";
}

.mdi-chat-remove::before {
  content: "\f1411";
}

.mdi-chat-remove-outline::before {
  content: "\f1414";
}

.mdi-chat-sleep::before {
  content: "\f12d1";
}

.mdi-chat-sleep-outline::before {
  content: "\f12d2";
}

.mdi-check::before {
  content: "\f012c";
}

.mdi-check-all::before {
  content: "\f012d";
}

.mdi-check-bold::before {
  content: "\f0e1e";
}

.mdi-check-circle::before {
  content: "\f05e0";
}

.mdi-check-circle-outline::before {
  content: "\f05e1";
}

.mdi-check-decagram::before {
  content: "\f0791";
}

.mdi-check-decagram-outline::before {
  content: "\f1740";
}

.mdi-check-network::before {
  content: "\f0c53";
}

.mdi-check-network-outline::before {
  content: "\f0c54";
}

.mdi-check-outline::before {
  content: "\f0855";
}

.mdi-check-underline::before {
  content: "\f0e1f";
}

.mdi-check-underline-circle::before {
  content: "\f0e20";
}

.mdi-check-underline-circle-outline::before {
  content: "\f0e21";
}

.mdi-checkbook::before {
  content: "\f0a9d";
}

.mdi-checkbox-blank::before {
  content: "\f012e";
}

.mdi-checkbox-blank-badge::before {
  content: "\f1176";
}

.mdi-checkbox-blank-badge-outline::before {
  content: "\f0117";
}

.mdi-checkbox-blank-circle::before {
  content: "\f012f";
}

.mdi-checkbox-blank-circle-outline::before {
  content: "\f0130";
}

.mdi-checkbox-blank-off::before {
  content: "\f12ec";
}

.mdi-checkbox-blank-off-outline::before {
  content: "\f12ed";
}

.mdi-checkbox-blank-outline::before {
  content: "\f0131";
}

.mdi-checkbox-intermediate::before {
  content: "\f0856";
}

.mdi-checkbox-marked::before {
  content: "\f0132";
}

.mdi-checkbox-marked-circle::before {
  content: "\f0133";
}

.mdi-checkbox-marked-circle-outline::before {
  content: "\f0134";
}

.mdi-checkbox-marked-circle-plus-outline::before {
  content: "\f1927";
}

.mdi-checkbox-marked-outline::before {
  content: "\f0135";
}

.mdi-checkbox-multiple-blank::before {
  content: "\f0136";
}

.mdi-checkbox-multiple-blank-circle::before {
  content: "\f063b";
}

.mdi-checkbox-multiple-blank-circle-outline::before {
  content: "\f063c";
}

.mdi-checkbox-multiple-blank-outline::before {
  content: "\f0137";
}

.mdi-checkbox-multiple-marked::before {
  content: "\f0138";
}

.mdi-checkbox-multiple-marked-circle::before {
  content: "\f063d";
}

.mdi-checkbox-multiple-marked-circle-outline::before {
  content: "\f063e";
}

.mdi-checkbox-multiple-marked-outline::before {
  content: "\f0139";
}

.mdi-checkbox-multiple-outline::before {
  content: "\f0c51";
}

.mdi-checkbox-outline::before {
  content: "\f0c52";
}

.mdi-checkerboard::before {
  content: "\f013a";
}

.mdi-checkerboard-minus::before {
  content: "\f1202";
}

.mdi-checkerboard-plus::before {
  content: "\f1201";
}

.mdi-checkerboard-remove::before {
  content: "\f1203";
}

.mdi-cheese::before {
  content: "\f12b9";
}

.mdi-cheese-off::before {
  content: "\f13ee";
}

.mdi-chef-hat::before {
  content: "\f0b7c";
}

.mdi-chemical-weapon::before {
  content: "\f013b";
}

.mdi-chess-bishop::before {
  content: "\f085c";
}

.mdi-chess-king::before {
  content: "\f0857";
}

.mdi-chess-knight::before {
  content: "\f0858";
}

.mdi-chess-pawn::before {
  content: "\f0859";
}

.mdi-chess-queen::before {
  content: "\f085a";
}

.mdi-chess-rook::before {
  content: "\f085b";
}

.mdi-chevron-double-down::before {
  content: "\f013c";
}

.mdi-chevron-double-left::before {
  content: "\f013d";
}

.mdi-chevron-double-right::before {
  content: "\f013e";
}

.mdi-chevron-double-up::before {
  content: "\f013f";
}

.mdi-chevron-down::before {
  content: "\f0140";
}

.mdi-chevron-down-box::before {
  content: "\f09d6";
}

.mdi-chevron-down-box-outline::before {
  content: "\f09d7";
}

.mdi-chevron-down-circle::before {
  content: "\f0b26";
}

.mdi-chevron-down-circle-outline::before {
  content: "\f0b27";
}

.mdi-chevron-left::before {
  content: "\f0141";
}

.mdi-chevron-left-box::before {
  content: "\f09d8";
}

.mdi-chevron-left-box-outline::before {
  content: "\f09d9";
}

.mdi-chevron-left-circle::before {
  content: "\f0b28";
}

.mdi-chevron-left-circle-outline::before {
  content: "\f0b29";
}

.mdi-chevron-right::before {
  content: "\f0142";
}

.mdi-chevron-right-box::before {
  content: "\f09da";
}

.mdi-chevron-right-box-outline::before {
  content: "\f09db";
}

.mdi-chevron-right-circle::before {
  content: "\f0b2a";
}

.mdi-chevron-right-circle-outline::before {
  content: "\f0b2b";
}

.mdi-chevron-triple-down::before {
  content: "\f0db9";
}

.mdi-chevron-triple-left::before {
  content: "\f0dba";
}

.mdi-chevron-triple-right::before {
  content: "\f0dbb";
}

.mdi-chevron-triple-up::before {
  content: "\f0dbc";
}

.mdi-chevron-up::before {
  content: "\f0143";
}

.mdi-chevron-up-box::before {
  content: "\f09dc";
}

.mdi-chevron-up-box-outline::before {
  content: "\f09dd";
}

.mdi-chevron-up-circle::before {
  content: "\f0b2c";
}

.mdi-chevron-up-circle-outline::before {
  content: "\f0b2d";
}

.mdi-chili-alert::before {
  content: "\f17ea";
}

.mdi-chili-alert-outline::before {
  content: "\f17eb";
}

.mdi-chili-hot::before {
  content: "\f07b2";
}

.mdi-chili-hot-outline::before {
  content: "\f17ec";
}

.mdi-chili-medium::before {
  content: "\f07b3";
}

.mdi-chili-medium-outline::before {
  content: "\f17ed";
}

.mdi-chili-mild::before {
  content: "\f07b4";
}

.mdi-chili-mild-outline::before {
  content: "\f17ee";
}

.mdi-chili-off::before {
  content: "\f1467";
}

.mdi-chili-off-outline::before {
  content: "\f17ef";
}

.mdi-chip::before {
  content: "\f061a";
}

.mdi-church::before {
  content: "\f0144";
}

.mdi-cigar::before {
  content: "\f1189";
}

.mdi-cigar-off::before {
  content: "\f141b";
}

.mdi-circle::before {
  content: "\f0765";
}

.mdi-circle-box::before {
  content: "\f15dc";
}

.mdi-circle-box-outline::before {
  content: "\f15dd";
}

.mdi-circle-double::before {
  content: "\f0e95";
}

.mdi-circle-edit-outline::before {
  content: "\f08d5";
}

.mdi-circle-expand::before {
  content: "\f0e96";
}

.mdi-circle-half::before {
  content: "\f1395";
}

.mdi-circle-half-full::before {
  content: "\f1396";
}

.mdi-circle-medium::before {
  content: "\f09de";
}

.mdi-circle-multiple::before {
  content: "\f0b38";
}

.mdi-circle-multiple-outline::before {
  content: "\f0695";
}

.mdi-circle-off-outline::before {
  content: "\f10d3";
}

.mdi-circle-opacity::before {
  content: "\f1853";
}

.mdi-circle-outline::before {
  content: "\f0766";
}

.mdi-circle-slice-1::before {
  content: "\f0a9e";
}

.mdi-circle-slice-2::before {
  content: "\f0a9f";
}

.mdi-circle-slice-3::before {
  content: "\f0aa0";
}

.mdi-circle-slice-4::before {
  content: "\f0aa1";
}

.mdi-circle-slice-5::before {
  content: "\f0aa2";
}

.mdi-circle-slice-6::before {
  content: "\f0aa3";
}

.mdi-circle-slice-7::before {
  content: "\f0aa4";
}

.mdi-circle-slice-8::before {
  content: "\f0aa5";
}

.mdi-circle-small::before {
  content: "\f09df";
}

.mdi-circular-saw::before {
  content: "\f0e22";
}

.mdi-city::before {
  content: "\f0146";
}

.mdi-city-variant::before {
  content: "\f0a36";
}

.mdi-city-variant-outline::before {
  content: "\f0a37";
}

.mdi-clipboard::before {
  content: "\f0147";
}

.mdi-clipboard-account::before {
  content: "\f0148";
}

.mdi-clipboard-account-outline::before {
  content: "\f0c55";
}

.mdi-clipboard-alert::before {
  content: "\f0149";
}

.mdi-clipboard-alert-outline::before {
  content: "\f0cf7";
}

.mdi-clipboard-arrow-down::before {
  content: "\f014a";
}

.mdi-clipboard-arrow-down-outline::before {
  content: "\f0c56";
}

.mdi-clipboard-arrow-left::before {
  content: "\f014b";
}

.mdi-clipboard-arrow-left-outline::before {
  content: "\f0cf8";
}

.mdi-clipboard-arrow-right::before {
  content: "\f0cf9";
}

.mdi-clipboard-arrow-right-outline::before {
  content: "\f0cfa";
}

.mdi-clipboard-arrow-up::before {
  content: "\f0c57";
}

.mdi-clipboard-arrow-up-outline::before {
  content: "\f0c58";
}

.mdi-clipboard-check::before {
  content: "\f014e";
}

.mdi-clipboard-check-multiple::before {
  content: "\f1263";
}

.mdi-clipboard-check-multiple-outline::before {
  content: "\f1264";
}

.mdi-clipboard-check-outline::before {
  content: "\f08a8";
}

.mdi-clipboard-clock::before {
  content: "\f16e2";
}

.mdi-clipboard-clock-outline::before {
  content: "\f16e3";
}

.mdi-clipboard-edit::before {
  content: "\f14e5";
}

.mdi-clipboard-edit-outline::before {
  content: "\f14e6";
}

.mdi-clipboard-file::before {
  content: "\f1265";
}

.mdi-clipboard-file-outline::before {
  content: "\f1266";
}

.mdi-clipboard-flow::before {
  content: "\f06c8";
}

.mdi-clipboard-flow-outline::before {
  content: "\f1117";
}

.mdi-clipboard-list::before {
  content: "\f10d4";
}

.mdi-clipboard-list-outline::before {
  content: "\f10d5";
}

.mdi-clipboard-minus::before {
  content: "\f1618";
}

.mdi-clipboard-minus-outline::before {
  content: "\f1619";
}

.mdi-clipboard-multiple::before {
  content: "\f1267";
}

.mdi-clipboard-multiple-outline::before {
  content: "\f1268";
}

.mdi-clipboard-off::before {
  content: "\f161a";
}

.mdi-clipboard-off-outline::before {
  content: "\f161b";
}

.mdi-clipboard-outline::before {
  content: "\f014c";
}

.mdi-clipboard-play::before {
  content: "\f0c59";
}

.mdi-clipboard-play-multiple::before {
  content: "\f1269";
}

.mdi-clipboard-play-multiple-outline::before {
  content: "\f126a";
}

.mdi-clipboard-play-outline::before {
  content: "\f0c5a";
}

.mdi-clipboard-plus::before {
  content: "\f0751";
}

.mdi-clipboard-plus-outline::before {
  content: "\f131f";
}

.mdi-clipboard-pulse::before {
  content: "\f085d";
}

.mdi-clipboard-pulse-outline::before {
  content: "\f085e";
}

.mdi-clipboard-remove::before {
  content: "\f161c";
}

.mdi-clipboard-remove-outline::before {
  content: "\f161d";
}

.mdi-clipboard-search::before {
  content: "\f161e";
}

.mdi-clipboard-search-outline::before {
  content: "\f161f";
}

.mdi-clipboard-text::before {
  content: "\f014d";
}

.mdi-clipboard-text-clock::before {
  content: "\f18f9";
}

.mdi-clipboard-text-clock-outline::before {
  content: "\f18fa";
}

.mdi-clipboard-text-multiple::before {
  content: "\f126b";
}

.mdi-clipboard-text-multiple-outline::before {
  content: "\f126c";
}

.mdi-clipboard-text-off::before {
  content: "\f1620";
}

.mdi-clipboard-text-off-outline::before {
  content: "\f1621";
}

.mdi-clipboard-text-outline::before {
  content: "\f0a38";
}

.mdi-clipboard-text-play::before {
  content: "\f0c5b";
}

.mdi-clipboard-text-play-outline::before {
  content: "\f0c5c";
}

.mdi-clipboard-text-search::before {
  content: "\f1622";
}

.mdi-clipboard-text-search-outline::before {
  content: "\f1623";
}

.mdi-clippy::before {
  content: "\f014f";
}

.mdi-clock::before {
  content: "\f0954";
}

.mdi-clock-alert::before {
  content: "\f0955";
}

.mdi-clock-alert-outline::before {
  content: "\f05ce";
}

.mdi-clock-check::before {
  content: "\f0fa8";
}

.mdi-clock-check-outline::before {
  content: "\f0fa9";
}

.mdi-clock-digital::before {
  content: "\f0e97";
}

.mdi-clock-edit::before {
  content: "\f19ba";
}

.mdi-clock-edit-outline::before {
  content: "\f19bb";
}

.mdi-clock-end::before {
  content: "\f0151";
}

.mdi-clock-fast::before {
  content: "\f0152";
}

.mdi-clock-in::before {
  content: "\f0153";
}

.mdi-clock-minus::before {
  content: "\f1863";
}

.mdi-clock-minus-outline::before {
  content: "\f1864";
}

.mdi-clock-out::before {
  content: "\f0154";
}

.mdi-clock-outline::before {
  content: "\f0150";
}

.mdi-clock-plus::before {
  content: "\f1861";
}

.mdi-clock-plus-outline::before {
  content: "\f1862";
}

.mdi-clock-remove::before {
  content: "\f1865";
}

.mdi-clock-remove-outline::before {
  content: "\f1866";
}

.mdi-clock-start::before {
  content: "\f0155";
}

.mdi-clock-time-eight::before {
  content: "\f1446";
}

.mdi-clock-time-eight-outline::before {
  content: "\f1452";
}

.mdi-clock-time-eleven::before {
  content: "\f1449";
}

.mdi-clock-time-eleven-outline::before {
  content: "\f1455";
}

.mdi-clock-time-five::before {
  content: "\f1443";
}

.mdi-clock-time-five-outline::before {
  content: "\f144f";
}

.mdi-clock-time-four::before {
  content: "\f1442";
}

.mdi-clock-time-four-outline::before {
  content: "\f144e";
}

.mdi-clock-time-nine::before {
  content: "\f1447";
}

.mdi-clock-time-nine-outline::before {
  content: "\f1453";
}

.mdi-clock-time-one::before {
  content: "\f143f";
}

.mdi-clock-time-one-outline::before {
  content: "\f144b";
}

.mdi-clock-time-seven::before {
  content: "\f1445";
}

.mdi-clock-time-seven-outline::before {
  content: "\f1451";
}

.mdi-clock-time-six::before {
  content: "\f1444";
}

.mdi-clock-time-six-outline::before {
  content: "\f1450";
}

.mdi-clock-time-ten::before {
  content: "\f1448";
}

.mdi-clock-time-ten-outline::before {
  content: "\f1454";
}

.mdi-clock-time-three::before {
  content: "\f1441";
}

.mdi-clock-time-three-outline::before {
  content: "\f144d";
}

.mdi-clock-time-twelve::before {
  content: "\f144a";
}

.mdi-clock-time-twelve-outline::before {
  content: "\f1456";
}

.mdi-clock-time-two::before {
  content: "\f1440";
}

.mdi-clock-time-two-outline::before {
  content: "\f144c";
}

.mdi-close::before {
  content: "\f0156";
}

.mdi-close-box::before {
  content: "\f0157";
}

.mdi-close-box-multiple::before {
  content: "\f0c5d";
}

.mdi-close-box-multiple-outline::before {
  content: "\f0c5e";
}

.mdi-close-box-outline::before {
  content: "\f0158";
}

.mdi-close-circle::before {
  content: "\f0159";
}

.mdi-close-circle-multiple::before {
  content: "\f062a";
}

.mdi-close-circle-multiple-outline::before {
  content: "\f0883";
}

.mdi-close-circle-outline::before {
  content: "\f015a";
}

.mdi-close-network::before {
  content: "\f015b";
}

.mdi-close-network-outline::before {
  content: "\f0c5f";
}

.mdi-close-octagon::before {
  content: "\f015c";
}

.mdi-close-octagon-outline::before {
  content: "\f015d";
}

.mdi-close-outline::before {
  content: "\f06c9";
}

.mdi-close-thick::before {
  content: "\f1398";
}

.mdi-closed-caption::before {
  content: "\f015e";
}

.mdi-closed-caption-outline::before {
  content: "\f0dbd";
}

.mdi-cloud::before {
  content: "\f015f";
}

.mdi-cloud-alert::before {
  content: "\f09e0";
}

.mdi-cloud-braces::before {
  content: "\f07b5";
}

.mdi-cloud-check::before {
  content: "\f0160";
}

.mdi-cloud-check-outline::before {
  content: "\f12cc";
}

.mdi-cloud-circle::before {
  content: "\f0161";
}

.mdi-cloud-download::before {
  content: "\f0162";
}

.mdi-cloud-download-outline::before {
  content: "\f0b7d";
}

.mdi-cloud-lock::before {
  content: "\f11f1";
}

.mdi-cloud-lock-outline::before {
  content: "\f11f2";
}

.mdi-cloud-off-outline::before {
  content: "\f0164";
}

.mdi-cloud-outline::before {
  content: "\f0163";
}

.mdi-cloud-print::before {
  content: "\f0165";
}

.mdi-cloud-print-outline::before {
  content: "\f0166";
}

.mdi-cloud-question::before {
  content: "\f0a39";
}

.mdi-cloud-refresh::before {
  content: "\f052a";
}

.mdi-cloud-search::before {
  content: "\f0956";
}

.mdi-cloud-search-outline::before {
  content: "\f0957";
}

.mdi-cloud-sync::before {
  content: "\f063f";
}

.mdi-cloud-sync-outline::before {
  content: "\f12d6";
}

.mdi-cloud-tags::before {
  content: "\f07b6";
}

.mdi-cloud-upload::before {
  content: "\f0167";
}

.mdi-cloud-upload-outline::before {
  content: "\f0b7e";
}

.mdi-clover::before {
  content: "\f0816";
}

.mdi-coach-lamp::before {
  content: "\f1020";
}

.mdi-coat-rack::before {
  content: "\f109e";
}

.mdi-code-array::before {
  content: "\f0168";
}

.mdi-code-braces::before {
  content: "\f0169";
}

.mdi-code-braces-box::before {
  content: "\f10d6";
}

.mdi-code-brackets::before {
  content: "\f016a";
}

.mdi-code-equal::before {
  content: "\f016b";
}

.mdi-code-greater-than::before {
  content: "\f016c";
}

.mdi-code-greater-than-or-equal::before {
  content: "\f016d";
}

.mdi-code-json::before {
  content: "\f0626";
}

.mdi-code-less-than::before {
  content: "\f016e";
}

.mdi-code-less-than-or-equal::before {
  content: "\f016f";
}

.mdi-code-not-equal::before {
  content: "\f0170";
}

.mdi-code-not-equal-variant::before {
  content: "\f0171";
}

.mdi-code-parentheses::before {
  content: "\f0172";
}

.mdi-code-parentheses-box::before {
  content: "\f10d7";
}

.mdi-code-string::before {
  content: "\f0173";
}

.mdi-code-tags::before {
  content: "\f0174";
}

.mdi-code-tags-check::before {
  content: "\f0694";
}

.mdi-codepen::before {
  content: "\f0175";
}

.mdi-coffee::before {
  content: "\f0176";
}

.mdi-coffee-maker::before {
  content: "\f109f";
}

.mdi-coffee-maker-check::before {
  content: "\f1931";
}

.mdi-coffee-maker-check-outline::before {
  content: "\f1932";
}

.mdi-coffee-maker-outline::before {
  content: "\f181b";
}

.mdi-coffee-off::before {
  content: "\f0faa";
}

.mdi-coffee-off-outline::before {
  content: "\f0fab";
}

.mdi-coffee-outline::before {
  content: "\f06ca";
}

.mdi-coffee-to-go::before {
  content: "\f0177";
}

.mdi-coffee-to-go-outline::before {
  content: "\f130e";
}

.mdi-coffin::before {
  content: "\f0b7f";
}

.mdi-cog::before {
  content: "\f0493";
}

.mdi-cog-box::before {
  content: "\f0494";
}

.mdi-cog-clockwise::before {
  content: "\f11dd";
}

.mdi-cog-counterclockwise::before {
  content: "\f11de";
}

.mdi-cog-off::before {
  content: "\f13ce";
}

.mdi-cog-off-outline::before {
  content: "\f13cf";
}

.mdi-cog-outline::before {
  content: "\f08bb";
}

.mdi-cog-pause::before {
  content: "\f1933";
}

.mdi-cog-pause-outline::before {
  content: "\f1934";
}

.mdi-cog-play::before {
  content: "\f1935";
}

.mdi-cog-play-outline::before {
  content: "\f1936";
}

.mdi-cog-refresh::before {
  content: "\f145e";
}

.mdi-cog-refresh-outline::before {
  content: "\f145f";
}

.mdi-cog-stop::before {
  content: "\f1937";
}

.mdi-cog-stop-outline::before {
  content: "\f1938";
}

.mdi-cog-sync::before {
  content: "\f1460";
}

.mdi-cog-sync-outline::before {
  content: "\f1461";
}

.mdi-cog-transfer::before {
  content: "\f105b";
}

.mdi-cog-transfer-outline::before {
  content: "\f105c";
}

.mdi-cogs::before {
  content: "\f08d6";
}

.mdi-collage::before {
  content: "\f0640";
}

.mdi-collapse-all::before {
  content: "\f0aa6";
}

.mdi-collapse-all-outline::before {
  content: "\f0aa7";
}

.mdi-color-helper::before {
  content: "\f0179";
}

.mdi-comma::before {
  content: "\f0e23";
}

.mdi-comma-box::before {
  content: "\f0e2b";
}

.mdi-comma-box-outline::before {
  content: "\f0e24";
}

.mdi-comma-circle::before {
  content: "\f0e25";
}

.mdi-comma-circle-outline::before {
  content: "\f0e26";
}

.mdi-comment::before {
  content: "\f017a";
}

.mdi-comment-account::before {
  content: "\f017b";
}

.mdi-comment-account-outline::before {
  content: "\f017c";
}

.mdi-comment-alert::before {
  content: "\f017d";
}

.mdi-comment-alert-outline::before {
  content: "\f017e";
}

.mdi-comment-arrow-left::before {
  content: "\f09e1";
}

.mdi-comment-arrow-left-outline::before {
  content: "\f09e2";
}

.mdi-comment-arrow-right::before {
  content: "\f09e3";
}

.mdi-comment-arrow-right-outline::before {
  content: "\f09e4";
}

.mdi-comment-bookmark::before {
  content: "\f15ae";
}

.mdi-comment-bookmark-outline::before {
  content: "\f15af";
}

.mdi-comment-check::before {
  content: "\f017f";
}

.mdi-comment-check-outline::before {
  content: "\f0180";
}

.mdi-comment-edit::before {
  content: "\f11bf";
}

.mdi-comment-edit-outline::before {
  content: "\f12c4";
}

.mdi-comment-eye::before {
  content: "\f0a3a";
}

.mdi-comment-eye-outline::before {
  content: "\f0a3b";
}

.mdi-comment-flash::before {
  content: "\f15b0";
}

.mdi-comment-flash-outline::before {
  content: "\f15b1";
}

.mdi-comment-minus::before {
  content: "\f15df";
}

.mdi-comment-minus-outline::before {
  content: "\f15e0";
}

.mdi-comment-multiple::before {
  content: "\f085f";
}

.mdi-comment-multiple-outline::before {
  content: "\f0181";
}

.mdi-comment-off::before {
  content: "\f15e1";
}

.mdi-comment-off-outline::before {
  content: "\f15e2";
}

.mdi-comment-outline::before {
  content: "\f0182";
}

.mdi-comment-plus::before {
  content: "\f09e5";
}

.mdi-comment-plus-outline::before {
  content: "\f0183";
}

.mdi-comment-processing::before {
  content: "\f0184";
}

.mdi-comment-processing-outline::before {
  content: "\f0185";
}

.mdi-comment-question::before {
  content: "\f0817";
}

.mdi-comment-question-outline::before {
  content: "\f0186";
}

.mdi-comment-quote::before {
  content: "\f1021";
}

.mdi-comment-quote-outline::before {
  content: "\f1022";
}

.mdi-comment-remove::before {
  content: "\f05de";
}

.mdi-comment-remove-outline::before {
  content: "\f0187";
}

.mdi-comment-search::before {
  content: "\f0a3c";
}

.mdi-comment-search-outline::before {
  content: "\f0a3d";
}

.mdi-comment-text::before {
  content: "\f0188";
}

.mdi-comment-text-multiple::before {
  content: "\f0860";
}

.mdi-comment-text-multiple-outline::before {
  content: "\f0861";
}

.mdi-comment-text-outline::before {
  content: "\f0189";
}

.mdi-compare::before {
  content: "\f018a";
}

.mdi-compare-horizontal::before {
  content: "\f1492";
}

.mdi-compare-remove::before {
  content: "\f18b3";
}

.mdi-compare-vertical::before {
  content: "\f1493";
}

.mdi-compass::before {
  content: "\f018b";
}

.mdi-compass-off::before {
  content: "\f0b80";
}

.mdi-compass-off-outline::before {
  content: "\f0b81";
}

.mdi-compass-outline::before {
  content: "\f018c";
}

.mdi-compass-rose::before {
  content: "\f1382";
}

.mdi-cone::before {
  content: "\f194c";
}

.mdi-cone-off::before {
  content: "\f194d";
}

.mdi-connection::before {
  content: "\f1616";
}

.mdi-console::before {
  content: "\f018d";
}

.mdi-console-line::before {
  content: "\f07b7";
}

.mdi-console-network::before {
  content: "\f08a9";
}

.mdi-console-network-outline::before {
  content: "\f0c60";
}

.mdi-consolidate::before {
  content: "\f10d8";
}

.mdi-contactless-payment::before {
  content: "\f0d6a";
}

.mdi-contactless-payment-circle::before {
  content: "\f0321";
}

.mdi-contactless-payment-circle-outline::before {
  content: "\f0408";
}

.mdi-contacts::before {
  content: "\f06cb";
}

.mdi-contacts-outline::before {
  content: "\f05b8";
}

.mdi-contain::before {
  content: "\f0a3e";
}

.mdi-contain-end::before {
  content: "\f0a3f";
}

.mdi-contain-start::before {
  content: "\f0a40";
}

.mdi-content-copy::before {
  content: "\f018f";
}

.mdi-content-cut::before {
  content: "\f0190";
}

.mdi-content-duplicate::before {
  content: "\f0191";
}

.mdi-content-paste::before {
  content: "\f0192";
}

.mdi-content-save::before {
  content: "\f0193";
}

.mdi-content-save-alert::before {
  content: "\f0f42";
}

.mdi-content-save-alert-outline::before {
  content: "\f0f43";
}

.mdi-content-save-all::before {
  content: "\f0194";
}

.mdi-content-save-all-outline::before {
  content: "\f0f44";
}

.mdi-content-save-check::before {
  content: "\f18ea";
}

.mdi-content-save-check-outline::before {
  content: "\f18eb";
}

.mdi-content-save-cog::before {
  content: "\f145b";
}

.mdi-content-save-cog-outline::before {
  content: "\f145c";
}

.mdi-content-save-edit::before {
  content: "\f0cfb";
}

.mdi-content-save-edit-outline::before {
  content: "\f0cfc";
}

.mdi-content-save-move::before {
  content: "\f0e27";
}

.mdi-content-save-move-outline::before {
  content: "\f0e28";
}

.mdi-content-save-off::before {
  content: "\f1643";
}

.mdi-content-save-off-outline::before {
  content: "\f1644";
}

.mdi-content-save-outline::before {
  content: "\f0818";
}

.mdi-content-save-settings::before {
  content: "\f061b";
}

.mdi-content-save-settings-outline::before {
  content: "\f0b2e";
}

.mdi-contrast::before {
  content: "\f0195";
}

.mdi-contrast-box::before {
  content: "\f0196";
}

.mdi-contrast-circle::before {
  content: "\f0197";
}

.mdi-controller-classic::before {
  content: "\f0b82";
}

.mdi-controller-classic-outline::before {
  content: "\f0b83";
}

.mdi-cookie::before {
  content: "\f0198";
}

.mdi-cookie-alert::before {
  content: "\f16d0";
}

.mdi-cookie-alert-outline::before {
  content: "\f16d1";
}

.mdi-cookie-check::before {
  content: "\f16d2";
}

.mdi-cookie-check-outline::before {
  content: "\f16d3";
}

.mdi-cookie-clock::before {
  content: "\f16e4";
}

.mdi-cookie-clock-outline::before {
  content: "\f16e5";
}

.mdi-cookie-cog::before {
  content: "\f16d4";
}

.mdi-cookie-cog-outline::before {
  content: "\f16d5";
}

.mdi-cookie-edit::before {
  content: "\f16e6";
}

.mdi-cookie-edit-outline::before {
  content: "\f16e7";
}

.mdi-cookie-lock::before {
  content: "\f16e8";
}

.mdi-cookie-lock-outline::before {
  content: "\f16e9";
}

.mdi-cookie-minus::before {
  content: "\f16da";
}

.mdi-cookie-minus-outline::before {
  content: "\f16db";
}

.mdi-cookie-off::before {
  content: "\f16ea";
}

.mdi-cookie-off-outline::before {
  content: "\f16eb";
}

.mdi-cookie-outline::before {
  content: "\f16de";
}

.mdi-cookie-plus::before {
  content: "\f16d6";
}

.mdi-cookie-plus-outline::before {
  content: "\f16d7";
}

.mdi-cookie-refresh::before {
  content: "\f16ec";
}

.mdi-cookie-refresh-outline::before {
  content: "\f16ed";
}

.mdi-cookie-remove::before {
  content: "\f16d8";
}

.mdi-cookie-remove-outline::before {
  content: "\f16d9";
}

.mdi-cookie-settings::before {
  content: "\f16dc";
}

.mdi-cookie-settings-outline::before {
  content: "\f16dd";
}

.mdi-coolant-temperature::before {
  content: "\f03c8";
}

.mdi-copyleft::before {
  content: "\f1939";
}

.mdi-copyright::before {
  content: "\f05e6";
}

.mdi-cordova::before {
  content: "\f0958";
}

.mdi-corn::before {
  content: "\f07b8";
}

.mdi-corn-off::before {
  content: "\f13ef";
}

.mdi-cosine-wave::before {
  content: "\f1479";
}

.mdi-counter::before {
  content: "\f0199";
}

.mdi-countertop::before {
  content: "\f181c";
}

.mdi-countertop-outline::before {
  content: "\f181d";
}

.mdi-cow::before {
  content: "\f019a";
}

.mdi-cow-off::before {
  content: "\f18fc";
}

.mdi-cpu-32-bit::before {
  content: "\f0edf";
}

.mdi-cpu-64-bit::before {
  content: "\f0ee0";
}

.mdi-cradle::before {
  content: "\f198b";
}

.mdi-cradle-outline::before {
  content: "\f1991";
}

.mdi-crane::before {
  content: "\f0862";
}

.mdi-creation::before {
  content: "\f0674";
}

.mdi-creative-commons::before {
  content: "\f0d6b";
}

.mdi-credit-card::before {
  content: "\f0fef";
}

.mdi-credit-card-check::before {
  content: "\f13d0";
}

.mdi-credit-card-check-outline::before {
  content: "\f13d1";
}

.mdi-credit-card-chip::before {
  content: "\f190f";
}

.mdi-credit-card-chip-outline::before {
  content: "\f1910";
}

.mdi-credit-card-clock::before {
  content: "\f0ee1";
}

.mdi-credit-card-clock-outline::before {
  content: "\f0ee2";
}

.mdi-credit-card-edit::before {
  content: "\f17d7";
}

.mdi-credit-card-edit-outline::before {
  content: "\f17d8";
}

.mdi-credit-card-fast::before {
  content: "\f1911";
}

.mdi-credit-card-fast-outline::before {
  content: "\f1912";
}

.mdi-credit-card-lock::before {
  content: "\f18e7";
}

.mdi-credit-card-lock-outline::before {
  content: "\f18e8";
}

.mdi-credit-card-marker::before {
  content: "\f06a8";
}

.mdi-credit-card-marker-outline::before {
  content: "\f0dbe";
}

.mdi-credit-card-minus::before {
  content: "\f0fac";
}

.mdi-credit-card-minus-outline::before {
  content: "\f0fad";
}

.mdi-credit-card-multiple::before {
  content: "\f0ff0";
}

.mdi-credit-card-multiple-outline::before {
  content: "\f019c";
}

.mdi-credit-card-off::before {
  content: "\f0ff1";
}

.mdi-credit-card-off-outline::before {
  content: "\f05e4";
}

.mdi-credit-card-outline::before {
  content: "\f019b";
}

.mdi-credit-card-plus::before {
  content: "\f0ff2";
}

.mdi-credit-card-plus-outline::before {
  content: "\f0676";
}

.mdi-credit-card-refresh::before {
  content: "\f1645";
}

.mdi-credit-card-refresh-outline::before {
  content: "\f1646";
}

.mdi-credit-card-refund::before {
  content: "\f0ff3";
}

.mdi-credit-card-refund-outline::before {
  content: "\f0aa8";
}

.mdi-credit-card-remove::before {
  content: "\f0fae";
}

.mdi-credit-card-remove-outline::before {
  content: "\f0faf";
}

.mdi-credit-card-scan::before {
  content: "\f0ff4";
}

.mdi-credit-card-scan-outline::before {
  content: "\f019d";
}

.mdi-credit-card-search::before {
  content: "\f1647";
}

.mdi-credit-card-search-outline::before {
  content: "\f1648";
}

.mdi-credit-card-settings::before {
  content: "\f0ff5";
}

.mdi-credit-card-settings-outline::before {
  content: "\f08d7";
}

.mdi-credit-card-sync::before {
  content: "\f1649";
}

.mdi-credit-card-sync-outline::before {
  content: "\f164a";
}

.mdi-credit-card-wireless::before {
  content: "\f0802";
}

.mdi-credit-card-wireless-off::before {
  content: "\f057a";
}

.mdi-credit-card-wireless-off-outline::before {
  content: "\f057b";
}

.mdi-credit-card-wireless-outline::before {
  content: "\f0d6c";
}

.mdi-cricket::before {
  content: "\f0d6d";
}

.mdi-crop::before {
  content: "\f019e";
}

.mdi-crop-free::before {
  content: "\f019f";
}

.mdi-crop-landscape::before {
  content: "\f01a0";
}

.mdi-crop-portrait::before {
  content: "\f01a1";
}

.mdi-crop-rotate::before {
  content: "\f0696";
}

.mdi-crop-square::before {
  content: "\f01a2";
}

.mdi-cross::before {
  content: "\f0953";
}

.mdi-cross-bolnisi::before {
  content: "\f0ced";
}

.mdi-cross-celtic::before {
  content: "\f0cf5";
}

.mdi-cross-outline::before {
  content: "\f0cf6";
}

.mdi-crosshairs::before {
  content: "\f01a3";
}

.mdi-crosshairs-gps::before {
  content: "\f01a4";
}

.mdi-crosshairs-off::before {
  content: "\f0f45";
}

.mdi-crosshairs-question::before {
  content: "\f1136";
}

.mdi-crowd::before {
  content: "\f1975";
}

.mdi-crown::before {
  content: "\f01a5";
}

.mdi-crown-circle::before {
  content: "\f17dc";
}

.mdi-crown-circle-outline::before {
  content: "\f17dd";
}

.mdi-crown-outline::before {
  content: "\f11d0";
}

.mdi-cryengine::before {
  content: "\f0959";
}

.mdi-crystal-ball::before {
  content: "\f0b2f";
}

.mdi-cube::before {
  content: "\f01a6";
}

.mdi-cube-off::before {
  content: "\f141c";
}

.mdi-cube-off-outline::before {
  content: "\f141d";
}

.mdi-cube-outline::before {
  content: "\f01a7";
}

.mdi-cube-scan::before {
  content: "\f0b84";
}

.mdi-cube-send::before {
  content: "\f01a8";
}

.mdi-cube-unfolded::before {
  content: "\f01a9";
}

.mdi-cup::before {
  content: "\f01aa";
}

.mdi-cup-off::before {
  content: "\f05e5";
}

.mdi-cup-off-outline::before {
  content: "\f137d";
}

.mdi-cup-outline::before {
  content: "\f130f";
}

.mdi-cup-water::before {
  content: "\f01ab";
}

.mdi-cupboard::before {
  content: "\f0f46";
}

.mdi-cupboard-outline::before {
  content: "\f0f47";
}

.mdi-cupcake::before {
  content: "\f095a";
}

.mdi-curling::before {
  content: "\f0863";
}

.mdi-currency-bdt::before {
  content: "\f0864";
}

.mdi-currency-brl::before {
  content: "\f0b85";
}

.mdi-currency-btc::before {
  content: "\f01ac";
}

.mdi-currency-cny::before {
  content: "\f07ba";
}

.mdi-currency-eth::before {
  content: "\f07bb";
}

.mdi-currency-eur::before {
  content: "\f01ad";
}

.mdi-currency-eur-off::before {
  content: "\f1315";
}

.mdi-currency-gbp::before {
  content: "\f01ae";
}

.mdi-currency-ils::before {
  content: "\f0c61";
}

.mdi-currency-inr::before {
  content: "\f01af";
}

.mdi-currency-jpy::before {
  content: "\f07bc";
}

.mdi-currency-krw::before {
  content: "\f07bd";
}

.mdi-currency-kzt::before {
  content: "\f0865";
}

.mdi-currency-mnt::before {
  content: "\f1512";
}

.mdi-currency-ngn::before {
  content: "\f01b0";
}

.mdi-currency-php::before {
  content: "\f09e6";
}

.mdi-currency-rial::before {
  content: "\f0e9c";
}

.mdi-currency-rub::before {
  content: "\f01b1";
}

.mdi-currency-rupee::before {
  content: "\f1976";
}

.mdi-currency-sign::before {
  content: "\f07be";
}

.mdi-currency-try::before {
  content: "\f01b2";
}

.mdi-currency-twd::before {
  content: "\f07bf";
}

.mdi-currency-usd::before {
  content: "\f01c1";
}

.mdi-currency-usd-off::before {
  content: "\f067a";
}

.mdi-current-ac::before {
  content: "\f1480";
}

.mdi-current-dc::before {
  content: "\f095c";
}

.mdi-cursor-default::before {
  content: "\f01c0";
}

.mdi-cursor-default-click::before {
  content: "\f0cfd";
}

.mdi-cursor-default-click-outline::before {
  content: "\f0cfe";
}

.mdi-cursor-default-gesture::before {
  content: "\f1127";
}

.mdi-cursor-default-gesture-outline::before {
  content: "\f1128";
}

.mdi-cursor-default-outline::before {
  content: "\f01bf";
}

.mdi-cursor-move::before {
  content: "\f01be";
}

.mdi-cursor-pointer::before {
  content: "\f01bd";
}

.mdi-cursor-text::before {
  content: "\f05e7";
}

.mdi-curtains::before {
  content: "\f1846";
}

.mdi-curtains-closed::before {
  content: "\f1847";
}

.mdi-cylinder::before {
  content: "\f194e";
}

.mdi-cylinder-off::before {
  content: "\f194f";
}

.mdi-dance-ballroom::before {
  content: "\f15fb";
}

.mdi-dance-pole::before {
  content: "\f1578";
}

.mdi-data-matrix::before {
  content: "\f153c";
}

.mdi-data-matrix-edit::before {
  content: "\f153d";
}

.mdi-data-matrix-minus::before {
  content: "\f153e";
}

.mdi-data-matrix-plus::before {
  content: "\f153f";
}

.mdi-data-matrix-remove::before {
  content: "\f1540";
}

.mdi-data-matrix-scan::before {
  content: "\f1541";
}

.mdi-database::before {
  content: "\f01bc";
}

.mdi-database-alert::before {
  content: "\f163a";
}

.mdi-database-alert-outline::before {
  content: "\f1624";
}

.mdi-database-arrow-down::before {
  content: "\f163b";
}

.mdi-database-arrow-down-outline::before {
  content: "\f1625";
}

.mdi-database-arrow-left::before {
  content: "\f163c";
}

.mdi-database-arrow-left-outline::before {
  content: "\f1626";
}

.mdi-database-arrow-right::before {
  content: "\f163d";
}

.mdi-database-arrow-right-outline::before {
  content: "\f1627";
}

.mdi-database-arrow-up::before {
  content: "\f163e";
}

.mdi-database-arrow-up-outline::before {
  content: "\f1628";
}

.mdi-database-check::before {
  content: "\f0aa9";
}

.mdi-database-check-outline::before {
  content: "\f1629";
}

.mdi-database-clock::before {
  content: "\f163f";
}

.mdi-database-clock-outline::before {
  content: "\f162a";
}

.mdi-database-cog::before {
  content: "\f164b";
}

.mdi-database-cog-outline::before {
  content: "\f164c";
}

.mdi-database-edit::before {
  content: "\f0b86";
}

.mdi-database-edit-outline::before {
  content: "\f162b";
}

.mdi-database-export::before {
  content: "\f095e";
}

.mdi-database-export-outline::before {
  content: "\f162c";
}

.mdi-database-eye::before {
  content: "\f191f";
}

.mdi-database-eye-off::before {
  content: "\f1920";
}

.mdi-database-eye-off-outline::before {
  content: "\f1921";
}

.mdi-database-eye-outline::before {
  content: "\f1922";
}

.mdi-database-import::before {
  content: "\f095d";
}

.mdi-database-import-outline::before {
  content: "\f162d";
}

.mdi-database-lock::before {
  content: "\f0aaa";
}

.mdi-database-lock-outline::before {
  content: "\f162e";
}

.mdi-database-marker::before {
  content: "\f12f6";
}

.mdi-database-marker-outline::before {
  content: "\f162f";
}

.mdi-database-minus::before {
  content: "\f01bb";
}

.mdi-database-minus-outline::before {
  content: "\f1630";
}

.mdi-database-off::before {
  content: "\f1640";
}

.mdi-database-off-outline::before {
  content: "\f1631";
}

.mdi-database-outline::before {
  content: "\f1632";
}

.mdi-database-plus::before {
  content: "\f01ba";
}

.mdi-database-plus-outline::before {
  content: "\f1633";
}

.mdi-database-refresh::before {
  content: "\f05c2";
}

.mdi-database-refresh-outline::before {
  content: "\f1634";
}

.mdi-database-remove::before {
  content: "\f0d00";
}

.mdi-database-remove-outline::before {
  content: "\f1635";
}

.mdi-database-search::before {
  content: "\f0866";
}

.mdi-database-search-outline::before {
  content: "\f1636";
}

.mdi-database-settings::before {
  content: "\f0d01";
}

.mdi-database-settings-outline::before {
  content: "\f1637";
}

.mdi-database-sync::before {
  content: "\f0cff";
}

.mdi-database-sync-outline::before {
  content: "\f1638";
}

.mdi-death-star::before {
  content: "\f08d8";
}

.mdi-death-star-variant::before {
  content: "\f08d9";
}

.mdi-deathly-hallows::before {
  content: "\f0b87";
}

.mdi-debian::before {
  content: "\f08da";
}

.mdi-debug-step-into::before {
  content: "\f01b9";
}

.mdi-debug-step-out::before {
  content: "\f01b8";
}

.mdi-debug-step-over::before {
  content: "\f01b7";
}

.mdi-decagram::before {
  content: "\f076c";
}

.mdi-decagram-outline::before {
  content: "\f076d";
}

.mdi-decimal::before {
  content: "\f10a1";
}

.mdi-decimal-comma::before {
  content: "\f10a2";
}

.mdi-decimal-comma-decrease::before {
  content: "\f10a3";
}

.mdi-decimal-comma-increase::before {
  content: "\f10a4";
}

.mdi-decimal-decrease::before {
  content: "\f01b6";
}

.mdi-decimal-increase::before {
  content: "\f01b5";
}

.mdi-delete::before {
  content: "\f01b4";
}

.mdi-delete-alert::before {
  content: "\f10a5";
}

.mdi-delete-alert-outline::before {
  content: "\f10a6";
}

.mdi-delete-circle::before {
  content: "\f0683";
}

.mdi-delete-circle-outline::before {
  content: "\f0b88";
}

.mdi-delete-clock::before {
  content: "\f1556";
}

.mdi-delete-clock-outline::before {
  content: "\f1557";
}

.mdi-delete-empty::before {
  content: "\f06cc";
}

.mdi-delete-empty-outline::before {
  content: "\f0e9d";
}

.mdi-delete-forever::before {
  content: "\f05e8";
}

.mdi-delete-forever-outline::before {
  content: "\f0b89";
}

.mdi-delete-off::before {
  content: "\f10a7";
}

.mdi-delete-off-outline::before {
  content: "\f10a8";
}

.mdi-delete-outline::before {
  content: "\f09e7";
}

.mdi-delete-restore::before {
  content: "\f0819";
}

.mdi-delete-sweep::before {
  content: "\f05e9";
}

.mdi-delete-sweep-outline::before {
  content: "\f0c62";
}

.mdi-delete-variant::before {
  content: "\f01b3";
}

.mdi-delta::before {
  content: "\f01c2";
}

.mdi-desk::before {
  content: "\f1239";
}

.mdi-desk-lamp::before {
  content: "\f095f";
}

.mdi-deskphone::before {
  content: "\f01c3";
}

.mdi-desktop-classic::before {
  content: "\f07c0";
}

.mdi-desktop-mac::before {
  content: "\f01c4";
}

.mdi-desktop-mac-dashboard::before {
  content: "\f09e8";
}

.mdi-desktop-tower::before {
  content: "\f01c5";
}

.mdi-desktop-tower-monitor::before {
  content: "\f0aab";
}

.mdi-details::before {
  content: "\f01c6";
}

.mdi-dev-to::before {
  content: "\f0d6e";
}

.mdi-developer-board::before {
  content: "\f0697";
}

.mdi-deviantart::before {
  content: "\f01c7";
}

.mdi-devices::before {
  content: "\f0fb0";
}

.mdi-dharmachakra::before {
  content: "\f094b";
}

.mdi-diabetes::before {
  content: "\f1126";
}

.mdi-dialpad::before {
  content: "\f061c";
}

.mdi-diameter::before {
  content: "\f0c63";
}

.mdi-diameter-outline::before {
  content: "\f0c64";
}

.mdi-diameter-variant::before {
  content: "\f0c65";
}

.mdi-diamond::before {
  content: "\f0b8a";
}

.mdi-diamond-outline::before {
  content: "\f0b8b";
}

.mdi-diamond-stone::before {
  content: "\f01c8";
}

.mdi-dice-1::before {
  content: "\f01ca";
}

.mdi-dice-1-outline::before {
  content: "\f114a";
}

.mdi-dice-2::before {
  content: "\f01cb";
}

.mdi-dice-2-outline::before {
  content: "\f114b";
}

.mdi-dice-3::before {
  content: "\f01cc";
}

.mdi-dice-3-outline::before {
  content: "\f114c";
}

.mdi-dice-4::before {
  content: "\f01cd";
}

.mdi-dice-4-outline::before {
  content: "\f114d";
}

.mdi-dice-5::before {
  content: "\f01ce";
}

.mdi-dice-5-outline::before {
  content: "\f114e";
}

.mdi-dice-6::before {
  content: "\f01cf";
}

.mdi-dice-6-outline::before {
  content: "\f114f";
}

.mdi-dice-d10::before {
  content: "\f1153";
}

.mdi-dice-d10-outline::before {
  content: "\f076f";
}

.mdi-dice-d12::before {
  content: "\f1154";
}

.mdi-dice-d12-outline::before {
  content: "\f0867";
}

.mdi-dice-d20::before {
  content: "\f1155";
}

.mdi-dice-d20-outline::before {
  content: "\f05ea";
}

.mdi-dice-d4::before {
  content: "\f1150";
}

.mdi-dice-d4-outline::before {
  content: "\f05eb";
}

.mdi-dice-d6::before {
  content: "\f1151";
}

.mdi-dice-d6-outline::before {
  content: "\f05ed";
}

.mdi-dice-d8::before {
  content: "\f1152";
}

.mdi-dice-d8-outline::before {
  content: "\f05ec";
}

.mdi-dice-multiple::before {
  content: "\f076e";
}

.mdi-dice-multiple-outline::before {
  content: "\f1156";
}

.mdi-digital-ocean::before {
  content: "\f1237";
}

.mdi-dip-switch::before {
  content: "\f07c1";
}

.mdi-directions::before {
  content: "\f01d0";
}

.mdi-directions-fork::before {
  content: "\f0641";
}

.mdi-disc::before {
  content: "\f05ee";
}

.mdi-disc-alert::before {
  content: "\f01d1";
}

.mdi-disc-player::before {
  content: "\f0960";
}

.mdi-discord::before {
  content: "\f066f";
}

.mdi-dishwasher::before {
  content: "\f0aac";
}

.mdi-dishwasher-alert::before {
  content: "\f11b8";
}

.mdi-dishwasher-off::before {
  content: "\f11b9";
}

.mdi-disqus::before {
  content: "\f01d2";
}

.mdi-distribute-horizontal-center::before {
  content: "\f11c9";
}

.mdi-distribute-horizontal-left::before {
  content: "\f11c8";
}

.mdi-distribute-horizontal-right::before {
  content: "\f11ca";
}

.mdi-distribute-vertical-bottom::before {
  content: "\f11cb";
}

.mdi-distribute-vertical-center::before {
  content: "\f11cc";
}

.mdi-distribute-vertical-top::before {
  content: "\f11cd";
}

.mdi-diversify::before {
  content: "\f1877";
}

.mdi-diving::before {
  content: "\f1977";
}

.mdi-diving-flippers::before {
  content: "\f0dbf";
}

.mdi-diving-helmet::before {
  content: "\f0dc0";
}

.mdi-diving-scuba::before {
  content: "\f0dc1";
}

.mdi-diving-scuba-flag::before {
  content: "\f0dc2";
}

.mdi-diving-scuba-tank::before {
  content: "\f0dc3";
}

.mdi-diving-scuba-tank-multiple::before {
  content: "\f0dc4";
}

.mdi-diving-snorkel::before {
  content: "\f0dc5";
}

.mdi-division::before {
  content: "\f01d4";
}

.mdi-division-box::before {
  content: "\f01d5";
}

.mdi-dlna::before {
  content: "\f0a41";
}

.mdi-dna::before {
  content: "\f0684";
}

.mdi-dns::before {
  content: "\f01d6";
}

.mdi-dns-outline::before {
  content: "\f0b8c";
}

.mdi-dock-bottom::before {
  content: "\f10a9";
}

.mdi-dock-left::before {
  content: "\f10aa";
}

.mdi-dock-right::before {
  content: "\f10ab";
}

.mdi-dock-top::before {
  content: "\f1513";
}

.mdi-dock-window::before {
  content: "\f10ac";
}

.mdi-docker::before {
  content: "\f0868";
}

.mdi-doctor::before {
  content: "\f0a42";
}

.mdi-dog::before {
  content: "\f0a43";
}

.mdi-dog-service::before {
  content: "\f0aad";
}

.mdi-dog-side::before {
  content: "\f0a44";
}

.mdi-dog-side-off::before {
  content: "\f16ee";
}

.mdi-dolby::before {
  content: "\f06b3";
}

.mdi-dolly::before {
  content: "\f0e9e";
}

.mdi-dolphin::before {
  content: "\f18b4";
}

.mdi-domain::before {
  content: "\f01d7";
}

.mdi-domain-off::before {
  content: "\f0d6f";
}

.mdi-domain-plus::before {
  content: "\f10ad";
}

.mdi-domain-remove::before {
  content: "\f10ae";
}

.mdi-dome-light::before {
  content: "\f141e";
}

.mdi-domino-mask::before {
  content: "\f1023";
}

.mdi-donkey::before {
  content: "\f07c2";
}

.mdi-door::before {
  content: "\f081a";
}

.mdi-door-closed::before {
  content: "\f081b";
}

.mdi-door-closed-lock::before {
  content: "\f10af";
}

.mdi-door-open::before {
  content: "\f081c";
}

.mdi-door-sliding::before {
  content: "\f181e";
}

.mdi-door-sliding-lock::before {
  content: "\f181f";
}

.mdi-door-sliding-open::before {
  content: "\f1820";
}

.mdi-doorbell::before {
  content: "\f12e6";
}

.mdi-doorbell-video::before {
  content: "\f0869";
}

.mdi-dot-net::before {
  content: "\f0aae";
}

.mdi-dots-circle::before {
  content: "\f1978";
}

.mdi-dots-grid::before {
  content: "\f15fc";
}

.mdi-dots-hexagon::before {
  content: "\f15ff";
}

.mdi-dots-horizontal::before {
  content: "\f01d8";
}

.mdi-dots-horizontal-circle::before {
  content: "\f07c3";
}

.mdi-dots-horizontal-circle-outline::before {
  content: "\f0b8d";
}

.mdi-dots-square::before {
  content: "\f15fd";
}

.mdi-dots-triangle::before {
  content: "\f15fe";
}

.mdi-dots-vertical::before {
  content: "\f01d9";
}

.mdi-dots-vertical-circle::before {
  content: "\f07c4";
}

.mdi-dots-vertical-circle-outline::before {
  content: "\f0b8e";
}

.mdi-download::before {
  content: "\f01da";
}

.mdi-download-box::before {
  content: "\f1462";
}

.mdi-download-box-outline::before {
  content: "\f1463";
}

.mdi-download-circle::before {
  content: "\f1464";
}

.mdi-download-circle-outline::before {
  content: "\f1465";
}

.mdi-download-lock::before {
  content: "\f1320";
}

.mdi-download-lock-outline::before {
  content: "\f1321";
}

.mdi-download-multiple::before {
  content: "\f09e9";
}

.mdi-download-network::before {
  content: "\f06f4";
}

.mdi-download-network-outline::before {
  content: "\f0c66";
}

.mdi-download-off::before {
  content: "\f10b0";
}

.mdi-download-off-outline::before {
  content: "\f10b1";
}

.mdi-download-outline::before {
  content: "\f0b8f";
}

.mdi-drag::before {
  content: "\f01db";
}

.mdi-drag-horizontal::before {
  content: "\f01dc";
}

.mdi-drag-horizontal-variant::before {
  content: "\f12f0";
}

.mdi-drag-variant::before {
  content: "\f0b90";
}

.mdi-drag-vertical::before {
  content: "\f01dd";
}

.mdi-drag-vertical-variant::before {
  content: "\f12f1";
}

.mdi-drama-masks::before {
  content: "\f0d02";
}

.mdi-draw::before {
  content: "\f0f49";
}

.mdi-draw-pen::before {
  content: "\f19b9";
}

.mdi-drawing::before {
  content: "\f01de";
}

.mdi-drawing-box::before {
  content: "\f01df";
}

.mdi-dresser::before {
  content: "\f0f4a";
}

.mdi-dresser-outline::before {
  content: "\f0f4b";
}

.mdi-drone::before {
  content: "\f01e2";
}

.mdi-dropbox::before {
  content: "\f01e3";
}

.mdi-drupal::before {
  content: "\f01e4";
}

.mdi-duck::before {
  content: "\f01e5";
}

.mdi-dumbbell::before {
  content: "\f01e6";
}

.mdi-dump-truck::before {
  content: "\f0c67";
}

.mdi-ear-hearing::before {
  content: "\f07c5";
}

.mdi-ear-hearing-off::before {
  content: "\f0a45";
}

.mdi-earbuds::before {
  content: "\f184f";
}

.mdi-earbuds-off::before {
  content: "\f1850";
}

.mdi-earbuds-off-outline::before {
  content: "\f1851";
}

.mdi-earbuds-outline::before {
  content: "\f1852";
}

.mdi-earth::before {
  content: "\f01e7";
}

.mdi-earth-arrow-right::before {
  content: "\f1311";
}

.mdi-earth-box::before {
  content: "\f06cd";
}

.mdi-earth-box-minus::before {
  content: "\f1407";
}

.mdi-earth-box-off::before {
  content: "\f06ce";
}

.mdi-earth-box-plus::before {
  content: "\f1406";
}

.mdi-earth-box-remove::before {
  content: "\f1408";
}

.mdi-earth-minus::before {
  content: "\f1404";
}

.mdi-earth-off::before {
  content: "\f01e8";
}

.mdi-earth-plus::before {
  content: "\f1403";
}

.mdi-earth-remove::before {
  content: "\f1405";
}

.mdi-egg::before {
  content: "\f0aaf";
}

.mdi-egg-easter::before {
  content: "\f0ab0";
}

.mdi-egg-fried::before {
  content: "\f184a";
}

.mdi-egg-off::before {
  content: "\f13f0";
}

.mdi-egg-off-outline::before {
  content: "\f13f1";
}

.mdi-egg-outline::before {
  content: "\f13f2";
}

.mdi-eiffel-tower::before {
  content: "\f156b";
}

.mdi-eight-track::before {
  content: "\f09ea";
}

.mdi-eject::before {
  content: "\f01ea";
}

.mdi-eject-outline::before {
  content: "\f0b91";
}

.mdi-electric-switch::before {
  content: "\f0e9f";
}

.mdi-electric-switch-closed::before {
  content: "\f10d9";
}

.mdi-electron-framework::before {
  content: "\f1024";
}

.mdi-elephant::before {
  content: "\f07c6";
}

.mdi-elevation-decline::before {
  content: "\f01eb";
}

.mdi-elevation-rise::before {
  content: "\f01ec";
}

.mdi-elevator::before {
  content: "\f01ed";
}

.mdi-elevator-down::before {
  content: "\f12c2";
}

.mdi-elevator-passenger::before {
  content: "\f1381";
}

.mdi-elevator-passenger-off::before {
  content: "\f1979";
}

.mdi-elevator-passenger-off-outline::before {
  content: "\f197a";
}

.mdi-elevator-passenger-outline::before {
  content: "\f197b";
}

.mdi-elevator-up::before {
  content: "\f12c1";
}

.mdi-ellipse::before {
  content: "\f0ea0";
}

.mdi-ellipse-outline::before {
  content: "\f0ea1";
}

.mdi-email::before {
  content: "\f01ee";
}

.mdi-email-alert::before {
  content: "\f06cf";
}

.mdi-email-alert-outline::before {
  content: "\f0d42";
}

.mdi-email-box::before {
  content: "\f0d03";
}

.mdi-email-check::before {
  content: "\f0ab1";
}

.mdi-email-check-outline::before {
  content: "\f0ab2";
}

.mdi-email-edit::before {
  content: "\f0ee3";
}

.mdi-email-edit-outline::before {
  content: "\f0ee4";
}

.mdi-email-fast::before {
  content: "\f186f";
}

.mdi-email-fast-outline::before {
  content: "\f1870";
}

.mdi-email-lock::before {
  content: "\f01f1";
}

.mdi-email-mark-as-unread::before {
  content: "\f0b92";
}

.mdi-email-minus::before {
  content: "\f0ee5";
}

.mdi-email-minus-outline::before {
  content: "\f0ee6";
}

.mdi-email-multiple::before {
  content: "\f0ee7";
}

.mdi-email-multiple-outline::before {
  content: "\f0ee8";
}

.mdi-email-newsletter::before {
  content: "\f0fb1";
}

.mdi-email-off::before {
  content: "\f13e3";
}

.mdi-email-off-outline::before {
  content: "\f13e4";
}

.mdi-email-open::before {
  content: "\f01ef";
}

.mdi-email-open-multiple::before {
  content: "\f0ee9";
}

.mdi-email-open-multiple-outline::before {
  content: "\f0eea";
}

.mdi-email-open-outline::before {
  content: "\f05ef";
}

.mdi-email-outline::before {
  content: "\f01f0";
}

.mdi-email-plus::before {
  content: "\f09eb";
}

.mdi-email-plus-outline::before {
  content: "\f09ec";
}

.mdi-email-receive::before {
  content: "\f10da";
}

.mdi-email-receive-outline::before {
  content: "\f10db";
}

.mdi-email-remove::before {
  content: "\f1661";
}

.mdi-email-remove-outline::before {
  content: "\f1662";
}

.mdi-email-seal::before {
  content: "\f195b";
}

.mdi-email-seal-outline::before {
  content: "\f195c";
}

.mdi-email-search::before {
  content: "\f0961";
}

.mdi-email-search-outline::before {
  content: "\f0962";
}

.mdi-email-send::before {
  content: "\f10dc";
}

.mdi-email-send-outline::before {
  content: "\f10dd";
}

.mdi-email-sync::before {
  content: "\f12c7";
}

.mdi-email-sync-outline::before {
  content: "\f12c8";
}

.mdi-email-variant::before {
  content: "\f05f0";
}

.mdi-ember::before {
  content: "\f0b30";
}

.mdi-emby::before {
  content: "\f06b4";
}

.mdi-emoticon::before {
  content: "\f0c68";
}

.mdi-emoticon-angry::before {
  content: "\f0c69";
}

.mdi-emoticon-angry-outline::before {
  content: "\f0c6a";
}

.mdi-emoticon-confused::before {
  content: "\f10de";
}

.mdi-emoticon-confused-outline::before {
  content: "\f10df";
}

.mdi-emoticon-cool::before {
  content: "\f0c6b";
}

.mdi-emoticon-cool-outline::before {
  content: "\f01f3";
}

.mdi-emoticon-cry::before {
  content: "\f0c6c";
}

.mdi-emoticon-cry-outline::before {
  content: "\f0c6d";
}

.mdi-emoticon-dead::before {
  content: "\f0c6e";
}

.mdi-emoticon-dead-outline::before {
  content: "\f069b";
}

.mdi-emoticon-devil::before {
  content: "\f0c6f";
}

.mdi-emoticon-devil-outline::before {
  content: "\f01f4";
}

.mdi-emoticon-excited::before {
  content: "\f0c70";
}

.mdi-emoticon-excited-outline::before {
  content: "\f069c";
}

.mdi-emoticon-frown::before {
  content: "\f0f4c";
}

.mdi-emoticon-frown-outline::before {
  content: "\f0f4d";
}

.mdi-emoticon-happy::before {
  content: "\f0c71";
}

.mdi-emoticon-happy-outline::before {
  content: "\f01f5";
}

.mdi-emoticon-kiss::before {
  content: "\f0c72";
}

.mdi-emoticon-kiss-outline::before {
  content: "\f0c73";
}

.mdi-emoticon-lol::before {
  content: "\f1214";
}

.mdi-emoticon-lol-outline::before {
  content: "\f1215";
}

.mdi-emoticon-neutral::before {
  content: "\f0c74";
}

.mdi-emoticon-neutral-outline::before {
  content: "\f01f6";
}

.mdi-emoticon-outline::before {
  content: "\f01f2";
}

.mdi-emoticon-poop::before {
  content: "\f01f7";
}

.mdi-emoticon-poop-outline::before {
  content: "\f0c75";
}

.mdi-emoticon-sad::before {
  content: "\f0c76";
}

.mdi-emoticon-sad-outline::before {
  content: "\f01f8";
}

.mdi-emoticon-sick::before {
  content: "\f157c";
}

.mdi-emoticon-sick-outline::before {
  content: "\f157d";
}

.mdi-emoticon-tongue::before {
  content: "\f01f9";
}

.mdi-emoticon-tongue-outline::before {
  content: "\f0c77";
}

.mdi-emoticon-wink::before {
  content: "\f0c78";
}

.mdi-emoticon-wink-outline::before {
  content: "\f0c79";
}

.mdi-engine::before {
  content: "\f01fa";
}

.mdi-engine-off::before {
  content: "\f0a46";
}

.mdi-engine-off-outline::before {
  content: "\f0a47";
}

.mdi-engine-outline::before {
  content: "\f01fb";
}

.mdi-epsilon::before {
  content: "\f10e0";
}

.mdi-equal::before {
  content: "\f01fc";
}

.mdi-equal-box::before {
  content: "\f01fd";
}

.mdi-equalizer::before {
  content: "\f0ea2";
}

.mdi-equalizer-outline::before {
  content: "\f0ea3";
}

.mdi-eraser::before {
  content: "\f01fe";
}

.mdi-eraser-variant::before {
  content: "\f0642";
}

.mdi-escalator::before {
  content: "\f01ff";
}

.mdi-escalator-box::before {
  content: "\f1399";
}

.mdi-escalator-down::before {
  content: "\f12c0";
}

.mdi-escalator-up::before {
  content: "\f12bf";
}

.mdi-eslint::before {
  content: "\f0c7a";
}

.mdi-et::before {
  content: "\f0ab3";
}

.mdi-ethereum::before {
  content: "\f086a";
}

.mdi-ethernet::before {
  content: "\f0200";
}

.mdi-ethernet-cable::before {
  content: "\f0201";
}

.mdi-ethernet-cable-off::before {
  content: "\f0202";
}

.mdi-ev-plug-ccs1::before {
  content: "\f1519";
}

.mdi-ev-plug-ccs2::before {
  content: "\f151a";
}

.mdi-ev-plug-chademo::before {
  content: "\f151b";
}

.mdi-ev-plug-tesla::before {
  content: "\f151c";
}

.mdi-ev-plug-type1::before {
  content: "\f151d";
}

.mdi-ev-plug-type2::before {
  content: "\f151e";
}

.mdi-ev-station::before {
  content: "\f05f1";
}

.mdi-evernote::before {
  content: "\f0204";
}

.mdi-excavator::before {
  content: "\f1025";
}

.mdi-exclamation::before {
  content: "\f0205";
}

.mdi-exclamation-thick::before {
  content: "\f1238";
}

.mdi-exit-run::before {
  content: "\f0a48";
}

.mdi-exit-to-app::before {
  content: "\f0206";
}

.mdi-expand-all::before {
  content: "\f0ab4";
}

.mdi-expand-all-outline::before {
  content: "\f0ab5";
}

.mdi-expansion-card::before {
  content: "\f08ae";
}

.mdi-expansion-card-variant::before {
  content: "\f0fb2";
}

.mdi-exponent::before {
  content: "\f0963";
}

.mdi-exponent-box::before {
  content: "\f0964";
}

.mdi-export::before {
  content: "\f0207";
}

.mdi-export-variant::before {
  content: "\f0b93";
}

.mdi-eye::before {
  content: "\f0208";
}

.mdi-eye-arrow-left::before {
  content: "\f18fd";
}

.mdi-eye-arrow-left-outline::before {
  content: "\f18fe";
}

.mdi-eye-arrow-right::before {
  content: "\f18ff";
}

.mdi-eye-arrow-right-outline::before {
  content: "\f1900";
}

.mdi-eye-check::before {
  content: "\f0d04";
}

.mdi-eye-check-outline::before {
  content: "\f0d05";
}

.mdi-eye-circle::before {
  content: "\f0b94";
}

.mdi-eye-circle-outline::before {
  content: "\f0b95";
}

.mdi-eye-minus::before {
  content: "\f1026";
}

.mdi-eye-minus-outline::before {
  content: "\f1027";
}

.mdi-eye-off::before {
  content: "\f0209";
}

.mdi-eye-off-outline::before {
  content: "\f06d1";
}

.mdi-eye-outline::before {
  content: "\f06d0";
}

.mdi-eye-plus::before {
  content: "\f086b";
}

.mdi-eye-plus-outline::before {
  content: "\f086c";
}

.mdi-eye-refresh::before {
  content: "\f197c";
}

.mdi-eye-refresh-outline::before {
  content: "\f197d";
}

.mdi-eye-remove::before {
  content: "\f15e3";
}

.mdi-eye-remove-outline::before {
  content: "\f15e4";
}

.mdi-eye-settings::before {
  content: "\f086d";
}

.mdi-eye-settings-outline::before {
  content: "\f086e";
}

.mdi-eyedropper::before {
  content: "\f020a";
}

.mdi-eyedropper-minus::before {
  content: "\f13dd";
}

.mdi-eyedropper-off::before {
  content: "\f13df";
}

.mdi-eyedropper-plus::before {
  content: "\f13dc";
}

.mdi-eyedropper-remove::before {
  content: "\f13de";
}

.mdi-eyedropper-variant::before {
  content: "\f020b";
}

.mdi-face-agent::before {
  content: "\f0d70";
}

.mdi-face-man::before {
  content: "\f0643";
}

.mdi-face-man-outline::before {
  content: "\f0b96";
}

.mdi-face-man-profile::before {
  content: "\f0644";
}

.mdi-face-man-shimmer::before {
  content: "\f15cc";
}

.mdi-face-man-shimmer-outline::before {
  content: "\f15cd";
}

.mdi-face-mask::before {
  content: "\f1586";
}

.mdi-face-mask-outline::before {
  content: "\f1587";
}

.mdi-face-recognition::before {
  content: "\f0c7b";
}

.mdi-face-woman::before {
  content: "\f1077";
}

.mdi-face-woman-outline::before {
  content: "\f1078";
}

.mdi-face-woman-profile::before {
  content: "\f1076";
}

.mdi-face-woman-shimmer::before {
  content: "\f15ce";
}

.mdi-face-woman-shimmer-outline::before {
  content: "\f15cf";
}

.mdi-facebook::before {
  content: "\f020c";
}

.mdi-facebook-gaming::before {
  content: "\f07dd";
}

.mdi-facebook-messenger::before {
  content: "\f020e";
}

.mdi-facebook-workplace::before {
  content: "\f0b31";
}

.mdi-factory::before {
  content: "\f020f";
}

.mdi-family-tree::before {
  content: "\f160e";
}

.mdi-fan::before {
  content: "\f0210";
}

.mdi-fan-alert::before {
  content: "\f146c";
}

.mdi-fan-auto::before {
  content: "\f171d";
}

.mdi-fan-chevron-down::before {
  content: "\f146d";
}

.mdi-fan-chevron-up::before {
  content: "\f146e";
}

.mdi-fan-minus::before {
  content: "\f1470";
}

.mdi-fan-off::before {
  content: "\f081d";
}

.mdi-fan-plus::before {
  content: "\f146f";
}

.mdi-fan-remove::before {
  content: "\f1471";
}

.mdi-fan-speed-1::before {
  content: "\f1472";
}

.mdi-fan-speed-2::before {
  content: "\f1473";
}

.mdi-fan-speed-3::before {
  content: "\f1474";
}

.mdi-fast-forward::before {
  content: "\f0211";
}

.mdi-fast-forward-10::before {
  content: "\f0d71";
}

.mdi-fast-forward-15::before {
  content: "\f193a";
}

.mdi-fast-forward-30::before {
  content: "\f0d06";
}

.mdi-fast-forward-5::before {
  content: "\f11f8";
}

.mdi-fast-forward-60::before {
  content: "\f160b";
}

.mdi-fast-forward-outline::before {
  content: "\f06d2";
}

.mdi-fax::before {
  content: "\f0212";
}

.mdi-feather::before {
  content: "\f06d3";
}

.mdi-feature-search::before {
  content: "\f0a49";
}

.mdi-feature-search-outline::before {
  content: "\f0a4a";
}

.mdi-fedora::before {
  content: "\f08db";
}

.mdi-fence::before {
  content: "\f179a";
}

.mdi-fence-electric::before {
  content: "\f17f6";
}

.mdi-fencing::before {
  content: "\f14c1";
}

.mdi-ferris-wheel::before {
  content: "\f0ea4";
}

.mdi-ferry::before {
  content: "\f0213";
}

.mdi-file::before {
  content: "\f0214";
}

.mdi-file-account::before {
  content: "\f073b";
}

.mdi-file-account-outline::before {
  content: "\f1028";
}

.mdi-file-alert::before {
  content: "\f0a4b";
}

.mdi-file-alert-outline::before {
  content: "\f0a4c";
}

.mdi-file-cabinet::before {
  content: "\f0ab6";
}

.mdi-file-cad::before {
  content: "\f0eeb";
}

.mdi-file-cad-box::before {
  content: "\f0eec";
}

.mdi-file-cancel::before {
  content: "\f0dc6";
}

.mdi-file-cancel-outline::before {
  content: "\f0dc7";
}

.mdi-file-certificate::before {
  content: "\f1186";
}

.mdi-file-certificate-outline::before {
  content: "\f1187";
}

.mdi-file-chart::before {
  content: "\f0215";
}

.mdi-file-chart-outline::before {
  content: "\f1029";
}

.mdi-file-check::before {
  content: "\f0216";
}

.mdi-file-check-outline::before {
  content: "\f0e29";
}

.mdi-file-clock::before {
  content: "\f12e1";
}

.mdi-file-clock-outline::before {
  content: "\f12e2";
}

.mdi-file-cloud::before {
  content: "\f0217";
}

.mdi-file-cloud-outline::before {
  content: "\f102a";
}

.mdi-file-code::before {
  content: "\f022e";
}

.mdi-file-code-outline::before {
  content: "\f102b";
}

.mdi-file-cog::before {
  content: "\f107b";
}

.mdi-file-cog-outline::before {
  content: "\f107c";
}

.mdi-file-compare::before {
  content: "\f08aa";
}

.mdi-file-delimited::before {
  content: "\f0218";
}

.mdi-file-delimited-outline::before {
  content: "\f0ea5";
}

.mdi-file-document::before {
  content: "\f0219";
}

.mdi-file-document-edit::before {
  content: "\f0dc8";
}

.mdi-file-document-edit-outline::before {
  content: "\f0dc9";
}

.mdi-file-document-multiple::before {
  content: "\f1517";
}

.mdi-file-document-multiple-outline::before {
  content: "\f1518";
}

.mdi-file-document-outline::before {
  content: "\f09ee";
}

.mdi-file-download::before {
  content: "\f0965";
}

.mdi-file-download-outline::before {
  content: "\f0966";
}

.mdi-file-edit::before {
  content: "\f11e7";
}

.mdi-file-edit-outline::before {
  content: "\f11e8";
}

.mdi-file-excel::before {
  content: "\f021b";
}

.mdi-file-excel-box::before {
  content: "\f021c";
}

.mdi-file-excel-box-outline::before {
  content: "\f102c";
}

.mdi-file-excel-outline::before {
  content: "\f102d";
}

.mdi-file-export::before {
  content: "\f021d";
}

.mdi-file-export-outline::before {
  content: "\f102e";
}

.mdi-file-eye::before {
  content: "\f0dca";
}

.mdi-file-eye-outline::before {
  content: "\f0dcb";
}

.mdi-file-find::before {
  content: "\f021e";
}

.mdi-file-find-outline::before {
  content: "\f0b97";
}

.mdi-file-gif-box::before {
  content: "\f0d78";
}

.mdi-file-hidden::before {
  content: "\f0613";
}

.mdi-file-image::before {
  content: "\f021f";
}

.mdi-file-image-marker::before {
  content: "\f1772";
}

.mdi-file-image-marker-outline::before {
  content: "\f1773";
}

.mdi-file-image-minus::before {
  content: "\f193b";
}

.mdi-file-image-minus-outline::before {
  content: "\f193c";
}

.mdi-file-image-outline::before {
  content: "\f0eb0";
}

.mdi-file-image-plus::before {
  content: "\f193d";
}

.mdi-file-image-plus-outline::before {
  content: "\f193e";
}

.mdi-file-image-remove::before {
  content: "\f193f";
}

.mdi-file-image-remove-outline::before {
  content: "\f1940";
}

.mdi-file-import::before {
  content: "\f0220";
}

.mdi-file-import-outline::before {
  content: "\f102f";
}

.mdi-file-jpg-box::before {
  content: "\f0225";
}

.mdi-file-key::before {
  content: "\f1184";
}

.mdi-file-key-outline::before {
  content: "\f1185";
}

.mdi-file-link::before {
  content: "\f1177";
}

.mdi-file-link-outline::before {
  content: "\f1178";
}

.mdi-file-lock::before {
  content: "\f0221";
}

.mdi-file-lock-outline::before {
  content: "\f1030";
}

.mdi-file-marker::before {
  content: "\f1774";
}

.mdi-file-marker-outline::before {
  content: "\f1775";
}

.mdi-file-move::before {
  content: "\f0ab9";
}

.mdi-file-move-outline::before {
  content: "\f1031";
}

.mdi-file-multiple::before {
  content: "\f0222";
}

.mdi-file-multiple-outline::before {
  content: "\f1032";
}

.mdi-file-music::before {
  content: "\f0223";
}

.mdi-file-music-outline::before {
  content: "\f0e2a";
}

.mdi-file-outline::before {
  content: "\f0224";
}

.mdi-file-pdf-box::before {
  content: "\f0226";
}

.mdi-file-percent::before {
  content: "\f081e";
}

.mdi-file-percent-outline::before {
  content: "\f1033";
}

.mdi-file-phone::before {
  content: "\f1179";
}

.mdi-file-phone-outline::before {
  content: "\f117a";
}

.mdi-file-plus::before {
  content: "\f0752";
}

.mdi-file-plus-outline::before {
  content: "\f0eed";
}

.mdi-file-png-box::before {
  content: "\f0e2d";
}

.mdi-file-powerpoint::before {
  content: "\f0227";
}

.mdi-file-powerpoint-box::before {
  content: "\f0228";
}

.mdi-file-powerpoint-box-outline::before {
  content: "\f1034";
}

.mdi-file-powerpoint-outline::before {
  content: "\f1035";
}

.mdi-file-presentation-box::before {
  content: "\f0229";
}

.mdi-file-question::before {
  content: "\f086f";
}

.mdi-file-question-outline::before {
  content: "\f1036";
}

.mdi-file-refresh::before {
  content: "\f0918";
}

.mdi-file-refresh-outline::before {
  content: "\f0541";
}

.mdi-file-remove::before {
  content: "\f0b98";
}

.mdi-file-remove-outline::before {
  content: "\f1037";
}

.mdi-file-replace::before {
  content: "\f0b32";
}

.mdi-file-replace-outline::before {
  content: "\f0b33";
}

.mdi-file-restore::before {
  content: "\f0670";
}

.mdi-file-restore-outline::before {
  content: "\f1038";
}

.mdi-file-search::before {
  content: "\f0c7c";
}

.mdi-file-search-outline::before {
  content: "\f0c7d";
}

.mdi-file-send::before {
  content: "\f022a";
}

.mdi-file-send-outline::before {
  content: "\f1039";
}

.mdi-file-settings::before {
  content: "\f1079";
}

.mdi-file-settings-outline::before {
  content: "\f107a";
}

.mdi-file-sign::before {
  content: "\f19c3";
}

.mdi-file-star::before {
  content: "\f103a";
}

.mdi-file-star-outline::before {
  content: "\f103b";
}

.mdi-file-swap::before {
  content: "\f0fb4";
}

.mdi-file-swap-outline::before {
  content: "\f0fb5";
}

.mdi-file-sync::before {
  content: "\f1216";
}

.mdi-file-sync-outline::before {
  content: "\f1217";
}

.mdi-file-table::before {
  content: "\f0c7e";
}

.mdi-file-table-box::before {
  content: "\f10e1";
}

.mdi-file-table-box-multiple::before {
  content: "\f10e2";
}

.mdi-file-table-box-multiple-outline::before {
  content: "\f10e3";
}

.mdi-file-table-box-outline::before {
  content: "\f10e4";
}

.mdi-file-table-outline::before {
  content: "\f0c7f";
}

.mdi-file-tree::before {
  content: "\f0645";
}

.mdi-file-tree-outline::before {
  content: "\f13d2";
}

.mdi-file-undo::before {
  content: "\f08dc";
}

.mdi-file-undo-outline::before {
  content: "\f103c";
}

.mdi-file-upload::before {
  content: "\f0a4d";
}

.mdi-file-upload-outline::before {
  content: "\f0a4e";
}

.mdi-file-video::before {
  content: "\f022b";
}

.mdi-file-video-outline::before {
  content: "\f0e2c";
}

.mdi-file-word::before {
  content: "\f022c";
}

.mdi-file-word-box::before {
  content: "\f022d";
}

.mdi-file-word-box-outline::before {
  content: "\f103d";
}

.mdi-file-word-outline::before {
  content: "\f103e";
}

.mdi-film::before {
  content: "\f022f";
}

.mdi-filmstrip::before {
  content: "\f0230";
}

.mdi-filmstrip-box::before {
  content: "\f0332";
}

.mdi-filmstrip-box-multiple::before {
  content: "\f0d18";
}

.mdi-filmstrip-off::before {
  content: "\f0231";
}

.mdi-filter::before {
  content: "\f0232";
}

.mdi-filter-check::before {
  content: "\f18ec";
}

.mdi-filter-check-outline::before {
  content: "\f18ed";
}

.mdi-filter-menu::before {
  content: "\f10e5";
}

.mdi-filter-menu-outline::before {
  content: "\f10e6";
}

.mdi-filter-minus::before {
  content: "\f0eee";
}

.mdi-filter-minus-outline::before {
  content: "\f0eef";
}

.mdi-filter-off::before {
  content: "\f14ef";
}

.mdi-filter-off-outline::before {
  content: "\f14f0";
}

.mdi-filter-outline::before {
  content: "\f0233";
}

.mdi-filter-plus::before {
  content: "\f0ef0";
}

.mdi-filter-plus-outline::before {
  content: "\f0ef1";
}

.mdi-filter-remove::before {
  content: "\f0234";
}

.mdi-filter-remove-outline::before {
  content: "\f0235";
}

.mdi-filter-variant::before {
  content: "\f0236";
}

.mdi-filter-variant-minus::before {
  content: "\f1112";
}

.mdi-filter-variant-plus::before {
  content: "\f1113";
}

.mdi-filter-variant-remove::before {
  content: "\f103f";
}

.mdi-finance::before {
  content: "\f081f";
}

.mdi-find-replace::before {
  content: "\f06d4";
}

.mdi-fingerprint::before {
  content: "\f0237";
}

.mdi-fingerprint-off::before {
  content: "\f0eb1";
}

.mdi-fire::before {
  content: "\f0238";
}

.mdi-fire-alert::before {
  content: "\f15d7";
}

.mdi-fire-circle::before {
  content: "\f1807";
}

.mdi-fire-extinguisher::before {
  content: "\f0ef2";
}

.mdi-fire-hydrant::before {
  content: "\f1137";
}

.mdi-fire-hydrant-alert::before {
  content: "\f1138";
}

.mdi-fire-hydrant-off::before {
  content: "\f1139";
}

.mdi-fire-off::before {
  content: "\f1722";
}

.mdi-fire-truck::before {
  content: "\f08ab";
}

.mdi-firebase::before {
  content: "\f0967";
}

.mdi-firefox::before {
  content: "\f0239";
}

.mdi-fireplace::before {
  content: "\f0e2e";
}

.mdi-fireplace-off::before {
  content: "\f0e2f";
}

.mdi-firewire::before {
  content: "\f05be";
}

.mdi-firework::before {
  content: "\f0e30";
}

.mdi-firework-off::before {
  content: "\f1723";
}

.mdi-fish::before {
  content: "\f023a";
}

.mdi-fish-off::before {
  content: "\f13f3";
}

.mdi-fishbowl::before {
  content: "\f0ef3";
}

.mdi-fishbowl-outline::before {
  content: "\f0ef4";
}

.mdi-fit-to-page::before {
  content: "\f0ef5";
}

.mdi-fit-to-page-outline::before {
  content: "\f0ef6";
}

.mdi-fit-to-screen::before {
  content: "\f18f4";
}

.mdi-fit-to-screen-outline::before {
  content: "\f18f5";
}

.mdi-flag::before {
  content: "\f023b";
}

.mdi-flag-checkered::before {
  content: "\f023c";
}

.mdi-flag-minus::before {
  content: "\f0b99";
}

.mdi-flag-minus-outline::before {
  content: "\f10b2";
}

.mdi-flag-off::before {
  content: "\f18ee";
}

.mdi-flag-off-outline::before {
  content: "\f18ef";
}

.mdi-flag-outline::before {
  content: "\f023d";
}

.mdi-flag-plus::before {
  content: "\f0b9a";
}

.mdi-flag-plus-outline::before {
  content: "\f10b3";
}

.mdi-flag-remove::before {
  content: "\f0b9b";
}

.mdi-flag-remove-outline::before {
  content: "\f10b4";
}

.mdi-flag-triangle::before {
  content: "\f023f";
}

.mdi-flag-variant::before {
  content: "\f0240";
}

.mdi-flag-variant-outline::before {
  content: "\f023e";
}

.mdi-flare::before {
  content: "\f0d72";
}

.mdi-flash::before {
  content: "\f0241";
}

.mdi-flash-alert::before {
  content: "\f0ef7";
}

.mdi-flash-alert-outline::before {
  content: "\f0ef8";
}

.mdi-flash-auto::before {
  content: "\f0242";
}

.mdi-flash-off::before {
  content: "\f0243";
}

.mdi-flash-outline::before {
  content: "\f06d5";
}

.mdi-flash-red-eye::before {
  content: "\f067b";
}

.mdi-flashlight::before {
  content: "\f0244";
}

.mdi-flashlight-off::before {
  content: "\f0245";
}

.mdi-flask::before {
  content: "\f0093";
}

.mdi-flask-empty::before {
  content: "\f0094";
}

.mdi-flask-empty-minus::before {
  content: "\f123a";
}

.mdi-flask-empty-minus-outline::before {
  content: "\f123b";
}

.mdi-flask-empty-off::before {
  content: "\f13f4";
}

.mdi-flask-empty-off-outline::before {
  content: "\f13f5";
}

.mdi-flask-empty-outline::before {
  content: "\f0095";
}

.mdi-flask-empty-plus::before {
  content: "\f123c";
}

.mdi-flask-empty-plus-outline::before {
  content: "\f123d";
}

.mdi-flask-empty-remove::before {
  content: "\f123e";
}

.mdi-flask-empty-remove-outline::before {
  content: "\f123f";
}

.mdi-flask-minus::before {
  content: "\f1240";
}

.mdi-flask-minus-outline::before {
  content: "\f1241";
}

.mdi-flask-off::before {
  content: "\f13f6";
}

.mdi-flask-off-outline::before {
  content: "\f13f7";
}

.mdi-flask-outline::before {
  content: "\f0096";
}

.mdi-flask-plus::before {
  content: "\f1242";
}

.mdi-flask-plus-outline::before {
  content: "\f1243";
}

.mdi-flask-remove::before {
  content: "\f1244";
}

.mdi-flask-remove-outline::before {
  content: "\f1245";
}

.mdi-flask-round-bottom::before {
  content: "\f124b";
}

.mdi-flask-round-bottom-empty::before {
  content: "\f124c";
}

.mdi-flask-round-bottom-empty-outline::before {
  content: "\f124d";
}

.mdi-flask-round-bottom-outline::before {
  content: "\f124e";
}

.mdi-fleur-de-lis::before {
  content: "\f1303";
}

.mdi-flip-horizontal::before {
  content: "\f10e7";
}

.mdi-flip-to-back::before {
  content: "\f0247";
}

.mdi-flip-to-front::before {
  content: "\f0248";
}

.mdi-flip-vertical::before {
  content: "\f10e8";
}

.mdi-floor-lamp::before {
  content: "\f08dd";
}

.mdi-floor-lamp-dual::before {
  content: "\f1040";
}

.mdi-floor-lamp-dual-outline::before {
  content: "\f17ce";
}

.mdi-floor-lamp-outline::before {
  content: "\f17c8";
}

.mdi-floor-lamp-torchiere::before {
  content: "\f1747";
}

.mdi-floor-lamp-torchiere-outline::before {
  content: "\f17d6";
}

.mdi-floor-lamp-torchiere-variant::before {
  content: "\f1041";
}

.mdi-floor-lamp-torchiere-variant-outline::before {
  content: "\f17cf";
}

.mdi-floor-plan::before {
  content: "\f0821";
}

.mdi-floppy::before {
  content: "\f0249";
}

.mdi-floppy-variant::before {
  content: "\f09ef";
}

.mdi-flower::before {
  content: "\f024a";
}

.mdi-flower-outline::before {
  content: "\f09f0";
}

.mdi-flower-pollen::before {
  content: "\f1885";
}

.mdi-flower-pollen-outline::before {
  content: "\f1886";
}

.mdi-flower-poppy::before {
  content: "\f0d08";
}

.mdi-flower-tulip::before {
  content: "\f09f1";
}

.mdi-flower-tulip-outline::before {
  content: "\f09f2";
}

.mdi-focus-auto::before {
  content: "\f0f4e";
}

.mdi-focus-field::before {
  content: "\f0f4f";
}

.mdi-focus-field-horizontal::before {
  content: "\f0f50";
}

.mdi-focus-field-vertical::before {
  content: "\f0f51";
}

.mdi-folder::before {
  content: "\f024b";
}

.mdi-folder-account::before {
  content: "\f024c";
}

.mdi-folder-account-outline::before {
  content: "\f0b9c";
}

.mdi-folder-alert::before {
  content: "\f0dcc";
}

.mdi-folder-alert-outline::before {
  content: "\f0dcd";
}

.mdi-folder-check::before {
  content: "\f197e";
}

.mdi-folder-check-outline::before {
  content: "\f197f";
}

.mdi-folder-clock::before {
  content: "\f0aba";
}

.mdi-folder-clock-outline::before {
  content: "\f0abb";
}

.mdi-folder-cog::before {
  content: "\f107f";
}

.mdi-folder-cog-outline::before {
  content: "\f1080";
}

.mdi-folder-download::before {
  content: "\f024d";
}

.mdi-folder-download-outline::before {
  content: "\f10e9";
}

.mdi-folder-edit::before {
  content: "\f08de";
}

.mdi-folder-edit-outline::before {
  content: "\f0dce";
}

.mdi-folder-eye::before {
  content: "\f178a";
}

.mdi-folder-eye-outline::before {
  content: "\f178b";
}

.mdi-folder-google-drive::before {
  content: "\f024e";
}

.mdi-folder-heart::before {
  content: "\f10ea";
}

.mdi-folder-heart-outline::before {
  content: "\f10eb";
}

.mdi-folder-hidden::before {
  content: "\f179e";
}

.mdi-folder-home::before {
  content: "\f10b5";
}

.mdi-folder-home-outline::before {
  content: "\f10b6";
}

.mdi-folder-image::before {
  content: "\f024f";
}

.mdi-folder-information::before {
  content: "\f10b7";
}

.mdi-folder-information-outline::before {
  content: "\f10b8";
}

.mdi-folder-key::before {
  content: "\f08ac";
}

.mdi-folder-key-network::before {
  content: "\f08ad";
}

.mdi-folder-key-network-outline::before {
  content: "\f0c80";
}

.mdi-folder-key-outline::before {
  content: "\f10ec";
}

.mdi-folder-lock::before {
  content: "\f0250";
}

.mdi-folder-lock-open::before {
  content: "\f0251";
}

.mdi-folder-marker::before {
  content: "\f126d";
}

.mdi-folder-marker-outline::before {
  content: "\f126e";
}

.mdi-folder-move::before {
  content: "\f0252";
}

.mdi-folder-move-outline::before {
  content: "\f1246";
}

.mdi-folder-multiple::before {
  content: "\f0253";
}

.mdi-folder-multiple-image::before {
  content: "\f0254";
}

.mdi-folder-multiple-outline::before {
  content: "\f0255";
}

.mdi-folder-multiple-plus::before {
  content: "\f147e";
}

.mdi-folder-multiple-plus-outline::before {
  content: "\f147f";
}

.mdi-folder-music::before {
  content: "\f1359";
}

.mdi-folder-music-outline::before {
  content: "\f135a";
}

.mdi-folder-network::before {
  content: "\f0870";
}

.mdi-folder-network-outline::before {
  content: "\f0c81";
}

.mdi-folder-open::before {
  content: "\f0770";
}

.mdi-folder-open-outline::before {
  content: "\f0dcf";
}

.mdi-folder-outline::before {
  content: "\f0256";
}

.mdi-folder-plus::before {
  content: "\f0257";
}

.mdi-folder-plus-outline::before {
  content: "\f0b9d";
}

.mdi-folder-pound::before {
  content: "\f0d09";
}

.mdi-folder-pound-outline::before {
  content: "\f0d0a";
}

.mdi-folder-refresh::before {
  content: "\f0749";
}

.mdi-folder-refresh-outline::before {
  content: "\f0542";
}

.mdi-folder-remove::before {
  content: "\f0258";
}

.mdi-folder-remove-outline::before {
  content: "\f0b9e";
}

.mdi-folder-search::before {
  content: "\f0968";
}

.mdi-folder-search-outline::before {
  content: "\f0969";
}

.mdi-folder-settings::before {
  content: "\f107d";
}

.mdi-folder-settings-outline::before {
  content: "\f107e";
}

.mdi-folder-star::before {
  content: "\f069d";
}

.mdi-folder-star-multiple::before {
  content: "\f13d3";
}

.mdi-folder-star-multiple-outline::before {
  content: "\f13d4";
}

.mdi-folder-star-outline::before {
  content: "\f0b9f";
}

.mdi-folder-swap::before {
  content: "\f0fb6";
}

.mdi-folder-swap-outline::before {
  content: "\f0fb7";
}

.mdi-folder-sync::before {
  content: "\f0d0b";
}

.mdi-folder-sync-outline::before {
  content: "\f0d0c";
}

.mdi-folder-table::before {
  content: "\f12e3";
}

.mdi-folder-table-outline::before {
  content: "\f12e4";
}

.mdi-folder-text::before {
  content: "\f0c82";
}

.mdi-folder-text-outline::before {
  content: "\f0c83";
}

.mdi-folder-upload::before {
  content: "\f0259";
}

.mdi-folder-upload-outline::before {
  content: "\f10ed";
}

.mdi-folder-zip::before {
  content: "\f06eb";
}

.mdi-folder-zip-outline::before {
  content: "\f07b9";
}

.mdi-font-awesome::before {
  content: "\f003a";
}

.mdi-food::before {
  content: "\f025a";
}

.mdi-food-apple::before {
  content: "\f025b";
}

.mdi-food-apple-outline::before {
  content: "\f0c84";
}

.mdi-food-croissant::before {
  content: "\f07c8";
}

.mdi-food-drumstick::before {
  content: "\f141f";
}

.mdi-food-drumstick-off::before {
  content: "\f1468";
}

.mdi-food-drumstick-off-outline::before {
  content: "\f1469";
}

.mdi-food-drumstick-outline::before {
  content: "\f1420";
}

.mdi-food-fork-drink::before {
  content: "\f05f2";
}

.mdi-food-halal::before {
  content: "\f1572";
}

.mdi-food-hot-dog::before {
  content: "\f184b";
}

.mdi-food-kosher::before {
  content: "\f1573";
}

.mdi-food-off::before {
  content: "\f05f3";
}

.mdi-food-off-outline::before {
  content: "\f1915";
}

.mdi-food-outline::before {
  content: "\f1916";
}

.mdi-food-steak::before {
  content: "\f146a";
}

.mdi-food-steak-off::before {
  content: "\f146b";
}

.mdi-food-takeout-box::before {
  content: "\f1836";
}

.mdi-food-takeout-box-outline::before {
  content: "\f1837";
}

.mdi-food-turkey::before {
  content: "\f171c";
}

.mdi-food-variant::before {
  content: "\f025c";
}

.mdi-food-variant-off::before {
  content: "\f13e5";
}

.mdi-foot-print::before {
  content: "\f0f52";
}

.mdi-football::before {
  content: "\f025d";
}

.mdi-football-australian::before {
  content: "\f025e";
}

.mdi-football-helmet::before {
  content: "\f025f";
}

.mdi-forest::before {
  content: "\f1897";
}

.mdi-forklift::before {
  content: "\f07c9";
}

.mdi-form-dropdown::before {
  content: "\f1400";
}

.mdi-form-select::before {
  content: "\f1401";
}

.mdi-form-textarea::before {
  content: "\f1095";
}

.mdi-form-textbox::before {
  content: "\f060e";
}

.mdi-form-textbox-lock::before {
  content: "\f135d";
}

.mdi-form-textbox-password::before {
  content: "\f07f5";
}

.mdi-format-align-bottom::before {
  content: "\f0753";
}

.mdi-format-align-center::before {
  content: "\f0260";
}

.mdi-format-align-justify::before {
  content: "\f0261";
}

.mdi-format-align-left::before {
  content: "\f0262";
}

.mdi-format-align-middle::before {
  content: "\f0754";
}

.mdi-format-align-right::before {
  content: "\f0263";
}

.mdi-format-align-top::before {
  content: "\f0755";
}

.mdi-format-annotation-minus::before {
  content: "\f0abc";
}

.mdi-format-annotation-plus::before {
  content: "\f0646";
}

.mdi-format-bold::before {
  content: "\f0264";
}

.mdi-format-clear::before {
  content: "\f0265";
}

.mdi-format-color-fill::before {
  content: "\f0266";
}

.mdi-format-color-highlight::before {
  content: "\f0e31";
}

.mdi-format-color-marker-cancel::before {
  content: "\f1313";
}

.mdi-format-color-text::before {
  content: "\f069e";
}

.mdi-format-columns::before {
  content: "\f08df";
}

.mdi-format-float-center::before {
  content: "\f0267";
}

.mdi-format-float-left::before {
  content: "\f0268";
}

.mdi-format-float-none::before {
  content: "\f0269";
}

.mdi-format-float-right::before {
  content: "\f026a";
}

.mdi-format-font::before {
  content: "\f06d6";
}

.mdi-format-font-size-decrease::before {
  content: "\f09f3";
}

.mdi-format-font-size-increase::before {
  content: "\f09f4";
}

.mdi-format-header-1::before {
  content: "\f026b";
}

.mdi-format-header-2::before {
  content: "\f026c";
}

.mdi-format-header-3::before {
  content: "\f026d";
}

.mdi-format-header-4::before {
  content: "\f026e";
}

.mdi-format-header-5::before {
  content: "\f026f";
}

.mdi-format-header-6::before {
  content: "\f0270";
}

.mdi-format-header-decrease::before {
  content: "\f0271";
}

.mdi-format-header-equal::before {
  content: "\f0272";
}

.mdi-format-header-increase::before {
  content: "\f0273";
}

.mdi-format-header-pound::before {
  content: "\f0274";
}

.mdi-format-horizontal-align-center::before {
  content: "\f061e";
}

.mdi-format-horizontal-align-left::before {
  content: "\f061f";
}

.mdi-format-horizontal-align-right::before {
  content: "\f0620";
}

.mdi-format-indent-decrease::before {
  content: "\f0275";
}

.mdi-format-indent-increase::before {
  content: "\f0276";
}

.mdi-format-italic::before {
  content: "\f0277";
}

.mdi-format-letter-case::before {
  content: "\f0b34";
}

.mdi-format-letter-case-lower::before {
  content: "\f0b35";
}

.mdi-format-letter-case-upper::before {
  content: "\f0b36";
}

.mdi-format-letter-ends-with::before {
  content: "\f0fb8";
}

.mdi-format-letter-matches::before {
  content: "\f0fb9";
}

.mdi-format-letter-spacing::before {
  content: "\f1956";
}

.mdi-format-letter-starts-with::before {
  content: "\f0fba";
}

.mdi-format-line-spacing::before {
  content: "\f0278";
}

.mdi-format-line-style::before {
  content: "\f05c8";
}

.mdi-format-line-weight::before {
  content: "\f05c9";
}

.mdi-format-list-bulleted::before {
  content: "\f0279";
}

.mdi-format-list-bulleted-square::before {
  content: "\f0dd0";
}

.mdi-format-list-bulleted-triangle::before {
  content: "\f0eb2";
}

.mdi-format-list-bulleted-type::before {
  content: "\f027a";
}

.mdi-format-list-checkbox::before {
  content: "\f096a";
}

.mdi-format-list-checks::before {
  content: "\f0756";
}

.mdi-format-list-group::before {
  content: "\f1860";
}

.mdi-format-list-numbered::before {
  content: "\f027b";
}

.mdi-format-list-numbered-rtl::before {
  content: "\f0d0d";
}

.mdi-format-list-text::before {
  content: "\f126f";
}

.mdi-format-overline::before {
  content: "\f0eb3";
}

.mdi-format-page-break::before {
  content: "\f06d7";
}

.mdi-format-page-split::before {
  content: "\f1917";
}

.mdi-format-paint::before {
  content: "\f027c";
}

.mdi-format-paragraph::before {
  content: "\f027d";
}

.mdi-format-pilcrow::before {
  content: "\f06d8";
}

.mdi-format-quote-close::before {
  content: "\f027e";
}

.mdi-format-quote-close-outline::before {
  content: "\f11a8";
}

.mdi-format-quote-open::before {
  content: "\f0757";
}

.mdi-format-quote-open-outline::before {
  content: "\f11a7";
}

.mdi-format-rotate-90::before {
  content: "\f06aa";
}

.mdi-format-section::before {
  content: "\f069f";
}

.mdi-format-size::before {
  content: "\f027f";
}

.mdi-format-strikethrough::before {
  content: "\f0280";
}

.mdi-format-strikethrough-variant::before {
  content: "\f0281";
}

.mdi-format-subscript::before {
  content: "\f0282";
}

.mdi-format-superscript::before {
  content: "\f0283";
}

.mdi-format-text::before {
  content: "\f0284";
}

.mdi-format-text-rotation-angle-down::before {
  content: "\f0fbb";
}

.mdi-format-text-rotation-angle-up::before {
  content: "\f0fbc";
}

.mdi-format-text-rotation-down::before {
  content: "\f0d73";
}

.mdi-format-text-rotation-down-vertical::before {
  content: "\f0fbd";
}

.mdi-format-text-rotation-none::before {
  content: "\f0d74";
}

.mdi-format-text-rotation-up::before {
  content: "\f0fbe";
}

.mdi-format-text-rotation-vertical::before {
  content: "\f0fbf";
}

.mdi-format-text-variant::before {
  content: "\f0e32";
}

.mdi-format-text-variant-outline::before {
  content: "\f150f";
}

.mdi-format-text-wrapping-clip::before {
  content: "\f0d0e";
}

.mdi-format-text-wrapping-overflow::before {
  content: "\f0d0f";
}

.mdi-format-text-wrapping-wrap::before {
  content: "\f0d10";
}

.mdi-format-textbox::before {
  content: "\f0d11";
}

.mdi-format-textdirection-l-to-r::before {
  content: "\f0285";
}

.mdi-format-textdirection-r-to-l::before {
  content: "\f0286";
}

.mdi-format-title::before {
  content: "\f05f4";
}

.mdi-format-underline::before {
  content: "\f0287";
}

.mdi-format-underline-wavy::before {
  content: "\f18e9";
}

.mdi-format-vertical-align-bottom::before {
  content: "\f0621";
}

.mdi-format-vertical-align-center::before {
  content: "\f0622";
}

.mdi-format-vertical-align-top::before {
  content: "\f0623";
}

.mdi-format-wrap-inline::before {
  content: "\f0288";
}

.mdi-format-wrap-square::before {
  content: "\f0289";
}

.mdi-format-wrap-tight::before {
  content: "\f028a";
}

.mdi-format-wrap-top-bottom::before {
  content: "\f028b";
}

.mdi-forum::before {
  content: "\f028c";
}

.mdi-forum-outline::before {
  content: "\f0822";
}

.mdi-forward::before {
  content: "\f028d";
}

.mdi-forwardburger::before {
  content: "\f0d75";
}

.mdi-fountain::before {
  content: "\f096b";
}

.mdi-fountain-pen::before {
  content: "\f0d12";
}

.mdi-fountain-pen-tip::before {
  content: "\f0d13";
}

.mdi-fraction-one-half::before {
  content: "\f1992";
}

.mdi-freebsd::before {
  content: "\f08e0";
}

.mdi-french-fries::before {
  content: "\f1957";
}

.mdi-frequently-asked-questions::before {
  content: "\f0eb4";
}

.mdi-fridge::before {
  content: "\f0290";
}

.mdi-fridge-alert::before {
  content: "\f11b1";
}

.mdi-fridge-alert-outline::before {
  content: "\f11b2";
}

.mdi-fridge-bottom::before {
  content: "\f0292";
}

.mdi-fridge-industrial::before {
  content: "\f15ee";
}

.mdi-fridge-industrial-alert::before {
  content: "\f15ef";
}

.mdi-fridge-industrial-alert-outline::before {
  content: "\f15f0";
}

.mdi-fridge-industrial-off::before {
  content: "\f15f1";
}

.mdi-fridge-industrial-off-outline::before {
  content: "\f15f2";
}

.mdi-fridge-industrial-outline::before {
  content: "\f15f3";
}

.mdi-fridge-off::before {
  content: "\f11af";
}

.mdi-fridge-off-outline::before {
  content: "\f11b0";
}

.mdi-fridge-outline::before {
  content: "\f028f";
}

.mdi-fridge-top::before {
  content: "\f0291";
}

.mdi-fridge-variant::before {
  content: "\f15f4";
}

.mdi-fridge-variant-alert::before {
  content: "\f15f5";
}

.mdi-fridge-variant-alert-outline::before {
  content: "\f15f6";
}

.mdi-fridge-variant-off::before {
  content: "\f15f7";
}

.mdi-fridge-variant-off-outline::before {
  content: "\f15f8";
}

.mdi-fridge-variant-outline::before {
  content: "\f15f9";
}

.mdi-fruit-cherries::before {
  content: "\f1042";
}

.mdi-fruit-cherries-off::before {
  content: "\f13f8";
}

.mdi-fruit-citrus::before {
  content: "\f1043";
}

.mdi-fruit-citrus-off::before {
  content: "\f13f9";
}

.mdi-fruit-grapes::before {
  content: "\f1044";
}

.mdi-fruit-grapes-outline::before {
  content: "\f1045";
}

.mdi-fruit-pineapple::before {
  content: "\f1046";
}

.mdi-fruit-watermelon::before {
  content: "\f1047";
}

.mdi-fuel::before {
  content: "\f07ca";
}

.mdi-fuel-cell::before {
  content: "\f18b5";
}

.mdi-fullscreen::before {
  content: "\f0293";
}

.mdi-fullscreen-exit::before {
  content: "\f0294";
}

.mdi-function::before {
  content: "\f0295";
}

.mdi-function-variant::before {
  content: "\f0871";
}

.mdi-furigana-horizontal::before {
  content: "\f1081";
}

.mdi-furigana-vertical::before {
  content: "\f1082";
}

.mdi-fuse::before {
  content: "\f0c85";
}

.mdi-fuse-alert::before {
  content: "\f142d";
}

.mdi-fuse-blade::before {
  content: "\f0c86";
}

.mdi-fuse-off::before {
  content: "\f142c";
}

.mdi-gamepad::before {
  content: "\f0296";
}

.mdi-gamepad-circle::before {
  content: "\f0e33";
}

.mdi-gamepad-circle-down::before {
  content: "\f0e34";
}

.mdi-gamepad-circle-left::before {
  content: "\f0e35";
}

.mdi-gamepad-circle-outline::before {
  content: "\f0e36";
}

.mdi-gamepad-circle-right::before {
  content: "\f0e37";
}

.mdi-gamepad-circle-up::before {
  content: "\f0e38";
}

.mdi-gamepad-down::before {
  content: "\f0e39";
}

.mdi-gamepad-left::before {
  content: "\f0e3a";
}

.mdi-gamepad-outline::before {
  content: "\f1919";
}

.mdi-gamepad-right::before {
  content: "\f0e3b";
}

.mdi-gamepad-round::before {
  content: "\f0e3c";
}

.mdi-gamepad-round-down::before {
  content: "\f0e3d";
}

.mdi-gamepad-round-left::before {
  content: "\f0e3e";
}

.mdi-gamepad-round-outline::before {
  content: "\f0e3f";
}

.mdi-gamepad-round-right::before {
  content: "\f0e40";
}

.mdi-gamepad-round-up::before {
  content: "\f0e41";
}

.mdi-gamepad-square::before {
  content: "\f0eb5";
}

.mdi-gamepad-square-outline::before {
  content: "\f0eb6";
}

.mdi-gamepad-up::before {
  content: "\f0e42";
}

.mdi-gamepad-variant::before {
  content: "\f0297";
}

.mdi-gamepad-variant-outline::before {
  content: "\f0eb7";
}

.mdi-gamma::before {
  content: "\f10ee";
}

.mdi-gantry-crane::before {
  content: "\f0dd1";
}

.mdi-garage::before {
  content: "\f06d9";
}

.mdi-garage-alert::before {
  content: "\f0872";
}

.mdi-garage-alert-variant::before {
  content: "\f12d5";
}

.mdi-garage-lock::before {
  content: "\f17fb";
}

.mdi-garage-open::before {
  content: "\f06da";
}

.mdi-garage-open-variant::before {
  content: "\f12d4";
}

.mdi-garage-variant::before {
  content: "\f12d3";
}

.mdi-garage-variant-lock::before {
  content: "\f17fc";
}

.mdi-gas-cylinder::before {
  content: "\f0647";
}

.mdi-gas-station::before {
  content: "\f0298";
}

.mdi-gas-station-off::before {
  content: "\f1409";
}

.mdi-gas-station-off-outline::before {
  content: "\f140a";
}

.mdi-gas-station-outline::before {
  content: "\f0eb8";
}

.mdi-gate::before {
  content: "\f0299";
}

.mdi-gate-alert::before {
  content: "\f17f8";
}

.mdi-gate-and::before {
  content: "\f08e1";
}

.mdi-gate-arrow-left::before {
  content: "\f17f7";
}

.mdi-gate-arrow-right::before {
  content: "\f1169";
}

.mdi-gate-nand::before {
  content: "\f08e2";
}

.mdi-gate-nor::before {
  content: "\f08e3";
}

.mdi-gate-not::before {
  content: "\f08e4";
}

.mdi-gate-open::before {
  content: "\f116a";
}

.mdi-gate-or::before {
  content: "\f08e5";
}

.mdi-gate-xnor::before {
  content: "\f08e6";
}

.mdi-gate-xor::before {
  content: "\f08e7";
}

.mdi-gatsby::before {
  content: "\f0e43";
}

.mdi-gauge::before {
  content: "\f029a";
}

.mdi-gauge-empty::before {
  content: "\f0873";
}

.mdi-gauge-full::before {
  content: "\f0874";
}

.mdi-gauge-low::before {
  content: "\f0875";
}

.mdi-gavel::before {
  content: "\f029b";
}

.mdi-gender-female::before {
  content: "\f029c";
}

.mdi-gender-male::before {
  content: "\f029d";
}

.mdi-gender-male-female::before {
  content: "\f029e";
}

.mdi-gender-male-female-variant::before {
  content: "\f113f";
}

.mdi-gender-non-binary::before {
  content: "\f1140";
}

.mdi-gender-transgender::before {
  content: "\f029f";
}

.mdi-gentoo::before {
  content: "\f08e8";
}

.mdi-gesture::before {
  content: "\f07cb";
}

.mdi-gesture-double-tap::before {
  content: "\f073c";
}

.mdi-gesture-pinch::before {
  content: "\f0abd";
}

.mdi-gesture-spread::before {
  content: "\f0abe";
}

.mdi-gesture-swipe::before {
  content: "\f0d76";
}

.mdi-gesture-swipe-down::before {
  content: "\f073d";
}

.mdi-gesture-swipe-horizontal::before {
  content: "\f0abf";
}

.mdi-gesture-swipe-left::before {
  content: "\f073e";
}

.mdi-gesture-swipe-right::before {
  content: "\f073f";
}

.mdi-gesture-swipe-up::before {
  content: "\f0740";
}

.mdi-gesture-swipe-vertical::before {
  content: "\f0ac0";
}

.mdi-gesture-tap::before {
  content: "\f0741";
}

.mdi-gesture-tap-box::before {
  content: "\f12a9";
}

.mdi-gesture-tap-button::before {
  content: "\f12a8";
}

.mdi-gesture-tap-hold::before {
  content: "\f0d77";
}

.mdi-gesture-two-double-tap::before {
  content: "\f0742";
}

.mdi-gesture-two-tap::before {
  content: "\f0743";
}

.mdi-ghost::before {
  content: "\f02a0";
}

.mdi-ghost-off::before {
  content: "\f09f5";
}

.mdi-ghost-off-outline::before {
  content: "\f165c";
}

.mdi-ghost-outline::before {
  content: "\f165d";
}

.mdi-gift::before {
  content: "\f0e44";
}

.mdi-gift-off::before {
  content: "\f16ef";
}

.mdi-gift-off-outline::before {
  content: "\f16f0";
}

.mdi-gift-open::before {
  content: "\f16f1";
}

.mdi-gift-open-outline::before {
  content: "\f16f2";
}

.mdi-gift-outline::before {
  content: "\f02a1";
}

.mdi-git::before {
  content: "\f02a2";
}

.mdi-github::before {
  content: "\f02a4";
}

.mdi-gitlab::before {
  content: "\f0ba0";
}

.mdi-glass-cocktail::before {
  content: "\f0356";
}

.mdi-glass-cocktail-off::before {
  content: "\f15e6";
}

.mdi-glass-flute::before {
  content: "\f02a5";
}

.mdi-glass-fragile::before {
  content: "\f1873";
}

.mdi-glass-mug::before {
  content: "\f02a6";
}

.mdi-glass-mug-off::before {
  content: "\f15e7";
}

.mdi-glass-mug-variant::before {
  content: "\f1116";
}

.mdi-glass-mug-variant-off::before {
  content: "\f15e8";
}

.mdi-glass-pint-outline::before {
  content: "\f130d";
}

.mdi-glass-stange::before {
  content: "\f02a7";
}

.mdi-glass-tulip::before {
  content: "\f02a8";
}

.mdi-glass-wine::before {
  content: "\f0876";
}

.mdi-glasses::before {
  content: "\f02aa";
}

.mdi-globe-light::before {
  content: "\f12d7";
}

.mdi-globe-model::before {
  content: "\f08e9";
}

.mdi-gmail::before {
  content: "\f02ab";
}

.mdi-gnome::before {
  content: "\f02ac";
}

.mdi-go-kart::before {
  content: "\f0d79";
}

.mdi-go-kart-track::before {
  content: "\f0d7a";
}

.mdi-gog::before {
  content: "\f0ba1";
}

.mdi-gold::before {
  content: "\f124f";
}

.mdi-golf::before {
  content: "\f0823";
}

.mdi-golf-cart::before {
  content: "\f11a4";
}

.mdi-golf-tee::before {
  content: "\f1083";
}

.mdi-gondola::before {
  content: "\f0686";
}

.mdi-goodreads::before {
  content: "\f0d7b";
}

.mdi-google::before {
  content: "\f02ad";
}

.mdi-google-ads::before {
  content: "\f0c87";
}

.mdi-google-analytics::before {
  content: "\f07cc";
}

.mdi-google-assistant::before {
  content: "\f07cd";
}

.mdi-google-cardboard::before {
  content: "\f02ae";
}

.mdi-google-chrome::before {
  content: "\f02af";
}

.mdi-google-circles::before {
  content: "\f02b0";
}

.mdi-google-circles-communities::before {
  content: "\f02b1";
}

.mdi-google-circles-extended::before {
  content: "\f02b2";
}

.mdi-google-circles-group::before {
  content: "\f02b3";
}

.mdi-google-classroom::before {
  content: "\f02c0";
}

.mdi-google-cloud::before {
  content: "\f11f6";
}

.mdi-google-controller::before {
  content: "\f02b4";
}

.mdi-google-controller-off::before {
  content: "\f02b5";
}

.mdi-google-downasaur::before {
  content: "\f1362";
}

.mdi-google-drive::before {
  content: "\f02b6";
}

.mdi-google-earth::before {
  content: "\f02b7";
}

.mdi-google-fit::before {
  content: "\f096c";
}

.mdi-google-glass::before {
  content: "\f02b8";
}

.mdi-google-hangouts::before {
  content: "\f02c9";
}

.mdi-google-home::before {
  content: "\f0824";
}

.mdi-google-keep::before {
  content: "\f06dc";
}

.mdi-google-lens::before {
  content: "\f09f6";
}

.mdi-google-maps::before {
  content: "\f05f5";
}

.mdi-google-my-business::before {
  content: "\f1048";
}

.mdi-google-nearby::before {
  content: "\f02b9";
}

.mdi-google-play::before {
  content: "\f02bc";
}

.mdi-google-plus::before {
  content: "\f02bd";
}

.mdi-google-podcast::before {
  content: "\f0eb9";
}

.mdi-google-spreadsheet::before {
  content: "\f09f7";
}

.mdi-google-street-view::before {
  content: "\f0c88";
}

.mdi-google-translate::before {
  content: "\f02bf";
}

.mdi-gradient-horizontal::before {
  content: "\f174a";
}

.mdi-gradient-vertical::before {
  content: "\f06a0";
}

.mdi-grain::before {
  content: "\f0d7c";
}

.mdi-graph::before {
  content: "\f1049";
}

.mdi-graph-outline::before {
  content: "\f104a";
}

.mdi-graphql::before {
  content: "\f0877";
}

.mdi-grass::before {
  content: "\f1510";
}

.mdi-grave-stone::before {
  content: "\f0ba2";
}

.mdi-grease-pencil::before {
  content: "\f0648";
}

.mdi-greater-than::before {
  content: "\f096d";
}

.mdi-greater-than-or-equal::before {
  content: "\f096e";
}

.mdi-greenhouse::before {
  content: "\f002d";
}

.mdi-grid::before {
  content: "\f02c1";
}

.mdi-grid-large::before {
  content: "\f0758";
}

.mdi-grid-off::before {
  content: "\f02c2";
}

.mdi-grill::before {
  content: "\f0e45";
}

.mdi-grill-outline::before {
  content: "\f118a";
}

.mdi-group::before {
  content: "\f02c3";
}

.mdi-guitar-acoustic::before {
  content: "\f0771";
}

.mdi-guitar-electric::before {
  content: "\f02c4";
}

.mdi-guitar-pick::before {
  content: "\f02c5";
}

.mdi-guitar-pick-outline::before {
  content: "\f02c6";
}

.mdi-guy-fawkes-mask::before {
  content: "\f0825";
}

.mdi-hail::before {
  content: "\f0ac1";
}

.mdi-hair-dryer::before {
  content: "\f10ef";
}

.mdi-hair-dryer-outline::before {
  content: "\f10f0";
}

.mdi-halloween::before {
  content: "\f0ba3";
}

.mdi-hamburger::before {
  content: "\f0685";
}

.mdi-hamburger-check::before {
  content: "\f1776";
}

.mdi-hamburger-minus::before {
  content: "\f1777";
}

.mdi-hamburger-off::before {
  content: "\f1778";
}

.mdi-hamburger-plus::before {
  content: "\f1779";
}

.mdi-hamburger-remove::before {
  content: "\f177a";
}

.mdi-hammer::before {
  content: "\f08ea";
}

.mdi-hammer-screwdriver::before {
  content: "\f1322";
}

.mdi-hammer-sickle::before {
  content: "\f1887";
}

.mdi-hammer-wrench::before {
  content: "\f1323";
}

.mdi-hand-back-left::before {
  content: "\f0e46";
}

.mdi-hand-back-left-off::before {
  content: "\f1830";
}

.mdi-hand-back-left-off-outline::before {
  content: "\f1832";
}

.mdi-hand-back-left-outline::before {
  content: "\f182c";
}

.mdi-hand-back-right::before {
  content: "\f0e47";
}

.mdi-hand-back-right-off::before {
  content: "\f1831";
}

.mdi-hand-back-right-off-outline::before {
  content: "\f1833";
}

.mdi-hand-back-right-outline::before {
  content: "\f182d";
}

.mdi-hand-clap::before {
  content: "\f194b";
}

.mdi-hand-coin::before {
  content: "\f188f";
}

.mdi-hand-coin-outline::before {
  content: "\f1890";
}

.mdi-hand-extended::before {
  content: "\f18b6";
}

.mdi-hand-extended-outline::before {
  content: "\f18b7";
}

.mdi-hand-front-left::before {
  content: "\f182b";
}

.mdi-hand-front-left-outline::before {
  content: "\f182e";
}

.mdi-hand-front-right::before {
  content: "\f0a4f";
}

.mdi-hand-front-right-outline::before {
  content: "\f182f";
}

.mdi-hand-heart::before {
  content: "\f10f1";
}

.mdi-hand-heart-outline::before {
  content: "\f157e";
}

.mdi-hand-okay::before {
  content: "\f0a50";
}

.mdi-hand-peace::before {
  content: "\f0a51";
}

.mdi-hand-peace-variant::before {
  content: "\f0a52";
}

.mdi-hand-pointing-down::before {
  content: "\f0a53";
}

.mdi-hand-pointing-left::before {
  content: "\f0a54";
}

.mdi-hand-pointing-right::before {
  content: "\f02c7";
}

.mdi-hand-pointing-up::before {
  content: "\f0a55";
}

.mdi-hand-saw::before {
  content: "\f0e48";
}

.mdi-hand-wash::before {
  content: "\f157f";
}

.mdi-hand-wash-outline::before {
  content: "\f1580";
}

.mdi-hand-water::before {
  content: "\f139f";
}

.mdi-hand-wave::before {
  content: "\f1821";
}

.mdi-hand-wave-outline::before {
  content: "\f1822";
}

.mdi-handball::before {
  content: "\f0f53";
}

.mdi-handcuffs::before {
  content: "\f113e";
}

.mdi-hands-pray::before {
  content: "\f0579";
}

.mdi-handshake::before {
  content: "\f1218";
}

.mdi-handshake-outline::before {
  content: "\f15a1";
}

.mdi-hanger::before {
  content: "\f02c8";
}

.mdi-hard-hat::before {
  content: "\f096f";
}

.mdi-harddisk::before {
  content: "\f02ca";
}

.mdi-harddisk-plus::before {
  content: "\f104b";
}

.mdi-harddisk-remove::before {
  content: "\f104c";
}

.mdi-hat-fedora::before {
  content: "\f0ba4";
}

.mdi-hazard-lights::before {
  content: "\f0c89";
}

.mdi-hdr::before {
  content: "\f0d7d";
}

.mdi-hdr-off::before {
  content: "\f0d7e";
}

.mdi-head::before {
  content: "\f135e";
}

.mdi-head-alert::before {
  content: "\f1338";
}

.mdi-head-alert-outline::before {
  content: "\f1339";
}

.mdi-head-check::before {
  content: "\f133a";
}

.mdi-head-check-outline::before {
  content: "\f133b";
}

.mdi-head-cog::before {
  content: "\f133c";
}

.mdi-head-cog-outline::before {
  content: "\f133d";
}

.mdi-head-dots-horizontal::before {
  content: "\f133e";
}

.mdi-head-dots-horizontal-outline::before {
  content: "\f133f";
}

.mdi-head-flash::before {
  content: "\f1340";
}

.mdi-head-flash-outline::before {
  content: "\f1341";
}

.mdi-head-heart::before {
  content: "\f1342";
}

.mdi-head-heart-outline::before {
  content: "\f1343";
}

.mdi-head-lightbulb::before {
  content: "\f1344";
}

.mdi-head-lightbulb-outline::before {
  content: "\f1345";
}

.mdi-head-minus::before {
  content: "\f1346";
}

.mdi-head-minus-outline::before {
  content: "\f1347";
}

.mdi-head-outline::before {
  content: "\f135f";
}

.mdi-head-plus::before {
  content: "\f1348";
}

.mdi-head-plus-outline::before {
  content: "\f1349";
}

.mdi-head-question::before {
  content: "\f134a";
}

.mdi-head-question-outline::before {
  content: "\f134b";
}

.mdi-head-remove::before {
  content: "\f134c";
}

.mdi-head-remove-outline::before {
  content: "\f134d";
}

.mdi-head-snowflake::before {
  content: "\f134e";
}

.mdi-head-snowflake-outline::before {
  content: "\f134f";
}

.mdi-head-sync::before {
  content: "\f1350";
}

.mdi-head-sync-outline::before {
  content: "\f1351";
}

.mdi-headphones::before {
  content: "\f02cb";
}

.mdi-headphones-bluetooth::before {
  content: "\f0970";
}

.mdi-headphones-box::before {
  content: "\f02cc";
}

.mdi-headphones-off::before {
  content: "\f07ce";
}

.mdi-headphones-settings::before {
  content: "\f02cd";
}

.mdi-headset::before {
  content: "\f02ce";
}

.mdi-headset-dock::before {
  content: "\f02cf";
}

.mdi-headset-off::before {
  content: "\f02d0";
}

.mdi-heart::before {
  content: "\f02d1";
}

.mdi-heart-box::before {
  content: "\f02d2";
}

.mdi-heart-box-outline::before {
  content: "\f02d3";
}

.mdi-heart-broken::before {
  content: "\f02d4";
}

.mdi-heart-broken-outline::before {
  content: "\f0d14";
}

.mdi-heart-circle::before {
  content: "\f0971";
}

.mdi-heart-circle-outline::before {
  content: "\f0972";
}

.mdi-heart-cog::before {
  content: "\f1663";
}

.mdi-heart-cog-outline::before {
  content: "\f1664";
}

.mdi-heart-flash::before {
  content: "\f0ef9";
}

.mdi-heart-half::before {
  content: "\f06df";
}

.mdi-heart-half-full::before {
  content: "\f06de";
}

.mdi-heart-half-outline::before {
  content: "\f06e0";
}

.mdi-heart-minus::before {
  content: "\f142f";
}

.mdi-heart-minus-outline::before {
  content: "\f1432";
}

.mdi-heart-multiple::before {
  content: "\f0a56";
}

.mdi-heart-multiple-outline::before {
  content: "\f0a57";
}

.mdi-heart-off::before {
  content: "\f0759";
}

.mdi-heart-off-outline::before {
  content: "\f1434";
}

.mdi-heart-outline::before {
  content: "\f02d5";
}

.mdi-heart-plus::before {
  content: "\f142e";
}

.mdi-heart-plus-outline::before {
  content: "\f1431";
}

.mdi-heart-pulse::before {
  content: "\f05f6";
}

.mdi-heart-remove::before {
  content: "\f1430";
}

.mdi-heart-remove-outline::before {
  content: "\f1433";
}

.mdi-heart-settings::before {
  content: "\f1665";
}

.mdi-heart-settings-outline::before {
  content: "\f1666";
}

.mdi-helicopter::before {
  content: "\f0ac2";
}

.mdi-help::before {
  content: "\f02d6";
}

.mdi-help-box::before {
  content: "\f078b";
}

.mdi-help-circle::before {
  content: "\f02d7";
}

.mdi-help-circle-outline::before {
  content: "\f0625";
}

.mdi-help-network::before {
  content: "\f06f5";
}

.mdi-help-network-outline::before {
  content: "\f0c8a";
}

.mdi-help-rhombus::before {
  content: "\f0ba5";
}

.mdi-help-rhombus-outline::before {
  content: "\f0ba6";
}

.mdi-hexadecimal::before {
  content: "\f12a7";
}

.mdi-hexagon::before {
  content: "\f02d8";
}

.mdi-hexagon-multiple::before {
  content: "\f06e1";
}

.mdi-hexagon-multiple-outline::before {
  content: "\f10f2";
}

.mdi-hexagon-outline::before {
  content: "\f02d9";
}

.mdi-hexagon-slice-1::before {
  content: "\f0ac3";
}

.mdi-hexagon-slice-2::before {
  content: "\f0ac4";
}

.mdi-hexagon-slice-3::before {
  content: "\f0ac5";
}

.mdi-hexagon-slice-4::before {
  content: "\f0ac6";
}

.mdi-hexagon-slice-5::before {
  content: "\f0ac7";
}

.mdi-hexagon-slice-6::before {
  content: "\f0ac8";
}

.mdi-hexagram::before {
  content: "\f0ac9";
}

.mdi-hexagram-outline::before {
  content: "\f0aca";
}

.mdi-high-definition::before {
  content: "\f07cf";
}

.mdi-high-definition-box::before {
  content: "\f0878";
}

.mdi-highway::before {
  content: "\f05f7";
}

.mdi-hiking::before {
  content: "\f0d7f";
}

.mdi-history::before {
  content: "\f02da";
}

.mdi-hockey-puck::before {
  content: "\f0879";
}

.mdi-hockey-sticks::before {
  content: "\f087a";
}

.mdi-hololens::before {
  content: "\f02db";
}

.mdi-home::before {
  content: "\f02dc";
}

.mdi-home-account::before {
  content: "\f0826";
}

.mdi-home-alert::before {
  content: "\f087b";
}

.mdi-home-alert-outline::before {
  content: "\f15d0";
}

.mdi-home-analytics::before {
  content: "\f0eba";
}

.mdi-home-assistant::before {
  content: "\f07d0";
}

.mdi-home-automation::before {
  content: "\f07d1";
}

.mdi-home-battery::before {
  content: "\f1901";
}

.mdi-home-battery-outline::before {
  content: "\f1902";
}

.mdi-home-circle::before {
  content: "\f07d2";
}

.mdi-home-circle-outline::before {
  content: "\f104d";
}

.mdi-home-city::before {
  content: "\f0d15";
}

.mdi-home-city-outline::before {
  content: "\f0d16";
}

.mdi-home-edit::before {
  content: "\f1159";
}

.mdi-home-edit-outline::before {
  content: "\f115a";
}

.mdi-home-export-outline::before {
  content: "\f0f9b";
}

.mdi-home-flood::before {
  content: "\f0efa";
}

.mdi-home-floor-0::before {
  content: "\f0dd2";
}

.mdi-home-floor-1::before {
  content: "\f0d80";
}

.mdi-home-floor-2::before {
  content: "\f0d81";
}

.mdi-home-floor-3::before {
  content: "\f0d82";
}

.mdi-home-floor-a::before {
  content: "\f0d83";
}

.mdi-home-floor-b::before {
  content: "\f0d84";
}

.mdi-home-floor-g::before {
  content: "\f0d85";
}

.mdi-home-floor-l::before {
  content: "\f0d86";
}

.mdi-home-floor-negative-1::before {
  content: "\f0dd3";
}

.mdi-home-group::before {
  content: "\f0dd4";
}

.mdi-home-group-minus::before {
  content: "\f19c1";
}

.mdi-home-group-plus::before {
  content: "\f19c0";
}

.mdi-home-group-remove::before {
  content: "\f19c2";
}

.mdi-home-heart::before {
  content: "\f0827";
}

.mdi-home-import-outline::before {
  content: "\f0f9c";
}

.mdi-home-lightbulb::before {
  content: "\f1251";
}

.mdi-home-lightbulb-outline::before {
  content: "\f1252";
}

.mdi-home-lightning-bolt::before {
  content: "\f1903";
}

.mdi-home-lightning-bolt-outline::before {
  content: "\f1904";
}

.mdi-home-lock::before {
  content: "\f08eb";
}

.mdi-home-lock-open::before {
  content: "\f08ec";
}

.mdi-home-map-marker::before {
  content: "\f05f8";
}

.mdi-home-minus::before {
  content: "\f0974";
}

.mdi-home-minus-outline::before {
  content: "\f13d5";
}

.mdi-home-modern::before {
  content: "\f02dd";
}

.mdi-home-outline::before {
  content: "\f06a1";
}

.mdi-home-plus::before {
  content: "\f0975";
}

.mdi-home-plus-outline::before {
  content: "\f13d6";
}

.mdi-home-remove::before {
  content: "\f1247";
}

.mdi-home-remove-outline::before {
  content: "\f13d7";
}

.mdi-home-roof::before {
  content: "\f112b";
}

.mdi-home-search::before {
  content: "\f13b0";
}

.mdi-home-search-outline::before {
  content: "\f13b1";
}

.mdi-home-switch::before {
  content: "\f1794";
}

.mdi-home-switch-outline::before {
  content: "\f1795";
}

.mdi-home-thermometer::before {
  content: "\f0f54";
}

.mdi-home-thermometer-outline::before {
  content: "\f0f55";
}

.mdi-home-variant::before {
  content: "\f02de";
}

.mdi-home-variant-outline::before {
  content: "\f0ba7";
}

.mdi-hook::before {
  content: "\f06e2";
}

.mdi-hook-off::before {
  content: "\f06e3";
}

.mdi-hoop-house::before {
  content: "\f0e56";
}

.mdi-hops::before {
  content: "\f02df";
}

.mdi-horizontal-rotate-clockwise::before {
  content: "\f10f3";
}

.mdi-horizontal-rotate-counterclockwise::before {
  content: "\f10f4";
}

.mdi-horse::before {
  content: "\f15bf";
}

.mdi-horse-human::before {
  content: "\f15c0";
}

.mdi-horse-variant::before {
  content: "\f15c1";
}

.mdi-horse-variant-fast::before {
  content: "\f186e";
}

.mdi-horseshoe::before {
  content: "\f0a58";
}

.mdi-hospital::before {
  content: "\f0ff6";
}

.mdi-hospital-box::before {
  content: "\f02e0";
}

.mdi-hospital-box-outline::before {
  content: "\f0ff7";
}

.mdi-hospital-building::before {
  content: "\f02e1";
}

.mdi-hospital-marker::before {
  content: "\f02e2";
}

.mdi-hot-tub::before {
  content: "\f0828";
}

.mdi-hours-24::before {
  content: "\f1478";
}

.mdi-hubspot::before {
  content: "\f0d17";
}

.mdi-hulu::before {
  content: "\f0829";
}

.mdi-human::before {
  content: "\f02e6";
}

.mdi-human-baby-changing-table::before {
  content: "\f138b";
}

.mdi-human-cane::before {
  content: "\f1581";
}

.mdi-human-capacity-decrease::before {
  content: "\f159b";
}

.mdi-human-capacity-increase::before {
  content: "\f159c";
}

.mdi-human-child::before {
  content: "\f02e7";
}

.mdi-human-dolly::before {
  content: "\f1980";
}

.mdi-human-edit::before {
  content: "\f14e8";
}

.mdi-human-female::before {
  content: "\f0649";
}

.mdi-human-female-boy::before {
  content: "\f0a59";
}

.mdi-human-female-dance::before {
  content: "\f15c9";
}

.mdi-human-female-female::before {
  content: "\f0a5a";
}

.mdi-human-female-girl::before {
  content: "\f0a5b";
}

.mdi-human-greeting::before {
  content: "\f17c4";
}

.mdi-human-greeting-proximity::before {
  content: "\f159d";
}

.mdi-human-greeting-variant::before {
  content: "\f064a";
}

.mdi-human-handsdown::before {
  content: "\f064b";
}

.mdi-human-handsup::before {
  content: "\f064c";
}

.mdi-human-male::before {
  content: "\f064d";
}

.mdi-human-male-board::before {
  content: "\f0890";
}

.mdi-human-male-board-poll::before {
  content: "\f0846";
}

.mdi-human-male-boy::before {
  content: "\f0a5c";
}

.mdi-human-male-child::before {
  content: "\f138c";
}

.mdi-human-male-female::before {
  content: "\f02e8";
}

.mdi-human-male-female-child::before {
  content: "\f1823";
}

.mdi-human-male-girl::before {
  content: "\f0a5d";
}

.mdi-human-male-height::before {
  content: "\f0efb";
}

.mdi-human-male-height-variant::before {
  content: "\f0efc";
}

.mdi-human-male-male::before {
  content: "\f0a5e";
}

.mdi-human-non-binary::before {
  content: "\f1848";
}

.mdi-human-pregnant::before {
  content: "\f05cf";
}

.mdi-human-queue::before {
  content: "\f1571";
}

.mdi-human-scooter::before {
  content: "\f11e9";
}

.mdi-human-wheelchair::before {
  content: "\f138d";
}

.mdi-human-white-cane::before {
  content: "\f1981";
}

.mdi-humble-bundle::before {
  content: "\f0744";
}

.mdi-hvac::before {
  content: "\f1352";
}

.mdi-hvac-off::before {
  content: "\f159e";
}

.mdi-hydraulic-oil-level::before {
  content: "\f1324";
}

.mdi-hydraulic-oil-temperature::before {
  content: "\f1325";
}

.mdi-hydro-power::before {
  content: "\f12e5";
}

.mdi-hydrogen-station::before {
  content: "\f1894";
}

.mdi-ice-cream::before {
  content: "\f082a";
}

.mdi-ice-cream-off::before {
  content: "\f0e52";
}

.mdi-ice-pop::before {
  content: "\f0efd";
}

.mdi-id-card::before {
  content: "\f0fc0";
}

.mdi-identifier::before {
  content: "\f0efe";
}

.mdi-ideogram-cjk::before {
  content: "\f1331";
}

.mdi-ideogram-cjk-variant::before {
  content: "\f1332";
}

.mdi-image::before {
  content: "\f02e9";
}

.mdi-image-album::before {
  content: "\f02ea";
}

.mdi-image-area::before {
  content: "\f02eb";
}

.mdi-image-area-close::before {
  content: "\f02ec";
}

.mdi-image-auto-adjust::before {
  content: "\f0fc1";
}

.mdi-image-broken::before {
  content: "\f02ed";
}

.mdi-image-broken-variant::before {
  content: "\f02ee";
}

.mdi-image-edit::before {
  content: "\f11e3";
}

.mdi-image-edit-outline::before {
  content: "\f11e4";
}

.mdi-image-filter-black-white::before {
  content: "\f02f0";
}

.mdi-image-filter-center-focus::before {
  content: "\f02f1";
}

.mdi-image-filter-center-focus-strong::before {
  content: "\f0eff";
}

.mdi-image-filter-center-focus-strong-outline::before {
  content: "\f0f00";
}

.mdi-image-filter-center-focus-weak::before {
  content: "\f02f2";
}

.mdi-image-filter-drama::before {
  content: "\f02f3";
}

.mdi-image-filter-frames::before {
  content: "\f02f4";
}

.mdi-image-filter-hdr::before {
  content: "\f02f5";
}

.mdi-image-filter-none::before {
  content: "\f02f6";
}

.mdi-image-filter-tilt-shift::before {
  content: "\f02f7";
}

.mdi-image-filter-vintage::before {
  content: "\f02f8";
}

.mdi-image-frame::before {
  content: "\f0e49";
}

.mdi-image-marker::before {
  content: "\f177b";
}

.mdi-image-marker-outline::before {
  content: "\f177c";
}

.mdi-image-minus::before {
  content: "\f1419";
}

.mdi-image-move::before {
  content: "\f09f8";
}

.mdi-image-multiple::before {
  content: "\f02f9";
}

.mdi-image-multiple-outline::before {
  content: "\f02ef";
}

.mdi-image-off::before {
  content: "\f082b";
}

.mdi-image-off-outline::before {
  content: "\f11d1";
}

.mdi-image-outline::before {
  content: "\f0976";
}

.mdi-image-plus::before {
  content: "\f087c";
}

.mdi-image-remove::before {
  content: "\f1418";
}

.mdi-image-search::before {
  content: "\f0977";
}

.mdi-image-search-outline::before {
  content: "\f0978";
}

.mdi-image-size-select-actual::before {
  content: "\f0c8d";
}

.mdi-image-size-select-large::before {
  content: "\f0c8e";
}

.mdi-image-size-select-small::before {
  content: "\f0c8f";
}

.mdi-image-text::before {
  content: "\f160d";
}

.mdi-import::before {
  content: "\f02fa";
}

.mdi-inbox::before {
  content: "\f0687";
}

.mdi-inbox-arrow-down::before {
  content: "\f02fb";
}

.mdi-inbox-arrow-down-outline::before {
  content: "\f1270";
}

.mdi-inbox-arrow-up::before {
  content: "\f03d1";
}

.mdi-inbox-arrow-up-outline::before {
  content: "\f1271";
}

.mdi-inbox-full::before {
  content: "\f1272";
}

.mdi-inbox-full-outline::before {
  content: "\f1273";
}

.mdi-inbox-multiple::before {
  content: "\f08b0";
}

.mdi-inbox-multiple-outline::before {
  content: "\f0ba8";
}

.mdi-inbox-outline::before {
  content: "\f1274";
}

.mdi-inbox-remove::before {
  content: "\f159f";
}

.mdi-inbox-remove-outline::before {
  content: "\f15a0";
}

.mdi-incognito::before {
  content: "\f05f9";
}

.mdi-incognito-circle::before {
  content: "\f1421";
}

.mdi-incognito-circle-off::before {
  content: "\f1422";
}

.mdi-incognito-off::before {
  content: "\f0075";
}

.mdi-induction::before {
  content: "\f184c";
}

.mdi-infinity::before {
  content: "\f06e4";
}

.mdi-information::before {
  content: "\f02fc";
}

.mdi-information-off::before {
  content: "\f178c";
}

.mdi-information-off-outline::before {
  content: "\f178d";
}

.mdi-information-outline::before {
  content: "\f02fd";
}

.mdi-information-variant::before {
  content: "\f064e";
}

.mdi-instagram::before {
  content: "\f02fe";
}

.mdi-instrument-triangle::before {
  content: "\f104e";
}

.mdi-integrated-circuit-chip::before {
  content: "\f1913";
}

.mdi-invert-colors::before {
  content: "\f0301";
}

.mdi-invert-colors-off::before {
  content: "\f0e4a";
}

.mdi-iobroker::before {
  content: "\f12e8";
}

.mdi-ip::before {
  content: "\f0a5f";
}

.mdi-ip-network::before {
  content: "\f0a60";
}

.mdi-ip-network-outline::before {
  content: "\f0c90";
}

.mdi-ip-outline::before {
  content: "\f1982";
}

.mdi-ipod::before {
  content: "\f0c91";
}

.mdi-iron::before {
  content: "\f1824";
}

.mdi-iron-board::before {
  content: "\f1838";
}

.mdi-iron-outline::before {
  content: "\f1825";
}

.mdi-island::before {
  content: "\f104f";
}

.mdi-iv-bag::before {
  content: "\f10b9";
}

.mdi-jabber::before {
  content: "\f0dd5";
}

.mdi-jeepney::before {
  content: "\f0302";
}

.mdi-jellyfish::before {
  content: "\f0f01";
}

.mdi-jellyfish-outline::before {
  content: "\f0f02";
}

.mdi-jira::before {
  content: "\f0303";
}

.mdi-jquery::before {
  content: "\f087d";
}

.mdi-jsfiddle::before {
  content: "\f0304";
}

.mdi-jump-rope::before {
  content: "\f12ff";
}

.mdi-kabaddi::before {
  content: "\f0d87";
}

.mdi-kangaroo::before {
  content: "\f1558";
}

.mdi-karate::before {
  content: "\f082c";
}

.mdi-kayaking::before {
  content: "\f08af";
}

.mdi-keg::before {
  content: "\f0305";
}

.mdi-kettle::before {
  content: "\f05fa";
}

.mdi-kettle-alert::before {
  content: "\f1317";
}

.mdi-kettle-alert-outline::before {
  content: "\f1318";
}

.mdi-kettle-off::before {
  content: "\f131b";
}

.mdi-kettle-off-outline::before {
  content: "\f131c";
}

.mdi-kettle-outline::before {
  content: "\f0f56";
}

.mdi-kettle-pour-over::before {
  content: "\f173c";
}

.mdi-kettle-steam::before {
  content: "\f1319";
}

.mdi-kettle-steam-outline::before {
  content: "\f131a";
}

.mdi-kettlebell::before {
  content: "\f1300";
}

.mdi-key::before {
  content: "\f0306";
}

.mdi-key-alert::before {
  content: "\f1983";
}

.mdi-key-alert-outline::before {
  content: "\f1984";
}

.mdi-key-arrow-right::before {
  content: "\f1312";
}

.mdi-key-chain::before {
  content: "\f1574";
}

.mdi-key-chain-variant::before {
  content: "\f1575";
}

.mdi-key-change::before {
  content: "\f0307";
}

.mdi-key-link::before {
  content: "\f119f";
}

.mdi-key-minus::before {
  content: "\f0308";
}

.mdi-key-outline::before {
  content: "\f0dd6";
}

.mdi-key-plus::before {
  content: "\f0309";
}

.mdi-key-remove::before {
  content: "\f030a";
}

.mdi-key-star::before {
  content: "\f119e";
}

.mdi-key-variant::before {
  content: "\f030b";
}

.mdi-key-wireless::before {
  content: "\f0fc2";
}

.mdi-keyboard::before {
  content: "\f030c";
}

.mdi-keyboard-backspace::before {
  content: "\f030d";
}

.mdi-keyboard-caps::before {
  content: "\f030e";
}

.mdi-keyboard-close::before {
  content: "\f030f";
}

.mdi-keyboard-esc::before {
  content: "\f12b7";
}

.mdi-keyboard-f1::before {
  content: "\f12ab";
}

.mdi-keyboard-f10::before {
  content: "\f12b4";
}

.mdi-keyboard-f11::before {
  content: "\f12b5";
}

.mdi-keyboard-f12::before {
  content: "\f12b6";
}

.mdi-keyboard-f2::before {
  content: "\f12ac";
}

.mdi-keyboard-f3::before {
  content: "\f12ad";
}

.mdi-keyboard-f4::before {
  content: "\f12ae";
}

.mdi-keyboard-f5::before {
  content: "\f12af";
}

.mdi-keyboard-f6::before {
  content: "\f12b0";
}

.mdi-keyboard-f7::before {
  content: "\f12b1";
}

.mdi-keyboard-f8::before {
  content: "\f12b2";
}

.mdi-keyboard-f9::before {
  content: "\f12b3";
}

.mdi-keyboard-off::before {
  content: "\f0310";
}

.mdi-keyboard-off-outline::before {
  content: "\f0e4b";
}

.mdi-keyboard-outline::before {
  content: "\f097b";
}

.mdi-keyboard-return::before {
  content: "\f0311";
}

.mdi-keyboard-settings::before {
  content: "\f09f9";
}

.mdi-keyboard-settings-outline::before {
  content: "\f09fa";
}

.mdi-keyboard-space::before {
  content: "\f1050";
}

.mdi-keyboard-tab::before {
  content: "\f0312";
}

.mdi-keyboard-tab-reverse::before {
  content: "\f0325";
}

.mdi-keyboard-variant::before {
  content: "\f0313";
}

.mdi-khanda::before {
  content: "\f10fd";
}

.mdi-kickstarter::before {
  content: "\f0745";
}

.mdi-kite::before {
  content: "\f1985";
}

.mdi-kite-outline::before {
  content: "\f1986";
}

.mdi-kitesurfing::before {
  content: "\f1744";
}

.mdi-klingon::before {
  content: "\f135b";
}

.mdi-knife::before {
  content: "\f09fb";
}

.mdi-knife-military::before {
  content: "\f09fc";
}

.mdi-koala::before {
  content: "\f173f";
}

.mdi-kodi::before {
  content: "\f0314";
}

.mdi-kubernetes::before {
  content: "\f10fe";
}

.mdi-label::before {
  content: "\f0315";
}

.mdi-label-multiple::before {
  content: "\f1375";
}

.mdi-label-multiple-outline::before {
  content: "\f1376";
}

.mdi-label-off::before {
  content: "\f0acb";
}

.mdi-label-off-outline::before {
  content: "\f0acc";
}

.mdi-label-outline::before {
  content: "\f0316";
}

.mdi-label-percent::before {
  content: "\f12ea";
}

.mdi-label-percent-outline::before {
  content: "\f12eb";
}

.mdi-label-variant::before {
  content: "\f0acd";
}

.mdi-label-variant-outline::before {
  content: "\f0ace";
}

.mdi-ladder::before {
  content: "\f15a2";
}

.mdi-ladybug::before {
  content: "\f082d";
}

.mdi-lambda::before {
  content: "\f0627";
}

.mdi-lamp::before {
  content: "\f06b5";
}

.mdi-lamp-outline::before {
  content: "\f17d0";
}

.mdi-lamps::before {
  content: "\f1576";
}

.mdi-lamps-outline::before {
  content: "\f17d1";
}

.mdi-lan::before {
  content: "\f0317";
}

.mdi-lan-check::before {
  content: "\f12aa";
}

.mdi-lan-connect::before {
  content: "\f0318";
}

.mdi-lan-disconnect::before {
  content: "\f0319";
}

.mdi-lan-pending::before {
  content: "\f031a";
}

.mdi-language-c::before {
  content: "\f0671";
}

.mdi-language-cpp::before {
  content: "\f0672";
}

.mdi-language-csharp::before {
  content: "\f031b";
}

.mdi-language-css3::before {
  content: "\f031c";
}

.mdi-language-fortran::before {
  content: "\f121a";
}

.mdi-language-go::before {
  content: "\f07d3";
}

.mdi-language-haskell::before {
  content: "\f0c92";
}

.mdi-language-html5::before {
  content: "\f031d";
}

.mdi-language-java::before {
  content: "\f0b37";
}

.mdi-language-javascript::before {
  content: "\f031e";
}

.mdi-language-kotlin::before {
  content: "\f1219";
}

.mdi-language-lua::before {
  content: "\f08b1";
}

.mdi-language-markdown::before {
  content: "\f0354";
}

.mdi-language-markdown-outline::before {
  content: "\f0f5b";
}

.mdi-language-php::before {
  content: "\f031f";
}

.mdi-language-python::before {
  content: "\f0320";
}

.mdi-language-r::before {
  content: "\f07d4";
}

.mdi-language-ruby::before {
  content: "\f0d2d";
}

.mdi-language-ruby-on-rails::before {
  content: "\f0acf";
}

.mdi-language-rust::before {
  content: "\f1617";
}

.mdi-language-swift::before {
  content: "\f06e5";
}

.mdi-language-typescript::before {
  content: "\f06e6";
}

.mdi-language-xaml::before {
  content: "\f0673";
}

.mdi-laptop::before {
  content: "\f0322";
}

.mdi-laptop-off::before {
  content: "\f06e7";
}

.mdi-laravel::before {
  content: "\f0ad0";
}

.mdi-laser-pointer::before {
  content: "\f1484";
}

.mdi-lasso::before {
  content: "\f0f03";
}

.mdi-lastpass::before {
  content: "\f0446";
}

.mdi-latitude::before {
  content: "\f0f57";
}

.mdi-launch::before {
  content: "\f0327";
}

.mdi-lava-lamp::before {
  content: "\f07d5";
}

.mdi-layers::before {
  content: "\f0328";
}

.mdi-layers-edit::before {
  content: "\f1892";
}

.mdi-layers-minus::before {
  content: "\f0e4c";
}

.mdi-layers-off::before {
  content: "\f0329";
}

.mdi-layers-off-outline::before {
  content: "\f09fd";
}

.mdi-layers-outline::before {
  content: "\f09fe";
}

.mdi-layers-plus::before {
  content: "\f0e4d";
}

.mdi-layers-remove::before {
  content: "\f0e4e";
}

.mdi-layers-search::before {
  content: "\f1206";
}

.mdi-layers-search-outline::before {
  content: "\f1207";
}

.mdi-layers-triple::before {
  content: "\f0f58";
}

.mdi-layers-triple-outline::before {
  content: "\f0f59";
}

.mdi-lead-pencil::before {
  content: "\f064f";
}

.mdi-leaf::before {
  content: "\f032a";
}

.mdi-leaf-circle::before {
  content: "\f1905";
}

.mdi-leaf-circle-outline::before {
  content: "\f1906";
}

.mdi-leaf-maple::before {
  content: "\f0c93";
}

.mdi-leaf-maple-off::before {
  content: "\f12da";
}

.mdi-leaf-off::before {
  content: "\f12d9";
}

.mdi-leak::before {
  content: "\f0dd7";
}

.mdi-leak-off::before {
  content: "\f0dd8";
}

.mdi-led-off::before {
  content: "\f032b";
}

.mdi-led-on::before {
  content: "\f032c";
}

.mdi-led-outline::before {
  content: "\f032d";
}

.mdi-led-strip::before {
  content: "\f07d6";
}

.mdi-led-strip-variant::before {
  content: "\f1051";
}

.mdi-led-variant-off::before {
  content: "\f032e";
}

.mdi-led-variant-on::before {
  content: "\f032f";
}

.mdi-led-variant-outline::before {
  content: "\f0330";
}

.mdi-leek::before {
  content: "\f117d";
}

.mdi-less-than::before {
  content: "\f097c";
}

.mdi-less-than-or-equal::before {
  content: "\f097d";
}

.mdi-library::before {
  content: "\f0331";
}

.mdi-library-shelves::before {
  content: "\f0ba9";
}

.mdi-license::before {
  content: "\f0fc3";
}

.mdi-lifebuoy::before {
  content: "\f087e";
}

.mdi-light-flood-down::before {
  content: "\f1987";
}

.mdi-light-flood-up::before {
  content: "\f1988";
}

.mdi-light-recessed::before {
  content: "\f179b";
}

.mdi-light-switch::before {
  content: "\f097e";
}

.mdi-lightbulb::before {
  content: "\f0335";
}

.mdi-lightbulb-auto::before {
  content: "\f1800";
}

.mdi-lightbulb-auto-outline::before {
  content: "\f1801";
}

.mdi-lightbulb-cfl::before {
  content: "\f1208";
}

.mdi-lightbulb-cfl-off::before {
  content: "\f1209";
}

.mdi-lightbulb-cfl-spiral::before {
  content: "\f1275";
}

.mdi-lightbulb-cfl-spiral-off::before {
  content: "\f12c3";
}

.mdi-lightbulb-fluorescent-tube::before {
  content: "\f1804";
}

.mdi-lightbulb-fluorescent-tube-outline::before {
  content: "\f1805";
}

.mdi-lightbulb-group::before {
  content: "\f1253";
}

.mdi-lightbulb-group-off::before {
  content: "\f12cd";
}

.mdi-lightbulb-group-off-outline::before {
  content: "\f12ce";
}

.mdi-lightbulb-group-outline::before {
  content: "\f1254";
}

.mdi-lightbulb-multiple::before {
  content: "\f1255";
}

.mdi-lightbulb-multiple-off::before {
  content: "\f12cf";
}

.mdi-lightbulb-multiple-off-outline::before {
  content: "\f12d0";
}

.mdi-lightbulb-multiple-outline::before {
  content: "\f1256";
}

.mdi-lightbulb-off::before {
  content: "\f0e4f";
}

.mdi-lightbulb-off-outline::before {
  content: "\f0e50";
}

.mdi-lightbulb-on::before {
  content: "\f06e8";
}

.mdi-lightbulb-on-outline::before {
  content: "\f06e9";
}

.mdi-lightbulb-outline::before {
  content: "\f0336";
}

.mdi-lightbulb-spot::before {
  content: "\f17f4";
}

.mdi-lightbulb-spot-off::before {
  content: "\f17f5";
}

.mdi-lightbulb-variant::before {
  content: "\f1802";
}

.mdi-lightbulb-variant-outline::before {
  content: "\f1803";
}

.mdi-lighthouse::before {
  content: "\f09ff";
}

.mdi-lighthouse-on::before {
  content: "\f0a00";
}

.mdi-lightning-bolt::before {
  content: "\f140b";
}

.mdi-lightning-bolt-circle::before {
  content: "\f0820";
}

.mdi-lightning-bolt-outline::before {
  content: "\f140c";
}

.mdi-line-scan::before {
  content: "\f0624";
}

.mdi-lingerie::before {
  content: "\f1476";
}

.mdi-link::before {
  content: "\f0337";
}

.mdi-link-box::before {
  content: "\f0d1a";
}

.mdi-link-box-outline::before {
  content: "\f0d1b";
}

.mdi-link-box-variant::before {
  content: "\f0d1c";
}

.mdi-link-box-variant-outline::before {
  content: "\f0d1d";
}

.mdi-link-lock::before {
  content: "\f10ba";
}

.mdi-link-off::before {
  content: "\f0338";
}

.mdi-link-plus::before {
  content: "\f0c94";
}

.mdi-link-variant::before {
  content: "\f0339";
}

.mdi-link-variant-minus::before {
  content: "\f10ff";
}

.mdi-link-variant-off::before {
  content: "\f033a";
}

.mdi-link-variant-plus::before {
  content: "\f1100";
}

.mdi-link-variant-remove::before {
  content: "\f1101";
}

.mdi-linkedin::before {
  content: "\f033b";
}

.mdi-linux::before {
  content: "\f033d";
}

.mdi-linux-mint::before {
  content: "\f08ed";
}

.mdi-lipstick::before {
  content: "\f13b5";
}

.mdi-liquid-spot::before {
  content: "\f1826";
}

.mdi-liquor::before {
  content: "\f191e";
}

.mdi-list-status::before {
  content: "\f15ab";
}

.mdi-litecoin::before {
  content: "\f0a61";
}

.mdi-loading::before {
  content: "\f0772";
}

.mdi-location-enter::before {
  content: "\f0fc4";
}

.mdi-location-exit::before {
  content: "\f0fc5";
}

.mdi-lock::before {
  content: "\f033e";
}

.mdi-lock-alert::before {
  content: "\f08ee";
}

.mdi-lock-alert-outline::before {
  content: "\f15d1";
}

.mdi-lock-check::before {
  content: "\f139a";
}

.mdi-lock-check-outline::before {
  content: "\f16a8";
}

.mdi-lock-clock::before {
  content: "\f097f";
}

.mdi-lock-minus::before {
  content: "\f16a9";
}

.mdi-lock-minus-outline::before {
  content: "\f16aa";
}

.mdi-lock-off::before {
  content: "\f1671";
}

.mdi-lock-off-outline::before {
  content: "\f1672";
}

.mdi-lock-open::before {
  content: "\f033f";
}

.mdi-lock-open-alert::before {
  content: "\f139b";
}

.mdi-lock-open-alert-outline::before {
  content: "\f15d2";
}

.mdi-lock-open-check::before {
  content: "\f139c";
}

.mdi-lock-open-check-outline::before {
  content: "\f16ab";
}

.mdi-lock-open-minus::before {
  content: "\f16ac";
}

.mdi-lock-open-minus-outline::before {
  content: "\f16ad";
}

.mdi-lock-open-outline::before {
  content: "\f0340";
}

.mdi-lock-open-plus::before {
  content: "\f16ae";
}

.mdi-lock-open-plus-outline::before {
  content: "\f16af";
}

.mdi-lock-open-remove::before {
  content: "\f16b0";
}

.mdi-lock-open-remove-outline::before {
  content: "\f16b1";
}

.mdi-lock-open-variant::before {
  content: "\f0fc6";
}

.mdi-lock-open-variant-outline::before {
  content: "\f0fc7";
}

.mdi-lock-outline::before {
  content: "\f0341";
}

.mdi-lock-pattern::before {
  content: "\f06ea";
}

.mdi-lock-plus::before {
  content: "\f05fb";
}

.mdi-lock-plus-outline::before {
  content: "\f16b2";
}

.mdi-lock-question::before {
  content: "\f08ef";
}

.mdi-lock-remove::before {
  content: "\f16b3";
}

.mdi-lock-remove-outline::before {
  content: "\f16b4";
}

.mdi-lock-reset::before {
  content: "\f0773";
}

.mdi-lock-smart::before {
  content: "\f08b2";
}

.mdi-locker::before {
  content: "\f07d7";
}

.mdi-locker-multiple::before {
  content: "\f07d8";
}

.mdi-login::before {
  content: "\f0342";
}

.mdi-login-variant::before {
  content: "\f05fc";
}

.mdi-logout::before {
  content: "\f0343";
}

.mdi-logout-variant::before {
  content: "\f05fd";
}

.mdi-longitude::before {
  content: "\f0f5a";
}

.mdi-looks::before {
  content: "\f0344";
}

.mdi-lotion::before {
  content: "\f1582";
}

.mdi-lotion-outline::before {
  content: "\f1583";
}

.mdi-lotion-plus::before {
  content: "\f1584";
}

.mdi-lotion-plus-outline::before {
  content: "\f1585";
}

.mdi-loupe::before {
  content: "\f0345";
}

.mdi-lumx::before {
  content: "\f0346";
}

.mdi-lungs::before {
  content: "\f1084";
}

.mdi-mace::before {
  content: "\f1843";
}

.mdi-magazine-pistol::before {
  content: "\f0324";
}

.mdi-magazine-rifle::before {
  content: "\f0323";
}

.mdi-magic-staff::before {
  content: "\f1844";
}

.mdi-magnet::before {
  content: "\f0347";
}

.mdi-magnet-on::before {
  content: "\f0348";
}

.mdi-magnify::before {
  content: "\f0349";
}

.mdi-magnify-close::before {
  content: "\f0980";
}

.mdi-magnify-expand::before {
  content: "\f1874";
}

.mdi-magnify-minus::before {
  content: "\f034a";
}

.mdi-magnify-minus-cursor::before {
  content: "\f0a62";
}

.mdi-magnify-minus-outline::before {
  content: "\f06ec";
}

.mdi-magnify-plus::before {
  content: "\f034b";
}

.mdi-magnify-plus-cursor::before {
  content: "\f0a63";
}

.mdi-magnify-plus-outline::before {
  content: "\f06ed";
}

.mdi-magnify-remove-cursor::before {
  content: "\f120c";
}

.mdi-magnify-remove-outline::before {
  content: "\f120d";
}

.mdi-magnify-scan::before {
  content: "\f1276";
}

.mdi-mail::before {
  content: "\f0ebb";
}

.mdi-mailbox::before {
  content: "\f06ee";
}

.mdi-mailbox-open::before {
  content: "\f0d88";
}

.mdi-mailbox-open-outline::before {
  content: "\f0d89";
}

.mdi-mailbox-open-up::before {
  content: "\f0d8a";
}

.mdi-mailbox-open-up-outline::before {
  content: "\f0d8b";
}

.mdi-mailbox-outline::before {
  content: "\f0d8c";
}

.mdi-mailbox-up::before {
  content: "\f0d8d";
}

.mdi-mailbox-up-outline::before {
  content: "\f0d8e";
}

.mdi-manjaro::before {
  content: "\f160a";
}

.mdi-map::before {
  content: "\f034d";
}

.mdi-map-check::before {
  content: "\f0ebc";
}

.mdi-map-check-outline::before {
  content: "\f0ebd";
}

.mdi-map-clock::before {
  content: "\f0d1e";
}

.mdi-map-clock-outline::before {
  content: "\f0d1f";
}

.mdi-map-legend::before {
  content: "\f0a01";
}

.mdi-map-marker::before {
  content: "\f034e";
}

.mdi-map-marker-account::before {
  content: "\f18e3";
}

.mdi-map-marker-account-outline::before {
  content: "\f18e4";
}

.mdi-map-marker-alert::before {
  content: "\f0f05";
}

.mdi-map-marker-alert-outline::before {
  content: "\f0f06";
}

.mdi-map-marker-check::before {
  content: "\f0c95";
}

.mdi-map-marker-check-outline::before {
  content: "\f12fb";
}

.mdi-map-marker-circle::before {
  content: "\f034f";
}

.mdi-map-marker-distance::before {
  content: "\f08f0";
}

.mdi-map-marker-down::before {
  content: "\f1102";
}

.mdi-map-marker-left::before {
  content: "\f12db";
}

.mdi-map-marker-left-outline::before {
  content: "\f12dd";
}

.mdi-map-marker-minus::before {
  content: "\f0650";
}

.mdi-map-marker-minus-outline::before {
  content: "\f12f9";
}

.mdi-map-marker-multiple::before {
  content: "\f0350";
}

.mdi-map-marker-multiple-outline::before {
  content: "\f1277";
}

.mdi-map-marker-off::before {
  content: "\f0351";
}

.mdi-map-marker-off-outline::before {
  content: "\f12fd";
}

.mdi-map-marker-outline::before {
  content: "\f07d9";
}

.mdi-map-marker-path::before {
  content: "\f0d20";
}

.mdi-map-marker-plus::before {
  content: "\f0651";
}

.mdi-map-marker-plus-outline::before {
  content: "\f12f8";
}

.mdi-map-marker-question::before {
  content: "\f0f07";
}

.mdi-map-marker-question-outline::before {
  content: "\f0f08";
}

.mdi-map-marker-radius::before {
  content: "\f0352";
}

.mdi-map-marker-radius-outline::before {
  content: "\f12fc";
}

.mdi-map-marker-remove::before {
  content: "\f0f09";
}

.mdi-map-marker-remove-outline::before {
  content: "\f12fa";
}

.mdi-map-marker-remove-variant::before {
  content: "\f0f0a";
}

.mdi-map-marker-right::before {
  content: "\f12dc";
}

.mdi-map-marker-right-outline::before {
  content: "\f12de";
}

.mdi-map-marker-star::before {
  content: "\f1608";
}

.mdi-map-marker-star-outline::before {
  content: "\f1609";
}

.mdi-map-marker-up::before {
  content: "\f1103";
}

.mdi-map-minus::before {
  content: "\f0981";
}

.mdi-map-outline::before {
  content: "\f0982";
}

.mdi-map-plus::before {
  content: "\f0983";
}

.mdi-map-search::before {
  content: "\f0984";
}

.mdi-map-search-outline::before {
  content: "\f0985";
}

.mdi-mapbox::before {
  content: "\f0baa";
}

.mdi-margin::before {
  content: "\f0353";
}

.mdi-marker::before {
  content: "\f0652";
}

.mdi-marker-cancel::before {
  content: "\f0dd9";
}

.mdi-marker-check::before {
  content: "\f0355";
}

.mdi-mastodon::before {
  content: "\f0ad1";
}

.mdi-material-design::before {
  content: "\f0986";
}

.mdi-material-ui::before {
  content: "\f0357";
}

.mdi-math-compass::before {
  content: "\f0358";
}

.mdi-math-cos::before {
  content: "\f0c96";
}

.mdi-math-integral::before {
  content: "\f0fc8";
}

.mdi-math-integral-box::before {
  content: "\f0fc9";
}

.mdi-math-log::before {
  content: "\f1085";
}

.mdi-math-norm::before {
  content: "\f0fca";
}

.mdi-math-norm-box::before {
  content: "\f0fcb";
}

.mdi-math-sin::before {
  content: "\f0c97";
}

.mdi-math-tan::before {
  content: "\f0c98";
}

.mdi-matrix::before {
  content: "\f0628";
}

.mdi-medal::before {
  content: "\f0987";
}

.mdi-medal-outline::before {
  content: "\f1326";
}

.mdi-medical-bag::before {
  content: "\f06ef";
}

.mdi-meditation::before {
  content: "\f117b";
}

.mdi-memory::before {
  content: "\f035b";
}

.mdi-menorah::before {
  content: "\f17d4";
}

.mdi-menorah-fire::before {
  content: "\f17d5";
}

.mdi-menu::before {
  content: "\f035c";
}

.mdi-menu-down::before {
  content: "\f035d";
}

.mdi-menu-down-outline::before {
  content: "\f06b6";
}

.mdi-menu-left::before {
  content: "\f035e";
}

.mdi-menu-left-outline::before {
  content: "\f0a02";
}

.mdi-menu-open::before {
  content: "\f0bab";
}

.mdi-menu-right::before {
  content: "\f035f";
}

.mdi-menu-right-outline::before {
  content: "\f0a03";
}

.mdi-menu-swap::before {
  content: "\f0a64";
}

.mdi-menu-swap-outline::before {
  content: "\f0a65";
}

.mdi-menu-up::before {
  content: "\f0360";
}

.mdi-menu-up-outline::before {
  content: "\f06b7";
}

.mdi-merge::before {
  content: "\f0f5c";
}

.mdi-message::before {
  content: "\f0361";
}

.mdi-message-alert::before {
  content: "\f0362";
}

.mdi-message-alert-outline::before {
  content: "\f0a04";
}

.mdi-message-arrow-left::before {
  content: "\f12f2";
}

.mdi-message-arrow-left-outline::before {
  content: "\f12f3";
}

.mdi-message-arrow-right::before {
  content: "\f12f4";
}

.mdi-message-arrow-right-outline::before {
  content: "\f12f5";
}

.mdi-message-badge::before {
  content: "\f1941";
}

.mdi-message-badge-outline::before {
  content: "\f1942";
}

.mdi-message-bookmark::before {
  content: "\f15ac";
}

.mdi-message-bookmark-outline::before {
  content: "\f15ad";
}

.mdi-message-bulleted::before {
  content: "\f06a2";
}

.mdi-message-bulleted-off::before {
  content: "\f06a3";
}

.mdi-message-cog::before {
  content: "\f06f1";
}

.mdi-message-cog-outline::before {
  content: "\f1172";
}

.mdi-message-draw::before {
  content: "\f0363";
}

.mdi-message-flash::before {
  content: "\f15a9";
}

.mdi-message-flash-outline::before {
  content: "\f15aa";
}

.mdi-message-image::before {
  content: "\f0364";
}

.mdi-message-image-outline::before {
  content: "\f116c";
}

.mdi-message-lock::before {
  content: "\f0fcc";
}

.mdi-message-lock-outline::before {
  content: "\f116d";
}

.mdi-message-minus::before {
  content: "\f116e";
}

.mdi-message-minus-outline::before {
  content: "\f116f";
}

.mdi-message-off::before {
  content: "\f164d";
}

.mdi-message-off-outline::before {
  content: "\f164e";
}

.mdi-message-outline::before {
  content: "\f0365";
}

.mdi-message-plus::before {
  content: "\f0653";
}

.mdi-message-plus-outline::before {
  content: "\f10bb";
}

.mdi-message-processing::before {
  content: "\f0366";
}

.mdi-message-processing-outline::before {
  content: "\f1170";
}

.mdi-message-question::before {
  content: "\f173a";
}

.mdi-message-question-outline::before {
  content: "\f173b";
}

.mdi-message-reply::before {
  content: "\f0367";
}

.mdi-message-reply-outline::before {
  content: "\f173d";
}

.mdi-message-reply-text::before {
  content: "\f0368";
}

.mdi-message-reply-text-outline::before {
  content: "\f173e";
}

.mdi-message-settings::before {
  content: "\f06f0";
}

.mdi-message-settings-outline::before {
  content: "\f1171";
}

.mdi-message-star::before {
  content: "\f069a";
}

.mdi-message-star-outline::before {
  content: "\f1250";
}

.mdi-message-text::before {
  content: "\f0369";
}

.mdi-message-text-clock::before {
  content: "\f1173";
}

.mdi-message-text-clock-outline::before {
  content: "\f1174";
}

.mdi-message-text-lock::before {
  content: "\f0fcd";
}

.mdi-message-text-lock-outline::before {
  content: "\f1175";
}

.mdi-message-text-outline::before {
  content: "\f036a";
}

.mdi-message-video::before {
  content: "\f036b";
}

.mdi-meteor::before {
  content: "\f0629";
}

.mdi-metronome::before {
  content: "\f07da";
}

.mdi-metronome-tick::before {
  content: "\f07db";
}

.mdi-micro-sd::before {
  content: "\f07dc";
}

.mdi-microphone::before {
  content: "\f036c";
}

.mdi-microphone-minus::before {
  content: "\f08b3";
}

.mdi-microphone-off::before {
  content: "\f036d";
}

.mdi-microphone-outline::before {
  content: "\f036e";
}

.mdi-microphone-plus::before {
  content: "\f08b4";
}

.mdi-microphone-question::before {
  content: "\f1989";
}

.mdi-microphone-question-outline::before {
  content: "\f198a";
}

.mdi-microphone-settings::before {
  content: "\f036f";
}

.mdi-microphone-variant::before {
  content: "\f0370";
}

.mdi-microphone-variant-off::before {
  content: "\f0371";
}

.mdi-microscope::before {
  content: "\f0654";
}

.mdi-microsoft::before {
  content: "\f0372";
}

.mdi-microsoft-access::before {
  content: "\f138e";
}

.mdi-microsoft-azure::before {
  content: "\f0805";
}

.mdi-microsoft-azure-devops::before {
  content: "\f0fd5";
}

.mdi-microsoft-bing::before {
  content: "\f00a4";
}

.mdi-microsoft-dynamics-365::before {
  content: "\f0988";
}

.mdi-microsoft-edge::before {
  content: "\f01e9";
}

.mdi-microsoft-excel::before {
  content: "\f138f";
}

.mdi-microsoft-internet-explorer::before {
  content: "\f0300";
}

.mdi-microsoft-office::before {
  content: "\f03c6";
}

.mdi-microsoft-onedrive::before {
  content: "\f03ca";
}

.mdi-microsoft-onenote::before {
  content: "\f0747";
}

.mdi-microsoft-outlook::before {
  content: "\f0d22";
}

.mdi-microsoft-powerpoint::before {
  content: "\f1390";
}

.mdi-microsoft-sharepoint::before {
  content: "\f1391";
}

.mdi-microsoft-teams::before {
  content: "\f02bb";
}

.mdi-microsoft-visual-studio::before {
  content: "\f0610";
}

.mdi-microsoft-visual-studio-code::before {
  content: "\f0a1e";
}

.mdi-microsoft-windows::before {
  content: "\f05b3";
}

.mdi-microsoft-windows-classic::before {
  content: "\f0a21";
}

.mdi-microsoft-word::before {
  content: "\f1392";
}

.mdi-microsoft-xbox::before {
  content: "\f05b9";
}

.mdi-microsoft-xbox-controller::before {
  content: "\f05ba";
}

.mdi-microsoft-xbox-controller-battery-alert::before {
  content: "\f074b";
}

.mdi-microsoft-xbox-controller-battery-charging::before {
  content: "\f0a22";
}

.mdi-microsoft-xbox-controller-battery-empty::before {
  content: "\f074c";
}

.mdi-microsoft-xbox-controller-battery-full::before {
  content: "\f074d";
}

.mdi-microsoft-xbox-controller-battery-low::before {
  content: "\f074e";
}

.mdi-microsoft-xbox-controller-battery-medium::before {
  content: "\f074f";
}

.mdi-microsoft-xbox-controller-battery-unknown::before {
  content: "\f0750";
}

.mdi-microsoft-xbox-controller-menu::before {
  content: "\f0e6f";
}

.mdi-microsoft-xbox-controller-off::before {
  content: "\f05bb";
}

.mdi-microsoft-xbox-controller-view::before {
  content: "\f0e70";
}

.mdi-microwave::before {
  content: "\f0c99";
}

.mdi-microwave-off::before {
  content: "\f1423";
}

.mdi-middleware::before {
  content: "\f0f5d";
}

.mdi-middleware-outline::before {
  content: "\f0f5e";
}

.mdi-midi::before {
  content: "\f08f1";
}

.mdi-midi-port::before {
  content: "\f08f2";
}

.mdi-mine::before {
  content: "\f0dda";
}

.mdi-minecraft::before {
  content: "\f0373";
}

.mdi-mini-sd::before {
  content: "\f0a05";
}

.mdi-minidisc::before {
  content: "\f0a06";
}

.mdi-minus::before {
  content: "\f0374";
}

.mdi-minus-box::before {
  content: "\f0375";
}

.mdi-minus-box-multiple::before {
  content: "\f1141";
}

.mdi-minus-box-multiple-outline::before {
  content: "\f1142";
}

.mdi-minus-box-outline::before {
  content: "\f06f2";
}

.mdi-minus-circle::before {
  content: "\f0376";
}

.mdi-minus-circle-multiple::before {
  content: "\f035a";
}

.mdi-minus-circle-multiple-outline::before {
  content: "\f0ad3";
}

.mdi-minus-circle-off::before {
  content: "\f1459";
}

.mdi-minus-circle-off-outline::before {
  content: "\f145a";
}

.mdi-minus-circle-outline::before {
  content: "\f0377";
}

.mdi-minus-network::before {
  content: "\f0378";
}

.mdi-minus-network-outline::before {
  content: "\f0c9a";
}

.mdi-minus-thick::before {
  content: "\f1639";
}

.mdi-mirror::before {
  content: "\f11fd";
}

.mdi-mirror-rectangle::before {
  content: "\f179f";
}

.mdi-mirror-variant::before {
  content: "\f17a0";
}

.mdi-mixed-martial-arts::before {
  content: "\f0d8f";
}

.mdi-mixed-reality::before {
  content: "\f087f";
}

.mdi-molecule::before {
  content: "\f0bac";
}

.mdi-molecule-co::before {
  content: "\f12fe";
}

.mdi-molecule-co2::before {
  content: "\f07e4";
}

.mdi-monitor::before {
  content: "\f0379";
}

.mdi-monitor-cellphone::before {
  content: "\f0989";
}

.mdi-monitor-cellphone-star::before {
  content: "\f098a";
}

.mdi-monitor-dashboard::before {
  content: "\f0a07";
}

.mdi-monitor-edit::before {
  content: "\f12c6";
}

.mdi-monitor-eye::before {
  content: "\f13b4";
}

.mdi-monitor-lock::before {
  content: "\f0ddb";
}

.mdi-monitor-multiple::before {
  content: "\f037a";
}

.mdi-monitor-off::before {
  content: "\f0d90";
}

.mdi-monitor-screenshot::before {
  content: "\f0e51";
}

.mdi-monitor-share::before {
  content: "\f1483";
}

.mdi-monitor-shimmer::before {
  content: "\f1104";
}

.mdi-monitor-small::before {
  content: "\f1876";
}

.mdi-monitor-speaker::before {
  content: "\f0f5f";
}

.mdi-monitor-speaker-off::before {
  content: "\f0f60";
}

.mdi-monitor-star::before {
  content: "\f0ddc";
}

.mdi-moon-first-quarter::before {
  content: "\f0f61";
}

.mdi-moon-full::before {
  content: "\f0f62";
}

.mdi-moon-last-quarter::before {
  content: "\f0f63";
}

.mdi-moon-new::before {
  content: "\f0f64";
}

.mdi-moon-waning-crescent::before {
  content: "\f0f65";
}

.mdi-moon-waning-gibbous::before {
  content: "\f0f66";
}

.mdi-moon-waxing-crescent::before {
  content: "\f0f67";
}

.mdi-moon-waxing-gibbous::before {
  content: "\f0f68";
}

.mdi-moped::before {
  content: "\f1086";
}

.mdi-moped-electric::before {
  content: "\f15b7";
}

.mdi-moped-electric-outline::before {
  content: "\f15b8";
}

.mdi-moped-outline::before {
  content: "\f15b9";
}

.mdi-more::before {
  content: "\f037b";
}

.mdi-mortar-pestle::before {
  content: "\f1748";
}

.mdi-mortar-pestle-plus::before {
  content: "\f03f1";
}

.mdi-mosque::before {
  content: "\f1827";
}

.mdi-mother-heart::before {
  content: "\f1314";
}

.mdi-mother-nurse::before {
  content: "\f0d21";
}

.mdi-motion::before {
  content: "\f15b2";
}

.mdi-motion-outline::before {
  content: "\f15b3";
}

.mdi-motion-pause::before {
  content: "\f1590";
}

.mdi-motion-pause-outline::before {
  content: "\f1592";
}

.mdi-motion-play::before {
  content: "\f158f";
}

.mdi-motion-play-outline::before {
  content: "\f1591";
}

.mdi-motion-sensor::before {
  content: "\f0d91";
}

.mdi-motion-sensor-off::before {
  content: "\f1435";
}

.mdi-motorbike::before {
  content: "\f037c";
}

.mdi-motorbike-electric::before {
  content: "\f15ba";
}

.mdi-mouse::before {
  content: "\f037d";
}

.mdi-mouse-bluetooth::before {
  content: "\f098b";
}

.mdi-mouse-move-down::before {
  content: "\f1550";
}

.mdi-mouse-move-up::before {
  content: "\f1551";
}

.mdi-mouse-move-vertical::before {
  content: "\f1552";
}

.mdi-mouse-off::before {
  content: "\f037e";
}

.mdi-mouse-variant::before {
  content: "\f037f";
}

.mdi-mouse-variant-off::before {
  content: "\f0380";
}

.mdi-move-resize::before {
  content: "\f0655";
}

.mdi-move-resize-variant::before {
  content: "\f0656";
}

.mdi-movie::before {
  content: "\f0381";
}

.mdi-movie-check::before {
  content: "\f16f3";
}

.mdi-movie-check-outline::before {
  content: "\f16f4";
}

.mdi-movie-cog::before {
  content: "\f16f5";
}

.mdi-movie-cog-outline::before {
  content: "\f16f6";
}

.mdi-movie-edit::before {
  content: "\f1122";
}

.mdi-movie-edit-outline::before {
  content: "\f1123";
}

.mdi-movie-filter::before {
  content: "\f1124";
}

.mdi-movie-filter-outline::before {
  content: "\f1125";
}

.mdi-movie-minus::before {
  content: "\f16f7";
}

.mdi-movie-minus-outline::before {
  content: "\f16f8";
}

.mdi-movie-off::before {
  content: "\f16f9";
}

.mdi-movie-off-outline::before {
  content: "\f16fa";
}

.mdi-movie-open::before {
  content: "\f0fce";
}

.mdi-movie-open-check::before {
  content: "\f16fb";
}

.mdi-movie-open-check-outline::before {
  content: "\f16fc";
}

.mdi-movie-open-cog::before {
  content: "\f16fd";
}

.mdi-movie-open-cog-outline::before {
  content: "\f16fe";
}

.mdi-movie-open-edit::before {
  content: "\f16ff";
}

.mdi-movie-open-edit-outline::before {
  content: "\f1700";
}

.mdi-movie-open-minus::before {
  content: "\f1701";
}

.mdi-movie-open-minus-outline::before {
  content: "\f1702";
}

.mdi-movie-open-off::before {
  content: "\f1703";
}

.mdi-movie-open-off-outline::before {
  content: "\f1704";
}

.mdi-movie-open-outline::before {
  content: "\f0fcf";
}

.mdi-movie-open-play::before {
  content: "\f1705";
}

.mdi-movie-open-play-outline::before {
  content: "\f1706";
}

.mdi-movie-open-plus::before {
  content: "\f1707";
}

.mdi-movie-open-plus-outline::before {
  content: "\f1708";
}

.mdi-movie-open-remove::before {
  content: "\f1709";
}

.mdi-movie-open-remove-outline::before {
  content: "\f170a";
}

.mdi-movie-open-settings::before {
  content: "\f170b";
}

.mdi-movie-open-settings-outline::before {
  content: "\f170c";
}

.mdi-movie-open-star::before {
  content: "\f170d";
}

.mdi-movie-open-star-outline::before {
  content: "\f170e";
}

.mdi-movie-outline::before {
  content: "\f0ddd";
}

.mdi-movie-play::before {
  content: "\f170f";
}

.mdi-movie-play-outline::before {
  content: "\f1710";
}

.mdi-movie-plus::before {
  content: "\f1711";
}

.mdi-movie-plus-outline::before {
  content: "\f1712";
}

.mdi-movie-remove::before {
  content: "\f1713";
}

.mdi-movie-remove-outline::before {
  content: "\f1714";
}

.mdi-movie-roll::before {
  content: "\f07de";
}

.mdi-movie-search::before {
  content: "\f11d2";
}

.mdi-movie-search-outline::before {
  content: "\f11d3";
}

.mdi-movie-settings::before {
  content: "\f1715";
}

.mdi-movie-settings-outline::before {
  content: "\f1716";
}

.mdi-movie-star::before {
  content: "\f1717";
}

.mdi-movie-star-outline::before {
  content: "\f1718";
}

.mdi-mower::before {
  content: "\f166f";
}

.mdi-mower-bag::before {
  content: "\f1670";
}

.mdi-muffin::before {
  content: "\f098c";
}

.mdi-multicast::before {
  content: "\f1893";
}

.mdi-multiplication::before {
  content: "\f0382";
}

.mdi-multiplication-box::before {
  content: "\f0383";
}

.mdi-mushroom::before {
  content: "\f07df";
}

.mdi-mushroom-off::before {
  content: "\f13fa";
}

.mdi-mushroom-off-outline::before {
  content: "\f13fb";
}

.mdi-mushroom-outline::before {
  content: "\f07e0";
}

.mdi-music::before {
  content: "\f075a";
}

.mdi-music-accidental-double-flat::before {
  content: "\f0f69";
}

.mdi-music-accidental-double-sharp::before {
  content: "\f0f6a";
}

.mdi-music-accidental-flat::before {
  content: "\f0f6b";
}

.mdi-music-accidental-natural::before {
  content: "\f0f6c";
}

.mdi-music-accidental-sharp::before {
  content: "\f0f6d";
}

.mdi-music-box::before {
  content: "\f0384";
}

.mdi-music-box-multiple::before {
  content: "\f0333";
}

.mdi-music-box-multiple-outline::before {
  content: "\f0f04";
}

.mdi-music-box-outline::before {
  content: "\f0385";
}

.mdi-music-circle::before {
  content: "\f0386";
}

.mdi-music-circle-outline::before {
  content: "\f0ad4";
}

.mdi-music-clef-alto::before {
  content: "\f0f6e";
}

.mdi-music-clef-bass::before {
  content: "\f0f6f";
}

.mdi-music-clef-treble::before {
  content: "\f0f70";
}

.mdi-music-note::before {
  content: "\f0387";
}

.mdi-music-note-bluetooth::before {
  content: "\f05fe";
}

.mdi-music-note-bluetooth-off::before {
  content: "\f05ff";
}

.mdi-music-note-eighth::before {
  content: "\f0388";
}

.mdi-music-note-eighth-dotted::before {
  content: "\f0f71";
}

.mdi-music-note-half::before {
  content: "\f0389";
}

.mdi-music-note-half-dotted::before {
  content: "\f0f72";
}

.mdi-music-note-off::before {
  content: "\f038a";
}

.mdi-music-note-off-outline::before {
  content: "\f0f73";
}

.mdi-music-note-outline::before {
  content: "\f0f74";
}

.mdi-music-note-plus::before {
  content: "\f0dde";
}

.mdi-music-note-quarter::before {
  content: "\f038b";
}

.mdi-music-note-quarter-dotted::before {
  content: "\f0f75";
}

.mdi-music-note-sixteenth::before {
  content: "\f038c";
}

.mdi-music-note-sixteenth-dotted::before {
  content: "\f0f76";
}

.mdi-music-note-whole::before {
  content: "\f038d";
}

.mdi-music-note-whole-dotted::before {
  content: "\f0f77";
}

.mdi-music-off::before {
  content: "\f075b";
}

.mdi-music-rest-eighth::before {
  content: "\f0f78";
}

.mdi-music-rest-half::before {
  content: "\f0f79";
}

.mdi-music-rest-quarter::before {
  content: "\f0f7a";
}

.mdi-music-rest-sixteenth::before {
  content: "\f0f7b";
}

.mdi-music-rest-whole::before {
  content: "\f0f7c";
}

.mdi-mustache::before {
  content: "\f15de";
}

.mdi-nail::before {
  content: "\f0ddf";
}

.mdi-nas::before {
  content: "\f08f3";
}

.mdi-nativescript::before {
  content: "\f0880";
}

.mdi-nature::before {
  content: "\f038e";
}

.mdi-nature-people::before {
  content: "\f038f";
}

.mdi-navigation::before {
  content: "\f0390";
}

.mdi-navigation-outline::before {
  content: "\f1607";
}

.mdi-navigation-variant::before {
  content: "\f18f0";
}

.mdi-navigation-variant-outline::before {
  content: "\f18f1";
}

.mdi-near-me::before {
  content: "\f05cd";
}

.mdi-necklace::before {
  content: "\f0f0b";
}

.mdi-needle::before {
  content: "\f0391";
}

.mdi-netflix::before {
  content: "\f0746";
}

.mdi-network::before {
  content: "\f06f3";
}

.mdi-network-off::before {
  content: "\f0c9b";
}

.mdi-network-off-outline::before {
  content: "\f0c9c";
}

.mdi-network-outline::before {
  content: "\f0c9d";
}

.mdi-network-strength-1::before {
  content: "\f08f4";
}

.mdi-network-strength-1-alert::before {
  content: "\f08f5";
}

.mdi-network-strength-2::before {
  content: "\f08f6";
}

.mdi-network-strength-2-alert::before {
  content: "\f08f7";
}

.mdi-network-strength-3::before {
  content: "\f08f8";
}

.mdi-network-strength-3-alert::before {
  content: "\f08f9";
}

.mdi-network-strength-4::before {
  content: "\f08fa";
}

.mdi-network-strength-4-alert::before {
  content: "\f08fb";
}

.mdi-network-strength-4-cog::before {
  content: "\f191a";
}

.mdi-network-strength-off::before {
  content: "\f08fc";
}

.mdi-network-strength-off-outline::before {
  content: "\f08fd";
}

.mdi-network-strength-outline::before {
  content: "\f08fe";
}

.mdi-new-box::before {
  content: "\f0394";
}

.mdi-newspaper::before {
  content: "\f0395";
}

.mdi-newspaper-check::before {
  content: "\f1943";
}

.mdi-newspaper-minus::before {
  content: "\f0f0c";
}

.mdi-newspaper-plus::before {
  content: "\f0f0d";
}

.mdi-newspaper-remove::before {
  content: "\f1944";
}

.mdi-newspaper-variant::before {
  content: "\f1001";
}

.mdi-newspaper-variant-multiple::before {
  content: "\f1002";
}

.mdi-newspaper-variant-multiple-outline::before {
  content: "\f1003";
}

.mdi-newspaper-variant-outline::before {
  content: "\f1004";
}

.mdi-nfc::before {
  content: "\f0396";
}

.mdi-nfc-search-variant::before {
  content: "\f0e53";
}

.mdi-nfc-tap::before {
  content: "\f0397";
}

.mdi-nfc-variant::before {
  content: "\f0398";
}

.mdi-nfc-variant-off::before {
  content: "\f0e54";
}

.mdi-ninja::before {
  content: "\f0774";
}

.mdi-nintendo-game-boy::before {
  content: "\f1393";
}

.mdi-nintendo-switch::before {
  content: "\f07e1";
}

.mdi-nintendo-wii::before {
  content: "\f05ab";
}

.mdi-nintendo-wiiu::before {
  content: "\f072d";
}

.mdi-nix::before {
  content: "\f1105";
}

.mdi-nodejs::before {
  content: "\f0399";
}

.mdi-noodles::before {
  content: "\f117e";
}

.mdi-not-equal::before {
  content: "\f098d";
}

.mdi-not-equal-variant::before {
  content: "\f098e";
}

.mdi-note::before {
  content: "\f039a";
}

.mdi-note-alert::before {
  content: "\f177d";
}

.mdi-note-alert-outline::before {
  content: "\f177e";
}

.mdi-note-check::before {
  content: "\f177f";
}

.mdi-note-check-outline::before {
  content: "\f1780";
}

.mdi-note-edit::before {
  content: "\f1781";
}

.mdi-note-edit-outline::before {
  content: "\f1782";
}

.mdi-note-minus::before {
  content: "\f164f";
}

.mdi-note-minus-outline::before {
  content: "\f1650";
}

.mdi-note-multiple::before {
  content: "\f06b8";
}

.mdi-note-multiple-outline::before {
  content: "\f06b9";
}

.mdi-note-off::before {
  content: "\f1783";
}

.mdi-note-off-outline::before {
  content: "\f1784";
}

.mdi-note-outline::before {
  content: "\f039b";
}

.mdi-note-plus::before {
  content: "\f039c";
}

.mdi-note-plus-outline::before {
  content: "\f039d";
}

.mdi-note-remove::before {
  content: "\f1651";
}

.mdi-note-remove-outline::before {
  content: "\f1652";
}

.mdi-note-search::before {
  content: "\f1653";
}

.mdi-note-search-outline::before {
  content: "\f1654";
}

.mdi-note-text::before {
  content: "\f039e";
}

.mdi-note-text-outline::before {
  content: "\f11d7";
}

.mdi-notebook::before {
  content: "\f082e";
}

.mdi-notebook-check::before {
  content: "\f14f5";
}

.mdi-notebook-check-outline::before {
  content: "\f14f6";
}

.mdi-notebook-edit::before {
  content: "\f14e7";
}

.mdi-notebook-edit-outline::before {
  content: "\f14e9";
}

.mdi-notebook-minus::before {
  content: "\f1610";
}

.mdi-notebook-minus-outline::before {
  content: "\f1611";
}

.mdi-notebook-multiple::before {
  content: "\f0e55";
}

.mdi-notebook-outline::before {
  content: "\f0ebf";
}

.mdi-notebook-plus::before {
  content: "\f1612";
}

.mdi-notebook-plus-outline::before {
  content: "\f1613";
}

.mdi-notebook-remove::before {
  content: "\f1614";
}

.mdi-notebook-remove-outline::before {
  content: "\f1615";
}

.mdi-notification-clear-all::before {
  content: "\f039f";
}

.mdi-npm::before {
  content: "\f06f7";
}

.mdi-nuke::before {
  content: "\f06a4";
}

.mdi-null::before {
  content: "\f07e2";
}

.mdi-numeric::before {
  content: "\f03a0";
}

.mdi-numeric-0::before {
  content: "\f0b39";
}

.mdi-numeric-0-box::before {
  content: "\f03a1";
}

.mdi-numeric-0-box-multiple::before {
  content: "\f0f0e";
}

.mdi-numeric-0-box-multiple-outline::before {
  content: "\f03a2";
}

.mdi-numeric-0-box-outline::before {
  content: "\f03a3";
}

.mdi-numeric-0-circle::before {
  content: "\f0c9e";
}

.mdi-numeric-0-circle-outline::before {
  content: "\f0c9f";
}

.mdi-numeric-1::before {
  content: "\f0b3a";
}

.mdi-numeric-1-box::before {
  content: "\f03a4";
}

.mdi-numeric-1-box-multiple::before {
  content: "\f0f0f";
}

.mdi-numeric-1-box-multiple-outline::before {
  content: "\f03a5";
}

.mdi-numeric-1-box-outline::before {
  content: "\f03a6";
}

.mdi-numeric-1-circle::before {
  content: "\f0ca0";
}

.mdi-numeric-1-circle-outline::before {
  content: "\f0ca1";
}

.mdi-numeric-10::before {
  content: "\f0fe9";
}

.mdi-numeric-10-box::before {
  content: "\f0f7d";
}

.mdi-numeric-10-box-multiple::before {
  content: "\f0fea";
}

.mdi-numeric-10-box-multiple-outline::before {
  content: "\f0feb";
}

.mdi-numeric-10-box-outline::before {
  content: "\f0f7e";
}

.mdi-numeric-10-circle::before {
  content: "\f0fec";
}

.mdi-numeric-10-circle-outline::before {
  content: "\f0fed";
}

.mdi-numeric-2::before {
  content: "\f0b3b";
}

.mdi-numeric-2-box::before {
  content: "\f03a7";
}

.mdi-numeric-2-box-multiple::before {
  content: "\f0f10";
}

.mdi-numeric-2-box-multiple-outline::before {
  content: "\f03a8";
}

.mdi-numeric-2-box-outline::before {
  content: "\f03a9";
}

.mdi-numeric-2-circle::before {
  content: "\f0ca2";
}

.mdi-numeric-2-circle-outline::before {
  content: "\f0ca3";
}

.mdi-numeric-3::before {
  content: "\f0b3c";
}

.mdi-numeric-3-box::before {
  content: "\f03aa";
}

.mdi-numeric-3-box-multiple::before {
  content: "\f0f11";
}

.mdi-numeric-3-box-multiple-outline::before {
  content: "\f03ab";
}

.mdi-numeric-3-box-outline::before {
  content: "\f03ac";
}

.mdi-numeric-3-circle::before {
  content: "\f0ca4";
}

.mdi-numeric-3-circle-outline::before {
  content: "\f0ca5";
}

.mdi-numeric-4::before {
  content: "\f0b3d";
}

.mdi-numeric-4-box::before {
  content: "\f03ad";
}

.mdi-numeric-4-box-multiple::before {
  content: "\f0f12";
}

.mdi-numeric-4-box-multiple-outline::before {
  content: "\f03b2";
}

.mdi-numeric-4-box-outline::before {
  content: "\f03ae";
}

.mdi-numeric-4-circle::before {
  content: "\f0ca6";
}

.mdi-numeric-4-circle-outline::before {
  content: "\f0ca7";
}

.mdi-numeric-5::before {
  content: "\f0b3e";
}

.mdi-numeric-5-box::before {
  content: "\f03b1";
}

.mdi-numeric-5-box-multiple::before {
  content: "\f0f13";
}

.mdi-numeric-5-box-multiple-outline::before {
  content: "\f03af";
}

.mdi-numeric-5-box-outline::before {
  content: "\f03b0";
}

.mdi-numeric-5-circle::before {
  content: "\f0ca8";
}

.mdi-numeric-5-circle-outline::before {
  content: "\f0ca9";
}

.mdi-numeric-6::before {
  content: "\f0b3f";
}

.mdi-numeric-6-box::before {
  content: "\f03b3";
}

.mdi-numeric-6-box-multiple::before {
  content: "\f0f14";
}

.mdi-numeric-6-box-multiple-outline::before {
  content: "\f03b4";
}

.mdi-numeric-6-box-outline::before {
  content: "\f03b5";
}

.mdi-numeric-6-circle::before {
  content: "\f0caa";
}

.mdi-numeric-6-circle-outline::before {
  content: "\f0cab";
}

.mdi-numeric-7::before {
  content: "\f0b40";
}

.mdi-numeric-7-box::before {
  content: "\f03b6";
}

.mdi-numeric-7-box-multiple::before {
  content: "\f0f15";
}

.mdi-numeric-7-box-multiple-outline::before {
  content: "\f03b7";
}

.mdi-numeric-7-box-outline::before {
  content: "\f03b8";
}

.mdi-numeric-7-circle::before {
  content: "\f0cac";
}

.mdi-numeric-7-circle-outline::before {
  content: "\f0cad";
}

.mdi-numeric-8::before {
  content: "\f0b41";
}

.mdi-numeric-8-box::before {
  content: "\f03b9";
}

.mdi-numeric-8-box-multiple::before {
  content: "\f0f16";
}

.mdi-numeric-8-box-multiple-outline::before {
  content: "\f03ba";
}

.mdi-numeric-8-box-outline::before {
  content: "\f03bb";
}

.mdi-numeric-8-circle::before {
  content: "\f0cae";
}

.mdi-numeric-8-circle-outline::before {
  content: "\f0caf";
}

.mdi-numeric-9::before {
  content: "\f0b42";
}

.mdi-numeric-9-box::before {
  content: "\f03bc";
}

.mdi-numeric-9-box-multiple::before {
  content: "\f0f17";
}

.mdi-numeric-9-box-multiple-outline::before {
  content: "\f03bd";
}

.mdi-numeric-9-box-outline::before {
  content: "\f03be";
}

.mdi-numeric-9-circle::before {
  content: "\f0cb0";
}

.mdi-numeric-9-circle-outline::before {
  content: "\f0cb1";
}

.mdi-numeric-9-plus::before {
  content: "\f0fee";
}

.mdi-numeric-9-plus-box::before {
  content: "\f03bf";
}

.mdi-numeric-9-plus-box-multiple::before {
  content: "\f0f18";
}

.mdi-numeric-9-plus-box-multiple-outline::before {
  content: "\f03c0";
}

.mdi-numeric-9-plus-box-outline::before {
  content: "\f03c1";
}

.mdi-numeric-9-plus-circle::before {
  content: "\f0cb2";
}

.mdi-numeric-9-plus-circle-outline::before {
  content: "\f0cb3";
}

.mdi-numeric-negative-1::before {
  content: "\f1052";
}

.mdi-numeric-positive-1::before {
  content: "\f15cb";
}

.mdi-nut::before {
  content: "\f06f8";
}

.mdi-nutrition::before {
  content: "\f03c2";
}

.mdi-nuxt::before {
  content: "\f1106";
}

.mdi-oar::before {
  content: "\f067c";
}

.mdi-ocarina::before {
  content: "\f0de0";
}

.mdi-oci::before {
  content: "\f12e9";
}

.mdi-ocr::before {
  content: "\f113a";
}

.mdi-octagon::before {
  content: "\f03c3";
}

.mdi-octagon-outline::before {
  content: "\f03c4";
}

.mdi-octagram::before {
  content: "\f06f9";
}

.mdi-octagram-outline::before {
  content: "\f0775";
}

.mdi-octahedron::before {
  content: "\f1950";
}

.mdi-octahedron-off::before {
  content: "\f1951";
}

.mdi-odnoklassniki::before {
  content: "\f03c5";
}

.mdi-offer::before {
  content: "\f121b";
}

.mdi-office-building::before {
  content: "\f0991";
}

.mdi-office-building-cog::before {
  content: "\f1949";
}

.mdi-office-building-cog-outline::before {
  content: "\f194a";
}

.mdi-office-building-marker::before {
  content: "\f1520";
}

.mdi-office-building-marker-outline::before {
  content: "\f1521";
}

.mdi-office-building-outline::before {
  content: "\f151f";
}

.mdi-oil::before {
  content: "\f03c7";
}

.mdi-oil-lamp::before {
  content: "\f0f19";
}

.mdi-oil-level::before {
  content: "\f1053";
}

.mdi-oil-temperature::before {
  content: "\f0ff8";
}

.mdi-om::before {
  content: "\f0973";
}

.mdi-omega::before {
  content: "\f03c9";
}

.mdi-one-up::before {
  content: "\f0bad";
}

.mdi-onepassword::before {
  content: "\f0881";
}

.mdi-opacity::before {
  content: "\f05cc";
}

.mdi-open-in-app::before {
  content: "\f03cb";
}

.mdi-open-in-new::before {
  content: "\f03cc";
}

.mdi-open-source-initiative::before {
  content: "\f0bae";
}

.mdi-openid::before {
  content: "\f03cd";
}

.mdi-opera::before {
  content: "\f03ce";
}

.mdi-orbit::before {
  content: "\f0018";
}

.mdi-orbit-variant::before {
  content: "\f15db";
}

.mdi-order-alphabetical-ascending::before {
  content: "\f020d";
}

.mdi-order-alphabetical-descending::before {
  content: "\f0d07";
}

.mdi-order-bool-ascending::before {
  content: "\f02be";
}

.mdi-order-bool-ascending-variant::before {
  content: "\f098f";
}

.mdi-order-bool-descending::before {
  content: "\f1384";
}

.mdi-order-bool-descending-variant::before {
  content: "\f0990";
}

.mdi-order-numeric-ascending::before {
  content: "\f0545";
}

.mdi-order-numeric-descending::before {
  content: "\f0546";
}

.mdi-origin::before {
  content: "\f0b43";
}

.mdi-ornament::before {
  content: "\f03cf";
}

.mdi-ornament-variant::before {
  content: "\f03d0";
}

.mdi-outdoor-lamp::before {
  content: "\f1054";
}

.mdi-overscan::before {
  content: "\f1005";
}

.mdi-owl::before {
  content: "\f03d2";
}

.mdi-pac-man::before {
  content: "\f0baf";
}

.mdi-package::before {
  content: "\f03d3";
}

.mdi-package-down::before {
  content: "\f03d4";
}

.mdi-package-up::before {
  content: "\f03d5";
}

.mdi-package-variant::before {
  content: "\f03d6";
}

.mdi-package-variant-closed::before {
  content: "\f03d7";
}

.mdi-page-first::before {
  content: "\f0600";
}

.mdi-page-last::before {
  content: "\f0601";
}

.mdi-page-layout-body::before {
  content: "\f06fa";
}

.mdi-page-layout-footer::before {
  content: "\f06fb";
}

.mdi-page-layout-header::before {
  content: "\f06fc";
}

.mdi-page-layout-header-footer::before {
  content: "\f0f7f";
}

.mdi-page-layout-sidebar-left::before {
  content: "\f06fd";
}

.mdi-page-layout-sidebar-right::before {
  content: "\f06fe";
}

.mdi-page-next::before {
  content: "\f0bb0";
}

.mdi-page-next-outline::before {
  content: "\f0bb1";
}

.mdi-page-previous::before {
  content: "\f0bb2";
}

.mdi-page-previous-outline::before {
  content: "\f0bb3";
}

.mdi-pail::before {
  content: "\f1417";
}

.mdi-pail-minus::before {
  content: "\f1437";
}

.mdi-pail-minus-outline::before {
  content: "\f143c";
}

.mdi-pail-off::before {
  content: "\f1439";
}

.mdi-pail-off-outline::before {
  content: "\f143e";
}

.mdi-pail-outline::before {
  content: "\f143a";
}

.mdi-pail-plus::before {
  content: "\f1436";
}

.mdi-pail-plus-outline::before {
  content: "\f143b";
}

.mdi-pail-remove::before {
  content: "\f1438";
}

.mdi-pail-remove-outline::before {
  content: "\f143d";
}

.mdi-palette::before {
  content: "\f03d8";
}

.mdi-palette-advanced::before {
  content: "\f03d9";
}

.mdi-palette-outline::before {
  content: "\f0e0c";
}

.mdi-palette-swatch::before {
  content: "\f08b5";
}

.mdi-palette-swatch-outline::before {
  content: "\f135c";
}

.mdi-palette-swatch-variant::before {
  content: "\f195a";
}

.mdi-palm-tree::before {
  content: "\f1055";
}

.mdi-pan::before {
  content: "\f0bb4";
}

.mdi-pan-bottom-left::before {
  content: "\f0bb5";
}

.mdi-pan-bottom-right::before {
  content: "\f0bb6";
}

.mdi-pan-down::before {
  content: "\f0bb7";
}

.mdi-pan-horizontal::before {
  content: "\f0bb8";
}

.mdi-pan-left::before {
  content: "\f0bb9";
}

.mdi-pan-right::before {
  content: "\f0bba";
}

.mdi-pan-top-left::before {
  content: "\f0bbb";
}

.mdi-pan-top-right::before {
  content: "\f0bbc";
}

.mdi-pan-up::before {
  content: "\f0bbd";
}

.mdi-pan-vertical::before {
  content: "\f0bbe";
}

.mdi-panda::before {
  content: "\f03da";
}

.mdi-pandora::before {
  content: "\f03db";
}

.mdi-panorama::before {
  content: "\f03dc";
}

.mdi-panorama-fisheye::before {
  content: "\f03dd";
}

.mdi-panorama-horizontal::before {
  content: "\f1928";
}

.mdi-panorama-horizontal-outline::before {
  content: "\f03de";
}

.mdi-panorama-outline::before {
  content: "\f198c";
}

.mdi-panorama-sphere::before {
  content: "\f198d";
}

.mdi-panorama-sphere-outline::before {
  content: "\f198e";
}

.mdi-panorama-variant::before {
  content: "\f198f";
}

.mdi-panorama-variant-outline::before {
  content: "\f1990";
}

.mdi-panorama-vertical::before {
  content: "\f1929";
}

.mdi-panorama-vertical-outline::before {
  content: "\f03df";
}

.mdi-panorama-wide-angle::before {
  content: "\f195f";
}

.mdi-panorama-wide-angle-outline::before {
  content: "\f03e0";
}

.mdi-paper-cut-vertical::before {
  content: "\f03e1";
}

.mdi-paper-roll::before {
  content: "\f1157";
}

.mdi-paper-roll-outline::before {
  content: "\f1158";
}

.mdi-paperclip::before {
  content: "\f03e2";
}

.mdi-parachute::before {
  content: "\f0cb4";
}

.mdi-parachute-outline::before {
  content: "\f0cb5";
}

.mdi-paragliding::before {
  content: "\f1745";
}

.mdi-parking::before {
  content: "\f03e3";
}

.mdi-party-popper::before {
  content: "\f1056";
}

.mdi-passport::before {
  content: "\f07e3";
}

.mdi-passport-biometric::before {
  content: "\f0de1";
}

.mdi-pasta::before {
  content: "\f1160";
}

.mdi-patio-heater::before {
  content: "\f0f80";
}

.mdi-patreon::before {
  content: "\f0882";
}

.mdi-pause::before {
  content: "\f03e4";
}

.mdi-pause-circle::before {
  content: "\f03e5";
}

.mdi-pause-circle-outline::before {
  content: "\f03e6";
}

.mdi-pause-octagon::before {
  content: "\f03e7";
}

.mdi-pause-octagon-outline::before {
  content: "\f03e8";
}

.mdi-paw::before {
  content: "\f03e9";
}

.mdi-paw-off::before {
  content: "\f0657";
}

.mdi-paw-off-outline::before {
  content: "\f1676";
}

.mdi-paw-outline::before {
  content: "\f1675";
}

.mdi-peace::before {
  content: "\f0884";
}

.mdi-peanut::before {
  content: "\f0ffc";
}

.mdi-peanut-off::before {
  content: "\f0ffd";
}

.mdi-peanut-off-outline::before {
  content: "\f0fff";
}

.mdi-peanut-outline::before {
  content: "\f0ffe";
}

.mdi-pen::before {
  content: "\f03ea";
}

.mdi-pen-lock::before {
  content: "\f0de2";
}

.mdi-pen-minus::before {
  content: "\f0de3";
}

.mdi-pen-off::before {
  content: "\f0de4";
}

.mdi-pen-plus::before {
  content: "\f0de5";
}

.mdi-pen-remove::before {
  content: "\f0de6";
}

.mdi-pencil::before {
  content: "\f03eb";
}

.mdi-pencil-box::before {
  content: "\f03ec";
}

.mdi-pencil-box-multiple::before {
  content: "\f1144";
}

.mdi-pencil-box-multiple-outline::before {
  content: "\f1145";
}

.mdi-pencil-box-outline::before {
  content: "\f03ed";
}

.mdi-pencil-circle::before {
  content: "\f06ff";
}

.mdi-pencil-circle-outline::before {
  content: "\f0776";
}

.mdi-pencil-lock::before {
  content: "\f03ee";
}

.mdi-pencil-lock-outline::before {
  content: "\f0de7";
}

.mdi-pencil-minus::before {
  content: "\f0de8";
}

.mdi-pencil-minus-outline::before {
  content: "\f0de9";
}

.mdi-pencil-off::before {
  content: "\f03ef";
}

.mdi-pencil-off-outline::before {
  content: "\f0dea";
}

.mdi-pencil-outline::before {
  content: "\f0cb6";
}

.mdi-pencil-plus::before {
  content: "\f0deb";
}

.mdi-pencil-plus-outline::before {
  content: "\f0dec";
}

.mdi-pencil-remove::before {
  content: "\f0ded";
}

.mdi-pencil-remove-outline::before {
  content: "\f0dee";
}

.mdi-pencil-ruler::before {
  content: "\f1353";
}

.mdi-penguin::before {
  content: "\f0ec0";
}

.mdi-pentagon::before {
  content: "\f0701";
}

.mdi-pentagon-outline::before {
  content: "\f0700";
}

.mdi-pentagram::before {
  content: "\f1667";
}

.mdi-percent::before {
  content: "\f03f0";
}

.mdi-percent-outline::before {
  content: "\f1278";
}

.mdi-periodic-table::before {
  content: "\f08b6";
}

.mdi-perspective-less::before {
  content: "\f0d23";
}

.mdi-perspective-more::before {
  content: "\f0d24";
}

.mdi-ph::before {
  content: "\f17c5";
}

.mdi-phone::before {
  content: "\f03f2";
}

.mdi-phone-alert::before {
  content: "\f0f1a";
}

.mdi-phone-alert-outline::before {
  content: "\f118e";
}

.mdi-phone-bluetooth::before {
  content: "\f03f3";
}

.mdi-phone-bluetooth-outline::before {
  content: "\f118f";
}

.mdi-phone-cancel::before {
  content: "\f10bc";
}

.mdi-phone-cancel-outline::before {
  content: "\f1190";
}

.mdi-phone-check::before {
  content: "\f11a9";
}

.mdi-phone-check-outline::before {
  content: "\f11aa";
}

.mdi-phone-classic::before {
  content: "\f0602";
}

.mdi-phone-classic-off::before {
  content: "\f1279";
}

.mdi-phone-dial::before {
  content: "\f1559";
}

.mdi-phone-dial-outline::before {
  content: "\f155a";
}

.mdi-phone-forward::before {
  content: "\f03f4";
}

.mdi-phone-forward-outline::before {
  content: "\f1191";
}

.mdi-phone-hangup::before {
  content: "\f03f5";
}

.mdi-phone-hangup-outline::before {
  content: "\f1192";
}

.mdi-phone-in-talk::before {
  content: "\f03f6";
}

.mdi-phone-in-talk-outline::before {
  content: "\f1182";
}

.mdi-phone-incoming::before {
  content: "\f03f7";
}

.mdi-phone-incoming-outline::before {
  content: "\f1193";
}

.mdi-phone-lock::before {
  content: "\f03f8";
}

.mdi-phone-lock-outline::before {
  content: "\f1194";
}

.mdi-phone-log::before {
  content: "\f03f9";
}

.mdi-phone-log-outline::before {
  content: "\f1195";
}

.mdi-phone-message::before {
  content: "\f1196";
}

.mdi-phone-message-outline::before {
  content: "\f1197";
}

.mdi-phone-minus::before {
  content: "\f0658";
}

.mdi-phone-minus-outline::before {
  content: "\f1198";
}

.mdi-phone-missed::before {
  content: "\f03fa";
}

.mdi-phone-missed-outline::before {
  content: "\f11a5";
}

.mdi-phone-off::before {
  content: "\f0def";
}

.mdi-phone-off-outline::before {
  content: "\f11a6";
}

.mdi-phone-outgoing::before {
  content: "\f03fb";
}

.mdi-phone-outgoing-outline::before {
  content: "\f1199";
}

.mdi-phone-outline::before {
  content: "\f0df0";
}

.mdi-phone-paused::before {
  content: "\f03fc";
}

.mdi-phone-paused-outline::before {
  content: "\f119a";
}

.mdi-phone-plus::before {
  content: "\f0659";
}

.mdi-phone-plus-outline::before {
  content: "\f119b";
}

.mdi-phone-refresh::before {
  content: "\f1993";
}

.mdi-phone-refresh-outline::before {
  content: "\f1994";
}

.mdi-phone-remove::before {
  content: "\f152f";
}

.mdi-phone-remove-outline::before {
  content: "\f1530";
}

.mdi-phone-return::before {
  content: "\f082f";
}

.mdi-phone-return-outline::before {
  content: "\f119c";
}

.mdi-phone-ring::before {
  content: "\f11ab";
}

.mdi-phone-ring-outline::before {
  content: "\f11ac";
}

.mdi-phone-rotate-landscape::before {
  content: "\f0885";
}

.mdi-phone-rotate-portrait::before {
  content: "\f0886";
}

.mdi-phone-settings::before {
  content: "\f03fd";
}

.mdi-phone-settings-outline::before {
  content: "\f119d";
}

.mdi-phone-sync::before {
  content: "\f1995";
}

.mdi-phone-sync-outline::before {
  content: "\f1996";
}

.mdi-phone-voip::before {
  content: "\f03fe";
}

.mdi-pi::before {
  content: "\f03ff";
}

.mdi-pi-box::before {
  content: "\f0400";
}

.mdi-pi-hole::before {
  content: "\f0df1";
}

.mdi-piano::before {
  content: "\f067d";
}

.mdi-piano-off::before {
  content: "\f0698";
}

.mdi-pickaxe::before {
  content: "\f08b7";
}

.mdi-picture-in-picture-bottom-right::before {
  content: "\f0e57";
}

.mdi-picture-in-picture-bottom-right-outline::before {
  content: "\f0e58";
}

.mdi-picture-in-picture-top-right::before {
  content: "\f0e59";
}

.mdi-picture-in-picture-top-right-outline::before {
  content: "\f0e5a";
}

.mdi-pier::before {
  content: "\f0887";
}

.mdi-pier-crane::before {
  content: "\f0888";
}

.mdi-pig::before {
  content: "\f0401";
}

.mdi-pig-variant::before {
  content: "\f1006";
}

.mdi-pig-variant-outline::before {
  content: "\f1678";
}

.mdi-piggy-bank::before {
  content: "\f1007";
}

.mdi-piggy-bank-outline::before {
  content: "\f1679";
}

.mdi-pill::before {
  content: "\f0402";
}

.mdi-pillar::before {
  content: "\f0702";
}

.mdi-pin::before {
  content: "\f0403";
}

.mdi-pin-off::before {
  content: "\f0404";
}

.mdi-pin-off-outline::before {
  content: "\f0930";
}

.mdi-pin-outline::before {
  content: "\f0931";
}

.mdi-pine-tree::before {
  content: "\f0405";
}

.mdi-pine-tree-box::before {
  content: "\f0406";
}

.mdi-pine-tree-fire::before {
  content: "\f141a";
}

.mdi-pinterest::before {
  content: "\f0407";
}

.mdi-pinwheel::before {
  content: "\f0ad5";
}

.mdi-pinwheel-outline::before {
  content: "\f0ad6";
}

.mdi-pipe::before {
  content: "\f07e5";
}

.mdi-pipe-disconnected::before {
  content: "\f07e6";
}

.mdi-pipe-leak::before {
  content: "\f0889";
}

.mdi-pipe-valve::before {
  content: "\f184d";
}

.mdi-pipe-wrench::before {
  content: "\f1354";
}

.mdi-pirate::before {
  content: "\f0a08";
}

.mdi-pistol::before {
  content: "\f0703";
}

.mdi-piston::before {
  content: "\f088a";
}

.mdi-pitchfork::before {
  content: "\f1553";
}

.mdi-pizza::before {
  content: "\f0409";
}

.mdi-play::before {
  content: "\f040a";
}

.mdi-play-box::before {
  content: "\f127a";
}

.mdi-play-box-multiple::before {
  content: "\f0d19";
}

.mdi-play-box-multiple-outline::before {
  content: "\f13e6";
}

.mdi-play-box-outline::before {
  content: "\f040b";
}

.mdi-play-circle::before {
  content: "\f040c";
}

.mdi-play-circle-outline::before {
  content: "\f040d";
}

.mdi-play-network::before {
  content: "\f088b";
}

.mdi-play-network-outline::before {
  content: "\f0cb7";
}

.mdi-play-outline::before {
  content: "\f0f1b";
}

.mdi-play-pause::before {
  content: "\f040e";
}

.mdi-play-protected-content::before {
  content: "\f040f";
}

.mdi-play-speed::before {
  content: "\f08ff";
}

.mdi-playlist-check::before {
  content: "\f05c7";
}

.mdi-playlist-edit::before {
  content: "\f0900";
}

.mdi-playlist-minus::before {
  content: "\f0410";
}

.mdi-playlist-music::before {
  content: "\f0cb8";
}

.mdi-playlist-music-outline::before {
  content: "\f0cb9";
}

.mdi-playlist-play::before {
  content: "\f0411";
}

.mdi-playlist-plus::before {
  content: "\f0412";
}

.mdi-playlist-remove::before {
  content: "\f0413";
}

.mdi-playlist-star::before {
  content: "\f0df2";
}

.mdi-plex::before {
  content: "\f06ba";
}

.mdi-pliers::before {
  content: "\f19a4";
}

.mdi-plus::before {
  content: "\f0415";
}

.mdi-plus-box::before {
  content: "\f0416";
}

.mdi-plus-box-multiple::before {
  content: "\f0334";
}

.mdi-plus-box-multiple-outline::before {
  content: "\f1143";
}

.mdi-plus-box-outline::before {
  content: "\f0704";
}

.mdi-plus-circle::before {
  content: "\f0417";
}

.mdi-plus-circle-multiple::before {
  content: "\f034c";
}

.mdi-plus-circle-multiple-outline::before {
  content: "\f0418";
}

.mdi-plus-circle-outline::before {
  content: "\f0419";
}

.mdi-plus-minus::before {
  content: "\f0992";
}

.mdi-plus-minus-box::before {
  content: "\f0993";
}

.mdi-plus-minus-variant::before {
  content: "\f14c9";
}

.mdi-plus-network::before {
  content: "\f041a";
}

.mdi-plus-network-outline::before {
  content: "\f0cba";
}

.mdi-plus-outline::before {
  content: "\f0705";
}

.mdi-plus-thick::before {
  content: "\f11ec";
}

.mdi-podcast::before {
  content: "\f0994";
}

.mdi-podium::before {
  content: "\f0d25";
}

.mdi-podium-bronze::before {
  content: "\f0d26";
}

.mdi-podium-gold::before {
  content: "\f0d27";
}

.mdi-podium-silver::before {
  content: "\f0d28";
}

.mdi-point-of-sale::before {
  content: "\f0d92";
}

.mdi-pokeball::before {
  content: "\f041d";
}

.mdi-pokemon-go::before {
  content: "\f0a09";
}

.mdi-poker-chip::before {
  content: "\f0830";
}

.mdi-polaroid::before {
  content: "\f041e";
}

.mdi-police-badge::before {
  content: "\f1167";
}

.mdi-police-badge-outline::before {
  content: "\f1168";
}

.mdi-police-station::before {
  content: "\f1839";
}

.mdi-poll::before {
  content: "\f041f";
}

.mdi-polo::before {
  content: "\f14c3";
}

.mdi-polymer::before {
  content: "\f0421";
}

.mdi-pool::before {
  content: "\f0606";
}

.mdi-popcorn::before {
  content: "\f0422";
}

.mdi-post::before {
  content: "\f1008";
}

.mdi-post-outline::before {
  content: "\f1009";
}

.mdi-postage-stamp::before {
  content: "\f0cbb";
}

.mdi-pot::before {
  content: "\f02e5";
}

.mdi-pot-mix::before {
  content: "\f065b";
}

.mdi-pot-mix-outline::before {
  content: "\f0677";
}

.mdi-pot-outline::before {
  content: "\f02ff";
}

.mdi-pot-steam::before {
  content: "\f065a";
}

.mdi-pot-steam-outline::before {
  content: "\f0326";
}

.mdi-pound::before {
  content: "\f0423";
}

.mdi-pound-box::before {
  content: "\f0424";
}

.mdi-pound-box-outline::before {
  content: "\f117f";
}

.mdi-power::before {
  content: "\f0425";
}

.mdi-power-cycle::before {
  content: "\f0901";
}

.mdi-power-off::before {
  content: "\f0902";
}

.mdi-power-on::before {
  content: "\f0903";
}

.mdi-power-plug::before {
  content: "\f06a5";
}

.mdi-power-plug-off::before {
  content: "\f06a6";
}

.mdi-power-plug-off-outline::before {
  content: "\f1424";
}

.mdi-power-plug-outline::before {
  content: "\f1425";
}

.mdi-power-settings::before {
  content: "\f0426";
}

.mdi-power-sleep::before {
  content: "\f0904";
}

.mdi-power-socket::before {
  content: "\f0427";
}

.mdi-power-socket-au::before {
  content: "\f0905";
}

.mdi-power-socket-ch::before {
  content: "\f0fb3";
}

.mdi-power-socket-de::before {
  content: "\f1107";
}

.mdi-power-socket-eu::before {
  content: "\f07e7";
}

.mdi-power-socket-fr::before {
  content: "\f1108";
}

.mdi-power-socket-it::before {
  content: "\f14ff";
}

.mdi-power-socket-jp::before {
  content: "\f1109";
}

.mdi-power-socket-uk::before {
  content: "\f07e8";
}

.mdi-power-socket-us::before {
  content: "\f07e9";
}

.mdi-power-standby::before {
  content: "\f0906";
}

.mdi-powershell::before {
  content: "\f0a0a";
}

.mdi-prescription::before {
  content: "\f0706";
}

.mdi-presentation::before {
  content: "\f0428";
}

.mdi-presentation-play::before {
  content: "\f0429";
}

.mdi-pretzel::before {
  content: "\f1562";
}

.mdi-printer::before {
  content: "\f042a";
}

.mdi-printer-3d::before {
  content: "\f042b";
}

.mdi-printer-3d-nozzle::before {
  content: "\f0e5b";
}

.mdi-printer-3d-nozzle-alert::before {
  content: "\f11c0";
}

.mdi-printer-3d-nozzle-alert-outline::before {
  content: "\f11c1";
}

.mdi-printer-3d-nozzle-heat::before {
  content: "\f18b8";
}

.mdi-printer-3d-nozzle-heat-outline::before {
  content: "\f18b9";
}

.mdi-printer-3d-nozzle-outline::before {
  content: "\f0e5c";
}

.mdi-printer-alert::before {
  content: "\f042c";
}

.mdi-printer-check::before {
  content: "\f1146";
}

.mdi-printer-eye::before {
  content: "\f1458";
}

.mdi-printer-off::before {
  content: "\f0e5d";
}

.mdi-printer-off-outline::before {
  content: "\f1785";
}

.mdi-printer-outline::before {
  content: "\f1786";
}

.mdi-printer-pos::before {
  content: "\f1057";
}

.mdi-printer-search::before {
  content: "\f1457";
}

.mdi-printer-settings::before {
  content: "\f0707";
}

.mdi-printer-wireless::before {
  content: "\f0a0b";
}

.mdi-priority-high::before {
  content: "\f0603";
}

.mdi-priority-low::before {
  content: "\f0604";
}

.mdi-professional-hexagon::before {
  content: "\f042d";
}

.mdi-progress-alert::before {
  content: "\f0cbc";
}

.mdi-progress-check::before {
  content: "\f0995";
}

.mdi-progress-clock::before {
  content: "\f0996";
}

.mdi-progress-close::before {
  content: "\f110a";
}

.mdi-progress-download::before {
  content: "\f0997";
}

.mdi-progress-pencil::before {
  content: "\f1787";
}

.mdi-progress-question::before {
  content: "\f1522";
}

.mdi-progress-star::before {
  content: "\f1788";
}

.mdi-progress-upload::before {
  content: "\f0998";
}

.mdi-progress-wrench::before {
  content: "\f0cbd";
}

.mdi-projector::before {
  content: "\f042e";
}

.mdi-projector-screen::before {
  content: "\f042f";
}

.mdi-projector-screen-off::before {
  content: "\f180d";
}

.mdi-projector-screen-off-outline::before {
  content: "\f180e";
}

.mdi-projector-screen-outline::before {
  content: "\f1724";
}

.mdi-projector-screen-variant::before {
  content: "\f180f";
}

.mdi-projector-screen-variant-off::before {
  content: "\f1810";
}

.mdi-projector-screen-variant-off-outline::before {
  content: "\f1811";
}

.mdi-projector-screen-variant-outline::before {
  content: "\f1812";
}

.mdi-propane-tank::before {
  content: "\f1357";
}

.mdi-propane-tank-outline::before {
  content: "\f1358";
}

.mdi-protocol::before {
  content: "\f0fd8";
}

.mdi-publish::before {
  content: "\f06a7";
}

.mdi-publish-off::before {
  content: "\f1945";
}

.mdi-pulse::before {
  content: "\f0430";
}

.mdi-pump::before {
  content: "\f1402";
}

.mdi-pumpkin::before {
  content: "\f0bbf";
}

.mdi-purse::before {
  content: "\f0f1c";
}

.mdi-purse-outline::before {
  content: "\f0f1d";
}

.mdi-puzzle::before {
  content: "\f0431";
}

.mdi-puzzle-check::before {
  content: "\f1426";
}

.mdi-puzzle-check-outline::before {
  content: "\f1427";
}

.mdi-puzzle-edit::before {
  content: "\f14d3";
}

.mdi-puzzle-edit-outline::before {
  content: "\f14d9";
}

.mdi-puzzle-heart::before {
  content: "\f14d4";
}

.mdi-puzzle-heart-outline::before {
  content: "\f14da";
}

.mdi-puzzle-minus::before {
  content: "\f14d1";
}

.mdi-puzzle-minus-outline::before {
  content: "\f14d7";
}

.mdi-puzzle-outline::before {
  content: "\f0a66";
}

.mdi-puzzle-plus::before {
  content: "\f14d0";
}

.mdi-puzzle-plus-outline::before {
  content: "\f14d6";
}

.mdi-puzzle-remove::before {
  content: "\f14d2";
}

.mdi-puzzle-remove-outline::before {
  content: "\f14d8";
}

.mdi-puzzle-star::before {
  content: "\f14d5";
}

.mdi-puzzle-star-outline::before {
  content: "\f14db";
}

.mdi-pyramid::before {
  content: "\f1952";
}

.mdi-pyramid-off::before {
  content: "\f1953";
}

.mdi-qi::before {
  content: "\f0999";
}

.mdi-qqchat::before {
  content: "\f0605";
}

.mdi-qrcode::before {
  content: "\f0432";
}

.mdi-qrcode-edit::before {
  content: "\f08b8";
}

.mdi-qrcode-minus::before {
  content: "\f118c";
}

.mdi-qrcode-plus::before {
  content: "\f118b";
}

.mdi-qrcode-remove::before {
  content: "\f118d";
}

.mdi-qrcode-scan::before {
  content: "\f0433";
}

.mdi-quadcopter::before {
  content: "\f0434";
}

.mdi-quality-high::before {
  content: "\f0435";
}

.mdi-quality-low::before {
  content: "\f0a0c";
}

.mdi-quality-medium::before {
  content: "\f0a0d";
}

.mdi-quora::before {
  content: "\f0d29";
}

.mdi-rabbit::before {
  content: "\f0907";
}

.mdi-racing-helmet::before {
  content: "\f0d93";
}

.mdi-racquetball::before {
  content: "\f0d94";
}

.mdi-radar::before {
  content: "\f0437";
}

.mdi-radiator::before {
  content: "\f0438";
}

.mdi-radiator-disabled::before {
  content: "\f0ad7";
}

.mdi-radiator-off::before {
  content: "\f0ad8";
}

.mdi-radio::before {
  content: "\f0439";
}

.mdi-radio-am::before {
  content: "\f0cbe";
}

.mdi-radio-fm::before {
  content: "\f0cbf";
}

.mdi-radio-handheld::before {
  content: "\f043a";
}

.mdi-radio-off::before {
  content: "\f121c";
}

.mdi-radio-tower::before {
  content: "\f043b";
}

.mdi-radioactive::before {
  content: "\f043c";
}

.mdi-radioactive-circle::before {
  content: "\f185d";
}

.mdi-radioactive-circle-outline::before {
  content: "\f185e";
}

.mdi-radioactive-off::before {
  content: "\f0ec1";
}

.mdi-radiobox-blank::before {
  content: "\f043d";
}

.mdi-radiobox-marked::before {
  content: "\f043e";
}

.mdi-radiology-box::before {
  content: "\f14c5";
}

.mdi-radiology-box-outline::before {
  content: "\f14c6";
}

.mdi-radius::before {
  content: "\f0cc0";
}

.mdi-radius-outline::before {
  content: "\f0cc1";
}

.mdi-railroad-light::before {
  content: "\f0f1e";
}

.mdi-rake::before {
  content: "\f1544";
}

.mdi-raspberry-pi::before {
  content: "\f043f";
}

.mdi-ray-end::before {
  content: "\f0440";
}

.mdi-ray-end-arrow::before {
  content: "\f0441";
}

.mdi-ray-start::before {
  content: "\f0442";
}

.mdi-ray-start-arrow::before {
  content: "\f0443";
}

.mdi-ray-start-end::before {
  content: "\f0444";
}

.mdi-ray-start-vertex-end::before {
  content: "\f15d8";
}

.mdi-ray-vertex::before {
  content: "\f0445";
}

.mdi-razor-double-edge::before {
  content: "\f1997";
}

.mdi-razor-single-edge::before {
  content: "\f1998";
}

.mdi-react::before {
  content: "\f0708";
}

.mdi-read::before {
  content: "\f0447";
}

.mdi-receipt::before {
  content: "\f0449";
}

.mdi-record::before {
  content: "\f044a";
}

.mdi-record-circle::before {
  content: "\f0ec2";
}

.mdi-record-circle-outline::before {
  content: "\f0ec3";
}

.mdi-record-player::before {
  content: "\f099a";
}

.mdi-record-rec::before {
  content: "\f044b";
}

.mdi-rectangle::before {
  content: "\f0e5e";
}

.mdi-rectangle-outline::before {
  content: "\f0e5f";
}

.mdi-recycle::before {
  content: "\f044c";
}

.mdi-recycle-variant::before {
  content: "\f139d";
}

.mdi-reddit::before {
  content: "\f044d";
}

.mdi-redhat::before {
  content: "\f111b";
}

.mdi-redo::before {
  content: "\f044e";
}

.mdi-redo-variant::before {
  content: "\f044f";
}

.mdi-reflect-horizontal::before {
  content: "\f0a0e";
}

.mdi-reflect-vertical::before {
  content: "\f0a0f";
}

.mdi-refresh::before {
  content: "\f0450";
}

.mdi-refresh-auto::before {
  content: "\f18f2";
}

.mdi-refresh-circle::before {
  content: "\f1377";
}

.mdi-regex::before {
  content: "\f0451";
}

.mdi-registered-trademark::before {
  content: "\f0a67";
}

.mdi-reiterate::before {
  content: "\f1588";
}

.mdi-relation-many-to-many::before {
  content: "\f1496";
}

.mdi-relation-many-to-one::before {
  content: "\f1497";
}

.mdi-relation-many-to-one-or-many::before {
  content: "\f1498";
}

.mdi-relation-many-to-only-one::before {
  content: "\f1499";
}

.mdi-relation-many-to-zero-or-many::before {
  content: "\f149a";
}

.mdi-relation-many-to-zero-or-one::before {
  content: "\f149b";
}

.mdi-relation-one-or-many-to-many::before {
  content: "\f149c";
}

.mdi-relation-one-or-many-to-one::before {
  content: "\f149d";
}

.mdi-relation-one-or-many-to-one-or-many::before {
  content: "\f149e";
}

.mdi-relation-one-or-many-to-only-one::before {
  content: "\f149f";
}

.mdi-relation-one-or-many-to-zero-or-many::before {
  content: "\f14a0";
}

.mdi-relation-one-or-many-to-zero-or-one::before {
  content: "\f14a1";
}

.mdi-relation-one-to-many::before {
  content: "\f14a2";
}

.mdi-relation-one-to-one::before {
  content: "\f14a3";
}

.mdi-relation-one-to-one-or-many::before {
  content: "\f14a4";
}

.mdi-relation-one-to-only-one::before {
  content: "\f14a5";
}

.mdi-relation-one-to-zero-or-many::before {
  content: "\f14a6";
}

.mdi-relation-one-to-zero-or-one::before {
  content: "\f14a7";
}

.mdi-relation-only-one-to-many::before {
  content: "\f14a8";
}

.mdi-relation-only-one-to-one::before {
  content: "\f14a9";
}

.mdi-relation-only-one-to-one-or-many::before {
  content: "\f14aa";
}

.mdi-relation-only-one-to-only-one::before {
  content: "\f14ab";
}

.mdi-relation-only-one-to-zero-or-many::before {
  content: "\f14ac";
}

.mdi-relation-only-one-to-zero-or-one::before {
  content: "\f14ad";
}

.mdi-relation-zero-or-many-to-many::before {
  content: "\f14ae";
}

.mdi-relation-zero-or-many-to-one::before {
  content: "\f14af";
}

.mdi-relation-zero-or-many-to-one-or-many::before {
  content: "\f14b0";
}

.mdi-relation-zero-or-many-to-only-one::before {
  content: "\f14b1";
}

.mdi-relation-zero-or-many-to-zero-or-many::before {
  content: "\f14b2";
}

.mdi-relation-zero-or-many-to-zero-or-one::before {
  content: "\f14b3";
}

.mdi-relation-zero-or-one-to-many::before {
  content: "\f14b4";
}

.mdi-relation-zero-or-one-to-one::before {
  content: "\f14b5";
}

.mdi-relation-zero-or-one-to-one-or-many::before {
  content: "\f14b6";
}

.mdi-relation-zero-or-one-to-only-one::before {
  content: "\f14b7";
}

.mdi-relation-zero-or-one-to-zero-or-many::before {
  content: "\f14b8";
}

.mdi-relation-zero-or-one-to-zero-or-one::before {
  content: "\f14b9";
}

.mdi-relative-scale::before {
  content: "\f0452";
}

.mdi-reload::before {
  content: "\f0453";
}

.mdi-reload-alert::before {
  content: "\f110b";
}

.mdi-reminder::before {
  content: "\f088c";
}

.mdi-remote::before {
  content: "\f0454";
}

.mdi-remote-desktop::before {
  content: "\f08b9";
}

.mdi-remote-off::before {
  content: "\f0ec4";
}

.mdi-remote-tv::before {
  content: "\f0ec5";
}

.mdi-remote-tv-off::before {
  content: "\f0ec6";
}

.mdi-rename-box::before {
  content: "\f0455";
}

.mdi-reorder-horizontal::before {
  content: "\f0688";
}

.mdi-reorder-vertical::before {
  content: "\f0689";
}

.mdi-repeat::before {
  content: "\f0456";
}

.mdi-repeat-off::before {
  content: "\f0457";
}

.mdi-repeat-once::before {
  content: "\f0458";
}

.mdi-repeat-variant::before {
  content: "\f0547";
}

.mdi-replay::before {
  content: "\f0459";
}

.mdi-reply::before {
  content: "\f045a";
}

.mdi-reply-all::before {
  content: "\f045b";
}

.mdi-reply-all-outline::before {
  content: "\f0f1f";
}

.mdi-reply-circle::before {
  content: "\f11ae";
}

.mdi-reply-outline::before {
  content: "\f0f20";
}

.mdi-reproduction::before {
  content: "\f045c";
}

.mdi-resistor::before {
  content: "\f0b44";
}

.mdi-resistor-nodes::before {
  content: "\f0b45";
}

.mdi-resize::before {
  content: "\f0a68";
}

.mdi-resize-bottom-right::before {
  content: "\f045d";
}

.mdi-responsive::before {
  content: "\f045e";
}

.mdi-restart::before {
  content: "\f0709";
}

.mdi-restart-alert::before {
  content: "\f110c";
}

.mdi-restart-off::before {
  content: "\f0d95";
}

.mdi-restore::before {
  content: "\f099b";
}

.mdi-restore-alert::before {
  content: "\f110d";
}

.mdi-rewind::before {
  content: "\f045f";
}

.mdi-rewind-10::before {
  content: "\f0d2a";
}

.mdi-rewind-15::before {
  content: "\f1946";
}

.mdi-rewind-30::before {
  content: "\f0d96";
}

.mdi-rewind-5::before {
  content: "\f11f9";
}

.mdi-rewind-60::before {
  content: "\f160c";
}

.mdi-rewind-outline::before {
  content: "\f070a";
}

.mdi-rhombus::before {
  content: "\f070b";
}

.mdi-rhombus-medium::before {
  content: "\f0a10";
}

.mdi-rhombus-medium-outline::before {
  content: "\f14dc";
}

.mdi-rhombus-outline::before {
  content: "\f070c";
}

.mdi-rhombus-split::before {
  content: "\f0a11";
}

.mdi-rhombus-split-outline::before {
  content: "\f14dd";
}

.mdi-ribbon::before {
  content: "\f0460";
}

.mdi-rice::before {
  content: "\f07ea";
}

.mdi-rickshaw::before {
  content: "\f15bb";
}

.mdi-rickshaw-electric::before {
  content: "\f15bc";
}

.mdi-ring::before {
  content: "\f07eb";
}

.mdi-rivet::before {
  content: "\f0e60";
}

.mdi-road::before {
  content: "\f0461";
}

.mdi-road-variant::before {
  content: "\f0462";
}

.mdi-robber::before {
  content: "\f1058";
}

.mdi-robot::before {
  content: "\f06a9";
}

.mdi-robot-angry::before {
  content: "\f169d";
}

.mdi-robot-angry-outline::before {
  content: "\f169e";
}

.mdi-robot-confused::before {
  content: "\f169f";
}

.mdi-robot-confused-outline::before {
  content: "\f16a0";
}

.mdi-robot-dead::before {
  content: "\f16a1";
}

.mdi-robot-dead-outline::before {
  content: "\f16a2";
}

.mdi-robot-excited::before {
  content: "\f16a3";
}

.mdi-robot-excited-outline::before {
  content: "\f16a4";
}

.mdi-robot-happy::before {
  content: "\f1719";
}

.mdi-robot-happy-outline::before {
  content: "\f171a";
}

.mdi-robot-industrial::before {
  content: "\f0b46";
}

.mdi-robot-love::before {
  content: "\f16a5";
}

.mdi-robot-love-outline::before {
  content: "\f16a6";
}

.mdi-robot-mower::before {
  content: "\f11f7";
}

.mdi-robot-mower-outline::before {
  content: "\f11f3";
}

.mdi-robot-off::before {
  content: "\f16a7";
}

.mdi-robot-off-outline::before {
  content: "\f167b";
}

.mdi-robot-outline::before {
  content: "\f167a";
}

.mdi-robot-vacuum::before {
  content: "\f070d";
}

.mdi-robot-vacuum-variant::before {
  content: "\f0908";
}

.mdi-rocket::before {
  content: "\f0463";
}

.mdi-rocket-launch::before {
  content: "\f14de";
}

.mdi-rocket-launch-outline::before {
  content: "\f14df";
}

.mdi-rocket-outline::before {
  content: "\f13af";
}

.mdi-rodent::before {
  content: "\f1327";
}

.mdi-roller-skate::before {
  content: "\f0d2b";
}

.mdi-roller-skate-off::before {
  content: "\f0145";
}

.mdi-rollerblade::before {
  content: "\f0d2c";
}

.mdi-rollerblade-off::before {
  content: "\f002e";
}

.mdi-rollupjs::before {
  content: "\f0bc0";
}

.mdi-roman-numeral-1::before {
  content: "\f1088";
}

.mdi-roman-numeral-10::before {
  content: "\f1091";
}

.mdi-roman-numeral-2::before {
  content: "\f1089";
}

.mdi-roman-numeral-3::before {
  content: "\f108a";
}

.mdi-roman-numeral-4::before {
  content: "\f108b";
}

.mdi-roman-numeral-5::before {
  content: "\f108c";
}

.mdi-roman-numeral-6::before {
  content: "\f108d";
}

.mdi-roman-numeral-7::before {
  content: "\f108e";
}

.mdi-roman-numeral-8::before {
  content: "\f108f";
}

.mdi-roman-numeral-9::before {
  content: "\f1090";
}

.mdi-room-service::before {
  content: "\f088d";
}

.mdi-room-service-outline::before {
  content: "\f0d97";
}

.mdi-rotate-360::before {
  content: "\f1999";
}

.mdi-rotate-3d::before {
  content: "\f0ec7";
}

.mdi-rotate-3d-variant::before {
  content: "\f0464";
}

.mdi-rotate-left::before {
  content: "\f0465";
}

.mdi-rotate-left-variant::before {
  content: "\f0466";
}

.mdi-rotate-orbit::before {
  content: "\f0d98";
}

.mdi-rotate-right::before {
  content: "\f0467";
}

.mdi-rotate-right-variant::before {
  content: "\f0468";
}

.mdi-rounded-corner::before {
  content: "\f0607";
}

.mdi-router::before {
  content: "\f11e2";
}

.mdi-router-network::before {
  content: "\f1087";
}

.mdi-router-wireless::before {
  content: "\f0469";
}

.mdi-router-wireless-off::before {
  content: "\f15a3";
}

.mdi-router-wireless-settings::before {
  content: "\f0a69";
}

.mdi-routes::before {
  content: "\f046a";
}

.mdi-routes-clock::before {
  content: "\f1059";
}

.mdi-rowing::before {
  content: "\f0608";
}

.mdi-rss::before {
  content: "\f046b";
}

.mdi-rss-box::before {
  content: "\f046c";
}

.mdi-rss-off::before {
  content: "\f0f21";
}

.mdi-rug::before {
  content: "\f1475";
}

.mdi-rugby::before {
  content: "\f0d99";
}

.mdi-ruler::before {
  content: "\f046d";
}

.mdi-ruler-square::before {
  content: "\f0cc2";
}

.mdi-ruler-square-compass::before {
  content: "\f0ebe";
}

.mdi-run::before {
  content: "\f070e";
}

.mdi-run-fast::before {
  content: "\f046e";
}

.mdi-rv-truck::before {
  content: "\f11d4";
}

.mdi-sack::before {
  content: "\f0d2e";
}

.mdi-sack-percent::before {
  content: "\f0d2f";
}

.mdi-safe::before {
  content: "\f0a6a";
}

.mdi-safe-square::before {
  content: "\f127c";
}

.mdi-safe-square-outline::before {
  content: "\f127d";
}

.mdi-safety-goggles::before {
  content: "\f0d30";
}

.mdi-sail-boat::before {
  content: "\f0ec8";
}

.mdi-sale::before {
  content: "\f046f";
}

.mdi-salesforce::before {
  content: "\f088e";
}

.mdi-sass::before {
  content: "\f07ec";
}

.mdi-satellite::before {
  content: "\f0470";
}

.mdi-satellite-uplink::before {
  content: "\f0909";
}

.mdi-satellite-variant::before {
  content: "\f0471";
}

.mdi-sausage::before {
  content: "\f08ba";
}

.mdi-sausage-off::before {
  content: "\f1789";
}

.mdi-saw-blade::before {
  content: "\f0e61";
}

.mdi-sawtooth-wave::before {
  content: "\f147a";
}

.mdi-saxophone::before {
  content: "\f0609";
}

.mdi-scale::before {
  content: "\f0472";
}

.mdi-scale-balance::before {
  content: "\f05d1";
}

.mdi-scale-bathroom::before {
  content: "\f0473";
}

.mdi-scale-off::before {
  content: "\f105a";
}

.mdi-scale-unbalanced::before {
  content: "\f19b8";
}

.mdi-scan-helper::before {
  content: "\f13d8";
}

.mdi-scanner::before {
  content: "\f06ab";
}

.mdi-scanner-off::before {
  content: "\f090a";
}

.mdi-scatter-plot::before {
  content: "\f0ec9";
}

.mdi-scatter-plot-outline::before {
  content: "\f0eca";
}

.mdi-scent::before {
  content: "\f1958";
}

.mdi-scent-off::before {
  content: "\f1959";
}

.mdi-school::before {
  content: "\f0474";
}

.mdi-school-outline::before {
  content: "\f1180";
}

.mdi-scissors-cutting::before {
  content: "\f0a6b";
}

.mdi-scooter::before {
  content: "\f15bd";
}

.mdi-scooter-electric::before {
  content: "\f15be";
}

.mdi-scoreboard::before {
  content: "\f127e";
}

.mdi-scoreboard-outline::before {
  content: "\f127f";
}

.mdi-screen-rotation::before {
  content: "\f0475";
}

.mdi-screen-rotation-lock::before {
  content: "\f0478";
}

.mdi-screw-flat-top::before {
  content: "\f0df3";
}

.mdi-screw-lag::before {
  content: "\f0df4";
}

.mdi-screw-machine-flat-top::before {
  content: "\f0df5";
}

.mdi-screw-machine-round-top::before {
  content: "\f0df6";
}

.mdi-screw-round-top::before {
  content: "\f0df7";
}

.mdi-screwdriver::before {
  content: "\f0476";
}

.mdi-script::before {
  content: "\f0bc1";
}

.mdi-script-outline::before {
  content: "\f0477";
}

.mdi-script-text::before {
  content: "\f0bc2";
}

.mdi-script-text-key::before {
  content: "\f1725";
}

.mdi-script-text-key-outline::before {
  content: "\f1726";
}

.mdi-script-text-outline::before {
  content: "\f0bc3";
}

.mdi-script-text-play::before {
  content: "\f1727";
}

.mdi-script-text-play-outline::before {
  content: "\f1728";
}

.mdi-sd::before {
  content: "\f0479";
}

.mdi-seal::before {
  content: "\f047a";
}

.mdi-seal-variant::before {
  content: "\f0fd9";
}

.mdi-search-web::before {
  content: "\f070f";
}

.mdi-seat::before {
  content: "\f0cc3";
}

.mdi-seat-flat::before {
  content: "\f047b";
}

.mdi-seat-flat-angled::before {
  content: "\f047c";
}

.mdi-seat-individual-suite::before {
  content: "\f047d";
}

.mdi-seat-legroom-extra::before {
  content: "\f047e";
}

.mdi-seat-legroom-normal::before {
  content: "\f047f";
}

.mdi-seat-legroom-reduced::before {
  content: "\f0480";
}

.mdi-seat-outline::before {
  content: "\f0cc4";
}

.mdi-seat-passenger::before {
  content: "\f1249";
}

.mdi-seat-recline-extra::before {
  content: "\f0481";
}

.mdi-seat-recline-normal::before {
  content: "\f0482";
}

.mdi-seatbelt::before {
  content: "\f0cc5";
}

.mdi-security::before {
  content: "\f0483";
}

.mdi-security-network::before {
  content: "\f0484";
}

.mdi-seed::before {
  content: "\f0e62";
}

.mdi-seed-off::before {
  content: "\f13fd";
}

.mdi-seed-off-outline::before {
  content: "\f13fe";
}

.mdi-seed-outline::before {
  content: "\f0e63";
}

.mdi-seesaw::before {
  content: "\f15a4";
}

.mdi-segment::before {
  content: "\f0ecb";
}

.mdi-select::before {
  content: "\f0485";
}

.mdi-select-all::before {
  content: "\f0486";
}

.mdi-select-color::before {
  content: "\f0d31";
}

.mdi-select-compare::before {
  content: "\f0ad9";
}

.mdi-select-drag::before {
  content: "\f0a6c";
}

.mdi-select-group::before {
  content: "\f0f82";
}

.mdi-select-inverse::before {
  content: "\f0487";
}

.mdi-select-marker::before {
  content: "\f1280";
}

.mdi-select-multiple::before {
  content: "\f1281";
}

.mdi-select-multiple-marker::before {
  content: "\f1282";
}

.mdi-select-off::before {
  content: "\f0488";
}

.mdi-select-place::before {
  content: "\f0fda";
}

.mdi-select-remove::before {
  content: "\f17c1";
}

.mdi-select-search::before {
  content: "\f1204";
}

.mdi-selection::before {
  content: "\f0489";
}

.mdi-selection-drag::before {
  content: "\f0a6d";
}

.mdi-selection-ellipse::before {
  content: "\f0d32";
}

.mdi-selection-ellipse-arrow-inside::before {
  content: "\f0f22";
}

.mdi-selection-ellipse-remove::before {
  content: "\f17c2";
}

.mdi-selection-marker::before {
  content: "\f1283";
}

.mdi-selection-multiple::before {
  content: "\f1285";
}

.mdi-selection-multiple-marker::before {
  content: "\f1284";
}

.mdi-selection-off::before {
  content: "\f0777";
}

.mdi-selection-remove::before {
  content: "\f17c3";
}

.mdi-selection-search::before {
  content: "\f1205";
}

.mdi-semantic-web::before {
  content: "\f1316";
}

.mdi-send::before {
  content: "\f048a";
}

.mdi-send-check::before {
  content: "\f1161";
}

.mdi-send-check-outline::before {
  content: "\f1162";
}

.mdi-send-circle::before {
  content: "\f0df8";
}

.mdi-send-circle-outline::before {
  content: "\f0df9";
}

.mdi-send-clock::before {
  content: "\f1163";
}

.mdi-send-clock-outline::before {
  content: "\f1164";
}

.mdi-send-lock::before {
  content: "\f07ed";
}

.mdi-send-lock-outline::before {
  content: "\f1166";
}

.mdi-send-outline::before {
  content: "\f1165";
}

.mdi-serial-port::before {
  content: "\f065c";
}

.mdi-server::before {
  content: "\f048b";
}

.mdi-server-minus::before {
  content: "\f048c";
}

.mdi-server-network::before {
  content: "\f048d";
}

.mdi-server-network-off::before {
  content: "\f048e";
}

.mdi-server-off::before {
  content: "\f048f";
}

.mdi-server-plus::before {
  content: "\f0490";
}

.mdi-server-remove::before {
  content: "\f0491";
}

.mdi-server-security::before {
  content: "\f0492";
}

.mdi-set-all::before {
  content: "\f0778";
}

.mdi-set-center::before {
  content: "\f0779";
}

.mdi-set-center-right::before {
  content: "\f077a";
}

.mdi-set-left::before {
  content: "\f077b";
}

.mdi-set-left-center::before {
  content: "\f077c";
}

.mdi-set-left-right::before {
  content: "\f077d";
}

.mdi-set-merge::before {
  content: "\f14e0";
}

.mdi-set-none::before {
  content: "\f077e";
}

.mdi-set-right::before {
  content: "\f077f";
}

.mdi-set-split::before {
  content: "\f14e1";
}

.mdi-set-square::before {
  content: "\f145d";
}

.mdi-set-top-box::before {
  content: "\f099f";
}

.mdi-settings-helper::before {
  content: "\f0a6e";
}

.mdi-shaker::before {
  content: "\f110e";
}

.mdi-shaker-outline::before {
  content: "\f110f";
}

.mdi-shape::before {
  content: "\f0831";
}

.mdi-shape-circle-plus::before {
  content: "\f065d";
}

.mdi-shape-outline::before {
  content: "\f0832";
}

.mdi-shape-oval-plus::before {
  content: "\f11fa";
}

.mdi-shape-plus::before {
  content: "\f0495";
}

.mdi-shape-polygon-plus::before {
  content: "\f065e";
}

.mdi-shape-rectangle-plus::before {
  content: "\f065f";
}

.mdi-shape-square-plus::before {
  content: "\f0660";
}

.mdi-shape-square-rounded-plus::before {
  content: "\f14fa";
}

.mdi-share::before {
  content: "\f0496";
}

.mdi-share-all::before {
  content: "\f11f4";
}

.mdi-share-all-outline::before {
  content: "\f11f5";
}

.mdi-share-circle::before {
  content: "\f11ad";
}

.mdi-share-off::before {
  content: "\f0f23";
}

.mdi-share-off-outline::before {
  content: "\f0f24";
}

.mdi-share-outline::before {
  content: "\f0932";
}

.mdi-share-variant::before {
  content: "\f0497";
}

.mdi-share-variant-outline::before {
  content: "\f1514";
}

.mdi-shark::before {
  content: "\f18ba";
}

.mdi-shark-fin::before {
  content: "\f1673";
}

.mdi-shark-fin-outline::before {
  content: "\f1674";
}

.mdi-shark-off::before {
  content: "\f18bb";
}

.mdi-sheep::before {
  content: "\f0cc6";
}

.mdi-shield::before {
  content: "\f0498";
}

.mdi-shield-account::before {
  content: "\f088f";
}

.mdi-shield-account-outline::before {
  content: "\f0a12";
}

.mdi-shield-account-variant::before {
  content: "\f15a7";
}

.mdi-shield-account-variant-outline::before {
  content: "\f15a8";
}

.mdi-shield-airplane::before {
  content: "\f06bb";
}

.mdi-shield-airplane-outline::before {
  content: "\f0cc7";
}

.mdi-shield-alert::before {
  content: "\f0ecc";
}

.mdi-shield-alert-outline::before {
  content: "\f0ecd";
}

.mdi-shield-bug::before {
  content: "\f13da";
}

.mdi-shield-bug-outline::before {
  content: "\f13db";
}

.mdi-shield-car::before {
  content: "\f0f83";
}

.mdi-shield-check::before {
  content: "\f0565";
}

.mdi-shield-check-outline::before {
  content: "\f0cc8";
}

.mdi-shield-cross::before {
  content: "\f0cc9";
}

.mdi-shield-cross-outline::before {
  content: "\f0cca";
}

.mdi-shield-crown::before {
  content: "\f18bc";
}

.mdi-shield-crown-outline::before {
  content: "\f18bd";
}

.mdi-shield-edit::before {
  content: "\f11a0";
}

.mdi-shield-edit-outline::before {
  content: "\f11a1";
}

.mdi-shield-half::before {
  content: "\f1360";
}

.mdi-shield-half-full::before {
  content: "\f0780";
}

.mdi-shield-home::before {
  content: "\f068a";
}

.mdi-shield-home-outline::before {
  content: "\f0ccb";
}

.mdi-shield-key::before {
  content: "\f0bc4";
}

.mdi-shield-key-outline::before {
  content: "\f0bc5";
}

.mdi-shield-link-variant::before {
  content: "\f0d33";
}

.mdi-shield-link-variant-outline::before {
  content: "\f0d34";
}

.mdi-shield-lock::before {
  content: "\f099d";
}

.mdi-shield-lock-open::before {
  content: "\f199a";
}

.mdi-shield-lock-open-outline::before {
  content: "\f199b";
}

.mdi-shield-lock-outline::before {
  content: "\f0ccc";
}

.mdi-shield-moon::before {
  content: "\f1828";
}

.mdi-shield-moon-outline::before {
  content: "\f1829";
}

.mdi-shield-off::before {
  content: "\f099e";
}

.mdi-shield-off-outline::before {
  content: "\f099c";
}

.mdi-shield-outline::before {
  content: "\f0499";
}

.mdi-shield-plus::before {
  content: "\f0ada";
}

.mdi-shield-plus-outline::before {
  content: "\f0adb";
}

.mdi-shield-refresh::before {
  content: "\f00aa";
}

.mdi-shield-refresh-outline::before {
  content: "\f01e0";
}

.mdi-shield-remove::before {
  content: "\f0adc";
}

.mdi-shield-remove-outline::before {
  content: "\f0add";
}

.mdi-shield-search::before {
  content: "\f0d9a";
}

.mdi-shield-star::before {
  content: "\f113b";
}

.mdi-shield-star-outline::before {
  content: "\f113c";
}

.mdi-shield-sun::before {
  content: "\f105d";
}

.mdi-shield-sun-outline::before {
  content: "\f105e";
}

.mdi-shield-sword::before {
  content: "\f18be";
}

.mdi-shield-sword-outline::before {
  content: "\f18bf";
}

.mdi-shield-sync::before {
  content: "\f11a2";
}

.mdi-shield-sync-outline::before {
  content: "\f11a3";
}

.mdi-shimmer::before {
  content: "\f1545";
}

.mdi-ship-wheel::before {
  content: "\f0833";
}

.mdi-shipping-pallet::before {
  content: "\f184e";
}

.mdi-shoe-ballet::before {
  content: "\f15ca";
}

.mdi-shoe-cleat::before {
  content: "\f15c7";
}

.mdi-shoe-formal::before {
  content: "\f0b47";
}

.mdi-shoe-heel::before {
  content: "\f0b48";
}

.mdi-shoe-print::before {
  content: "\f0dfa";
}

.mdi-shoe-sneaker::before {
  content: "\f15c8";
}

.mdi-shopping::before {
  content: "\f049a";
}

.mdi-shopping-music::before {
  content: "\f049b";
}

.mdi-shopping-outline::before {
  content: "\f11d5";
}

.mdi-shopping-search::before {
  content: "\f0f84";
}

.mdi-shore::before {
  content: "\f14f9";
}

.mdi-shovel::before {
  content: "\f0710";
}

.mdi-shovel-off::before {
  content: "\f0711";
}

.mdi-shower::before {
  content: "\f09a0";
}

.mdi-shower-head::before {
  content: "\f09a1";
}

.mdi-shredder::before {
  content: "\f049c";
}

.mdi-shuffle::before {
  content: "\f049d";
}

.mdi-shuffle-disabled::before {
  content: "\f049e";
}

.mdi-shuffle-variant::before {
  content: "\f049f";
}

.mdi-shuriken::before {
  content: "\f137f";
}

.mdi-sickle::before {
  content: "\f18c0";
}

.mdi-sigma::before {
  content: "\f04a0";
}

.mdi-sigma-lower::before {
  content: "\f062b";
}

.mdi-sign-caution::before {
  content: "\f04a1";
}

.mdi-sign-direction::before {
  content: "\f0781";
}

.mdi-sign-direction-minus::before {
  content: "\f1000";
}

.mdi-sign-direction-plus::before {
  content: "\f0fdc";
}

.mdi-sign-direction-remove::before {
  content: "\f0fdd";
}

.mdi-sign-pole::before {
  content: "\f14f8";
}

.mdi-sign-real-estate::before {
  content: "\f1118";
}

.mdi-sign-text::before {
  content: "\f0782";
}

.mdi-signal::before {
  content: "\f04a2";
}

.mdi-signal-2g::before {
  content: "\f0712";
}

.mdi-signal-3g::before {
  content: "\f0713";
}

.mdi-signal-4g::before {
  content: "\f0714";
}

.mdi-signal-5g::before {
  content: "\f0a6f";
}

.mdi-signal-cellular-1::before {
  content: "\f08bc";
}

.mdi-signal-cellular-2::before {
  content: "\f08bd";
}

.mdi-signal-cellular-3::before {
  content: "\f08be";
}

.mdi-signal-cellular-outline::before {
  content: "\f08bf";
}

.mdi-signal-distance-variant::before {
  content: "\f0e64";
}

.mdi-signal-hspa::before {
  content: "\f0715";
}

.mdi-signal-hspa-plus::before {
  content: "\f0716";
}

.mdi-signal-off::before {
  content: "\f0783";
}

.mdi-signal-variant::before {
  content: "\f060a";
}

.mdi-signature::before {
  content: "\f0dfb";
}

.mdi-signature-freehand::before {
  content: "\f0dfc";
}

.mdi-signature-image::before {
  content: "\f0dfd";
}

.mdi-signature-text::before {
  content: "\f0dfe";
}

.mdi-silo::before {
  content: "\f0b49";
}

.mdi-silverware::before {
  content: "\f04a3";
}

.mdi-silverware-clean::before {
  content: "\f0fde";
}

.mdi-silverware-fork::before {
  content: "\f04a4";
}

.mdi-silverware-fork-knife::before {
  content: "\f0a70";
}

.mdi-silverware-spoon::before {
  content: "\f04a5";
}

.mdi-silverware-variant::before {
  content: "\f04a6";
}

.mdi-sim::before {
  content: "\f04a7";
}

.mdi-sim-alert::before {
  content: "\f04a8";
}

.mdi-sim-alert-outline::before {
  content: "\f15d3";
}

.mdi-sim-off::before {
  content: "\f04a9";
}

.mdi-sim-off-outline::before {
  content: "\f15d4";
}

.mdi-sim-outline::before {
  content: "\f15d5";
}

.mdi-simple-icons::before {
  content: "\f131d";
}

.mdi-sina-weibo::before {
  content: "\f0adf";
}

.mdi-sine-wave::before {
  content: "\f095b";
}

.mdi-sitemap::before {
  content: "\f04aa";
}

.mdi-sitemap-outline::before {
  content: "\f199c";
}

.mdi-size-l::before {
  content: "\f13a6";
}

.mdi-size-m::before {
  content: "\f13a5";
}

.mdi-size-s::before {
  content: "\f13a4";
}

.mdi-size-xl::before {
  content: "\f13a7";
}

.mdi-size-xs::before {
  content: "\f13a3";
}

.mdi-size-xxl::before {
  content: "\f13a8";
}

.mdi-size-xxs::before {
  content: "\f13a2";
}

.mdi-size-xxxl::before {
  content: "\f13a9";
}

.mdi-skate::before {
  content: "\f0d35";
}

.mdi-skate-off::before {
  content: "\f0699";
}

.mdi-skateboard::before {
  content: "\f14c2";
}

.mdi-skateboarding::before {
  content: "\f0501";
}

.mdi-skew-less::before {
  content: "\f0d36";
}

.mdi-skew-more::before {
  content: "\f0d37";
}

.mdi-ski::before {
  content: "\f1304";
}

.mdi-ski-cross-country::before {
  content: "\f1305";
}

.mdi-ski-water::before {
  content: "\f1306";
}

.mdi-skip-backward::before {
  content: "\f04ab";
}

.mdi-skip-backward-outline::before {
  content: "\f0f25";
}

.mdi-skip-forward::before {
  content: "\f04ac";
}

.mdi-skip-forward-outline::before {
  content: "\f0f26";
}

.mdi-skip-next::before {
  content: "\f04ad";
}

.mdi-skip-next-circle::before {
  content: "\f0661";
}

.mdi-skip-next-circle-outline::before {
  content: "\f0662";
}

.mdi-skip-next-outline::before {
  content: "\f0f27";
}

.mdi-skip-previous::before {
  content: "\f04ae";
}

.mdi-skip-previous-circle::before {
  content: "\f0663";
}

.mdi-skip-previous-circle-outline::before {
  content: "\f0664";
}

.mdi-skip-previous-outline::before {
  content: "\f0f28";
}

.mdi-skull::before {
  content: "\f068c";
}

.mdi-skull-crossbones::before {
  content: "\f0bc6";
}

.mdi-skull-crossbones-outline::before {
  content: "\f0bc7";
}

.mdi-skull-outline::before {
  content: "\f0bc8";
}

.mdi-skull-scan::before {
  content: "\f14c7";
}

.mdi-skull-scan-outline::before {
  content: "\f14c8";
}

.mdi-skype::before {
  content: "\f04af";
}

.mdi-skype-business::before {
  content: "\f04b0";
}

.mdi-slack::before {
  content: "\f04b1";
}

.mdi-slash-forward::before {
  content: "\f0fdf";
}

.mdi-slash-forward-box::before {
  content: "\f0fe0";
}

.mdi-sledding::before {
  content: "\f041b";
}

.mdi-sleep::before {
  content: "\f04b2";
}

.mdi-sleep-off::before {
  content: "\f04b3";
}

.mdi-slide::before {
  content: "\f15a5";
}

.mdi-slope-downhill::before {
  content: "\f0dff";
}

.mdi-slope-uphill::before {
  content: "\f0e00";
}

.mdi-slot-machine::before {
  content: "\f1114";
}

.mdi-slot-machine-outline::before {
  content: "\f1115";
}

.mdi-smart-card::before {
  content: "\f10bd";
}

.mdi-smart-card-off::before {
  content: "\f18f7";
}

.mdi-smart-card-off-outline::before {
  content: "\f18f8";
}

.mdi-smart-card-outline::before {
  content: "\f10be";
}

.mdi-smart-card-reader::before {
  content: "\f10bf";
}

.mdi-smart-card-reader-outline::before {
  content: "\f10c0";
}

.mdi-smog::before {
  content: "\f0a71";
}

.mdi-smoke::before {
  content: "\f1799";
}

.mdi-smoke-detector::before {
  content: "\f0392";
}

.mdi-smoke-detector-alert::before {
  content: "\f192e";
}

.mdi-smoke-detector-alert-outline::before {
  content: "\f192f";
}

.mdi-smoke-detector-off::before {
  content: "\f1809";
}

.mdi-smoke-detector-off-outline::before {
  content: "\f180a";
}

.mdi-smoke-detector-outline::before {
  content: "\f1808";
}

.mdi-smoke-detector-variant::before {
  content: "\f180b";
}

.mdi-smoke-detector-variant-alert::before {
  content: "\f1930";
}

.mdi-smoke-detector-variant-off::before {
  content: "\f180c";
}

.mdi-smoking::before {
  content: "\f04b4";
}

.mdi-smoking-off::before {
  content: "\f04b5";
}

.mdi-smoking-pipe::before {
  content: "\f140d";
}

.mdi-smoking-pipe-off::before {
  content: "\f1428";
}

.mdi-snail::before {
  content: "\f1677";
}

.mdi-snake::before {
  content: "\f150e";
}

.mdi-snapchat::before {
  content: "\f04b6";
}

.mdi-snowboard::before {
  content: "\f1307";
}

.mdi-snowflake::before {
  content: "\f0717";
}

.mdi-snowflake-alert::before {
  content: "\f0f29";
}

.mdi-snowflake-melt::before {
  content: "\f12cb";
}

.mdi-snowflake-off::before {
  content: "\f14e3";
}

.mdi-snowflake-variant::before {
  content: "\f0f2a";
}

.mdi-snowman::before {
  content: "\f04b7";
}

.mdi-snowmobile::before {
  content: "\f06dd";
}

.mdi-soccer::before {
  content: "\f04b8";
}

.mdi-soccer-field::before {
  content: "\f0834";
}

.mdi-social-distance-2-meters::before {
  content: "\f1579";
}

.mdi-social-distance-6-feet::before {
  content: "\f157a";
}

.mdi-sofa::before {
  content: "\f04b9";
}

.mdi-sofa-outline::before {
  content: "\f156d";
}

.mdi-sofa-single::before {
  content: "\f156e";
}

.mdi-sofa-single-outline::before {
  content: "\f156f";
}

.mdi-solar-panel::before {
  content: "\f0d9b";
}

.mdi-solar-panel-large::before {
  content: "\f0d9c";
}

.mdi-solar-power::before {
  content: "\f0a72";
}

.mdi-soldering-iron::before {
  content: "\f1092";
}

.mdi-solid::before {
  content: "\f068d";
}

.mdi-sony-playstation::before {
  content: "\f0414";
}

.mdi-sort::before {
  content: "\f04ba";
}

.mdi-sort-alphabetical-ascending::before {
  content: "\f05bd";
}

.mdi-sort-alphabetical-ascending-variant::before {
  content: "\f1148";
}

.mdi-sort-alphabetical-descending::before {
  content: "\f05bf";
}

.mdi-sort-alphabetical-descending-variant::before {
  content: "\f1149";
}

.mdi-sort-alphabetical-variant::before {
  content: "\f04bb";
}

.mdi-sort-ascending::before {
  content: "\f04bc";
}

.mdi-sort-bool-ascending::before {
  content: "\f1385";
}

.mdi-sort-bool-ascending-variant::before {
  content: "\f1386";
}

.mdi-sort-bool-descending::before {
  content: "\f1387";
}

.mdi-sort-bool-descending-variant::before {
  content: "\f1388";
}

.mdi-sort-calendar-ascending::before {
  content: "\f1547";
}

.mdi-sort-calendar-descending::before {
  content: "\f1548";
}

.mdi-sort-clock-ascending::before {
  content: "\f1549";
}

.mdi-sort-clock-ascending-outline::before {
  content: "\f154a";
}

.mdi-sort-clock-descending::before {
  content: "\f154b";
}

.mdi-sort-clock-descending-outline::before {
  content: "\f154c";
}

.mdi-sort-descending::before {
  content: "\f04bd";
}

.mdi-sort-numeric-ascending::before {
  content: "\f1389";
}

.mdi-sort-numeric-ascending-variant::before {
  content: "\f090d";
}

.mdi-sort-numeric-descending::before {
  content: "\f138a";
}

.mdi-sort-numeric-descending-variant::before {
  content: "\f0ad2";
}

.mdi-sort-numeric-variant::before {
  content: "\f04be";
}

.mdi-sort-reverse-variant::before {
  content: "\f033c";
}

.mdi-sort-variant::before {
  content: "\f04bf";
}

.mdi-sort-variant-lock::before {
  content: "\f0ccd";
}

.mdi-sort-variant-lock-open::before {
  content: "\f0cce";
}

.mdi-sort-variant-remove::before {
  content: "\f1147";
}

.mdi-soundbar::before {
  content: "\f17db";
}

.mdi-soundcloud::before {
  content: "\f04c0";
}

.mdi-source-branch::before {
  content: "\f062c";
}

.mdi-source-branch-check::before {
  content: "\f14cf";
}

.mdi-source-branch-minus::before {
  content: "\f14cb";
}

.mdi-source-branch-plus::before {
  content: "\f14ca";
}

.mdi-source-branch-refresh::before {
  content: "\f14cd";
}

.mdi-source-branch-remove::before {
  content: "\f14cc";
}

.mdi-source-branch-sync::before {
  content: "\f14ce";
}

.mdi-source-commit::before {
  content: "\f0718";
}

.mdi-source-commit-end::before {
  content: "\f0719";
}

.mdi-source-commit-end-local::before {
  content: "\f071a";
}

.mdi-source-commit-local::before {
  content: "\f071b";
}

.mdi-source-commit-next-local::before {
  content: "\f071c";
}

.mdi-source-commit-start::before {
  content: "\f071d";
}

.mdi-source-commit-start-next-local::before {
  content: "\f071e";
}

.mdi-source-fork::before {
  content: "\f04c1";
}

.mdi-source-merge::before {
  content: "\f062d";
}

.mdi-source-pull::before {
  content: "\f04c2";
}

.mdi-source-repository::before {
  content: "\f0ccf";
}

.mdi-source-repository-multiple::before {
  content: "\f0cd0";
}

.mdi-soy-sauce::before {
  content: "\f07ee";
}

.mdi-soy-sauce-off::before {
  content: "\f13fc";
}

.mdi-spa::before {
  content: "\f0cd1";
}

.mdi-spa-outline::before {
  content: "\f0cd2";
}

.mdi-space-invaders::before {
  content: "\f0bc9";
}

.mdi-space-station::before {
  content: "\f1383";
}

.mdi-spade::before {
  content: "\f0e65";
}

.mdi-speaker::before {
  content: "\f04c3";
}

.mdi-speaker-bluetooth::before {
  content: "\f09a2";
}

.mdi-speaker-multiple::before {
  content: "\f0d38";
}

.mdi-speaker-off::before {
  content: "\f04c4";
}

.mdi-speaker-wireless::before {
  content: "\f071f";
}

.mdi-spear::before {
  content: "\f1845";
}

.mdi-speedometer::before {
  content: "\f04c5";
}

.mdi-speedometer-medium::before {
  content: "\f0f85";
}

.mdi-speedometer-slow::before {
  content: "\f0f86";
}

.mdi-spellcheck::before {
  content: "\f04c6";
}

.mdi-sphere::before {
  content: "\f1954";
}

.mdi-sphere-off::before {
  content: "\f1955";
}

.mdi-spider::before {
  content: "\f11ea";
}

.mdi-spider-thread::before {
  content: "\f11eb";
}

.mdi-spider-web::before {
  content: "\f0bca";
}

.mdi-spirit-level::before {
  content: "\f14f1";
}

.mdi-spoon-sugar::before {
  content: "\f1429";
}

.mdi-spotify::before {
  content: "\f04c7";
}

.mdi-spotlight::before {
  content: "\f04c8";
}

.mdi-spotlight-beam::before {
  content: "\f04c9";
}

.mdi-spray::before {
  content: "\f0665";
}

.mdi-spray-bottle::before {
  content: "\f0ae0";
}

.mdi-sprinkler::before {
  content: "\f105f";
}

.mdi-sprinkler-fire::before {
  content: "\f199d";
}

.mdi-sprinkler-variant::before {
  content: "\f1060";
}

.mdi-sprout::before {
  content: "\f0e66";
}

.mdi-sprout-outline::before {
  content: "\f0e67";
}

.mdi-square::before {
  content: "\f0764";
}

.mdi-square-circle::before {
  content: "\f1500";
}

.mdi-square-edit-outline::before {
  content: "\f090c";
}

.mdi-square-medium::before {
  content: "\f0a13";
}

.mdi-square-medium-outline::before {
  content: "\f0a14";
}

.mdi-square-off::before {
  content: "\f12ee";
}

.mdi-square-off-outline::before {
  content: "\f12ef";
}

.mdi-square-opacity::before {
  content: "\f1854";
}

.mdi-square-outline::before {
  content: "\f0763";
}

.mdi-square-root::before {
  content: "\f0784";
}

.mdi-square-root-box::before {
  content: "\f09a3";
}

.mdi-square-rounded::before {
  content: "\f14fb";
}

.mdi-square-rounded-outline::before {
  content: "\f14fc";
}

.mdi-square-small::before {
  content: "\f0a15";
}

.mdi-square-wave::before {
  content: "\f147b";
}

.mdi-squeegee::before {
  content: "\f0ae1";
}

.mdi-ssh::before {
  content: "\f08c0";
}

.mdi-stack-exchange::before {
  content: "\f060b";
}

.mdi-stack-overflow::before {
  content: "\f04cc";
}

.mdi-stackpath::before {
  content: "\f0359";
}

.mdi-stadium::before {
  content: "\f0ff9";
}

.mdi-stadium-variant::before {
  content: "\f0720";
}

.mdi-stairs::before {
  content: "\f04cd";
}

.mdi-stairs-box::before {
  content: "\f139e";
}

.mdi-stairs-down::before {
  content: "\f12be";
}

.mdi-stairs-up::before {
  content: "\f12bd";
}

.mdi-stamper::before {
  content: "\f0d39";
}

.mdi-standard-definition::before {
  content: "\f07ef";
}

.mdi-star::before {
  content: "\f04ce";
}

.mdi-star-box::before {
  content: "\f0a73";
}

.mdi-star-box-multiple::before {
  content: "\f1286";
}

.mdi-star-box-multiple-outline::before {
  content: "\f1287";
}

.mdi-star-box-outline::before {
  content: "\f0a74";
}

.mdi-star-check::before {
  content: "\f1566";
}

.mdi-star-check-outline::before {
  content: "\f156a";
}

.mdi-star-circle::before {
  content: "\f04cf";
}

.mdi-star-circle-outline::before {
  content: "\f09a4";
}

.mdi-star-cog::before {
  content: "\f1668";
}

.mdi-star-cog-outline::before {
  content: "\f1669";
}

.mdi-star-crescent::before {
  content: "\f0979";
}

.mdi-star-david::before {
  content: "\f097a";
}

.mdi-star-face::before {
  content: "\f09a5";
}

.mdi-star-four-points::before {
  content: "\f0ae2";
}

.mdi-star-four-points-outline::before {
  content: "\f0ae3";
}

.mdi-star-half::before {
  content: "\f0246";
}

.mdi-star-half-full::before {
  content: "\f04d0";
}

.mdi-star-minus::before {
  content: "\f1564";
}

.mdi-star-minus-outline::before {
  content: "\f1568";
}

.mdi-star-off::before {
  content: "\f04d1";
}

.mdi-star-off-outline::before {
  content: "\f155b";
}

.mdi-star-outline::before {
  content: "\f04d2";
}

.mdi-star-plus::before {
  content: "\f1563";
}

.mdi-star-plus-outline::before {
  content: "\f1567";
}

.mdi-star-remove::before {
  content: "\f1565";
}

.mdi-star-remove-outline::before {
  content: "\f1569";
}

.mdi-star-settings::before {
  content: "\f166a";
}

.mdi-star-settings-outline::before {
  content: "\f166b";
}

.mdi-star-shooting::before {
  content: "\f1741";
}

.mdi-star-shooting-outline::before {
  content: "\f1742";
}

.mdi-star-three-points::before {
  content: "\f0ae4";
}

.mdi-star-three-points-outline::before {
  content: "\f0ae5";
}

.mdi-state-machine::before {
  content: "\f11ef";
}

.mdi-steam::before {
  content: "\f04d3";
}

.mdi-steering::before {
  content: "\f04d4";
}

.mdi-steering-off::before {
  content: "\f090e";
}

.mdi-step-backward::before {
  content: "\f04d5";
}

.mdi-step-backward-2::before {
  content: "\f04d6";
}

.mdi-step-forward::before {
  content: "\f04d7";
}

.mdi-step-forward-2::before {
  content: "\f04d8";
}

.mdi-stethoscope::before {
  content: "\f04d9";
}

.mdi-sticker::before {
  content: "\f1364";
}

.mdi-sticker-alert::before {
  content: "\f1365";
}

.mdi-sticker-alert-outline::before {
  content: "\f1366";
}

.mdi-sticker-check::before {
  content: "\f1367";
}

.mdi-sticker-check-outline::before {
  content: "\f1368";
}

.mdi-sticker-circle-outline::before {
  content: "\f05d0";
}

.mdi-sticker-emoji::before {
  content: "\f0785";
}

.mdi-sticker-minus::before {
  content: "\f1369";
}

.mdi-sticker-minus-outline::before {
  content: "\f136a";
}

.mdi-sticker-outline::before {
  content: "\f136b";
}

.mdi-sticker-plus::before {
  content: "\f136c";
}

.mdi-sticker-plus-outline::before {
  content: "\f136d";
}

.mdi-sticker-remove::before {
  content: "\f136e";
}

.mdi-sticker-remove-outline::before {
  content: "\f136f";
}

.mdi-sticker-text::before {
  content: "\f178e";
}

.mdi-sticker-text-outline::before {
  content: "\f178f";
}

.mdi-stocking::before {
  content: "\f04da";
}

.mdi-stomach::before {
  content: "\f1093";
}

.mdi-stool::before {
  content: "\f195d";
}

.mdi-stool-outline::before {
  content: "\f195e";
}

.mdi-stop::before {
  content: "\f04db";
}

.mdi-stop-circle::before {
  content: "\f0666";
}

.mdi-stop-circle-outline::before {
  content: "\f0667";
}

.mdi-store::before {
  content: "\f04dc";
}

.mdi-store-24-hour::before {
  content: "\f04dd";
}

.mdi-store-alert::before {
  content: "\f18c1";
}

.mdi-store-alert-outline::before {
  content: "\f18c2";
}

.mdi-store-check::before {
  content: "\f18c3";
}

.mdi-store-check-outline::before {
  content: "\f18c4";
}

.mdi-store-clock::before {
  content: "\f18c5";
}

.mdi-store-clock-outline::before {
  content: "\f18c6";
}

.mdi-store-cog::before {
  content: "\f18c7";
}

.mdi-store-cog-outline::before {
  content: "\f18c8";
}

.mdi-store-edit::before {
  content: "\f18c9";
}

.mdi-store-edit-outline::before {
  content: "\f18ca";
}

.mdi-store-marker::before {
  content: "\f18cb";
}

.mdi-store-marker-outline::before {
  content: "\f18cc";
}

.mdi-store-minus::before {
  content: "\f165e";
}

.mdi-store-minus-outline::before {
  content: "\f18cd";
}

.mdi-store-off::before {
  content: "\f18ce";
}

.mdi-store-off-outline::before {
  content: "\f18cf";
}

.mdi-store-outline::before {
  content: "\f1361";
}

.mdi-store-plus::before {
  content: "\f165f";
}

.mdi-store-plus-outline::before {
  content: "\f18d0";
}

.mdi-store-remove::before {
  content: "\f1660";
}

.mdi-store-remove-outline::before {
  content: "\f18d1";
}

.mdi-store-search::before {
  content: "\f18d2";
}

.mdi-store-search-outline::before {
  content: "\f18d3";
}

.mdi-store-settings::before {
  content: "\f18d4";
}

.mdi-store-settings-outline::before {
  content: "\f18d5";
}

.mdi-storefront::before {
  content: "\f07c7";
}

.mdi-storefront-outline::before {
  content: "\f10c1";
}

.mdi-stove::before {
  content: "\f04de";
}

.mdi-strategy::before {
  content: "\f11d6";
}

.mdi-stretch-to-page::before {
  content: "\f0f2b";
}

.mdi-stretch-to-page-outline::before {
  content: "\f0f2c";
}

.mdi-string-lights::before {
  content: "\f12ba";
}

.mdi-string-lights-off::before {
  content: "\f12bb";
}

.mdi-subdirectory-arrow-left::before {
  content: "\f060c";
}

.mdi-subdirectory-arrow-right::before {
  content: "\f060d";
}

.mdi-submarine::before {
  content: "\f156c";
}

.mdi-subtitles::before {
  content: "\f0a16";
}

.mdi-subtitles-outline::before {
  content: "\f0a17";
}

.mdi-subway::before {
  content: "\f06ac";
}

.mdi-subway-alert-variant::before {
  content: "\f0d9d";
}

.mdi-subway-variant::before {
  content: "\f04df";
}

.mdi-summit::before {
  content: "\f0786";
}

.mdi-sun-compass::before {
  content: "\f19a5";
}

.mdi-sun-snowflake::before {
  content: "\f1796";
}

.mdi-sun-thermometer::before {
  content: "\f18d6";
}

.mdi-sun-thermometer-outline::before {
  content: "\f18d7";
}

.mdi-sun-wireless::before {
  content: "\f17fe";
}

.mdi-sun-wireless-outline::before {
  content: "\f17ff";
}

.mdi-sunglasses::before {
  content: "\f04e0";
}

.mdi-surfing::before {
  content: "\f1746";
}

.mdi-surround-sound::before {
  content: "\f05c5";
}

.mdi-surround-sound-2-0::before {
  content: "\f07f0";
}

.mdi-surround-sound-2-1::before {
  content: "\f1729";
}

.mdi-surround-sound-3-1::before {
  content: "\f07f1";
}

.mdi-surround-sound-5-1::before {
  content: "\f07f2";
}

.mdi-surround-sound-5-1-2::before {
  content: "\f172a";
}

.mdi-surround-sound-7-1::before {
  content: "\f07f3";
}

.mdi-svg::before {
  content: "\f0721";
}

.mdi-swap-horizontal::before {
  content: "\f04e1";
}

.mdi-swap-horizontal-bold::before {
  content: "\f0bcd";
}

.mdi-swap-horizontal-circle::before {
  content: "\f0fe1";
}

.mdi-swap-horizontal-circle-outline::before {
  content: "\f0fe2";
}

.mdi-swap-horizontal-variant::before {
  content: "\f08c1";
}

.mdi-swap-vertical::before {
  content: "\f04e2";
}

.mdi-swap-vertical-bold::before {
  content: "\f0bce";
}

.mdi-swap-vertical-circle::before {
  content: "\f0fe3";
}

.mdi-swap-vertical-circle-outline::before {
  content: "\f0fe4";
}

.mdi-swap-vertical-variant::before {
  content: "\f08c2";
}

.mdi-swim::before {
  content: "\f04e3";
}

.mdi-switch::before {
  content: "\f04e4";
}

.mdi-sword::before {
  content: "\f04e5";
}

.mdi-sword-cross::before {
  content: "\f0787";
}

.mdi-syllabary-hangul::before {
  content: "\f1333";
}

.mdi-syllabary-hiragana::before {
  content: "\f1334";
}

.mdi-syllabary-katakana::before {
  content: "\f1335";
}

.mdi-syllabary-katakana-halfwidth::before {
  content: "\f1336";
}

.mdi-symbol::before {
  content: "\f1501";
}

.mdi-symfony::before {
  content: "\f0ae6";
}

.mdi-sync::before {
  content: "\f04e6";
}

.mdi-sync-alert::before {
  content: "\f04e7";
}

.mdi-sync-circle::before {
  content: "\f1378";
}

.mdi-sync-off::before {
  content: "\f04e8";
}

.mdi-tab::before {
  content: "\f04e9";
}

.mdi-tab-minus::before {
  content: "\f0b4b";
}

.mdi-tab-plus::before {
  content: "\f075c";
}

.mdi-tab-remove::before {
  content: "\f0b4c";
}

.mdi-tab-search::before {
  content: "\f199e";
}

.mdi-tab-unselected::before {
  content: "\f04ea";
}

.mdi-table::before {
  content: "\f04eb";
}

.mdi-table-account::before {
  content: "\f13b9";
}

.mdi-table-alert::before {
  content: "\f13ba";
}

.mdi-table-arrow-down::before {
  content: "\f13bb";
}

.mdi-table-arrow-left::before {
  content: "\f13bc";
}

.mdi-table-arrow-right::before {
  content: "\f13bd";
}

.mdi-table-arrow-up::before {
  content: "\f13be";
}

.mdi-table-border::before {
  content: "\f0a18";
}

.mdi-table-cancel::before {
  content: "\f13bf";
}

.mdi-table-chair::before {
  content: "\f1061";
}

.mdi-table-check::before {
  content: "\f13c0";
}

.mdi-table-clock::before {
  content: "\f13c1";
}

.mdi-table-cog::before {
  content: "\f13c2";
}

.mdi-table-column::before {
  content: "\f0835";
}

.mdi-table-column-plus-after::before {
  content: "\f04ec";
}

.mdi-table-column-plus-before::before {
  content: "\f04ed";
}

.mdi-table-column-remove::before {
  content: "\f04ee";
}

.mdi-table-column-width::before {
  content: "\f04ef";
}

.mdi-table-edit::before {
  content: "\f04f0";
}

.mdi-table-eye::before {
  content: "\f1094";
}

.mdi-table-eye-off::before {
  content: "\f13c3";
}

.mdi-table-furniture::before {
  content: "\f05bc";
}

.mdi-table-headers-eye::before {
  content: "\f121d";
}

.mdi-table-headers-eye-off::before {
  content: "\f121e";
}

.mdi-table-heart::before {
  content: "\f13c4";
}

.mdi-table-key::before {
  content: "\f13c5";
}

.mdi-table-large::before {
  content: "\f04f1";
}

.mdi-table-large-plus::before {
  content: "\f0f87";
}

.mdi-table-large-remove::before {
  content: "\f0f88";
}

.mdi-table-lock::before {
  content: "\f13c6";
}

.mdi-table-merge-cells::before {
  content: "\f09a6";
}

.mdi-table-minus::before {
  content: "\f13c7";
}

.mdi-table-multiple::before {
  content: "\f13c8";
}

.mdi-table-network::before {
  content: "\f13c9";
}

.mdi-table-of-contents::before {
  content: "\f0836";
}

.mdi-table-off::before {
  content: "\f13ca";
}

.mdi-table-picnic::before {
  content: "\f1743";
}

.mdi-table-pivot::before {
  content: "\f183c";
}

.mdi-table-plus::before {
  content: "\f0a75";
}

.mdi-table-refresh::before {
  content: "\f13a0";
}

.mdi-table-remove::before {
  content: "\f0a76";
}

.mdi-table-row::before {
  content: "\f0837";
}

.mdi-table-row-height::before {
  content: "\f04f2";
}

.mdi-table-row-plus-after::before {
  content: "\f04f3";
}

.mdi-table-row-plus-before::before {
  content: "\f04f4";
}

.mdi-table-row-remove::before {
  content: "\f04f5";
}

.mdi-table-search::before {
  content: "\f090f";
}

.mdi-table-settings::before {
  content: "\f0838";
}

.mdi-table-split-cell::before {
  content: "\f142a";
}

.mdi-table-star::before {
  content: "\f13cb";
}

.mdi-table-sync::before {
  content: "\f13a1";
}

.mdi-table-tennis::before {
  content: "\f0e68";
}

.mdi-tablet::before {
  content: "\f04f6";
}

.mdi-tablet-android::before {
  content: "\f04f7";
}

.mdi-tablet-cellphone::before {
  content: "\f09a7";
}

.mdi-tablet-dashboard::before {
  content: "\f0ece";
}

.mdi-taco::before {
  content: "\f0762";
}

.mdi-tag::before {
  content: "\f04f9";
}

.mdi-tag-arrow-down::before {
  content: "\f172b";
}

.mdi-tag-arrow-down-outline::before {
  content: "\f172c";
}

.mdi-tag-arrow-left::before {
  content: "\f172d";
}

.mdi-tag-arrow-left-outline::before {
  content: "\f172e";
}

.mdi-tag-arrow-right::before {
  content: "\f172f";
}

.mdi-tag-arrow-right-outline::before {
  content: "\f1730";
}

.mdi-tag-arrow-up::before {
  content: "\f1731";
}

.mdi-tag-arrow-up-outline::before {
  content: "\f1732";
}

.mdi-tag-faces::before {
  content: "\f04fa";
}

.mdi-tag-heart::before {
  content: "\f068b";
}

.mdi-tag-heart-outline::before {
  content: "\f0bcf";
}

.mdi-tag-minus::before {
  content: "\f0910";
}

.mdi-tag-minus-outline::before {
  content: "\f121f";
}

.mdi-tag-multiple::before {
  content: "\f04fb";
}

.mdi-tag-multiple-outline::before {
  content: "\f12f7";
}

.mdi-tag-off::before {
  content: "\f1220";
}

.mdi-tag-off-outline::before {
  content: "\f1221";
}

.mdi-tag-outline::before {
  content: "\f04fc";
}

.mdi-tag-plus::before {
  content: "\f0722";
}

.mdi-tag-plus-outline::before {
  content: "\f1222";
}

.mdi-tag-remove::before {
  content: "\f0723";
}

.mdi-tag-remove-outline::before {
  content: "\f1223";
}

.mdi-tag-search::before {
  content: "\f1907";
}

.mdi-tag-search-outline::before {
  content: "\f1908";
}

.mdi-tag-text::before {
  content: "\f1224";
}

.mdi-tag-text-outline::before {
  content: "\f04fd";
}

.mdi-tailwind::before {
  content: "\f13ff";
}

.mdi-tangram::before {
  content: "\f04f8";
}

.mdi-tank::before {
  content: "\f0d3a";
}

.mdi-tanker-truck::before {
  content: "\f0fe5";
}

.mdi-tape-drive::before {
  content: "\f16df";
}

.mdi-tape-measure::before {
  content: "\f0b4d";
}

.mdi-target::before {
  content: "\f04fe";
}

.mdi-target-account::before {
  content: "\f0bd0";
}

.mdi-target-variant::before {
  content: "\f0a77";
}

.mdi-taxi::before {
  content: "\f04ff";
}

.mdi-tea::before {
  content: "\f0d9e";
}

.mdi-tea-outline::before {
  content: "\f0d9f";
}

.mdi-teamviewer::before {
  content: "\f0500";
}

.mdi-teddy-bear::before {
  content: "\f18fb";
}

.mdi-telescope::before {
  content: "\f0b4e";
}

.mdi-television::before {
  content: "\f0502";
}

.mdi-television-ambient-light::before {
  content: "\f1356";
}

.mdi-television-box::before {
  content: "\f0839";
}

.mdi-television-classic::before {
  content: "\f07f4";
}

.mdi-television-classic-off::before {
  content: "\f083a";
}

.mdi-television-guide::before {
  content: "\f0503";
}

.mdi-television-off::before {
  content: "\f083b";
}

.mdi-television-pause::before {
  content: "\f0f89";
}

.mdi-television-play::before {
  content: "\f0ecf";
}

.mdi-television-shimmer::before {
  content: "\f1110";
}

.mdi-television-stop::before {
  content: "\f0f8a";
}

.mdi-temperature-celsius::before {
  content: "\f0504";
}

.mdi-temperature-fahrenheit::before {
  content: "\f0505";
}

.mdi-temperature-kelvin::before {
  content: "\f0506";
}

.mdi-tennis::before {
  content: "\f0da0";
}

.mdi-tennis-ball::before {
  content: "\f0507";
}

.mdi-tent::before {
  content: "\f0508";
}

.mdi-terraform::before {
  content: "\f1062";
}

.mdi-terrain::before {
  content: "\f0509";
}

.mdi-test-tube::before {
  content: "\f0668";
}

.mdi-test-tube-empty::before {
  content: "\f0911";
}

.mdi-test-tube-off::before {
  content: "\f0912";
}

.mdi-text::before {
  content: "\f09a8";
}

.mdi-text-account::before {
  content: "\f1570";
}

.mdi-text-box::before {
  content: "\f021a";
}

.mdi-text-box-check::before {
  content: "\f0ea6";
}

.mdi-text-box-check-outline::before {
  content: "\f0ea7";
}

.mdi-text-box-minus::before {
  content: "\f0ea8";
}

.mdi-text-box-minus-outline::before {
  content: "\f0ea9";
}

.mdi-text-box-multiple::before {
  content: "\f0ab7";
}

.mdi-text-box-multiple-outline::before {
  content: "\f0ab8";
}

.mdi-text-box-outline::before {
  content: "\f09ed";
}

.mdi-text-box-plus::before {
  content: "\f0eaa";
}

.mdi-text-box-plus-outline::before {
  content: "\f0eab";
}

.mdi-text-box-remove::before {
  content: "\f0eac";
}

.mdi-text-box-remove-outline::before {
  content: "\f0ead";
}

.mdi-text-box-search::before {
  content: "\f0eae";
}

.mdi-text-box-search-outline::before {
  content: "\f0eaf";
}

.mdi-text-long::before {
  content: "\f09aa";
}

.mdi-text-recognition::before {
  content: "\f113d";
}

.mdi-text-search::before {
  content: "\f13b8";
}

.mdi-text-shadow::before {
  content: "\f0669";
}

.mdi-text-short::before {
  content: "\f09a9";
}

.mdi-text-to-speech::before {
  content: "\f050a";
}

.mdi-text-to-speech-off::before {
  content: "\f050b";
}

.mdi-texture::before {
  content: "\f050c";
}

.mdi-texture-box::before {
  content: "\f0fe6";
}

.mdi-theater::before {
  content: "\f050d";
}

.mdi-theme-light-dark::before {
  content: "\f050e";
}

.mdi-thermometer::before {
  content: "\f050f";
}

.mdi-thermometer-alert::before {
  content: "\f0e01";
}

.mdi-thermometer-bluetooth::before {
  content: "\f1895";
}

.mdi-thermometer-chevron-down::before {
  content: "\f0e02";
}

.mdi-thermometer-chevron-up::before {
  content: "\f0e03";
}

.mdi-thermometer-high::before {
  content: "\f10c2";
}

.mdi-thermometer-lines::before {
  content: "\f0510";
}

.mdi-thermometer-low::before {
  content: "\f10c3";
}

.mdi-thermometer-minus::before {
  content: "\f0e04";
}

.mdi-thermometer-off::before {
  content: "\f1531";
}

.mdi-thermometer-plus::before {
  content: "\f0e05";
}

.mdi-thermostat::before {
  content: "\f0393";
}

.mdi-thermostat-box::before {
  content: "\f0891";
}

.mdi-thought-bubble::before {
  content: "\f07f6";
}

.mdi-thought-bubble-outline::before {
  content: "\f07f7";
}

.mdi-thumb-down::before {
  content: "\f0511";
}

.mdi-thumb-down-outline::before {
  content: "\f0512";
}

.mdi-thumb-up::before {
  content: "\f0513";
}

.mdi-thumb-up-outline::before {
  content: "\f0514";
}

.mdi-thumbs-up-down::before {
  content: "\f0515";
}

.mdi-thumbs-up-down-outline::before {
  content: "\f1914";
}

.mdi-ticket::before {
  content: "\f0516";
}

.mdi-ticket-account::before {
  content: "\f0517";
}

.mdi-ticket-confirmation::before {
  content: "\f0518";
}

.mdi-ticket-confirmation-outline::before {
  content: "\f13aa";
}

.mdi-ticket-outline::before {
  content: "\f0913";
}

.mdi-ticket-percent::before {
  content: "\f0724";
}

.mdi-ticket-percent-outline::before {
  content: "\f142b";
}

.mdi-tie::before {
  content: "\f0519";
}

.mdi-tilde::before {
  content: "\f0725";
}

.mdi-tilde-off::before {
  content: "\f18f3";
}

.mdi-timelapse::before {
  content: "\f051a";
}

.mdi-timeline::before {
  content: "\f0bd1";
}

.mdi-timeline-alert::before {
  content: "\f0f95";
}

.mdi-timeline-alert-outline::before {
  content: "\f0f98";
}

.mdi-timeline-check::before {
  content: "\f1532";
}

.mdi-timeline-check-outline::before {
  content: "\f1533";
}

.mdi-timeline-clock::before {
  content: "\f11fb";
}

.mdi-timeline-clock-outline::before {
  content: "\f11fc";
}

.mdi-timeline-help::before {
  content: "\f0f99";
}

.mdi-timeline-help-outline::before {
  content: "\f0f9a";
}

.mdi-timeline-minus::before {
  content: "\f1534";
}

.mdi-timeline-minus-outline::before {
  content: "\f1535";
}

.mdi-timeline-outline::before {
  content: "\f0bd2";
}

.mdi-timeline-plus::before {
  content: "\f0f96";
}

.mdi-timeline-plus-outline::before {
  content: "\f0f97";
}

.mdi-timeline-remove::before {
  content: "\f1536";
}

.mdi-timeline-remove-outline::before {
  content: "\f1537";
}

.mdi-timeline-text::before {
  content: "\f0bd3";
}

.mdi-timeline-text-outline::before {
  content: "\f0bd4";
}

.mdi-timer::before {
  content: "\f13ab";
}

.mdi-timer-10::before {
  content: "\f051c";
}

.mdi-timer-3::before {
  content: "\f051d";
}

.mdi-timer-cog::before {
  content: "\f1925";
}

.mdi-timer-cog-outline::before {
  content: "\f1926";
}

.mdi-timer-off::before {
  content: "\f13ac";
}

.mdi-timer-off-outline::before {
  content: "\f051e";
}

.mdi-timer-outline::before {
  content: "\f051b";
}

.mdi-timer-sand::before {
  content: "\f051f";
}

.mdi-timer-sand-complete::before {
  content: "\f199f";
}

.mdi-timer-sand-empty::before {
  content: "\f06ad";
}

.mdi-timer-sand-full::before {
  content: "\f078c";
}

.mdi-timer-sand-paused::before {
  content: "\f19a0";
}

.mdi-timer-settings::before {
  content: "\f1923";
}

.mdi-timer-settings-outline::before {
  content: "\f1924";
}

.mdi-timetable::before {
  content: "\f0520";
}

.mdi-tire::before {
  content: "\f1896";
}

.mdi-toaster::before {
  content: "\f1063";
}

.mdi-toaster-off::before {
  content: "\f11b7";
}

.mdi-toaster-oven::before {
  content: "\f0cd3";
}

.mdi-toggle-switch::before {
  content: "\f0521";
}

.mdi-toggle-switch-off::before {
  content: "\f0522";
}

.mdi-toggle-switch-off-outline::before {
  content: "\f0a19";
}

.mdi-toggle-switch-outline::before {
  content: "\f0a1a";
}

.mdi-toilet::before {
  content: "\f09ab";
}

.mdi-toolbox::before {
  content: "\f09ac";
}

.mdi-toolbox-outline::before {
  content: "\f09ad";
}

.mdi-tools::before {
  content: "\f1064";
}

.mdi-tooltip::before {
  content: "\f0523";
}

.mdi-tooltip-account::before {
  content: "\f000c";
}

.mdi-tooltip-cellphone::before {
  content: "\f183b";
}

.mdi-tooltip-check::before {
  content: "\f155c";
}

.mdi-tooltip-check-outline::before {
  content: "\f155d";
}

.mdi-tooltip-edit::before {
  content: "\f0524";
}

.mdi-tooltip-edit-outline::before {
  content: "\f12c5";
}

.mdi-tooltip-image::before {
  content: "\f0525";
}

.mdi-tooltip-image-outline::before {
  content: "\f0bd5";
}

.mdi-tooltip-minus::before {
  content: "\f155e";
}

.mdi-tooltip-minus-outline::before {
  content: "\f155f";
}

.mdi-tooltip-outline::before {
  content: "\f0526";
}

.mdi-tooltip-plus::before {
  content: "\f0bd6";
}

.mdi-tooltip-plus-outline::before {
  content: "\f0527";
}

.mdi-tooltip-remove::before {
  content: "\f1560";
}

.mdi-tooltip-remove-outline::before {
  content: "\f1561";
}

.mdi-tooltip-text::before {
  content: "\f0528";
}

.mdi-tooltip-text-outline::before {
  content: "\f0bd7";
}

.mdi-tooth::before {
  content: "\f08c3";
}

.mdi-tooth-outline::before {
  content: "\f0529";
}

.mdi-toothbrush::before {
  content: "\f1129";
}

.mdi-toothbrush-electric::before {
  content: "\f112c";
}

.mdi-toothbrush-paste::before {
  content: "\f112a";
}

.mdi-torch::before {
  content: "\f1606";
}

.mdi-tortoise::before {
  content: "\f0d3b";
}

.mdi-toslink::before {
  content: "\f12b8";
}

.mdi-tournament::before {
  content: "\f09ae";
}

.mdi-tow-truck::before {
  content: "\f083c";
}

.mdi-tower-beach::before {
  content: "\f0681";
}

.mdi-tower-fire::before {
  content: "\f0682";
}

.mdi-town-hall::before {
  content: "\f1875";
}

.mdi-toy-brick::before {
  content: "\f1288";
}

.mdi-toy-brick-marker::before {
  content: "\f1289";
}

.mdi-toy-brick-marker-outline::before {
  content: "\f128a";
}

.mdi-toy-brick-minus::before {
  content: "\f128b";
}

.mdi-toy-brick-minus-outline::before {
  content: "\f128c";
}

.mdi-toy-brick-outline::before {
  content: "\f128d";
}

.mdi-toy-brick-plus::before {
  content: "\f128e";
}

.mdi-toy-brick-plus-outline::before {
  content: "\f128f";
}

.mdi-toy-brick-remove::before {
  content: "\f1290";
}

.mdi-toy-brick-remove-outline::before {
  content: "\f1291";
}

.mdi-toy-brick-search::before {
  content: "\f1292";
}

.mdi-toy-brick-search-outline::before {
  content: "\f1293";
}

.mdi-track-light::before {
  content: "\f0914";
}

.mdi-trackpad::before {
  content: "\f07f8";
}

.mdi-trackpad-lock::before {
  content: "\f0933";
}

.mdi-tractor::before {
  content: "\f0892";
}

.mdi-tractor-variant::before {
  content: "\f14c4";
}

.mdi-trademark::before {
  content: "\f0a78";
}

.mdi-traffic-cone::before {
  content: "\f137c";
}

.mdi-traffic-light::before {
  content: "\f052b";
}

.mdi-traffic-light-outline::before {
  content: "\f182a";
}

.mdi-train::before {
  content: "\f052c";
}

.mdi-train-car::before {
  content: "\f0bd8";
}

.mdi-train-car-passenger::before {
  content: "\f1733";
}

.mdi-train-car-passenger-door::before {
  content: "\f1734";
}

.mdi-train-car-passenger-door-open::before {
  content: "\f1735";
}

.mdi-train-car-passenger-variant::before {
  content: "\f1736";
}

.mdi-train-variant::before {
  content: "\f08c4";
}

.mdi-tram::before {
  content: "\f052d";
}

.mdi-tram-side::before {
  content: "\f0fe7";
}

.mdi-transcribe::before {
  content: "\f052e";
}

.mdi-transcribe-close::before {
  content: "\f052f";
}

.mdi-transfer::before {
  content: "\f1065";
}

.mdi-transfer-down::before {
  content: "\f0da1";
}

.mdi-transfer-left::before {
  content: "\f0da2";
}

.mdi-transfer-right::before {
  content: "\f0530";
}

.mdi-transfer-up::before {
  content: "\f0da3";
}

.mdi-transit-connection::before {
  content: "\f0d3c";
}

.mdi-transit-connection-horizontal::before {
  content: "\f1546";
}

.mdi-transit-connection-variant::before {
  content: "\f0d3d";
}

.mdi-transit-detour::before {
  content: "\f0f8b";
}

.mdi-transit-skip::before {
  content: "\f1515";
}

.mdi-transit-transfer::before {
  content: "\f06ae";
}

.mdi-transition::before {
  content: "\f0915";
}

.mdi-transition-masked::before {
  content: "\f0916";
}

.mdi-translate::before {
  content: "\f05ca";
}

.mdi-translate-off::before {
  content: "\f0e06";
}

.mdi-transmission-tower::before {
  content: "\f0d3e";
}

.mdi-transmission-tower-export::before {
  content: "\f192c";
}

.mdi-transmission-tower-import::before {
  content: "\f192d";
}

.mdi-trash-can::before {
  content: "\f0a79";
}

.mdi-trash-can-outline::before {
  content: "\f0a7a";
}

.mdi-tray::before {
  content: "\f1294";
}

.mdi-tray-alert::before {
  content: "\f1295";
}

.mdi-tray-arrow-down::before {
  content: "\f0120";
}

.mdi-tray-arrow-up::before {
  content: "\f011d";
}

.mdi-tray-full::before {
  content: "\f1296";
}

.mdi-tray-minus::before {
  content: "\f1297";
}

.mdi-tray-plus::before {
  content: "\f1298";
}

.mdi-tray-remove::before {
  content: "\f1299";
}

.mdi-treasure-chest::before {
  content: "\f0726";
}

.mdi-tree::before {
  content: "\f0531";
}

.mdi-tree-outline::before {
  content: "\f0e69";
}

.mdi-trello::before {
  content: "\f0532";
}

.mdi-trending-down::before {
  content: "\f0533";
}

.mdi-trending-neutral::before {
  content: "\f0534";
}

.mdi-trending-up::before {
  content: "\f0535";
}

.mdi-triangle::before {
  content: "\f0536";
}

.mdi-triangle-outline::before {
  content: "\f0537";
}

.mdi-triangle-wave::before {
  content: "\f147c";
}

.mdi-triforce::before {
  content: "\f0bd9";
}

.mdi-trophy::before {
  content: "\f0538";
}

.mdi-trophy-award::before {
  content: "\f0539";
}

.mdi-trophy-broken::before {
  content: "\f0da4";
}

.mdi-trophy-outline::before {
  content: "\f053a";
}

.mdi-trophy-variant::before {
  content: "\f053b";
}

.mdi-trophy-variant-outline::before {
  content: "\f053c";
}

.mdi-truck::before {
  content: "\f053d";
}

.mdi-truck-cargo-container::before {
  content: "\f18d8";
}

.mdi-truck-check::before {
  content: "\f0cd4";
}

.mdi-truck-check-outline::before {
  content: "\f129a";
}

.mdi-truck-delivery::before {
  content: "\f053e";
}

.mdi-truck-delivery-outline::before {
  content: "\f129b";
}

.mdi-truck-fast::before {
  content: "\f0788";
}

.mdi-truck-fast-outline::before {
  content: "\f129c";
}

.mdi-truck-flatbed::before {
  content: "\f1891";
}

.mdi-truck-minus::before {
  content: "\f19ae";
}

.mdi-truck-minus-outline::before {
  content: "\f19bd";
}

.mdi-truck-outline::before {
  content: "\f129d";
}

.mdi-truck-plus::before {
  content: "\f19ad";
}

.mdi-truck-plus-outline::before {
  content: "\f19bc";
}

.mdi-truck-remove::before {
  content: "\f19af";
}

.mdi-truck-remove-outline::before {
  content: "\f19be";
}

.mdi-truck-snowflake::before {
  content: "\f19a6";
}

.mdi-truck-trailer::before {
  content: "\f0727";
}

.mdi-trumpet::before {
  content: "\f1096";
}

.mdi-tshirt-crew::before {
  content: "\f0a7b";
}

.mdi-tshirt-crew-outline::before {
  content: "\f053f";
}

.mdi-tshirt-v::before {
  content: "\f0a7c";
}

.mdi-tshirt-v-outline::before {
  content: "\f0540";
}

.mdi-tumble-dryer::before {
  content: "\f0917";
}

.mdi-tumble-dryer-alert::before {
  content: "\f11ba";
}

.mdi-tumble-dryer-off::before {
  content: "\f11bb";
}

.mdi-tune::before {
  content: "\f062e";
}

.mdi-tune-variant::before {
  content: "\f1542";
}

.mdi-tune-vertical::before {
  content: "\f066a";
}

.mdi-tune-vertical-variant::before {
  content: "\f1543";
}

.mdi-tunnel::before {
  content: "\f183d";
}

.mdi-tunnel-outline::before {
  content: "\f183e";
}

.mdi-turkey::before {
  content: "\f171b";
}

.mdi-turnstile::before {
  content: "\f0cd5";
}

.mdi-turnstile-outline::before {
  content: "\f0cd6";
}

.mdi-turtle::before {
  content: "\f0cd7";
}

.mdi-twitch::before {
  content: "\f0543";
}

.mdi-twitter::before {
  content: "\f0544";
}

.mdi-two-factor-authentication::before {
  content: "\f09af";
}

.mdi-typewriter::before {
  content: "\f0f2d";
}

.mdi-ubisoft::before {
  content: "\f0bda";
}

.mdi-ubuntu::before {
  content: "\f0548";
}

.mdi-ufo::before {
  content: "\f10c4";
}

.mdi-ufo-outline::before {
  content: "\f10c5";
}

.mdi-ultra-high-definition::before {
  content: "\f07f9";
}

.mdi-umbraco::before {
  content: "\f0549";
}

.mdi-umbrella::before {
  content: "\f054a";
}

.mdi-umbrella-beach::before {
  content: "\f188a";
}

.mdi-umbrella-beach-outline::before {
  content: "\f188b";
}

.mdi-umbrella-closed::before {
  content: "\f09b0";
}

.mdi-umbrella-closed-outline::before {
  content: "\f13e2";
}

.mdi-umbrella-closed-variant::before {
  content: "\f13e1";
}

.mdi-umbrella-outline::before {
  content: "\f054b";
}

.mdi-undo::before {
  content: "\f054c";
}

.mdi-undo-variant::before {
  content: "\f054d";
}

.mdi-unfold-less-horizontal::before {
  content: "\f054e";
}

.mdi-unfold-less-vertical::before {
  content: "\f0760";
}

.mdi-unfold-more-horizontal::before {
  content: "\f054f";
}

.mdi-unfold-more-vertical::before {
  content: "\f0761";
}

.mdi-ungroup::before {
  content: "\f0550";
}

.mdi-unicode::before {
  content: "\f0ed0";
}

.mdi-unicorn::before {
  content: "\f15c2";
}

.mdi-unicorn-variant::before {
  content: "\f15c3";
}

.mdi-unicycle::before {
  content: "\f15e5";
}

.mdi-unity::before {
  content: "\f06af";
}

.mdi-unreal::before {
  content: "\f09b1";
}

.mdi-update::before {
  content: "\f06b0";
}

.mdi-upload::before {
  content: "\f0552";
}

.mdi-upload-lock::before {
  content: "\f1373";
}

.mdi-upload-lock-outline::before {
  content: "\f1374";
}

.mdi-upload-multiple::before {
  content: "\f083d";
}

.mdi-upload-network::before {
  content: "\f06f6";
}

.mdi-upload-network-outline::before {
  content: "\f0cd8";
}

.mdi-upload-off::before {
  content: "\f10c6";
}

.mdi-upload-off-outline::before {
  content: "\f10c7";
}

.mdi-upload-outline::before {
  content: "\f0e07";
}

.mdi-usb::before {
  content: "\f0553";
}

.mdi-usb-flash-drive::before {
  content: "\f129e";
}

.mdi-usb-flash-drive-outline::before {
  content: "\f129f";
}

.mdi-usb-port::before {
  content: "\f11f0";
}

.mdi-vacuum::before {
  content: "\f19a1";
}

.mdi-vacuum-outline::before {
  content: "\f19a2";
}

.mdi-valve::before {
  content: "\f1066";
}

.mdi-valve-closed::before {
  content: "\f1067";
}

.mdi-valve-open::before {
  content: "\f1068";
}

.mdi-van-passenger::before {
  content: "\f07fa";
}

.mdi-van-utility::before {
  content: "\f07fb";
}

.mdi-vanish::before {
  content: "\f07fc";
}

.mdi-vanish-quarter::before {
  content: "\f1554";
}

.mdi-vanity-light::before {
  content: "\f11e1";
}

.mdi-variable::before {
  content: "\f0ae7";
}

.mdi-variable-box::before {
  content: "\f1111";
}

.mdi-vector-arrange-above::before {
  content: "\f0554";
}

.mdi-vector-arrange-below::before {
  content: "\f0555";
}

.mdi-vector-bezier::before {
  content: "\f0ae8";
}

.mdi-vector-circle::before {
  content: "\f0556";
}

.mdi-vector-circle-variant::before {
  content: "\f0557";
}

.mdi-vector-combine::before {
  content: "\f0558";
}

.mdi-vector-curve::before {
  content: "\f0559";
}

.mdi-vector-difference::before {
  content: "\f055a";
}

.mdi-vector-difference-ab::before {
  content: "\f055b";
}

.mdi-vector-difference-ba::before {
  content: "\f055c";
}

.mdi-vector-ellipse::before {
  content: "\f0893";
}

.mdi-vector-intersection::before {
  content: "\f055d";
}

.mdi-vector-line::before {
  content: "\f055e";
}

.mdi-vector-link::before {
  content: "\f0fe8";
}

.mdi-vector-point::before {
  content: "\f055f";
}

.mdi-vector-polygon::before {
  content: "\f0560";
}

.mdi-vector-polygon-variant::before {
  content: "\f1856";
}

.mdi-vector-polyline::before {
  content: "\f0561";
}

.mdi-vector-polyline-edit::before {
  content: "\f1225";
}

.mdi-vector-polyline-minus::before {
  content: "\f1226";
}

.mdi-vector-polyline-plus::before {
  content: "\f1227";
}

.mdi-vector-polyline-remove::before {
  content: "\f1228";
}

.mdi-vector-radius::before {
  content: "\f074a";
}

.mdi-vector-rectangle::before {
  content: "\f05c6";
}

.mdi-vector-selection::before {
  content: "\f0562";
}

.mdi-vector-square::before {
  content: "\f0001";
}

.mdi-vector-square-close::before {
  content: "\f1857";
}

.mdi-vector-square-edit::before {
  content: "\f18d9";
}

.mdi-vector-square-minus::before {
  content: "\f18da";
}

.mdi-vector-square-open::before {
  content: "\f1858";
}

.mdi-vector-square-plus::before {
  content: "\f18db";
}

.mdi-vector-square-remove::before {
  content: "\f18dc";
}

.mdi-vector-triangle::before {
  content: "\f0563";
}

.mdi-vector-union::before {
  content: "\f0564";
}

.mdi-vhs::before {
  content: "\f0a1b";
}

.mdi-vibrate::before {
  content: "\f0566";
}

.mdi-vibrate-off::before {
  content: "\f0cd9";
}

.mdi-video::before {
  content: "\f0567";
}

.mdi-video-3d::before {
  content: "\f07fd";
}

.mdi-video-3d-off::before {
  content: "\f13d9";
}

.mdi-video-3d-variant::before {
  content: "\f0ed1";
}

.mdi-video-4k-box::before {
  content: "\f083e";
}

.mdi-video-account::before {
  content: "\f0919";
}

.mdi-video-box::before {
  content: "\f00fd";
}

.mdi-video-box-off::before {
  content: "\f00fe";
}

.mdi-video-check::before {
  content: "\f1069";
}

.mdi-video-check-outline::before {
  content: "\f106a";
}

.mdi-video-high-definition::before {
  content: "\f152e";
}

.mdi-video-image::before {
  content: "\f091a";
}

.mdi-video-input-antenna::before {
  content: "\f083f";
}

.mdi-video-input-component::before {
  content: "\f0840";
}

.mdi-video-input-hdmi::before {
  content: "\f0841";
}

.mdi-video-input-scart::before {
  content: "\f0f8c";
}

.mdi-video-input-svideo::before {
  content: "\f0842";
}

.mdi-video-marker::before {
  content: "\f19a9";
}

.mdi-video-marker-outline::before {
  content: "\f19aa";
}

.mdi-video-minus::before {
  content: "\f09b2";
}

.mdi-video-minus-outline::before {
  content: "\f02ba";
}

.mdi-video-off::before {
  content: "\f0568";
}

.mdi-video-off-outline::before {
  content: "\f0bdb";
}

.mdi-video-outline::before {
  content: "\f0bdc";
}

.mdi-video-plus::before {
  content: "\f09b3";
}

.mdi-video-plus-outline::before {
  content: "\f01d3";
}

.mdi-video-stabilization::before {
  content: "\f091b";
}

.mdi-video-switch::before {
  content: "\f0569";
}

.mdi-video-switch-outline::before {
  content: "\f0790";
}

.mdi-video-vintage::before {
  content: "\f0a1c";
}

.mdi-video-wireless::before {
  content: "\f0ed2";
}

.mdi-video-wireless-outline::before {
  content: "\f0ed3";
}

.mdi-view-agenda::before {
  content: "\f056a";
}

.mdi-view-agenda-outline::before {
  content: "\f11d8";
}

.mdi-view-array::before {
  content: "\f056b";
}

.mdi-view-array-outline::before {
  content: "\f1485";
}

.mdi-view-carousel::before {
  content: "\f056c";
}

.mdi-view-carousel-outline::before {
  content: "\f1486";
}

.mdi-view-column::before {
  content: "\f056d";
}

.mdi-view-column-outline::before {
  content: "\f1487";
}

.mdi-view-comfy::before {
  content: "\f0e6a";
}

.mdi-view-comfy-outline::before {
  content: "\f1488";
}

.mdi-view-compact::before {
  content: "\f0e6b";
}

.mdi-view-compact-outline::before {
  content: "\f0e6c";
}

.mdi-view-dashboard::before {
  content: "\f056e";
}

.mdi-view-dashboard-edit::before {
  content: "\f1947";
}

.mdi-view-dashboard-edit-outline::before {
  content: "\f1948";
}

.mdi-view-dashboard-outline::before {
  content: "\f0a1d";
}

.mdi-view-dashboard-variant::before {
  content: "\f0843";
}

.mdi-view-dashboard-variant-outline::before {
  content: "\f1489";
}

.mdi-view-day::before {
  content: "\f056f";
}

.mdi-view-day-outline::before {
  content: "\f148a";
}

.mdi-view-gallery::before {
  content: "\f1888";
}

.mdi-view-gallery-outline::before {
  content: "\f1889";
}

.mdi-view-grid::before {
  content: "\f0570";
}

.mdi-view-grid-outline::before {
  content: "\f11d9";
}

.mdi-view-grid-plus::before {
  content: "\f0f8d";
}

.mdi-view-grid-plus-outline::before {
  content: "\f11da";
}

.mdi-view-headline::before {
  content: "\f0571";
}

.mdi-view-list::before {
  content: "\f0572";
}

.mdi-view-list-outline::before {
  content: "\f148b";
}

.mdi-view-module::before {
  content: "\f0573";
}

.mdi-view-module-outline::before {
  content: "\f148c";
}

.mdi-view-parallel::before {
  content: "\f0728";
}

.mdi-view-parallel-outline::before {
  content: "\f148d";
}

.mdi-view-quilt::before {
  content: "\f0574";
}

.mdi-view-quilt-outline::before {
  content: "\f148e";
}

.mdi-view-sequential::before {
  content: "\f0729";
}

.mdi-view-sequential-outline::before {
  content: "\f148f";
}

.mdi-view-split-horizontal::before {
  content: "\f0bcb";
}

.mdi-view-split-vertical::before {
  content: "\f0bcc";
}

.mdi-view-stream::before {
  content: "\f0575";
}

.mdi-view-stream-outline::before {
  content: "\f1490";
}

.mdi-view-week::before {
  content: "\f0576";
}

.mdi-view-week-outline::before {
  content: "\f1491";
}

.mdi-vimeo::before {
  content: "\f0577";
}

.mdi-violin::before {
  content: "\f060f";
}

.mdi-virtual-reality::before {
  content: "\f0894";
}

.mdi-virus::before {
  content: "\f13b6";
}

.mdi-virus-off::before {
  content: "\f18e1";
}

.mdi-virus-off-outline::before {
  content: "\f18e2";
}

.mdi-virus-outline::before {
  content: "\f13b7";
}

.mdi-vlc::before {
  content: "\f057c";
}

.mdi-voicemail::before {
  content: "\f057d";
}

.mdi-volleyball::before {
  content: "\f09b4";
}

.mdi-volume-high::before {
  content: "\f057e";
}

.mdi-volume-low::before {
  content: "\f057f";
}

.mdi-volume-medium::before {
  content: "\f0580";
}

.mdi-volume-minus::before {
  content: "\f075e";
}

.mdi-volume-mute::before {
  content: "\f075f";
}

.mdi-volume-off::before {
  content: "\f0581";
}

.mdi-volume-plus::before {
  content: "\f075d";
}

.mdi-volume-source::before {
  content: "\f1120";
}

.mdi-volume-variant-off::before {
  content: "\f0e08";
}

.mdi-volume-vibrate::before {
  content: "\f1121";
}

.mdi-vote::before {
  content: "\f0a1f";
}

.mdi-vote-outline::before {
  content: "\f0a20";
}

.mdi-vpn::before {
  content: "\f0582";
}

.mdi-vuejs::before {
  content: "\f0844";
}

.mdi-vuetify::before {
  content: "\f0e6d";
}

.mdi-walk::before {
  content: "\f0583";
}

.mdi-wall::before {
  content: "\f07fe";
}

.mdi-wall-sconce::before {
  content: "\f091c";
}

.mdi-wall-sconce-flat::before {
  content: "\f091d";
}

.mdi-wall-sconce-flat-outline::before {
  content: "\f17c9";
}

.mdi-wall-sconce-flat-variant::before {
  content: "\f041c";
}

.mdi-wall-sconce-flat-variant-outline::before {
  content: "\f17ca";
}

.mdi-wall-sconce-outline::before {
  content: "\f17cb";
}

.mdi-wall-sconce-round::before {
  content: "\f0748";
}

.mdi-wall-sconce-round-outline::before {
  content: "\f17cc";
}

.mdi-wall-sconce-round-variant::before {
  content: "\f091e";
}

.mdi-wall-sconce-round-variant-outline::before {
  content: "\f17cd";
}

.mdi-wallet::before {
  content: "\f0584";
}

.mdi-wallet-giftcard::before {
  content: "\f0585";
}

.mdi-wallet-membership::before {
  content: "\f0586";
}

.mdi-wallet-outline::before {
  content: "\f0bdd";
}

.mdi-wallet-plus::before {
  content: "\f0f8e";
}

.mdi-wallet-plus-outline::before {
  content: "\f0f8f";
}

.mdi-wallet-travel::before {
  content: "\f0587";
}

.mdi-wallpaper::before {
  content: "\f0e09";
}

.mdi-wan::before {
  content: "\f0588";
}

.mdi-wardrobe::before {
  content: "\f0f90";
}

.mdi-wardrobe-outline::before {
  content: "\f0f91";
}

.mdi-warehouse::before {
  content: "\f0f81";
}

.mdi-washing-machine::before {
  content: "\f072a";
}

.mdi-washing-machine-alert::before {
  content: "\f11bc";
}

.mdi-washing-machine-off::before {
  content: "\f11bd";
}

.mdi-watch::before {
  content: "\f0589";
}

.mdi-watch-export::before {
  content: "\f058a";
}

.mdi-watch-export-variant::before {
  content: "\f0895";
}

.mdi-watch-import::before {
  content: "\f058b";
}

.mdi-watch-import-variant::before {
  content: "\f0896";
}

.mdi-watch-variant::before {
  content: "\f0897";
}

.mdi-watch-vibrate::before {
  content: "\f06b1";
}

.mdi-watch-vibrate-off::before {
  content: "\f0cda";
}

.mdi-water::before {
  content: "\f058c";
}

.mdi-water-alert::before {
  content: "\f1502";
}

.mdi-water-alert-outline::before {
  content: "\f1503";
}

.mdi-water-boiler::before {
  content: "\f0f92";
}

.mdi-water-boiler-alert::before {
  content: "\f11b3";
}

.mdi-water-boiler-off::before {
  content: "\f11b4";
}

.mdi-water-check::before {
  content: "\f1504";
}

.mdi-water-check-outline::before {
  content: "\f1505";
}

.mdi-water-circle::before {
  content: "\f1806";
}

.mdi-water-minus::before {
  content: "\f1506";
}

.mdi-water-minus-outline::before {
  content: "\f1507";
}

.mdi-water-off::before {
  content: "\f058d";
}

.mdi-water-off-outline::before {
  content: "\f1508";
}

.mdi-water-opacity::before {
  content: "\f1855";
}

.mdi-water-outline::before {
  content: "\f0e0a";
}

.mdi-water-percent::before {
  content: "\f058e";
}

.mdi-water-percent-alert::before {
  content: "\f1509";
}

.mdi-water-plus::before {
  content: "\f150a";
}

.mdi-water-plus-outline::before {
  content: "\f150b";
}

.mdi-water-polo::before {
  content: "\f12a0";
}

.mdi-water-pump::before {
  content: "\f058f";
}

.mdi-water-pump-off::before {
  content: "\f0f93";
}

.mdi-water-remove::before {
  content: "\f150c";
}

.mdi-water-remove-outline::before {
  content: "\f150d";
}

.mdi-water-sync::before {
  content: "\f17c6";
}

.mdi-water-well::before {
  content: "\f106b";
}

.mdi-water-well-outline::before {
  content: "\f106c";
}

.mdi-waterfall::before {
  content: "\f1849";
}

.mdi-watering-can::before {
  content: "\f1481";
}

.mdi-watering-can-outline::before {
  content: "\f1482";
}

.mdi-watermark::before {
  content: "\f0612";
}

.mdi-wave::before {
  content: "\f0f2e";
}

.mdi-waveform::before {
  content: "\f147d";
}

.mdi-waves::before {
  content: "\f078d";
}

.mdi-waves-arrow-left::before {
  content: "\f1859";
}

.mdi-waves-arrow-right::before {
  content: "\f185a";
}

.mdi-waves-arrow-up::before {
  content: "\f185b";
}

.mdi-waze::before {
  content: "\f0bde";
}

.mdi-weather-cloudy::before {
  content: "\f0590";
}

.mdi-weather-cloudy-alert::before {
  content: "\f0f2f";
}

.mdi-weather-cloudy-arrow-right::before {
  content: "\f0e6e";
}

.mdi-weather-cloudy-clock::before {
  content: "\f18f6";
}

.mdi-weather-fog::before {
  content: "\f0591";
}

.mdi-weather-hail::before {
  content: "\f0592";
}

.mdi-weather-hazy::before {
  content: "\f0f30";
}

.mdi-weather-hurricane::before {
  content: "\f0898";
}

.mdi-weather-lightning::before {
  content: "\f0593";
}

.mdi-weather-lightning-rainy::before {
  content: "\f067e";
}

.mdi-weather-night::before {
  content: "\f0594";
}

.mdi-weather-night-partly-cloudy::before {
  content: "\f0f31";
}

.mdi-weather-partly-cloudy::before {
  content: "\f0595";
}

.mdi-weather-partly-lightning::before {
  content: "\f0f32";
}

.mdi-weather-partly-rainy::before {
  content: "\f0f33";
}

.mdi-weather-partly-snowy::before {
  content: "\f0f34";
}

.mdi-weather-partly-snowy-rainy::before {
  content: "\f0f35";
}

.mdi-weather-pouring::before {
  content: "\f0596";
}

.mdi-weather-rainy::before {
  content: "\f0597";
}

.mdi-weather-snowy::before {
  content: "\f0598";
}

.mdi-weather-snowy-heavy::before {
  content: "\f0f36";
}

.mdi-weather-snowy-rainy::before {
  content: "\f067f";
}

.mdi-weather-sunny::before {
  content: "\f0599";
}

.mdi-weather-sunny-alert::before {
  content: "\f0f37";
}

.mdi-weather-sunny-off::before {
  content: "\f14e4";
}

.mdi-weather-sunset::before {
  content: "\f059a";
}

.mdi-weather-sunset-down::before {
  content: "\f059b";
}

.mdi-weather-sunset-up::before {
  content: "\f059c";
}

.mdi-weather-tornado::before {
  content: "\f0f38";
}

.mdi-weather-windy::before {
  content: "\f059d";
}

.mdi-weather-windy-variant::before {
  content: "\f059e";
}

.mdi-web::before {
  content: "\f059f";
}

.mdi-web-box::before {
  content: "\f0f94";
}

.mdi-web-cancel::before {
  content: "\f1790";
}

.mdi-web-check::before {
  content: "\f0789";
}

.mdi-web-clock::before {
  content: "\f124a";
}

.mdi-web-minus::before {
  content: "\f10a0";
}

.mdi-web-off::before {
  content: "\f0a8e";
}

.mdi-web-plus::before {
  content: "\f0033";
}

.mdi-web-refresh::before {
  content: "\f1791";
}

.mdi-web-remove::before {
  content: "\f0551";
}

.mdi-web-sync::before {
  content: "\f1792";
}

.mdi-webcam::before {
  content: "\f05a0";
}

.mdi-webcam-off::before {
  content: "\f1737";
}

.mdi-webhook::before {
  content: "\f062f";
}

.mdi-webpack::before {
  content: "\f072b";
}

.mdi-webrtc::before {
  content: "\f1248";
}

.mdi-wechat::before {
  content: "\f0611";
}

.mdi-weight::before {
  content: "\f05a1";
}

.mdi-weight-gram::before {
  content: "\f0d3f";
}

.mdi-weight-kilogram::before {
  content: "\f05a2";
}

.mdi-weight-lifter::before {
  content: "\f115d";
}

.mdi-weight-pound::before {
  content: "\f09b5";
}

.mdi-whatsapp::before {
  content: "\f05a3";
}

.mdi-wheel-barrow::before {
  content: "\f14f2";
}

.mdi-wheelchair-accessibility::before {
  content: "\f05a4";
}

.mdi-whistle::before {
  content: "\f09b6";
}

.mdi-whistle-outline::before {
  content: "\f12bc";
}

.mdi-white-balance-auto::before {
  content: "\f05a5";
}

.mdi-white-balance-incandescent::before {
  content: "\f05a6";
}

.mdi-white-balance-iridescent::before {
  content: "\f05a7";
}

.mdi-white-balance-sunny::before {
  content: "\f05a8";
}

.mdi-widgets::before {
  content: "\f072c";
}

.mdi-widgets-outline::before {
  content: "\f1355";
}

.mdi-wifi::before {
  content: "\f05a9";
}

.mdi-wifi-alert::before {
  content: "\f16b5";
}

.mdi-wifi-arrow-down::before {
  content: "\f16b6";
}

.mdi-wifi-arrow-left::before {
  content: "\f16b7";
}

.mdi-wifi-arrow-left-right::before {
  content: "\f16b8";
}

.mdi-wifi-arrow-right::before {
  content: "\f16b9";
}

.mdi-wifi-arrow-up::before {
  content: "\f16ba";
}

.mdi-wifi-arrow-up-down::before {
  content: "\f16bb";
}

.mdi-wifi-cancel::before {
  content: "\f16bc";
}

.mdi-wifi-check::before {
  content: "\f16bd";
}

.mdi-wifi-cog::before {
  content: "\f16be";
}

.mdi-wifi-lock::before {
  content: "\f16bf";
}

.mdi-wifi-lock-open::before {
  content: "\f16c0";
}

.mdi-wifi-marker::before {
  content: "\f16c1";
}

.mdi-wifi-minus::before {
  content: "\f16c2";
}

.mdi-wifi-off::before {
  content: "\f05aa";
}

.mdi-wifi-plus::before {
  content: "\f16c3";
}

.mdi-wifi-refresh::before {
  content: "\f16c4";
}

.mdi-wifi-remove::before {
  content: "\f16c5";
}

.mdi-wifi-settings::before {
  content: "\f16c6";
}

.mdi-wifi-star::before {
  content: "\f0e0b";
}

.mdi-wifi-strength-1::before {
  content: "\f091f";
}

.mdi-wifi-strength-1-alert::before {
  content: "\f0920";
}

.mdi-wifi-strength-1-lock::before {
  content: "\f0921";
}

.mdi-wifi-strength-1-lock-open::before {
  content: "\f16cb";
}

.mdi-wifi-strength-2::before {
  content: "\f0922";
}

.mdi-wifi-strength-2-alert::before {
  content: "\f0923";
}

.mdi-wifi-strength-2-lock::before {
  content: "\f0924";
}

.mdi-wifi-strength-2-lock-open::before {
  content: "\f16cc";
}

.mdi-wifi-strength-3::before {
  content: "\f0925";
}

.mdi-wifi-strength-3-alert::before {
  content: "\f0926";
}

.mdi-wifi-strength-3-lock::before {
  content: "\f0927";
}

.mdi-wifi-strength-3-lock-open::before {
  content: "\f16cd";
}

.mdi-wifi-strength-4::before {
  content: "\f0928";
}

.mdi-wifi-strength-4-alert::before {
  content: "\f0929";
}

.mdi-wifi-strength-4-lock::before {
  content: "\f092a";
}

.mdi-wifi-strength-4-lock-open::before {
  content: "\f16ce";
}

.mdi-wifi-strength-alert-outline::before {
  content: "\f092b";
}

.mdi-wifi-strength-lock-open-outline::before {
  content: "\f16cf";
}

.mdi-wifi-strength-lock-outline::before {
  content: "\f092c";
}

.mdi-wifi-strength-off::before {
  content: "\f092d";
}

.mdi-wifi-strength-off-outline::before {
  content: "\f092e";
}

.mdi-wifi-strength-outline::before {
  content: "\f092f";
}

.mdi-wifi-sync::before {
  content: "\f16c7";
}

.mdi-wikipedia::before {
  content: "\f05ac";
}

.mdi-wind-turbine::before {
  content: "\f0da5";
}

.mdi-wind-turbine-alert::before {
  content: "\f19ab";
}

.mdi-wind-turbine-check::before {
  content: "\f19ac";
}

.mdi-window-close::before {
  content: "\f05ad";
}

.mdi-window-closed::before {
  content: "\f05ae";
}

.mdi-window-closed-variant::before {
  content: "\f11db";
}

.mdi-window-maximize::before {
  content: "\f05af";
}

.mdi-window-minimize::before {
  content: "\f05b0";
}

.mdi-window-open::before {
  content: "\f05b1";
}

.mdi-window-open-variant::before {
  content: "\f11dc";
}

.mdi-window-restore::before {
  content: "\f05b2";
}

.mdi-window-shutter::before {
  content: "\f111c";
}

.mdi-window-shutter-alert::before {
  content: "\f111d";
}

.mdi-window-shutter-open::before {
  content: "\f111e";
}

.mdi-windsock::before {
  content: "\f15fa";
}

.mdi-wiper::before {
  content: "\f0ae9";
}

.mdi-wiper-wash::before {
  content: "\f0da6";
}

.mdi-wiper-wash-alert::before {
  content: "\f18df";
}

.mdi-wizard-hat::before {
  content: "\f1477";
}

.mdi-wordpress::before {
  content: "\f05b4";
}

.mdi-wrap::before {
  content: "\f05b6";
}

.mdi-wrap-disabled::before {
  content: "\f0bdf";
}

.mdi-wrench::before {
  content: "\f05b7";
}

.mdi-wrench-clock::before {
  content: "\f19a3";
}

.mdi-wrench-outline::before {
  content: "\f0be0";
}

.mdi-xamarin::before {
  content: "\f0845";
}

.mdi-xml::before {
  content: "\f05c0";
}

.mdi-xmpp::before {
  content: "\f07ff";
}

.mdi-yahoo::before {
  content: "\f0b4f";
}

.mdi-yeast::before {
  content: "\f05c1";
}

.mdi-yin-yang::before {
  content: "\f0680";
}

.mdi-yoga::before {
  content: "\f117c";
}

.mdi-youtube::before {
  content: "\f05c3";
}

.mdi-youtube-gaming::before {
  content: "\f0848";
}

.mdi-youtube-studio::before {
  content: "\f0847";
}

.mdi-youtube-subscription::before {
  content: "\f0d40";
}

.mdi-youtube-tv::before {
  content: "\f0448";
}

.mdi-yurt::before {
  content: "\f1516";
}

.mdi-z-wave::before {
  content: "\f0aea";
}

.mdi-zend::before {
  content: "\f0aeb";
}

.mdi-zigbee::before {
  content: "\f0d41";
}

.mdi-zip-box::before {
  content: "\f05c4";
}

.mdi-zip-box-outline::before {
  content: "\f0ffa";
}

.mdi-zip-disk::before {
  content: "\f0a23";
}

.mdi-zodiac-aquarius::before {
  content: "\f0a7d";
}

.mdi-zodiac-aries::before {
  content: "\f0a7e";
}

.mdi-zodiac-cancer::before {
  content: "\f0a7f";
}

.mdi-zodiac-capricorn::before {
  content: "\f0a80";
}

.mdi-zodiac-gemini::before {
  content: "\f0a81";
}

.mdi-zodiac-leo::before {
  content: "\f0a82";
}

.mdi-zodiac-libra::before {
  content: "\f0a83";
}

.mdi-zodiac-pisces::before {
  content: "\f0a84";
}

.mdi-zodiac-sagittarius::before {
  content: "\f0a85";
}

.mdi-zodiac-scorpio::before {
  content: "\f0a86";
}

.mdi-zodiac-taurus::before {
  content: "\f0a87";
}

.mdi-zodiac-virgo::before {
  content: "\f0a88";
}

.mdi-blank::before {
  content: "\f68c";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: white;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(45deg);
            transform: scaleX(-1) rotate(45deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: scaleY(-1) rotate(45deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(90deg);
            transform: scaleX(-1) rotate(90deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: scaleY(-1) rotate(90deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(135deg);
            transform: scaleX(-1) rotate(135deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: scaleY(-1) rotate(135deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(180deg);
            transform: scaleX(-1) rotate(180deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: scaleY(-1) rotate(180deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(225deg);
            transform: scaleX(-1) rotate(225deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: scaleY(-1) rotate(225deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(270deg);
            transform: scaleX(-1) rotate(270deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: scaleY(-1) rotate(270deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315 {
  /*
        // Not included in production
        &.mdi-flip-h:before {
            -webkit-transform: scaleX(-1) rotate(315deg);
            transform: scaleX(-1) rotate(315deg);
            filter: FlipH;
            -ms-filter: "FlipH";
        }
        &.mdi-flip-v:before {
            -webkit-transform: scaleY(-1) rotate(315deg);
            -ms-transform: rotate(315deg);
            transform: scaleY(-1) rotate(315deg);
            filter: FlipV;
            -ms-filter: "FlipV";
        }
        */
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@font-face {
  font-family: "boxicons";
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/boxicons.eot");
  src: url("../fonts/boxicons.eot") format("embedded-opentype"), url("../fonts/boxicons.woff2") format("woff2"), url("../fonts/boxicons.woff") format("woff"), url("../fonts/boxicons.ttf") format("truetype"), url("../fonts/boxicons.svg?#boxicons") format("svg");
}
.bx {
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bx-ul {
  margin-left: 2em;
  padding-left: 0;
  list-style: none;
}

.bx-ul > li {
  position: relative;
}

.bx-ul .bx {
  font-size: inherit;
  line-height: inherit;
  position: absolute;
  left: -2em;
  width: 2em;
  text-align: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes burst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes burst {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  90% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes flashing {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@keyframes flashing {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@keyframes fade-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@keyframes fade-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}
@-webkit-keyframes fade-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@keyframes fade-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
}
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }
  40%, 60%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.bx-spin {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.bx-spin-hover:hover {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.bx-tada {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.bx-tada-hover:hover {
  -webkit-animation: tada 1.5s ease infinite;
  animation: tada 1.5s ease infinite;
}

.bx-flashing {
  -webkit-animation: flashing 1.5s infinite linear;
  animation: flashing 1.5s infinite linear;
}

.bx-flashing-hover:hover {
  -webkit-animation: flashing 1.5s infinite linear;
  animation: flashing 1.5s infinite linear;
}

.bx-burst {
  -webkit-animation: burst 1.5s infinite linear;
  animation: burst 1.5s infinite linear;
}

.bx-burst-hover:hover {
  -webkit-animation: burst 1.5s infinite linear;
  animation: burst 1.5s infinite linear;
}

.bx-fade-up {
  -webkit-animation: fade-up 1.5s infinite linear;
  animation: fade-up 1.5s infinite linear;
}

.bx-fade-up-hover:hover {
  -webkit-animation: fade-up 1.5s infinite linear;
  animation: fade-up 1.5s infinite linear;
}

.bx-fade-down {
  -webkit-animation: fade-down 1.5s infinite linear;
  animation: fade-down 1.5s infinite linear;
}

.bx-fade-down-hover:hover {
  -webkit-animation: fade-down 1.5s infinite linear;
  animation: fade-down 1.5s infinite linear;
}

.bx-fade-left {
  -webkit-animation: fade-left 1.5s infinite linear;
  animation: fade-left 1.5s infinite linear;
}

.bx-fade-left-hover:hover {
  -webkit-animation: fade-left 1.5s infinite linear;
  animation: fade-left 1.5s infinite linear;
}

.bx-fade-right {
  -webkit-animation: fade-right 1.5s infinite linear;
  animation: fade-right 1.5s infinite linear;
}

.bx-fade-right-hover:hover {
  -webkit-animation: fade-right 1.5s infinite linear;
  animation: fade-right 1.5s infinite linear;
}

.bx-xs {
  font-size: 1rem !important;
}

.bx-sm {
  font-size: 1.55rem !important;
}

.bx-md {
  font-size: 2.25rem !important;
}

.bx-lg {
  font-size: 3rem !important;
}

.bx-fw {
  font-size: 1.2857142857em;
  line-height: 0.8em;
  width: 1.2857142857em;
  height: 0.8em;
  margin-top: -0.2em !important;
  vertical-align: middle;
}

.bx-pull-left {
  float: left;
  margin-right: 0.3em !important;
}

.bx-pull-right {
  float: right;
  margin-left: 0.3em !important;
}

.bx-rotate-90 {
  transform: rotate(90deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.bx-rotate-180 {
  transform: rotate(180deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
}

.bx-rotate-270 {
  transform: rotate(270deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.bx-flip-horizontal {
  transform: scaleX(-1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
}

.bx-flip-vertical {
  transform: scaleY(-1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.bx-border {
  padding: 0.25em;
  border: 0.07em solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;
}

.bx-border-circle {
  padding: 0.25em;
  border: 0.07em solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.bxl-meta:before {
  content: "\ef27";
}

.bx-lemon:before {
  content: "\ef28";
}

.bxs-lemon:before {
  content: "\ef29";
}

.bx-cricket-ball:before {
  content: "\ef0c";
}

.bx-baguette:before {
  content: "\ef0d";
}

.bx-bowl-hot:before {
  content: "\ef0e";
}

.bx-bowl-rice:before {
  content: "\ef0f";
}

.bx-cable-car:before {
  content: "\ef10";
}

.bx-candles:before {
  content: "\ef11";
}

.bx-circle-half:before {
  content: "\ef12";
}

.bx-circle-quarter:before {
  content: "\ef13";
}

.bx-circle-three-quarter:before {
  content: "\ef14";
}

.bx-cross:before {
  content: "\ef15";
}

.bx-fork:before {
  content: "\ef16";
}

.bx-knife:before {
  content: "\ef17";
}

.bx-money-withdraw:before {
  content: "\ef18";
}

.bx-popsicle:before {
  content: "\ef19";
}

.bx-scatter-chart:before {
  content: "\ef1a";
}

.bxs-baguette:before {
  content: "\ef1b";
}

.bxs-bowl-hot:before {
  content: "\ef1c";
}

.bxs-bowl-rice:before {
  content: "\ef1d";
}

.bxs-cable-car:before {
  content: "\ef1e";
}

.bxs-circle-half:before {
  content: "\ef1f";
}

.bxs-circle-quarter:before {
  content: "\ef20";
}

.bxs-circle-three-quarter:before {
  content: "\ef21";
}

.bxs-cricket-ball:before {
  content: "\ef22";
}

.bxs-invader:before {
  content: "\ef23";
}

.bx-male-female:before {
  content: "\ef24";
}

.bxs-popsicle:before {
  content: "\ef25";
}

.bxs-tree-alt:before {
  content: "\ef26";
}

.bxl-venmo:before {
  content: "\e900";
}

.bxl-upwork:before {
  content: "\e901";
}

.bxl-netlify:before {
  content: "\e902";
}

.bxl-java:before {
  content: "\e903";
}

.bxl-heroku:before {
  content: "\e904";
}

.bxl-go-lang:before {
  content: "\e905";
}

.bxl-gmail:before {
  content: "\e906";
}

.bxl-flask:before {
  content: "\e907";
}

.bxl-99designs:before {
  content: "\e908";
}

.bxl-500px:before {
  content: "\e909";
}

.bxl-adobe:before {
  content: "\e90a";
}

.bxl-airbnb:before {
  content: "\e90b";
}

.bxl-algolia:before {
  content: "\e90c";
}

.bxl-amazon:before {
  content: "\e90d";
}

.bxl-android:before {
  content: "\e90e";
}

.bxl-angular:before {
  content: "\e90f";
}

.bxl-apple:before {
  content: "\e910";
}

.bxl-audible:before {
  content: "\e911";
}

.bxl-aws:before {
  content: "\e912";
}

.bxl-baidu:before {
  content: "\e913";
}

.bxl-behance:before {
  content: "\e914";
}

.bxl-bing:before {
  content: "\e915";
}

.bxl-bitcoin:before {
  content: "\e916";
}

.bxl-blender:before {
  content: "\e917";
}

.bxl-blogger:before {
  content: "\e918";
}

.bxl-bootstrap:before {
  content: "\e919";
}

.bxl-chrome:before {
  content: "\e91a";
}

.bxl-codepen:before {
  content: "\e91b";
}

.bxl-c-plus-plus:before {
  content: "\e91c";
}

.bxl-creative-commons:before {
  content: "\e91d";
}

.bxl-css3:before {
  content: "\e91e";
}

.bxl-dailymotion:before {
  content: "\e91f";
}

.bxl-deviantart:before {
  content: "\e920";
}

.bxl-dev-to:before {
  content: "\e921";
}

.bxl-digg:before {
  content: "\e922";
}

.bxl-digitalocean:before {
  content: "\e923";
}

.bxl-discord:before {
  content: "\e924";
}

.bxl-discord-alt:before {
  content: "\e925";
}

.bxl-discourse:before {
  content: "\e926";
}

.bxl-django:before {
  content: "\e927";
}

.bxl-docker:before {
  content: "\e928";
}

.bxl-dribbble:before {
  content: "\e929";
}

.bxl-dropbox:before {
  content: "\e92a";
}

.bxl-drupal:before {
  content: "\e92b";
}

.bxl-ebay:before {
  content: "\e92c";
}

.bxl-edge:before {
  content: "\e92d";
}

.bxl-etsy:before {
  content: "\e92e";
}

.bxl-facebook:before {
  content: "\e92f";
}

.bxl-facebook-circle:before {
  content: "\e930";
}

.bxl-facebook-square:before {
  content: "\e931";
}

.bxl-figma:before {
  content: "\e932";
}

.bxl-firebase:before {
  content: "\e933";
}

.bxl-firefox:before {
  content: "\e934";
}

.bxl-flickr:before {
  content: "\e935";
}

.bxl-flickr-square:before {
  content: "\e936";
}

.bxl-flutter:before {
  content: "\e937";
}

.bxl-foursquare:before {
  content: "\e938";
}

.bxl-git:before {
  content: "\e939";
}

.bxl-github:before {
  content: "\e93a";
}

.bxl-gitlab:before {
  content: "\e93b";
}

.bxl-google:before {
  content: "\e93c";
}

.bxl-google-cloud:before {
  content: "\e93d";
}

.bxl-google-plus:before {
  content: "\e93e";
}

.bxl-google-plus-circle:before {
  content: "\e93f";
}

.bxl-html5:before {
  content: "\e940";
}

.bxl-imdb:before {
  content: "\e941";
}

.bxl-instagram:before {
  content: "\e942";
}

.bxl-instagram-alt:before {
  content: "\e943";
}

.bxl-internet-explorer:before {
  content: "\e944";
}

.bxl-invision:before {
  content: "\e945";
}

.bxl-javascript:before {
  content: "\e946";
}

.bxl-joomla:before {
  content: "\e947";
}

.bxl-jquery:before {
  content: "\e948";
}

.bxl-jsfiddle:before {
  content: "\e949";
}

.bxl-kickstarter:before {
  content: "\e94a";
}

.bxl-kubernetes:before {
  content: "\e94b";
}

.bxl-less:before {
  content: "\e94c";
}

.bxl-linkedin:before {
  content: "\e94d";
}

.bxl-linkedin-square:before {
  content: "\e94e";
}

.bxl-magento:before {
  content: "\e94f";
}

.bxl-mailchimp:before {
  content: "\e950";
}

.bxl-markdown:before {
  content: "\e951";
}

.bxl-mastercard:before {
  content: "\e952";
}

.bxl-mastodon:before {
  content: "\e953";
}

.bxl-medium:before {
  content: "\e954";
}

.bxl-medium-old:before {
  content: "\e955";
}

.bxl-medium-square:before {
  content: "\e956";
}

.bxl-messenger:before {
  content: "\e957";
}

.bxl-microsoft:before {
  content: "\e958";
}

.bxl-microsoft-teams:before {
  content: "\e959";
}

.bxl-nodejs:before {
  content: "\e95a";
}

.bxl-ok-ru:before {
  content: "\e95b";
}

.bxl-opera:before {
  content: "\e95c";
}

.bxl-patreon:before {
  content: "\e95d";
}

.bxl-paypal:before {
  content: "\e95e";
}

.bxl-periscope:before {
  content: "\e95f";
}

.bxl-php:before {
  content: "\e960";
}

.bxl-pinterest:before {
  content: "\e961";
}

.bxl-pinterest-alt:before {
  content: "\e962";
}

.bxl-play-store:before {
  content: "\e963";
}

.bxl-pocket:before {
  content: "\e964";
}

.bxl-product-hunt:before {
  content: "\e965";
}

.bxl-python:before {
  content: "\e966";
}

.bxl-quora:before {
  content: "\e967";
}

.bxl-react:before {
  content: "\e968";
}

.bxl-redbubble:before {
  content: "\e969";
}

.bxl-reddit:before {
  content: "\e96a";
}

.bxl-redux:before {
  content: "\e96b";
}

.bxl-sass:before {
  content: "\e96c";
}

.bxl-shopify:before {
  content: "\e96d";
}

.bxl-sketch:before {
  content: "\e96e";
}

.bxl-skype:before {
  content: "\e96f";
}

.bxl-slack:before {
  content: "\e970";
}

.bxl-slack-old:before {
  content: "\e971";
}

.bxl-snapchat:before {
  content: "\e972";
}

.bxl-soundcloud:before {
  content: "\e973";
}

.bxl-spotify:before {
  content: "\e974";
}

.bxl-spring-boot:before {
  content: "\e975";
}

.bxl-squarespace:before {
  content: "\e976";
}

.bxl-stack-overflow:before {
  content: "\e977";
}

.bxl-steam:before {
  content: "\e978";
}

.bxl-stripe:before {
  content: "\e979";
}

.bxl-tailwind-css:before {
  content: "\e97a";
}

.bxl-telegram:before {
  content: "\e97b";
}

.bxl-tiktok:before {
  content: "\e97c";
}

.bxl-trello:before {
  content: "\e97d";
}

.bxl-trip-advisor:before {
  content: "\e97e";
}

.bxl-tumblr:before {
  content: "\e97f";
}

.bxl-tux:before {
  content: "\e980";
}

.bxl-twitch:before {
  content: "\e981";
}

.bxl-twitter:before {
  content: "\e982";
}

.bxl-unity:before {
  content: "\e983";
}

.bxl-unsplash:before {
  content: "\e984";
}

.bxl-vimeo:before {
  content: "\e985";
}

.bxl-visa:before {
  content: "\e986";
}

.bxl-visual-studio:before {
  content: "\e987";
}

.bxl-vk:before {
  content: "\e988";
}

.bxl-vuejs:before {
  content: "\e989";
}

.bxl-whatsapp:before {
  content: "\e98a";
}

.bxl-whatsapp-square:before {
  content: "\e98b";
}

.bxl-wikipedia:before {
  content: "\e98c";
}

.bxl-windows:before {
  content: "\e98d";
}

.bxl-wix:before {
  content: "\e98e";
}

.bxl-wordpress:before {
  content: "\e98f";
}

.bxl-yahoo:before {
  content: "\e990";
}

.bxl-yelp:before {
  content: "\e991";
}

.bxl-youtube:before {
  content: "\e992";
}

.bxl-zoom:before {
  content: "\e993";
}

.bx-collapse-alt:before {
  content: "\e994";
}

.bx-collapse-horizontal:before {
  content: "\e995";
}

.bx-collapse-vertical:before {
  content: "\e996";
}

.bx-expand-horizontal:before {
  content: "\e997";
}

.bx-expand-vertical:before {
  content: "\e998";
}

.bx-injection:before {
  content: "\e999";
}

.bx-leaf:before {
  content: "\e99a";
}

.bx-math:before {
  content: "\e99b";
}

.bx-party:before {
  content: "\e99c";
}

.bx-abacus:before {
  content: "\e99d";
}

.bx-accessibility:before {
  content: "\e99e";
}

.bx-add-to-queue:before {
  content: "\e99f";
}

.bx-adjust:before {
  content: "\e9a0";
}

.bx-alarm:before {
  content: "\e9a1";
}

.bx-alarm-add:before {
  content: "\e9a2";
}

.bx-alarm-exclamation:before {
  content: "\e9a3";
}

.bx-alarm-off:before {
  content: "\e9a4";
}

.bx-alarm-snooze:before {
  content: "\e9a5";
}

.bx-album:before {
  content: "\e9a6";
}

.bx-align-justify:before {
  content: "\e9a7";
}

.bx-align-left:before {
  content: "\e9a8";
}

.bx-align-middle:before {
  content: "\e9a9";
}

.bx-align-right:before {
  content: "\e9aa";
}

.bx-analyse:before {
  content: "\e9ab";
}

.bx-anchor:before {
  content: "\e9ac";
}

.bx-angry:before {
  content: "\e9ad";
}

.bx-aperture:before {
  content: "\e9ae";
}

.bx-arch:before {
  content: "\e9af";
}

.bx-archive:before {
  content: "\e9b0";
}

.bx-archive-in:before {
  content: "\e9b1";
}

.bx-archive-out:before {
  content: "\e9b2";
}

.bx-area:before {
  content: "\e9b3";
}

.bx-arrow-back:before {
  content: "\e9b4";
}

.bx-arrow-from-bottom:before {
  content: "\e9b5";
}

.bx-arrow-from-left:before {
  content: "\e9b6";
}

.bx-arrow-from-right:before {
  content: "\e9b7";
}

.bx-arrow-from-top:before {
  content: "\e9b8";
}

.bx-arrow-to-bottom:before {
  content: "\e9b9";
}

.bx-arrow-to-left:before {
  content: "\e9ba";
}

.bx-arrow-to-right:before {
  content: "\e9bb";
}

.bx-arrow-to-top:before {
  content: "\e9bc";
}

.bx-at:before {
  content: "\e9bd";
}

.bx-atom:before {
  content: "\e9be";
}

.bx-award:before {
  content: "\e9bf";
}

.bx-badge:before {
  content: "\e9c0";
}

.bx-badge-check:before {
  content: "\e9c1";
}

.bx-ball:before {
  content: "\e9c2";
}

.bx-band-aid:before {
  content: "\e9c3";
}

.bx-bar-chart:before {
  content: "\e9c4";
}

.bx-bar-chart-alt:before {
  content: "\e9c5";
}

.bx-bar-chart-alt-2:before {
  content: "\e9c6";
}

.bx-bar-chart-square:before {
  content: "\e9c7";
}

.bx-barcode:before {
  content: "\e9c8";
}

.bx-barcode-reader:before {
  content: "\e9c9";
}

.bx-baseball:before {
  content: "\e9ca";
}

.bx-basket:before {
  content: "\e9cb";
}

.bx-basketball:before {
  content: "\e9cc";
}

.bx-bath:before {
  content: "\e9cd";
}

.bx-battery:before {
  content: "\e9ce";
}

.bx-bed:before {
  content: "\e9cf";
}

.bx-been-here:before {
  content: "\e9d0";
}

.bx-beer:before {
  content: "\e9d1";
}

.bx-bell:before {
  content: "\e9d2";
}

.bx-bell-minus:before {
  content: "\e9d3";
}

.bx-bell-off:before {
  content: "\e9d4";
}

.bx-bell-plus:before {
  content: "\e9d5";
}

.bx-bible:before {
  content: "\e9d6";
}

.bx-bitcoin:before {
  content: "\e9d7";
}

.bx-blanket:before {
  content: "\e9d8";
}

.bx-block:before {
  content: "\e9d9";
}

.bx-bluetooth:before {
  content: "\e9da";
}

.bx-body:before {
  content: "\e9db";
}

.bx-bold:before {
  content: "\e9dc";
}

.bx-bolt-circle:before {
  content: "\e9dd";
}

.bx-bomb:before {
  content: "\e9de";
}

.bx-bone:before {
  content: "\e9df";
}

.bx-bong:before {
  content: "\e9e0";
}

.bx-book:before {
  content: "\e9e1";
}

.bx-book-add:before {
  content: "\e9e2";
}

.bx-book-alt:before {
  content: "\e9e3";
}

.bx-book-bookmark:before {
  content: "\e9e4";
}

.bx-book-content:before {
  content: "\e9e5";
}

.bx-book-heart:before {
  content: "\e9e6";
}

.bx-bookmark:before {
  content: "\e9e7";
}

.bx-bookmark-alt:before {
  content: "\e9e8";
}

.bx-bookmark-alt-minus:before {
  content: "\e9e9";
}

.bx-bookmark-alt-plus:before {
  content: "\e9ea";
}

.bx-bookmark-heart:before {
  content: "\e9eb";
}

.bx-bookmark-minus:before {
  content: "\e9ec";
}

.bx-bookmark-plus:before {
  content: "\e9ed";
}

.bx-bookmarks:before {
  content: "\e9ee";
}

.bx-book-open:before {
  content: "\e9ef";
}

.bx-book-reader:before {
  content: "\e9f0";
}

.bx-border-all:before {
  content: "\e9f1";
}

.bx-border-bottom:before {
  content: "\e9f2";
}

.bx-border-inner:before {
  content: "\e9f3";
}

.bx-border-left:before {
  content: "\e9f4";
}

.bx-border-none:before {
  content: "\e9f5";
}

.bx-border-outer:before {
  content: "\e9f6";
}

.bx-border-radius:before {
  content: "\e9f7";
}

.bx-border-right:before {
  content: "\e9f8";
}

.bx-border-top:before {
  content: "\e9f9";
}

.bx-bot:before {
  content: "\e9fa";
}

.bx-bowling-ball:before {
  content: "\e9fb";
}

.bx-box:before {
  content: "\e9fc";
}

.bx-bracket:before {
  content: "\e9fd";
}

.bx-braille:before {
  content: "\e9fe";
}

.bx-brain:before {
  content: "\e9ff";
}

.bx-briefcase:before {
  content: "\ea00";
}

.bx-briefcase-alt:before {
  content: "\ea01";
}

.bx-briefcase-alt-2:before {
  content: "\ea02";
}

.bx-brightness:before {
  content: "\ea03";
}

.bx-brightness-half:before {
  content: "\ea04";
}

.bx-broadcast:before {
  content: "\ea05";
}

.bx-brush:before {
  content: "\ea06";
}

.bx-brush-alt:before {
  content: "\ea07";
}

.bx-bug:before {
  content: "\ea08";
}

.bx-bug-alt:before {
  content: "\ea09";
}

.bx-building:before {
  content: "\ea0a";
}

.bx-building-house:before {
  content: "\ea0b";
}

.bx-buildings:before {
  content: "\ea0c";
}

.bx-bulb:before {
  content: "\ea0d";
}

.bx-bullseye:before {
  content: "\ea0e";
}

.bx-buoy:before {
  content: "\ea0f";
}

.bx-bus:before {
  content: "\ea10";
}

.bx-bus-school:before {
  content: "\ea11";
}

.bx-cabinet:before {
  content: "\ea12";
}

.bx-cake:before {
  content: "\ea13";
}

.bx-calculator:before {
  content: "\ea14";
}

.bx-calendar:before {
  content: "\ea15";
}

.bx-calendar-alt:before {
  content: "\ea16";
}

.bx-calendar-check:before {
  content: "\ea17";
}

.bx-calendar-edit:before {
  content: "\ea18";
}

.bx-calendar-event:before {
  content: "\ea19";
}

.bx-calendar-exclamation:before {
  content: "\ea1a";
}

.bx-calendar-heart:before {
  content: "\ea1b";
}

.bx-calendar-minus:before {
  content: "\ea1c";
}

.bx-calendar-plus:before {
  content: "\ea1d";
}

.bx-calendar-star:before {
  content: "\ea1e";
}

.bx-calendar-week:before {
  content: "\ea1f";
}

.bx-calendar-x:before {
  content: "\ea20";
}

.bx-camera:before {
  content: "\ea21";
}

.bx-camera-home:before {
  content: "\ea22";
}

.bx-camera-movie:before {
  content: "\ea23";
}

.bx-camera-off:before {
  content: "\ea24";
}

.bx-capsule:before {
  content: "\ea25";
}

.bx-captions:before {
  content: "\ea26";
}

.bx-car:before {
  content: "\ea27";
}

.bx-card:before {
  content: "\ea28";
}

.bx-caret-down:before {
  content: "\ea29";
}

.bx-caret-down-circle:before {
  content: "\ea2a";
}

.bx-caret-down-square:before {
  content: "\ea2b";
}

.bx-caret-left:before {
  content: "\ea2c";
}

.bx-caret-left-circle:before {
  content: "\ea2d";
}

.bx-caret-left-square:before {
  content: "\ea2e";
}

.bx-caret-right:before {
  content: "\ea2f";
}

.bx-caret-right-circle:before {
  content: "\ea30";
}

.bx-caret-right-square:before {
  content: "\ea31";
}

.bx-caret-up:before {
  content: "\ea32";
}

.bx-caret-up-circle:before {
  content: "\ea33";
}

.bx-caret-up-square:before {
  content: "\ea34";
}

.bx-carousel:before {
  content: "\ea35";
}

.bx-cart:before {
  content: "\ea36";
}

.bx-cart-alt:before {
  content: "\ea37";
}

.bx-cast:before {
  content: "\ea38";
}

.bx-category:before {
  content: "\ea39";
}

.bx-category-alt:before {
  content: "\ea3a";
}

.bx-cctv:before {
  content: "\ea3b";
}

.bx-certification:before {
  content: "\ea3c";
}

.bx-chair:before {
  content: "\ea3d";
}

.bx-chalkboard:before {
  content: "\ea3e";
}

.bx-chart:before {
  content: "\ea3f";
}

.bx-chat:before {
  content: "\ea40";
}

.bx-check:before {
  content: "\ea41";
}

.bx-checkbox:before {
  content: "\ea42";
}

.bx-checkbox-checked:before {
  content: "\ea43";
}

.bx-checkbox-minus:before {
  content: "\ea44";
}

.bx-checkbox-square:before {
  content: "\ea45";
}

.bx-check-circle:before {
  content: "\ea46";
}

.bx-check-double:before {
  content: "\ea47";
}

.bx-check-shield:before {
  content: "\ea48";
}

.bx-check-square:before {
  content: "\ea49";
}

.bx-chevron-down:before {
  content: "\ea4a";
}

.bx-chevron-down-circle:before {
  content: "\ea4b";
}

.bx-chevron-down-square:before {
  content: "\ea4c";
}

.bx-chevron-left:before {
  content: "\ea4d";
}

.bx-chevron-left-circle:before {
  content: "\ea4e";
}

.bx-chevron-left-square:before {
  content: "\ea4f";
}

.bx-chevron-right:before {
  content: "\ea50";
}

.bx-chevron-right-circle:before {
  content: "\ea51";
}

.bx-chevron-right-square:before {
  content: "\ea52";
}

.bx-chevrons-down:before {
  content: "\ea53";
}

.bx-chevrons-left:before {
  content: "\ea54";
}

.bx-chevrons-right:before {
  content: "\ea55";
}

.bx-chevrons-up:before {
  content: "\ea56";
}

.bx-chevron-up:before {
  content: "\ea57";
}

.bx-chevron-up-circle:before {
  content: "\ea58";
}

.bx-chevron-up-square:before {
  content: "\ea59";
}

.bx-chip:before {
  content: "\ea5a";
}

.bx-church:before {
  content: "\ea5b";
}

.bx-circle:before {
  content: "\ea5c";
}

.bx-clinic:before {
  content: "\ea5d";
}

.bx-clipboard:before {
  content: "\ea5e";
}

.bx-closet:before {
  content: "\ea5f";
}

.bx-cloud:before {
  content: "\ea60";
}

.bx-cloud-download:before {
  content: "\ea61";
}

.bx-cloud-drizzle:before {
  content: "\ea62";
}

.bx-cloud-lightning:before {
  content: "\ea63";
}

.bx-cloud-light-rain:before {
  content: "\ea64";
}

.bx-cloud-rain:before {
  content: "\ea65";
}

.bx-cloud-snow:before {
  content: "\ea66";
}

.bx-cloud-upload:before {
  content: "\ea67";
}

.bx-code:before {
  content: "\ea68";
}

.bx-code-alt:before {
  content: "\ea69";
}

.bx-code-block:before {
  content: "\ea6a";
}

.bx-code-curly:before {
  content: "\ea6b";
}

.bx-coffee:before {
  content: "\ea6c";
}

.bx-coffee-togo:before {
  content: "\ea6d";
}

.bx-cog:before {
  content: "\ea6e";
}

.bx-coin:before {
  content: "\ea6f";
}

.bx-coin-stack:before {
  content: "\ea70";
}

.bx-collapse:before {
  content: "\ea71";
}

.bx-collection:before {
  content: "\ea72";
}

.bx-color-fill:before {
  content: "\ea73";
}

.bx-columns:before {
  content: "\ea74";
}

.bx-command:before {
  content: "\ea75";
}

.bx-comment:before {
  content: "\ea76";
}

.bx-comment-add:before {
  content: "\ea77";
}

.bx-comment-check:before {
  content: "\ea78";
}

.bx-comment-detail:before {
  content: "\ea79";
}

.bx-comment-dots:before {
  content: "\ea7a";
}

.bx-comment-edit:before {
  content: "\ea7b";
}

.bx-comment-error:before {
  content: "\ea7c";
}

.bx-comment-minus:before {
  content: "\ea7d";
}

.bx-comment-x:before {
  content: "\ea7e";
}

.bx-compass:before {
  content: "\ea7f";
}

.bx-confused:before {
  content: "\ea80";
}

.bx-conversation:before {
  content: "\ea81";
}

.bx-cookie:before {
  content: "\ea82";
}

.bx-cool:before {
  content: "\ea83";
}

.bx-copy:before {
  content: "\ea84";
}

.bx-copy-alt:before {
  content: "\ea85";
}

.bx-copyright:before {
  content: "\ea86";
}

.bx-credit-card:before {
  content: "\ea87";
}

.bx-credit-card-alt:before {
  content: "\ea88";
}

.bx-credit-card-front:before {
  content: "\ea89";
}

.bx-crop:before {
  content: "\ea8a";
}

.bx-crosshair:before {
  content: "\ea8b";
}

.bx-crown:before {
  content: "\ea8c";
}

.bx-cube:before {
  content: "\ea8d";
}

.bx-cube-alt:before {
  content: "\ea8e";
}

.bx-cuboid:before {
  content: "\ea8f";
}

.bx-current-location:before {
  content: "\ea90";
}

.bx-customize:before {
  content: "\ea91";
}

.bx-cut:before {
  content: "\ea92";
}

.bx-cycling:before {
  content: "\ea93";
}

.bx-cylinder:before {
  content: "\ea94";
}

.bx-data:before {
  content: "\ea95";
}

.bx-desktop:before {
  content: "\ea96";
}

.bx-detail:before {
  content: "\ea97";
}

.bx-devices:before {
  content: "\ea98";
}

.bx-dialpad:before {
  content: "\ea99";
}

.bx-dialpad-alt:before {
  content: "\ea9a";
}

.bx-diamond:before {
  content: "\ea9b";
}

.bx-dice-1:before {
  content: "\ea9c";
}

.bx-dice-2:before {
  content: "\ea9d";
}

.bx-dice-3:before {
  content: "\ea9e";
}

.bx-dice-4:before {
  content: "\ea9f";
}

.bx-dice-5:before {
  content: "\eaa0";
}

.bx-dice-6:before {
  content: "\eaa1";
}

.bx-directions:before {
  content: "\eaa2";
}

.bx-disc:before {
  content: "\eaa3";
}

.bx-dish:before {
  content: "\eaa4";
}

.bx-dislike:before {
  content: "\eaa5";
}

.bx-dizzy:before {
  content: "\eaa6";
}

.bx-dna:before {
  content: "\eaa7";
}

.bx-dock-bottom:before {
  content: "\eaa8";
}

.bx-dock-left:before {
  content: "\eaa9";
}

.bx-dock-right:before {
  content: "\eaaa";
}

.bx-dock-top:before {
  content: "\eaab";
}

.bx-dollar:before {
  content: "\eaac";
}

.bx-dollar-circle:before {
  content: "\eaad";
}

.bx-donate-blood:before {
  content: "\eaae";
}

.bx-donate-heart:before {
  content: "\eaaf";
}

.bx-door-open:before {
  content: "\eab0";
}

.bx-dots-horizontal:before {
  content: "\eab1";
}

.bx-dots-horizontal-rounded:before {
  content: "\eab2";
}

.bx-dots-vertical:before {
  content: "\eab3";
}

.bx-dots-vertical-rounded:before {
  content: "\eab4";
}

.bx-doughnut-chart:before {
  content: "\eab5";
}

.bx-down-arrow:before {
  content: "\eab6";
}

.bx-down-arrow-alt:before {
  content: "\eab7";
}

.bx-down-arrow-circle:before {
  content: "\eab8";
}

.bx-download:before {
  content: "\eab9";
}

.bx-downvote:before {
  content: "\eaba";
}

.bx-drink:before {
  content: "\eabb";
}

.bx-droplet:before {
  content: "\eabc";
}

.bx-dumbbell:before {
  content: "\eabd";
}

.bx-duplicate:before {
  content: "\eabe";
}

.bx-edit:before {
  content: "\eabf";
}

.bx-edit-alt:before {
  content: "\eac0";
}

.bx-envelope:before {
  content: "\eac1";
}

.bx-envelope-open:before {
  content: "\eac2";
}

.bx-equalizer:before {
  content: "\eac3";
}

.bx-eraser:before {
  content: "\eac4";
}

.bx-error:before {
  content: "\eac5";
}

.bx-error-alt:before {
  content: "\eac6";
}

.bx-error-circle:before {
  content: "\eac7";
}

.bx-euro:before {
  content: "\eac8";
}

.bx-exclude:before {
  content: "\eac9";
}

.bx-exit:before {
  content: "\eaca";
}

.bx-exit-fullscreen:before {
  content: "\eacb";
}

.bx-expand:before {
  content: "\eacc";
}

.bx-expand-alt:before {
  content: "\eacd";
}

.bx-export:before {
  content: "\eace";
}

.bx-extension:before {
  content: "\eacf";
}

.bx-face:before {
  content: "\ead0";
}

.bx-fast-forward:before {
  content: "\ead1";
}

.bx-fast-forward-circle:before {
  content: "\ead2";
}

.bx-female:before {
  content: "\ead3";
}

.bx-female-sign:before {
  content: "\ead4";
}

.bx-file:before {
  content: "\ead5";
}

.bx-file-blank:before {
  content: "\ead6";
}

.bx-file-find:before {
  content: "\ead7";
}

.bx-film:before {
  content: "\ead8";
}

.bx-filter:before {
  content: "\ead9";
}

.bx-filter-alt:before {
  content: "\eada";
}

.bx-fingerprint:before {
  content: "\eadb";
}

.bx-first-aid:before {
  content: "\eadc";
}

.bx-first-page:before {
  content: "\eadd";
}

.bx-flag:before {
  content: "\eade";
}

.bx-folder:before {
  content: "\eadf";
}

.bx-folder-minus:before {
  content: "\eae0";
}

.bx-folder-open:before {
  content: "\eae1";
}

.bx-folder-plus:before {
  content: "\eae2";
}

.bx-font:before {
  content: "\eae3";
}

.bx-font-color:before {
  content: "\eae4";
}

.bx-font-family:before {
  content: "\eae5";
}

.bx-font-size:before {
  content: "\eae6";
}

.bx-food-menu:before {
  content: "\eae7";
}

.bx-food-tag:before {
  content: "\eae8";
}

.bx-football:before {
  content: "\eae9";
}

.bx-fridge:before {
  content: "\eaea";
}

.bx-fullscreen:before {
  content: "\eaeb";
}

.bx-game:before {
  content: "\eaec";
}

.bx-gas-pump:before {
  content: "\eaed";
}

.bx-ghost:before {
  content: "\eaee";
}

.bx-gift:before {
  content: "\eaef";
}

.bx-git-branch:before {
  content: "\eaf0";
}

.bx-git-commit:before {
  content: "\eaf1";
}

.bx-git-compare:before {
  content: "\eaf2";
}

.bx-git-merge:before {
  content: "\eaf3";
}

.bx-git-pull-request:before {
  content: "\eaf4";
}

.bx-git-repo-forked:before {
  content: "\eaf5";
}

.bx-glasses:before {
  content: "\eaf6";
}

.bx-glasses-alt:before {
  content: "\eaf7";
}

.bx-globe:before {
  content: "\eaf8";
}

.bx-globe-alt:before {
  content: "\eaf9";
}

.bx-grid:before {
  content: "\eafa";
}

.bx-grid-alt:before {
  content: "\eafb";
}

.bx-grid-horizontal:before {
  content: "\eafc";
}

.bx-grid-small:before {
  content: "\eafd";
}

.bx-grid-vertical:before {
  content: "\eafe";
}

.bx-group:before {
  content: "\eaff";
}

.bx-handicap:before {
  content: "\eb00";
}

.bx-happy:before {
  content: "\eb01";
}

.bx-happy-alt:before {
  content: "\eb02";
}

.bx-happy-beaming:before {
  content: "\eb03";
}

.bx-happy-heart-eyes:before {
  content: "\eb04";
}

.bx-hash:before {
  content: "\eb05";
}

.bx-hdd:before {
  content: "\eb06";
}

.bx-heading:before {
  content: "\eb07";
}

.bx-headphone:before {
  content: "\eb08";
}

.bx-health:before {
  content: "\eb09";
}

.bx-heart:before {
  content: "\eb0a";
}

.bx-heart-circle:before {
  content: "\eb0b";
}

.bx-heart-square:before {
  content: "\eb0c";
}

.bx-help-circle:before {
  content: "\eb0d";
}

.bx-hide:before {
  content: "\eb0e";
}

.bx-highlight:before {
  content: "\eb0f";
}

.bx-history:before {
  content: "\eb10";
}

.bx-hive:before {
  content: "\eb11";
}

.bx-home:before {
  content: "\eb12";
}

.bx-home-alt:before {
  content: "\eb13";
}

.bx-home-circle:before {
  content: "\eb14";
}

.bx-home-heart:before {
  content: "\eb15";
}

.bx-home-smile:before {
  content: "\eb16";
}

.bx-horizontal-center:before {
  content: "\eb17";
}

.bx-hotel:before {
  content: "\eb18";
}

.bx-hourglass:before {
  content: "\eb19";
}

.bx-id-card:before {
  content: "\eb1a";
}

.bx-image:before {
  content: "\eb1b";
}

.bx-image-add:before {
  content: "\eb1c";
}

.bx-image-alt:before {
  content: "\eb1d";
}

.bx-images:before {
  content: "\eb1e";
}

.bx-import:before {
  content: "\eb1f";
}

.bx-infinite:before {
  content: "\eb20";
}

.bx-info-circle:before {
  content: "\eb21";
}

.bx-info-square:before {
  content: "\eb22";
}

.bx-intersect:before {
  content: "\eb23";
}

.bx-italic:before {
  content: "\eb24";
}

.bx-joystick:before {
  content: "\eb25";
}

.bx-joystick-alt:before {
  content: "\eb26";
}

.bx-joystick-button:before {
  content: "\eb27";
}

.bx-key:before {
  content: "\eb28";
}

.bx-label:before {
  content: "\eb29";
}

.bx-landscape:before {
  content: "\eb2a";
}

.bx-laptop:before {
  content: "\eb2b";
}

.bx-last-page:before {
  content: "\eb2c";
}

.bx-laugh:before {
  content: "\eb2d";
}

.bx-layer:before {
  content: "\eb2e";
}

.bx-layer-minus:before {
  content: "\eb2f";
}

.bx-layer-plus:before {
  content: "\eb30";
}

.bx-layout:before {
  content: "\eb31";
}

.bx-left-arrow:before {
  content: "\eb32";
}

.bx-left-arrow-alt:before {
  content: "\eb33";
}

.bx-left-arrow-circle:before {
  content: "\eb34";
}

.bx-left-down-arrow-circle:before {
  content: "\eb35";
}

.bx-left-indent:before {
  content: "\eb36";
}

.bx-left-top-arrow-circle:before {
  content: "\eb37";
}

.bx-library:before {
  content: "\eb38";
}

.bx-like:before {
  content: "\eb39";
}

.bx-line-chart:before {
  content: "\eb3a";
}

.bx-line-chart-down:before {
  content: "\eb3b";
}

.bx-link:before {
  content: "\eb3c";
}

.bx-link-alt:before {
  content: "\eb3d";
}

.bx-link-external:before {
  content: "\eb3e";
}

.bx-lira:before {
  content: "\eb3f";
}

.bx-list-check:before {
  content: "\eb40";
}

.bx-list-minus:before {
  content: "\eb41";
}

.bx-list-ol:before {
  content: "\eb42";
}

.bx-list-plus:before {
  content: "\eb43";
}

.bx-list-ul:before {
  content: "\eb44";
}

.bx-loader:before {
  content: "\eb45";
}

.bx-loader-alt:before {
  content: "\eb46";
}

.bx-loader-circle:before {
  content: "\eb47";
}

.bx-location-plus:before {
  content: "\eb48";
}

.bx-lock:before {
  content: "\eb49";
}

.bx-lock-alt:before {
  content: "\eb4a";
}

.bx-lock-open:before {
  content: "\eb4b";
}

.bx-lock-open-alt:before {
  content: "\eb4c";
}

.bx-log-in:before {
  content: "\eb4d";
}

.bx-log-in-circle:before {
  content: "\eb4e";
}

.bx-log-out:before {
  content: "\eb4f";
}

.bx-log-out-circle:before {
  content: "\eb50";
}

.bx-low-vision:before {
  content: "\eb51";
}

.bx-magnet:before {
  content: "\eb52";
}

.bx-mail-send:before {
  content: "\eb53";
}

.bx-male:before {
  content: "\eb54";
}

.bx-male-sign:before {
  content: "\eb55";
}

.bx-map:before {
  content: "\eb56";
}

.bx-map-alt:before {
  content: "\eb57";
}

.bx-map-pin:before {
  content: "\eb58";
}

.bx-mask:before {
  content: "\eb59";
}

.bx-medal:before {
  content: "\eb5a";
}

.bx-meh:before {
  content: "\eb5b";
}

.bx-meh-alt:before {
  content: "\eb5c";
}

.bx-meh-blank:before {
  content: "\eb5d";
}

.bx-memory-card:before {
  content: "\eb5e";
}

.bx-menu:before {
  content: "\eb5f";
}

.bx-menu-alt-left:before {
  content: "\eb60";
}

.bx-menu-alt-right:before {
  content: "\eb61";
}

.bx-merge:before {
  content: "\eb62";
}

.bx-message:before {
  content: "\eb63";
}

.bx-message-add:before {
  content: "\eb64";
}

.bx-message-alt:before {
  content: "\eb65";
}

.bx-message-alt-add:before {
  content: "\eb66";
}

.bx-message-alt-check:before {
  content: "\eb67";
}

.bx-message-alt-detail:before {
  content: "\eb68";
}

.bx-message-alt-dots:before {
  content: "\eb69";
}

.bx-message-alt-edit:before {
  content: "\eb6a";
}

.bx-message-alt-error:before {
  content: "\eb6b";
}

.bx-message-alt-minus:before {
  content: "\eb6c";
}

.bx-message-alt-x:before {
  content: "\eb6d";
}

.bx-message-check:before {
  content: "\eb6e";
}

.bx-message-detail:before {
  content: "\eb6f";
}

.bx-message-dots:before {
  content: "\eb70";
}

.bx-message-edit:before {
  content: "\eb71";
}

.bx-message-error:before {
  content: "\eb72";
}

.bx-message-minus:before {
  content: "\eb73";
}

.bx-message-rounded:before {
  content: "\eb74";
}

.bx-message-rounded-add:before {
  content: "\eb75";
}

.bx-message-rounded-check:before {
  content: "\eb76";
}

.bx-message-rounded-detail:before {
  content: "\eb77";
}

.bx-message-rounded-dots:before {
  content: "\eb78";
}

.bx-message-rounded-edit:before {
  content: "\eb79";
}

.bx-message-rounded-error:before {
  content: "\eb7a";
}

.bx-message-rounded-minus:before {
  content: "\eb7b";
}

.bx-message-rounded-x:before {
  content: "\eb7c";
}

.bx-message-square:before {
  content: "\eb7d";
}

.bx-message-square-add:before {
  content: "\eb7e";
}

.bx-message-square-check:before {
  content: "\eb7f";
}

.bx-message-square-detail:before {
  content: "\eb80";
}

.bx-message-square-dots:before {
  content: "\eb81";
}

.bx-message-square-edit:before {
  content: "\eb82";
}

.bx-message-square-error:before {
  content: "\eb83";
}

.bx-message-square-minus:before {
  content: "\eb84";
}

.bx-message-square-x:before {
  content: "\eb85";
}

.bx-message-x:before {
  content: "\eb86";
}

.bx-meteor:before {
  content: "\eb87";
}

.bx-microchip:before {
  content: "\eb88";
}

.bx-microphone:before {
  content: "\eb89";
}

.bx-microphone-off:before {
  content: "\eb8a";
}

.bx-minus:before {
  content: "\eb8b";
}

.bx-minus-back:before {
  content: "\eb8c";
}

.bx-minus-circle:before {
  content: "\eb8d";
}

.bx-minus-front:before {
  content: "\eb8e";
}

.bx-mobile:before {
  content: "\eb8f";
}

.bx-mobile-alt:before {
  content: "\eb90";
}

.bx-mobile-landscape:before {
  content: "\eb91";
}

.bx-mobile-vibration:before {
  content: "\eb92";
}

.bx-money:before {
  content: "\eb93";
}

.bx-moon:before {
  content: "\eb94";
}

.bx-mouse:before {
  content: "\eb95";
}

.bx-mouse-alt:before {
  content: "\eb96";
}

.bx-move:before {
  content: "\eb97";
}

.bx-move-horizontal:before {
  content: "\eb98";
}

.bx-move-vertical:before {
  content: "\eb99";
}

.bx-movie:before {
  content: "\eb9a";
}

.bx-movie-play:before {
  content: "\eb9b";
}

.bx-music:before {
  content: "\eb9c";
}

.bx-navigation:before {
  content: "\eb9d";
}

.bx-network-chart:before {
  content: "\eb9e";
}

.bx-news:before {
  content: "\eb9f";
}

.bx-no-entry:before {
  content: "\eba0";
}

.bx-note:before {
  content: "\eba1";
}

.bx-notepad:before {
  content: "\eba2";
}

.bx-notification:before {
  content: "\eba3";
}

.bx-notification-off:before {
  content: "\eba4";
}

.bx-outline:before {
  content: "\eba5";
}

.bx-package:before {
  content: "\eba6";
}

.bx-paint:before {
  content: "\eba7";
}

.bx-paint-roll:before {
  content: "\eba8";
}

.bx-palette:before {
  content: "\eba9";
}

.bx-paperclip:before {
  content: "\ebaa";
}

.bx-paper-plane:before {
  content: "\ebab";
}

.bx-paragraph:before {
  content: "\ebac";
}

.bx-paste:before {
  content: "\ebad";
}

.bx-pause:before {
  content: "\ebae";
}

.bx-pause-circle:before {
  content: "\ebaf";
}

.bx-pen:before {
  content: "\ebb0";
}

.bx-pencil:before {
  content: "\ebb1";
}

.bx-phone:before {
  content: "\ebb2";
}

.bx-phone-call:before {
  content: "\ebb3";
}

.bx-phone-incoming:before {
  content: "\ebb4";
}

.bx-phone-off:before {
  content: "\ebb5";
}

.bx-phone-outgoing:before {
  content: "\ebb6";
}

.bx-photo-album:before {
  content: "\ebb7";
}

.bx-pie-chart:before {
  content: "\ebb8";
}

.bx-pie-chart-alt:before {
  content: "\ebb9";
}

.bx-pie-chart-alt-2:before {
  content: "\ebba";
}

.bx-pin:before {
  content: "\ebbb";
}

.bx-planet:before {
  content: "\ebbc";
}

.bx-play:before {
  content: "\ebbd";
}

.bx-play-circle:before {
  content: "\ebbe";
}

.bx-plug:before {
  content: "\ebbf";
}

.bx-plus:before {
  content: "\ebc0";
}

.bx-plus-circle:before {
  content: "\ebc1";
}

.bx-plus-medical:before {
  content: "\ebc2";
}

.bx-podcast:before {
  content: "\ebc3";
}

.bx-pointer:before {
  content: "\ebc4";
}

.bx-poll:before {
  content: "\ebc5";
}

.bx-polygon:before {
  content: "\ebc6";
}

.bx-pound:before {
  content: "\ebc7";
}

.bx-power-off:before {
  content: "\ebc8";
}

.bx-printer:before {
  content: "\ebc9";
}

.bx-pulse:before {
  content: "\ebca";
}

.bx-purchase-tag:before {
  content: "\ebcb";
}

.bx-purchase-tag-alt:before {
  content: "\ebcc";
}

.bx-pyramid:before {
  content: "\ebcd";
}

.bx-qr:before {
  content: "\ebce";
}

.bx-qr-scan:before {
  content: "\ebcf";
}

.bx-question-mark:before {
  content: "\ebd0";
}

.bx-radar:before {
  content: "\ebd1";
}

.bx-radio:before {
  content: "\ebd2";
}

.bx-radio-circle:before {
  content: "\ebd3";
}

.bx-radio-circle-marked:before {
  content: "\ebd4";
}

.bx-receipt:before {
  content: "\ebd5";
}

.bx-rectangle:before {
  content: "\ebd6";
}

.bx-recycle:before {
  content: "\ebd7";
}

.bx-redo:before {
  content: "\ebd8";
}

.bx-refresh:before {
  content: "\ebd9";
}

.bx-registered:before {
  content: "\ebda";
}

.bx-rename:before {
  content: "\ebdb";
}

.bx-repeat:before {
  content: "\ebdc";
}

.bx-reply:before {
  content: "\ebdd";
}

.bx-reply-all:before {
  content: "\ebde";
}

.bx-repost:before {
  content: "\ebdf";
}

.bx-reset:before {
  content: "\ebe0";
}

.bx-restaurant:before {
  content: "\ebe1";
}

.bx-revision:before {
  content: "\ebe2";
}

.bx-rewind:before {
  content: "\ebe3";
}

.bx-rewind-circle:before {
  content: "\ebe4";
}

.bx-right-arrow:before {
  content: "\ebe5";
}

.bx-right-arrow-alt:before {
  content: "\ebe6";
}

.bx-right-arrow-circle:before {
  content: "\ebe7";
}

.bx-right-down-arrow-circle:before {
  content: "\ebe8";
}

.bx-right-indent:before {
  content: "\ebe9";
}

.bx-right-top-arrow-circle:before {
  content: "\ebea";
}

.bx-rocket:before {
  content: "\ebeb";
}

.bx-rotate-left:before {
  content: "\ebec";
}

.bx-rotate-right:before {
  content: "\ebed";
}

.bx-rss:before {
  content: "\ebee";
}

.bx-ruble:before {
  content: "\ebef";
}

.bx-ruler:before {
  content: "\ebf0";
}

.bx-run:before {
  content: "\ebf1";
}

.bx-rupee:before {
  content: "\ebf2";
}

.bx-sad:before {
  content: "\ebf3";
}

.bx-save:before {
  content: "\ebf4";
}

.bx-scan:before {
  content: "\ebf5";
}

.bx-screenshot:before {
  content: "\ebf6";
}

.bx-search:before {
  content: "\ebf7";
}

.bx-search-alt:before {
  content: "\ebf8";
}

.bx-search-alt-2:before {
  content: "\ebf9";
}

.bx-selection:before {
  content: "\ebfa";
}

.bx-select-multiple:before {
  content: "\ebfb";
}

.bx-send:before {
  content: "\ebfc";
}

.bx-server:before {
  content: "\ebfd";
}

.bx-shape-circle:before {
  content: "\ebfe";
}

.bx-shape-polygon:before {
  content: "\ebff";
}

.bx-shape-square:before {
  content: "\ec00";
}

.bx-shape-triangle:before {
  content: "\ec01";
}

.bx-share:before {
  content: "\ec02";
}

.bx-share-alt:before {
  content: "\ec03";
}

.bx-shekel:before {
  content: "\ec04";
}

.bx-shield:before {
  content: "\ec05";
}

.bx-shield-alt:before {
  content: "\ec06";
}

.bx-shield-alt-2:before {
  content: "\ec07";
}

.bx-shield-quarter:before {
  content: "\ec08";
}

.bx-shield-x:before {
  content: "\ec09";
}

.bx-shocked:before {
  content: "\ec0a";
}

.bx-shopping-bag:before {
  content: "\ec0b";
}

.bx-show:before {
  content: "\ec0c";
}

.bx-show-alt:before {
  content: "\ec0d";
}

.bx-shuffle:before {
  content: "\ec0e";
}

.bx-sidebar:before {
  content: "\ec0f";
}

.bx-sitemap:before {
  content: "\ec10";
}

.bx-skip-next:before {
  content: "\ec11";
}

.bx-skip-next-circle:before {
  content: "\ec12";
}

.bx-skip-previous:before {
  content: "\ec13";
}

.bx-skip-previous-circle:before {
  content: "\ec14";
}

.bx-sleepy:before {
  content: "\ec15";
}

.bx-slider:before {
  content: "\ec16";
}

.bx-slider-alt:before {
  content: "\ec17";
}

.bx-slideshow:before {
  content: "\ec18";
}

.bx-smile:before {
  content: "\ec19";
}

.bx-sort:before {
  content: "\ec1a";
}

.bx-sort-alt-2:before {
  content: "\ec1b";
}

.bx-sort-a-z:before {
  content: "\ec1c";
}

.bx-sort-down:before {
  content: "\ec1d";
}

.bx-sort-up:before {
  content: "\ec1e";
}

.bx-sort-z-a:before {
  content: "\ec1f";
}

.bx-spa:before {
  content: "\ec20";
}

.bx-space-bar:before {
  content: "\ec21";
}

.bx-speaker:before {
  content: "\ec22";
}

.bx-spray-can:before {
  content: "\ec23";
}

.bx-spreadsheet:before {
  content: "\ec24";
}

.bx-square:before {
  content: "\ec25";
}

.bx-square-rounded:before {
  content: "\ec26";
}

.bx-star:before {
  content: "\ec27";
}

.bx-station:before {
  content: "\ec28";
}

.bx-stats:before {
  content: "\ec29";
}

.bx-sticker:before {
  content: "\ec2a";
}

.bx-stop:before {
  content: "\ec2b";
}

.bx-stop-circle:before {
  content: "\ec2c";
}

.bx-stopwatch:before {
  content: "\ec2d";
}

.bx-store:before {
  content: "\ec2e";
}

.bx-store-alt:before {
  content: "\ec2f";
}

.bx-street-view:before {
  content: "\ec30";
}

.bx-strikethrough:before {
  content: "\ec31";
}

.bx-subdirectory-left:before {
  content: "\ec32";
}

.bx-subdirectory-right:before {
  content: "\ec33";
}

.bx-sun:before {
  content: "\ec34";
}

.bx-support:before {
  content: "\ec35";
}

.bx-swim:before {
  content: "\ec36";
}

.bx-sync:before {
  content: "\ec37";
}

.bx-tab:before {
  content: "\ec38";
}

.bx-table:before {
  content: "\ec39";
}

.bx-tachometer:before {
  content: "\ec3a";
}

.bx-tag:before {
  content: "\ec3b";
}

.bx-tag-alt:before {
  content: "\ec3c";
}

.bx-target-lock:before {
  content: "\ec3d";
}

.bx-task:before {
  content: "\ec3e";
}

.bx-task-x:before {
  content: "\ec3f";
}

.bx-taxi:before {
  content: "\ec40";
}

.bx-tennis-ball:before {
  content: "\ec41";
}

.bx-terminal:before {
  content: "\ec42";
}

.bx-test-tube:before {
  content: "\ec43";
}

.bx-text:before {
  content: "\ec44";
}

.bx-time:before {
  content: "\ec45";
}

.bx-time-five:before {
  content: "\ec46";
}

.bx-timer:before {
  content: "\ec47";
}

.bx-tired:before {
  content: "\ec48";
}

.bx-toggle-left:before {
  content: "\ec49";
}

.bx-toggle-right:before {
  content: "\ec4a";
}

.bx-tone:before {
  content: "\ec4b";
}

.bx-traffic-cone:before {
  content: "\ec4c";
}

.bx-train:before {
  content: "\ec4d";
}

.bx-transfer:before {
  content: "\ec4e";
}

.bx-transfer-alt:before {
  content: "\ec4f";
}

.bx-trash:before {
  content: "\ec50";
}

.bx-trash-alt:before {
  content: "\ec51";
}

.bx-trending-down:before {
  content: "\ec52";
}

.bx-trending-up:before {
  content: "\ec53";
}

.bx-trim:before {
  content: "\ec54";
}

.bx-trip:before {
  content: "\ec55";
}

.bx-trophy:before {
  content: "\ec56";
}

.bx-tv:before {
  content: "\ec57";
}

.bx-underline:before {
  content: "\ec58";
}

.bx-undo:before {
  content: "\ec59";
}

.bx-unite:before {
  content: "\ec5a";
}

.bx-unlink:before {
  content: "\ec5b";
}

.bx-up-arrow:before {
  content: "\ec5c";
}

.bx-up-arrow-alt:before {
  content: "\ec5d";
}

.bx-up-arrow-circle:before {
  content: "\ec5e";
}

.bx-upload:before {
  content: "\ec5f";
}

.bx-upside-down:before {
  content: "\ec60";
}

.bx-upvote:before {
  content: "\ec61";
}

.bx-usb:before {
  content: "\ec62";
}

.bx-user:before {
  content: "\ec63";
}

.bx-user-check:before {
  content: "\ec64";
}

.bx-user-circle:before {
  content: "\ec65";
}

.bx-user-minus:before {
  content: "\ec66";
}

.bx-user-pin:before {
  content: "\ec67";
}

.bx-user-plus:before {
  content: "\ec68";
}

.bx-user-voice:before {
  content: "\ec69";
}

.bx-user-x:before {
  content: "\ec6a";
}

.bx-vector:before {
  content: "\ec6b";
}

.bx-vertical-center:before {
  content: "\ec6c";
}

.bx-vial:before {
  content: "\ec6d";
}

.bx-video:before {
  content: "\ec6e";
}

.bx-video-off:before {
  content: "\ec6f";
}

.bx-video-plus:before {
  content: "\ec70";
}

.bx-video-recording:before {
  content: "\ec71";
}

.bx-voicemail:before {
  content: "\ec72";
}

.bx-volume:before {
  content: "\ec73";
}

.bx-volume-full:before {
  content: "\ec74";
}

.bx-volume-low:before {
  content: "\ec75";
}

.bx-volume-mute:before {
  content: "\ec76";
}

.bx-walk:before {
  content: "\ec77";
}

.bx-wallet:before {
  content: "\ec78";
}

.bx-wallet-alt:before {
  content: "\ec79";
}

.bx-water:before {
  content: "\ec7a";
}

.bx-webcam:before {
  content: "\ec7b";
}

.bx-wifi:before {
  content: "\ec7c";
}

.bx-wifi-0:before {
  content: "\ec7d";
}

.bx-wifi-1:before {
  content: "\ec7e";
}

.bx-wifi-2:before {
  content: "\ec7f";
}

.bx-wifi-off:before {
  content: "\ec80";
}

.bx-wind:before {
  content: "\ec81";
}

.bx-window:before {
  content: "\ec82";
}

.bx-window-alt:before {
  content: "\ec83";
}

.bx-window-close:before {
  content: "\ec84";
}

.bx-window-open:before {
  content: "\ec85";
}

.bx-windows:before {
  content: "\ec86";
}

.bx-wine:before {
  content: "\ec87";
}

.bx-wink-smile:before {
  content: "\ec88";
}

.bx-wink-tongue:before {
  content: "\ec89";
}

.bx-won:before {
  content: "\ec8a";
}

.bx-world:before {
  content: "\ec8b";
}

.bx-wrench:before {
  content: "\ec8c";
}

.bx-x:before {
  content: "\ec8d";
}

.bx-x-circle:before {
  content: "\ec8e";
}

.bx-yen:before {
  content: "\ec8f";
}

.bx-zoom-in:before {
  content: "\ec90";
}

.bx-zoom-out:before {
  content: "\ec91";
}

.bxs-party:before {
  content: "\ec92";
}

.bxs-hot:before {
  content: "\ec93";
}

.bxs-droplet:before {
  content: "\ec94";
}

.bxs-cat:before {
  content: "\ec95";
}

.bxs-dog:before {
  content: "\ec96";
}

.bxs-injection:before {
  content: "\ec97";
}

.bxs-leaf:before {
  content: "\ec98";
}

.bxs-add-to-queue:before {
  content: "\ec99";
}

.bxs-adjust:before {
  content: "\ec9a";
}

.bxs-adjust-alt:before {
  content: "\ec9b";
}

.bxs-alarm:before {
  content: "\ec9c";
}

.bxs-alarm-add:before {
  content: "\ec9d";
}

.bxs-alarm-exclamation:before {
  content: "\ec9e";
}

.bxs-alarm-off:before {
  content: "\ec9f";
}

.bxs-alarm-snooze:before {
  content: "\eca0";
}

.bxs-album:before {
  content: "\eca1";
}

.bxs-ambulance:before {
  content: "\eca2";
}

.bxs-analyse:before {
  content: "\eca3";
}

.bxs-angry:before {
  content: "\eca4";
}

.bxs-arch:before {
  content: "\eca5";
}

.bxs-archive:before {
  content: "\eca6";
}

.bxs-archive-in:before {
  content: "\eca7";
}

.bxs-archive-out:before {
  content: "\eca8";
}

.bxs-area:before {
  content: "\eca9";
}

.bxs-arrow-from-bottom:before {
  content: "\ecaa";
}

.bxs-arrow-from-left:before {
  content: "\ecab";
}

.bxs-arrow-from-right:before {
  content: "\ecac";
}

.bxs-arrow-from-top:before {
  content: "\ecad";
}

.bxs-arrow-to-bottom:before {
  content: "\ecae";
}

.bxs-arrow-to-left:before {
  content: "\ecaf";
}

.bxs-arrow-to-right:before {
  content: "\ecb0";
}

.bxs-arrow-to-top:before {
  content: "\ecb1";
}

.bxs-award:before {
  content: "\ecb2";
}

.bxs-baby-carriage:before {
  content: "\ecb3";
}

.bxs-backpack:before {
  content: "\ecb4";
}

.bxs-badge:before {
  content: "\ecb5";
}

.bxs-badge-check:before {
  content: "\ecb6";
}

.bxs-badge-dollar:before {
  content: "\ecb7";
}

.bxs-ball:before {
  content: "\ecb8";
}

.bxs-band-aid:before {
  content: "\ecb9";
}

.bxs-bank:before {
  content: "\ecba";
}

.bxs-bar-chart-alt-2:before {
  content: "\ecbb";
}

.bxs-bar-chart-square:before {
  content: "\ecbc";
}

.bxs-barcode:before {
  content: "\ecbd";
}

.bxs-baseball:before {
  content: "\ecbe";
}

.bxs-basket:before {
  content: "\ecbf";
}

.bxs-basketball:before {
  content: "\ecc0";
}

.bxs-bath:before {
  content: "\ecc1";
}

.bxs-battery:before {
  content: "\ecc2";
}

.bxs-battery-charging:before {
  content: "\ecc3";
}

.bxs-battery-full:before {
  content: "\ecc4";
}

.bxs-battery-low:before {
  content: "\ecc5";
}

.bxs-bed:before {
  content: "\ecc6";
}

.bxs-been-here:before {
  content: "\ecc7";
}

.bxs-beer:before {
  content: "\ecc8";
}

.bxs-bell:before {
  content: "\ecc9";
}

.bxs-bell-minus:before {
  content: "\ecca";
}

.bxs-bell-off:before {
  content: "\eccb";
}

.bxs-bell-plus:before {
  content: "\eccc";
}

.bxs-bell-ring:before {
  content: "\eccd";
}

.bxs-bible:before {
  content: "\ecce";
}

.bxs-binoculars:before {
  content: "\eccf";
}

.bxs-blanket:before {
  content: "\ecd0";
}

.bxs-bolt:before {
  content: "\ecd1";
}

.bxs-bolt-circle:before {
  content: "\ecd2";
}

.bxs-bomb:before {
  content: "\ecd3";
}

.bxs-bone:before {
  content: "\ecd4";
}

.bxs-bong:before {
  content: "\ecd5";
}

.bxs-book:before {
  content: "\ecd6";
}

.bxs-book-add:before {
  content: "\ecd7";
}

.bxs-book-alt:before {
  content: "\ecd8";
}

.bxs-book-bookmark:before {
  content: "\ecd9";
}

.bxs-book-content:before {
  content: "\ecda";
}

.bxs-book-heart:before {
  content: "\ecdb";
}

.bxs-bookmark:before {
  content: "\ecdc";
}

.bxs-bookmark-alt:before {
  content: "\ecdd";
}

.bxs-bookmark-alt-minus:before {
  content: "\ecde";
}

.bxs-bookmark-alt-plus:before {
  content: "\ecdf";
}

.bxs-bookmark-heart:before {
  content: "\ece0";
}

.bxs-bookmark-minus:before {
  content: "\ece1";
}

.bxs-bookmark-plus:before {
  content: "\ece2";
}

.bxs-bookmarks:before {
  content: "\ece3";
}

.bxs-bookmark-star:before {
  content: "\ece4";
}

.bxs-book-open:before {
  content: "\ece5";
}

.bxs-book-reader:before {
  content: "\ece6";
}

.bxs-bot:before {
  content: "\ece7";
}

.bxs-bowling-ball:before {
  content: "\ece8";
}

.bxs-box:before {
  content: "\ece9";
}

.bxs-brain:before {
  content: "\ecea";
}

.bxs-briefcase:before {
  content: "\eceb";
}

.bxs-briefcase-alt:before {
  content: "\ecec";
}

.bxs-briefcase-alt-2:before {
  content: "\eced";
}

.bxs-brightness:before {
  content: "\ecee";
}

.bxs-brightness-half:before {
  content: "\ecef";
}

.bxs-brush:before {
  content: "\ecf0";
}

.bxs-brush-alt:before {
  content: "\ecf1";
}

.bxs-bug:before {
  content: "\ecf2";
}

.bxs-bug-alt:before {
  content: "\ecf3";
}

.bxs-building:before {
  content: "\ecf4";
}

.bxs-building-house:before {
  content: "\ecf5";
}

.bxs-buildings:before {
  content: "\ecf6";
}

.bxs-bulb:before {
  content: "\ecf7";
}

.bxs-bullseye:before {
  content: "\ecf8";
}

.bxs-buoy:before {
  content: "\ecf9";
}

.bxs-bus:before {
  content: "\ecfa";
}

.bxs-business:before {
  content: "\ecfb";
}

.bxs-bus-school:before {
  content: "\ecfc";
}

.bxs-cabinet:before {
  content: "\ecfd";
}

.bxs-cake:before {
  content: "\ecfe";
}

.bxs-calculator:before {
  content: "\ecff";
}

.bxs-calendar:before {
  content: "\ed00";
}

.bxs-calendar-alt:before {
  content: "\ed01";
}

.bxs-calendar-check:before {
  content: "\ed02";
}

.bxs-calendar-edit:before {
  content: "\ed03";
}

.bxs-calendar-event:before {
  content: "\ed04";
}

.bxs-calendar-exclamation:before {
  content: "\ed05";
}

.bxs-calendar-heart:before {
  content: "\ed06";
}

.bxs-calendar-minus:before {
  content: "\ed07";
}

.bxs-calendar-plus:before {
  content: "\ed08";
}

.bxs-calendar-star:before {
  content: "\ed09";
}

.bxs-calendar-week:before {
  content: "\ed0a";
}

.bxs-calendar-x:before {
  content: "\ed0b";
}

.bxs-camera:before {
  content: "\ed0c";
}

.bxs-camera-home:before {
  content: "\ed0d";
}

.bxs-camera-movie:before {
  content: "\ed0e";
}

.bxs-camera-off:before {
  content: "\ed0f";
}

.bxs-camera-plus:before {
  content: "\ed10";
}

.bxs-capsule:before {
  content: "\ed11";
}

.bxs-captions:before {
  content: "\ed12";
}

.bxs-car:before {
  content: "\ed13";
}

.bxs-car-battery:before {
  content: "\ed14";
}

.bxs-car-crash:before {
  content: "\ed15";
}

.bxs-card:before {
  content: "\ed16";
}

.bxs-caret-down-circle:before {
  content: "\ed17";
}

.bxs-caret-down-square:before {
  content: "\ed18";
}

.bxs-caret-left-circle:before {
  content: "\ed19";
}

.bxs-caret-left-square:before {
  content: "\ed1a";
}

.bxs-caret-right-circle:before {
  content: "\ed1b";
}

.bxs-caret-right-square:before {
  content: "\ed1c";
}

.bxs-caret-up-circle:before {
  content: "\ed1d";
}

.bxs-caret-up-square:before {
  content: "\ed1e";
}

.bxs-car-garage:before {
  content: "\ed1f";
}

.bxs-car-mechanic:before {
  content: "\ed20";
}

.bxs-carousel:before {
  content: "\ed21";
}

.bxs-cart:before {
  content: "\ed22";
}

.bxs-cart-add:before {
  content: "\ed23";
}

.bxs-cart-alt:before {
  content: "\ed24";
}

.bxs-cart-download:before {
  content: "\ed25";
}

.bxs-car-wash:before {
  content: "\ed26";
}

.bxs-category:before {
  content: "\ed27";
}

.bxs-category-alt:before {
  content: "\ed28";
}

.bxs-cctv:before {
  content: "\ed29";
}

.bxs-certification:before {
  content: "\ed2a";
}

.bxs-chalkboard:before {
  content: "\ed2b";
}

.bxs-chart:before {
  content: "\ed2c";
}

.bxs-chat:before {
  content: "\ed2d";
}

.bxs-checkbox:before {
  content: "\ed2e";
}

.bxs-checkbox-checked:before {
  content: "\ed2f";
}

.bxs-checkbox-minus:before {
  content: "\ed30";
}

.bxs-check-circle:before {
  content: "\ed31";
}

.bxs-check-shield:before {
  content: "\ed32";
}

.bxs-check-square:before {
  content: "\ed33";
}

.bxs-chess:before {
  content: "\ed34";
}

.bxs-chevron-down:before {
  content: "\ed35";
}

.bxs-chevron-down-circle:before {
  content: "\ed36";
}

.bxs-chevron-down-square:before {
  content: "\ed37";
}

.bxs-chevron-left:before {
  content: "\ed38";
}

.bxs-chevron-left-circle:before {
  content: "\ed39";
}

.bxs-chevron-left-square:before {
  content: "\ed3a";
}

.bxs-chevron-right:before {
  content: "\ed3b";
}

.bxs-chevron-right-circle:before {
  content: "\ed3c";
}

.bxs-chevron-right-square:before {
  content: "\ed3d";
}

.bxs-chevrons-down:before {
  content: "\ed3e";
}

.bxs-chevrons-left:before {
  content: "\ed3f";
}

.bxs-chevrons-right:before {
  content: "\ed40";
}

.bxs-chevrons-up:before {
  content: "\ed41";
}

.bxs-chevron-up:before {
  content: "\ed42";
}

.bxs-chevron-up-circle:before {
  content: "\ed43";
}

.bxs-chevron-up-square:before {
  content: "\ed44";
}

.bxs-chip:before {
  content: "\ed45";
}

.bxs-church:before {
  content: "\ed46";
}

.bxs-circle:before {
  content: "\ed47";
}

.bxs-city:before {
  content: "\ed48";
}

.bxs-clinic:before {
  content: "\ed49";
}

.bxs-cloud:before {
  content: "\ed4a";
}

.bxs-cloud-download:before {
  content: "\ed4b";
}

.bxs-cloud-lightning:before {
  content: "\ed4c";
}

.bxs-cloud-rain:before {
  content: "\ed4d";
}

.bxs-cloud-upload:before {
  content: "\ed4e";
}

.bxs-coffee:before {
  content: "\ed4f";
}

.bxs-coffee-alt:before {
  content: "\ed50";
}

.bxs-coffee-togo:before {
  content: "\ed51";
}

.bxs-cog:before {
  content: "\ed52";
}

.bxs-coin:before {
  content: "\ed53";
}

.bxs-coin-stack:before {
  content: "\ed54";
}

.bxs-collection:before {
  content: "\ed55";
}

.bxs-color-fill:before {
  content: "\ed56";
}

.bxs-comment:before {
  content: "\ed57";
}

.bxs-comment-add:before {
  content: "\ed58";
}

.bxs-comment-check:before {
  content: "\ed59";
}

.bxs-comment-detail:before {
  content: "\ed5a";
}

.bxs-comment-dots:before {
  content: "\ed5b";
}

.bxs-comment-edit:before {
  content: "\ed5c";
}

.bxs-comment-error:before {
  content: "\ed5d";
}

.bxs-comment-minus:before {
  content: "\ed5e";
}

.bxs-comment-x:before {
  content: "\ed5f";
}

.bxs-compass:before {
  content: "\ed60";
}

.bxs-component:before {
  content: "\ed61";
}

.bxs-confused:before {
  content: "\ed62";
}

.bxs-contact:before {
  content: "\ed63";
}

.bxs-conversation:before {
  content: "\ed64";
}

.bxs-cookie:before {
  content: "\ed65";
}

.bxs-cool:before {
  content: "\ed66";
}

.bxs-copy:before {
  content: "\ed67";
}

.bxs-copy-alt:before {
  content: "\ed68";
}

.bxs-copyright:before {
  content: "\ed69";
}

.bxs-coupon:before {
  content: "\ed6a";
}

.bxs-credit-card:before {
  content: "\ed6b";
}

.bxs-credit-card-alt:before {
  content: "\ed6c";
}

.bxs-credit-card-front:before {
  content: "\ed6d";
}

.bxs-crop:before {
  content: "\ed6e";
}

.bxs-crown:before {
  content: "\ed6f";
}

.bxs-cube:before {
  content: "\ed70";
}

.bxs-cube-alt:before {
  content: "\ed71";
}

.bxs-cuboid:before {
  content: "\ed72";
}

.bxs-customize:before {
  content: "\ed73";
}

.bxs-cylinder:before {
  content: "\ed74";
}

.bxs-dashboard:before {
  content: "\ed75";
}

.bxs-data:before {
  content: "\ed76";
}

.bxs-detail:before {
  content: "\ed77";
}

.bxs-devices:before {
  content: "\ed78";
}

.bxs-diamond:before {
  content: "\ed79";
}

.bxs-dice-1:before {
  content: "\ed7a";
}

.bxs-dice-2:before {
  content: "\ed7b";
}

.bxs-dice-3:before {
  content: "\ed7c";
}

.bxs-dice-4:before {
  content: "\ed7d";
}

.bxs-dice-5:before {
  content: "\ed7e";
}

.bxs-dice-6:before {
  content: "\ed7f";
}

.bxs-direction-left:before {
  content: "\ed80";
}

.bxs-direction-right:before {
  content: "\ed81";
}

.bxs-directions:before {
  content: "\ed82";
}

.bxs-disc:before {
  content: "\ed83";
}

.bxs-discount:before {
  content: "\ed84";
}

.bxs-dish:before {
  content: "\ed85";
}

.bxs-dislike:before {
  content: "\ed86";
}

.bxs-dizzy:before {
  content: "\ed87";
}

.bxs-dock-bottom:before {
  content: "\ed88";
}

.bxs-dock-left:before {
  content: "\ed89";
}

.bxs-dock-right:before {
  content: "\ed8a";
}

.bxs-dock-top:before {
  content: "\ed8b";
}

.bxs-dollar-circle:before {
  content: "\ed8c";
}

.bxs-donate-blood:before {
  content: "\ed8d";
}

.bxs-donate-heart:before {
  content: "\ed8e";
}

.bxs-door-open:before {
  content: "\ed8f";
}

.bxs-doughnut-chart:before {
  content: "\ed90";
}

.bxs-down-arrow:before {
  content: "\ed91";
}

.bxs-down-arrow-alt:before {
  content: "\ed92";
}

.bxs-down-arrow-circle:before {
  content: "\ed93";
}

.bxs-down-arrow-square:before {
  content: "\ed94";
}

.bxs-download:before {
  content: "\ed95";
}

.bxs-downvote:before {
  content: "\ed96";
}

.bxs-drink:before {
  content: "\ed97";
}

.bxs-droplet-half:before {
  content: "\ed98";
}

.bxs-dryer:before {
  content: "\ed99";
}

.bxs-duplicate:before {
  content: "\ed9a";
}

.bxs-edit:before {
  content: "\ed9b";
}

.bxs-edit-alt:before {
  content: "\ed9c";
}

.bxs-edit-location:before {
  content: "\ed9d";
}

.bxs-eject:before {
  content: "\ed9e";
}

.bxs-envelope:before {
  content: "\ed9f";
}

.bxs-envelope-open:before {
  content: "\eda0";
}

.bxs-eraser:before {
  content: "\eda1";
}

.bxs-error:before {
  content: "\eda2";
}

.bxs-error-alt:before {
  content: "\eda3";
}

.bxs-error-circle:before {
  content: "\eda4";
}

.bxs-ev-station:before {
  content: "\eda5";
}

.bxs-exit:before {
  content: "\eda6";
}

.bxs-extension:before {
  content: "\eda7";
}

.bxs-eyedropper:before {
  content: "\eda8";
}

.bxs-face:before {
  content: "\eda9";
}

.bxs-face-mask:before {
  content: "\edaa";
}

.bxs-factory:before {
  content: "\edab";
}

.bxs-fast-forward-circle:before {
  content: "\edac";
}

.bxs-file:before {
  content: "\edad";
}

.bxs-file-archive:before {
  content: "\edae";
}

.bxs-file-blank:before {
  content: "\edaf";
}

.bxs-file-css:before {
  content: "\edb0";
}

.bxs-file-doc:before {
  content: "\edb1";
}

.bxs-file-export:before {
  content: "\edb2";
}

.bxs-file-find:before {
  content: "\edb3";
}

.bxs-file-gif:before {
  content: "\edb4";
}

.bxs-file-html:before {
  content: "\edb5";
}

.bxs-file-image:before {
  content: "\edb6";
}

.bxs-file-import:before {
  content: "\edb7";
}

.bxs-file-jpg:before {
  content: "\edb8";
}

.bxs-file-js:before {
  content: "\edb9";
}

.bxs-file-json:before {
  content: "\edba";
}

.bxs-file-md:before {
  content: "\edbb";
}

.bxs-file-pdf:before {
  content: "\edbc";
}

.bxs-file-plus:before {
  content: "\edbd";
}

.bxs-file-png:before {
  content: "\edbe";
}

.bxs-file-txt:before {
  content: "\edbf";
}

.bxs-film:before {
  content: "\edc0";
}

.bxs-filter-alt:before {
  content: "\edc1";
}

.bxs-first-aid:before {
  content: "\edc2";
}

.bxs-flag:before {
  content: "\edc3";
}

.bxs-flag-alt:before {
  content: "\edc4";
}

.bxs-flag-checkered:before {
  content: "\edc5";
}

.bxs-flame:before {
  content: "\edc6";
}

.bxs-flask:before {
  content: "\edc7";
}

.bxs-florist:before {
  content: "\edc8";
}

.bxs-folder:before {
  content: "\edc9";
}

.bxs-folder-minus:before {
  content: "\edca";
}

.bxs-folder-open:before {
  content: "\edcb";
}

.bxs-folder-plus:before {
  content: "\edcc";
}

.bxs-food-menu:before {
  content: "\edcd";
}

.bxs-fridge:before {
  content: "\edce";
}

.bxs-game:before {
  content: "\edcf";
}

.bxs-gas-pump:before {
  content: "\edd0";
}

.bxs-ghost:before {
  content: "\edd1";
}

.bxs-gift:before {
  content: "\edd2";
}

.bxs-graduation:before {
  content: "\edd3";
}

.bxs-grid:before {
  content: "\edd4";
}

.bxs-grid-alt:before {
  content: "\edd5";
}

.bxs-group:before {
  content: "\edd6";
}

.bxs-guitar-amp:before {
  content: "\edd7";
}

.bxs-hand:before {
  content: "\edd8";
}

.bxs-hand-down:before {
  content: "\edd9";
}

.bxs-hand-left:before {
  content: "\edda";
}

.bxs-hand-right:before {
  content: "\eddb";
}

.bxs-hand-up:before {
  content: "\eddc";
}

.bxs-happy:before {
  content: "\eddd";
}

.bxs-happy-alt:before {
  content: "\edde";
}

.bxs-happy-beaming:before {
  content: "\eddf";
}

.bxs-happy-heart-eyes:before {
  content: "\ede0";
}

.bxs-hdd:before {
  content: "\ede1";
}

.bxs-heart:before {
  content: "\ede2";
}

.bxs-heart-circle:before {
  content: "\ede3";
}

.bxs-heart-square:before {
  content: "\ede4";
}

.bxs-help-circle:before {
  content: "\ede5";
}

.bxs-hide:before {
  content: "\ede6";
}

.bxs-home:before {
  content: "\ede7";
}

.bxs-home-circle:before {
  content: "\ede8";
}

.bxs-home-heart:before {
  content: "\ede9";
}

.bxs-home-smile:before {
  content: "\edea";
}

.bxs-hotel:before {
  content: "\edeb";
}

.bxs-hourglass:before {
  content: "\edec";
}

.bxs-hourglass-bottom:before {
  content: "\eded";
}

.bxs-hourglass-top:before {
  content: "\edee";
}

.bxs-id-card:before {
  content: "\edef";
}

.bxs-image:before {
  content: "\edf0";
}

.bxs-image-add:before {
  content: "\edf1";
}

.bxs-image-alt:before {
  content: "\edf2";
}

.bxs-inbox:before {
  content: "\edf3";
}

.bxs-info-circle:before {
  content: "\edf4";
}

.bxs-info-square:before {
  content: "\edf5";
}

.bxs-institution:before {
  content: "\edf6";
}

.bxs-joystick:before {
  content: "\edf7";
}

.bxs-joystick-alt:before {
  content: "\edf8";
}

.bxs-joystick-button:before {
  content: "\edf9";
}

.bxs-key:before {
  content: "\edfa";
}

.bxs-keyboard:before {
  content: "\edfb";
}

.bxs-label:before {
  content: "\edfc";
}

.bxs-landmark:before {
  content: "\edfd";
}

.bxs-landscape:before {
  content: "\edfe";
}

.bxs-laugh:before {
  content: "\edff";
}

.bxs-layer:before {
  content: "\ee00";
}

.bxs-layer-minus:before {
  content: "\ee01";
}

.bxs-layer-plus:before {
  content: "\ee02";
}

.bxs-layout:before {
  content: "\ee03";
}

.bxs-left-arrow:before {
  content: "\ee04";
}

.bxs-left-arrow-alt:before {
  content: "\ee05";
}

.bxs-left-arrow-circle:before {
  content: "\ee06";
}

.bxs-left-arrow-square:before {
  content: "\ee07";
}

.bxs-left-down-arrow-circle:before {
  content: "\ee08";
}

.bxs-left-top-arrow-circle:before {
  content: "\ee09";
}

.bxs-like:before {
  content: "\ee0a";
}

.bxs-location-plus:before {
  content: "\ee0b";
}

.bxs-lock:before {
  content: "\ee0c";
}

.bxs-lock-alt:before {
  content: "\ee0d";
}

.bxs-lock-open:before {
  content: "\ee0e";
}

.bxs-lock-open-alt:before {
  content: "\ee0f";
}

.bxs-log-in:before {
  content: "\ee10";
}

.bxs-log-in-circle:before {
  content: "\ee11";
}

.bxs-log-out:before {
  content: "\ee12";
}

.bxs-log-out-circle:before {
  content: "\ee13";
}

.bxs-low-vision:before {
  content: "\ee14";
}

.bxs-magic-wand:before {
  content: "\ee15";
}

.bxs-magnet:before {
  content: "\ee16";
}

.bxs-map:before {
  content: "\ee17";
}

.bxs-map-alt:before {
  content: "\ee18";
}

.bxs-map-pin:before {
  content: "\ee19";
}

.bxs-mask:before {
  content: "\ee1a";
}

.bxs-medal:before {
  content: "\ee1b";
}

.bxs-megaphone:before {
  content: "\ee1c";
}

.bxs-meh:before {
  content: "\ee1d";
}

.bxs-meh-alt:before {
  content: "\ee1e";
}

.bxs-meh-blank:before {
  content: "\ee1f";
}

.bxs-memory-card:before {
  content: "\ee20";
}

.bxs-message:before {
  content: "\ee21";
}

.bxs-message-add:before {
  content: "\ee22";
}

.bxs-message-alt:before {
  content: "\ee23";
}

.bxs-message-alt-add:before {
  content: "\ee24";
}

.bxs-message-alt-check:before {
  content: "\ee25";
}

.bxs-message-alt-detail:before {
  content: "\ee26";
}

.bxs-message-alt-dots:before {
  content: "\ee27";
}

.bxs-message-alt-edit:before {
  content: "\ee28";
}

.bxs-message-alt-error:before {
  content: "\ee29";
}

.bxs-message-alt-minus:before {
  content: "\ee2a";
}

.bxs-message-alt-x:before {
  content: "\ee2b";
}

.bxs-message-check:before {
  content: "\ee2c";
}

.bxs-message-detail:before {
  content: "\ee2d";
}

.bxs-message-dots:before {
  content: "\ee2e";
}

.bxs-message-edit:before {
  content: "\ee2f";
}

.bxs-message-error:before {
  content: "\ee30";
}

.bxs-message-minus:before {
  content: "\ee31";
}

.bxs-message-rounded:before {
  content: "\ee32";
}

.bxs-message-rounded-add:before {
  content: "\ee33";
}

.bxs-message-rounded-check:before {
  content: "\ee34";
}

.bxs-message-rounded-detail:before {
  content: "\ee35";
}

.bxs-message-rounded-dots:before {
  content: "\ee36";
}

.bxs-message-rounded-edit:before {
  content: "\ee37";
}

.bxs-message-rounded-error:before {
  content: "\ee38";
}

.bxs-message-rounded-minus:before {
  content: "\ee39";
}

.bxs-message-rounded-x:before {
  content: "\ee3a";
}

.bxs-message-square:before {
  content: "\ee3b";
}

.bxs-message-square-add:before {
  content: "\ee3c";
}

.bxs-message-square-check:before {
  content: "\ee3d";
}

.bxs-message-square-detail:before {
  content: "\ee3e";
}

.bxs-message-square-dots:before {
  content: "\ee3f";
}

.bxs-message-square-edit:before {
  content: "\ee40";
}

.bxs-message-square-error:before {
  content: "\ee41";
}

.bxs-message-square-minus:before {
  content: "\ee42";
}

.bxs-message-square-x:before {
  content: "\ee43";
}

.bxs-message-x:before {
  content: "\ee44";
}

.bxs-meteor:before {
  content: "\ee45";
}

.bxs-microchip:before {
  content: "\ee46";
}

.bxs-microphone:before {
  content: "\ee47";
}

.bxs-microphone-alt:before {
  content: "\ee48";
}

.bxs-microphone-off:before {
  content: "\ee49";
}

.bxs-minus-circle:before {
  content: "\ee4a";
}

.bxs-minus-square:before {
  content: "\ee4b";
}

.bxs-mobile:before {
  content: "\ee4c";
}

.bxs-mobile-vibration:before {
  content: "\ee4d";
}

.bxs-moon:before {
  content: "\ee4e";
}

.bxs-mouse:before {
  content: "\ee4f";
}

.bxs-mouse-alt:before {
  content: "\ee50";
}

.bxs-movie:before {
  content: "\ee51";
}

.bxs-movie-play:before {
  content: "\ee52";
}

.bxs-music:before {
  content: "\ee53";
}

.bxs-navigation:before {
  content: "\ee54";
}

.bxs-network-chart:before {
  content: "\ee55";
}

.bxs-news:before {
  content: "\ee56";
}

.bxs-no-entry:before {
  content: "\ee57";
}

.bxs-note:before {
  content: "\ee58";
}

.bxs-notepad:before {
  content: "\ee59";
}

.bxs-notification:before {
  content: "\ee5a";
}

.bxs-notification-off:before {
  content: "\ee5b";
}

.bxs-offer:before {
  content: "\ee5c";
}

.bxs-package:before {
  content: "\ee5d";
}

.bxs-paint:before {
  content: "\ee5e";
}

.bxs-paint-roll:before {
  content: "\ee5f";
}

.bxs-palette:before {
  content: "\ee60";
}

.bxs-paper-plane:before {
  content: "\ee61";
}

.bxs-parking:before {
  content: "\ee62";
}

.bxs-paste:before {
  content: "\ee63";
}

.bxs-pen:before {
  content: "\ee64";
}

.bxs-pencil:before {
  content: "\ee65";
}

.bxs-phone:before {
  content: "\ee66";
}

.bxs-phone-call:before {
  content: "\ee67";
}

.bxs-phone-incoming:before {
  content: "\ee68";
}

.bxs-phone-off:before {
  content: "\ee69";
}

.bxs-phone-outgoing:before {
  content: "\ee6a";
}

.bxs-photo-album:before {
  content: "\ee6b";
}

.bxs-piano:before {
  content: "\ee6c";
}

.bxs-pie-chart:before {
  content: "\ee6d";
}

.bxs-pie-chart-alt:before {
  content: "\ee6e";
}

.bxs-pie-chart-alt-2:before {
  content: "\ee6f";
}

.bxs-pin:before {
  content: "\ee70";
}

.bxs-pizza:before {
  content: "\ee71";
}

.bxs-plane:before {
  content: "\ee72";
}

.bxs-plane-alt:before {
  content: "\ee73";
}

.bxs-plane-land:before {
  content: "\ee74";
}

.bxs-planet:before {
  content: "\ee75";
}

.bxs-plane-take-off:before {
  content: "\ee76";
}

.bxs-playlist:before {
  content: "\ee77";
}

.bxs-plug:before {
  content: "\ee78";
}

.bxs-plus-circle:before {
  content: "\ee79";
}

.bxs-plus-square:before {
  content: "\ee7a";
}

.bxs-pointer:before {
  content: "\ee7b";
}

.bxs-polygon:before {
  content: "\ee7c";
}

.bxs-printer:before {
  content: "\ee7d";
}

.bxs-purchase-tag:before {
  content: "\ee7e";
}

.bxs-purchase-tag-alt:before {
  content: "\ee7f";
}

.bxs-pyramid:before {
  content: "\ee80";
}

.bxs-quote-alt-left:before {
  content: "\ee81";
}

.bxs-quote-alt-right:before {
  content: "\ee82";
}

.bxs-quote-left:before {
  content: "\ee83";
}

.bxs-quote-right:before {
  content: "\ee84";
}

.bxs-quote-single-left:before {
  content: "\ee85";
}

.bxs-quote-single-right:before {
  content: "\ee86";
}

.bxs-radiation:before {
  content: "\ee87";
}

.bxs-radio:before {
  content: "\ee88";
}

.bxs-receipt:before {
  content: "\ee89";
}

.bxs-rectangle:before {
  content: "\ee8a";
}

.bxs-registered:before {
  content: "\ee8b";
}

.bxs-rename:before {
  content: "\ee8c";
}

.bxs-report:before {
  content: "\ee8d";
}

.bxs-rewind-circle:before {
  content: "\ee8e";
}

.bxs-right-arrow:before {
  content: "\ee8f";
}

.bxs-right-arrow-alt:before {
  content: "\ee90";
}

.bxs-right-arrow-circle:before {
  content: "\ee91";
}

.bxs-right-arrow-square:before {
  content: "\ee92";
}

.bxs-right-down-arrow-circle:before {
  content: "\ee93";
}

.bxs-right-top-arrow-circle:before {
  content: "\ee94";
}

.bxs-rocket:before {
  content: "\ee95";
}

.bxs-ruler:before {
  content: "\ee96";
}

.bxs-sad:before {
  content: "\ee97";
}

.bxs-save:before {
  content: "\ee98";
}

.bxs-school:before {
  content: "\ee99";
}

.bxs-search:before {
  content: "\ee9a";
}

.bxs-search-alt-2:before {
  content: "\ee9b";
}

.bxs-select-multiple:before {
  content: "\ee9c";
}

.bxs-send:before {
  content: "\ee9d";
}

.bxs-server:before {
  content: "\ee9e";
}

.bxs-shapes:before {
  content: "\ee9f";
}

.bxs-share:before {
  content: "\eea0";
}

.bxs-share-alt:before {
  content: "\eea1";
}

.bxs-shield:before {
  content: "\eea2";
}

.bxs-shield-alt-2:before {
  content: "\eea3";
}

.bxs-shield-x:before {
  content: "\eea4";
}

.bxs-ship:before {
  content: "\eea5";
}

.bxs-shocked:before {
  content: "\eea6";
}

.bxs-shopping-bag:before {
  content: "\eea7";
}

.bxs-shopping-bag-alt:before {
  content: "\eea8";
}

.bxs-shopping-bags:before {
  content: "\eea9";
}

.bxs-show:before {
  content: "\eeaa";
}

.bxs-skip-next-circle:before {
  content: "\eeab";
}

.bxs-skip-previous-circle:before {
  content: "\eeac";
}

.bxs-skull:before {
  content: "\eead";
}

.bxs-sleepy:before {
  content: "\eeae";
}

.bxs-slideshow:before {
  content: "\eeaf";
}

.bxs-smile:before {
  content: "\eeb0";
}

.bxs-sort-alt:before {
  content: "\eeb1";
}

.bxs-spa:before {
  content: "\eeb2";
}

.bxs-speaker:before {
  content: "\eeb3";
}

.bxs-spray-can:before {
  content: "\eeb4";
}

.bxs-spreadsheet:before {
  content: "\eeb5";
}

.bxs-square:before {
  content: "\eeb6";
}

.bxs-square-rounded:before {
  content: "\eeb7";
}

.bxs-star:before {
  content: "\eeb8";
}

.bxs-star-half:before {
  content: "\eeb9";
}

.bxs-sticker:before {
  content: "\eeba";
}

.bxs-stopwatch:before {
  content: "\eebb";
}

.bxs-store:before {
  content: "\eebc";
}

.bxs-store-alt:before {
  content: "\eebd";
}

.bxs-sun:before {
  content: "\eebe";
}

.bxs-tachometer:before {
  content: "\eebf";
}

.bxs-tag:before {
  content: "\eec0";
}

.bxs-tag-alt:before {
  content: "\eec1";
}

.bxs-tag-x:before {
  content: "\eec2";
}

.bxs-taxi:before {
  content: "\eec3";
}

.bxs-tennis-ball:before {
  content: "\eec4";
}

.bxs-terminal:before {
  content: "\eec5";
}

.bxs-thermometer:before {
  content: "\eec6";
}

.bxs-time:before {
  content: "\eec7";
}

.bxs-time-five:before {
  content: "\eec8";
}

.bxs-timer:before {
  content: "\eec9";
}

.bxs-tired:before {
  content: "\eeca";
}

.bxs-toggle-left:before {
  content: "\eecb";
}

.bxs-toggle-right:before {
  content: "\eecc";
}

.bxs-tone:before {
  content: "\eecd";
}

.bxs-torch:before {
  content: "\eece";
}

.bxs-to-top:before {
  content: "\eecf";
}

.bxs-traffic:before {
  content: "\eed0";
}

.bxs-traffic-barrier:before {
  content: "\eed1";
}

.bxs-traffic-cone:before {
  content: "\eed2";
}

.bxs-train:before {
  content: "\eed3";
}

.bxs-trash:before {
  content: "\eed4";
}

.bxs-trash-alt:before {
  content: "\eed5";
}

.bxs-tree:before {
  content: "\eed6";
}

.bxs-trophy:before {
  content: "\eed7";
}

.bxs-truck:before {
  content: "\eed8";
}

.bxs-t-shirt:before {
  content: "\eed9";
}

.bxs-tv:before {
  content: "\eeda";
}

.bxs-up-arrow:before {
  content: "\eedb";
}

.bxs-up-arrow-alt:before {
  content: "\eedc";
}

.bxs-up-arrow-circle:before {
  content: "\eedd";
}

.bxs-up-arrow-square:before {
  content: "\eede";
}

.bxs-upside-down:before {
  content: "\eedf";
}

.bxs-upvote:before {
  content: "\eee0";
}

.bxs-user:before {
  content: "\eee1";
}

.bxs-user-account:before {
  content: "\eee2";
}

.bxs-user-badge:before {
  content: "\eee3";
}

.bxs-user-check:before {
  content: "\eee4";
}

.bxs-user-circle:before {
  content: "\eee5";
}

.bxs-user-detail:before {
  content: "\eee6";
}

.bxs-user-minus:before {
  content: "\eee7";
}

.bxs-user-pin:before {
  content: "\eee8";
}

.bxs-user-plus:before {
  content: "\eee9";
}

.bxs-user-rectangle:before {
  content: "\eeea";
}

.bxs-user-voice:before {
  content: "\eeeb";
}

.bxs-user-x:before {
  content: "\eeec";
}

.bxs-vector:before {
  content: "\eeed";
}

.bxs-vial:before {
  content: "\eeee";
}

.bxs-video:before {
  content: "\eeef";
}

.bxs-video-off:before {
  content: "\eef0";
}

.bxs-video-plus:before {
  content: "\eef1";
}

.bxs-video-recording:before {
  content: "\eef2";
}

.bxs-videos:before {
  content: "\eef3";
}

.bxs-virus:before {
  content: "\eef4";
}

.bxs-virus-block:before {
  content: "\eef5";
}

.bxs-volume:before {
  content: "\eef6";
}

.bxs-volume-full:before {
  content: "\eef7";
}

.bxs-volume-low:before {
  content: "\eef8";
}

.bxs-volume-mute:before {
  content: "\eef9";
}

.bxs-wallet:before {
  content: "\eefa";
}

.bxs-wallet-alt:before {
  content: "\eefb";
}

.bxs-washer:before {
  content: "\eefc";
}

.bxs-watch:before {
  content: "\eefd";
}

.bxs-watch-alt:before {
  content: "\eefe";
}

.bxs-webcam:before {
  content: "\eeff";
}

.bxs-widget:before {
  content: "\ef00";
}

.bxs-window-alt:before {
  content: "\ef01";
}

.bxs-wine:before {
  content: "\ef02";
}

.bxs-wink-smile:before {
  content: "\ef03";
}

.bxs-wink-tongue:before {
  content: "\ef04";
}

.bxs-wrench:before {
  content: "\ef05";
}

.bxs-x-circle:before {
  content: "\ef06";
}

.bxs-x-square:before {
  content: "\ef07";
}

.bxs-yin-yang:before {
  content: "\ef08";
}

.bxs-zap:before {
  content: "\ef09";
}

.bxs-zoom-in:before {
  content: "\ef0a";
}

.bxs-zoom-out:before {
  content: "\ef0b";
}

/*
* Remix Icon v2.5.0
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
*
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*
* Date: 2020-05-23
*/
@font-face {
  font-family: "remixicon";
  src: url("../fonts/remixicon.eot?t=1590207869815"); /* IE9*/
  src: url("../fonts/remixicon.eot?t=1590207869815#iefix") format("embedded-opentype"), url("../fonts/remixicon.woff2?t=1590207869815") format("woff2"), url("../fonts/remixicon.woff?t=1590207869815") format("woff"), url("../fonts/remixicon.ttf?t=1590207869815") format("truetype"), url("../fonts/remixicon.svg?t=1590207869815#remixicon") format("svg"); /* iOS 4.1- */
  font-display: swap;
}
[class^=ri-], [class*=" ri-"] {
  font-family: "remixicon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -0.075em;
}

.ri-xxs {
  font-size: 0.5em;
}

.ri-xs {
  font-size: 0.75em;
}

.ri-sm {
  font-size: 0.875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-24-hours-fill:before {
  content: "\ea01";
}

.ri-24-hours-line:before {
  content: "\ea02";
}

.ri-4k-fill:before {
  content: "\ea03";
}

.ri-4k-line:before {
  content: "\ea04";
}

.ri-a-b:before {
  content: "\ea05";
}

.ri-account-box-fill:before {
  content: "\ea06";
}

.ri-account-box-line:before {
  content: "\ea07";
}

.ri-account-circle-fill:before {
  content: "\ea08";
}

.ri-account-circle-line:before {
  content: "\ea09";
}

.ri-account-pin-box-fill:before {
  content: "\ea0a";
}

.ri-account-pin-box-line:before {
  content: "\ea0b";
}

.ri-account-pin-circle-fill:before {
  content: "\ea0c";
}

.ri-account-pin-circle-line:before {
  content: "\ea0d";
}

.ri-add-box-fill:before {
  content: "\ea0e";
}

.ri-add-box-line:before {
  content: "\ea0f";
}

.ri-add-circle-fill:before {
  content: "\ea10";
}

.ri-add-circle-line:before {
  content: "\ea11";
}

.ri-add-fill:before {
  content: "\ea12";
}

.ri-add-line:before {
  content: "\ea13";
}

.ri-admin-fill:before {
  content: "\ea14";
}

.ri-admin-line:before {
  content: "\ea15";
}

.ri-advertisement-fill:before {
  content: "\ea16";
}

.ri-advertisement-line:before {
  content: "\ea17";
}

.ri-airplay-fill:before {
  content: "\ea18";
}

.ri-airplay-line:before {
  content: "\ea19";
}

.ri-alarm-fill:before {
  content: "\ea1a";
}

.ri-alarm-line:before {
  content: "\ea1b";
}

.ri-alarm-warning-fill:before {
  content: "\ea1c";
}

.ri-alarm-warning-line:before {
  content: "\ea1d";
}

.ri-album-fill:before {
  content: "\ea1e";
}

.ri-album-line:before {
  content: "\ea1f";
}

.ri-alert-fill:before {
  content: "\ea20";
}

.ri-alert-line:before {
  content: "\ea21";
}

.ri-aliens-fill:before {
  content: "\ea22";
}

.ri-aliens-line:before {
  content: "\ea23";
}

.ri-align-bottom:before {
  content: "\ea24";
}

.ri-align-center:before {
  content: "\ea25";
}

.ri-align-justify:before {
  content: "\ea26";
}

.ri-align-left:before {
  content: "\ea27";
}

.ri-align-right:before {
  content: "\ea28";
}

.ri-align-top:before {
  content: "\ea29";
}

.ri-align-vertically:before {
  content: "\ea2a";
}

.ri-alipay-fill:before {
  content: "\ea2b";
}

.ri-alipay-line:before {
  content: "\ea2c";
}

.ri-amazon-fill:before {
  content: "\ea2d";
}

.ri-amazon-line:before {
  content: "\ea2e";
}

.ri-anchor-fill:before {
  content: "\ea2f";
}

.ri-anchor-line:before {
  content: "\ea30";
}

.ri-ancient-gate-fill:before {
  content: "\ea31";
}

.ri-ancient-gate-line:before {
  content: "\ea32";
}

.ri-ancient-pavilion-fill:before {
  content: "\ea33";
}

.ri-ancient-pavilion-line:before {
  content: "\ea34";
}

.ri-android-fill:before {
  content: "\ea35";
}

.ri-android-line:before {
  content: "\ea36";
}

.ri-angularjs-fill:before {
  content: "\ea37";
}

.ri-angularjs-line:before {
  content: "\ea38";
}

.ri-anticlockwise-2-fill:before {
  content: "\ea39";
}

.ri-anticlockwise-2-line:before {
  content: "\ea3a";
}

.ri-anticlockwise-fill:before {
  content: "\ea3b";
}

.ri-anticlockwise-line:before {
  content: "\ea3c";
}

.ri-app-store-fill:before {
  content: "\ea3d";
}

.ri-app-store-line:before {
  content: "\ea3e";
}

.ri-apple-fill:before {
  content: "\ea3f";
}

.ri-apple-line:before {
  content: "\ea40";
}

.ri-apps-2-fill:before {
  content: "\ea41";
}

.ri-apps-2-line:before {
  content: "\ea42";
}

.ri-apps-fill:before {
  content: "\ea43";
}

.ri-apps-line:before {
  content: "\ea44";
}

.ri-archive-drawer-fill:before {
  content: "\ea45";
}

.ri-archive-drawer-line:before {
  content: "\ea46";
}

.ri-archive-fill:before {
  content: "\ea47";
}

.ri-archive-line:before {
  content: "\ea48";
}

.ri-arrow-down-circle-fill:before {
  content: "\ea49";
}

.ri-arrow-down-circle-line:before {
  content: "\ea4a";
}

.ri-arrow-down-fill:before {
  content: "\ea4b";
}

.ri-arrow-down-line:before {
  content: "\ea4c";
}

.ri-arrow-down-s-fill:before {
  content: "\ea4d";
}

.ri-arrow-down-s-line:before {
  content: "\ea4e";
}

.ri-arrow-drop-down-fill:before {
  content: "\ea4f";
}

.ri-arrow-drop-down-line:before {
  content: "\ea50";
}

.ri-arrow-drop-left-fill:before {
  content: "\ea51";
}

.ri-arrow-drop-left-line:before {
  content: "\ea52";
}

.ri-arrow-drop-right-fill:before {
  content: "\ea53";
}

.ri-arrow-drop-right-line:before {
  content: "\ea54";
}

.ri-arrow-drop-up-fill:before {
  content: "\ea55";
}

.ri-arrow-drop-up-line:before {
  content: "\ea56";
}

.ri-arrow-go-back-fill:before {
  content: "\ea57";
}

.ri-arrow-go-back-line:before {
  content: "\ea58";
}

.ri-arrow-go-forward-fill:before {
  content: "\ea59";
}

.ri-arrow-go-forward-line:before {
  content: "\ea5a";
}

.ri-arrow-left-circle-fill:before {
  content: "\ea5b";
}

.ri-arrow-left-circle-line:before {
  content: "\ea5c";
}

.ri-arrow-left-down-fill:before {
  content: "\ea5d";
}

.ri-arrow-left-down-line:before {
  content: "\ea5e";
}

.ri-arrow-left-fill:before {
  content: "\ea5f";
}

.ri-arrow-left-line:before {
  content: "\ea60";
}

.ri-arrow-left-right-fill:before {
  content: "\ea61";
}

.ri-arrow-left-right-line:before {
  content: "\ea62";
}

.ri-arrow-left-s-fill:before {
  content: "\ea63";
}

.ri-arrow-left-s-line:before {
  content: "\ea64";
}

.ri-arrow-left-up-fill:before {
  content: "\ea65";
}

.ri-arrow-left-up-line:before {
  content: "\ea66";
}

.ri-arrow-right-circle-fill:before {
  content: "\ea67";
}

.ri-arrow-right-circle-line:before {
  content: "\ea68";
}

.ri-arrow-right-down-fill:before {
  content: "\ea69";
}

.ri-arrow-right-down-line:before {
  content: "\ea6a";
}

.ri-arrow-right-fill:before {
  content: "\ea6b";
}

.ri-arrow-right-line:before {
  content: "\ea6c";
}

.ri-arrow-right-s-fill:before {
  content: "\ea6d";
}

.ri-arrow-right-s-line:before {
  content: "\ea6e";
}

.ri-arrow-right-up-fill:before {
  content: "\ea6f";
}

.ri-arrow-right-up-line:before {
  content: "\ea70";
}

.ri-arrow-up-circle-fill:before {
  content: "\ea71";
}

.ri-arrow-up-circle-line:before {
  content: "\ea72";
}

.ri-arrow-up-down-fill:before {
  content: "\ea73";
}

.ri-arrow-up-down-line:before {
  content: "\ea74";
}

.ri-arrow-up-fill:before {
  content: "\ea75";
}

.ri-arrow-up-line:before {
  content: "\ea76";
}

.ri-arrow-up-s-fill:before {
  content: "\ea77";
}

.ri-arrow-up-s-line:before {
  content: "\ea78";
}

.ri-artboard-2-fill:before {
  content: "\ea79";
}

.ri-artboard-2-line:before {
  content: "\ea7a";
}

.ri-artboard-fill:before {
  content: "\ea7b";
}

.ri-artboard-line:before {
  content: "\ea7c";
}

.ri-article-fill:before {
  content: "\ea7d";
}

.ri-article-line:before {
  content: "\ea7e";
}

.ri-aspect-ratio-fill:before {
  content: "\ea7f";
}

.ri-aspect-ratio-line:before {
  content: "\ea80";
}

.ri-asterisk:before {
  content: "\ea81";
}

.ri-at-fill:before {
  content: "\ea82";
}

.ri-at-line:before {
  content: "\ea83";
}

.ri-attachment-2:before {
  content: "\ea84";
}

.ri-attachment-fill:before {
  content: "\ea85";
}

.ri-attachment-line:before {
  content: "\ea86";
}

.ri-auction-fill:before {
  content: "\ea87";
}

.ri-auction-line:before {
  content: "\ea88";
}

.ri-award-fill:before {
  content: "\ea89";
}

.ri-award-line:before {
  content: "\ea8a";
}

.ri-baidu-fill:before {
  content: "\ea8b";
}

.ri-baidu-line:before {
  content: "\ea8c";
}

.ri-ball-pen-fill:before {
  content: "\ea8d";
}

.ri-ball-pen-line:before {
  content: "\ea8e";
}

.ri-bank-card-2-fill:before {
  content: "\ea8f";
}

.ri-bank-card-2-line:before {
  content: "\ea90";
}

.ri-bank-card-fill:before {
  content: "\ea91";
}

.ri-bank-card-line:before {
  content: "\ea92";
}

.ri-bank-fill:before {
  content: "\ea93";
}

.ri-bank-line:before {
  content: "\ea94";
}

.ri-bar-chart-2-fill:before {
  content: "\ea95";
}

.ri-bar-chart-2-line:before {
  content: "\ea96";
}

.ri-bar-chart-box-fill:before {
  content: "\ea97";
}

.ri-bar-chart-box-line:before {
  content: "\ea98";
}

.ri-bar-chart-fill:before {
  content: "\ea99";
}

.ri-bar-chart-grouped-fill:before {
  content: "\ea9a";
}

.ri-bar-chart-grouped-line:before {
  content: "\ea9b";
}

.ri-bar-chart-horizontal-fill:before {
  content: "\ea9c";
}

.ri-bar-chart-horizontal-line:before {
  content: "\ea9d";
}

.ri-bar-chart-line:before {
  content: "\ea9e";
}

.ri-barcode-box-fill:before {
  content: "\ea9f";
}

.ri-barcode-box-line:before {
  content: "\eaa0";
}

.ri-barcode-fill:before {
  content: "\eaa1";
}

.ri-barcode-line:before {
  content: "\eaa2";
}

.ri-barricade-fill:before {
  content: "\eaa3";
}

.ri-barricade-line:before {
  content: "\eaa4";
}

.ri-base-station-fill:before {
  content: "\eaa5";
}

.ri-base-station-line:before {
  content: "\eaa6";
}

.ri-basketball-fill:before {
  content: "\eaa7";
}

.ri-basketball-line:before {
  content: "\eaa8";
}

.ri-battery-2-charge-fill:before {
  content: "\eaa9";
}

.ri-battery-2-charge-line:before {
  content: "\eaaa";
}

.ri-battery-2-fill:before {
  content: "\eaab";
}

.ri-battery-2-line:before {
  content: "\eaac";
}

.ri-battery-charge-fill:before {
  content: "\eaad";
}

.ri-battery-charge-line:before {
  content: "\eaae";
}

.ri-battery-fill:before {
  content: "\eaaf";
}

.ri-battery-line:before {
  content: "\eab0";
}

.ri-battery-low-fill:before {
  content: "\eab1";
}

.ri-battery-low-line:before {
  content: "\eab2";
}

.ri-battery-saver-fill:before {
  content: "\eab3";
}

.ri-battery-saver-line:before {
  content: "\eab4";
}

.ri-battery-share-fill:before {
  content: "\eab5";
}

.ri-battery-share-line:before {
  content: "\eab6";
}

.ri-bear-smile-fill:before {
  content: "\eab7";
}

.ri-bear-smile-line:before {
  content: "\eab8";
}

.ri-behance-fill:before {
  content: "\eab9";
}

.ri-behance-line:before {
  content: "\eaba";
}

.ri-bell-fill:before {
  content: "\eabb";
}

.ri-bell-line:before {
  content: "\eabc";
}

.ri-bike-fill:before {
  content: "\eabd";
}

.ri-bike-line:before {
  content: "\eabe";
}

.ri-bilibili-fill:before {
  content: "\eabf";
}

.ri-bilibili-line:before {
  content: "\eac0";
}

.ri-bill-fill:before {
  content: "\eac1";
}

.ri-bill-line:before {
  content: "\eac2";
}

.ri-billiards-fill:before {
  content: "\eac3";
}

.ri-billiards-line:before {
  content: "\eac4";
}

.ri-bit-coin-fill:before {
  content: "\eac5";
}

.ri-bit-coin-line:before {
  content: "\eac6";
}

.ri-blaze-fill:before {
  content: "\eac7";
}

.ri-blaze-line:before {
  content: "\eac8";
}

.ri-bluetooth-connect-fill:before {
  content: "\eac9";
}

.ri-bluetooth-connect-line:before {
  content: "\eaca";
}

.ri-bluetooth-fill:before {
  content: "\eacb";
}

.ri-bluetooth-line:before {
  content: "\eacc";
}

.ri-blur-off-fill:before {
  content: "\eacd";
}

.ri-blur-off-line:before {
  content: "\eace";
}

.ri-body-scan-fill:before {
  content: "\eacf";
}

.ri-body-scan-line:before {
  content: "\ead0";
}

.ri-bold:before {
  content: "\ead1";
}

.ri-book-2-fill:before {
  content: "\ead2";
}

.ri-book-2-line:before {
  content: "\ead3";
}

.ri-book-3-fill:before {
  content: "\ead4";
}

.ri-book-3-line:before {
  content: "\ead5";
}

.ri-book-fill:before {
  content: "\ead6";
}

.ri-book-line:before {
  content: "\ead7";
}

.ri-book-mark-fill:before {
  content: "\ead8";
}

.ri-book-mark-line:before {
  content: "\ead9";
}

.ri-book-open-fill:before {
  content: "\eada";
}

.ri-book-open-line:before {
  content: "\eadb";
}

.ri-book-read-fill:before {
  content: "\eadc";
}

.ri-book-read-line:before {
  content: "\eadd";
}

.ri-booklet-fill:before {
  content: "\eade";
}

.ri-booklet-line:before {
  content: "\eadf";
}

.ri-bookmark-2-fill:before {
  content: "\eae0";
}

.ri-bookmark-2-line:before {
  content: "\eae1";
}

.ri-bookmark-3-fill:before {
  content: "\eae2";
}

.ri-bookmark-3-line:before {
  content: "\eae3";
}

.ri-bookmark-fill:before {
  content: "\eae4";
}

.ri-bookmark-line:before {
  content: "\eae5";
}

.ri-boxing-fill:before {
  content: "\eae6";
}

.ri-boxing-line:before {
  content: "\eae7";
}

.ri-braces-fill:before {
  content: "\eae8";
}

.ri-braces-line:before {
  content: "\eae9";
}

.ri-brackets-fill:before {
  content: "\eaea";
}

.ri-brackets-line:before {
  content: "\eaeb";
}

.ri-briefcase-2-fill:before {
  content: "\eaec";
}

.ri-briefcase-2-line:before {
  content: "\eaed";
}

.ri-briefcase-3-fill:before {
  content: "\eaee";
}

.ri-briefcase-3-line:before {
  content: "\eaef";
}

.ri-briefcase-4-fill:before {
  content: "\eaf0";
}

.ri-briefcase-4-line:before {
  content: "\eaf1";
}

.ri-briefcase-5-fill:before {
  content: "\eaf2";
}

.ri-briefcase-5-line:before {
  content: "\eaf3";
}

.ri-briefcase-fill:before {
  content: "\eaf4";
}

.ri-briefcase-line:before {
  content: "\eaf5";
}

.ri-bring-forward:before {
  content: "\eaf6";
}

.ri-bring-to-front:before {
  content: "\eaf7";
}

.ri-broadcast-fill:before {
  content: "\eaf8";
}

.ri-broadcast-line:before {
  content: "\eaf9";
}

.ri-brush-2-fill:before {
  content: "\eafa";
}

.ri-brush-2-line:before {
  content: "\eafb";
}

.ri-brush-3-fill:before {
  content: "\eafc";
}

.ri-brush-3-line:before {
  content: "\eafd";
}

.ri-brush-4-fill:before {
  content: "\eafe";
}

.ri-brush-4-line:before {
  content: "\eaff";
}

.ri-brush-fill:before {
  content: "\eb00";
}

.ri-brush-line:before {
  content: "\eb01";
}

.ri-bubble-chart-fill:before {
  content: "\eb02";
}

.ri-bubble-chart-line:before {
  content: "\eb03";
}

.ri-bug-2-fill:before {
  content: "\eb04";
}

.ri-bug-2-line:before {
  content: "\eb05";
}

.ri-bug-fill:before {
  content: "\eb06";
}

.ri-bug-line:before {
  content: "\eb07";
}

.ri-building-2-fill:before {
  content: "\eb08";
}

.ri-building-2-line:before {
  content: "\eb09";
}

.ri-building-3-fill:before {
  content: "\eb0a";
}

.ri-building-3-line:before {
  content: "\eb0b";
}

.ri-building-4-fill:before {
  content: "\eb0c";
}

.ri-building-4-line:before {
  content: "\eb0d";
}

.ri-building-fill:before {
  content: "\eb0e";
}

.ri-building-line:before {
  content: "\eb0f";
}

.ri-bus-2-fill:before {
  content: "\eb10";
}

.ri-bus-2-line:before {
  content: "\eb11";
}

.ri-bus-fill:before {
  content: "\eb12";
}

.ri-bus-line:before {
  content: "\eb13";
}

.ri-bus-wifi-fill:before {
  content: "\eb14";
}

.ri-bus-wifi-line:before {
  content: "\eb15";
}

.ri-cactus-fill:before {
  content: "\eb16";
}

.ri-cactus-line:before {
  content: "\eb17";
}

.ri-cake-2-fill:before {
  content: "\eb18";
}

.ri-cake-2-line:before {
  content: "\eb19";
}

.ri-cake-3-fill:before {
  content: "\eb1a";
}

.ri-cake-3-line:before {
  content: "\eb1b";
}

.ri-cake-fill:before {
  content: "\eb1c";
}

.ri-cake-line:before {
  content: "\eb1d";
}

.ri-calculator-fill:before {
  content: "\eb1e";
}

.ri-calculator-line:before {
  content: "\eb1f";
}

.ri-calendar-2-fill:before {
  content: "\eb20";
}

.ri-calendar-2-line:before {
  content: "\eb21";
}

.ri-calendar-check-fill:before {
  content: "\eb22";
}

.ri-calendar-check-line:before {
  content: "\eb23";
}

.ri-calendar-event-fill:before {
  content: "\eb24";
}

.ri-calendar-event-line:before {
  content: "\eb25";
}

.ri-calendar-fill:before {
  content: "\eb26";
}

.ri-calendar-line:before {
  content: "\eb27";
}

.ri-calendar-todo-fill:before {
  content: "\eb28";
}

.ri-calendar-todo-line:before {
  content: "\eb29";
}

.ri-camera-2-fill:before {
  content: "\eb2a";
}

.ri-camera-2-line:before {
  content: "\eb2b";
}

.ri-camera-3-fill:before {
  content: "\eb2c";
}

.ri-camera-3-line:before {
  content: "\eb2d";
}

.ri-camera-fill:before {
  content: "\eb2e";
}

.ri-camera-lens-fill:before {
  content: "\eb2f";
}

.ri-camera-lens-line:before {
  content: "\eb30";
}

.ri-camera-line:before {
  content: "\eb31";
}

.ri-camera-off-fill:before {
  content: "\eb32";
}

.ri-camera-off-line:before {
  content: "\eb33";
}

.ri-camera-switch-fill:before {
  content: "\eb34";
}

.ri-camera-switch-line:before {
  content: "\eb35";
}

.ri-capsule-fill:before {
  content: "\eb36";
}

.ri-capsule-line:before {
  content: "\eb37";
}

.ri-car-fill:before {
  content: "\eb38";
}

.ri-car-line:before {
  content: "\eb39";
}

.ri-car-washing-fill:before {
  content: "\eb3a";
}

.ri-car-washing-line:before {
  content: "\eb3b";
}

.ri-caravan-fill:before {
  content: "\eb3c";
}

.ri-caravan-line:before {
  content: "\eb3d";
}

.ri-cast-fill:before {
  content: "\eb3e";
}

.ri-cast-line:before {
  content: "\eb3f";
}

.ri-cellphone-fill:before {
  content: "\eb40";
}

.ri-cellphone-line:before {
  content: "\eb41";
}

.ri-celsius-fill:before {
  content: "\eb42";
}

.ri-celsius-line:before {
  content: "\eb43";
}

.ri-centos-fill:before {
  content: "\eb44";
}

.ri-centos-line:before {
  content: "\eb45";
}

.ri-character-recognition-fill:before {
  content: "\eb46";
}

.ri-character-recognition-line:before {
  content: "\eb47";
}

.ri-charging-pile-2-fill:before {
  content: "\eb48";
}

.ri-charging-pile-2-line:before {
  content: "\eb49";
}

.ri-charging-pile-fill:before {
  content: "\eb4a";
}

.ri-charging-pile-line:before {
  content: "\eb4b";
}

.ri-chat-1-fill:before {
  content: "\eb4c";
}

.ri-chat-1-line:before {
  content: "\eb4d";
}

.ri-chat-2-fill:before {
  content: "\eb4e";
}

.ri-chat-2-line:before {
  content: "\eb4f";
}

.ri-chat-3-fill:before {
  content: "\eb50";
}

.ri-chat-3-line:before {
  content: "\eb51";
}

.ri-chat-4-fill:before {
  content: "\eb52";
}

.ri-chat-4-line:before {
  content: "\eb53";
}

.ri-chat-check-fill:before {
  content: "\eb54";
}

.ri-chat-check-line:before {
  content: "\eb55";
}

.ri-chat-delete-fill:before {
  content: "\eb56";
}

.ri-chat-delete-line:before {
  content: "\eb57";
}

.ri-chat-download-fill:before {
  content: "\eb58";
}

.ri-chat-download-line:before {
  content: "\eb59";
}

.ri-chat-follow-up-fill:before {
  content: "\eb5a";
}

.ri-chat-follow-up-line:before {
  content: "\eb5b";
}

.ri-chat-forward-fill:before {
  content: "\eb5c";
}

.ri-chat-forward-line:before {
  content: "\eb5d";
}

.ri-chat-heart-fill:before {
  content: "\eb5e";
}

.ri-chat-heart-line:before {
  content: "\eb5f";
}

.ri-chat-history-fill:before {
  content: "\eb60";
}

.ri-chat-history-line:before {
  content: "\eb61";
}

.ri-chat-new-fill:before {
  content: "\eb62";
}

.ri-chat-new-line:before {
  content: "\eb63";
}

.ri-chat-off-fill:before {
  content: "\eb64";
}

.ri-chat-off-line:before {
  content: "\eb65";
}

.ri-chat-poll-fill:before {
  content: "\eb66";
}

.ri-chat-poll-line:before {
  content: "\eb67";
}

.ri-chat-private-fill:before {
  content: "\eb68";
}

.ri-chat-private-line:before {
  content: "\eb69";
}

.ri-chat-quote-fill:before {
  content: "\eb6a";
}

.ri-chat-quote-line:before {
  content: "\eb6b";
}

.ri-chat-settings-fill:before {
  content: "\eb6c";
}

.ri-chat-settings-line:before {
  content: "\eb6d";
}

.ri-chat-smile-2-fill:before {
  content: "\eb6e";
}

.ri-chat-smile-2-line:before {
  content: "\eb6f";
}

.ri-chat-smile-3-fill:before {
  content: "\eb70";
}

.ri-chat-smile-3-line:before {
  content: "\eb71";
}

.ri-chat-smile-fill:before {
  content: "\eb72";
}

.ri-chat-smile-line:before {
  content: "\eb73";
}

.ri-chat-upload-fill:before {
  content: "\eb74";
}

.ri-chat-upload-line:before {
  content: "\eb75";
}

.ri-chat-voice-fill:before {
  content: "\eb76";
}

.ri-chat-voice-line:before {
  content: "\eb77";
}

.ri-check-double-fill:before {
  content: "\eb78";
}

.ri-check-double-line:before {
  content: "\eb79";
}

.ri-check-fill:before {
  content: "\eb7a";
}

.ri-check-line:before {
  content: "\eb7b";
}

.ri-checkbox-blank-circle-fill:before {
  content: "\eb7c";
}

.ri-checkbox-blank-circle-line:before {
  content: "\eb7d";
}

.ri-checkbox-blank-fill:before {
  content: "\eb7e";
}

.ri-checkbox-blank-line:before {
  content: "\eb7f";
}

.ri-checkbox-circle-fill:before {
  content: "\eb80";
}

.ri-checkbox-circle-line:before {
  content: "\eb81";
}

.ri-checkbox-fill:before {
  content: "\eb82";
}

.ri-checkbox-indeterminate-fill:before {
  content: "\eb83";
}

.ri-checkbox-indeterminate-line:before {
  content: "\eb84";
}

.ri-checkbox-line:before {
  content: "\eb85";
}

.ri-checkbox-multiple-blank-fill:before {
  content: "\eb86";
}

.ri-checkbox-multiple-blank-line:before {
  content: "\eb87";
}

.ri-checkbox-multiple-fill:before {
  content: "\eb88";
}

.ri-checkbox-multiple-line:before {
  content: "\eb89";
}

.ri-china-railway-fill:before {
  content: "\eb8a";
}

.ri-china-railway-line:before {
  content: "\eb8b";
}

.ri-chrome-fill:before {
  content: "\eb8c";
}

.ri-chrome-line:before {
  content: "\eb8d";
}

.ri-clapperboard-fill:before {
  content: "\eb8e";
}

.ri-clapperboard-line:before {
  content: "\eb8f";
}

.ri-clipboard-fill:before {
  content: "\eb90";
}

.ri-clipboard-line:before {
  content: "\eb91";
}

.ri-clockwise-2-fill:before {
  content: "\eb92";
}

.ri-clockwise-2-line:before {
  content: "\eb93";
}

.ri-clockwise-fill:before {
  content: "\eb94";
}

.ri-clockwise-line:before {
  content: "\eb95";
}

.ri-close-circle-fill:before {
  content: "\eb96";
}

.ri-close-circle-line:before {
  content: "\eb97";
}

.ri-close-fill:before {
  content: "\eb98";
}

.ri-close-line:before {
  content: "\eb99";
}

.ri-closed-captioning-fill:before {
  content: "\eb9a";
}

.ri-closed-captioning-line:before {
  content: "\eb9b";
}

.ri-cloud-fill:before {
  content: "\eb9c";
}

.ri-cloud-line:before {
  content: "\eb9d";
}

.ri-cloud-off-fill:before {
  content: "\eb9e";
}

.ri-cloud-off-line:before {
  content: "\eb9f";
}

.ri-cloud-windy-fill:before {
  content: "\eba0";
}

.ri-cloud-windy-line:before {
  content: "\eba1";
}

.ri-cloudy-2-fill:before {
  content: "\eba2";
}

.ri-cloudy-2-line:before {
  content: "\eba3";
}

.ri-cloudy-fill:before {
  content: "\eba4";
}

.ri-cloudy-line:before {
  content: "\eba5";
}

.ri-code-box-fill:before {
  content: "\eba6";
}

.ri-code-box-line:before {
  content: "\eba7";
}

.ri-code-fill:before {
  content: "\eba8";
}

.ri-code-line:before {
  content: "\eba9";
}

.ri-code-s-fill:before {
  content: "\ebaa";
}

.ri-code-s-line:before {
  content: "\ebab";
}

.ri-code-s-slash-fill:before {
  content: "\ebac";
}

.ri-code-s-slash-line:before {
  content: "\ebad";
}

.ri-code-view:before {
  content: "\ebae";
}

.ri-codepen-fill:before {
  content: "\ebaf";
}

.ri-codepen-line:before {
  content: "\ebb0";
}

.ri-coin-fill:before {
  content: "\ebb1";
}

.ri-coin-line:before {
  content: "\ebb2";
}

.ri-coins-fill:before {
  content: "\ebb3";
}

.ri-coins-line:before {
  content: "\ebb4";
}

.ri-collage-fill:before {
  content: "\ebb5";
}

.ri-collage-line:before {
  content: "\ebb6";
}

.ri-command-fill:before {
  content: "\ebb7";
}

.ri-command-line:before {
  content: "\ebb8";
}

.ri-community-fill:before {
  content: "\ebb9";
}

.ri-community-line:before {
  content: "\ebba";
}

.ri-compass-2-fill:before {
  content: "\ebbb";
}

.ri-compass-2-line:before {
  content: "\ebbc";
}

.ri-compass-3-fill:before {
  content: "\ebbd";
}

.ri-compass-3-line:before {
  content: "\ebbe";
}

.ri-compass-4-fill:before {
  content: "\ebbf";
}

.ri-compass-4-line:before {
  content: "\ebc0";
}

.ri-compass-discover-fill:before {
  content: "\ebc1";
}

.ri-compass-discover-line:before {
  content: "\ebc2";
}

.ri-compass-fill:before {
  content: "\ebc3";
}

.ri-compass-line:before {
  content: "\ebc4";
}

.ri-compasses-2-fill:before {
  content: "\ebc5";
}

.ri-compasses-2-line:before {
  content: "\ebc6";
}

.ri-compasses-fill:before {
  content: "\ebc7";
}

.ri-compasses-line:before {
  content: "\ebc8";
}

.ri-computer-fill:before {
  content: "\ebc9";
}

.ri-computer-line:before {
  content: "\ebca";
}

.ri-contacts-book-2-fill:before {
  content: "\ebcb";
}

.ri-contacts-book-2-line:before {
  content: "\ebcc";
}

.ri-contacts-book-fill:before {
  content: "\ebcd";
}

.ri-contacts-book-line:before {
  content: "\ebce";
}

.ri-contacts-book-upload-fill:before {
  content: "\ebcf";
}

.ri-contacts-book-upload-line:before {
  content: "\ebd0";
}

.ri-contacts-fill:before {
  content: "\ebd1";
}

.ri-contacts-line:before {
  content: "\ebd2";
}

.ri-contrast-2-fill:before {
  content: "\ebd3";
}

.ri-contrast-2-line:before {
  content: "\ebd4";
}

.ri-contrast-drop-2-fill:before {
  content: "\ebd5";
}

.ri-contrast-drop-2-line:before {
  content: "\ebd6";
}

.ri-contrast-drop-fill:before {
  content: "\ebd7";
}

.ri-contrast-drop-line:before {
  content: "\ebd8";
}

.ri-contrast-fill:before {
  content: "\ebd9";
}

.ri-contrast-line:before {
  content: "\ebda";
}

.ri-copper-coin-fill:before {
  content: "\ebdb";
}

.ri-copper-coin-line:before {
  content: "\ebdc";
}

.ri-copper-diamond-fill:before {
  content: "\ebdd";
}

.ri-copper-diamond-line:before {
  content: "\ebde";
}

.ri-copyleft-fill:before {
  content: "\ebdf";
}

.ri-copyleft-line:before {
  content: "\ebe0";
}

.ri-copyright-fill:before {
  content: "\ebe1";
}

.ri-copyright-line:before {
  content: "\ebe2";
}

.ri-coreos-fill:before {
  content: "\ebe3";
}

.ri-coreos-line:before {
  content: "\ebe4";
}

.ri-coupon-2-fill:before {
  content: "\ebe5";
}

.ri-coupon-2-line:before {
  content: "\ebe6";
}

.ri-coupon-3-fill:before {
  content: "\ebe7";
}

.ri-coupon-3-line:before {
  content: "\ebe8";
}

.ri-coupon-4-fill:before {
  content: "\ebe9";
}

.ri-coupon-4-line:before {
  content: "\ebea";
}

.ri-coupon-5-fill:before {
  content: "\ebeb";
}

.ri-coupon-5-line:before {
  content: "\ebec";
}

.ri-coupon-fill:before {
  content: "\ebed";
}

.ri-coupon-line:before {
  content: "\ebee";
}

.ri-cpu-fill:before {
  content: "\ebef";
}

.ri-cpu-line:before {
  content: "\ebf0";
}

.ri-creative-commons-by-fill:before {
  content: "\ebf1";
}

.ri-creative-commons-by-line:before {
  content: "\ebf2";
}

.ri-creative-commons-fill:before {
  content: "\ebf3";
}

.ri-creative-commons-line:before {
  content: "\ebf4";
}

.ri-creative-commons-nc-fill:before {
  content: "\ebf5";
}

.ri-creative-commons-nc-line:before {
  content: "\ebf6";
}

.ri-creative-commons-nd-fill:before {
  content: "\ebf7";
}

.ri-creative-commons-nd-line:before {
  content: "\ebf8";
}

.ri-creative-commons-sa-fill:before {
  content: "\ebf9";
}

.ri-creative-commons-sa-line:before {
  content: "\ebfa";
}

.ri-creative-commons-zero-fill:before {
  content: "\ebfb";
}

.ri-creative-commons-zero-line:before {
  content: "\ebfc";
}

.ri-criminal-fill:before {
  content: "\ebfd";
}

.ri-criminal-line:before {
  content: "\ebfe";
}

.ri-crop-2-fill:before {
  content: "\ebff";
}

.ri-crop-2-line:before {
  content: "\ec00";
}

.ri-crop-fill:before {
  content: "\ec01";
}

.ri-crop-line:before {
  content: "\ec02";
}

.ri-css3-fill:before {
  content: "\ec03";
}

.ri-css3-line:before {
  content: "\ec04";
}

.ri-cup-fill:before {
  content: "\ec05";
}

.ri-cup-line:before {
  content: "\ec06";
}

.ri-currency-fill:before {
  content: "\ec07";
}

.ri-currency-line:before {
  content: "\ec08";
}

.ri-cursor-fill:before {
  content: "\ec09";
}

.ri-cursor-line:before {
  content: "\ec0a";
}

.ri-customer-service-2-fill:before {
  content: "\ec0b";
}

.ri-customer-service-2-line:before {
  content: "\ec0c";
}

.ri-customer-service-fill:before {
  content: "\ec0d";
}

.ri-customer-service-line:before {
  content: "\ec0e";
}

.ri-dashboard-2-fill:before {
  content: "\ec0f";
}

.ri-dashboard-2-line:before {
  content: "\ec10";
}

.ri-dashboard-3-fill:before {
  content: "\ec11";
}

.ri-dashboard-3-line:before {
  content: "\ec12";
}

.ri-dashboard-fill:before {
  content: "\ec13";
}

.ri-dashboard-line:before {
  content: "\ec14";
}

.ri-database-2-fill:before {
  content: "\ec15";
}

.ri-database-2-line:before {
  content: "\ec16";
}

.ri-database-fill:before {
  content: "\ec17";
}

.ri-database-line:before {
  content: "\ec18";
}

.ri-delete-back-2-fill:before {
  content: "\ec19";
}

.ri-delete-back-2-line:before {
  content: "\ec1a";
}

.ri-delete-back-fill:before {
  content: "\ec1b";
}

.ri-delete-back-line:before {
  content: "\ec1c";
}

.ri-delete-bin-2-fill:before {
  content: "\ec1d";
}

.ri-delete-bin-2-line:before {
  content: "\ec1e";
}

.ri-delete-bin-3-fill:before {
  content: "\ec1f";
}

.ri-delete-bin-3-line:before {
  content: "\ec20";
}

.ri-delete-bin-4-fill:before {
  content: "\ec21";
}

.ri-delete-bin-4-line:before {
  content: "\ec22";
}

.ri-delete-bin-5-fill:before {
  content: "\ec23";
}

.ri-delete-bin-5-line:before {
  content: "\ec24";
}

.ri-delete-bin-6-fill:before {
  content: "\ec25";
}

.ri-delete-bin-6-line:before {
  content: "\ec26";
}

.ri-delete-bin-7-fill:before {
  content: "\ec27";
}

.ri-delete-bin-7-line:before {
  content: "\ec28";
}

.ri-delete-bin-fill:before {
  content: "\ec29";
}

.ri-delete-bin-line:before {
  content: "\ec2a";
}

.ri-delete-column:before {
  content: "\ec2b";
}

.ri-delete-row:before {
  content: "\ec2c";
}

.ri-device-fill:before {
  content: "\ec2d";
}

.ri-device-line:before {
  content: "\ec2e";
}

.ri-device-recover-fill:before {
  content: "\ec2f";
}

.ri-device-recover-line:before {
  content: "\ec30";
}

.ri-dingding-fill:before {
  content: "\ec31";
}

.ri-dingding-line:before {
  content: "\ec32";
}

.ri-direction-fill:before {
  content: "\ec33";
}

.ri-direction-line:before {
  content: "\ec34";
}

.ri-disc-fill:before {
  content: "\ec35";
}

.ri-disc-line:before {
  content: "\ec36";
}

.ri-discord-fill:before {
  content: "\ec37";
}

.ri-discord-line:before {
  content: "\ec38";
}

.ri-discuss-fill:before {
  content: "\ec39";
}

.ri-discuss-line:before {
  content: "\ec3a";
}

.ri-dislike-fill:before {
  content: "\ec3b";
}

.ri-dislike-line:before {
  content: "\ec3c";
}

.ri-disqus-fill:before {
  content: "\ec3d";
}

.ri-disqus-line:before {
  content: "\ec3e";
}

.ri-divide-fill:before {
  content: "\ec3f";
}

.ri-divide-line:before {
  content: "\ec40";
}

.ri-donut-chart-fill:before {
  content: "\ec41";
}

.ri-donut-chart-line:before {
  content: "\ec42";
}

.ri-door-closed-fill:before {
  content: "\ec43";
}

.ri-door-closed-line:before {
  content: "\ec44";
}

.ri-door-fill:before {
  content: "\ec45";
}

.ri-door-line:before {
  content: "\ec46";
}

.ri-door-lock-box-fill:before {
  content: "\ec47";
}

.ri-door-lock-box-line:before {
  content: "\ec48";
}

.ri-door-lock-fill:before {
  content: "\ec49";
}

.ri-door-lock-line:before {
  content: "\ec4a";
}

.ri-door-open-fill:before {
  content: "\ec4b";
}

.ri-door-open-line:before {
  content: "\ec4c";
}

.ri-dossier-fill:before {
  content: "\ec4d";
}

.ri-dossier-line:before {
  content: "\ec4e";
}

.ri-douban-fill:before {
  content: "\ec4f";
}

.ri-douban-line:before {
  content: "\ec50";
}

.ri-double-quotes-l:before {
  content: "\ec51";
}

.ri-double-quotes-r:before {
  content: "\ec52";
}

.ri-download-2-fill:before {
  content: "\ec53";
}

.ri-download-2-line:before {
  content: "\ec54";
}

.ri-download-cloud-2-fill:before {
  content: "\ec55";
}

.ri-download-cloud-2-line:before {
  content: "\ec56";
}

.ri-download-cloud-fill:before {
  content: "\ec57";
}

.ri-download-cloud-line:before {
  content: "\ec58";
}

.ri-download-fill:before {
  content: "\ec59";
}

.ri-download-line:before {
  content: "\ec5a";
}

.ri-draft-fill:before {
  content: "\ec5b";
}

.ri-draft-line:before {
  content: "\ec5c";
}

.ri-drag-drop-fill:before {
  content: "\ec5d";
}

.ri-drag-drop-line:before {
  content: "\ec5e";
}

.ri-drag-move-2-fill:before {
  content: "\ec5f";
}

.ri-drag-move-2-line:before {
  content: "\ec60";
}

.ri-drag-move-fill:before {
  content: "\ec61";
}

.ri-drag-move-line:before {
  content: "\ec62";
}

.ri-dribbble-fill:before {
  content: "\ec63";
}

.ri-dribbble-line:before {
  content: "\ec64";
}

.ri-drive-fill:before {
  content: "\ec65";
}

.ri-drive-line:before {
  content: "\ec66";
}

.ri-drizzle-fill:before {
  content: "\ec67";
}

.ri-drizzle-line:before {
  content: "\ec68";
}

.ri-drop-fill:before {
  content: "\ec69";
}

.ri-drop-line:before {
  content: "\ec6a";
}

.ri-dropbox-fill:before {
  content: "\ec6b";
}

.ri-dropbox-line:before {
  content: "\ec6c";
}

.ri-dual-sim-1-fill:before {
  content: "\ec6d";
}

.ri-dual-sim-1-line:before {
  content: "\ec6e";
}

.ri-dual-sim-2-fill:before {
  content: "\ec6f";
}

.ri-dual-sim-2-line:before {
  content: "\ec70";
}

.ri-dv-fill:before {
  content: "\ec71";
}

.ri-dv-line:before {
  content: "\ec72";
}

.ri-dvd-fill:before {
  content: "\ec73";
}

.ri-dvd-line:before {
  content: "\ec74";
}

.ri-e-bike-2-fill:before {
  content: "\ec75";
}

.ri-e-bike-2-line:before {
  content: "\ec76";
}

.ri-e-bike-fill:before {
  content: "\ec77";
}

.ri-e-bike-line:before {
  content: "\ec78";
}

.ri-earth-fill:before {
  content: "\ec79";
}

.ri-earth-line:before {
  content: "\ec7a";
}

.ri-earthquake-fill:before {
  content: "\ec7b";
}

.ri-earthquake-line:before {
  content: "\ec7c";
}

.ri-edge-fill:before {
  content: "\ec7d";
}

.ri-edge-line:before {
  content: "\ec7e";
}

.ri-edit-2-fill:before {
  content: "\ec7f";
}

.ri-edit-2-line:before {
  content: "\ec80";
}

.ri-edit-box-fill:before {
  content: "\ec81";
}

.ri-edit-box-line:before {
  content: "\ec82";
}

.ri-edit-circle-fill:before {
  content: "\ec83";
}

.ri-edit-circle-line:before {
  content: "\ec84";
}

.ri-edit-fill:before {
  content: "\ec85";
}

.ri-edit-line:before {
  content: "\ec86";
}

.ri-eject-fill:before {
  content: "\ec87";
}

.ri-eject-line:before {
  content: "\ec88";
}

.ri-emotion-2-fill:before {
  content: "\ec89";
}

.ri-emotion-2-line:before {
  content: "\ec8a";
}

.ri-emotion-fill:before {
  content: "\ec8b";
}

.ri-emotion-happy-fill:before {
  content: "\ec8c";
}

.ri-emotion-happy-line:before {
  content: "\ec8d";
}

.ri-emotion-laugh-fill:before {
  content: "\ec8e";
}

.ri-emotion-laugh-line:before {
  content: "\ec8f";
}

.ri-emotion-line:before {
  content: "\ec90";
}

.ri-emotion-normal-fill:before {
  content: "\ec91";
}

.ri-emotion-normal-line:before {
  content: "\ec92";
}

.ri-emotion-sad-fill:before {
  content: "\ec93";
}

.ri-emotion-sad-line:before {
  content: "\ec94";
}

.ri-emotion-unhappy-fill:before {
  content: "\ec95";
}

.ri-emotion-unhappy-line:before {
  content: "\ec96";
}

.ri-empathize-fill:before {
  content: "\ec97";
}

.ri-empathize-line:before {
  content: "\ec98";
}

.ri-emphasis-cn:before {
  content: "\ec99";
}

.ri-emphasis:before {
  content: "\ec9a";
}

.ri-english-input:before {
  content: "\ec9b";
}

.ri-equalizer-fill:before {
  content: "\ec9c";
}

.ri-equalizer-line:before {
  content: "\ec9d";
}

.ri-eraser-fill:before {
  content: "\ec9e";
}

.ri-eraser-line:before {
  content: "\ec9f";
}

.ri-error-warning-fill:before {
  content: "\eca0";
}

.ri-error-warning-line:before {
  content: "\eca1";
}

.ri-evernote-fill:before {
  content: "\eca2";
}

.ri-evernote-line:before {
  content: "\eca3";
}

.ri-exchange-box-fill:before {
  content: "\eca4";
}

.ri-exchange-box-line:before {
  content: "\eca5";
}

.ri-exchange-cny-fill:before {
  content: "\eca6";
}

.ri-exchange-cny-line:before {
  content: "\eca7";
}

.ri-exchange-dollar-fill:before {
  content: "\eca8";
}

.ri-exchange-dollar-line:before {
  content: "\eca9";
}

.ri-exchange-fill:before {
  content: "\ecaa";
}

.ri-exchange-funds-fill:before {
  content: "\ecab";
}

.ri-exchange-funds-line:before {
  content: "\ecac";
}

.ri-exchange-line:before {
  content: "\ecad";
}

.ri-external-link-fill:before {
  content: "\ecae";
}

.ri-external-link-line:before {
  content: "\ecaf";
}

.ri-eye-2-fill:before {
  content: "\ecb0";
}

.ri-eye-2-line:before {
  content: "\ecb1";
}

.ri-eye-close-fill:before {
  content: "\ecb2";
}

.ri-eye-close-line:before {
  content: "\ecb3";
}

.ri-eye-fill:before {
  content: "\ecb4";
}

.ri-eye-line:before {
  content: "\ecb5";
}

.ri-eye-off-fill:before {
  content: "\ecb6";
}

.ri-eye-off-line:before {
  content: "\ecb7";
}

.ri-facebook-box-fill:before {
  content: "\ecb8";
}

.ri-facebook-box-line:before {
  content: "\ecb9";
}

.ri-facebook-circle-fill:before {
  content: "\ecba";
}

.ri-facebook-circle-line:before {
  content: "\ecbb";
}

.ri-facebook-fill:before {
  content: "\ecbc";
}

.ri-facebook-line:before {
  content: "\ecbd";
}

.ri-fahrenheit-fill:before {
  content: "\ecbe";
}

.ri-fahrenheit-line:before {
  content: "\ecbf";
}

.ri-feedback-fill:before {
  content: "\ecc0";
}

.ri-feedback-line:before {
  content: "\ecc1";
}

.ri-file-2-fill:before {
  content: "\ecc2";
}

.ri-file-2-line:before {
  content: "\ecc3";
}

.ri-file-3-fill:before {
  content: "\ecc4";
}

.ri-file-3-line:before {
  content: "\ecc5";
}

.ri-file-4-fill:before {
  content: "\ecc6";
}

.ri-file-4-line:before {
  content: "\ecc7";
}

.ri-file-add-fill:before {
  content: "\ecc8";
}

.ri-file-add-line:before {
  content: "\ecc9";
}

.ri-file-chart-2-fill:before {
  content: "\ecca";
}

.ri-file-chart-2-line:before {
  content: "\eccb";
}

.ri-file-chart-fill:before {
  content: "\eccc";
}

.ri-file-chart-line:before {
  content: "\eccd";
}

.ri-file-cloud-fill:before {
  content: "\ecce";
}

.ri-file-cloud-line:before {
  content: "\eccf";
}

.ri-file-code-fill:before {
  content: "\ecd0";
}

.ri-file-code-line:before {
  content: "\ecd1";
}

.ri-file-copy-2-fill:before {
  content: "\ecd2";
}

.ri-file-copy-2-line:before {
  content: "\ecd3";
}

.ri-file-copy-fill:before {
  content: "\ecd4";
}

.ri-file-copy-line:before {
  content: "\ecd5";
}

.ri-file-damage-fill:before {
  content: "\ecd6";
}

.ri-file-damage-line:before {
  content: "\ecd7";
}

.ri-file-download-fill:before {
  content: "\ecd8";
}

.ri-file-download-line:before {
  content: "\ecd9";
}

.ri-file-edit-fill:before {
  content: "\ecda";
}

.ri-file-edit-line:before {
  content: "\ecdb";
}

.ri-file-excel-2-fill:before {
  content: "\ecdc";
}

.ri-file-excel-2-line:before {
  content: "\ecdd";
}

.ri-file-excel-fill:before {
  content: "\ecde";
}

.ri-file-excel-line:before {
  content: "\ecdf";
}

.ri-file-fill:before {
  content: "\ece0";
}

.ri-file-forbid-fill:before {
  content: "\ece1";
}

.ri-file-forbid-line:before {
  content: "\ece2";
}

.ri-file-gif-fill:before {
  content: "\ece3";
}

.ri-file-gif-line:before {
  content: "\ece4";
}

.ri-file-history-fill:before {
  content: "\ece5";
}

.ri-file-history-line:before {
  content: "\ece6";
}

.ri-file-hwp-fill:before {
  content: "\ece7";
}

.ri-file-hwp-line:before {
  content: "\ece8";
}

.ri-file-info-fill:before {
  content: "\ece9";
}

.ri-file-info-line:before {
  content: "\ecea";
}

.ri-file-line:before {
  content: "\eceb";
}

.ri-file-list-2-fill:before {
  content: "\ecec";
}

.ri-file-list-2-line:before {
  content: "\eced";
}

.ri-file-list-3-fill:before {
  content: "\ecee";
}

.ri-file-list-3-line:before {
  content: "\ecef";
}

.ri-file-list-fill:before {
  content: "\ecf0";
}

.ri-file-list-line:before {
  content: "\ecf1";
}

.ri-file-lock-fill:before {
  content: "\ecf2";
}

.ri-file-lock-line:before {
  content: "\ecf3";
}

.ri-file-mark-fill:before {
  content: "\ecf4";
}

.ri-file-mark-line:before {
  content: "\ecf5";
}

.ri-file-music-fill:before {
  content: "\ecf6";
}

.ri-file-music-line:before {
  content: "\ecf7";
}

.ri-file-paper-2-fill:before {
  content: "\ecf8";
}

.ri-file-paper-2-line:before {
  content: "\ecf9";
}

.ri-file-paper-fill:before {
  content: "\ecfa";
}

.ri-file-paper-line:before {
  content: "\ecfb";
}

.ri-file-pdf-fill:before {
  content: "\ecfc";
}

.ri-file-pdf-line:before {
  content: "\ecfd";
}

.ri-file-ppt-2-fill:before {
  content: "\ecfe";
}

.ri-file-ppt-2-line:before {
  content: "\ecff";
}

.ri-file-ppt-fill:before {
  content: "\ed00";
}

.ri-file-ppt-line:before {
  content: "\ed01";
}

.ri-file-reduce-fill:before {
  content: "\ed02";
}

.ri-file-reduce-line:before {
  content: "\ed03";
}

.ri-file-search-fill:before {
  content: "\ed04";
}

.ri-file-search-line:before {
  content: "\ed05";
}

.ri-file-settings-fill:before {
  content: "\ed06";
}

.ri-file-settings-line:before {
  content: "\ed07";
}

.ri-file-shield-2-fill:before {
  content: "\ed08";
}

.ri-file-shield-2-line:before {
  content: "\ed09";
}

.ri-file-shield-fill:before {
  content: "\ed0a";
}

.ri-file-shield-line:before {
  content: "\ed0b";
}

.ri-file-shred-fill:before {
  content: "\ed0c";
}

.ri-file-shred-line:before {
  content: "\ed0d";
}

.ri-file-text-fill:before {
  content: "\ed0e";
}

.ri-file-text-line:before {
  content: "\ed0f";
}

.ri-file-transfer-fill:before {
  content: "\ed10";
}

.ri-file-transfer-line:before {
  content: "\ed11";
}

.ri-file-unknow-fill:before {
  content: "\ed12";
}

.ri-file-unknow-line:before {
  content: "\ed13";
}

.ri-file-upload-fill:before {
  content: "\ed14";
}

.ri-file-upload-line:before {
  content: "\ed15";
}

.ri-file-user-fill:before {
  content: "\ed16";
}

.ri-file-user-line:before {
  content: "\ed17";
}

.ri-file-warning-fill:before {
  content: "\ed18";
}

.ri-file-warning-line:before {
  content: "\ed19";
}

.ri-file-word-2-fill:before {
  content: "\ed1a";
}

.ri-file-word-2-line:before {
  content: "\ed1b";
}

.ri-file-word-fill:before {
  content: "\ed1c";
}

.ri-file-word-line:before {
  content: "\ed1d";
}

.ri-file-zip-fill:before {
  content: "\ed1e";
}

.ri-file-zip-line:before {
  content: "\ed1f";
}

.ri-film-fill:before {
  content: "\ed20";
}

.ri-film-line:before {
  content: "\ed21";
}

.ri-filter-2-fill:before {
  content: "\ed22";
}

.ri-filter-2-line:before {
  content: "\ed23";
}

.ri-filter-3-fill:before {
  content: "\ed24";
}

.ri-filter-3-line:before {
  content: "\ed25";
}

.ri-filter-fill:before {
  content: "\ed26";
}

.ri-filter-line:before {
  content: "\ed27";
}

.ri-filter-off-fill:before {
  content: "\ed28";
}

.ri-filter-off-line:before {
  content: "\ed29";
}

.ri-find-replace-fill:before {
  content: "\ed2a";
}

.ri-find-replace-line:before {
  content: "\ed2b";
}

.ri-finder-fill:before {
  content: "\ed2c";
}

.ri-finder-line:before {
  content: "\ed2d";
}

.ri-fingerprint-2-fill:before {
  content: "\ed2e";
}

.ri-fingerprint-2-line:before {
  content: "\ed2f";
}

.ri-fingerprint-fill:before {
  content: "\ed30";
}

.ri-fingerprint-line:before {
  content: "\ed31";
}

.ri-fire-fill:before {
  content: "\ed32";
}

.ri-fire-line:before {
  content: "\ed33";
}

.ri-firefox-fill:before {
  content: "\ed34";
}

.ri-firefox-line:before {
  content: "\ed35";
}

.ri-first-aid-kit-fill:before {
  content: "\ed36";
}

.ri-first-aid-kit-line:before {
  content: "\ed37";
}

.ri-flag-2-fill:before {
  content: "\ed38";
}

.ri-flag-2-line:before {
  content: "\ed39";
}

.ri-flag-fill:before {
  content: "\ed3a";
}

.ri-flag-line:before {
  content: "\ed3b";
}

.ri-flashlight-fill:before {
  content: "\ed3c";
}

.ri-flashlight-line:before {
  content: "\ed3d";
}

.ri-flask-fill:before {
  content: "\ed3e";
}

.ri-flask-line:before {
  content: "\ed3f";
}

.ri-flight-land-fill:before {
  content: "\ed40";
}

.ri-flight-land-line:before {
  content: "\ed41";
}

.ri-flight-takeoff-fill:before {
  content: "\ed42";
}

.ri-flight-takeoff-line:before {
  content: "\ed43";
}

.ri-flood-fill:before {
  content: "\ed44";
}

.ri-flood-line:before {
  content: "\ed45";
}

.ri-flow-chart:before {
  content: "\ed46";
}

.ri-flutter-fill:before {
  content: "\ed47";
}

.ri-flutter-line:before {
  content: "\ed48";
}

.ri-focus-2-fill:before {
  content: "\ed49";
}

.ri-focus-2-line:before {
  content: "\ed4a";
}

.ri-focus-3-fill:before {
  content: "\ed4b";
}

.ri-focus-3-line:before {
  content: "\ed4c";
}

.ri-focus-fill:before {
  content: "\ed4d";
}

.ri-focus-line:before {
  content: "\ed4e";
}

.ri-foggy-fill:before {
  content: "\ed4f";
}

.ri-foggy-line:before {
  content: "\ed50";
}

.ri-folder-2-fill:before {
  content: "\ed51";
}

.ri-folder-2-line:before {
  content: "\ed52";
}

.ri-folder-3-fill:before {
  content: "\ed53";
}

.ri-folder-3-line:before {
  content: "\ed54";
}

.ri-folder-4-fill:before {
  content: "\ed55";
}

.ri-folder-4-line:before {
  content: "\ed56";
}

.ri-folder-5-fill:before {
  content: "\ed57";
}

.ri-folder-5-line:before {
  content: "\ed58";
}

.ri-folder-add-fill:before {
  content: "\ed59";
}

.ri-folder-add-line:before {
  content: "\ed5a";
}

.ri-folder-chart-2-fill:before {
  content: "\ed5b";
}

.ri-folder-chart-2-line:before {
  content: "\ed5c";
}

.ri-folder-chart-fill:before {
  content: "\ed5d";
}

.ri-folder-chart-line:before {
  content: "\ed5e";
}

.ri-folder-download-fill:before {
  content: "\ed5f";
}

.ri-folder-download-line:before {
  content: "\ed60";
}

.ri-folder-fill:before {
  content: "\ed61";
}

.ri-folder-forbid-fill:before {
  content: "\ed62";
}

.ri-folder-forbid-line:before {
  content: "\ed63";
}

.ri-folder-history-fill:before {
  content: "\ed64";
}

.ri-folder-history-line:before {
  content: "\ed65";
}

.ri-folder-info-fill:before {
  content: "\ed66";
}

.ri-folder-info-line:before {
  content: "\ed67";
}

.ri-folder-keyhole-fill:before {
  content: "\ed68";
}

.ri-folder-keyhole-line:before {
  content: "\ed69";
}

.ri-folder-line:before {
  content: "\ed6a";
}

.ri-folder-lock-fill:before {
  content: "\ed6b";
}

.ri-folder-lock-line:before {
  content: "\ed6c";
}

.ri-folder-music-fill:before {
  content: "\ed6d";
}

.ri-folder-music-line:before {
  content: "\ed6e";
}

.ri-folder-open-fill:before {
  content: "\ed6f";
}

.ri-folder-open-line:before {
  content: "\ed70";
}

.ri-folder-received-fill:before {
  content: "\ed71";
}

.ri-folder-received-line:before {
  content: "\ed72";
}

.ri-folder-reduce-fill:before {
  content: "\ed73";
}

.ri-folder-reduce-line:before {
  content: "\ed74";
}

.ri-folder-settings-fill:before {
  content: "\ed75";
}

.ri-folder-settings-line:before {
  content: "\ed76";
}

.ri-folder-shared-fill:before {
  content: "\ed77";
}

.ri-folder-shared-line:before {
  content: "\ed78";
}

.ri-folder-shield-2-fill:before {
  content: "\ed79";
}

.ri-folder-shield-2-line:before {
  content: "\ed7a";
}

.ri-folder-shield-fill:before {
  content: "\ed7b";
}

.ri-folder-shield-line:before {
  content: "\ed7c";
}

.ri-folder-transfer-fill:before {
  content: "\ed7d";
}

.ri-folder-transfer-line:before {
  content: "\ed7e";
}

.ri-folder-unknow-fill:before {
  content: "\ed7f";
}

.ri-folder-unknow-line:before {
  content: "\ed80";
}

.ri-folder-upload-fill:before {
  content: "\ed81";
}

.ri-folder-upload-line:before {
  content: "\ed82";
}

.ri-folder-user-fill:before {
  content: "\ed83";
}

.ri-folder-user-line:before {
  content: "\ed84";
}

.ri-folder-warning-fill:before {
  content: "\ed85";
}

.ri-folder-warning-line:before {
  content: "\ed86";
}

.ri-folder-zip-fill:before {
  content: "\ed87";
}

.ri-folder-zip-line:before {
  content: "\ed88";
}

.ri-folders-fill:before {
  content: "\ed89";
}

.ri-folders-line:before {
  content: "\ed8a";
}

.ri-font-color:before {
  content: "\ed8b";
}

.ri-font-size-2:before {
  content: "\ed8c";
}

.ri-font-size:before {
  content: "\ed8d";
}

.ri-football-fill:before {
  content: "\ed8e";
}

.ri-football-line:before {
  content: "\ed8f";
}

.ri-footprint-fill:before {
  content: "\ed90";
}

.ri-footprint-line:before {
  content: "\ed91";
}

.ri-forbid-2-fill:before {
  content: "\ed92";
}

.ri-forbid-2-line:before {
  content: "\ed93";
}

.ri-forbid-fill:before {
  content: "\ed94";
}

.ri-forbid-line:before {
  content: "\ed95";
}

.ri-format-clear:before {
  content: "\ed96";
}

.ri-fridge-fill:before {
  content: "\ed97";
}

.ri-fridge-line:before {
  content: "\ed98";
}

.ri-fullscreen-exit-fill:before {
  content: "\ed99";
}

.ri-fullscreen-exit-line:before {
  content: "\ed9a";
}

.ri-fullscreen-fill:before {
  content: "\ed9b";
}

.ri-fullscreen-line:before {
  content: "\ed9c";
}

.ri-function-fill:before {
  content: "\ed9d";
}

.ri-function-line:before {
  content: "\ed9e";
}

.ri-functions:before {
  content: "\ed9f";
}

.ri-funds-box-fill:before {
  content: "\eda0";
}

.ri-funds-box-line:before {
  content: "\eda1";
}

.ri-funds-fill:before {
  content: "\eda2";
}

.ri-funds-line:before {
  content: "\eda3";
}

.ri-gallery-fill:before {
  content: "\eda4";
}

.ri-gallery-line:before {
  content: "\eda5";
}

.ri-gallery-upload-fill:before {
  content: "\eda6";
}

.ri-gallery-upload-line:before {
  content: "\eda7";
}

.ri-game-fill:before {
  content: "\eda8";
}

.ri-game-line:before {
  content: "\eda9";
}

.ri-gamepad-fill:before {
  content: "\edaa";
}

.ri-gamepad-line:before {
  content: "\edab";
}

.ri-gas-station-fill:before {
  content: "\edac";
}

.ri-gas-station-line:before {
  content: "\edad";
}

.ri-gatsby-fill:before {
  content: "\edae";
}

.ri-gatsby-line:before {
  content: "\edaf";
}

.ri-genderless-fill:before {
  content: "\edb0";
}

.ri-genderless-line:before {
  content: "\edb1";
}

.ri-ghost-2-fill:before {
  content: "\edb2";
}

.ri-ghost-2-line:before {
  content: "\edb3";
}

.ri-ghost-fill:before {
  content: "\edb4";
}

.ri-ghost-line:before {
  content: "\edb5";
}

.ri-ghost-smile-fill:before {
  content: "\edb6";
}

.ri-ghost-smile-line:before {
  content: "\edb7";
}

.ri-gift-2-fill:before {
  content: "\edb8";
}

.ri-gift-2-line:before {
  content: "\edb9";
}

.ri-gift-fill:before {
  content: "\edba";
}

.ri-gift-line:before {
  content: "\edbb";
}

.ri-git-branch-fill:before {
  content: "\edbc";
}

.ri-git-branch-line:before {
  content: "\edbd";
}

.ri-git-commit-fill:before {
  content: "\edbe";
}

.ri-git-commit-line:before {
  content: "\edbf";
}

.ri-git-merge-fill:before {
  content: "\edc0";
}

.ri-git-merge-line:before {
  content: "\edc1";
}

.ri-git-pull-request-fill:before {
  content: "\edc2";
}

.ri-git-pull-request-line:before {
  content: "\edc3";
}

.ri-git-repository-commits-fill:before {
  content: "\edc4";
}

.ri-git-repository-commits-line:before {
  content: "\edc5";
}

.ri-git-repository-fill:before {
  content: "\edc6";
}

.ri-git-repository-line:before {
  content: "\edc7";
}

.ri-git-repository-private-fill:before {
  content: "\edc8";
}

.ri-git-repository-private-line:before {
  content: "\edc9";
}

.ri-github-fill:before {
  content: "\edca";
}

.ri-github-line:before {
  content: "\edcb";
}

.ri-gitlab-fill:before {
  content: "\edcc";
}

.ri-gitlab-line:before {
  content: "\edcd";
}

.ri-global-fill:before {
  content: "\edce";
}

.ri-global-line:before {
  content: "\edcf";
}

.ri-globe-fill:before {
  content: "\edd0";
}

.ri-globe-line:before {
  content: "\edd1";
}

.ri-goblet-fill:before {
  content: "\edd2";
}

.ri-goblet-line:before {
  content: "\edd3";
}

.ri-google-fill:before {
  content: "\edd4";
}

.ri-google-line:before {
  content: "\edd5";
}

.ri-google-play-fill:before {
  content: "\edd6";
}

.ri-google-play-line:before {
  content: "\edd7";
}

.ri-government-fill:before {
  content: "\edd8";
}

.ri-government-line:before {
  content: "\edd9";
}

.ri-gps-fill:before {
  content: "\edda";
}

.ri-gps-line:before {
  content: "\eddb";
}

.ri-gradienter-fill:before {
  content: "\eddc";
}

.ri-gradienter-line:before {
  content: "\eddd";
}

.ri-grid-fill:before {
  content: "\edde";
}

.ri-grid-line:before {
  content: "\eddf";
}

.ri-group-2-fill:before {
  content: "\ede0";
}

.ri-group-2-line:before {
  content: "\ede1";
}

.ri-group-fill:before {
  content: "\ede2";
}

.ri-group-line:before {
  content: "\ede3";
}

.ri-guide-fill:before {
  content: "\ede4";
}

.ri-guide-line:before {
  content: "\ede5";
}

.ri-h-1:before {
  content: "\ede6";
}

.ri-h-2:before {
  content: "\ede7";
}

.ri-h-3:before {
  content: "\ede8";
}

.ri-h-4:before {
  content: "\ede9";
}

.ri-h-5:before {
  content: "\edea";
}

.ri-h-6:before {
  content: "\edeb";
}

.ri-hail-fill:before {
  content: "\edec";
}

.ri-hail-line:before {
  content: "\eded";
}

.ri-hammer-fill:before {
  content: "\edee";
}

.ri-hammer-line:before {
  content: "\edef";
}

.ri-hand-coin-fill:before {
  content: "\edf0";
}

.ri-hand-coin-line:before {
  content: "\edf1";
}

.ri-hand-heart-fill:before {
  content: "\edf2";
}

.ri-hand-heart-line:before {
  content: "\edf3";
}

.ri-hand-sanitizer-fill:before {
  content: "\edf4";
}

.ri-hand-sanitizer-line:before {
  content: "\edf5";
}

.ri-handbag-fill:before {
  content: "\edf6";
}

.ri-handbag-line:before {
  content: "\edf7";
}

.ri-hard-drive-2-fill:before {
  content: "\edf8";
}

.ri-hard-drive-2-line:before {
  content: "\edf9";
}

.ri-hard-drive-fill:before {
  content: "\edfa";
}

.ri-hard-drive-line:before {
  content: "\edfb";
}

.ri-hashtag:before {
  content: "\edfc";
}

.ri-haze-2-fill:before {
  content: "\edfd";
}

.ri-haze-2-line:before {
  content: "\edfe";
}

.ri-haze-fill:before {
  content: "\edff";
}

.ri-haze-line:before {
  content: "\ee00";
}

.ri-hd-fill:before {
  content: "\ee01";
}

.ri-hd-line:before {
  content: "\ee02";
}

.ri-heading:before {
  content: "\ee03";
}

.ri-headphone-fill:before {
  content: "\ee04";
}

.ri-headphone-line:before {
  content: "\ee05";
}

.ri-health-book-fill:before {
  content: "\ee06";
}

.ri-health-book-line:before {
  content: "\ee07";
}

.ri-heart-2-fill:before {
  content: "\ee08";
}

.ri-heart-2-line:before {
  content: "\ee09";
}

.ri-heart-3-fill:before {
  content: "\ee0a";
}

.ri-heart-3-line:before {
  content: "\ee0b";
}

.ri-heart-add-fill:before {
  content: "\ee0c";
}

.ri-heart-add-line:before {
  content: "\ee0d";
}

.ri-heart-fill:before {
  content: "\ee0e";
}

.ri-heart-line:before {
  content: "\ee0f";
}

.ri-heart-pulse-fill:before {
  content: "\ee10";
}

.ri-heart-pulse-line:before {
  content: "\ee11";
}

.ri-hearts-fill:before {
  content: "\ee12";
}

.ri-hearts-line:before {
  content: "\ee13";
}

.ri-heavy-showers-fill:before {
  content: "\ee14";
}

.ri-heavy-showers-line:before {
  content: "\ee15";
}

.ri-history-fill:before {
  content: "\ee16";
}

.ri-history-line:before {
  content: "\ee17";
}

.ri-home-2-fill:before {
  content: "\ee18";
}

.ri-home-2-line:before {
  content: "\ee19";
}

.ri-home-3-fill:before {
  content: "\ee1a";
}

.ri-home-3-line:before {
  content: "\ee1b";
}

.ri-home-4-fill:before {
  content: "\ee1c";
}

.ri-home-4-line:before {
  content: "\ee1d";
}

.ri-home-5-fill:before {
  content: "\ee1e";
}

.ri-home-5-line:before {
  content: "\ee1f";
}

.ri-home-6-fill:before {
  content: "\ee20";
}

.ri-home-6-line:before {
  content: "\ee21";
}

.ri-home-7-fill:before {
  content: "\ee22";
}

.ri-home-7-line:before {
  content: "\ee23";
}

.ri-home-8-fill:before {
  content: "\ee24";
}

.ri-home-8-line:before {
  content: "\ee25";
}

.ri-home-fill:before {
  content: "\ee26";
}

.ri-home-gear-fill:before {
  content: "\ee27";
}

.ri-home-gear-line:before {
  content: "\ee28";
}

.ri-home-heart-fill:before {
  content: "\ee29";
}

.ri-home-heart-line:before {
  content: "\ee2a";
}

.ri-home-line:before {
  content: "\ee2b";
}

.ri-home-smile-2-fill:before {
  content: "\ee2c";
}

.ri-home-smile-2-line:before {
  content: "\ee2d";
}

.ri-home-smile-fill:before {
  content: "\ee2e";
}

.ri-home-smile-line:before {
  content: "\ee2f";
}

.ri-home-wifi-fill:before {
  content: "\ee30";
}

.ri-home-wifi-line:before {
  content: "\ee31";
}

.ri-honor-of-kings-fill:before {
  content: "\ee32";
}

.ri-honor-of-kings-line:before {
  content: "\ee33";
}

.ri-honour-fill:before {
  content: "\ee34";
}

.ri-honour-line:before {
  content: "\ee35";
}

.ri-hospital-fill:before {
  content: "\ee36";
}

.ri-hospital-line:before {
  content: "\ee37";
}

.ri-hotel-bed-fill:before {
  content: "\ee38";
}

.ri-hotel-bed-line:before {
  content: "\ee39";
}

.ri-hotel-fill:before {
  content: "\ee3a";
}

.ri-hotel-line:before {
  content: "\ee3b";
}

.ri-hotspot-fill:before {
  content: "\ee3c";
}

.ri-hotspot-line:before {
  content: "\ee3d";
}

.ri-hq-fill:before {
  content: "\ee3e";
}

.ri-hq-line:before {
  content: "\ee3f";
}

.ri-html5-fill:before {
  content: "\ee40";
}

.ri-html5-line:before {
  content: "\ee41";
}

.ri-ie-fill:before {
  content: "\ee42";
}

.ri-ie-line:before {
  content: "\ee43";
}

.ri-image-2-fill:before {
  content: "\ee44";
}

.ri-image-2-line:before {
  content: "\ee45";
}

.ri-image-add-fill:before {
  content: "\ee46";
}

.ri-image-add-line:before {
  content: "\ee47";
}

.ri-image-edit-fill:before {
  content: "\ee48";
}

.ri-image-edit-line:before {
  content: "\ee49";
}

.ri-image-fill:before {
  content: "\ee4a";
}

.ri-image-line:before {
  content: "\ee4b";
}

.ri-inbox-archive-fill:before {
  content: "\ee4c";
}

.ri-inbox-archive-line:before {
  content: "\ee4d";
}

.ri-inbox-fill:before {
  content: "\ee4e";
}

.ri-inbox-line:before {
  content: "\ee4f";
}

.ri-inbox-unarchive-fill:before {
  content: "\ee50";
}

.ri-inbox-unarchive-line:before {
  content: "\ee51";
}

.ri-increase-decrease-fill:before {
  content: "\ee52";
}

.ri-increase-decrease-line:before {
  content: "\ee53";
}

.ri-indent-decrease:before {
  content: "\ee54";
}

.ri-indent-increase:before {
  content: "\ee55";
}

.ri-indeterminate-circle-fill:before {
  content: "\ee56";
}

.ri-indeterminate-circle-line:before {
  content: "\ee57";
}

.ri-information-fill:before {
  content: "\ee58";
}

.ri-information-line:before {
  content: "\ee59";
}

.ri-infrared-thermometer-fill:before {
  content: "\ee5a";
}

.ri-infrared-thermometer-line:before {
  content: "\ee5b";
}

.ri-ink-bottle-fill:before {
  content: "\ee5c";
}

.ri-ink-bottle-line:before {
  content: "\ee5d";
}

.ri-input-cursor-move:before {
  content: "\ee5e";
}

.ri-input-method-fill:before {
  content: "\ee5f";
}

.ri-input-method-line:before {
  content: "\ee60";
}

.ri-insert-column-left:before {
  content: "\ee61";
}

.ri-insert-column-right:before {
  content: "\ee62";
}

.ri-insert-row-bottom:before {
  content: "\ee63";
}

.ri-insert-row-top:before {
  content: "\ee64";
}

.ri-instagram-fill:before {
  content: "\ee65";
}

.ri-instagram-line:before {
  content: "\ee66";
}

.ri-install-fill:before {
  content: "\ee67";
}

.ri-install-line:before {
  content: "\ee68";
}

.ri-invision-fill:before {
  content: "\ee69";
}

.ri-invision-line:before {
  content: "\ee6a";
}

.ri-italic:before {
  content: "\ee6b";
}

.ri-kakao-talk-fill:before {
  content: "\ee6c";
}

.ri-kakao-talk-line:before {
  content: "\ee6d";
}

.ri-key-2-fill:before {
  content: "\ee6e";
}

.ri-key-2-line:before {
  content: "\ee6f";
}

.ri-key-fill:before {
  content: "\ee70";
}

.ri-key-line:before {
  content: "\ee71";
}

.ri-keyboard-box-fill:before {
  content: "\ee72";
}

.ri-keyboard-box-line:before {
  content: "\ee73";
}

.ri-keyboard-fill:before {
  content: "\ee74";
}

.ri-keyboard-line:before {
  content: "\ee75";
}

.ri-keynote-fill:before {
  content: "\ee76";
}

.ri-keynote-line:before {
  content: "\ee77";
}

.ri-knife-blood-fill:before {
  content: "\ee78";
}

.ri-knife-blood-line:before {
  content: "\ee79";
}

.ri-knife-fill:before {
  content: "\ee7a";
}

.ri-knife-line:before {
  content: "\ee7b";
}

.ri-landscape-fill:before {
  content: "\ee7c";
}

.ri-landscape-line:before {
  content: "\ee7d";
}

.ri-layout-2-fill:before {
  content: "\ee7e";
}

.ri-layout-2-line:before {
  content: "\ee7f";
}

.ri-layout-3-fill:before {
  content: "\ee80";
}

.ri-layout-3-line:before {
  content: "\ee81";
}

.ri-layout-4-fill:before {
  content: "\ee82";
}

.ri-layout-4-line:before {
  content: "\ee83";
}

.ri-layout-5-fill:before {
  content: "\ee84";
}

.ri-layout-5-line:before {
  content: "\ee85";
}

.ri-layout-6-fill:before {
  content: "\ee86";
}

.ri-layout-6-line:before {
  content: "\ee87";
}

.ri-layout-bottom-2-fill:before {
  content: "\ee88";
}

.ri-layout-bottom-2-line:before {
  content: "\ee89";
}

.ri-layout-bottom-fill:before {
  content: "\ee8a";
}

.ri-layout-bottom-line:before {
  content: "\ee8b";
}

.ri-layout-column-fill:before {
  content: "\ee8c";
}

.ri-layout-column-line:before {
  content: "\ee8d";
}

.ri-layout-fill:before {
  content: "\ee8e";
}

.ri-layout-grid-fill:before {
  content: "\ee8f";
}

.ri-layout-grid-line:before {
  content: "\ee90";
}

.ri-layout-left-2-fill:before {
  content: "\ee91";
}

.ri-layout-left-2-line:before {
  content: "\ee92";
}

.ri-layout-left-fill:before {
  content: "\ee93";
}

.ri-layout-left-line:before {
  content: "\ee94";
}

.ri-layout-line:before {
  content: "\ee95";
}

.ri-layout-masonry-fill:before {
  content: "\ee96";
}

.ri-layout-masonry-line:before {
  content: "\ee97";
}

.ri-layout-right-2-fill:before {
  content: "\ee98";
}

.ri-layout-right-2-line:before {
  content: "\ee99";
}

.ri-layout-right-fill:before {
  content: "\ee9a";
}

.ri-layout-right-line:before {
  content: "\ee9b";
}

.ri-layout-row-fill:before {
  content: "\ee9c";
}

.ri-layout-row-line:before {
  content: "\ee9d";
}

.ri-layout-top-2-fill:before {
  content: "\ee9e";
}

.ri-layout-top-2-line:before {
  content: "\ee9f";
}

.ri-layout-top-fill:before {
  content: "\eea0";
}

.ri-layout-top-line:before {
  content: "\eea1";
}

.ri-leaf-fill:before {
  content: "\eea2";
}

.ri-leaf-line:before {
  content: "\eea3";
}

.ri-lifebuoy-fill:before {
  content: "\eea4";
}

.ri-lifebuoy-line:before {
  content: "\eea5";
}

.ri-lightbulb-fill:before {
  content: "\eea6";
}

.ri-lightbulb-flash-fill:before {
  content: "\eea7";
}

.ri-lightbulb-flash-line:before {
  content: "\eea8";
}

.ri-lightbulb-line:before {
  content: "\eea9";
}

.ri-line-chart-fill:before {
  content: "\eeaa";
}

.ri-line-chart-line:before {
  content: "\eeab";
}

.ri-line-fill:before {
  content: "\eeac";
}

.ri-line-height:before {
  content: "\eead";
}

.ri-line-line:before {
  content: "\eeae";
}

.ri-link-m:before {
  content: "\eeaf";
}

.ri-link-unlink-m:before {
  content: "\eeb0";
}

.ri-link-unlink:before {
  content: "\eeb1";
}

.ri-link:before {
  content: "\eeb2";
}

.ri-linkedin-box-fill:before {
  content: "\eeb3";
}

.ri-linkedin-box-line:before {
  content: "\eeb4";
}

.ri-linkedin-fill:before {
  content: "\eeb5";
}

.ri-linkedin-line:before {
  content: "\eeb6";
}

.ri-links-fill:before {
  content: "\eeb7";
}

.ri-links-line:before {
  content: "\eeb8";
}

.ri-list-check-2:before {
  content: "\eeb9";
}

.ri-list-check:before {
  content: "\eeba";
}

.ri-list-ordered:before {
  content: "\eebb";
}

.ri-list-settings-fill:before {
  content: "\eebc";
}

.ri-list-settings-line:before {
  content: "\eebd";
}

.ri-list-unordered:before {
  content: "\eebe";
}

.ri-live-fill:before {
  content: "\eebf";
}

.ri-live-line:before {
  content: "\eec0";
}

.ri-loader-2-fill:before {
  content: "\eec1";
}

.ri-loader-2-line:before {
  content: "\eec2";
}

.ri-loader-3-fill:before {
  content: "\eec3";
}

.ri-loader-3-line:before {
  content: "\eec4";
}

.ri-loader-4-fill:before {
  content: "\eec5";
}

.ri-loader-4-line:before {
  content: "\eec6";
}

.ri-loader-5-fill:before {
  content: "\eec7";
}

.ri-loader-5-line:before {
  content: "\eec8";
}

.ri-loader-fill:before {
  content: "\eec9";
}

.ri-loader-line:before {
  content: "\eeca";
}

.ri-lock-2-fill:before {
  content: "\eecb";
}

.ri-lock-2-line:before {
  content: "\eecc";
}

.ri-lock-fill:before {
  content: "\eecd";
}

.ri-lock-line:before {
  content: "\eece";
}

.ri-lock-password-fill:before {
  content: "\eecf";
}

.ri-lock-password-line:before {
  content: "\eed0";
}

.ri-lock-unlock-fill:before {
  content: "\eed1";
}

.ri-lock-unlock-line:before {
  content: "\eed2";
}

.ri-login-box-fill:before {
  content: "\eed3";
}

.ri-login-box-line:before {
  content: "\eed4";
}

.ri-login-circle-fill:before {
  content: "\eed5";
}

.ri-login-circle-line:before {
  content: "\eed6";
}

.ri-logout-box-fill:before {
  content: "\eed7";
}

.ri-logout-box-line:before {
  content: "\eed8";
}

.ri-logout-box-r-fill:before {
  content: "\eed9";
}

.ri-logout-box-r-line:before {
  content: "\eeda";
}

.ri-logout-circle-fill:before {
  content: "\eedb";
}

.ri-logout-circle-line:before {
  content: "\eedc";
}

.ri-logout-circle-r-fill:before {
  content: "\eedd";
}

.ri-logout-circle-r-line:before {
  content: "\eede";
}

.ri-luggage-cart-fill:before {
  content: "\eedf";
}

.ri-luggage-cart-line:before {
  content: "\eee0";
}

.ri-luggage-deposit-fill:before {
  content: "\eee1";
}

.ri-luggage-deposit-line:before {
  content: "\eee2";
}

.ri-lungs-fill:before {
  content: "\eee3";
}

.ri-lungs-line:before {
  content: "\eee4";
}

.ri-mac-fill:before {
  content: "\eee5";
}

.ri-mac-line:before {
  content: "\eee6";
}

.ri-macbook-fill:before {
  content: "\eee7";
}

.ri-macbook-line:before {
  content: "\eee8";
}

.ri-magic-fill:before {
  content: "\eee9";
}

.ri-magic-line:before {
  content: "\eeea";
}

.ri-mail-add-fill:before {
  content: "\eeeb";
}

.ri-mail-add-line:before {
  content: "\eeec";
}

.ri-mail-check-fill:before {
  content: "\eeed";
}

.ri-mail-check-line:before {
  content: "\eeee";
}

.ri-mail-close-fill:before {
  content: "\eeef";
}

.ri-mail-close-line:before {
  content: "\eef0";
}

.ri-mail-download-fill:before {
  content: "\eef1";
}

.ri-mail-download-line:before {
  content: "\eef2";
}

.ri-mail-fill:before {
  content: "\eef3";
}

.ri-mail-forbid-fill:before {
  content: "\eef4";
}

.ri-mail-forbid-line:before {
  content: "\eef5";
}

.ri-mail-line:before {
  content: "\eef6";
}

.ri-mail-lock-fill:before {
  content: "\eef7";
}

.ri-mail-lock-line:before {
  content: "\eef8";
}

.ri-mail-open-fill:before {
  content: "\eef9";
}

.ri-mail-open-line:before {
  content: "\eefa";
}

.ri-mail-send-fill:before {
  content: "\eefb";
}

.ri-mail-send-line:before {
  content: "\eefc";
}

.ri-mail-settings-fill:before {
  content: "\eefd";
}

.ri-mail-settings-line:before {
  content: "\eefe";
}

.ri-mail-star-fill:before {
  content: "\eeff";
}

.ri-mail-star-line:before {
  content: "\ef00";
}

.ri-mail-unread-fill:before {
  content: "\ef01";
}

.ri-mail-unread-line:before {
  content: "\ef02";
}

.ri-mail-volume-fill:before {
  content: "\ef03";
}

.ri-mail-volume-line:before {
  content: "\ef04";
}

.ri-map-2-fill:before {
  content: "\ef05";
}

.ri-map-2-line:before {
  content: "\ef06";
}

.ri-map-fill:before {
  content: "\ef07";
}

.ri-map-line:before {
  content: "\ef08";
}

.ri-map-pin-2-fill:before {
  content: "\ef09";
}

.ri-map-pin-2-line:before {
  content: "\ef0a";
}

.ri-map-pin-3-fill:before {
  content: "\ef0b";
}

.ri-map-pin-3-line:before {
  content: "\ef0c";
}

.ri-map-pin-4-fill:before {
  content: "\ef0d";
}

.ri-map-pin-4-line:before {
  content: "\ef0e";
}

.ri-map-pin-5-fill:before {
  content: "\ef0f";
}

.ri-map-pin-5-line:before {
  content: "\ef10";
}

.ri-map-pin-add-fill:before {
  content: "\ef11";
}

.ri-map-pin-add-line:before {
  content: "\ef12";
}

.ri-map-pin-fill:before {
  content: "\ef13";
}

.ri-map-pin-line:before {
  content: "\ef14";
}

.ri-map-pin-range-fill:before {
  content: "\ef15";
}

.ri-map-pin-range-line:before {
  content: "\ef16";
}

.ri-map-pin-time-fill:before {
  content: "\ef17";
}

.ri-map-pin-time-line:before {
  content: "\ef18";
}

.ri-map-pin-user-fill:before {
  content: "\ef19";
}

.ri-map-pin-user-line:before {
  content: "\ef1a";
}

.ri-mark-pen-fill:before {
  content: "\ef1b";
}

.ri-mark-pen-line:before {
  content: "\ef1c";
}

.ri-markdown-fill:before {
  content: "\ef1d";
}

.ri-markdown-line:before {
  content: "\ef1e";
}

.ri-markup-fill:before {
  content: "\ef1f";
}

.ri-markup-line:before {
  content: "\ef20";
}

.ri-mastercard-fill:before {
  content: "\ef21";
}

.ri-mastercard-line:before {
  content: "\ef22";
}

.ri-mastodon-fill:before {
  content: "\ef23";
}

.ri-mastodon-line:before {
  content: "\ef24";
}

.ri-medal-2-fill:before {
  content: "\ef25";
}

.ri-medal-2-line:before {
  content: "\ef26";
}

.ri-medal-fill:before {
  content: "\ef27";
}

.ri-medal-line:before {
  content: "\ef28";
}

.ri-medicine-bottle-fill:before {
  content: "\ef29";
}

.ri-medicine-bottle-line:before {
  content: "\ef2a";
}

.ri-medium-fill:before {
  content: "\ef2b";
}

.ri-medium-line:before {
  content: "\ef2c";
}

.ri-men-fill:before {
  content: "\ef2d";
}

.ri-men-line:before {
  content: "\ef2e";
}

.ri-mental-health-fill:before {
  content: "\ef2f";
}

.ri-mental-health-line:before {
  content: "\ef30";
}

.ri-menu-2-fill:before {
  content: "\ef31";
}

.ri-menu-2-line:before {
  content: "\ef32";
}

.ri-menu-3-fill:before {
  content: "\ef33";
}

.ri-menu-3-line:before {
  content: "\ef34";
}

.ri-menu-4-fill:before {
  content: "\ef35";
}

.ri-menu-4-line:before {
  content: "\ef36";
}

.ri-menu-5-fill:before {
  content: "\ef37";
}

.ri-menu-5-line:before {
  content: "\ef38";
}

.ri-menu-add-fill:before {
  content: "\ef39";
}

.ri-menu-add-line:before {
  content: "\ef3a";
}

.ri-menu-fill:before {
  content: "\ef3b";
}

.ri-menu-fold-fill:before {
  content: "\ef3c";
}

.ri-menu-fold-line:before {
  content: "\ef3d";
}

.ri-menu-line:before {
  content: "\ef3e";
}

.ri-menu-unfold-fill:before {
  content: "\ef3f";
}

.ri-menu-unfold-line:before {
  content: "\ef40";
}

.ri-merge-cells-horizontal:before {
  content: "\ef41";
}

.ri-merge-cells-vertical:before {
  content: "\ef42";
}

.ri-message-2-fill:before {
  content: "\ef43";
}

.ri-message-2-line:before {
  content: "\ef44";
}

.ri-message-3-fill:before {
  content: "\ef45";
}

.ri-message-3-line:before {
  content: "\ef46";
}

.ri-message-fill:before {
  content: "\ef47";
}

.ri-message-line:before {
  content: "\ef48";
}

.ri-messenger-fill:before {
  content: "\ef49";
}

.ri-messenger-line:before {
  content: "\ef4a";
}

.ri-meteor-fill:before {
  content: "\ef4b";
}

.ri-meteor-line:before {
  content: "\ef4c";
}

.ri-mic-2-fill:before {
  content: "\ef4d";
}

.ri-mic-2-line:before {
  content: "\ef4e";
}

.ri-mic-fill:before {
  content: "\ef4f";
}

.ri-mic-line:before {
  content: "\ef50";
}

.ri-mic-off-fill:before {
  content: "\ef51";
}

.ri-mic-off-line:before {
  content: "\ef52";
}

.ri-mickey-fill:before {
  content: "\ef53";
}

.ri-mickey-line:before {
  content: "\ef54";
}

.ri-microscope-fill:before {
  content: "\ef55";
}

.ri-microscope-line:before {
  content: "\ef56";
}

.ri-microsoft-fill:before {
  content: "\ef57";
}

.ri-microsoft-line:before {
  content: "\ef58";
}

.ri-mind-map:before {
  content: "\ef59";
}

.ri-mini-program-fill:before {
  content: "\ef5a";
}

.ri-mini-program-line:before {
  content: "\ef5b";
}

.ri-mist-fill:before {
  content: "\ef5c";
}

.ri-mist-line:before {
  content: "\ef5d";
}

.ri-money-cny-box-fill:before {
  content: "\ef5e";
}

.ri-money-cny-box-line:before {
  content: "\ef5f";
}

.ri-money-cny-circle-fill:before {
  content: "\ef60";
}

.ri-money-cny-circle-line:before {
  content: "\ef61";
}

.ri-money-dollar-box-fill:before {
  content: "\ef62";
}

.ri-money-dollar-box-line:before {
  content: "\ef63";
}

.ri-money-dollar-circle-fill:before {
  content: "\ef64";
}

.ri-money-dollar-circle-line:before {
  content: "\ef65";
}

.ri-money-euro-box-fill:before {
  content: "\ef66";
}

.ri-money-euro-box-line:before {
  content: "\ef67";
}

.ri-money-euro-circle-fill:before {
  content: "\ef68";
}

.ri-money-euro-circle-line:before {
  content: "\ef69";
}

.ri-money-pound-box-fill:before {
  content: "\ef6a";
}

.ri-money-pound-box-line:before {
  content: "\ef6b";
}

.ri-money-pound-circle-fill:before {
  content: "\ef6c";
}

.ri-money-pound-circle-line:before {
  content: "\ef6d";
}

.ri-moon-clear-fill:before {
  content: "\ef6e";
}

.ri-moon-clear-line:before {
  content: "\ef6f";
}

.ri-moon-cloudy-fill:before {
  content: "\ef70";
}

.ri-moon-cloudy-line:before {
  content: "\ef71";
}

.ri-moon-fill:before {
  content: "\ef72";
}

.ri-moon-foggy-fill:before {
  content: "\ef73";
}

.ri-moon-foggy-line:before {
  content: "\ef74";
}

.ri-moon-line:before {
  content: "\ef75";
}

.ri-more-2-fill:before {
  content: "\ef76";
}

.ri-more-2-line:before {
  content: "\ef77";
}

.ri-more-fill:before {
  content: "\ef78";
}

.ri-more-line:before {
  content: "\ef79";
}

.ri-motorbike-fill:before {
  content: "\ef7a";
}

.ri-motorbike-line:before {
  content: "\ef7b";
}

.ri-mouse-fill:before {
  content: "\ef7c";
}

.ri-mouse-line:before {
  content: "\ef7d";
}

.ri-movie-2-fill:before {
  content: "\ef7e";
}

.ri-movie-2-line:before {
  content: "\ef7f";
}

.ri-movie-fill:before {
  content: "\ef80";
}

.ri-movie-line:before {
  content: "\ef81";
}

.ri-music-2-fill:before {
  content: "\ef82";
}

.ri-music-2-line:before {
  content: "\ef83";
}

.ri-music-fill:before {
  content: "\ef84";
}

.ri-music-line:before {
  content: "\ef85";
}

.ri-mv-fill:before {
  content: "\ef86";
}

.ri-mv-line:before {
  content: "\ef87";
}

.ri-navigation-fill:before {
  content: "\ef88";
}

.ri-navigation-line:before {
  content: "\ef89";
}

.ri-netease-cloud-music-fill:before {
  content: "\ef8a";
}

.ri-netease-cloud-music-line:before {
  content: "\ef8b";
}

.ri-netflix-fill:before {
  content: "\ef8c";
}

.ri-netflix-line:before {
  content: "\ef8d";
}

.ri-newspaper-fill:before {
  content: "\ef8e";
}

.ri-newspaper-line:before {
  content: "\ef8f";
}

.ri-node-tree:before {
  content: "\ef90";
}

.ri-notification-2-fill:before {
  content: "\ef91";
}

.ri-notification-2-line:before {
  content: "\ef92";
}

.ri-notification-3-fill:before {
  content: "\ef93";
}

.ri-notification-3-line:before {
  content: "\ef94";
}

.ri-notification-4-fill:before {
  content: "\ef95";
}

.ri-notification-4-line:before {
  content: "\ef96";
}

.ri-notification-badge-fill:before {
  content: "\ef97";
}

.ri-notification-badge-line:before {
  content: "\ef98";
}

.ri-notification-fill:before {
  content: "\ef99";
}

.ri-notification-line:before {
  content: "\ef9a";
}

.ri-notification-off-fill:before {
  content: "\ef9b";
}

.ri-notification-off-line:before {
  content: "\ef9c";
}

.ri-npmjs-fill:before {
  content: "\ef9d";
}

.ri-npmjs-line:before {
  content: "\ef9e";
}

.ri-number-0:before {
  content: "\ef9f";
}

.ri-number-1:before {
  content: "\efa0";
}

.ri-number-2:before {
  content: "\efa1";
}

.ri-number-3:before {
  content: "\efa2";
}

.ri-number-4:before {
  content: "\efa3";
}

.ri-number-5:before {
  content: "\efa4";
}

.ri-number-6:before {
  content: "\efa5";
}

.ri-number-7:before {
  content: "\efa6";
}

.ri-number-8:before {
  content: "\efa7";
}

.ri-number-9:before {
  content: "\efa8";
}

.ri-numbers-fill:before {
  content: "\efa9";
}

.ri-numbers-line:before {
  content: "\efaa";
}

.ri-nurse-fill:before {
  content: "\efab";
}

.ri-nurse-line:before {
  content: "\efac";
}

.ri-oil-fill:before {
  content: "\efad";
}

.ri-oil-line:before {
  content: "\efae";
}

.ri-omega:before {
  content: "\efaf";
}

.ri-open-arm-fill:before {
  content: "\efb0";
}

.ri-open-arm-line:before {
  content: "\efb1";
}

.ri-open-source-fill:before {
  content: "\efb2";
}

.ri-open-source-line:before {
  content: "\efb3";
}

.ri-opera-fill:before {
  content: "\efb4";
}

.ri-opera-line:before {
  content: "\efb5";
}

.ri-order-play-fill:before {
  content: "\efb6";
}

.ri-order-play-line:before {
  content: "\efb7";
}

.ri-organization-chart:before {
  content: "\efb8";
}

.ri-outlet-2-fill:before {
  content: "\efb9";
}

.ri-outlet-2-line:before {
  content: "\efba";
}

.ri-outlet-fill:before {
  content: "\efbb";
}

.ri-outlet-line:before {
  content: "\efbc";
}

.ri-page-separator:before {
  content: "\efbd";
}

.ri-pages-fill:before {
  content: "\efbe";
}

.ri-pages-line:before {
  content: "\efbf";
}

.ri-paint-brush-fill:before {
  content: "\efc0";
}

.ri-paint-brush-line:before {
  content: "\efc1";
}

.ri-paint-fill:before {
  content: "\efc2";
}

.ri-paint-line:before {
  content: "\efc3";
}

.ri-palette-fill:before {
  content: "\efc4";
}

.ri-palette-line:before {
  content: "\efc5";
}

.ri-pantone-fill:before {
  content: "\efc6";
}

.ri-pantone-line:before {
  content: "\efc7";
}

.ri-paragraph:before {
  content: "\efc8";
}

.ri-parent-fill:before {
  content: "\efc9";
}

.ri-parent-line:before {
  content: "\efca";
}

.ri-parentheses-fill:before {
  content: "\efcb";
}

.ri-parentheses-line:before {
  content: "\efcc";
}

.ri-parking-box-fill:before {
  content: "\efcd";
}

.ri-parking-box-line:before {
  content: "\efce";
}

.ri-parking-fill:before {
  content: "\efcf";
}

.ri-parking-line:before {
  content: "\efd0";
}

.ri-passport-fill:before {
  content: "\efd1";
}

.ri-passport-line:before {
  content: "\efd2";
}

.ri-patreon-fill:before {
  content: "\efd3";
}

.ri-patreon-line:before {
  content: "\efd4";
}

.ri-pause-circle-fill:before {
  content: "\efd5";
}

.ri-pause-circle-line:before {
  content: "\efd6";
}

.ri-pause-fill:before {
  content: "\efd7";
}

.ri-pause-line:before {
  content: "\efd8";
}

.ri-pause-mini-fill:before {
  content: "\efd9";
}

.ri-pause-mini-line:before {
  content: "\efda";
}

.ri-paypal-fill:before {
  content: "\efdb";
}

.ri-paypal-line:before {
  content: "\efdc";
}

.ri-pen-nib-fill:before {
  content: "\efdd";
}

.ri-pen-nib-line:before {
  content: "\efde";
}

.ri-pencil-fill:before {
  content: "\efdf";
}

.ri-pencil-line:before {
  content: "\efe0";
}

.ri-pencil-ruler-2-fill:before {
  content: "\efe1";
}

.ri-pencil-ruler-2-line:before {
  content: "\efe2";
}

.ri-pencil-ruler-fill:before {
  content: "\efe3";
}

.ri-pencil-ruler-line:before {
  content: "\efe4";
}

.ri-percent-fill:before {
  content: "\efe5";
}

.ri-percent-line:before {
  content: "\efe6";
}

.ri-phone-camera-fill:before {
  content: "\efe7";
}

.ri-phone-camera-line:before {
  content: "\efe8";
}

.ri-phone-fill:before {
  content: "\efe9";
}

.ri-phone-find-fill:before {
  content: "\efea";
}

.ri-phone-find-line:before {
  content: "\efeb";
}

.ri-phone-line:before {
  content: "\efec";
}

.ri-phone-lock-fill:before {
  content: "\efed";
}

.ri-phone-lock-line:before {
  content: "\efee";
}

.ri-picture-in-picture-2-fill:before {
  content: "\efef";
}

.ri-picture-in-picture-2-line:before {
  content: "\eff0";
}

.ri-picture-in-picture-exit-fill:before {
  content: "\eff1";
}

.ri-picture-in-picture-exit-line:before {
  content: "\eff2";
}

.ri-picture-in-picture-fill:before {
  content: "\eff3";
}

.ri-picture-in-picture-line:before {
  content: "\eff4";
}

.ri-pie-chart-2-fill:before {
  content: "\eff5";
}

.ri-pie-chart-2-line:before {
  content: "\eff6";
}

.ri-pie-chart-box-fill:before {
  content: "\eff7";
}

.ri-pie-chart-box-line:before {
  content: "\eff8";
}

.ri-pie-chart-fill:before {
  content: "\eff9";
}

.ri-pie-chart-line:before {
  content: "\effa";
}

.ri-pin-distance-fill:before {
  content: "\effb";
}

.ri-pin-distance-line:before {
  content: "\effc";
}

.ri-ping-pong-fill:before {
  content: "\effd";
}

.ri-ping-pong-line:before {
  content: "\effe";
}

.ri-pinterest-fill:before {
  content: "\efff";
}

.ri-pinterest-line:before {
  content: "\f000";
}

.ri-pinyin-input:before {
  content: "\f001";
}

.ri-pixelfed-fill:before {
  content: "\f002";
}

.ri-pixelfed-line:before {
  content: "\f003";
}

.ri-plane-fill:before {
  content: "\f004";
}

.ri-plane-line:before {
  content: "\f005";
}

.ri-plant-fill:before {
  content: "\f006";
}

.ri-plant-line:before {
  content: "\f007";
}

.ri-play-circle-fill:before {
  content: "\f008";
}

.ri-play-circle-line:before {
  content: "\f009";
}

.ri-play-fill:before {
  content: "\f00a";
}

.ri-play-line:before {
  content: "\f00b";
}

.ri-play-list-2-fill:before {
  content: "\f00c";
}

.ri-play-list-2-line:before {
  content: "\f00d";
}

.ri-play-list-add-fill:before {
  content: "\f00e";
}

.ri-play-list-add-line:before {
  content: "\f00f";
}

.ri-play-list-fill:before {
  content: "\f010";
}

.ri-play-list-line:before {
  content: "\f011";
}

.ri-play-mini-fill:before {
  content: "\f012";
}

.ri-play-mini-line:before {
  content: "\f013";
}

.ri-playstation-fill:before {
  content: "\f014";
}

.ri-playstation-line:before {
  content: "\f015";
}

.ri-plug-2-fill:before {
  content: "\f016";
}

.ri-plug-2-line:before {
  content: "\f017";
}

.ri-plug-fill:before {
  content: "\f018";
}

.ri-plug-line:before {
  content: "\f019";
}

.ri-polaroid-2-fill:before {
  content: "\f01a";
}

.ri-polaroid-2-line:before {
  content: "\f01b";
}

.ri-polaroid-fill:before {
  content: "\f01c";
}

.ri-polaroid-line:before {
  content: "\f01d";
}

.ri-police-car-fill:before {
  content: "\f01e";
}

.ri-police-car-line:before {
  content: "\f01f";
}

.ri-price-tag-2-fill:before {
  content: "\f020";
}

.ri-price-tag-2-line:before {
  content: "\f021";
}

.ri-price-tag-3-fill:before {
  content: "\f022";
}

.ri-price-tag-3-line:before {
  content: "\f023";
}

.ri-price-tag-fill:before {
  content: "\f024";
}

.ri-price-tag-line:before {
  content: "\f025";
}

.ri-printer-cloud-fill:before {
  content: "\f026";
}

.ri-printer-cloud-line:before {
  content: "\f027";
}

.ri-printer-fill:before {
  content: "\f028";
}

.ri-printer-line:before {
  content: "\f029";
}

.ri-product-hunt-fill:before {
  content: "\f02a";
}

.ri-product-hunt-line:before {
  content: "\f02b";
}

.ri-profile-fill:before {
  content: "\f02c";
}

.ri-profile-line:before {
  content: "\f02d";
}

.ri-projector-2-fill:before {
  content: "\f02e";
}

.ri-projector-2-line:before {
  content: "\f02f";
}

.ri-projector-fill:before {
  content: "\f030";
}

.ri-projector-line:before {
  content: "\f031";
}

.ri-psychotherapy-fill:before {
  content: "\f032";
}

.ri-psychotherapy-line:before {
  content: "\f033";
}

.ri-pulse-fill:before {
  content: "\f034";
}

.ri-pulse-line:before {
  content: "\f035";
}

.ri-pushpin-2-fill:before {
  content: "\f036";
}

.ri-pushpin-2-line:before {
  content: "\f037";
}

.ri-pushpin-fill:before {
  content: "\f038";
}

.ri-pushpin-line:before {
  content: "\f039";
}

.ri-qq-fill:before {
  content: "\f03a";
}

.ri-qq-line:before {
  content: "\f03b";
}

.ri-qr-code-fill:before {
  content: "\f03c";
}

.ri-qr-code-line:before {
  content: "\f03d";
}

.ri-qr-scan-2-fill:before {
  content: "\f03e";
}

.ri-qr-scan-2-line:before {
  content: "\f03f";
}

.ri-qr-scan-fill:before {
  content: "\f040";
}

.ri-qr-scan-line:before {
  content: "\f041";
}

.ri-question-answer-fill:before {
  content: "\f042";
}

.ri-question-answer-line:before {
  content: "\f043";
}

.ri-question-fill:before {
  content: "\f044";
}

.ri-question-line:before {
  content: "\f045";
}

.ri-question-mark:before {
  content: "\f046";
}

.ri-questionnaire-fill:before {
  content: "\f047";
}

.ri-questionnaire-line:before {
  content: "\f048";
}

.ri-quill-pen-fill:before {
  content: "\f049";
}

.ri-quill-pen-line:before {
  content: "\f04a";
}

.ri-radar-fill:before {
  content: "\f04b";
}

.ri-radar-line:before {
  content: "\f04c";
}

.ri-radio-2-fill:before {
  content: "\f04d";
}

.ri-radio-2-line:before {
  content: "\f04e";
}

.ri-radio-button-fill:before {
  content: "\f04f";
}

.ri-radio-button-line:before {
  content: "\f050";
}

.ri-radio-fill:before {
  content: "\f051";
}

.ri-radio-line:before {
  content: "\f052";
}

.ri-rainbow-fill:before {
  content: "\f053";
}

.ri-rainbow-line:before {
  content: "\f054";
}

.ri-rainy-fill:before {
  content: "\f055";
}

.ri-rainy-line:before {
  content: "\f056";
}

.ri-reactjs-fill:before {
  content: "\f057";
}

.ri-reactjs-line:before {
  content: "\f058";
}

.ri-record-circle-fill:before {
  content: "\f059";
}

.ri-record-circle-line:before {
  content: "\f05a";
}

.ri-record-mail-fill:before {
  content: "\f05b";
}

.ri-record-mail-line:before {
  content: "\f05c";
}

.ri-recycle-fill:before {
  content: "\f05d";
}

.ri-recycle-line:before {
  content: "\f05e";
}

.ri-red-packet-fill:before {
  content: "\f05f";
}

.ri-red-packet-line:before {
  content: "\f060";
}

.ri-reddit-fill:before {
  content: "\f061";
}

.ri-reddit-line:before {
  content: "\f062";
}

.ri-refresh-fill:before {
  content: "\f063";
}

.ri-refresh-line:before {
  content: "\f064";
}

.ri-refund-2-fill:before {
  content: "\f065";
}

.ri-refund-2-line:before {
  content: "\f066";
}

.ri-refund-fill:before {
  content: "\f067";
}

.ri-refund-line:before {
  content: "\f068";
}

.ri-registered-fill:before {
  content: "\f069";
}

.ri-registered-line:before {
  content: "\f06a";
}

.ri-remixicon-fill:before {
  content: "\f06b";
}

.ri-remixicon-line:before {
  content: "\f06c";
}

.ri-remote-control-2-fill:before {
  content: "\f06d";
}

.ri-remote-control-2-line:before {
  content: "\f06e";
}

.ri-remote-control-fill:before {
  content: "\f06f";
}

.ri-remote-control-line:before {
  content: "\f070";
}

.ri-repeat-2-fill:before {
  content: "\f071";
}

.ri-repeat-2-line:before {
  content: "\f072";
}

.ri-repeat-fill:before {
  content: "\f073";
}

.ri-repeat-line:before {
  content: "\f074";
}

.ri-repeat-one-fill:before {
  content: "\f075";
}

.ri-repeat-one-line:before {
  content: "\f076";
}

.ri-reply-all-fill:before {
  content: "\f077";
}

.ri-reply-all-line:before {
  content: "\f078";
}

.ri-reply-fill:before {
  content: "\f079";
}

.ri-reply-line:before {
  content: "\f07a";
}

.ri-reserved-fill:before {
  content: "\f07b";
}

.ri-reserved-line:before {
  content: "\f07c";
}

.ri-rest-time-fill:before {
  content: "\f07d";
}

.ri-rest-time-line:before {
  content: "\f07e";
}

.ri-restart-fill:before {
  content: "\f07f";
}

.ri-restart-line:before {
  content: "\f080";
}

.ri-restaurant-2-fill:before {
  content: "\f081";
}

.ri-restaurant-2-line:before {
  content: "\f082";
}

.ri-restaurant-fill:before {
  content: "\f083";
}

.ri-restaurant-line:before {
  content: "\f084";
}

.ri-rewind-fill:before {
  content: "\f085";
}

.ri-rewind-line:before {
  content: "\f086";
}

.ri-rewind-mini-fill:before {
  content: "\f087";
}

.ri-rewind-mini-line:before {
  content: "\f088";
}

.ri-rhythm-fill:before {
  content: "\f089";
}

.ri-rhythm-line:before {
  content: "\f08a";
}

.ri-riding-fill:before {
  content: "\f08b";
}

.ri-riding-line:before {
  content: "\f08c";
}

.ri-road-map-fill:before {
  content: "\f08d";
}

.ri-road-map-line:before {
  content: "\f08e";
}

.ri-roadster-fill:before {
  content: "\f08f";
}

.ri-roadster-line:before {
  content: "\f090";
}

.ri-robot-fill:before {
  content: "\f091";
}

.ri-robot-line:before {
  content: "\f092";
}

.ri-rocket-2-fill:before {
  content: "\f093";
}

.ri-rocket-2-line:before {
  content: "\f094";
}

.ri-rocket-fill:before {
  content: "\f095";
}

.ri-rocket-line:before {
  content: "\f096";
}

.ri-rotate-lock-fill:before {
  content: "\f097";
}

.ri-rotate-lock-line:before {
  content: "\f098";
}

.ri-rounded-corner:before {
  content: "\f099";
}

.ri-route-fill:before {
  content: "\f09a";
}

.ri-route-line:before {
  content: "\f09b";
}

.ri-router-fill:before {
  content: "\f09c";
}

.ri-router-line:before {
  content: "\f09d";
}

.ri-rss-fill:before {
  content: "\f09e";
}

.ri-rss-line:before {
  content: "\f09f";
}

.ri-ruler-2-fill:before {
  content: "\f0a0";
}

.ri-ruler-2-line:before {
  content: "\f0a1";
}

.ri-ruler-fill:before {
  content: "\f0a2";
}

.ri-ruler-line:before {
  content: "\f0a3";
}

.ri-run-fill:before {
  content: "\f0a4";
}

.ri-run-line:before {
  content: "\f0a5";
}

.ri-safari-fill:before {
  content: "\f0a6";
}

.ri-safari-line:before {
  content: "\f0a7";
}

.ri-safe-2-fill:before {
  content: "\f0a8";
}

.ri-safe-2-line:before {
  content: "\f0a9";
}

.ri-safe-fill:before {
  content: "\f0aa";
}

.ri-safe-line:before {
  content: "\f0ab";
}

.ri-sailboat-fill:before {
  content: "\f0ac";
}

.ri-sailboat-line:before {
  content: "\f0ad";
}

.ri-save-2-fill:before {
  content: "\f0ae";
}

.ri-save-2-line:before {
  content: "\f0af";
}

.ri-save-3-fill:before {
  content: "\f0b0";
}

.ri-save-3-line:before {
  content: "\f0b1";
}

.ri-save-fill:before {
  content: "\f0b2";
}

.ri-save-line:before {
  content: "\f0b3";
}

.ri-scales-2-fill:before {
  content: "\f0b4";
}

.ri-scales-2-line:before {
  content: "\f0b5";
}

.ri-scales-3-fill:before {
  content: "\f0b6";
}

.ri-scales-3-line:before {
  content: "\f0b7";
}

.ri-scales-fill:before {
  content: "\f0b8";
}

.ri-scales-line:before {
  content: "\f0b9";
}

.ri-scan-2-fill:before {
  content: "\f0ba";
}

.ri-scan-2-line:before {
  content: "\f0bb";
}

.ri-scan-fill:before {
  content: "\f0bc";
}

.ri-scan-line:before {
  content: "\f0bd";
}

.ri-scissors-2-fill:before {
  content: "\f0be";
}

.ri-scissors-2-line:before {
  content: "\f0bf";
}

.ri-scissors-cut-fill:before {
  content: "\f0c0";
}

.ri-scissors-cut-line:before {
  content: "\f0c1";
}

.ri-scissors-fill:before {
  content: "\f0c2";
}

.ri-scissors-line:before {
  content: "\f0c3";
}

.ri-screenshot-2-fill:before {
  content: "\f0c4";
}

.ri-screenshot-2-line:before {
  content: "\f0c5";
}

.ri-screenshot-fill:before {
  content: "\f0c6";
}

.ri-screenshot-line:before {
  content: "\f0c7";
}

.ri-sd-card-fill:before {
  content: "\f0c8";
}

.ri-sd-card-line:before {
  content: "\f0c9";
}

.ri-sd-card-mini-fill:before {
  content: "\f0ca";
}

.ri-sd-card-mini-line:before {
  content: "\f0cb";
}

.ri-search-2-fill:before {
  content: "\f0cc";
}

.ri-search-2-line:before {
  content: "\f0cd";
}

.ri-search-eye-fill:before {
  content: "\f0ce";
}

.ri-search-eye-line:before {
  content: "\f0cf";
}

.ri-search-fill:before {
  content: "\f0d0";
}

.ri-search-line:before {
  content: "\f0d1";
}

.ri-secure-payment-fill:before {
  content: "\f0d2";
}

.ri-secure-payment-line:before {
  content: "\f0d3";
}

.ri-seedling-fill:before {
  content: "\f0d4";
}

.ri-seedling-line:before {
  content: "\f0d5";
}

.ri-send-backward:before {
  content: "\f0d6";
}

.ri-send-plane-2-fill:before {
  content: "\f0d7";
}

.ri-send-plane-2-line:before {
  content: "\f0d8";
}

.ri-send-plane-fill:before {
  content: "\f0d9";
}

.ri-send-plane-line:before {
  content: "\f0da";
}

.ri-send-to-back:before {
  content: "\f0db";
}

.ri-sensor-fill:before {
  content: "\f0dc";
}

.ri-sensor-line:before {
  content: "\f0dd";
}

.ri-separator:before {
  content: "\f0de";
}

.ri-server-fill:before {
  content: "\f0df";
}

.ri-server-line:before {
  content: "\f0e0";
}

.ri-service-fill:before {
  content: "\f0e1";
}

.ri-service-line:before {
  content: "\f0e2";
}

.ri-settings-2-fill:before {
  content: "\f0e3";
}

.ri-settings-2-line:before {
  content: "\f0e4";
}

.ri-settings-3-fill:before {
  content: "\f0e5";
}

.ri-settings-3-line:before {
  content: "\f0e6";
}

.ri-settings-4-fill:before {
  content: "\f0e7";
}

.ri-settings-4-line:before {
  content: "\f0e8";
}

.ri-settings-5-fill:before {
  content: "\f0e9";
}

.ri-settings-5-line:before {
  content: "\f0ea";
}

.ri-settings-6-fill:before {
  content: "\f0eb";
}

.ri-settings-6-line:before {
  content: "\f0ec";
}

.ri-settings-fill:before {
  content: "\f0ed";
}

.ri-settings-line:before {
  content: "\f0ee";
}

.ri-shape-2-fill:before {
  content: "\f0ef";
}

.ri-shape-2-line:before {
  content: "\f0f0";
}

.ri-shape-fill:before {
  content: "\f0f1";
}

.ri-shape-line:before {
  content: "\f0f2";
}

.ri-share-box-fill:before {
  content: "\f0f3";
}

.ri-share-box-line:before {
  content: "\f0f4";
}

.ri-share-circle-fill:before {
  content: "\f0f5";
}

.ri-share-circle-line:before {
  content: "\f0f6";
}

.ri-share-fill:before {
  content: "\f0f7";
}

.ri-share-forward-2-fill:before {
  content: "\f0f8";
}

.ri-share-forward-2-line:before {
  content: "\f0f9";
}

.ri-share-forward-box-fill:before {
  content: "\f0fa";
}

.ri-share-forward-box-line:before {
  content: "\f0fb";
}

.ri-share-forward-fill:before {
  content: "\f0fc";
}

.ri-share-forward-line:before {
  content: "\f0fd";
}

.ri-share-line:before {
  content: "\f0fe";
}

.ri-shield-check-fill:before {
  content: "\f0ff";
}

.ri-shield-check-line:before {
  content: "\f100";
}

.ri-shield-cross-fill:before {
  content: "\f101";
}

.ri-shield-cross-line:before {
  content: "\f102";
}

.ri-shield-fill:before {
  content: "\f103";
}

.ri-shield-flash-fill:before {
  content: "\f104";
}

.ri-shield-flash-line:before {
  content: "\f105";
}

.ri-shield-keyhole-fill:before {
  content: "\f106";
}

.ri-shield-keyhole-line:before {
  content: "\f107";
}

.ri-shield-line:before {
  content: "\f108";
}

.ri-shield-star-fill:before {
  content: "\f109";
}

.ri-shield-star-line:before {
  content: "\f10a";
}

.ri-shield-user-fill:before {
  content: "\f10b";
}

.ri-shield-user-line:before {
  content: "\f10c";
}

.ri-ship-2-fill:before {
  content: "\f10d";
}

.ri-ship-2-line:before {
  content: "\f10e";
}

.ri-ship-fill:before {
  content: "\f10f";
}

.ri-ship-line:before {
  content: "\f110";
}

.ri-shirt-fill:before {
  content: "\f111";
}

.ri-shirt-line:before {
  content: "\f112";
}

.ri-shopping-bag-2-fill:before {
  content: "\f113";
}

.ri-shopping-bag-2-line:before {
  content: "\f114";
}

.ri-shopping-bag-3-fill:before {
  content: "\f115";
}

.ri-shopping-bag-3-line:before {
  content: "\f116";
}

.ri-shopping-bag-fill:before {
  content: "\f117";
}

.ri-shopping-bag-line:before {
  content: "\f118";
}

.ri-shopping-basket-2-fill:before {
  content: "\f119";
}

.ri-shopping-basket-2-line:before {
  content: "\f11a";
}

.ri-shopping-basket-fill:before {
  content: "\f11b";
}

.ri-shopping-basket-line:before {
  content: "\f11c";
}

.ri-shopping-cart-2-fill:before {
  content: "\f11d";
}

.ri-shopping-cart-2-line:before {
  content: "\f11e";
}

.ri-shopping-cart-fill:before {
  content: "\f11f";
}

.ri-shopping-cart-line:before {
  content: "\f120";
}

.ri-showers-fill:before {
  content: "\f121";
}

.ri-showers-line:before {
  content: "\f122";
}

.ri-shuffle-fill:before {
  content: "\f123";
}

.ri-shuffle-line:before {
  content: "\f124";
}

.ri-shut-down-fill:before {
  content: "\f125";
}

.ri-shut-down-line:before {
  content: "\f126";
}

.ri-side-bar-fill:before {
  content: "\f127";
}

.ri-side-bar-line:before {
  content: "\f128";
}

.ri-signal-tower-fill:before {
  content: "\f129";
}

.ri-signal-tower-line:before {
  content: "\f12a";
}

.ri-signal-wifi-1-fill:before {
  content: "\f12b";
}

.ri-signal-wifi-1-line:before {
  content: "\f12c";
}

.ri-signal-wifi-2-fill:before {
  content: "\f12d";
}

.ri-signal-wifi-2-line:before {
  content: "\f12e";
}

.ri-signal-wifi-3-fill:before {
  content: "\f12f";
}

.ri-signal-wifi-3-line:before {
  content: "\f130";
}

.ri-signal-wifi-error-fill:before {
  content: "\f131";
}

.ri-signal-wifi-error-line:before {
  content: "\f132";
}

.ri-signal-wifi-fill:before {
  content: "\f133";
}

.ri-signal-wifi-line:before {
  content: "\f134";
}

.ri-signal-wifi-off-fill:before {
  content: "\f135";
}

.ri-signal-wifi-off-line:before {
  content: "\f136";
}

.ri-sim-card-2-fill:before {
  content: "\f137";
}

.ri-sim-card-2-line:before {
  content: "\f138";
}

.ri-sim-card-fill:before {
  content: "\f139";
}

.ri-sim-card-line:before {
  content: "\f13a";
}

.ri-single-quotes-l:before {
  content: "\f13b";
}

.ri-single-quotes-r:before {
  content: "\f13c";
}

.ri-sip-fill:before {
  content: "\f13d";
}

.ri-sip-line:before {
  content: "\f13e";
}

.ri-skip-back-fill:before {
  content: "\f13f";
}

.ri-skip-back-line:before {
  content: "\f140";
}

.ri-skip-back-mini-fill:before {
  content: "\f141";
}

.ri-skip-back-mini-line:before {
  content: "\f142";
}

.ri-skip-forward-fill:before {
  content: "\f143";
}

.ri-skip-forward-line:before {
  content: "\f144";
}

.ri-skip-forward-mini-fill:before {
  content: "\f145";
}

.ri-skip-forward-mini-line:before {
  content: "\f146";
}

.ri-skull-2-fill:before {
  content: "\f147";
}

.ri-skull-2-line:before {
  content: "\f148";
}

.ri-skull-fill:before {
  content: "\f149";
}

.ri-skull-line:before {
  content: "\f14a";
}

.ri-skype-fill:before {
  content: "\f14b";
}

.ri-skype-line:before {
  content: "\f14c";
}

.ri-slack-fill:before {
  content: "\f14d";
}

.ri-slack-line:before {
  content: "\f14e";
}

.ri-slice-fill:before {
  content: "\f14f";
}

.ri-slice-line:before {
  content: "\f150";
}

.ri-slideshow-2-fill:before {
  content: "\f151";
}

.ri-slideshow-2-line:before {
  content: "\f152";
}

.ri-slideshow-3-fill:before {
  content: "\f153";
}

.ri-slideshow-3-line:before {
  content: "\f154";
}

.ri-slideshow-4-fill:before {
  content: "\f155";
}

.ri-slideshow-4-line:before {
  content: "\f156";
}

.ri-slideshow-fill:before {
  content: "\f157";
}

.ri-slideshow-line:before {
  content: "\f158";
}

.ri-smartphone-fill:before {
  content: "\f159";
}

.ri-smartphone-line:before {
  content: "\f15a";
}

.ri-snapchat-fill:before {
  content: "\f15b";
}

.ri-snapchat-line:before {
  content: "\f15c";
}

.ri-snowy-fill:before {
  content: "\f15d";
}

.ri-snowy-line:before {
  content: "\f15e";
}

.ri-sort-asc:before {
  content: "\f15f";
}

.ri-sort-desc:before {
  content: "\f160";
}

.ri-sound-module-fill:before {
  content: "\f161";
}

.ri-sound-module-line:before {
  content: "\f162";
}

.ri-soundcloud-fill:before {
  content: "\f163";
}

.ri-soundcloud-line:before {
  content: "\f164";
}

.ri-space-ship-fill:before {
  content: "\f165";
}

.ri-space-ship-line:before {
  content: "\f166";
}

.ri-space:before {
  content: "\f167";
}

.ri-spam-2-fill:before {
  content: "\f168";
}

.ri-spam-2-line:before {
  content: "\f169";
}

.ri-spam-3-fill:before {
  content: "\f16a";
}

.ri-spam-3-line:before {
  content: "\f16b";
}

.ri-spam-fill:before {
  content: "\f16c";
}

.ri-spam-line:before {
  content: "\f16d";
}

.ri-speaker-2-fill:before {
  content: "\f16e";
}

.ri-speaker-2-line:before {
  content: "\f16f";
}

.ri-speaker-3-fill:before {
  content: "\f170";
}

.ri-speaker-3-line:before {
  content: "\f171";
}

.ri-speaker-fill:before {
  content: "\f172";
}

.ri-speaker-line:before {
  content: "\f173";
}

.ri-spectrum-fill:before {
  content: "\f174";
}

.ri-spectrum-line:before {
  content: "\f175";
}

.ri-speed-fill:before {
  content: "\f176";
}

.ri-speed-line:before {
  content: "\f177";
}

.ri-speed-mini-fill:before {
  content: "\f178";
}

.ri-speed-mini-line:before {
  content: "\f179";
}

.ri-split-cells-horizontal:before {
  content: "\f17a";
}

.ri-split-cells-vertical:before {
  content: "\f17b";
}

.ri-spotify-fill:before {
  content: "\f17c";
}

.ri-spotify-line:before {
  content: "\f17d";
}

.ri-spy-fill:before {
  content: "\f17e";
}

.ri-spy-line:before {
  content: "\f17f";
}

.ri-stack-fill:before {
  content: "\f180";
}

.ri-stack-line:before {
  content: "\f181";
}

.ri-stack-overflow-fill:before {
  content: "\f182";
}

.ri-stack-overflow-line:before {
  content: "\f183";
}

.ri-stackshare-fill:before {
  content: "\f184";
}

.ri-stackshare-line:before {
  content: "\f185";
}

.ri-star-fill:before {
  content: "\f186";
}

.ri-star-half-fill:before {
  content: "\f187";
}

.ri-star-half-line:before {
  content: "\f188";
}

.ri-star-half-s-fill:before {
  content: "\f189";
}

.ri-star-half-s-line:before {
  content: "\f18a";
}

.ri-star-line:before {
  content: "\f18b";
}

.ri-star-s-fill:before {
  content: "\f18c";
}

.ri-star-s-line:before {
  content: "\f18d";
}

.ri-star-smile-fill:before {
  content: "\f18e";
}

.ri-star-smile-line:before {
  content: "\f18f";
}

.ri-steam-fill:before {
  content: "\f190";
}

.ri-steam-line:before {
  content: "\f191";
}

.ri-steering-2-fill:before {
  content: "\f192";
}

.ri-steering-2-line:before {
  content: "\f193";
}

.ri-steering-fill:before {
  content: "\f194";
}

.ri-steering-line:before {
  content: "\f195";
}

.ri-stethoscope-fill:before {
  content: "\f196";
}

.ri-stethoscope-line:before {
  content: "\f197";
}

.ri-sticky-note-2-fill:before {
  content: "\f198";
}

.ri-sticky-note-2-line:before {
  content: "\f199";
}

.ri-sticky-note-fill:before {
  content: "\f19a";
}

.ri-sticky-note-line:before {
  content: "\f19b";
}

.ri-stock-fill:before {
  content: "\f19c";
}

.ri-stock-line:before {
  content: "\f19d";
}

.ri-stop-circle-fill:before {
  content: "\f19e";
}

.ri-stop-circle-line:before {
  content: "\f19f";
}

.ri-stop-fill:before {
  content: "\f1a0";
}

.ri-stop-line:before {
  content: "\f1a1";
}

.ri-stop-mini-fill:before {
  content: "\f1a2";
}

.ri-stop-mini-line:before {
  content: "\f1a3";
}

.ri-store-2-fill:before {
  content: "\f1a4";
}

.ri-store-2-line:before {
  content: "\f1a5";
}

.ri-store-3-fill:before {
  content: "\f1a6";
}

.ri-store-3-line:before {
  content: "\f1a7";
}

.ri-store-fill:before {
  content: "\f1a8";
}

.ri-store-line:before {
  content: "\f1a9";
}

.ri-strikethrough-2:before {
  content: "\f1aa";
}

.ri-strikethrough:before {
  content: "\f1ab";
}

.ri-subscript-2:before {
  content: "\f1ac";
}

.ri-subscript:before {
  content: "\f1ad";
}

.ri-subtract-fill:before {
  content: "\f1ae";
}

.ri-subtract-line:before {
  content: "\f1af";
}

.ri-subway-fill:before {
  content: "\f1b0";
}

.ri-subway-line:before {
  content: "\f1b1";
}

.ri-subway-wifi-fill:before {
  content: "\f1b2";
}

.ri-subway-wifi-line:before {
  content: "\f1b3";
}

.ri-suitcase-2-fill:before {
  content: "\f1b4";
}

.ri-suitcase-2-line:before {
  content: "\f1b5";
}

.ri-suitcase-3-fill:before {
  content: "\f1b6";
}

.ri-suitcase-3-line:before {
  content: "\f1b7";
}

.ri-suitcase-fill:before {
  content: "\f1b8";
}

.ri-suitcase-line:before {
  content: "\f1b9";
}

.ri-sun-cloudy-fill:before {
  content: "\f1ba";
}

.ri-sun-cloudy-line:before {
  content: "\f1bb";
}

.ri-sun-fill:before {
  content: "\f1bc";
}

.ri-sun-foggy-fill:before {
  content: "\f1bd";
}

.ri-sun-foggy-line:before {
  content: "\f1be";
}

.ri-sun-line:before {
  content: "\f1bf";
}

.ri-superscript-2:before {
  content: "\f1c0";
}

.ri-superscript:before {
  content: "\f1c1";
}

.ri-surgical-mask-fill:before {
  content: "\f1c2";
}

.ri-surgical-mask-line:before {
  content: "\f1c3";
}

.ri-surround-sound-fill:before {
  content: "\f1c4";
}

.ri-surround-sound-line:before {
  content: "\f1c5";
}

.ri-survey-fill:before {
  content: "\f1c6";
}

.ri-survey-line:before {
  content: "\f1c7";
}

.ri-swap-box-fill:before {
  content: "\f1c8";
}

.ri-swap-box-line:before {
  content: "\f1c9";
}

.ri-swap-fill:before {
  content: "\f1ca";
}

.ri-swap-line:before {
  content: "\f1cb";
}

.ri-switch-fill:before {
  content: "\f1cc";
}

.ri-switch-line:before {
  content: "\f1cd";
}

.ri-sword-fill:before {
  content: "\f1ce";
}

.ri-sword-line:before {
  content: "\f1cf";
}

.ri-syringe-fill:before {
  content: "\f1d0";
}

.ri-syringe-line:before {
  content: "\f1d1";
}

.ri-t-box-fill:before {
  content: "\f1d2";
}

.ri-t-box-line:before {
  content: "\f1d3";
}

.ri-t-shirt-2-fill:before {
  content: "\f1d4";
}

.ri-t-shirt-2-line:before {
  content: "\f1d5";
}

.ri-t-shirt-air-fill:before {
  content: "\f1d6";
}

.ri-t-shirt-air-line:before {
  content: "\f1d7";
}

.ri-t-shirt-fill:before {
  content: "\f1d8";
}

.ri-t-shirt-line:before {
  content: "\f1d9";
}

.ri-table-2:before {
  content: "\f1da";
}

.ri-table-alt-fill:before {
  content: "\f1db";
}

.ri-table-alt-line:before {
  content: "\f1dc";
}

.ri-table-fill:before {
  content: "\f1dd";
}

.ri-table-line:before {
  content: "\f1de";
}

.ri-tablet-fill:before {
  content: "\f1df";
}

.ri-tablet-line:before {
  content: "\f1e0";
}

.ri-takeaway-fill:before {
  content: "\f1e1";
}

.ri-takeaway-line:before {
  content: "\f1e2";
}

.ri-taobao-fill:before {
  content: "\f1e3";
}

.ri-taobao-line:before {
  content: "\f1e4";
}

.ri-tape-fill:before {
  content: "\f1e5";
}

.ri-tape-line:before {
  content: "\f1e6";
}

.ri-task-fill:before {
  content: "\f1e7";
}

.ri-task-line:before {
  content: "\f1e8";
}

.ri-taxi-fill:before {
  content: "\f1e9";
}

.ri-taxi-line:before {
  content: "\f1ea";
}

.ri-taxi-wifi-fill:before {
  content: "\f1eb";
}

.ri-taxi-wifi-line:before {
  content: "\f1ec";
}

.ri-team-fill:before {
  content: "\f1ed";
}

.ri-team-line:before {
  content: "\f1ee";
}

.ri-telegram-fill:before {
  content: "\f1ef";
}

.ri-telegram-line:before {
  content: "\f1f0";
}

.ri-temp-cold-fill:before {
  content: "\f1f1";
}

.ri-temp-cold-line:before {
  content: "\f1f2";
}

.ri-temp-hot-fill:before {
  content: "\f1f3";
}

.ri-temp-hot-line:before {
  content: "\f1f4";
}

.ri-terminal-box-fill:before {
  content: "\f1f5";
}

.ri-terminal-box-line:before {
  content: "\f1f6";
}

.ri-terminal-fill:before {
  content: "\f1f7";
}

.ri-terminal-line:before {
  content: "\f1f8";
}

.ri-terminal-window-fill:before {
  content: "\f1f9";
}

.ri-terminal-window-line:before {
  content: "\f1fa";
}

.ri-test-tube-fill:before {
  content: "\f1fb";
}

.ri-test-tube-line:before {
  content: "\f1fc";
}

.ri-text-direction-l:before {
  content: "\f1fd";
}

.ri-text-direction-r:before {
  content: "\f1fe";
}

.ri-text-spacing:before {
  content: "\f1ff";
}

.ri-text-wrap:before {
  content: "\f200";
}

.ri-text:before {
  content: "\f201";
}

.ri-thermometer-fill:before {
  content: "\f202";
}

.ri-thermometer-line:before {
  content: "\f203";
}

.ri-thumb-down-fill:before {
  content: "\f204";
}

.ri-thumb-down-line:before {
  content: "\f205";
}

.ri-thumb-up-fill:before {
  content: "\f206";
}

.ri-thumb-up-line:before {
  content: "\f207";
}

.ri-thunderstorms-fill:before {
  content: "\f208";
}

.ri-thunderstorms-line:before {
  content: "\f209";
}

.ri-ticket-2-fill:before {
  content: "\f20a";
}

.ri-ticket-2-line:before {
  content: "\f20b";
}

.ri-ticket-fill:before {
  content: "\f20c";
}

.ri-ticket-line:before {
  content: "\f20d";
}

.ri-time-fill:before {
  content: "\f20e";
}

.ri-time-line:before {
  content: "\f20f";
}

.ri-timer-2-fill:before {
  content: "\f210";
}

.ri-timer-2-line:before {
  content: "\f211";
}

.ri-timer-fill:before {
  content: "\f212";
}

.ri-timer-flash-fill:before {
  content: "\f213";
}

.ri-timer-flash-line:before {
  content: "\f214";
}

.ri-timer-line:before {
  content: "\f215";
}

.ri-todo-fill:before {
  content: "\f216";
}

.ri-todo-line:before {
  content: "\f217";
}

.ri-toggle-fill:before {
  content: "\f218";
}

.ri-toggle-line:before {
  content: "\f219";
}

.ri-tools-fill:before {
  content: "\f21a";
}

.ri-tools-line:before {
  content: "\f21b";
}

.ri-tornado-fill:before {
  content: "\f21c";
}

.ri-tornado-line:before {
  content: "\f21d";
}

.ri-trademark-fill:before {
  content: "\f21e";
}

.ri-trademark-line:before {
  content: "\f21f";
}

.ri-traffic-light-fill:before {
  content: "\f220";
}

.ri-traffic-light-line:before {
  content: "\f221";
}

.ri-train-fill:before {
  content: "\f222";
}

.ri-train-line:before {
  content: "\f223";
}

.ri-train-wifi-fill:before {
  content: "\f224";
}

.ri-train-wifi-line:before {
  content: "\f225";
}

.ri-translate-2:before {
  content: "\f226";
}

.ri-translate:before {
  content: "\f227";
}

.ri-travesti-fill:before {
  content: "\f228";
}

.ri-travesti-line:before {
  content: "\f229";
}

.ri-treasure-map-fill:before {
  content: "\f22a";
}

.ri-treasure-map-line:before {
  content: "\f22b";
}

.ri-trello-fill:before {
  content: "\f22c";
}

.ri-trello-line:before {
  content: "\f22d";
}

.ri-trophy-fill:before {
  content: "\f22e";
}

.ri-trophy-line:before {
  content: "\f22f";
}

.ri-truck-fill:before {
  content: "\f230";
}

.ri-truck-line:before {
  content: "\f231";
}

.ri-tumblr-fill:before {
  content: "\f232";
}

.ri-tumblr-line:before {
  content: "\f233";
}

.ri-tv-2-fill:before {
  content: "\f234";
}

.ri-tv-2-line:before {
  content: "\f235";
}

.ri-tv-fill:before {
  content: "\f236";
}

.ri-tv-line:before {
  content: "\f237";
}

.ri-twitch-fill:before {
  content: "\f238";
}

.ri-twitch-line:before {
  content: "\f239";
}

.ri-twitter-fill:before {
  content: "\f23a";
}

.ri-twitter-line:before {
  content: "\f23b";
}

.ri-typhoon-fill:before {
  content: "\f23c";
}

.ri-typhoon-line:before {
  content: "\f23d";
}

.ri-u-disk-fill:before {
  content: "\f23e";
}

.ri-u-disk-line:before {
  content: "\f23f";
}

.ri-ubuntu-fill:before {
  content: "\f240";
}

.ri-ubuntu-line:before {
  content: "\f241";
}

.ri-umbrella-fill:before {
  content: "\f242";
}

.ri-umbrella-line:before {
  content: "\f243";
}

.ri-underline:before {
  content: "\f244";
}

.ri-uninstall-fill:before {
  content: "\f245";
}

.ri-uninstall-line:before {
  content: "\f246";
}

.ri-unsplash-fill:before {
  content: "\f247";
}

.ri-unsplash-line:before {
  content: "\f248";
}

.ri-upload-2-fill:before {
  content: "\f249";
}

.ri-upload-2-line:before {
  content: "\f24a";
}

.ri-upload-cloud-2-fill:before {
  content: "\f24b";
}

.ri-upload-cloud-2-line:before {
  content: "\f24c";
}

.ri-upload-cloud-fill:before {
  content: "\f24d";
}

.ri-upload-cloud-line:before {
  content: "\f24e";
}

.ri-upload-fill:before {
  content: "\f24f";
}

.ri-upload-line:before {
  content: "\f250";
}

.ri-usb-fill:before {
  content: "\f251";
}

.ri-usb-line:before {
  content: "\f252";
}

.ri-user-2-fill:before {
  content: "\f253";
}

.ri-user-2-line:before {
  content: "\f254";
}

.ri-user-3-fill:before {
  content: "\f255";
}

.ri-user-3-line:before {
  content: "\f256";
}

.ri-user-4-fill:before {
  content: "\f257";
}

.ri-user-4-line:before {
  content: "\f258";
}

.ri-user-5-fill:before {
  content: "\f259";
}

.ri-user-5-line:before {
  content: "\f25a";
}

.ri-user-6-fill:before {
  content: "\f25b";
}

.ri-user-6-line:before {
  content: "\f25c";
}

.ri-user-add-fill:before {
  content: "\f25d";
}

.ri-user-add-line:before {
  content: "\f25e";
}

.ri-user-fill:before {
  content: "\f25f";
}

.ri-user-follow-fill:before {
  content: "\f260";
}

.ri-user-follow-line:before {
  content: "\f261";
}

.ri-user-heart-fill:before {
  content: "\f262";
}

.ri-user-heart-line:before {
  content: "\f263";
}

.ri-user-line:before {
  content: "\f264";
}

.ri-user-location-fill:before {
  content: "\f265";
}

.ri-user-location-line:before {
  content: "\f266";
}

.ri-user-received-2-fill:before {
  content: "\f267";
}

.ri-user-received-2-line:before {
  content: "\f268";
}

.ri-user-received-fill:before {
  content: "\f269";
}

.ri-user-received-line:before {
  content: "\f26a";
}

.ri-user-search-fill:before {
  content: "\f26b";
}

.ri-user-search-line:before {
  content: "\f26c";
}

.ri-user-settings-fill:before {
  content: "\f26d";
}

.ri-user-settings-line:before {
  content: "\f26e";
}

.ri-user-shared-2-fill:before {
  content: "\f26f";
}

.ri-user-shared-2-line:before {
  content: "\f270";
}

.ri-user-shared-fill:before {
  content: "\f271";
}

.ri-user-shared-line:before {
  content: "\f272";
}

.ri-user-smile-fill:before {
  content: "\f273";
}

.ri-user-smile-line:before {
  content: "\f274";
}

.ri-user-star-fill:before {
  content: "\f275";
}

.ri-user-star-line:before {
  content: "\f276";
}

.ri-user-unfollow-fill:before {
  content: "\f277";
}

.ri-user-unfollow-line:before {
  content: "\f278";
}

.ri-user-voice-fill:before {
  content: "\f279";
}

.ri-user-voice-line:before {
  content: "\f27a";
}

.ri-video-add-fill:before {
  content: "\f27b";
}

.ri-video-add-line:before {
  content: "\f27c";
}

.ri-video-chat-fill:before {
  content: "\f27d";
}

.ri-video-chat-line:before {
  content: "\f27e";
}

.ri-video-download-fill:before {
  content: "\f27f";
}

.ri-video-download-line:before {
  content: "\f280";
}

.ri-video-fill:before {
  content: "\f281";
}

.ri-video-line:before {
  content: "\f282";
}

.ri-video-upload-fill:before {
  content: "\f283";
}

.ri-video-upload-line:before {
  content: "\f284";
}

.ri-vidicon-2-fill:before {
  content: "\f285";
}

.ri-vidicon-2-line:before {
  content: "\f286";
}

.ri-vidicon-fill:before {
  content: "\f287";
}

.ri-vidicon-line:before {
  content: "\f288";
}

.ri-vimeo-fill:before {
  content: "\f289";
}

.ri-vimeo-line:before {
  content: "\f28a";
}

.ri-vip-crown-2-fill:before {
  content: "\f28b";
}

.ri-vip-crown-2-line:before {
  content: "\f28c";
}

.ri-vip-crown-fill:before {
  content: "\f28d";
}

.ri-vip-crown-line:before {
  content: "\f28e";
}

.ri-vip-diamond-fill:before {
  content: "\f28f";
}

.ri-vip-diamond-line:before {
  content: "\f290";
}

.ri-vip-fill:before {
  content: "\f291";
}

.ri-vip-line:before {
  content: "\f292";
}

.ri-virus-fill:before {
  content: "\f293";
}

.ri-virus-line:before {
  content: "\f294";
}

.ri-visa-fill:before {
  content: "\f295";
}

.ri-visa-line:before {
  content: "\f296";
}

.ri-voice-recognition-fill:before {
  content: "\f297";
}

.ri-voice-recognition-line:before {
  content: "\f298";
}

.ri-voiceprint-fill:before {
  content: "\f299";
}

.ri-voiceprint-line:before {
  content: "\f29a";
}

.ri-volume-down-fill:before {
  content: "\f29b";
}

.ri-volume-down-line:before {
  content: "\f29c";
}

.ri-volume-mute-fill:before {
  content: "\f29d";
}

.ri-volume-mute-line:before {
  content: "\f29e";
}

.ri-volume-off-vibrate-fill:before {
  content: "\f29f";
}

.ri-volume-off-vibrate-line:before {
  content: "\f2a0";
}

.ri-volume-up-fill:before {
  content: "\f2a1";
}

.ri-volume-up-line:before {
  content: "\f2a2";
}

.ri-volume-vibrate-fill:before {
  content: "\f2a3";
}

.ri-volume-vibrate-line:before {
  content: "\f2a4";
}

.ri-vuejs-fill:before {
  content: "\f2a5";
}

.ri-vuejs-line:before {
  content: "\f2a6";
}

.ri-walk-fill:before {
  content: "\f2a7";
}

.ri-walk-line:before {
  content: "\f2a8";
}

.ri-wallet-2-fill:before {
  content: "\f2a9";
}

.ri-wallet-2-line:before {
  content: "\f2aa";
}

.ri-wallet-3-fill:before {
  content: "\f2ab";
}

.ri-wallet-3-line:before {
  content: "\f2ac";
}

.ri-wallet-fill:before {
  content: "\f2ad";
}

.ri-wallet-line:before {
  content: "\f2ae";
}

.ri-water-flash-fill:before {
  content: "\f2af";
}

.ri-water-flash-line:before {
  content: "\f2b0";
}

.ri-webcam-fill:before {
  content: "\f2b1";
}

.ri-webcam-line:before {
  content: "\f2b2";
}

.ri-wechat-2-fill:before {
  content: "\f2b3";
}

.ri-wechat-2-line:before {
  content: "\f2b4";
}

.ri-wechat-fill:before {
  content: "\f2b5";
}

.ri-wechat-line:before {
  content: "\f2b6";
}

.ri-wechat-pay-fill:before {
  content: "\f2b7";
}

.ri-wechat-pay-line:before {
  content: "\f2b8";
}

.ri-weibo-fill:before {
  content: "\f2b9";
}

.ri-weibo-line:before {
  content: "\f2ba";
}

.ri-whatsapp-fill:before {
  content: "\f2bb";
}

.ri-whatsapp-line:before {
  content: "\f2bc";
}

.ri-wheelchair-fill:before {
  content: "\f2bd";
}

.ri-wheelchair-line:before {
  content: "\f2be";
}

.ri-wifi-fill:before {
  content: "\f2bf";
}

.ri-wifi-line:before {
  content: "\f2c0";
}

.ri-wifi-off-fill:before {
  content: "\f2c1";
}

.ri-wifi-off-line:before {
  content: "\f2c2";
}

.ri-window-2-fill:before {
  content: "\f2c3";
}

.ri-window-2-line:before {
  content: "\f2c4";
}

.ri-window-fill:before {
  content: "\f2c5";
}

.ri-window-line:before {
  content: "\f2c6";
}

.ri-windows-fill:before {
  content: "\f2c7";
}

.ri-windows-line:before {
  content: "\f2c8";
}

.ri-windy-fill:before {
  content: "\f2c9";
}

.ri-windy-line:before {
  content: "\f2ca";
}

.ri-wireless-charging-fill:before {
  content: "\f2cb";
}

.ri-wireless-charging-line:before {
  content: "\f2cc";
}

.ri-women-fill:before {
  content: "\f2cd";
}

.ri-women-line:before {
  content: "\f2ce";
}

.ri-wubi-input:before {
  content: "\f2cf";
}

.ri-xbox-fill:before {
  content: "\f2d0";
}

.ri-xbox-line:before {
  content: "\f2d1";
}

.ri-xing-fill:before {
  content: "\f2d2";
}

.ri-xing-line:before {
  content: "\f2d3";
}

.ri-youtube-fill:before {
  content: "\f2d4";
}

.ri-youtube-line:before {
  content: "\f2d5";
}

.ri-zcool-fill:before {
  content: "\f2d6";
}

.ri-zcool-line:before {
  content: "\f2d7";
}

.ri-zhihu-fill:before {
  content: "\f2d8";
}

.ri-zhihu-line:before {
  content: "\f2d9";
}

.ri-zoom-in-fill:before {
  content: "\f2da";
}

.ri-zoom-in-line:before {
  content: "\f2db";
}

.ri-zoom-out-fill:before {
  content: "\f2dc";
}

.ri-zoom-out-line:before {
  content: "\f2dd";
}

.ri-zzz-fill:before {
  content: "\f2de";
}

.ri-zzz-line:before {
  content: "\f2df";
}

@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("../fonts/bootstrap-icons.woff2?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff2"), url("../fonts/bootstrap-icons.woff?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff");
}
.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-123::before {
  content: "\f67f";
}

.bi-alarm-fill::before {
  content: "\f101";
}

.bi-alarm::before {
  content: "\f102";
}

.bi-align-bottom::before {
  content: "\f103";
}

.bi-align-center::before {
  content: "\f104";
}

.bi-align-end::before {
  content: "\f105";
}

.bi-align-middle::before {
  content: "\f106";
}

.bi-align-start::before {
  content: "\f107";
}

.bi-align-top::before {
  content: "\f108";
}

.bi-alt::before {
  content: "\f109";
}

.bi-app-indicator::before {
  content: "\f10a";
}

.bi-app::before {
  content: "\f10b";
}

.bi-archive-fill::before {
  content: "\f10c";
}

.bi-archive::before {
  content: "\f10d";
}

.bi-arrow-90deg-down::before {
  content: "\f10e";
}

.bi-arrow-90deg-left::before {
  content: "\f10f";
}

.bi-arrow-90deg-right::before {
  content: "\f110";
}

.bi-arrow-90deg-up::before {
  content: "\f111";
}

.bi-arrow-bar-down::before {
  content: "\f112";
}

.bi-arrow-bar-left::before {
  content: "\f113";
}

.bi-arrow-bar-right::before {
  content: "\f114";
}

.bi-arrow-bar-up::before {
  content: "\f115";
}

.bi-arrow-clockwise::before {
  content: "\f116";
}

.bi-arrow-counterclockwise::before {
  content: "\f117";
}

.bi-arrow-down-circle-fill::before {
  content: "\f118";
}

.bi-arrow-down-circle::before {
  content: "\f119";
}

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a";
}

.bi-arrow-down-left-circle::before {
  content: "\f11b";
}

.bi-arrow-down-left-square-fill::before {
  content: "\f11c";
}

.bi-arrow-down-left-square::before {
  content: "\f11d";
}

.bi-arrow-down-left::before {
  content: "\f11e";
}

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f";
}

.bi-arrow-down-right-circle::before {
  content: "\f120";
}

.bi-arrow-down-right-square-fill::before {
  content: "\f121";
}

.bi-arrow-down-right-square::before {
  content: "\f122";
}

.bi-arrow-down-right::before {
  content: "\f123";
}

.bi-arrow-down-short::before {
  content: "\f124";
}

.bi-arrow-down-square-fill::before {
  content: "\f125";
}

.bi-arrow-down-square::before {
  content: "\f126";
}

.bi-arrow-down-up::before {
  content: "\f127";
}

.bi-arrow-down::before {
  content: "\f128";
}

.bi-arrow-left-circle-fill::before {
  content: "\f129";
}

.bi-arrow-left-circle::before {
  content: "\f12a";
}

.bi-arrow-left-right::before {
  content: "\f12b";
}

.bi-arrow-left-short::before {
  content: "\f12c";
}

.bi-arrow-left-square-fill::before {
  content: "\f12d";
}

.bi-arrow-left-square::before {
  content: "\f12e";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.bi-arrow-repeat::before {
  content: "\f130";
}

.bi-arrow-return-left::before {
  content: "\f131";
}

.bi-arrow-return-right::before {
  content: "\f132";
}

.bi-arrow-right-circle-fill::before {
  content: "\f133";
}

.bi-arrow-right-circle::before {
  content: "\f134";
}

.bi-arrow-right-short::before {
  content: "\f135";
}

.bi-arrow-right-square-fill::before {
  content: "\f136";
}

.bi-arrow-right-square::before {
  content: "\f137";
}

.bi-arrow-right::before {
  content: "\f138";
}

.bi-arrow-up-circle-fill::before {
  content: "\f139";
}

.bi-arrow-up-circle::before {
  content: "\f13a";
}

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b";
}

.bi-arrow-up-left-circle::before {
  content: "\f13c";
}

.bi-arrow-up-left-square-fill::before {
  content: "\f13d";
}

.bi-arrow-up-left-square::before {
  content: "\f13e";
}

.bi-arrow-up-left::before {
  content: "\f13f";
}

.bi-arrow-up-right-circle-fill::before {
  content: "\f140";
}

.bi-arrow-up-right-circle::before {
  content: "\f141";
}

.bi-arrow-up-right-square-fill::before {
  content: "\f142";
}

.bi-arrow-up-right-square::before {
  content: "\f143";
}

.bi-arrow-up-right::before {
  content: "\f144";
}

.bi-arrow-up-short::before {
  content: "\f145";
}

.bi-arrow-up-square-fill::before {
  content: "\f146";
}

.bi-arrow-up-square::before {
  content: "\f147";
}

.bi-arrow-up::before {
  content: "\f148";
}

.bi-arrows-angle-contract::before {
  content: "\f149";
}

.bi-arrows-angle-expand::before {
  content: "\f14a";
}

.bi-arrows-collapse::before {
  content: "\f14b";
}

.bi-arrows-expand::before {
  content: "\f14c";
}

.bi-arrows-fullscreen::before {
  content: "\f14d";
}

.bi-arrows-move::before {
  content: "\f14e";
}

.bi-aspect-ratio-fill::before {
  content: "\f14f";
}

.bi-aspect-ratio::before {
  content: "\f150";
}

.bi-asterisk::before {
  content: "\f151";
}

.bi-at::before {
  content: "\f152";
}

.bi-award-fill::before {
  content: "\f153";
}

.bi-award::before {
  content: "\f154";
}

.bi-back::before {
  content: "\f155";
}

.bi-backspace-fill::before {
  content: "\f156";
}

.bi-backspace-reverse-fill::before {
  content: "\f157";
}

.bi-backspace-reverse::before {
  content: "\f158";
}

.bi-backspace::before {
  content: "\f159";
}

.bi-badge-3d-fill::before {
  content: "\f15a";
}

.bi-badge-3d::before {
  content: "\f15b";
}

.bi-badge-4k-fill::before {
  content: "\f15c";
}

.bi-badge-4k::before {
  content: "\f15d";
}

.bi-badge-8k-fill::before {
  content: "\f15e";
}

.bi-badge-8k::before {
  content: "\f15f";
}

.bi-badge-ad-fill::before {
  content: "\f160";
}

.bi-badge-ad::before {
  content: "\f161";
}

.bi-badge-ar-fill::before {
  content: "\f162";
}

.bi-badge-ar::before {
  content: "\f163";
}

.bi-badge-cc-fill::before {
  content: "\f164";
}

.bi-badge-cc::before {
  content: "\f165";
}

.bi-badge-hd-fill::before {
  content: "\f166";
}

.bi-badge-hd::before {
  content: "\f167";
}

.bi-badge-tm-fill::before {
  content: "\f168";
}

.bi-badge-tm::before {
  content: "\f169";
}

.bi-badge-vo-fill::before {
  content: "\f16a";
}

.bi-badge-vo::before {
  content: "\f16b";
}

.bi-badge-vr-fill::before {
  content: "\f16c";
}

.bi-badge-vr::before {
  content: "\f16d";
}

.bi-badge-wc-fill::before {
  content: "\f16e";
}

.bi-badge-wc::before {
  content: "\f16f";
}

.bi-bag-check-fill::before {
  content: "\f170";
}

.bi-bag-check::before {
  content: "\f171";
}

.bi-bag-dash-fill::before {
  content: "\f172";
}

.bi-bag-dash::before {
  content: "\f173";
}

.bi-bag-fill::before {
  content: "\f174";
}

.bi-bag-plus-fill::before {
  content: "\f175";
}

.bi-bag-plus::before {
  content: "\f176";
}

.bi-bag-x-fill::before {
  content: "\f177";
}

.bi-bag-x::before {
  content: "\f178";
}

.bi-bag::before {
  content: "\f179";
}

.bi-bar-chart-fill::before {
  content: "\f17a";
}

.bi-bar-chart-line-fill::before {
  content: "\f17b";
}

.bi-bar-chart-line::before {
  content: "\f17c";
}

.bi-bar-chart-steps::before {
  content: "\f17d";
}

.bi-bar-chart::before {
  content: "\f17e";
}

.bi-basket-fill::before {
  content: "\f17f";
}

.bi-basket::before {
  content: "\f180";
}

.bi-basket2-fill::before {
  content: "\f181";
}

.bi-basket2::before {
  content: "\f182";
}

.bi-basket3-fill::before {
  content: "\f183";
}

.bi-basket3::before {
  content: "\f184";
}

.bi-battery-charging::before {
  content: "\f185";
}

.bi-battery-full::before {
  content: "\f186";
}

.bi-battery-half::before {
  content: "\f187";
}

.bi-battery::before {
  content: "\f188";
}

.bi-bell-fill::before {
  content: "\f189";
}

.bi-bell::before {
  content: "\f18a";
}

.bi-bezier::before {
  content: "\f18b";
}

.bi-bezier2::before {
  content: "\f18c";
}

.bi-bicycle::before {
  content: "\f18d";
}

.bi-binoculars-fill::before {
  content: "\f18e";
}

.bi-binoculars::before {
  content: "\f18f";
}

.bi-blockquote-left::before {
  content: "\f190";
}

.bi-blockquote-right::before {
  content: "\f191";
}

.bi-book-fill::before {
  content: "\f192";
}

.bi-book-half::before {
  content: "\f193";
}

.bi-book::before {
  content: "\f194";
}

.bi-bookmark-check-fill::before {
  content: "\f195";
}

.bi-bookmark-check::before {
  content: "\f196";
}

.bi-bookmark-dash-fill::before {
  content: "\f197";
}

.bi-bookmark-dash::before {
  content: "\f198";
}

.bi-bookmark-fill::before {
  content: "\f199";
}

.bi-bookmark-heart-fill::before {
  content: "\f19a";
}

.bi-bookmark-heart::before {
  content: "\f19b";
}

.bi-bookmark-plus-fill::before {
  content: "\f19c";
}

.bi-bookmark-plus::before {
  content: "\f19d";
}

.bi-bookmark-star-fill::before {
  content: "\f19e";
}

.bi-bookmark-star::before {
  content: "\f19f";
}

.bi-bookmark-x-fill::before {
  content: "\f1a0";
}

.bi-bookmark-x::before {
  content: "\f1a1";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-bookmarks-fill::before {
  content: "\f1a3";
}

.bi-bookmarks::before {
  content: "\f1a4";
}

.bi-bookshelf::before {
  content: "\f1a5";
}

.bi-bootstrap-fill::before {
  content: "\f1a6";
}

.bi-bootstrap-reboot::before {
  content: "\f1a7";
}

.bi-bootstrap::before {
  content: "\f1a8";
}

.bi-border-all::before {
  content: "\f1a9";
}

.bi-border-bottom::before {
  content: "\f1aa";
}

.bi-border-center::before {
  content: "\f1ab";
}

.bi-border-inner::before {
  content: "\f1ac";
}

.bi-border-left::before {
  content: "\f1ad";
}

.bi-border-middle::before {
  content: "\f1ae";
}

.bi-border-outer::before {
  content: "\f1af";
}

.bi-border-right::before {
  content: "\f1b0";
}

.bi-border-style::before {
  content: "\f1b1";
}

.bi-border-top::before {
  content: "\f1b2";
}

.bi-border-width::before {
  content: "\f1b3";
}

.bi-border::before {
  content: "\f1b4";
}

.bi-bounding-box-circles::before {
  content: "\f1b5";
}

.bi-bounding-box::before {
  content: "\f1b6";
}

.bi-box-arrow-down-left::before {
  content: "\f1b7";
}

.bi-box-arrow-down-right::before {
  content: "\f1b8";
}

.bi-box-arrow-down::before {
  content: "\f1b9";
}

.bi-box-arrow-in-down-left::before {
  content: "\f1ba";
}

.bi-box-arrow-in-down-right::before {
  content: "\f1bb";
}

.bi-box-arrow-in-down::before {
  content: "\f1bc";
}

.bi-box-arrow-in-left::before {
  content: "\f1bd";
}

.bi-box-arrow-in-right::before {
  content: "\f1be";
}

.bi-box-arrow-in-up-left::before {
  content: "\f1bf";
}

.bi-box-arrow-in-up-right::before {
  content: "\f1c0";
}

.bi-box-arrow-in-up::before {
  content: "\f1c1";
}

.bi-box-arrow-left::before {
  content: "\f1c2";
}

.bi-box-arrow-right::before {
  content: "\f1c3";
}

.bi-box-arrow-up-left::before {
  content: "\f1c4";
}

.bi-box-arrow-up-right::before {
  content: "\f1c5";
}

.bi-box-arrow-up::before {
  content: "\f1c6";
}

.bi-box-seam::before {
  content: "\f1c7";
}

.bi-box::before {
  content: "\f1c8";
}

.bi-braces::before {
  content: "\f1c9";
}

.bi-bricks::before {
  content: "\f1ca";
}

.bi-briefcase-fill::before {
  content: "\f1cb";
}

.bi-briefcase::before {
  content: "\f1cc";
}

.bi-brightness-alt-high-fill::before {
  content: "\f1cd";
}

.bi-brightness-alt-high::before {
  content: "\f1ce";
}

.bi-brightness-alt-low-fill::before {
  content: "\f1cf";
}

.bi-brightness-alt-low::before {
  content: "\f1d0";
}

.bi-brightness-high-fill::before {
  content: "\f1d1";
}

.bi-brightness-high::before {
  content: "\f1d2";
}

.bi-brightness-low-fill::before {
  content: "\f1d3";
}

.bi-brightness-low::before {
  content: "\f1d4";
}

.bi-broadcast-pin::before {
  content: "\f1d5";
}

.bi-broadcast::before {
  content: "\f1d6";
}

.bi-brush-fill::before {
  content: "\f1d7";
}

.bi-brush::before {
  content: "\f1d8";
}

.bi-bucket-fill::before {
  content: "\f1d9";
}

.bi-bucket::before {
  content: "\f1da";
}

.bi-bug-fill::before {
  content: "\f1db";
}

.bi-bug::before {
  content: "\f1dc";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-bullseye::before {
  content: "\f1de";
}

.bi-calculator-fill::before {
  content: "\f1df";
}

.bi-calculator::before {
  content: "\f1e0";
}

.bi-calendar-check-fill::before {
  content: "\f1e1";
}

.bi-calendar-check::before {
  content: "\f1e2";
}

.bi-calendar-date-fill::before {
  content: "\f1e3";
}

.bi-calendar-date::before {
  content: "\f1e4";
}

.bi-calendar-day-fill::before {
  content: "\f1e5";
}

.bi-calendar-day::before {
  content: "\f1e6";
}

.bi-calendar-event-fill::before {
  content: "\f1e7";
}

.bi-calendar-event::before {
  content: "\f1e8";
}

.bi-calendar-fill::before {
  content: "\f1e9";
}

.bi-calendar-minus-fill::before {
  content: "\f1ea";
}

.bi-calendar-minus::before {
  content: "\f1eb";
}

.bi-calendar-month-fill::before {
  content: "\f1ec";
}

.bi-calendar-month::before {
  content: "\f1ed";
}

.bi-calendar-plus-fill::before {
  content: "\f1ee";
}

.bi-calendar-plus::before {
  content: "\f1ef";
}

.bi-calendar-range-fill::before {
  content: "\f1f0";
}

.bi-calendar-range::before {
  content: "\f1f1";
}

.bi-calendar-week-fill::before {
  content: "\f1f2";
}

.bi-calendar-week::before {
  content: "\f1f3";
}

.bi-calendar-x-fill::before {
  content: "\f1f4";
}

.bi-calendar-x::before {
  content: "\f1f5";
}

.bi-calendar::before {
  content: "\f1f6";
}

.bi-calendar2-check-fill::before {
  content: "\f1f7";
}

.bi-calendar2-check::before {
  content: "\f1f8";
}

.bi-calendar2-date-fill::before {
  content: "\f1f9";
}

.bi-calendar2-date::before {
  content: "\f1fa";
}

.bi-calendar2-day-fill::before {
  content: "\f1fb";
}

.bi-calendar2-day::before {
  content: "\f1fc";
}

.bi-calendar2-event-fill::before {
  content: "\f1fd";
}

.bi-calendar2-event::before {
  content: "\f1fe";
}

.bi-calendar2-fill::before {
  content: "\f1ff";
}

.bi-calendar2-minus-fill::before {
  content: "\f200";
}

.bi-calendar2-minus::before {
  content: "\f201";
}

.bi-calendar2-month-fill::before {
  content: "\f202";
}

.bi-calendar2-month::before {
  content: "\f203";
}

.bi-calendar2-plus-fill::before {
  content: "\f204";
}

.bi-calendar2-plus::before {
  content: "\f205";
}

.bi-calendar2-range-fill::before {
  content: "\f206";
}

.bi-calendar2-range::before {
  content: "\f207";
}

.bi-calendar2-week-fill::before {
  content: "\f208";
}

.bi-calendar2-week::before {
  content: "\f209";
}

.bi-calendar2-x-fill::before {
  content: "\f20a";
}

.bi-calendar2-x::before {
  content: "\f20b";
}

.bi-calendar2::before {
  content: "\f20c";
}

.bi-calendar3-event-fill::before {
  content: "\f20d";
}

.bi-calendar3-event::before {
  content: "\f20e";
}

.bi-calendar3-fill::before {
  content: "\f20f";
}

.bi-calendar3-range-fill::before {
  content: "\f210";
}

.bi-calendar3-range::before {
  content: "\f211";
}

.bi-calendar3-week-fill::before {
  content: "\f212";
}

.bi-calendar3-week::before {
  content: "\f213";
}

.bi-calendar3::before {
  content: "\f214";
}

.bi-calendar4-event::before {
  content: "\f215";
}

.bi-calendar4-range::before {
  content: "\f216";
}

.bi-calendar4-week::before {
  content: "\f217";
}

.bi-calendar4::before {
  content: "\f218";
}

.bi-camera-fill::before {
  content: "\f219";
}

.bi-camera-reels-fill::before {
  content: "\f21a";
}

.bi-camera-reels::before {
  content: "\f21b";
}

.bi-camera-video-fill::before {
  content: "\f21c";
}

.bi-camera-video-off-fill::before {
  content: "\f21d";
}

.bi-camera-video-off::before {
  content: "\f21e";
}

.bi-camera-video::before {
  content: "\f21f";
}

.bi-camera::before {
  content: "\f220";
}

.bi-camera2::before {
  content: "\f221";
}

.bi-capslock-fill::before {
  content: "\f222";
}

.bi-capslock::before {
  content: "\f223";
}

.bi-card-checklist::before {
  content: "\f224";
}

.bi-card-heading::before {
  content: "\f225";
}

.bi-card-image::before {
  content: "\f226";
}

.bi-card-list::before {
  content: "\f227";
}

.bi-card-text::before {
  content: "\f228";
}

.bi-caret-down-fill::before {
  content: "\f229";
}

.bi-caret-down-square-fill::before {
  content: "\f22a";
}

.bi-caret-down-square::before {
  content: "\f22b";
}

.bi-caret-down::before {
  content: "\f22c";
}

.bi-caret-left-fill::before {
  content: "\f22d";
}

.bi-caret-left-square-fill::before {
  content: "\f22e";
}

.bi-caret-left-square::before {
  content: "\f22f";
}

.bi-caret-left::before {
  content: "\f230";
}

.bi-caret-right-fill::before {
  content: "\f231";
}

.bi-caret-right-square-fill::before {
  content: "\f232";
}

.bi-caret-right-square::before {
  content: "\f233";
}

.bi-caret-right::before {
  content: "\f234";
}

.bi-caret-up-fill::before {
  content: "\f235";
}

.bi-caret-up-square-fill::before {
  content: "\f236";
}

.bi-caret-up-square::before {
  content: "\f237";
}

.bi-caret-up::before {
  content: "\f238";
}

.bi-cart-check-fill::before {
  content: "\f239";
}

.bi-cart-check::before {
  content: "\f23a";
}

.bi-cart-dash-fill::before {
  content: "\f23b";
}

.bi-cart-dash::before {
  content: "\f23c";
}

.bi-cart-fill::before {
  content: "\f23d";
}

.bi-cart-plus-fill::before {
  content: "\f23e";
}

.bi-cart-plus::before {
  content: "\f23f";
}

.bi-cart-x-fill::before {
  content: "\f240";
}

.bi-cart-x::before {
  content: "\f241";
}

.bi-cart::before {
  content: "\f242";
}

.bi-cart2::before {
  content: "\f243";
}

.bi-cart3::before {
  content: "\f244";
}

.bi-cart4::before {
  content: "\f245";
}

.bi-cash-stack::before {
  content: "\f246";
}

.bi-cash::before {
  content: "\f247";
}

.bi-cast::before {
  content: "\f248";
}

.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}

.bi-chat-fill::before {
  content: "\f24b";
}

.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}

.bi-chat-left-fill::before {
  content: "\f24e";
}

.bi-chat-left-quote-fill::before {
  content: "\f24f";
}

.bi-chat-left-quote::before {
  content: "\f250";
}

.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}

.bi-chat-left::before {
  content: "\f253";
}

.bi-chat-quote-fill::before {
  content: "\f254";
}

.bi-chat-quote::before {
  content: "\f255";
}

.bi-chat-right-dots-fill::before {
  content: "\f256";
}

.bi-chat-right-dots::before {
  content: "\f257";
}

.bi-chat-right-fill::before {
  content: "\f258";
}

.bi-chat-right-quote-fill::before {
  content: "\f259";
}

.bi-chat-right-quote::before {
  content: "\f25a";
}

.bi-chat-right-text-fill::before {
  content: "\f25b";
}

.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-chat-right::before {
  content: "\f25d";
}

.bi-chat-square-dots-fill::before {
  content: "\f25e";
}

.bi-chat-square-dots::before {
  content: "\f25f";
}

.bi-chat-square-fill::before {
  content: "\f260";
}

.bi-chat-square-quote-fill::before {
  content: "\f261";
}

.bi-chat-square-quote::before {
  content: "\f262";
}

.bi-chat-square-text-fill::before {
  content: "\f263";
}

.bi-chat-square-text::before {
  content: "\f264";
}

.bi-chat-square::before {
  content: "\f265";
}

.bi-chat-text-fill::before {
  content: "\f266";
}

.bi-chat-text::before {
  content: "\f267";
}

.bi-chat::before {
  content: "\f268";
}

.bi-check-all::before {
  content: "\f269";
}

.bi-check-circle-fill::before {
  content: "\f26a";
}

.bi-check-circle::before {
  content: "\f26b";
}

.bi-check-square-fill::before {
  content: "\f26c";
}

.bi-check-square::before {
  content: "\f26d";
}

.bi-check::before {
  content: "\f26e";
}

.bi-check2-all::before {
  content: "\f26f";
}

.bi-check2-circle::before {
  content: "\f270";
}

.bi-check2-square::before {
  content: "\f271";
}

.bi-check2::before {
  content: "\f272";
}

.bi-chevron-bar-contract::before {
  content: "\f273";
}

.bi-chevron-bar-down::before {
  content: "\f274";
}

.bi-chevron-bar-expand::before {
  content: "\f275";
}

.bi-chevron-bar-left::before {
  content: "\f276";
}

.bi-chevron-bar-right::before {
  content: "\f277";
}

.bi-chevron-bar-up::before {
  content: "\f278";
}

.bi-chevron-compact-down::before {
  content: "\f279";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-chevron-compact-right::before {
  content: "\f27b";
}

.bi-chevron-compact-up::before {
  content: "\f27c";
}

.bi-chevron-contract::before {
  content: "\f27d";
}

.bi-chevron-double-down::before {
  content: "\f27e";
}

.bi-chevron-double-left::before {
  content: "\f27f";
}

.bi-chevron-double-right::before {
  content: "\f280";
}

.bi-chevron-double-up::before {
  content: "\f281";
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-expand::before {
  content: "\f283";
}

.bi-chevron-left::before {
  content: "\f284";
}

.bi-chevron-right::before {
  content: "\f285";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle-fill::before {
  content: "\f287";
}

.bi-circle-half::before {
  content: "\f288";
}

.bi-circle-square::before {
  content: "\f289";
}

.bi-circle::before {
  content: "\f28a";
}

.bi-clipboard-check::before {
  content: "\f28b";
}

.bi-clipboard-data::before {
  content: "\f28c";
}

.bi-clipboard-minus::before {
  content: "\f28d";
}

.bi-clipboard-plus::before {
  content: "\f28e";
}

.bi-clipboard-x::before {
  content: "\f28f";
}

.bi-clipboard::before {
  content: "\f290";
}

.bi-clock-fill::before {
  content: "\f291";
}

.bi-clock-history::before {
  content: "\f292";
}

.bi-clock::before {
  content: "\f293";
}

.bi-cloud-arrow-down-fill::before {
  content: "\f294";
}

.bi-cloud-arrow-down::before {
  content: "\f295";
}

.bi-cloud-arrow-up-fill::before {
  content: "\f296";
}

.bi-cloud-arrow-up::before {
  content: "\f297";
}

.bi-cloud-check-fill::before {
  content: "\f298";
}

.bi-cloud-check::before {
  content: "\f299";
}

.bi-cloud-download-fill::before {
  content: "\f29a";
}

.bi-cloud-download::before {
  content: "\f29b";
}

.bi-cloud-drizzle-fill::before {
  content: "\f29c";
}

.bi-cloud-drizzle::before {
  content: "\f29d";
}

.bi-cloud-fill::before {
  content: "\f29e";
}

.bi-cloud-fog-fill::before {
  content: "\f29f";
}

.bi-cloud-fog::before {
  content: "\f2a0";
}

.bi-cloud-fog2-fill::before {
  content: "\f2a1";
}

.bi-cloud-fog2::before {
  content: "\f2a2";
}

.bi-cloud-hail-fill::before {
  content: "\f2a3";
}

.bi-cloud-hail::before {
  content: "\f2a4";
}

.bi-cloud-haze-1::before {
  content: "\f2a5";
}

.bi-cloud-haze-fill::before {
  content: "\f2a6";
}

.bi-cloud-haze::before {
  content: "\f2a7";
}

.bi-cloud-haze2-fill::before {
  content: "\f2a8";
}

.bi-cloud-lightning-fill::before {
  content: "\f2a9";
}

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa";
}

.bi-cloud-lightning-rain::before {
  content: "\f2ab";
}

.bi-cloud-lightning::before {
  content: "\f2ac";
}

.bi-cloud-minus-fill::before {
  content: "\f2ad";
}

.bi-cloud-minus::before {
  content: "\f2ae";
}

.bi-cloud-moon-fill::before {
  content: "\f2af";
}

.bi-cloud-moon::before {
  content: "\f2b0";
}

.bi-cloud-plus-fill::before {
  content: "\f2b1";
}

.bi-cloud-plus::before {
  content: "\f2b2";
}

.bi-cloud-rain-fill::before {
  content: "\f2b3";
}

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4";
}

.bi-cloud-rain-heavy::before {
  content: "\f2b5";
}

.bi-cloud-rain::before {
  content: "\f2b6";
}

.bi-cloud-slash-fill::before {
  content: "\f2b7";
}

.bi-cloud-slash::before {
  content: "\f2b8";
}

.bi-cloud-sleet-fill::before {
  content: "\f2b9";
}

.bi-cloud-sleet::before {
  content: "\f2ba";
}

.bi-cloud-snow-fill::before {
  content: "\f2bb";
}

.bi-cloud-snow::before {
  content: "\f2bc";
}

.bi-cloud-sun-fill::before {
  content: "\f2bd";
}

.bi-cloud-sun::before {
  content: "\f2be";
}

.bi-cloud-upload-fill::before {
  content: "\f2bf";
}

.bi-cloud-upload::before {
  content: "\f2c0";
}

.bi-cloud::before {
  content: "\f2c1";
}

.bi-clouds-fill::before {
  content: "\f2c2";
}

.bi-clouds::before {
  content: "\f2c3";
}

.bi-cloudy-fill::before {
  content: "\f2c4";
}

.bi-cloudy::before {
  content: "\f2c5";
}

.bi-code-slash::before {
  content: "\f2c6";
}

.bi-code-square::before {
  content: "\f2c7";
}

.bi-code::before {
  content: "\f2c8";
}

.bi-collection-fill::before {
  content: "\f2c9";
}

.bi-collection-play-fill::before {
  content: "\f2ca";
}

.bi-collection-play::before {
  content: "\f2cb";
}

.bi-collection::before {
  content: "\f2cc";
}

.bi-columns-gap::before {
  content: "\f2cd";
}

.bi-columns::before {
  content: "\f2ce";
}

.bi-command::before {
  content: "\f2cf";
}

.bi-compass-fill::before {
  content: "\f2d0";
}

.bi-compass::before {
  content: "\f2d1";
}

.bi-cone-striped::before {
  content: "\f2d2";
}

.bi-cone::before {
  content: "\f2d3";
}

.bi-controller::before {
  content: "\f2d4";
}

.bi-cpu-fill::before {
  content: "\f2d5";
}

.bi-cpu::before {
  content: "\f2d6";
}

.bi-credit-card-2-back-fill::before {
  content: "\f2d7";
}

.bi-credit-card-2-back::before {
  content: "\f2d8";
}

.bi-credit-card-2-front-fill::before {
  content: "\f2d9";
}

.bi-credit-card-2-front::before {
  content: "\f2da";
}

.bi-credit-card-fill::before {
  content: "\f2db";
}

.bi-credit-card::before {
  content: "\f2dc";
}

.bi-crop::before {
  content: "\f2dd";
}

.bi-cup-fill::before {
  content: "\f2de";
}

.bi-cup-straw::before {
  content: "\f2df";
}

.bi-cup::before {
  content: "\f2e0";
}

.bi-cursor-fill::before {
  content: "\f2e1";
}

.bi-cursor-text::before {
  content: "\f2e2";
}

.bi-cursor::before {
  content: "\f2e3";
}

.bi-dash-circle-dotted::before {
  content: "\f2e4";
}

.bi-dash-circle-fill::before {
  content: "\f2e5";
}

.bi-dash-circle::before {
  content: "\f2e6";
}

.bi-dash-square-dotted::before {
  content: "\f2e7";
}

.bi-dash-square-fill::before {
  content: "\f2e8";
}

.bi-dash-square::before {
  content: "\f2e9";
}

.bi-dash::before {
  content: "\f2ea";
}

.bi-diagram-2-fill::before {
  content: "\f2eb";
}

.bi-diagram-2::before {
  content: "\f2ec";
}

.bi-diagram-3-fill::before {
  content: "\f2ed";
}

.bi-diagram-3::before {
  content: "\f2ee";
}

.bi-diamond-fill::before {
  content: "\f2ef";
}

.bi-diamond-half::before {
  content: "\f2f0";
}

.bi-diamond::before {
  content: "\f2f1";
}

.bi-dice-1-fill::before {
  content: "\f2f2";
}

.bi-dice-1::before {
  content: "\f2f3";
}

.bi-dice-2-fill::before {
  content: "\f2f4";
}

.bi-dice-2::before {
  content: "\f2f5";
}

.bi-dice-3-fill::before {
  content: "\f2f6";
}

.bi-dice-3::before {
  content: "\f2f7";
}

.bi-dice-4-fill::before {
  content: "\f2f8";
}

.bi-dice-4::before {
  content: "\f2f9";
}

.bi-dice-5-fill::before {
  content: "\f2fa";
}

.bi-dice-5::before {
  content: "\f2fb";
}

.bi-dice-6-fill::before {
  content: "\f2fc";
}

.bi-dice-6::before {
  content: "\f2fd";
}

.bi-disc-fill::before {
  content: "\f2fe";
}

.bi-disc::before {
  content: "\f2ff";
}

.bi-discord::before {
  content: "\f300";
}

.bi-display-fill::before {
  content: "\f301";
}

.bi-display::before {
  content: "\f302";
}

.bi-distribute-horizontal::before {
  content: "\f303";
}

.bi-distribute-vertical::before {
  content: "\f304";
}

.bi-door-closed-fill::before {
  content: "\f305";
}

.bi-door-closed::before {
  content: "\f306";
}

.bi-door-open-fill::before {
  content: "\f307";
}

.bi-door-open::before {
  content: "\f308";
}

.bi-dot::before {
  content: "\f309";
}

.bi-download::before {
  content: "\f30a";
}

.bi-droplet-fill::before {
  content: "\f30b";
}

.bi-droplet-half::before {
  content: "\f30c";
}

.bi-droplet::before {
  content: "\f30d";
}

.bi-earbuds::before {
  content: "\f30e";
}

.bi-easel-fill::before {
  content: "\f30f";
}

.bi-easel::before {
  content: "\f310";
}

.bi-egg-fill::before {
  content: "\f311";
}

.bi-egg-fried::before {
  content: "\f312";
}

.bi-egg::before {
  content: "\f313";
}

.bi-eject-fill::before {
  content: "\f314";
}

.bi-eject::before {
  content: "\f315";
}

.bi-emoji-angry-fill::before {
  content: "\f316";
}

.bi-emoji-angry::before {
  content: "\f317";
}

.bi-emoji-dizzy-fill::before {
  content: "\f318";
}

.bi-emoji-dizzy::before {
  content: "\f319";
}

.bi-emoji-expressionless-fill::before {
  content: "\f31a";
}

.bi-emoji-expressionless::before {
  content: "\f31b";
}

.bi-emoji-frown-fill::before {
  content: "\f31c";
}

.bi-emoji-frown::before {
  content: "\f31d";
}

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e";
}

.bi-emoji-heart-eyes::before {
  content: "\f31f";
}

.bi-emoji-laughing-fill::before {
  content: "\f320";
}

.bi-emoji-laughing::before {
  content: "\f321";
}

.bi-emoji-neutral-fill::before {
  content: "\f322";
}

.bi-emoji-neutral::before {
  content: "\f323";
}

.bi-emoji-smile-fill::before {
  content: "\f324";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325";
}

.bi-emoji-smile-upside-down::before {
  content: "\f326";
}

.bi-emoji-smile::before {
  content: "\f327";
}

.bi-emoji-sunglasses-fill::before {
  content: "\f328";
}

.bi-emoji-sunglasses::before {
  content: "\f329";
}

.bi-emoji-wink-fill::before {
  content: "\f32a";
}

.bi-emoji-wink::before {
  content: "\f32b";
}

.bi-envelope-fill::before {
  content: "\f32c";
}

.bi-envelope-open-fill::before {
  content: "\f32d";
}

.bi-envelope-open::before {
  content: "\f32e";
}

.bi-envelope::before {
  content: "\f32f";
}

.bi-eraser-fill::before {
  content: "\f330";
}

.bi-eraser::before {
  content: "\f331";
}

.bi-exclamation-circle-fill::before {
  content: "\f332";
}

.bi-exclamation-circle::before {
  content: "\f333";
}

.bi-exclamation-diamond-fill::before {
  content: "\f334";
}

.bi-exclamation-diamond::before {
  content: "\f335";
}

.bi-exclamation-octagon-fill::before {
  content: "\f336";
}

.bi-exclamation-octagon::before {
  content: "\f337";
}

.bi-exclamation-square-fill::before {
  content: "\f338";
}

.bi-exclamation-square::before {
  content: "\f339";
}

.bi-exclamation-triangle-fill::before {
  content: "\f33a";
}

.bi-exclamation-triangle::before {
  content: "\f33b";
}

.bi-exclamation::before {
  content: "\f33c";
}

.bi-exclude::before {
  content: "\f33d";
}

.bi-eye-fill::before {
  content: "\f33e";
}

.bi-eye-slash-fill::before {
  content: "\f33f";
}

.bi-eye-slash::before {
  content: "\f340";
}

.bi-eye::before {
  content: "\f341";
}

.bi-eyedropper::before {
  content: "\f342";
}

.bi-eyeglasses::before {
  content: "\f343";
}

.bi-facebook::before {
  content: "\f344";
}

.bi-file-arrow-down-fill::before {
  content: "\f345";
}

.bi-file-arrow-down::before {
  content: "\f346";
}

.bi-file-arrow-up-fill::before {
  content: "\f347";
}

.bi-file-arrow-up::before {
  content: "\f348";
}

.bi-file-bar-graph-fill::before {
  content: "\f349";
}

.bi-file-bar-graph::before {
  content: "\f34a";
}

.bi-file-binary-fill::before {
  content: "\f34b";
}

.bi-file-binary::before {
  content: "\f34c";
}

.bi-file-break-fill::before {
  content: "\f34d";
}

.bi-file-break::before {
  content: "\f34e";
}

.bi-file-check-fill::before {
  content: "\f34f";
}

.bi-file-check::before {
  content: "\f350";
}

.bi-file-code-fill::before {
  content: "\f351";
}

.bi-file-code::before {
  content: "\f352";
}

.bi-file-diff-fill::before {
  content: "\f353";
}

.bi-file-diff::before {
  content: "\f354";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355";
}

.bi-file-earmark-arrow-down::before {
  content: "\f356";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357";
}

.bi-file-earmark-arrow-up::before {
  content: "\f358";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359";
}

.bi-file-earmark-bar-graph::before {
  content: "\f35a";
}

.bi-file-earmark-binary-fill::before {
  content: "\f35b";
}

.bi-file-earmark-binary::before {
  content: "\f35c";
}

.bi-file-earmark-break-fill::before {
  content: "\f35d";
}

.bi-file-earmark-break::before {
  content: "\f35e";
}

.bi-file-earmark-check-fill::before {
  content: "\f35f";
}

.bi-file-earmark-check::before {
  content: "\f360";
}

.bi-file-earmark-code-fill::before {
  content: "\f361";
}

.bi-file-earmark-code::before {
  content: "\f362";
}

.bi-file-earmark-diff-fill::before {
  content: "\f363";
}

.bi-file-earmark-diff::before {
  content: "\f364";
}

.bi-file-earmark-easel-fill::before {
  content: "\f365";
}

.bi-file-earmark-easel::before {
  content: "\f366";
}

.bi-file-earmark-excel-fill::before {
  content: "\f367";
}

.bi-file-earmark-excel::before {
  content: "\f368";
}

.bi-file-earmark-fill::before {
  content: "\f369";
}

.bi-file-earmark-font-fill::before {
  content: "\f36a";
}

.bi-file-earmark-font::before {
  content: "\f36b";
}

.bi-file-earmark-image-fill::before {
  content: "\f36c";
}

.bi-file-earmark-image::before {
  content: "\f36d";
}

.bi-file-earmark-lock-fill::before {
  content: "\f36e";
}

.bi-file-earmark-lock::before {
  content: "\f36f";
}

.bi-file-earmark-lock2-fill::before {
  content: "\f370";
}

.bi-file-earmark-lock2::before {
  content: "\f371";
}

.bi-file-earmark-medical-fill::before {
  content: "\f372";
}

.bi-file-earmark-medical::before {
  content: "\f373";
}

.bi-file-earmark-minus-fill::before {
  content: "\f374";
}

.bi-file-earmark-minus::before {
  content: "\f375";
}

.bi-file-earmark-music-fill::before {
  content: "\f376";
}

.bi-file-earmark-music::before {
  content: "\f377";
}

.bi-file-earmark-person-fill::before {
  content: "\f378";
}

.bi-file-earmark-person::before {
  content: "\f379";
}

.bi-file-earmark-play-fill::before {
  content: "\f37a";
}

.bi-file-earmark-play::before {
  content: "\f37b";
}

.bi-file-earmark-plus-fill::before {
  content: "\f37c";
}

.bi-file-earmark-plus::before {
  content: "\f37d";
}

.bi-file-earmark-post-fill::before {
  content: "\f37e";
}

.bi-file-earmark-post::before {
  content: "\f37f";
}

.bi-file-earmark-ppt-fill::before {
  content: "\f380";
}

.bi-file-earmark-ppt::before {
  content: "\f381";
}

.bi-file-earmark-richtext-fill::before {
  content: "\f382";
}

.bi-file-earmark-richtext::before {
  content: "\f383";
}

.bi-file-earmark-ruled-fill::before {
  content: "\f384";
}

.bi-file-earmark-ruled::before {
  content: "\f385";
}

.bi-file-earmark-slides-fill::before {
  content: "\f386";
}

.bi-file-earmark-slides::before {
  content: "\f387";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388";
}

.bi-file-earmark-spreadsheet::before {
  content: "\f389";
}

.bi-file-earmark-text-fill::before {
  content: "\f38a";
}

.bi-file-earmark-text::before {
  content: "\f38b";
}

.bi-file-earmark-word-fill::before {
  content: "\f38c";
}

.bi-file-earmark-word::before {
  content: "\f38d";
}

.bi-file-earmark-x-fill::before {
  content: "\f38e";
}

.bi-file-earmark-x::before {
  content: "\f38f";
}

.bi-file-earmark-zip-fill::before {
  content: "\f390";
}

.bi-file-earmark-zip::before {
  content: "\f391";
}

.bi-file-earmark::before {
  content: "\f392";
}

.bi-file-easel-fill::before {
  content: "\f393";
}

.bi-file-easel::before {
  content: "\f394";
}

.bi-file-excel-fill::before {
  content: "\f395";
}

.bi-file-excel::before {
  content: "\f396";
}

.bi-file-fill::before {
  content: "\f397";
}

.bi-file-font-fill::before {
  content: "\f398";
}

.bi-file-font::before {
  content: "\f399";
}

.bi-file-image-fill::before {
  content: "\f39a";
}

.bi-file-image::before {
  content: "\f39b";
}

.bi-file-lock-fill::before {
  content: "\f39c";
}

.bi-file-lock::before {
  content: "\f39d";
}

.bi-file-lock2-fill::before {
  content: "\f39e";
}

.bi-file-lock2::before {
  content: "\f39f";
}

.bi-file-medical-fill::before {
  content: "\f3a0";
}

.bi-file-medical::before {
  content: "\f3a1";
}

.bi-file-minus-fill::before {
  content: "\f3a2";
}

.bi-file-minus::before {
  content: "\f3a3";
}

.bi-file-music-fill::before {
  content: "\f3a4";
}

.bi-file-music::before {
  content: "\f3a5";
}

.bi-file-person-fill::before {
  content: "\f3a6";
}

.bi-file-person::before {
  content: "\f3a7";
}

.bi-file-play-fill::before {
  content: "\f3a8";
}

.bi-file-play::before {
  content: "\f3a9";
}

.bi-file-plus-fill::before {
  content: "\f3aa";
}

.bi-file-plus::before {
  content: "\f3ab";
}

.bi-file-post-fill::before {
  content: "\f3ac";
}

.bi-file-post::before {
  content: "\f3ad";
}

.bi-file-ppt-fill::before {
  content: "\f3ae";
}

.bi-file-ppt::before {
  content: "\f3af";
}

.bi-file-richtext-fill::before {
  content: "\f3b0";
}

.bi-file-richtext::before {
  content: "\f3b1";
}

.bi-file-ruled-fill::before {
  content: "\f3b2";
}

.bi-file-ruled::before {
  content: "\f3b3";
}

.bi-file-slides-fill::before {
  content: "\f3b4";
}

.bi-file-slides::before {
  content: "\f3b5";
}

.bi-file-spreadsheet-fill::before {
  content: "\f3b6";
}

.bi-file-spreadsheet::before {
  content: "\f3b7";
}

.bi-file-text-fill::before {
  content: "\f3b8";
}

.bi-file-text::before {
  content: "\f3b9";
}

.bi-file-word-fill::before {
  content: "\f3ba";
}

.bi-file-word::before {
  content: "\f3bb";
}

.bi-file-x-fill::before {
  content: "\f3bc";
}

.bi-file-x::before {
  content: "\f3bd";
}

.bi-file-zip-fill::before {
  content: "\f3be";
}

.bi-file-zip::before {
  content: "\f3bf";
}

.bi-file::before {
  content: "\f3c0";
}

.bi-files-alt::before {
  content: "\f3c1";
}

.bi-files::before {
  content: "\f3c2";
}

.bi-film::before {
  content: "\f3c3";
}

.bi-filter-circle-fill::before {
  content: "\f3c4";
}

.bi-filter-circle::before {
  content: "\f3c5";
}

.bi-filter-left::before {
  content: "\f3c6";
}

.bi-filter-right::before {
  content: "\f3c7";
}

.bi-filter-square-fill::before {
  content: "\f3c8";
}

.bi-filter-square::before {
  content: "\f3c9";
}

.bi-filter::before {
  content: "\f3ca";
}

.bi-flag-fill::before {
  content: "\f3cb";
}

.bi-flag::before {
  content: "\f3cc";
}

.bi-flower1::before {
  content: "\f3cd";
}

.bi-flower2::before {
  content: "\f3ce";
}

.bi-flower3::before {
  content: "\f3cf";
}

.bi-folder-check::before {
  content: "\f3d0";
}

.bi-folder-fill::before {
  content: "\f3d1";
}

.bi-folder-minus::before {
  content: "\f3d2";
}

.bi-folder-plus::before {
  content: "\f3d3";
}

.bi-folder-symlink-fill::before {
  content: "\f3d4";
}

.bi-folder-symlink::before {
  content: "\f3d5";
}

.bi-folder-x::before {
  content: "\f3d6";
}

.bi-folder::before {
  content: "\f3d7";
}

.bi-folder2-open::before {
  content: "\f3d8";
}

.bi-folder2::before {
  content: "\f3d9";
}

.bi-fonts::before {
  content: "\f3da";
}

.bi-forward-fill::before {
  content: "\f3db";
}

.bi-forward::before {
  content: "\f3dc";
}

.bi-front::before {
  content: "\f3dd";
}

.bi-fullscreen-exit::before {
  content: "\f3de";
}

.bi-fullscreen::before {
  content: "\f3df";
}

.bi-funnel-fill::before {
  content: "\f3e0";
}

.bi-funnel::before {
  content: "\f3e1";
}

.bi-gear-fill::before {
  content: "\f3e2";
}

.bi-gear-wide-connected::before {
  content: "\f3e3";
}

.bi-gear-wide::before {
  content: "\f3e4";
}

.bi-gear::before {
  content: "\f3e5";
}

.bi-gem::before {
  content: "\f3e6";
}

.bi-geo-alt-fill::before {
  content: "\f3e7";
}

.bi-geo-alt::before {
  content: "\f3e8";
}

.bi-geo-fill::before {
  content: "\f3e9";
}

.bi-geo::before {
  content: "\f3ea";
}

.bi-gift-fill::before {
  content: "\f3eb";
}

.bi-gift::before {
  content: "\f3ec";
}

.bi-github::before {
  content: "\f3ed";
}

.bi-globe::before {
  content: "\f3ee";
}

.bi-globe2::before {
  content: "\f3ef";
}

.bi-google::before {
  content: "\f3f0";
}

.bi-graph-down::before {
  content: "\f3f1";
}

.bi-graph-up::before {
  content: "\f3f2";
}

.bi-grid-1x2-fill::before {
  content: "\f3f3";
}

.bi-grid-1x2::before {
  content: "\f3f4";
}

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5";
}

.bi-grid-3x2-gap::before {
  content: "\f3f6";
}

.bi-grid-3x2::before {
  content: "\f3f7";
}

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8";
}

.bi-grid-3x3-gap::before {
  content: "\f3f9";
}

.bi-grid-3x3::before {
  content: "\f3fa";
}

.bi-grid-fill::before {
  content: "\f3fb";
}

.bi-grid::before {
  content: "\f3fc";
}

.bi-grip-horizontal::before {
  content: "\f3fd";
}

.bi-grip-vertical::before {
  content: "\f3fe";
}

.bi-hammer::before {
  content: "\f3ff";
}

.bi-hand-index-fill::before {
  content: "\f400";
}

.bi-hand-index-thumb-fill::before {
  content: "\f401";
}

.bi-hand-index-thumb::before {
  content: "\f402";
}

.bi-hand-index::before {
  content: "\f403";
}

.bi-hand-thumbs-down-fill::before {
  content: "\f404";
}

.bi-hand-thumbs-down::before {
  content: "\f405";
}

.bi-hand-thumbs-up-fill::before {
  content: "\f406";
}

.bi-hand-thumbs-up::before {
  content: "\f407";
}

.bi-handbag-fill::before {
  content: "\f408";
}

.bi-handbag::before {
  content: "\f409";
}

.bi-hash::before {
  content: "\f40a";
}

.bi-hdd-fill::before {
  content: "\f40b";
}

.bi-hdd-network-fill::before {
  content: "\f40c";
}

.bi-hdd-network::before {
  content: "\f40d";
}

.bi-hdd-rack-fill::before {
  content: "\f40e";
}

.bi-hdd-rack::before {
  content: "\f40f";
}

.bi-hdd-stack-fill::before {
  content: "\f410";
}

.bi-hdd-stack::before {
  content: "\f411";
}

.bi-hdd::before {
  content: "\f412";
}

.bi-headphones::before {
  content: "\f413";
}

.bi-headset::before {
  content: "\f414";
}

.bi-heart-fill::before {
  content: "\f415";
}

.bi-heart-half::before {
  content: "\f416";
}

.bi-heart::before {
  content: "\f417";
}

.bi-heptagon-fill::before {
  content: "\f418";
}

.bi-heptagon-half::before {
  content: "\f419";
}

.bi-heptagon::before {
  content: "\f41a";
}

.bi-hexagon-fill::before {
  content: "\f41b";
}

.bi-hexagon-half::before {
  content: "\f41c";
}

.bi-hexagon::before {
  content: "\f41d";
}

.bi-hourglass-bottom::before {
  content: "\f41e";
}

.bi-hourglass-split::before {
  content: "\f41f";
}

.bi-hourglass-top::before {
  content: "\f420";
}

.bi-hourglass::before {
  content: "\f421";
}

.bi-house-door-fill::before {
  content: "\f422";
}

.bi-house-door::before {
  content: "\f423";
}

.bi-house-fill::before {
  content: "\f424";
}

.bi-house::before {
  content: "\f425";
}

.bi-hr::before {
  content: "\f426";
}

.bi-hurricane::before {
  content: "\f427";
}

.bi-image-alt::before {
  content: "\f428";
}

.bi-image-fill::before {
  content: "\f429";
}

.bi-image::before {
  content: "\f42a";
}

.bi-images::before {
  content: "\f42b";
}

.bi-inbox-fill::before {
  content: "\f42c";
}

.bi-inbox::before {
  content: "\f42d";
}

.bi-inboxes-fill::before {
  content: "\f42e";
}

.bi-inboxes::before {
  content: "\f42f";
}

.bi-info-circle-fill::before {
  content: "\f430";
}

.bi-info-circle::before {
  content: "\f431";
}

.bi-info-square-fill::before {
  content: "\f432";
}

.bi-info-square::before {
  content: "\f433";
}

.bi-info::before {
  content: "\f434";
}

.bi-input-cursor-text::before {
  content: "\f435";
}

.bi-input-cursor::before {
  content: "\f436";
}

.bi-instagram::before {
  content: "\f437";
}

.bi-intersect::before {
  content: "\f438";
}

.bi-journal-album::before {
  content: "\f439";
}

.bi-journal-arrow-down::before {
  content: "\f43a";
}

.bi-journal-arrow-up::before {
  content: "\f43b";
}

.bi-journal-bookmark-fill::before {
  content: "\f43c";
}

.bi-journal-bookmark::before {
  content: "\f43d";
}

.bi-journal-check::before {
  content: "\f43e";
}

.bi-journal-code::before {
  content: "\f43f";
}

.bi-journal-medical::before {
  content: "\f440";
}

.bi-journal-minus::before {
  content: "\f441";
}

.bi-journal-plus::before {
  content: "\f442";
}

.bi-journal-richtext::before {
  content: "\f443";
}

.bi-journal-text::before {
  content: "\f444";
}

.bi-journal-x::before {
  content: "\f445";
}

.bi-journal::before {
  content: "\f446";
}

.bi-journals::before {
  content: "\f447";
}

.bi-joystick::before {
  content: "\f448";
}

.bi-justify-left::before {
  content: "\f449";
}

.bi-justify-right::before {
  content: "\f44a";
}

.bi-justify::before {
  content: "\f44b";
}

.bi-kanban-fill::before {
  content: "\f44c";
}

.bi-kanban::before {
  content: "\f44d";
}

.bi-key-fill::before {
  content: "\f44e";
}

.bi-key::before {
  content: "\f44f";
}

.bi-keyboard-fill::before {
  content: "\f450";
}

.bi-keyboard::before {
  content: "\f451";
}

.bi-ladder::before {
  content: "\f452";
}

.bi-lamp-fill::before {
  content: "\f453";
}

.bi-lamp::before {
  content: "\f454";
}

.bi-laptop-fill::before {
  content: "\f455";
}

.bi-laptop::before {
  content: "\f456";
}

.bi-layer-backward::before {
  content: "\f457";
}

.bi-layer-forward::before {
  content: "\f458";
}

.bi-layers-fill::before {
  content: "\f459";
}

.bi-layers-half::before {
  content: "\f45a";
}

.bi-layers::before {
  content: "\f45b";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c";
}

.bi-layout-sidebar-inset::before {
  content: "\f45d";
}

.bi-layout-sidebar-reverse::before {
  content: "\f45e";
}

.bi-layout-sidebar::before {
  content: "\f45f";
}

.bi-layout-split::before {
  content: "\f460";
}

.bi-layout-text-sidebar-reverse::before {
  content: "\f461";
}

.bi-layout-text-sidebar::before {
  content: "\f462";
}

.bi-layout-text-window-reverse::before {
  content: "\f463";
}

.bi-layout-text-window::before {
  content: "\f464";
}

.bi-layout-three-columns::before {
  content: "\f465";
}

.bi-layout-wtf::before {
  content: "\f466";
}

.bi-life-preserver::before {
  content: "\f467";
}

.bi-lightbulb-fill::before {
  content: "\f468";
}

.bi-lightbulb-off-fill::before {
  content: "\f469";
}

.bi-lightbulb-off::before {
  content: "\f46a";
}

.bi-lightbulb::before {
  content: "\f46b";
}

.bi-lightning-charge-fill::before {
  content: "\f46c";
}

.bi-lightning-charge::before {
  content: "\f46d";
}

.bi-lightning-fill::before {
  content: "\f46e";
}

.bi-lightning::before {
  content: "\f46f";
}

.bi-link-45deg::before {
  content: "\f470";
}

.bi-link::before {
  content: "\f471";
}

.bi-linkedin::before {
  content: "\f472";
}

.bi-list-check::before {
  content: "\f473";
}

.bi-list-nested::before {
  content: "\f474";
}

.bi-list-ol::before {
  content: "\f475";
}

.bi-list-stars::before {
  content: "\f476";
}

.bi-list-task::before {
  content: "\f477";
}

.bi-list-ul::before {
  content: "\f478";
}

.bi-list::before {
  content: "\f479";
}

.bi-lock-fill::before {
  content: "\f47a";
}

.bi-lock::before {
  content: "\f47b";
}

.bi-mailbox::before {
  content: "\f47c";
}

.bi-mailbox2::before {
  content: "\f47d";
}

.bi-map-fill::before {
  content: "\f47e";
}

.bi-map::before {
  content: "\f47f";
}

.bi-markdown-fill::before {
  content: "\f480";
}

.bi-markdown::before {
  content: "\f481";
}

.bi-mask::before {
  content: "\f482";
}

.bi-megaphone-fill::before {
  content: "\f483";
}

.bi-megaphone::before {
  content: "\f484";
}

.bi-menu-app-fill::before {
  content: "\f485";
}

.bi-menu-app::before {
  content: "\f486";
}

.bi-menu-button-fill::before {
  content: "\f487";
}

.bi-menu-button-wide-fill::before {
  content: "\f488";
}

.bi-menu-button-wide::before {
  content: "\f489";
}

.bi-menu-button::before {
  content: "\f48a";
}

.bi-menu-down::before {
  content: "\f48b";
}

.bi-menu-up::before {
  content: "\f48c";
}

.bi-mic-fill::before {
  content: "\f48d";
}

.bi-mic-mute-fill::before {
  content: "\f48e";
}

.bi-mic-mute::before {
  content: "\f48f";
}

.bi-mic::before {
  content: "\f490";
}

.bi-minecart-loaded::before {
  content: "\f491";
}

.bi-minecart::before {
  content: "\f492";
}

.bi-moisture::before {
  content: "\f493";
}

.bi-moon-fill::before {
  content: "\f494";
}

.bi-moon-stars-fill::before {
  content: "\f495";
}

.bi-moon-stars::before {
  content: "\f496";
}

.bi-moon::before {
  content: "\f497";
}

.bi-mouse-fill::before {
  content: "\f498";
}

.bi-mouse::before {
  content: "\f499";
}

.bi-mouse2-fill::before {
  content: "\f49a";
}

.bi-mouse2::before {
  content: "\f49b";
}

.bi-mouse3-fill::before {
  content: "\f49c";
}

.bi-mouse3::before {
  content: "\f49d";
}

.bi-music-note-beamed::before {
  content: "\f49e";
}

.bi-music-note-list::before {
  content: "\f49f";
}

.bi-music-note::before {
  content: "\f4a0";
}

.bi-music-player-fill::before {
  content: "\f4a1";
}

.bi-music-player::before {
  content: "\f4a2";
}

.bi-newspaper::before {
  content: "\f4a3";
}

.bi-node-minus-fill::before {
  content: "\f4a4";
}

.bi-node-minus::before {
  content: "\f4a5";
}

.bi-node-plus-fill::before {
  content: "\f4a6";
}

.bi-node-plus::before {
  content: "\f4a7";
}

.bi-nut-fill::before {
  content: "\f4a8";
}

.bi-nut::before {
  content: "\f4a9";
}

.bi-octagon-fill::before {
  content: "\f4aa";
}

.bi-octagon-half::before {
  content: "\f4ab";
}

.bi-octagon::before {
  content: "\f4ac";
}

.bi-option::before {
  content: "\f4ad";
}

.bi-outlet::before {
  content: "\f4ae";
}

.bi-paint-bucket::before {
  content: "\f4af";
}

.bi-palette-fill::before {
  content: "\f4b0";
}

.bi-palette::before {
  content: "\f4b1";
}

.bi-palette2::before {
  content: "\f4b2";
}

.bi-paperclip::before {
  content: "\f4b3";
}

.bi-paragraph::before {
  content: "\f4b4";
}

.bi-patch-check-fill::before {
  content: "\f4b5";
}

.bi-patch-check::before {
  content: "\f4b6";
}

.bi-patch-exclamation-fill::before {
  content: "\f4b7";
}

.bi-patch-exclamation::before {
  content: "\f4b8";
}

.bi-patch-minus-fill::before {
  content: "\f4b9";
}

.bi-patch-minus::before {
  content: "\f4ba";
}

.bi-patch-plus-fill::before {
  content: "\f4bb";
}

.bi-patch-plus::before {
  content: "\f4bc";
}

.bi-patch-question-fill::before {
  content: "\f4bd";
}

.bi-patch-question::before {
  content: "\f4be";
}

.bi-pause-btn-fill::before {
  content: "\f4bf";
}

.bi-pause-btn::before {
  content: "\f4c0";
}

.bi-pause-circle-fill::before {
  content: "\f4c1";
}

.bi-pause-circle::before {
  content: "\f4c2";
}

.bi-pause-fill::before {
  content: "\f4c3";
}

.bi-pause::before {
  content: "\f4c4";
}

.bi-peace-fill::before {
  content: "\f4c5";
}

.bi-peace::before {
  content: "\f4c6";
}

.bi-pen-fill::before {
  content: "\f4c7";
}

.bi-pen::before {
  content: "\f4c8";
}

.bi-pencil-fill::before {
  content: "\f4c9";
}

.bi-pencil-square::before {
  content: "\f4ca";
}

.bi-pencil::before {
  content: "\f4cb";
}

.bi-pentagon-fill::before {
  content: "\f4cc";
}

.bi-pentagon-half::before {
  content: "\f4cd";
}

.bi-pentagon::before {
  content: "\f4ce";
}

.bi-people-fill::before {
  content: "\f4cf";
}

.bi-people::before {
  content: "\f4d0";
}

.bi-percent::before {
  content: "\f4d1";
}

.bi-person-badge-fill::before {
  content: "\f4d2";
}

.bi-person-badge::before {
  content: "\f4d3";
}

.bi-person-bounding-box::before {
  content: "\f4d4";
}

.bi-person-check-fill::before {
  content: "\f4d5";
}

.bi-person-check::before {
  content: "\f4d6";
}

.bi-person-circle::before {
  content: "\f4d7";
}

.bi-person-dash-fill::before {
  content: "\f4d8";
}

.bi-person-dash::before {
  content: "\f4d9";
}

.bi-person-fill::before {
  content: "\f4da";
}

.bi-person-lines-fill::before {
  content: "\f4db";
}

.bi-person-plus-fill::before {
  content: "\f4dc";
}

.bi-person-plus::before {
  content: "\f4dd";
}

.bi-person-square::before {
  content: "\f4de";
}

.bi-person-x-fill::before {
  content: "\f4df";
}

.bi-person-x::before {
  content: "\f4e0";
}

.bi-person::before {
  content: "\f4e1";
}

.bi-phone-fill::before {
  content: "\f4e2";
}

.bi-phone-landscape-fill::before {
  content: "\f4e3";
}

.bi-phone-landscape::before {
  content: "\f4e4";
}

.bi-phone-vibrate-fill::before {
  content: "\f4e5";
}

.bi-phone-vibrate::before {
  content: "\f4e6";
}

.bi-phone::before {
  content: "\f4e7";
}

.bi-pie-chart-fill::before {
  content: "\f4e8";
}

.bi-pie-chart::before {
  content: "\f4e9";
}

.bi-pin-angle-fill::before {
  content: "\f4ea";
}

.bi-pin-angle::before {
  content: "\f4eb";
}

.bi-pin-fill::before {
  content: "\f4ec";
}

.bi-pin::before {
  content: "\f4ed";
}

.bi-pip-fill::before {
  content: "\f4ee";
}

.bi-pip::before {
  content: "\f4ef";
}

.bi-play-btn-fill::before {
  content: "\f4f0";
}

.bi-play-btn::before {
  content: "\f4f1";
}

.bi-play-circle-fill::before {
  content: "\f4f2";
}

.bi-play-circle::before {
  content: "\f4f3";
}

.bi-play-fill::before {
  content: "\f4f4";
}

.bi-play::before {
  content: "\f4f5";
}

.bi-plug-fill::before {
  content: "\f4f6";
}

.bi-plug::before {
  content: "\f4f7";
}

.bi-plus-circle-dotted::before {
  content: "\f4f8";
}

.bi-plus-circle-fill::before {
  content: "\f4f9";
}

.bi-plus-circle::before {
  content: "\f4fa";
}

.bi-plus-square-dotted::before {
  content: "\f4fb";
}

.bi-plus-square-fill::before {
  content: "\f4fc";
}

.bi-plus-square::before {
  content: "\f4fd";
}

.bi-plus::before {
  content: "\f4fe";
}

.bi-power::before {
  content: "\f4ff";
}

.bi-printer-fill::before {
  content: "\f500";
}

.bi-printer::before {
  content: "\f501";
}

.bi-puzzle-fill::before {
  content: "\f502";
}

.bi-puzzle::before {
  content: "\f503";
}

.bi-question-circle-fill::before {
  content: "\f504";
}

.bi-question-circle::before {
  content: "\f505";
}

.bi-question-diamond-fill::before {
  content: "\f506";
}

.bi-question-diamond::before {
  content: "\f507";
}

.bi-question-octagon-fill::before {
  content: "\f508";
}

.bi-question-octagon::before {
  content: "\f509";
}

.bi-question-square-fill::before {
  content: "\f50a";
}

.bi-question-square::before {
  content: "\f50b";
}

.bi-question::before {
  content: "\f50c";
}

.bi-rainbow::before {
  content: "\f50d";
}

.bi-receipt-cutoff::before {
  content: "\f50e";
}

.bi-receipt::before {
  content: "\f50f";
}

.bi-reception-0::before {
  content: "\f510";
}

.bi-reception-1::before {
  content: "\f511";
}

.bi-reception-2::before {
  content: "\f512";
}

.bi-reception-3::before {
  content: "\f513";
}

.bi-reception-4::before {
  content: "\f514";
}

.bi-record-btn-fill::before {
  content: "\f515";
}

.bi-record-btn::before {
  content: "\f516";
}

.bi-record-circle-fill::before {
  content: "\f517";
}

.bi-record-circle::before {
  content: "\f518";
}

.bi-record-fill::before {
  content: "\f519";
}

.bi-record::before {
  content: "\f51a";
}

.bi-record2-fill::before {
  content: "\f51b";
}

.bi-record2::before {
  content: "\f51c";
}

.bi-reply-all-fill::before {
  content: "\f51d";
}

.bi-reply-all::before {
  content: "\f51e";
}

.bi-reply-fill::before {
  content: "\f51f";
}

.bi-reply::before {
  content: "\f520";
}

.bi-rss-fill::before {
  content: "\f521";
}

.bi-rss::before {
  content: "\f522";
}

.bi-rulers::before {
  content: "\f523";
}

.bi-save-fill::before {
  content: "\f524";
}

.bi-save::before {
  content: "\f525";
}

.bi-save2-fill::before {
  content: "\f526";
}

.bi-save2::before {
  content: "\f527";
}

.bi-scissors::before {
  content: "\f528";
}

.bi-screwdriver::before {
  content: "\f529";
}

.bi-search::before {
  content: "\f52a";
}

.bi-segmented-nav::before {
  content: "\f52b";
}

.bi-server::before {
  content: "\f52c";
}

.bi-share-fill::before {
  content: "\f52d";
}

.bi-share::before {
  content: "\f52e";
}

.bi-shield-check::before {
  content: "\f52f";
}

.bi-shield-exclamation::before {
  content: "\f530";
}

.bi-shield-fill-check::before {
  content: "\f531";
}

.bi-shield-fill-exclamation::before {
  content: "\f532";
}

.bi-shield-fill-minus::before {
  content: "\f533";
}

.bi-shield-fill-plus::before {
  content: "\f534";
}

.bi-shield-fill-x::before {
  content: "\f535";
}

.bi-shield-fill::before {
  content: "\f536";
}

.bi-shield-lock-fill::before {
  content: "\f537";
}

.bi-shield-lock::before {
  content: "\f538";
}

.bi-shield-minus::before {
  content: "\f539";
}

.bi-shield-plus::before {
  content: "\f53a";
}

.bi-shield-shaded::before {
  content: "\f53b";
}

.bi-shield-slash-fill::before {
  content: "\f53c";
}

.bi-shield-slash::before {
  content: "\f53d";
}

.bi-shield-x::before {
  content: "\f53e";
}

.bi-shield::before {
  content: "\f53f";
}

.bi-shift-fill::before {
  content: "\f540";
}

.bi-shift::before {
  content: "\f541";
}

.bi-shop-window::before {
  content: "\f542";
}

.bi-shop::before {
  content: "\f543";
}

.bi-shuffle::before {
  content: "\f544";
}

.bi-signpost-2-fill::before {
  content: "\f545";
}

.bi-signpost-2::before {
  content: "\f546";
}

.bi-signpost-fill::before {
  content: "\f547";
}

.bi-signpost-split-fill::before {
  content: "\f548";
}

.bi-signpost-split::before {
  content: "\f549";
}

.bi-signpost::before {
  content: "\f54a";
}

.bi-sim-fill::before {
  content: "\f54b";
}

.bi-sim::before {
  content: "\f54c";
}

.bi-skip-backward-btn-fill::before {
  content: "\f54d";
}

.bi-skip-backward-btn::before {
  content: "\f54e";
}

.bi-skip-backward-circle-fill::before {
  content: "\f54f";
}

.bi-skip-backward-circle::before {
  content: "\f550";
}

.bi-skip-backward-fill::before {
  content: "\f551";
}

.bi-skip-backward::before {
  content: "\f552";
}

.bi-skip-end-btn-fill::before {
  content: "\f553";
}

.bi-skip-end-btn::before {
  content: "\f554";
}

.bi-skip-end-circle-fill::before {
  content: "\f555";
}

.bi-skip-end-circle::before {
  content: "\f556";
}

.bi-skip-end-fill::before {
  content: "\f557";
}

.bi-skip-end::before {
  content: "\f558";
}

.bi-skip-forward-btn-fill::before {
  content: "\f559";
}

.bi-skip-forward-btn::before {
  content: "\f55a";
}

.bi-skip-forward-circle-fill::before {
  content: "\f55b";
}

.bi-skip-forward-circle::before {
  content: "\f55c";
}

.bi-skip-forward-fill::before {
  content: "\f55d";
}

.bi-skip-forward::before {
  content: "\f55e";
}

.bi-skip-start-btn-fill::before {
  content: "\f55f";
}

.bi-skip-start-btn::before {
  content: "\f560";
}

.bi-skip-start-circle-fill::before {
  content: "\f561";
}

.bi-skip-start-circle::before {
  content: "\f562";
}

.bi-skip-start-fill::before {
  content: "\f563";
}

.bi-skip-start::before {
  content: "\f564";
}

.bi-slack::before {
  content: "\f565";
}

.bi-slash-circle-fill::before {
  content: "\f566";
}

.bi-slash-circle::before {
  content: "\f567";
}

.bi-slash-square-fill::before {
  content: "\f568";
}

.bi-slash-square::before {
  content: "\f569";
}

.bi-slash::before {
  content: "\f56a";
}

.bi-sliders::before {
  content: "\f56b";
}

.bi-smartwatch::before {
  content: "\f56c";
}

.bi-snow::before {
  content: "\f56d";
}

.bi-snow2::before {
  content: "\f56e";
}

.bi-snow3::before {
  content: "\f56f";
}

.bi-sort-alpha-down-alt::before {
  content: "\f570";
}

.bi-sort-alpha-down::before {
  content: "\f571";
}

.bi-sort-alpha-up-alt::before {
  content: "\f572";
}

.bi-sort-alpha-up::before {
  content: "\f573";
}

.bi-sort-down-alt::before {
  content: "\f574";
}

.bi-sort-down::before {
  content: "\f575";
}

.bi-sort-numeric-down-alt::before {
  content: "\f576";
}

.bi-sort-numeric-down::before {
  content: "\f577";
}

.bi-sort-numeric-up-alt::before {
  content: "\f578";
}

.bi-sort-numeric-up::before {
  content: "\f579";
}

.bi-sort-up-alt::before {
  content: "\f57a";
}

.bi-sort-up::before {
  content: "\f57b";
}

.bi-soundwave::before {
  content: "\f57c";
}

.bi-speaker-fill::before {
  content: "\f57d";
}

.bi-speaker::before {
  content: "\f57e";
}

.bi-speedometer::before {
  content: "\f57f";
}

.bi-speedometer2::before {
  content: "\f580";
}

.bi-spellcheck::before {
  content: "\f581";
}

.bi-square-fill::before {
  content: "\f582";
}

.bi-square-half::before {
  content: "\f583";
}

.bi-square::before {
  content: "\f584";
}

.bi-stack::before {
  content: "\f585";
}

.bi-star-fill::before {
  content: "\f586";
}

.bi-star-half::before {
  content: "\f587";
}

.bi-star::before {
  content: "\f588";
}

.bi-stars::before {
  content: "\f589";
}

.bi-stickies-fill::before {
  content: "\f58a";
}

.bi-stickies::before {
  content: "\f58b";
}

.bi-sticky-fill::before {
  content: "\f58c";
}

.bi-sticky::before {
  content: "\f58d";
}

.bi-stop-btn-fill::before {
  content: "\f58e";
}

.bi-stop-btn::before {
  content: "\f58f";
}

.bi-stop-circle-fill::before {
  content: "\f590";
}

.bi-stop-circle::before {
  content: "\f591";
}

.bi-stop-fill::before {
  content: "\f592";
}

.bi-stop::before {
  content: "\f593";
}

.bi-stoplights-fill::before {
  content: "\f594";
}

.bi-stoplights::before {
  content: "\f595";
}

.bi-stopwatch-fill::before {
  content: "\f596";
}

.bi-stopwatch::before {
  content: "\f597";
}

.bi-subtract::before {
  content: "\f598";
}

.bi-suit-club-fill::before {
  content: "\f599";
}

.bi-suit-club::before {
  content: "\f59a";
}

.bi-suit-diamond-fill::before {
  content: "\f59b";
}

.bi-suit-diamond::before {
  content: "\f59c";
}

.bi-suit-heart-fill::before {
  content: "\f59d";
}

.bi-suit-heart::before {
  content: "\f59e";
}

.bi-suit-spade-fill::before {
  content: "\f59f";
}

.bi-suit-spade::before {
  content: "\f5a0";
}

.bi-sun-fill::before {
  content: "\f5a1";
}

.bi-sun::before {
  content: "\f5a2";
}

.bi-sunglasses::before {
  content: "\f5a3";
}

.bi-sunrise-fill::before {
  content: "\f5a4";
}

.bi-sunrise::before {
  content: "\f5a5";
}

.bi-sunset-fill::before {
  content: "\f5a6";
}

.bi-sunset::before {
  content: "\f5a7";
}

.bi-symmetry-horizontal::before {
  content: "\f5a8";
}

.bi-symmetry-vertical::before {
  content: "\f5a9";
}

.bi-table::before {
  content: "\f5aa";
}

.bi-tablet-fill::before {
  content: "\f5ab";
}

.bi-tablet-landscape-fill::before {
  content: "\f5ac";
}

.bi-tablet-landscape::before {
  content: "\f5ad";
}

.bi-tablet::before {
  content: "\f5ae";
}

.bi-tag-fill::before {
  content: "\f5af";
}

.bi-tag::before {
  content: "\f5b0";
}

.bi-tags-fill::before {
  content: "\f5b1";
}

.bi-tags::before {
  content: "\f5b2";
}

.bi-telegram::before {
  content: "\f5b3";
}

.bi-telephone-fill::before {
  content: "\f5b4";
}

.bi-telephone-forward-fill::before {
  content: "\f5b5";
}

.bi-telephone-forward::before {
  content: "\f5b6";
}

.bi-telephone-inbound-fill::before {
  content: "\f5b7";
}

.bi-telephone-inbound::before {
  content: "\f5b8";
}

.bi-telephone-minus-fill::before {
  content: "\f5b9";
}

.bi-telephone-minus::before {
  content: "\f5ba";
}

.bi-telephone-outbound-fill::before {
  content: "\f5bb";
}

.bi-telephone-outbound::before {
  content: "\f5bc";
}

.bi-telephone-plus-fill::before {
  content: "\f5bd";
}

.bi-telephone-plus::before {
  content: "\f5be";
}

.bi-telephone-x-fill::before {
  content: "\f5bf";
}

.bi-telephone-x::before {
  content: "\f5c0";
}

.bi-telephone::before {
  content: "\f5c1";
}

.bi-terminal-fill::before {
  content: "\f5c2";
}

.bi-terminal::before {
  content: "\f5c3";
}

.bi-text-center::before {
  content: "\f5c4";
}

.bi-text-indent-left::before {
  content: "\f5c5";
}

.bi-text-indent-right::before {
  content: "\f5c6";
}

.bi-text-left::before {
  content: "\f5c7";
}

.bi-text-paragraph::before {
  content: "\f5c8";
}

.bi-text-right::before {
  content: "\f5c9";
}

.bi-textarea-resize::before {
  content: "\f5ca";
}

.bi-textarea-t::before {
  content: "\f5cb";
}

.bi-textarea::before {
  content: "\f5cc";
}

.bi-thermometer-half::before {
  content: "\f5cd";
}

.bi-thermometer-high::before {
  content: "\f5ce";
}

.bi-thermometer-low::before {
  content: "\f5cf";
}

.bi-thermometer-snow::before {
  content: "\f5d0";
}

.bi-thermometer-sun::before {
  content: "\f5d1";
}

.bi-thermometer::before {
  content: "\f5d2";
}

.bi-three-dots-vertical::before {
  content: "\f5d3";
}

.bi-three-dots::before {
  content: "\f5d4";
}

.bi-toggle-off::before {
  content: "\f5d5";
}

.bi-toggle-on::before {
  content: "\f5d6";
}

.bi-toggle2-off::before {
  content: "\f5d7";
}

.bi-toggle2-on::before {
  content: "\f5d8";
}

.bi-toggles::before {
  content: "\f5d9";
}

.bi-toggles2::before {
  content: "\f5da";
}

.bi-tools::before {
  content: "\f5db";
}

.bi-tornado::before {
  content: "\f5dc";
}

.bi-trash-fill::before {
  content: "\f5dd";
}

.bi-trash::before {
  content: "\f5de";
}

.bi-trash2-fill::before {
  content: "\f5df";
}

.bi-trash2::before {
  content: "\f5e0";
}

.bi-tree-fill::before {
  content: "\f5e1";
}

.bi-tree::before {
  content: "\f5e2";
}

.bi-triangle-fill::before {
  content: "\f5e3";
}

.bi-triangle-half::before {
  content: "\f5e4";
}

.bi-triangle::before {
  content: "\f5e5";
}

.bi-trophy-fill::before {
  content: "\f5e6";
}

.bi-trophy::before {
  content: "\f5e7";
}

.bi-tropical-storm::before {
  content: "\f5e8";
}

.bi-truck-flatbed::before {
  content: "\f5e9";
}

.bi-truck::before {
  content: "\f5ea";
}

.bi-tsunami::before {
  content: "\f5eb";
}

.bi-tv-fill::before {
  content: "\f5ec";
}

.bi-tv::before {
  content: "\f5ed";
}

.bi-twitch::before {
  content: "\f5ee";
}

.bi-twitter::before {
  content: "\f5ef";
}

.bi-type-bold::before {
  content: "\f5f0";
}

.bi-type-h1::before {
  content: "\f5f1";
}

.bi-type-h2::before {
  content: "\f5f2";
}

.bi-type-h3::before {
  content: "\f5f3";
}

.bi-type-italic::before {
  content: "\f5f4";
}

.bi-type-strikethrough::before {
  content: "\f5f5";
}

.bi-type-underline::before {
  content: "\f5f6";
}

.bi-type::before {
  content: "\f5f7";
}

.bi-ui-checks-grid::before {
  content: "\f5f8";
}

.bi-ui-checks::before {
  content: "\f5f9";
}

.bi-ui-radios-grid::before {
  content: "\f5fa";
}

.bi-ui-radios::before {
  content: "\f5fb";
}

.bi-umbrella-fill::before {
  content: "\f5fc";
}

.bi-umbrella::before {
  content: "\f5fd";
}

.bi-union::before {
  content: "\f5fe";
}

.bi-unlock-fill::before {
  content: "\f5ff";
}

.bi-unlock::before {
  content: "\f600";
}

.bi-upc-scan::before {
  content: "\f601";
}

.bi-upc::before {
  content: "\f602";
}

.bi-upload::before {
  content: "\f603";
}

.bi-vector-pen::before {
  content: "\f604";
}

.bi-view-list::before {
  content: "\f605";
}

.bi-view-stacked::before {
  content: "\f606";
}

.bi-vinyl-fill::before {
  content: "\f607";
}

.bi-vinyl::before {
  content: "\f608";
}

.bi-voicemail::before {
  content: "\f609";
}

.bi-volume-down-fill::before {
  content: "\f60a";
}

.bi-volume-down::before {
  content: "\f60b";
}

.bi-volume-mute-fill::before {
  content: "\f60c";
}

.bi-volume-mute::before {
  content: "\f60d";
}

.bi-volume-off-fill::before {
  content: "\f60e";
}

.bi-volume-off::before {
  content: "\f60f";
}

.bi-volume-up-fill::before {
  content: "\f610";
}

.bi-volume-up::before {
  content: "\f611";
}

.bi-vr::before {
  content: "\f612";
}

.bi-wallet-fill::before {
  content: "\f613";
}

.bi-wallet::before {
  content: "\f614";
}

.bi-wallet2::before {
  content: "\f615";
}

.bi-watch::before {
  content: "\f616";
}

.bi-water::before {
  content: "\f617";
}

.bi-whatsapp::before {
  content: "\f618";
}

.bi-wifi-1::before {
  content: "\f619";
}

.bi-wifi-2::before {
  content: "\f61a";
}

.bi-wifi-off::before {
  content: "\f61b";
}

.bi-wifi::before {
  content: "\f61c";
}

.bi-wind::before {
  content: "\f61d";
}

.bi-window-dock::before {
  content: "\f61e";
}

.bi-window-sidebar::before {
  content: "\f61f";
}

.bi-window::before {
  content: "\f620";
}

.bi-wrench::before {
  content: "\f621";
}

.bi-x-circle-fill::before {
  content: "\f622";
}

.bi-x-circle::before {
  content: "\f623";
}

.bi-x-diamond-fill::before {
  content: "\f624";
}

.bi-x-diamond::before {
  content: "\f625";
}

.bi-x-octagon-fill::before {
  content: "\f626";
}

.bi-x-octagon::before {
  content: "\f627";
}

.bi-x-square-fill::before {
  content: "\f628";
}

.bi-x-square::before {
  content: "\f629";
}

.bi-x::before {
  content: "\f62a";
}

.bi-youtube::before {
  content: "\f62b";
}

.bi-zoom-in::before {
  content: "\f62c";
}

.bi-zoom-out::before {
  content: "\f62d";
}

.bi-bank::before {
  content: "\f62e";
}

.bi-bank2::before {
  content: "\f62f";
}

.bi-bell-slash-fill::before {
  content: "\f630";
}

.bi-bell-slash::before {
  content: "\f631";
}

.bi-cash-coin::before {
  content: "\f632";
}

.bi-check-lg::before {
  content: "\f633";
}

.bi-coin::before {
  content: "\f634";
}

.bi-currency-bitcoin::before {
  content: "\f635";
}

.bi-currency-dollar::before {
  content: "\f636";
}

.bi-currency-euro::before {
  content: "\f637";
}

.bi-currency-exchange::before {
  content: "\f638";
}

.bi-currency-pound::before {
  content: "\f639";
}

.bi-currency-yen::before {
  content: "\f63a";
}

.bi-dash-lg::before {
  content: "\f63b";
}

.bi-exclamation-lg::before {
  content: "\f63c";
}

.bi-file-earmark-pdf-fill::before {
  content: "\f63d";
}

.bi-file-earmark-pdf::before {
  content: "\f63e";
}

.bi-file-pdf-fill::before {
  content: "\f63f";
}

.bi-file-pdf::before {
  content: "\f640";
}

.bi-gender-ambiguous::before {
  content: "\f641";
}

.bi-gender-female::before {
  content: "\f642";
}

.bi-gender-male::before {
  content: "\f643";
}

.bi-gender-trans::before {
  content: "\f644";
}

.bi-headset-vr::before {
  content: "\f645";
}

.bi-info-lg::before {
  content: "\f646";
}

.bi-mastodon::before {
  content: "\f647";
}

.bi-messenger::before {
  content: "\f648";
}

.bi-piggy-bank-fill::before {
  content: "\f649";
}

.bi-piggy-bank::before {
  content: "\f64a";
}

.bi-pin-map-fill::before {
  content: "\f64b";
}

.bi-pin-map::before {
  content: "\f64c";
}

.bi-plus-lg::before {
  content: "\f64d";
}

.bi-question-lg::before {
  content: "\f64e";
}

.bi-recycle::before {
  content: "\f64f";
}

.bi-reddit::before {
  content: "\f650";
}

.bi-safe-fill::before {
  content: "\f651";
}

.bi-safe2-fill::before {
  content: "\f652";
}

.bi-safe2::before {
  content: "\f653";
}

.bi-sd-card-fill::before {
  content: "\f654";
}

.bi-sd-card::before {
  content: "\f655";
}

.bi-skype::before {
  content: "\f656";
}

.bi-slash-lg::before {
  content: "\f657";
}

.bi-translate::before {
  content: "\f658";
}

.bi-x-lg::before {
  content: "\f659";
}

.bi-safe::before {
  content: "\f65a";
}

.bi-apple::before {
  content: "\f65b";
}

.bi-microsoft::before {
  content: "\f65d";
}

.bi-windows::before {
  content: "\f65e";
}

.bi-behance::before {
  content: "\f65c";
}

.bi-dribbble::before {
  content: "\f65f";
}

.bi-line::before {
  content: "\f660";
}

.bi-medium::before {
  content: "\f661";
}

.bi-paypal::before {
  content: "\f662";
}

.bi-pinterest::before {
  content: "\f663";
}

.bi-signal::before {
  content: "\f664";
}

.bi-snapchat::before {
  content: "\f665";
}

.bi-spotify::before {
  content: "\f666";
}

.bi-stack-overflow::before {
  content: "\f667";
}

.bi-strava::before {
  content: "\f668";
}

.bi-wordpress::before {
  content: "\f669";
}

.bi-vimeo::before {
  content: "\f66a";
}

.bi-activity::before {
  content: "\f66b";
}

.bi-easel2-fill::before {
  content: "\f66c";
}

.bi-easel2::before {
  content: "\f66d";
}

.bi-easel3-fill::before {
  content: "\f66e";
}

.bi-easel3::before {
  content: "\f66f";
}

.bi-fan::before {
  content: "\f670";
}

.bi-fingerprint::before {
  content: "\f671";
}

.bi-graph-down-arrow::before {
  content: "\f672";
}

.bi-graph-up-arrow::before {
  content: "\f673";
}

.bi-hypnotize::before {
  content: "\f674";
}

.bi-magic::before {
  content: "\f675";
}

.bi-person-rolodex::before {
  content: "\f676";
}

.bi-person-video::before {
  content: "\f677";
}

.bi-person-video2::before {
  content: "\f678";
}

.bi-person-video3::before {
  content: "\f679";
}

.bi-person-workspace::before {
  content: "\f67a";
}

.bi-radioactive::before {
  content: "\f67b";
}

.bi-webcam-fill::before {
  content: "\f67c";
}

.bi-webcam::before {
  content: "\f67d";
}

.bi-yin-yang::before {
  content: "\f67e";
}

.bi-bandaid-fill::before {
  content: "\f680";
}

.bi-bandaid::before {
  content: "\f681";
}

.bi-bluetooth::before {
  content: "\f682";
}

.bi-body-text::before {
  content: "\f683";
}

.bi-boombox::before {
  content: "\f684";
}

.bi-boxes::before {
  content: "\f685";
}

.bi-dpad-fill::before {
  content: "\f686";
}

.bi-dpad::before {
  content: "\f687";
}

.bi-ear-fill::before {
  content: "\f688";
}

.bi-ear::before {
  content: "\f689";
}

.bi-envelope-check-1::before {
  content: "\f68a";
}

.bi-envelope-check-fill::before {
  content: "\f68b";
}

.bi-envelope-check::before {
  content: "\f68c";
}

.bi-envelope-dash-1::before {
  content: "\f68d";
}

.bi-envelope-dash-fill::before {
  content: "\f68e";
}

.bi-envelope-dash::before {
  content: "\f68f";
}

.bi-envelope-exclamation-1::before {
  content: "\f690";
}

.bi-envelope-exclamation-fill::before {
  content: "\f691";
}

.bi-envelope-exclamation::before {
  content: "\f692";
}

.bi-envelope-plus-fill::before {
  content: "\f693";
}

.bi-envelope-plus::before {
  content: "\f694";
}

.bi-envelope-slash-1::before {
  content: "\f695";
}

.bi-envelope-slash-fill::before {
  content: "\f696";
}

.bi-envelope-slash::before {
  content: "\f697";
}

.bi-envelope-x-1::before {
  content: "\f698";
}

.bi-envelope-x-fill::before {
  content: "\f699";
}

.bi-envelope-x::before {
  content: "\f69a";
}

.bi-explicit-fill::before {
  content: "\f69b";
}

.bi-explicit::before {
  content: "\f69c";
}

.bi-git::before {
  content: "\f69d";
}

.bi-infinity::before {
  content: "\f69e";
}

.bi-list-columns-reverse::before {
  content: "\f69f";
}

.bi-list-columns::before {
  content: "\f6a0";
}

.bi-meta::before {
  content: "\f6a1";
}

.bi-mortorboard-fill::before {
  content: "\f6a2";
}

.bi-mortorboard::before {
  content: "\f6a3";
}

.bi-nintendo-switch::before {
  content: "\f6a4";
}

.bi-pc-display-horizontal::before {
  content: "\f6a5";
}

.bi-pc-display::before {
  content: "\f6a6";
}

.bi-pc-horizontal::before {
  content: "\f6a7";
}

.bi-pc::before {
  content: "\f6a8";
}

.bi-playstation::before {
  content: "\f6a9";
}

.bi-plus-slash-minus::before {
  content: "\f6aa";
}

.bi-projector-fill::before {
  content: "\f6ab";
}

.bi-projector::before {
  content: "\f6ac";
}

.bi-qr-code-scan::before {
  content: "\f6ad";
}

.bi-qr-code::before {
  content: "\f6ae";
}

.bi-quora::before {
  content: "\f6af";
}

.bi-quote::before {
  content: "\f6b0";
}

.bi-robot::before {
  content: "\f6b1";
}

.bi-send-check-fill::before {
  content: "\f6b2";
}

.bi-send-check::before {
  content: "\f6b3";
}

.bi-send-dash-fill::before {
  content: "\f6b4";
}

.bi-send-dash::before {
  content: "\f6b5";
}

.bi-send-exclamation-1::before {
  content: "\f6b6";
}

.bi-send-exclamation-fill::before {
  content: "\f6b7";
}

.bi-send-exclamation::before {
  content: "\f6b8";
}

.bi-send-fill::before {
  content: "\f6b9";
}

.bi-send-plus-fill::before {
  content: "\f6ba";
}

.bi-send-plus::before {
  content: "\f6bb";
}

.bi-send-slash-fill::before {
  content: "\f6bc";
}

.bi-send-slash::before {
  content: "\f6bd";
}

.bi-send-x-fill::before {
  content: "\f6be";
}

.bi-send-x::before {
  content: "\f6bf";
}

.bi-send::before {
  content: "\f6c0";
}

.bi-steam::before {
  content: "\f6c1";
}

.bi-terminal-dash-1::before {
  content: "\f6c2";
}

.bi-terminal-dash::before {
  content: "\f6c3";
}

.bi-terminal-plus::before {
  content: "\f6c4";
}

.bi-terminal-split::before {
  content: "\f6c5";
}

.bi-ticket-detailed-fill::before {
  content: "\f6c6";
}

.bi-ticket-detailed::before {
  content: "\f6c7";
}

.bi-ticket-fill::before {
  content: "\f6c8";
}

.bi-ticket-perforated-fill::before {
  content: "\f6c9";
}

.bi-ticket-perforated::before {
  content: "\f6ca";
}

.bi-ticket::before {
  content: "\f6cb";
}

.bi-tiktok::before {
  content: "\f6cc";
}

.bi-window-dash::before {
  content: "\f6cd";
}

.bi-window-desktop::before {
  content: "\f6ce";
}

.bi-window-fullscreen::before {
  content: "\f6cf";
}

.bi-window-plus::before {
  content: "\f6d0";
}

.bi-window-split::before {
  content: "\f6d1";
}

.bi-window-stack::before {
  content: "\f6d2";
}

.bi-window-x::before {
  content: "\f6d3";
}

.bi-xbox::before {
  content: "\f6d4";
}

.bi-ethernet::before {
  content: "\f6d5";
}

.bi-hdmi-fill::before {
  content: "\f6d6";
}

.bi-hdmi::before {
  content: "\f6d7";
}

.bi-usb-c-fill::before {
  content: "\f6d8";
}

.bi-usb-c::before {
  content: "\f6d9";
}

.bi-usb-fill::before {
  content: "\f6da";
}

.bi-usb-plug-fill::before {
  content: "\f6db";
}

.bi-usb-plug::before {
  content: "\f6dc";
}

.bi-usb-symbol::before {
  content: "\f6dd";
}

.bi-usb::before {
  content: "\f6de";
}

.bi-boombox-fill::before {
  content: "\f6df";
}

.bi-displayport-1::before {
  content: "\f6e0";
}

.bi-displayport::before {
  content: "\f6e1";
}

.bi-gpu-card::before {
  content: "\f6e2";
}

.bi-memory::before {
  content: "\f6e3";
}

.bi-modem-fill::before {
  content: "\f6e4";
}

.bi-modem::before {
  content: "\f6e5";
}

.bi-motherboard-fill::before {
  content: "\f6e6";
}

.bi-motherboard::before {
  content: "\f6e7";
}

.bi-optical-audio-fill::before {
  content: "\f6e8";
}

.bi-optical-audio::before {
  content: "\f6e9";
}

.bi-pci-card::before {
  content: "\f6ea";
}

.bi-router-fill::before {
  content: "\f6eb";
}

.bi-router::before {
  content: "\f6ec";
}

.bi-ssd-fill::before {
  content: "\f6ed";
}

.bi-ssd::before {
  content: "\f6ee";
}

.bi-thunderbolt-fill::before {
  content: "\f6ef";
}

.bi-thunderbolt::before {
  content: "\f6f0";
}

.bi-usb-drive-fill::before {
  content: "\f6f1";
}

.bi-usb-drive::before {
  content: "\f6f2";
}

.bi-usb-micro-fill::before {
  content: "\f6f3";
}

.bi-usb-micro::before {
  content: "\f6f4";
}

.bi-usb-mini-fill::before {
  content: "\f6f5";
}

.bi-usb-mini::before {
  content: "\f6f6";
}

.bi-cloud-haze2::before {
  content: "\f6f7";
}

.bi-device-hdd-fill::before {
  content: "\f6f8";
}

.bi-device-hdd::before {
  content: "\f6f9";
}

.bi-device-ssd-fill::before {
  content: "\f6fa";
}

.bi-device-ssd::before {
  content: "\f6fb";
}

.bi-displayport-fill::before {
  content: "\f6fc";
}

.bi-mortarboard-fill::before {
  content: "\f6fd";
}

.bi-mortarboard::before {
  content: "\f6fe";
}

.bi-terminal-x::before {
  content: "\f6ff";
}

.bi-arrow-through-heart-fill::before {
  content: "\f700";
}

.bi-arrow-through-heart::before {
  content: "\f701";
}

.bi-badge-sd-fill::before {
  content: "\f702";
}

.bi-badge-sd::before {
  content: "\f703";
}

.bi-bag-heart-fill::before {
  content: "\f704";
}

.bi-bag-heart::before {
  content: "\f705";
}

.bi-balloon-fill::before {
  content: "\f706";
}

.bi-balloon-heart-fill::before {
  content: "\f707";
}

.bi-balloon-heart::before {
  content: "\f708";
}

.bi-balloon::before {
  content: "\f709";
}

.bi-box2-fill::before {
  content: "\f70a";
}

.bi-box2-heart-fill::before {
  content: "\f70b";
}

.bi-box2-heart::before {
  content: "\f70c";
}

.bi-box2::before {
  content: "\f70d";
}

.bi-braces-asterisk::before {
  content: "\f70e";
}

.bi-calendar-heart-fill::before {
  content: "\f70f";
}

.bi-calendar-heart::before {
  content: "\f710";
}

.bi-calendar2-heart-fill::before {
  content: "\f711";
}

.bi-calendar2-heart::before {
  content: "\f712";
}

.bi-chat-heart-fill::before {
  content: "\f713";
}

.bi-chat-heart::before {
  content: "\f714";
}

.bi-chat-left-heart-fill::before {
  content: "\f715";
}

.bi-chat-left-heart::before {
  content: "\f716";
}

.bi-chat-right-heart-fill::before {
  content: "\f717";
}

.bi-chat-right-heart::before {
  content: "\f718";
}

.bi-chat-square-heart-fill::before {
  content: "\f719";
}

.bi-chat-square-heart::before {
  content: "\f71a";
}

.bi-clipboard-check-fill::before {
  content: "\f71b";
}

.bi-clipboard-data-fill::before {
  content: "\f71c";
}

.bi-clipboard-fill::before {
  content: "\f71d";
}

.bi-clipboard-heart-fill::before {
  content: "\f71e";
}

.bi-clipboard-heart::before {
  content: "\f71f";
}

.bi-clipboard-minus-fill::before {
  content: "\f720";
}

.bi-clipboard-plus-fill::before {
  content: "\f721";
}

.bi-clipboard-pulse::before {
  content: "\f722";
}

.bi-clipboard-x-fill::before {
  content: "\f723";
}

.bi-clipboard2-check-fill::before {
  content: "\f724";
}

.bi-clipboard2-check::before {
  content: "\f725";
}

.bi-clipboard2-data-fill::before {
  content: "\f726";
}

.bi-clipboard2-data::before {
  content: "\f727";
}

.bi-clipboard2-fill::before {
  content: "\f728";
}

.bi-clipboard2-heart-fill::before {
  content: "\f729";
}

.bi-clipboard2-heart::before {
  content: "\f72a";
}

.bi-clipboard2-minus-fill::before {
  content: "\f72b";
}

.bi-clipboard2-minus::before {
  content: "\f72c";
}

.bi-clipboard2-plus-fill::before {
  content: "\f72d";
}

.bi-clipboard2-plus::before {
  content: "\f72e";
}

.bi-clipboard2-pulse-fill::before {
  content: "\f72f";
}

.bi-clipboard2-pulse::before {
  content: "\f730";
}

.bi-clipboard2-x-fill::before {
  content: "\f731";
}

.bi-clipboard2-x::before {
  content: "\f732";
}

.bi-clipboard2::before {
  content: "\f733";
}

.bi-emoji-kiss-fill::before {
  content: "\f734";
}

.bi-emoji-kiss::before {
  content: "\f735";
}

.bi-envelope-heart-fill::before {
  content: "\f736";
}

.bi-envelope-heart::before {
  content: "\f737";
}

.bi-envelope-open-heart-fill::before {
  content: "\f738";
}

.bi-envelope-open-heart::before {
  content: "\f739";
}

.bi-envelope-paper-fill::before {
  content: "\f73a";
}

.bi-envelope-paper-heart-fill::before {
  content: "\f73b";
}

.bi-envelope-paper-heart::before {
  content: "\f73c";
}

.bi-envelope-paper::before {
  content: "\f73d";
}

.bi-filetype-aac::before {
  content: "\f73e";
}

.bi-filetype-ai::before {
  content: "\f73f";
}

.bi-filetype-bmp::before {
  content: "\f740";
}

.bi-filetype-cs::before {
  content: "\f741";
}

.bi-filetype-css::before {
  content: "\f742";
}

.bi-filetype-csv::before {
  content: "\f743";
}

.bi-filetype-doc::before {
  content: "\f744";
}

.bi-filetype-docx::before {
  content: "\f745";
}

.bi-filetype-exe::before {
  content: "\f746";
}

.bi-filetype-gif::before {
  content: "\f747";
}

.bi-filetype-heic::before {
  content: "\f748";
}

.bi-filetype-html::before {
  content: "\f749";
}

.bi-filetype-java::before {
  content: "\f74a";
}

.bi-filetype-jpg::before {
  content: "\f74b";
}

.bi-filetype-js::before {
  content: "\f74c";
}

.bi-filetype-jsx::before {
  content: "\f74d";
}

.bi-filetype-key::before {
  content: "\f74e";
}

.bi-filetype-m4p::before {
  content: "\f74f";
}

.bi-filetype-md::before {
  content: "\f750";
}

.bi-filetype-mdx::before {
  content: "\f751";
}

.bi-filetype-mov::before {
  content: "\f752";
}

.bi-filetype-mp3::before {
  content: "\f753";
}

.bi-filetype-mp4::before {
  content: "\f754";
}

.bi-filetype-otf::before {
  content: "\f755";
}

.bi-filetype-pdf::before {
  content: "\f756";
}

.bi-filetype-php::before {
  content: "\f757";
}

.bi-filetype-png::before {
  content: "\f758";
}

.bi-filetype-ppt-1::before {
  content: "\f759";
}

.bi-filetype-ppt::before {
  content: "\f75a";
}

.bi-filetype-psd::before {
  content: "\f75b";
}

.bi-filetype-py::before {
  content: "\f75c";
}

.bi-filetype-raw::before {
  content: "\f75d";
}

.bi-filetype-rb::before {
  content: "\f75e";
}

.bi-filetype-sass::before {
  content: "\f75f";
}

.bi-filetype-scss::before {
  content: "\f760";
}

.bi-filetype-sh::before {
  content: "\f761";
}

.bi-filetype-svg::before {
  content: "\f762";
}

.bi-filetype-tiff::before {
  content: "\f763";
}

.bi-filetype-tsx::before {
  content: "\f764";
}

.bi-filetype-ttf::before {
  content: "\f765";
}

.bi-filetype-txt::before {
  content: "\f766";
}

.bi-filetype-wav::before {
  content: "\f767";
}

.bi-filetype-woff::before {
  content: "\f768";
}

.bi-filetype-xls-1::before {
  content: "\f769";
}

.bi-filetype-xls::before {
  content: "\f76a";
}

.bi-filetype-xml::before {
  content: "\f76b";
}

.bi-filetype-yml::before {
  content: "\f76c";
}

.bi-heart-arrow::before {
  content: "\f76d";
}

.bi-heart-pulse-fill::before {
  content: "\f76e";
}

.bi-heart-pulse::before {
  content: "\f76f";
}

.bi-heartbreak-fill::before {
  content: "\f770";
}

.bi-heartbreak::before {
  content: "\f771";
}

.bi-hearts::before {
  content: "\f772";
}

.bi-hospital-fill::before {
  content: "\f773";
}

.bi-hospital::before {
  content: "\f774";
}

.bi-house-heart-fill::before {
  content: "\f775";
}

.bi-house-heart::before {
  content: "\f776";
}

.bi-incognito::before {
  content: "\f777";
}

.bi-magnet-fill::before {
  content: "\f778";
}

.bi-magnet::before {
  content: "\f779";
}

.bi-person-heart::before {
  content: "\f77a";
}

.bi-person-hearts::before {
  content: "\f77b";
}

.bi-phone-flip::before {
  content: "\f77c";
}

.bi-plugin::before {
  content: "\f77d";
}

.bi-postage-fill::before {
  content: "\f77e";
}

.bi-postage-heart-fill::before {
  content: "\f77f";
}

.bi-postage-heart::before {
  content: "\f780";
}

.bi-postage::before {
  content: "\f781";
}

.bi-postcard-fill::before {
  content: "\f782";
}

.bi-postcard-heart-fill::before {
  content: "\f783";
}

.bi-postcard-heart::before {
  content: "\f784";
}

.bi-postcard::before {
  content: "\f785";
}

.bi-search-heart-fill::before {
  content: "\f786";
}

.bi-search-heart::before {
  content: "\f787";
}

.bi-sliders2-vertical::before {
  content: "\f788";
}

.bi-sliders2::before {
  content: "\f789";
}

.bi-trash3-fill::before {
  content: "\f78a";
}

.bi-trash3::before {
  content: "\f78b";
}

.bi-valentine::before {
  content: "\f78c";
}

.bi-valentine2::before {
  content: "\f78d";
}

.bi-wrench-adjustable-circle-fill::before {
  content: "\f78e";
}

.bi-wrench-adjustable-circle::before {
  content: "\f78f";
}

.bi-wrench-adjustable::before {
  content: "\f790";
}

.bi-filetype-json::before {
  content: "\f791";
}

.bi-filetype-pptx::before {
  content: "\f792";
}

.bi-filetype-xlsx::before {
  content: "\f793";
}

.bi-1-circle-1::before {
  content: "\f794";
}

.bi-1-circle-fill-1::before {
  content: "\f795";
}

.bi-1-circle-fill::before {
  content: "\f796";
}

.bi-1-circle::before {
  content: "\f797";
}

.bi-1-square-fill::before {
  content: "\f798";
}

.bi-1-square::before {
  content: "\f799";
}

.bi-2-circle-1::before {
  content: "\f79a";
}

.bi-2-circle-fill-1::before {
  content: "\f79b";
}

.bi-2-circle-fill::before {
  content: "\f79c";
}

.bi-2-circle::before {
  content: "\f79d";
}

.bi-2-square-fill::before {
  content: "\f79e";
}

.bi-2-square::before {
  content: "\f79f";
}

.bi-3-circle-1::before {
  content: "\f7a0";
}

.bi-3-circle-fill-1::before {
  content: "\f7a1";
}

.bi-3-circle-fill::before {
  content: "\f7a2";
}

.bi-3-circle::before {
  content: "\f7a3";
}

.bi-3-square-fill::before {
  content: "\f7a4";
}

.bi-3-square::before {
  content: "\f7a5";
}

.bi-4-circle-1::before {
  content: "\f7a6";
}

.bi-4-circle-fill-1::before {
  content: "\f7a7";
}

.bi-4-circle-fill::before {
  content: "\f7a8";
}

.bi-4-circle::before {
  content: "\f7a9";
}

.bi-4-square-fill::before {
  content: "\f7aa";
}

.bi-4-square::before {
  content: "\f7ab";
}

.bi-5-circle-1::before {
  content: "\f7ac";
}

.bi-5-circle-fill-1::before {
  content: "\f7ad";
}

.bi-5-circle-fill::before {
  content: "\f7ae";
}

.bi-5-circle::before {
  content: "\f7af";
}

.bi-5-square-fill::before {
  content: "\f7b0";
}

.bi-5-square::before {
  content: "\f7b1";
}

.bi-6-circle-1::before {
  content: "\f7b2";
}

.bi-6-circle-fill-1::before {
  content: "\f7b3";
}

.bi-6-circle-fill::before {
  content: "\f7b4";
}

.bi-6-circle::before {
  content: "\f7b5";
}

.bi-6-square-fill::before {
  content: "\f7b6";
}

.bi-6-square::before {
  content: "\f7b7";
}

.bi-7-circle-1::before {
  content: "\f7b8";
}

.bi-7-circle-fill-1::before {
  content: "\f7b9";
}

.bi-7-circle-fill::before {
  content: "\f7ba";
}

.bi-7-circle::before {
  content: "\f7bb";
}

.bi-7-square-fill::before {
  content: "\f7bc";
}

.bi-7-square::before {
  content: "\f7bd";
}

.bi-8-circle-1::before {
  content: "\f7be";
}

.bi-8-circle-fill-1::before {
  content: "\f7bf";
}

.bi-8-circle-fill::before {
  content: "\f7c0";
}

.bi-8-circle::before {
  content: "\f7c1";
}

.bi-8-square-fill::before {
  content: "\f7c2";
}

.bi-8-square::before {
  content: "\f7c3";
}

.bi-9-circle-1::before {
  content: "\f7c4";
}

.bi-9-circle-fill-1::before {
  content: "\f7c5";
}

.bi-9-circle-fill::before {
  content: "\f7c6";
}

.bi-9-circle::before {
  content: "\f7c7";
}

.bi-9-square-fill::before {
  content: "\f7c8";
}

.bi-9-square::before {
  content: "\f7c9";
}

.bi-airplane-engines-fill::before {
  content: "\f7ca";
}

.bi-airplane-engines::before {
  content: "\f7cb";
}

.bi-airplane-fill::before {
  content: "\f7cc";
}

.bi-airplane::before {
  content: "\f7cd";
}

.bi-alexa::before {
  content: "\f7ce";
}

.bi-alipay::before {
  content: "\f7cf";
}

.bi-android::before {
  content: "\f7d0";
}

.bi-android2::before {
  content: "\f7d1";
}

.bi-box-fill::before {
  content: "\f7d2";
}

.bi-box-seam-fill::before {
  content: "\f7d3";
}

.bi-browser-chrome::before {
  content: "\f7d4";
}

.bi-browser-edge::before {
  content: "\f7d5";
}

.bi-browser-firefox::before {
  content: "\f7d6";
}

.bi-browser-safari::before {
  content: "\f7d7";
}

.bi-c-circle-1::before {
  content: "\f7d8";
}

.bi-c-circle-fill-1::before {
  content: "\f7d9";
}

.bi-c-circle-fill::before {
  content: "\f7da";
}

.bi-c-circle::before {
  content: "\f7db";
}

.bi-c-square-fill::before {
  content: "\f7dc";
}

.bi-c-square::before {
  content: "\f7dd";
}

.bi-capsule-pill::before {
  content: "\f7de";
}

.bi-capsule::before {
  content: "\f7df";
}

.bi-car-front-fill::before {
  content: "\f7e0";
}

.bi-car-front::before {
  content: "\f7e1";
}

.bi-cassette-fill::before {
  content: "\f7e2";
}

.bi-cassette::before {
  content: "\f7e3";
}

.bi-cc-circle-1::before {
  content: "\f7e4";
}

.bi-cc-circle-fill-1::before {
  content: "\f7e5";
}

.bi-cc-circle-fill::before {
  content: "\f7e6";
}

.bi-cc-circle::before {
  content: "\f7e7";
}

.bi-cc-square-fill::before {
  content: "\f7e8";
}

.bi-cc-square::before {
  content: "\f7e9";
}

.bi-cup-hot-fill::before {
  content: "\f7ea";
}

.bi-cup-hot::before {
  content: "\f7eb";
}

.bi-currency-rupee::before {
  content: "\f7ec";
}

.bi-dropbox::before {
  content: "\f7ed";
}

.bi-escape::before {
  content: "\f7ee";
}

.bi-fast-forward-btn-fill::before {
  content: "\f7ef";
}

.bi-fast-forward-btn::before {
  content: "\f7f0";
}

.bi-fast-forward-circle-fill::before {
  content: "\f7f1";
}

.bi-fast-forward-circle::before {
  content: "\f7f2";
}

.bi-fast-forward-fill::before {
  content: "\f7f3";
}

.bi-fast-forward::before {
  content: "\f7f4";
}

.bi-filetype-sql::before {
  content: "\f7f5";
}

.bi-fire::before {
  content: "\f7f6";
}

.bi-google-play::before {
  content: "\f7f7";
}

.bi-h-circle-1::before {
  content: "\f7f8";
}

.bi-h-circle-fill-1::before {
  content: "\f7f9";
}

.bi-h-circle-fill::before {
  content: "\f7fa";
}

.bi-h-circle::before {
  content: "\f7fb";
}

.bi-h-square-fill::before {
  content: "\f7fc";
}

.bi-h-square::before {
  content: "\f7fd";
}

.bi-indent::before {
  content: "\f7fe";
}

.bi-lungs-fill::before {
  content: "\f7ff";
}

.bi-lungs::before {
  content: "\f800";
}

.bi-microsoft-teams::before {
  content: "\f801";
}

.bi-p-circle-1::before {
  content: "\f802";
}

.bi-p-circle-fill-1::before {
  content: "\f803";
}

.bi-p-circle-fill::before {
  content: "\f804";
}

.bi-p-circle::before {
  content: "\f805";
}

.bi-p-square-fill::before {
  content: "\f806";
}

.bi-p-square::before {
  content: "\f807";
}

.bi-pass-fill::before {
  content: "\f808";
}

.bi-pass::before {
  content: "\f809";
}

.bi-prescription::before {
  content: "\f80a";
}

.bi-prescription2::before {
  content: "\f80b";
}

.bi-r-circle-1::before {
  content: "\f80c";
}

.bi-r-circle-fill-1::before {
  content: "\f80d";
}

.bi-r-circle-fill::before {
  content: "\f80e";
}

.bi-r-circle::before {
  content: "\f80f";
}

.bi-r-square-fill::before {
  content: "\f810";
}

.bi-r-square::before {
  content: "\f811";
}

.bi-repeat-1::before {
  content: "\f812";
}

.bi-repeat::before {
  content: "\f813";
}

.bi-rewind-btn-fill::before {
  content: "\f814";
}

.bi-rewind-btn::before {
  content: "\f815";
}

.bi-rewind-circle-fill::before {
  content: "\f816";
}

.bi-rewind-circle::before {
  content: "\f817";
}

.bi-rewind-fill::before {
  content: "\f818";
}

.bi-rewind::before {
  content: "\f819";
}

.bi-train-freight-front-fill::before {
  content: "\f81a";
}

.bi-train-freight-front::before {
  content: "\f81b";
}

.bi-train-front-fill::before {
  content: "\f81c";
}

.bi-train-front::before {
  content: "\f81d";
}

.bi-train-lightrail-front-fill::before {
  content: "\f81e";
}

.bi-train-lightrail-front::before {
  content: "\f81f";
}

.bi-truck-front-fill::before {
  content: "\f820";
}

.bi-truck-front::before {
  content: "\f821";
}

.bi-ubuntu::before {
  content: "\f822";
}

.bi-unindent::before {
  content: "\f823";
}

.bi-unity::before {
  content: "\f824";
}

.bi-universal-access-circle::before {
  content: "\f825";
}

.bi-universal-access::before {
  content: "\f826";
}

.bi-virus::before {
  content: "\f827";
}

.bi-virus2::before {
  content: "\f828";
}

.bi-wechat::before {
  content: "\f829";
}

.bi-yelp::before {
  content: "\f82a";
}

.bi-sign-stop-fill::before {
  content: "\f82b";
}

.bi-sign-stop-lights-fill::before {
  content: "\f82c";
}

.bi-sign-stop-lights::before {
  content: "\f82d";
}

.bi-sign-stop::before {
  content: "\f82e";
}

.bi-sign-turn-left-fill::before {
  content: "\f82f";
}

.bi-sign-turn-left::before {
  content: "\f830";
}

.bi-sign-turn-right-fill::before {
  content: "\f831";
}

.bi-sign-turn-right::before {
  content: "\f832";
}

.bi-sign-turn-slight-left-fill::before {
  content: "\f833";
}

.bi-sign-turn-slight-left::before {
  content: "\f834";
}

.bi-sign-turn-slight-right-fill::before {
  content: "\f835";
}

.bi-sign-turn-slight-right::before {
  content: "\f836";
}

.bi-sign-yield-fill::before {
  content: "\f837";
}

.bi-sign-yield::before {
  content: "\f838";
}

.bi-ev-station-fill::before {
  content: "\f839";
}

.bi-ev-station::before {
  content: "\f83a";
}

.bi-fuel-pump-diesel-fill::before {
  content: "\f83b";
}

.bi-fuel-pump-diesel::before {
  content: "\f83c";
}

.bi-fuel-pump-fill::before {
  content: "\f83d";
}

.bi-fuel-pump::before {
  content: "\f83e";
}

.bi-0-circle-fill::before {
  content: "\f83f";
}

.bi-0-circle::before {
  content: "\f840";
}

.bi-0-square-fill::before {
  content: "\f841";
}

.bi-0-square::before {
  content: "\f842";
}

.bi-rocket-fill::before {
  content: "\f843";
}

.bi-rocket-takeoff-fill::before {
  content: "\f844";
}

.bi-rocket-takeoff::before {
  content: "\f845";
}

.bi-rocket::before {
  content: "\f846";
}

.bi-stripe::before {
  content: "\f847";
}

.bi-subscript::before {
  content: "\f848";
}

.bi-superscript::before {
  content: "\f849";
}

.bi-trello::before {
  content: "\f84a";
}

.bi-envelope-at-fill::before {
  content: "\f84b";
}

.bi-envelope-at::before {
  content: "\f84c";
}

.bi-regex::before {
  content: "\f84d";
}

.bi-text-wrap::before {
  content: "\f84e";
}

.bi-sign-dead-end-fill::before {
  content: "\f84f";
}

.bi-sign-dead-end::before {
  content: "\f850";
}

.bi-sign-do-not-enter-fill::before {
  content: "\f851";
}

.bi-sign-do-not-enter::before {
  content: "\f852";
}

.bi-sign-intersection-fill::before {
  content: "\f853";
}

.bi-sign-intersection-side-fill::before {
  content: "\f854";
}

.bi-sign-intersection-side::before {
  content: "\f855";
}

.bi-sign-intersection-t-fill::before {
  content: "\f856";
}

.bi-sign-intersection-t::before {
  content: "\f857";
}

.bi-sign-intersection-y-fill::before {
  content: "\f858";
}

.bi-sign-intersection-y::before {
  content: "\f859";
}

.bi-sign-intersection::before {
  content: "\f85a";
}

.bi-sign-merge-left-fill::before {
  content: "\f85b";
}

.bi-sign-merge-left::before {
  content: "\f85c";
}

.bi-sign-merge-right-fill::before {
  content: "\f85d";
}

.bi-sign-merge-right::before {
  content: "\f85e";
}

.bi-sign-no-left-turn-fill::before {
  content: "\f85f";
}

.bi-sign-no-left-turn::before {
  content: "\f860";
}

.bi-sign-no-parking-fill::before {
  content: "\f861";
}

.bi-sign-no-parking::before {
  content: "\f862";
}

.bi-sign-no-right-turn-fill::before {
  content: "\f863";
}

.bi-sign-no-right-turn::before {
  content: "\f864";
}

.bi-sign-railroad-fill::before {
  content: "\f865";
}

.bi-sign-railroad::before {
  content: "\f866";
}

.bi-building-add::before {
  content: "\f867";
}

.bi-building-check::before {
  content: "\f868";
}

.bi-building-dash::before {
  content: "\f869";
}

.bi-building-down::before {
  content: "\f86a";
}

.bi-building-exclamation::before {
  content: "\f86b";
}

.bi-building-fill-add::before {
  content: "\f86c";
}

.bi-building-fill-check::before {
  content: "\f86d";
}

.bi-building-fill-dash::before {
  content: "\f86e";
}

.bi-building-fill-down::before {
  content: "\f86f";
}

.bi-building-fill-exclamation::before {
  content: "\f870";
}

.bi-building-fill-gear::before {
  content: "\f871";
}

.bi-building-fill-lock::before {
  content: "\f872";
}

.bi-building-fill-slash::before {
  content: "\f873";
}

.bi-building-fill-up::before {
  content: "\f874";
}

.bi-building-fill-x::before {
  content: "\f875";
}

.bi-building-fill::before {
  content: "\f876";
}

.bi-building-gear::before {
  content: "\f877";
}

.bi-building-lock::before {
  content: "\f878";
}

.bi-building-slash::before {
  content: "\f879";
}

.bi-building-up::before {
  content: "\f87a";
}

.bi-building-x::before {
  content: "\f87b";
}

.bi-buildings-fill::before {
  content: "\f87c";
}

.bi-buildings::before {
  content: "\f87d";
}

.bi-bus-front-fill::before {
  content: "\f87e";
}

.bi-bus-front::before {
  content: "\f87f";
}

.bi-ev-front-fill::before {
  content: "\f880";
}

.bi-ev-front::before {
  content: "\f881";
}

.bi-globe-americas::before {
  content: "\f882";
}

.bi-globe-asia-australia::before {
  content: "\f883";
}

.bi-globe-central-south-asia::before {
  content: "\f884";
}

.bi-globe-europe-africa::before {
  content: "\f885";
}

.bi-house-add-fill::before {
  content: "\f886";
}

.bi-house-add::before {
  content: "\f887";
}

.bi-house-check-fill::before {
  content: "\f888";
}

.bi-house-check::before {
  content: "\f889";
}

.bi-house-dash-fill::before {
  content: "\f88a";
}

.bi-house-dash::before {
  content: "\f88b";
}

.bi-house-down-fill::before {
  content: "\f88c";
}

.bi-house-down::before {
  content: "\f88d";
}

.bi-house-exclamation-fill::before {
  content: "\f88e";
}

.bi-house-exclamation::before {
  content: "\f88f";
}

.bi-house-gear-fill::before {
  content: "\f890";
}

.bi-house-gear::before {
  content: "\f891";
}

.bi-house-lock-fill::before {
  content: "\f892";
}

.bi-house-lock::before {
  content: "\f893";
}

.bi-house-slash-fill::before {
  content: "\f894";
}

.bi-house-slash::before {
  content: "\f895";
}

.bi-house-up-fill::before {
  content: "\f896";
}

.bi-house-up::before {
  content: "\f897";
}

.bi-house-x-fill::before {
  content: "\f898";
}

.bi-house-x::before {
  content: "\f899";
}

.bi-person-add::before {
  content: "\f89a";
}

.bi-person-down::before {
  content: "\f89b";
}

.bi-person-exclamation::before {
  content: "\f89c";
}

.bi-person-fill-add::before {
  content: "\f89d";
}

.bi-person-fill-check::before {
  content: "\f89e";
}

.bi-person-fill-dash::before {
  content: "\f89f";
}

.bi-person-fill-down::before {
  content: "\f8a0";
}

.bi-person-fill-exclamation::before {
  content: "\f8a1";
}

.bi-person-fill-gear::before {
  content: "\f8a2";
}

.bi-person-fill-lock::before {
  content: "\f8a3";
}

.bi-person-fill-slash::before {
  content: "\f8a4";
}

.bi-person-fill-up::before {
  content: "\f8a5";
}

.bi-person-fill-x::before {
  content: "\f8a6";
}

.bi-person-gear::before {
  content: "\f8a7";
}

.bi-person-lock::before {
  content: "\f8a8";
}

.bi-person-slash::before {
  content: "\f8a9";
}

.bi-person-up::before {
  content: "\f8aa";
}

.bi-scooter::before {
  content: "\f8ab";
}

.bi-taxi-front-fill::before {
  content: "\f8ac";
}

.bi-taxi-front::before {
  content: "\f8ad";
}

.bi-amd::before {
  content: "\f8ae";
}

.bi-database-add::before {
  content: "\f8af";
}

.bi-database-check::before {
  content: "\f8b0";
}

.bi-database-dash::before {
  content: "\f8b1";
}

.bi-database-down::before {
  content: "\f8b2";
}

.bi-database-exclamation::before {
  content: "\f8b3";
}

.bi-database-fill-add::before {
  content: "\f8b4";
}

.bi-database-fill-check::before {
  content: "\f8b5";
}

.bi-database-fill-dash::before {
  content: "\f8b6";
}

.bi-database-fill-down::before {
  content: "\f8b7";
}

.bi-database-fill-exclamation::before {
  content: "\f8b8";
}

.bi-database-fill-gear::before {
  content: "\f8b9";
}

.bi-database-fill-lock::before {
  content: "\f8ba";
}

.bi-database-fill-slash::before {
  content: "\f8bb";
}

.bi-database-fill-up::before {
  content: "\f8bc";
}

.bi-database-fill-x::before {
  content: "\f8bd";
}

.bi-database-fill::before {
  content: "\f8be";
}

.bi-database-gear::before {
  content: "\f8bf";
}

.bi-database-lock::before {
  content: "\f8c0";
}

.bi-database-slash::before {
  content: "\f8c1";
}

.bi-database-up::before {
  content: "\f8c2";
}

.bi-database-x::before {
  content: "\f8c3";
}

.bi-database::before {
  content: "\f8c4";
}

.bi-houses-fill::before {
  content: "\f8c5";
}

.bi-houses::before {
  content: "\f8c6";
}

.bi-nvidia::before {
  content: "\f8c7";
}

.bi-person-vcard-fill::before {
  content: "\f8c8";
}

.bi-person-vcard::before {
  content: "\f8c9";
}

.bi-sina-weibo::before {
  content: "\f8ca";
}

.bi-tencent-qq::before {
  content: "\f8cb";
}

.bi-wikipedia::before {
  content: "\f8cc";
}

/*--------------------------------

Phosphor Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: "Phosphor";
  src: url("../fonts/Phosphor.eot");
  src: url("../fonts/Phosphor.eot") format("embedded-opentype"), url("../fonts/Phosphor.woff2") format("woff2"), url("../fonts/Phosphor.woff") format("woff"), url("../fonts/Phosphor.ttf") format("truetype"), url("../fonts/Phosphor.svg?#Phosphor") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/*------------------------
	base class definition
-------------------------*/
[class^=ph-],
[class*=" ph-"] {
  display: inline-flex;
}

[class^=ph-]:before,
[class*=" ph-"]:before {
  font: normal normal normal 1em/1 "Phosphor";
  color: inherit;
  flex-shrink: 0;
  speak: none;
  text-transform: none;
  text-decoration: inherit;
  text-align: center;
  line-height: initial;
  vertical-align: middle;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/
/* relative units */
.ph-xxs {
  font-size: 0.5em;
}

.ph-xs {
  font-size: 0.75em;
}

.ph-sm {
  font-size: 0.875em;
}

.ph-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.ph-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -0.075em;
}

.ph-1x {
  font-size: 1em;
}

.ph-2x {
  font-size: 2em;
}

.ph-3x {
  font-size: 3em;
}

.ph-4x {
  font-size: 4em;
}

.ph-5x {
  font-size: 5em;
}

.ph-6x {
  font-size: 6em;
}

.ph-7x {
  font-size: 7em;
}

.ph-8x {
  font-size: 8em;
}

.ph-9x {
  font-size: 9em;
}

.ph-10x {
  font-size: 10em;
}

.ph-fw {
  text-align: center;
  width: 1.25em;
}

/*------------------------
	icons
-------------------------*/
.ph-activity-thin::before {
  content: "\ea02";
}

.ph-address-book-thin::before {
  content: "\ea03";
}

.ph-airplane-in-flight-thin::before {
  content: "\ea04";
}

.ph-airplane-landing-thin::before {
  content: "\ea05";
}

.ph-airplane-takeoff-thin::before {
  content: "\ea06";
}

.ph-airplane-thin::before {
  content: "\ea07";
}

.ph-airplane-tilt-thin::before {
  content: "\ea08";
}

.ph-airplay-thin::before {
  content: "\ea09";
}

.ph-alarm-thin::before {
  content: "\ea0a";
}

.ph-alien-thin::before {
  content: "\ea0b";
}

.ph-align-bottom-simple-thin::before {
  content: "\ea0c";
}

.ph-align-bottom-thin::before {
  content: "\ea0d";
}

.ph-align-center-horizontal-simple-thin::before {
  content: "\ea0e";
}

.ph-align-center-horizontal-thin::before {
  content: "\ea0f";
}

.ph-align-center-vertical-simple-thin::before {
  content: "\ea10";
}

.ph-align-center-vertical-thin::before {
  content: "\ea11";
}

.ph-align-left-simple-thin::before {
  content: "\ea12";
}

.ph-align-left-thin::before {
  content: "\ea13";
}

.ph-align-right-simple-thin::before {
  content: "\ea14";
}

.ph-align-right-thin::before {
  content: "\ea15";
}

.ph-align-top-simple-thin::before {
  content: "\ea16";
}

.ph-align-top-thin::before {
  content: "\ea17";
}

.ph-anchor-simple-thin::before {
  content: "\ea18";
}

.ph-anchor-thin::before {
  content: "\ea19";
}

.ph-android-logo-thin::before {
  content: "\ea1a";
}

.ph-angular-logo-thin::before {
  content: "\ea1b";
}

.ph-aperture-thin::before {
  content: "\ea1c";
}

.ph-app-store-logo-thin::before {
  content: "\ea1d";
}

.ph-app-window-thin::before {
  content: "\ea1e";
}

.ph-apple-logo-thin::before {
  content: "\ea1f";
}

.ph-apple-podcasts-logo-thin::before {
  content: "\ea20";
}

.ph-archive-box-thin::before {
  content: "\ea21";
}

.ph-archive-thin::before {
  content: "\ea22";
}

.ph-archive-tray-thin::before {
  content: "\ea23";
}

.ph-armchair-thin::before {
  content: "\ea24";
}

.ph-arrow-arc-left-thin::before {
  content: "\ea25";
}

.ph-arrow-arc-right-thin::before {
  content: "\ea26";
}

.ph-arrow-bend-double-up-left-thin::before {
  content: "\ea27";
}

.ph-arrow-bend-double-up-right-thin::before {
  content: "\ea28";
}

.ph-arrow-bend-down-left-thin::before {
  content: "\ea29";
}

.ph-arrow-bend-down-right-thin::before {
  content: "\ea2a";
}

.ph-arrow-bend-left-down-thin::before {
  content: "\ea2b";
}

.ph-arrow-bend-left-up-thin::before {
  content: "\ea2c";
}

.ph-arrow-bend-right-down-thin::before {
  content: "\ea2d";
}

.ph-arrow-bend-right-up-thin::before {
  content: "\ea2e";
}

.ph-arrow-bend-up-left-thin::before {
  content: "\ea2f";
}

.ph-arrow-bend-up-right-thin::before {
  content: "\ea30";
}

.ph-arrow-circle-down-left-thin::before {
  content: "\ea31";
}

.ph-arrow-circle-down-right-thin::before {
  content: "\ea32";
}

.ph-arrow-circle-down-thin::before {
  content: "\ea33";
}

.ph-arrow-circle-left-thin::before {
  content: "\ea34";
}

.ph-arrow-circle-right-thin::before {
  content: "\ea35";
}

.ph-arrow-circle-up-left-thin::before {
  content: "\ea36";
}

.ph-arrow-circle-up-right-thin::before {
  content: "\ea37";
}

.ph-arrow-circle-up-thin::before {
  content: "\ea38";
}

.ph-arrow-clockwise-thin::before {
  content: "\ea39";
}

.ph-arrow-counter-clockwise-thin::before {
  content: "\ea3a";
}

.ph-arrow-down-left-thin::before {
  content: "\ea3b";
}

.ph-arrow-down-right-thin::before {
  content: "\ea3c";
}

.ph-arrow-down-thin::before {
  content: "\ea3d";
}

.ph-arrow-elbow-down-left-thin::before {
  content: "\ea3e";
}

.ph-arrow-elbow-down-right-thin::before {
  content: "\ea3f";
}

.ph-arrow-elbow-left-down-thin::before {
  content: "\ea40";
}

.ph-arrow-elbow-left-thin::before {
  content: "\ea41";
}

.ph-arrow-elbow-left-up-thin::before {
  content: "\ea42";
}

.ph-arrow-elbow-right-down-thin::before {
  content: "\ea43";
}

.ph-arrow-elbow-right-thin::before {
  content: "\ea44";
}

.ph-arrow-elbow-right-up-thin::before {
  content: "\ea45";
}

.ph-arrow-elbow-up-left-thin::before {
  content: "\ea46";
}

.ph-arrow-elbow-up-right-thin::before {
  content: "\ea47";
}

.ph-arrow-fat-down-thin::before {
  content: "\ea48";
}

.ph-arrow-fat-left-thin::before {
  content: "\ea49";
}

.ph-arrow-fat-line-down-thin::before {
  content: "\ea4a";
}

.ph-arrow-fat-line-left-thin::before {
  content: "\ea4b";
}

.ph-arrow-fat-line-right-thin::before {
  content: "\ea4c";
}

.ph-arrow-fat-line-up-thin::before {
  content: "\ea4d";
}

.ph-arrow-fat-lines-down-thin::before {
  content: "\ea4e";
}

.ph-arrow-fat-lines-left-thin::before {
  content: "\ea4f";
}

.ph-arrow-fat-lines-right-thin::before {
  content: "\ea50";
}

.ph-arrow-fat-lines-up-thin::before {
  content: "\ea51";
}

.ph-arrow-fat-right-thin::before {
  content: "\ea52";
}

.ph-arrow-fat-up-thin::before {
  content: "\ea53";
}

.ph-arrow-left-thin::before {
  content: "\ea54";
}

.ph-arrow-line-down-left-thin::before {
  content: "\ea55";
}

.ph-arrow-line-down-right-thin::before {
  content: "\ea56";
}

.ph-arrow-line-down-thin::before {
  content: "\ea57";
}

.ph-arrow-line-left-thin::before {
  content: "\ea58";
}

.ph-arrow-line-right-thin::before {
  content: "\ea59";
}

.ph-arrow-line-up-left-thin::before {
  content: "\ea5a";
}

.ph-arrow-line-up-right-thin::before {
  content: "\ea5b";
}

.ph-arrow-line-up-thin::before {
  content: "\ea5c";
}

.ph-arrow-right-thin::before {
  content: "\ea5d";
}

.ph-arrow-square-down-left-thin::before {
  content: "\ea5e";
}

.ph-arrow-square-down-right-thin::before {
  content: "\ea5f";
}

.ph-arrow-square-down-thin::before {
  content: "\ea60";
}

.ph-arrow-square-in-thin::before {
  content: "\ea61";
}

.ph-arrow-square-left-thin::before {
  content: "\ea62";
}

.ph-arrow-square-out-thin::before {
  content: "\ea63";
}

.ph-arrow-square-right-thin::before {
  content: "\ea64";
}

.ph-arrow-square-up-left-thin::before {
  content: "\ea65";
}

.ph-arrow-square-up-right-thin::before {
  content: "\ea66";
}

.ph-arrow-square-up-thin::before {
  content: "\ea67";
}

.ph-arrow-u-down-left-thin::before {
  content: "\ea68";
}

.ph-arrow-u-down-right-thin::before {
  content: "\ea69";
}

.ph-arrow-u-left-down-thin::before {
  content: "\ea6a";
}

.ph-arrow-u-left-up-thin::before {
  content: "\ea6b";
}

.ph-arrow-u-right-down-thin::before {
  content: "\ea6c";
}

.ph-arrow-u-right-up-thin::before {
  content: "\ea6d";
}

.ph-arrow-u-up-left-thin::before {
  content: "\ea6e";
}

.ph-arrow-u-up-right-thin::before {
  content: "\ea6f";
}

.ph-arrow-up-left-thin::before {
  content: "\ea70";
}

.ph-arrow-up-right-thin::before {
  content: "\ea71";
}

.ph-arrow-up-thin::before {
  content: "\ea72";
}

.ph-arrows-clockwise-thin::before {
  content: "\ea73";
}

.ph-arrows-counter-clockwise-thin::before {
  content: "\ea74";
}

.ph-arrows-down-up-thin::before {
  content: "\ea75";
}

.ph-arrows-horizontal-thin::before {
  content: "\ea76";
}

.ph-arrows-in-cardinal-thin::before {
  content: "\ea77";
}

.ph-arrows-in-line-horizontal-thin::before {
  content: "\ea78";
}

.ph-arrows-in-line-vertical-thin::before {
  content: "\ea79";
}

.ph-arrows-in-simple-thin::before {
  content: "\ea7a";
}

.ph-arrows-in-thin::before {
  content: "\ea7b";
}

.ph-arrows-left-right-thin::before {
  content: "\ea7c";
}

.ph-arrows-out-cardinal-thin::before {
  content: "\ea7d";
}

.ph-arrows-out-line-horizontal-thin::before {
  content: "\ea7e";
}

.ph-arrows-out-line-vertical-thin::before {
  content: "\ea7f";
}

.ph-arrows-out-simple-thin::before {
  content: "\ea80";
}

.ph-arrows-out-thin::before {
  content: "\ea81";
}

.ph-arrows-vertical-thin::before {
  content: "\ea82";
}

.ph-article-medium-thin::before {
  content: "\ea83";
}

.ph-article-ny-times-thin::before {
  content: "\ea84";
}

.ph-article-thin::before {
  content: "\ea85";
}

.ph-asterisk-simple-thin::before {
  content: "\ea86";
}

.ph-asterisk-thin::before {
  content: "\ea87";
}

.ph-at-thin::before {
  content: "\ea88";
}

.ph-atom-thin::before {
  content: "\ea89";
}

.ph-baby-thin::before {
  content: "\ea8a";
}

.ph-backpack-thin::before {
  content: "\ea8b";
}

.ph-backspace-thin::before {
  content: "\ea8c";
}

.ph-bag-simple-thin::before {
  content: "\ea8d";
}

.ph-bag-thin::before {
  content: "\ea8e";
}

.ph-balloon-thin::before {
  content: "\ea8f";
}

.ph-bandaids-thin::before {
  content: "\ea90";
}

.ph-bank-thin::before {
  content: "\ea91";
}

.ph-barbell-thin::before {
  content: "\ea92";
}

.ph-barcode-thin::before {
  content: "\ea93";
}

.ph-barricade-thin::before {
  content: "\ea94";
}

.ph-baseball-thin::before {
  content: "\ea95";
}

.ph-basketball-thin::before {
  content: "\ea96";
}

.ph-bathtub-thin::before {
  content: "\ea97";
}

.ph-battery-charging-thin::before {
  content: "\ea98";
}

.ph-battery-charging-vertical-thin::before {
  content: "\ea99";
}

.ph-battery-empty-thin::before {
  content: "\ea9a";
}

.ph-battery-full-thin::before {
  content: "\ea9b";
}

.ph-battery-high-thin::before {
  content: "\ea9c";
}

.ph-battery-low-thin::before {
  content: "\ea9d";
}

.ph-battery-medium-thin::before {
  content: "\ea9e";
}

.ph-battery-plus-thin::before {
  content: "\ea9f";
}

.ph-battery-warning-thin::before {
  content: "\eaa0";
}

.ph-battery-warning-vertical-thin::before {
  content: "\eaa1";
}

.ph-bed-thin::before {
  content: "\eaa2";
}

.ph-beer-bottle-thin::before {
  content: "\eaa3";
}

.ph-behance-logo-thin::before {
  content: "\eaa4";
}

.ph-bell-ringing-thin::before {
  content: "\eaa5";
}

.ph-bell-simple-ringing-thin::before {
  content: "\eaa6";
}

.ph-bell-simple-slash-thin::before {
  content: "\eaa7";
}

.ph-bell-simple-thin::before {
  content: "\eaa8";
}

.ph-bell-simple-z-thin::before {
  content: "\eaa9";
}

.ph-bell-slash-thin::before {
  content: "\eaaa";
}

.ph-bell-thin::before {
  content: "\eaab";
}

.ph-bell-z-thin::before {
  content: "\eaac";
}

.ph-bezier-curve-thin::before {
  content: "\eaad";
}

.ph-bicycle-thin::before {
  content: "\eaae";
}

.ph-binoculars-thin::before {
  content: "\eaaf";
}

.ph-bird-thin::before {
  content: "\eab0";
}

.ph-bluetooth-connected-thin::before {
  content: "\eab1";
}

.ph-bluetooth-slash-thin::before {
  content: "\eab2";
}

.ph-bluetooth-thin::before {
  content: "\eab3";
}

.ph-bluetooth-x-thin::before {
  content: "\eab4";
}

.ph-boat-thin::before {
  content: "\eab5";
}

.ph-book-bookmark-thin::before {
  content: "\eab6";
}

.ph-book-open-thin::before {
  content: "\eab7";
}

.ph-book-thin::before {
  content: "\eab8";
}

.ph-bookmark-simple-thin::before {
  content: "\eab9";
}

.ph-bookmark-thin::before {
  content: "\eaba";
}

.ph-bookmarks-simple-thin::before {
  content: "\eabb";
}

.ph-bookmarks-thin::before {
  content: "\eabc";
}

.ph-books-thin::before {
  content: "\eabd";
}

.ph-bounding-box-thin::before {
  content: "\eabe";
}

.ph-brackets-angle-thin::before {
  content: "\eabf";
}

.ph-brackets-curly-thin::before {
  content: "\eac0";
}

.ph-brackets-round-thin::before {
  content: "\eac1";
}

.ph-brackets-square-thin::before {
  content: "\eac2";
}

.ph-brain-thin::before {
  content: "\eac3";
}

.ph-brandy-thin::before {
  content: "\eac4";
}

.ph-briefcase-metal-thin::before {
  content: "\eac5";
}

.ph-briefcase-thin::before {
  content: "\eac6";
}

.ph-broadcast-thin::before {
  content: "\eac7";
}

.ph-browser-thin::before {
  content: "\eac8";
}

.ph-browsers-thin::before {
  content: "\eac9";
}

.ph-bug-beetle-thin::before {
  content: "\eaca";
}

.ph-bug-droid-thin::before {
  content: "\eacb";
}

.ph-bug-thin::before {
  content: "\eacc";
}

.ph-buildings-thin::before {
  content: "\eacd";
}

.ph-bus-thin::before {
  content: "\eace";
}

.ph-butterfly-thin::before {
  content: "\eacf";
}

.ph-cactus-thin::before {
  content: "\ead0";
}

.ph-cake-thin::before {
  content: "\ead1";
}

.ph-calculator-thin::before {
  content: "\ead2";
}

.ph-calendar-blank-thin::before {
  content: "\ead3";
}

.ph-calendar-check-thin::before {
  content: "\ead4";
}

.ph-calendar-plus-thin::before {
  content: "\ead5";
}

.ph-calendar-thin::before {
  content: "\ead6";
}

.ph-calendar-x-thin::before {
  content: "\ead7";
}

.ph-camera-rotate-thin::before {
  content: "\ead8";
}

.ph-camera-slash-thin::before {
  content: "\ead9";
}

.ph-camera-thin::before {
  content: "\eada";
}

.ph-campfire-thin::before {
  content: "\eadb";
}

.ph-car-simple-thin::before {
  content: "\eadc";
}

.ph-car-thin::before {
  content: "\eadd";
}

.ph-cardholder-thin::before {
  content: "\eade";
}

.ph-cards-thin::before {
  content: "\eadf";
}

.ph-caret-circle-double-down-thin::before {
  content: "\eae0";
}

.ph-caret-circle-double-left-thin::before {
  content: "\eae1";
}

.ph-caret-circle-double-right-thin::before {
  content: "\eae2";
}

.ph-caret-circle-double-up-thin::before {
  content: "\eae3";
}

.ph-caret-circle-down-thin::before {
  content: "\eae4";
}

.ph-caret-circle-left-thin::before {
  content: "\eae5";
}

.ph-caret-circle-right-thin::before {
  content: "\eae6";
}

.ph-caret-circle-up-thin::before {
  content: "\eae7";
}

.ph-caret-double-down-thin::before {
  content: "\eae8";
}

.ph-caret-double-left-thin::before {
  content: "\eae9";
}

.ph-caret-double-right-thin::before {
  content: "\eaea";
}

.ph-caret-double-up-thin::before {
  content: "\eaeb";
}

.ph-caret-down-thin::before {
  content: "\eaec";
}

.ph-caret-left-thin::before {
  content: "\eaed";
}

.ph-caret-right-thin::before {
  content: "\eaee";
}

.ph-caret-up-thin::before {
  content: "\eaef";
}

.ph-cat-thin::before {
  content: "\eaf0";
}

.ph-cell-signal-full-thin::before {
  content: "\eaf1";
}

.ph-cell-signal-high-thin::before {
  content: "\eaf2";
}

.ph-cell-signal-low-thin::before {
  content: "\eaf3";
}

.ph-cell-signal-medium-thin::before {
  content: "\eaf4";
}

.ph-cell-signal-none-thin::before {
  content: "\eaf5";
}

.ph-cell-signal-slash-thin::before {
  content: "\eaf6";
}

.ph-cell-signal-x-thin::before {
  content: "\eaf7";
}

.ph-chalkboard-simple-thin::before {
  content: "\eaf8";
}

.ph-chalkboard-teacher-thin::before {
  content: "\eaf9";
}

.ph-chalkboard-thin::before {
  content: "\eafa";
}

.ph-chart-bar-horizontal-thin::before {
  content: "\eafb";
}

.ph-chart-bar-thin::before {
  content: "\eafc";
}

.ph-chart-line-thin::before {
  content: "\eafd";
}

.ph-chart-line-up-thin::before {
  content: "\eafe";
}

.ph-chart-pie-slice-thin::before {
  content: "\eaff";
}

.ph-chart-pie-thin::before {
  content: "\eb00";
}

.ph-chat-centered-dots-thin::before {
  content: "\eb01";
}

.ph-chat-centered-text-thin::before {
  content: "\eb02";
}

.ph-chat-centered-thin::before {
  content: "\eb03";
}

.ph-chat-circle-dots-thin::before {
  content: "\eb04";
}

.ph-chat-circle-text-thin::before {
  content: "\eb05";
}

.ph-chat-circle-thin::before {
  content: "\eb06";
}

.ph-chat-dots-thin::before {
  content: "\eb07";
}

.ph-chat-teardrop-dots-thin::before {
  content: "\eb08";
}

.ph-chat-teardrop-text-thin::before {
  content: "\eb09";
}

.ph-chat-teardrop-thin::before {
  content: "\eb0a";
}

.ph-chat-text-thin::before {
  content: "\eb0b";
}

.ph-chat-thin::before {
  content: "\eb0c";
}

.ph-chats-circle-thin::before {
  content: "\eb0d";
}

.ph-chats-teardrop-thin::before {
  content: "\eb0e";
}

.ph-chats-thin::before {
  content: "\eb0f";
}

.ph-check-circle-thin::before {
  content: "\eb10";
}

.ph-check-square-offset-thin::before {
  content: "\eb11";
}

.ph-check-square-thin::before {
  content: "\eb12";
}

.ph-check-thin::before {
  content: "\eb13";
}

.ph-checks-thin::before {
  content: "\eb14";
}

.ph-circle-dashed-thin::before {
  content: "\eb15";
}

.ph-circle-half-thin::before {
  content: "\eb16";
}

.ph-circle-half-tilt-thin::before {
  content: "\eb17";
}

.ph-circle-notch-thin::before {
  content: "\eb18";
}

.ph-circle-thin::before {
  content: "\eb19";
}

.ph-circle-wavy-check-thin::before {
  content: "\eb1a";
}

.ph-circle-wavy-question-thin::before {
  content: "\eb1b";
}

.ph-circle-wavy-thin::before {
  content: "\eb1c";
}

.ph-circle-wavy-warning-thin::before {
  content: "\eb1d";
}

.ph-circles-four-thin::before {
  content: "\eb1e";
}

.ph-circles-three-plus-thin::before {
  content: "\eb1f";
}

.ph-circles-three-thin::before {
  content: "\eb20";
}

.ph-clipboard-text-thin::before {
  content: "\eb21";
}

.ph-clipboard-thin::before {
  content: "\eb22";
}

.ph-clock-afternoon-thin::before {
  content: "\eb23";
}

.ph-clock-clockwise-thin::before {
  content: "\eb24";
}

.ph-clock-counter-clockwise-thin::before {
  content: "\eb25";
}

.ph-clock-thin::before {
  content: "\eb26";
}

.ph-closed-captioning-thin::before {
  content: "\eb27";
}

.ph-cloud-arrow-down-thin::before {
  content: "\eb28";
}

.ph-cloud-arrow-up-thin::before {
  content: "\eb29";
}

.ph-cloud-check-thin::before {
  content: "\eb2a";
}

.ph-cloud-fog-thin::before {
  content: "\eb2b";
}

.ph-cloud-lightning-thin::before {
  content: "\eb2c";
}

.ph-cloud-moon-thin::before {
  content: "\eb2d";
}

.ph-cloud-rain-thin::before {
  content: "\eb2e";
}

.ph-cloud-slash-thin::before {
  content: "\eb2f";
}

.ph-cloud-snow-thin::before {
  content: "\eb30";
}

.ph-cloud-sun-thin::before {
  content: "\eb31";
}

.ph-cloud-thin::before {
  content: "\eb32";
}

.ph-club-thin::before {
  content: "\eb33";
}

.ph-coat-hanger-thin::before {
  content: "\eb34";
}

.ph-code-simple-thin::before {
  content: "\eb35";
}

.ph-code-thin::before {
  content: "\eb36";
}

.ph-codepen-logo-thin::before {
  content: "\eb37";
}

.ph-codesandbox-logo-thin::before {
  content: "\eb38";
}

.ph-coffee-thin::before {
  content: "\eb39";
}

.ph-coin-thin::before {
  content: "\eb3a";
}

.ph-coin-vertical-thin::before {
  content: "\eb3b";
}

.ph-coins-thin::before {
  content: "\eb3c";
}

.ph-columns-thin::before {
  content: "\eb3d";
}

.ph-command-thin::before {
  content: "\eb3e";
}

.ph-compass-thin::before {
  content: "\eb3f";
}

.ph-computer-tower-thin::before {
  content: "\eb40";
}

.ph-confetti-thin::before {
  content: "\eb41";
}

.ph-cookie-thin::before {
  content: "\eb42";
}

.ph-cooking-pot-thin::before {
  content: "\eb43";
}

.ph-copy-simple-thin::before {
  content: "\eb44";
}

.ph-copy-thin::before {
  content: "\eb45";
}

.ph-copyleft-thin::before {
  content: "\eb46";
}

.ph-copyright-thin::before {
  content: "\eb47";
}

.ph-corners-in-thin::before {
  content: "\eb48";
}

.ph-corners-out-thin::before {
  content: "\eb49";
}

.ph-cpu-thin::before {
  content: "\eb4a";
}

.ph-credit-card-thin::before {
  content: "\eb4b";
}

.ph-crop-thin::before {
  content: "\eb4c";
}

.ph-crosshair-simple-thin::before {
  content: "\eb4d";
}

.ph-crosshair-thin::before {
  content: "\eb4e";
}

.ph-crown-simple-thin::before {
  content: "\eb4f";
}

.ph-crown-thin::before {
  content: "\eb50";
}

.ph-cube-thin::before {
  content: "\eb51";
}

.ph-currency-btc-thin::before {
  content: "\eb52";
}

.ph-currency-circle-dollar-thin::before {
  content: "\eb53";
}

.ph-currency-cny-thin::before {
  content: "\eb54";
}

.ph-currency-dollar-simple-thin::before {
  content: "\eb55";
}

.ph-currency-dollar-thin::before {
  content: "\eb56";
}

.ph-currency-eth-thin::before {
  content: "\eb57";
}

.ph-currency-eur-thin::before {
  content: "\eb58";
}

.ph-currency-gbp-thin::before {
  content: "\eb59";
}

.ph-currency-inr-thin::before {
  content: "\eb5a";
}

.ph-currency-jpy-thin::before {
  content: "\eb5b";
}

.ph-currency-krw-thin::before {
  content: "\eb5c";
}

.ph-currency-kzt-thin::before {
  content: "\eb5d";
}

.ph-currency-ngn-thin::before {
  content: "\eb5e";
}

.ph-currency-rub-thin::before {
  content: "\eb5f";
}

.ph-cursor-text-thin::before {
  content: "\eb60";
}

.ph-cursor-thin::before {
  content: "\eb61";
}

.ph-cylinder-thin::before {
  content: "\eb62";
}

.ph-database-thin::before {
  content: "\eb63";
}

.ph-desktop-thin::before {
  content: "\eb64";
}

.ph-desktop-tower-thin::before {
  content: "\eb65";
}

.ph-detective-thin::before {
  content: "\eb66";
}

.ph-device-mobile-camera-thin::before {
  content: "\eb67";
}

.ph-device-mobile-speaker-thin::before {
  content: "\eb68";
}

.ph-device-mobile-thin::before {
  content: "\eb69";
}

.ph-device-tablet-camera-thin::before {
  content: "\eb6a";
}

.ph-device-tablet-speaker-thin::before {
  content: "\eb6b";
}

.ph-device-tablet-thin::before {
  content: "\eb6c";
}

.ph-diamond-thin::before {
  content: "\eb6d";
}

.ph-diamonds-four-thin::before {
  content: "\eb6e";
}

.ph-dice-five-thin::before {
  content: "\eb6f";
}

.ph-dice-four-thin::before {
  content: "\eb70";
}

.ph-dice-one-thin::before {
  content: "\eb71";
}

.ph-dice-six-thin::before {
  content: "\eb72";
}

.ph-dice-three-thin::before {
  content: "\eb73";
}

.ph-dice-two-thin::before {
  content: "\eb74";
}

.ph-disc-thin::before {
  content: "\eb75";
}

.ph-discord-logo-thin::before {
  content: "\eb76";
}

.ph-divide-thin::before {
  content: "\eb77";
}

.ph-dog-thin::before {
  content: "\eb78";
}

.ph-door-thin::before {
  content: "\eb79";
}

.ph-dots-nine-thin::before {
  content: "\eb7a";
}

.ph-dots-six-thin::before {
  content: "\eb7b";
}

.ph-dots-six-vertical-thin::before {
  content: "\eb7c";
}

.ph-dots-three-circle-thin::before {
  content: "\eb7d";
}

.ph-dots-three-circle-vertical-thin::before {
  content: "\eb7e";
}

.ph-dots-three-outline-thin::before {
  content: "\eb7f";
}

.ph-dots-three-outline-vertical-thin::before {
  content: "\eb80";
}

.ph-dots-three-thin::before {
  content: "\eb81";
}

.ph-dots-three-vertical-thin::before {
  content: "\eb82";
}

.ph-download-simple-thin::before {
  content: "\eb83";
}

.ph-download-thin::before {
  content: "\eb84";
}

.ph-dribbble-logo-thin::before {
  content: "\eb85";
}

.ph-drop-half-bottom-thin::before {
  content: "\eb86";
}

.ph-drop-half-thin::before {
  content: "\eb87";
}

.ph-drop-thin::before {
  content: "\eb88";
}

.ph-ear-slash-thin::before {
  content: "\eb89";
}

.ph-ear-thin::before {
  content: "\eb8a";
}

.ph-egg-crack-thin::before {
  content: "\eb8b";
}

.ph-egg-thin::before {
  content: "\eb8c";
}

.ph-eject-simple-thin::before {
  content: "\eb8d";
}

.ph-eject-thin::before {
  content: "\eb8e";
}

.ph-envelope-open-thin::before {
  content: "\eb8f";
}

.ph-envelope-simple-open-thin::before {
  content: "\eb90";
}

.ph-envelope-simple-thin::before {
  content: "\eb91";
}

.ph-envelope-thin::before {
  content: "\eb92";
}

.ph-equalizer-thin::before {
  content: "\eb93";
}

.ph-equals-thin::before {
  content: "\eb94";
}

.ph-eraser-thin::before {
  content: "\eb95";
}

.ph-exam-thin::before {
  content: "\eb96";
}

.ph-export-thin::before {
  content: "\eb97";
}

.ph-eye-closed-thin::before {
  content: "\eb98";
}

.ph-eye-slash-thin::before {
  content: "\eb99";
}

.ph-eye-thin::before {
  content: "\eb9a";
}

.ph-eyedropper-sample-thin::before {
  content: "\eb9b";
}

.ph-eyedropper-thin::before {
  content: "\eb9c";
}

.ph-eyeglasses-thin::before {
  content: "\eb9d";
}

.ph-face-mask-thin::before {
  content: "\eb9e";
}

.ph-facebook-logo-thin::before {
  content: "\eb9f";
}

.ph-factory-thin::before {
  content: "\eba0";
}

.ph-faders-horizontal-thin::before {
  content: "\eba1";
}

.ph-faders-thin::before {
  content: "\eba2";
}

.ph-fast-forward-circle-thin::before {
  content: "\eba3";
}

.ph-fast-forward-thin::before {
  content: "\eba4";
}

.ph-figma-logo-thin::before {
  content: "\eba5";
}

.ph-file-arrow-down-thin::before {
  content: "\eba6";
}

.ph-file-arrow-up-thin::before {
  content: "\eba7";
}

.ph-file-audio-thin::before {
  content: "\eba8";
}

.ph-file-cloud-thin::before {
  content: "\eba9";
}

.ph-file-code-thin::before {
  content: "\ebaa";
}

.ph-file-css-thin::before {
  content: "\ebab";
}

.ph-file-csv-thin::before {
  content: "\ebac";
}

.ph-file-doc-thin::before {
  content: "\ebad";
}

.ph-file-dotted-thin::before {
  content: "\ebae";
}

.ph-file-html-thin::before {
  content: "\ebaf";
}

.ph-file-image-thin::before {
  content: "\ebb0";
}

.ph-file-jpg-thin::before {
  content: "\ebb1";
}

.ph-file-js-thin::before {
  content: "\ebb2";
}

.ph-file-jsx-thin::before {
  content: "\ebb3";
}

.ph-file-lock-thin::before {
  content: "\ebb4";
}

.ph-file-minus-thin::before {
  content: "\ebb5";
}

.ph-file-pdf-thin::before {
  content: "\ebb6";
}

.ph-file-plus-thin::before {
  content: "\ebb7";
}

.ph-file-png-thin::before {
  content: "\ebb8";
}

.ph-file-ppt-thin::before {
  content: "\ebb9";
}

.ph-file-rs-thin::before {
  content: "\ebba";
}

.ph-file-search-thin::before {
  content: "\ebbb";
}

.ph-file-text-thin::before {
  content: "\ebbc";
}

.ph-file-thin::before {
  content: "\ebbd";
}

.ph-file-ts-thin::before {
  content: "\ebbe";
}

.ph-file-tsx-thin::before {
  content: "\ebbf";
}

.ph-file-video-thin::before {
  content: "\ebc0";
}

.ph-file-vue-thin::before {
  content: "\ebc1";
}

.ph-file-x-thin::before {
  content: "\ebc2";
}

.ph-file-xls-thin::before {
  content: "\ebc3";
}

.ph-file-zip-thin::before {
  content: "\ebc4";
}

.ph-files-thin::before {
  content: "\ebc5";
}

.ph-film-script-thin::before {
  content: "\ebc6";
}

.ph-film-slate-thin::before {
  content: "\ebc7";
}

.ph-film-strip-thin::before {
  content: "\ebc8";
}

.ph-fingerprint-simple-thin::before {
  content: "\ebc9";
}

.ph-fingerprint-thin::before {
  content: "\ebca";
}

.ph-finn-the-human-thin::before {
  content: "\ebcb";
}

.ph-fire-simple-thin::before {
  content: "\ebcc";
}

.ph-fire-thin::before {
  content: "\ebcd";
}

.ph-first-aid-kit-thin::before {
  content: "\ebce";
}

.ph-first-aid-thin::before {
  content: "\ebcf";
}

.ph-fish-simple-thin::before {
  content: "\ebd0";
}

.ph-fish-thin::before {
  content: "\ebd1";
}

.ph-flag-banner-thin::before {
  content: "\ebd2";
}

.ph-flag-checkered-thin::before {
  content: "\ebd3";
}

.ph-flag-thin::before {
  content: "\ebd4";
}

.ph-flame-thin::before {
  content: "\ebd5";
}

.ph-flashlight-thin::before {
  content: "\ebd6";
}

.ph-flask-thin::before {
  content: "\ebd7";
}

.ph-floppy-disk-back-thin::before {
  content: "\ebd8";
}

.ph-floppy-disk-thin::before {
  content: "\ebd9";
}

.ph-flow-arrow-thin::before {
  content: "\ebda";
}

.ph-flower-lotus-thin::before {
  content: "\ebdb";
}

.ph-flower-thin::before {
  content: "\ebdc";
}

.ph-flying-saucer-thin::before {
  content: "\ebdd";
}

.ph-folder-dotted-thin::before {
  content: "\ebde";
}

.ph-folder-lock-thin::before {
  content: "\ebdf";
}

.ph-folder-minus-thin::before {
  content: "\ebe0";
}

.ph-folder-notch-minus-thin::before {
  content: "\ebe1";
}

.ph-folder-notch-open-thin::before {
  content: "\ebe2";
}

.ph-folder-notch-plus-thin::before {
  content: "\ebe3";
}

.ph-folder-notch-thin::before {
  content: "\ebe4";
}

.ph-folder-open-thin::before {
  content: "\ebe5";
}

.ph-folder-plus-thin::before {
  content: "\ebe6";
}

.ph-folder-simple-dotted-thin::before {
  content: "\ebe7";
}

.ph-folder-simple-lock-thin::before {
  content: "\ebe8";
}

.ph-folder-simple-minus-thin::before {
  content: "\ebe9";
}

.ph-folder-simple-plus-thin::before {
  content: "\ebea";
}

.ph-folder-simple-star-thin::before {
  content: "\ebeb";
}

.ph-folder-simple-thin::before {
  content: "\ebec";
}

.ph-folder-simple-user-thin::before {
  content: "\ebed";
}

.ph-folder-star-thin::before {
  content: "\ebee";
}

.ph-folder-thin::before {
  content: "\ebef";
}

.ph-folder-user-thin::before {
  content: "\ebf0";
}

.ph-folders-thin::before {
  content: "\ebf1";
}

.ph-football-thin::before {
  content: "\ebf2";
}

.ph-fork-knife-thin::before {
  content: "\ebf3";
}

.ph-frame-corners-thin::before {
  content: "\ebf4";
}

.ph-framer-logo-thin::before {
  content: "\ebf5";
}

.ph-function-thin::before {
  content: "\ebf6";
}

.ph-funnel-simple-thin::before {
  content: "\ebf7";
}

.ph-funnel-thin::before {
  content: "\ebf8";
}

.ph-game-controller-thin::before {
  content: "\ebf9";
}

.ph-gas-pump-thin::before {
  content: "\ebfa";
}

.ph-gauge-thin::before {
  content: "\ebfb";
}

.ph-gear-six-thin::before {
  content: "\ebfc";
}

.ph-gear-thin::before {
  content: "\ebfd";
}

.ph-gender-female-thin::before {
  content: "\ebfe";
}

.ph-gender-intersex-thin::before {
  content: "\ebff";
}

.ph-gender-male-thin::before {
  content: "\ec00";
}

.ph-gender-neuter-thin::before {
  content: "\ec01";
}

.ph-gender-nonbinary-thin::before {
  content: "\ec02";
}

.ph-gender-transgender-thin::before {
  content: "\ec03";
}

.ph-ghost-thin::before {
  content: "\ec04";
}

.ph-gif-thin::before {
  content: "\ec05";
}

.ph-gift-thin::before {
  content: "\ec06";
}

.ph-git-branch-thin::before {
  content: "\ec07";
}

.ph-git-commit-thin::before {
  content: "\ec08";
}

.ph-git-diff-thin::before {
  content: "\ec09";
}

.ph-git-fork-thin::before {
  content: "\ec0a";
}

.ph-git-merge-thin::before {
  content: "\ec0b";
}

.ph-git-pull-request-thin::before {
  content: "\ec0c";
}

.ph-github-logo-thin::before {
  content: "\ec0d";
}

.ph-gitlab-logo-simple-thin::before {
  content: "\ec0e";
}

.ph-gitlab-logo-thin::before {
  content: "\ec0f";
}

.ph-globe-hemisphere-east-thin::before {
  content: "\ec10";
}

.ph-globe-hemisphere-west-thin::before {
  content: "\ec11";
}

.ph-globe-simple-thin::before {
  content: "\ec12";
}

.ph-globe-stand-thin::before {
  content: "\ec13";
}

.ph-globe-thin::before {
  content: "\ec14";
}

.ph-google-chrome-logo-thin::before {
  content: "\ec15";
}

.ph-google-logo-thin::before {
  content: "\ec16";
}

.ph-google-photos-logo-thin::before {
  content: "\ec17";
}

.ph-google-play-logo-thin::before {
  content: "\ec18";
}

.ph-google-podcasts-logo-thin::before {
  content: "\ec19";
}

.ph-gradient-thin::before {
  content: "\ec1a";
}

.ph-graduation-cap-thin::before {
  content: "\ec1b";
}

.ph-graph-thin::before {
  content: "\ec1c";
}

.ph-grid-four-thin::before {
  content: "\ec1d";
}

.ph-hamburger-thin::before {
  content: "\ec1e";
}

.ph-hand-eye-thin::before {
  content: "\ec1f";
}

.ph-hand-fist-thin::before {
  content: "\ec20";
}

.ph-hand-grabbing-thin::before {
  content: "\ec21";
}

.ph-hand-palm-thin::before {
  content: "\ec22";
}

.ph-hand-pointing-thin::before {
  content: "\ec23";
}

.ph-hand-soap-thin::before {
  content: "\ec24";
}

.ph-hand-thin::before {
  content: "\ec25";
}

.ph-hand-waving-thin::before {
  content: "\ec26";
}

.ph-handbag-simple-thin::before {
  content: "\ec27";
}

.ph-handbag-thin::before {
  content: "\ec28";
}

.ph-hands-clapping-thin::before {
  content: "\ec29";
}

.ph-handshake-thin::before {
  content: "\ec2a";
}

.ph-hard-drive-thin::before {
  content: "\ec2b";
}

.ph-hard-drives-thin::before {
  content: "\ec2c";
}

.ph-hash-straight-thin::before {
  content: "\ec2d";
}

.ph-hash-thin::before {
  content: "\ec2e";
}

.ph-headlights-thin::before {
  content: "\ec2f";
}

.ph-headphones-thin::before {
  content: "\ec30";
}

.ph-headset-thin::before {
  content: "\ec31";
}

.ph-heart-break-thin::before {
  content: "\ec32";
}

.ph-heart-straight-break-thin::before {
  content: "\ec33";
}

.ph-heart-straight-thin::before {
  content: "\ec34";
}

.ph-heart-thin::before {
  content: "\ec35";
}

.ph-heartbeat-thin::before {
  content: "\ec36";
}

.ph-hexagon-thin::before {
  content: "\ec37";
}

.ph-highlighter-circle-thin::before {
  content: "\ec38";
}

.ph-horse-thin::before {
  content: "\ec39";
}

.ph-hourglass-high-thin::before {
  content: "\ec3a";
}

.ph-hourglass-low-thin::before {
  content: "\ec3b";
}

.ph-hourglass-medium-thin::before {
  content: "\ec3c";
}

.ph-hourglass-simple-high-thin::before {
  content: "\ec3d";
}

.ph-hourglass-simple-low-thin::before {
  content: "\ec3e";
}

.ph-hourglass-simple-medium-thin::before {
  content: "\ec3f";
}

.ph-hourglass-simple-thin::before {
  content: "\ec40";
}

.ph-hourglass-thin::before {
  content: "\ec41";
}

.ph-house-line-thin::before {
  content: "\ec42";
}

.ph-house-simple-thin::before {
  content: "\ec43";
}

.ph-house-thin::before {
  content: "\ec44";
}

.ph-identification-badge-thin::before {
  content: "\ec45";
}

.ph-identification-card-thin::before {
  content: "\ec46";
}

.ph-image-square-thin::before {
  content: "\ec47";
}

.ph-image-thin::before {
  content: "\ec48";
}

.ph-infinity-thin::before {
  content: "\ec49";
}

.ph-info-thin::before {
  content: "\ec4a";
}

.ph-instagram-logo-thin::before {
  content: "\ec4b";
}

.ph-intersect-thin::before {
  content: "\ec4c";
}

.ph-jeep-thin::before {
  content: "\ec4d";
}

.ph-kanban-thin::before {
  content: "\ec4e";
}

.ph-key-return-thin::before {
  content: "\ec4f";
}

.ph-key-thin::before {
  content: "\ec50";
}

.ph-keyboard-thin::before {
  content: "\ec51";
}

.ph-keyhole-thin::before {
  content: "\ec52";
}

.ph-knife-thin::before {
  content: "\ec53";
}

.ph-ladder-simple-thin::before {
  content: "\ec54";
}

.ph-ladder-thin::before {
  content: "\ec55";
}

.ph-lamp-thin::before {
  content: "\ec56";
}

.ph-laptop-thin::before {
  content: "\ec57";
}

.ph-layout-thin::before {
  content: "\ec58";
}

.ph-leaf-thin::before {
  content: "\ec59";
}

.ph-lifebuoy-thin::before {
  content: "\ec5a";
}

.ph-lightbulb-filament-thin::before {
  content: "\ec5b";
}

.ph-lightbulb-thin::before {
  content: "\ec5c";
}

.ph-lightning-slash-thin::before {
  content: "\ec5d";
}

.ph-lightning-thin::before {
  content: "\ec5e";
}

.ph-line-segment-thin::before {
  content: "\ec5f";
}

.ph-line-segments-thin::before {
  content: "\ec60";
}

.ph-link-break-thin::before {
  content: "\ec61";
}

.ph-link-simple-break-thin::before {
  content: "\ec62";
}

.ph-link-simple-horizontal-break-thin::before {
  content: "\ec63";
}

.ph-link-simple-horizontal-thin::before {
  content: "\ec64";
}

.ph-link-simple-thin::before {
  content: "\ec65";
}

.ph-link-thin::before {
  content: "\ec66";
}

.ph-linkedin-logo-thin::before {
  content: "\ec67";
}

.ph-linux-logo-thin::before {
  content: "\ec68";
}

.ph-list-bullets-thin::before {
  content: "\ec69";
}

.ph-list-checks-thin::before {
  content: "\ec6a";
}

.ph-list-dashes-thin::before {
  content: "\ec6b";
}

.ph-list-numbers-thin::before {
  content: "\ec6c";
}

.ph-list-plus-thin::before {
  content: "\ec6d";
}

.ph-list-thin::before {
  content: "\ec6e";
}

.ph-lock-key-open-thin::before {
  content: "\ec6f";
}

.ph-lock-key-thin::before {
  content: "\ec70";
}

.ph-lock-laminated-open-thin::before {
  content: "\ec71";
}

.ph-lock-laminated-thin::before {
  content: "\ec72";
}

.ph-lock-open-thin::before {
  content: "\ec73";
}

.ph-lock-simple-open-thin::before {
  content: "\ec74";
}

.ph-lock-simple-thin::before {
  content: "\ec75";
}

.ph-lock-thin::before {
  content: "\ec76";
}

.ph-magic-wand-thin::before {
  content: "\ec77";
}

.ph-magnet-straight-thin::before {
  content: "\ec78";
}

.ph-magnet-thin::before {
  content: "\ec79";
}

.ph-magnifying-glass-minus-thin::before {
  content: "\ec7a";
}

.ph-magnifying-glass-plus-thin::before {
  content: "\ec7b";
}

.ph-magnifying-glass-thin::before {
  content: "\ec7c";
}

.ph-map-pin-line-thin::before {
  content: "\ec7d";
}

.ph-map-pin-thin::before {
  content: "\ec7e";
}

.ph-map-trifold-thin::before {
  content: "\ec7f";
}

.ph-marker-circle-thin::before {
  content: "\ec80";
}

.ph-martini-thin::before {
  content: "\ec81";
}

.ph-mask-happy-thin::before {
  content: "\ec82";
}

.ph-mask-sad-thin::before {
  content: "\ec83";
}

.ph-math-operations-thin::before {
  content: "\ec84";
}

.ph-medal-thin::before {
  content: "\ec85";
}

.ph-medium-logo-thin::before {
  content: "\ec86";
}

.ph-megaphone-simple-thin::before {
  content: "\ec87";
}

.ph-megaphone-thin::before {
  content: "\ec88";
}

.ph-messenger-logo-thin::before {
  content: "\ec89";
}

.ph-microphone-slash-thin::before {
  content: "\ec8a";
}

.ph-microphone-stage-thin::before {
  content: "\ec8b";
}

.ph-microphone-thin::before {
  content: "\ec8c";
}

.ph-microsoft-excel-logo-thin::before {
  content: "\ec8d";
}

.ph-microsoft-powerpoint-logo-thin::before {
  content: "\ec8e";
}

.ph-microsoft-teams-logo-thin::before {
  content: "\ec8f";
}

.ph-microsoft-word-logo-thin::before {
  content: "\ec90";
}

.ph-minus-circle-thin::before {
  content: "\ec91";
}

.ph-minus-thin::before {
  content: "\ec92";
}

.ph-money-thin::before {
  content: "\ec93";
}

.ph-monitor-play-thin::before {
  content: "\ec94";
}

.ph-monitor-thin::before {
  content: "\ec95";
}

.ph-moon-stars-thin::before {
  content: "\ec96";
}

.ph-moon-thin::before {
  content: "\ec97";
}

.ph-mountains-thin::before {
  content: "\ec98";
}

.ph-mouse-simple-thin::before {
  content: "\ec99";
}

.ph-mouse-thin::before {
  content: "\ec9a";
}

.ph-music-note-simple-thin::before {
  content: "\ec9b";
}

.ph-music-note-thin::before {
  content: "\ec9c";
}

.ph-music-notes-plus-thin::before {
  content: "\ec9d";
}

.ph-music-notes-simple-thin::before {
  content: "\ec9e";
}

.ph-music-notes-thin::before {
  content: "\ec9f";
}

.ph-navigation-arrow-thin::before {
  content: "\eca0";
}

.ph-needle-thin::before {
  content: "\eca1";
}

.ph-newspaper-clipping-thin::before {
  content: "\eca2";
}

.ph-newspaper-thin::before {
  content: "\eca3";
}

.ph-note-blank-thin::before {
  content: "\eca4";
}

.ph-note-pencil-thin::before {
  content: "\eca5";
}

.ph-note-thin::before {
  content: "\eca6";
}

.ph-notebook-thin::before {
  content: "\eca7";
}

.ph-notepad-thin::before {
  content: "\eca8";
}

.ph-notification-thin::before {
  content: "\eca9";
}

.ph-number-circle-eight-thin::before {
  content: "\ecaa";
}

.ph-number-circle-five-thin::before {
  content: "\ecab";
}

.ph-number-circle-four-thin::before {
  content: "\ecac";
}

.ph-number-circle-nine-thin::before {
  content: "\ecad";
}

.ph-number-circle-one-thin::before {
  content: "\ecae";
}

.ph-number-circle-seven-thin::before {
  content: "\ecaf";
}

.ph-number-circle-six-thin::before {
  content: "\ecb0";
}

.ph-number-circle-three-thin::before {
  content: "\ecb1";
}

.ph-number-circle-two-thin::before {
  content: "\ecb2";
}

.ph-number-circle-zero-thin::before {
  content: "\ecb3";
}

.ph-number-eight-thin::before {
  content: "\ecb4";
}

.ph-number-five-thin::before {
  content: "\ecb5";
}

.ph-number-four-thin::before {
  content: "\ecb6";
}

.ph-number-nine-thin::before {
  content: "\ecb7";
}

.ph-number-one-thin::before {
  content: "\ecb8";
}

.ph-number-seven-thin::before {
  content: "\ecb9";
}

.ph-number-six-thin::before {
  content: "\ecba";
}

.ph-number-square-eight-thin::before {
  content: "\ecbb";
}

.ph-number-square-five-thin::before {
  content: "\ecbc";
}

.ph-number-square-four-thin::before {
  content: "\ecbd";
}

.ph-number-square-nine-thin::before {
  content: "\ecbe";
}

.ph-number-square-one-thin::before {
  content: "\ecbf";
}

.ph-number-square-seven-thin::before {
  content: "\ecc0";
}

.ph-number-square-six-thin::before {
  content: "\ecc1";
}

.ph-number-square-three-thin::before {
  content: "\ecc2";
}

.ph-number-square-two-thin::before {
  content: "\ecc3";
}

.ph-number-square-zero-thin::before {
  content: "\ecc4";
}

.ph-number-three-thin::before {
  content: "\ecc5";
}

.ph-number-two-thin::before {
  content: "\ecc6";
}

.ph-number-zero-thin::before {
  content: "\ecc7";
}

.ph-nut-thin::before {
  content: "\ecc8";
}

.ph-ny-times-logo-thin::before {
  content: "\ecc9";
}

.ph-octagon-thin::before {
  content: "\ecca";
}

.ph-option-thin::before {
  content: "\eccb";
}

.ph-package-thin::before {
  content: "\eccc";
}

.ph-paint-brush-broad-thin::before {
  content: "\eccd";
}

.ph-paint-brush-household-thin::before {
  content: "\ecce";
}

.ph-paint-brush-thin::before {
  content: "\eccf";
}

.ph-paint-bucket-thin::before {
  content: "\ecd0";
}

.ph-paint-roller-thin::before {
  content: "\ecd1";
}

.ph-palette-thin::before {
  content: "\ecd2";
}

.ph-paper-plane-right-thin::before {
  content: "\ecd3";
}

.ph-paper-plane-thin::before {
  content: "\ecd4";
}

.ph-paper-plane-tilt-thin::before {
  content: "\ecd5";
}

.ph-paperclip-horizontal-thin::before {
  content: "\ecd6";
}

.ph-paperclip-thin::before {
  content: "\ecd7";
}

.ph-parachute-thin::before {
  content: "\ecd8";
}

.ph-password-thin::before {
  content: "\ecd9";
}

.ph-path-thin::before {
  content: "\ecda";
}

.ph-pause-circle-thin::before {
  content: "\ecdb";
}

.ph-pause-thin::before {
  content: "\ecdc";
}

.ph-paw-print-thin::before {
  content: "\ecdd";
}

.ph-peace-thin::before {
  content: "\ecde";
}

.ph-pen-nib-straight-thin::before {
  content: "\ecdf";
}

.ph-pen-nib-thin::before {
  content: "\ece0";
}

.ph-pen-thin::before {
  content: "\ece1";
}

.ph-pencil-circle-thin::before {
  content: "\ece2";
}

.ph-pencil-line-thin::before {
  content: "\ece3";
}

.ph-pencil-simple-line-thin::before {
  content: "\ece4";
}

.ph-pencil-simple-thin::before {
  content: "\ece5";
}

.ph-pencil-thin::before {
  content: "\ece6";
}

.ph-percent-thin::before {
  content: "\ece7";
}

.ph-person-simple-run-thin::before {
  content: "\ece8";
}

.ph-person-simple-thin::before {
  content: "\ece9";
}

.ph-person-simple-walk-thin::before {
  content: "\ecea";
}

.ph-person-thin::before {
  content: "\eceb";
}

.ph-perspective-thin::before {
  content: "\ecec";
}

.ph-phone-call-thin::before {
  content: "\eced";
}

.ph-phone-disconnect-thin::before {
  content: "\ecee";
}

.ph-phone-incoming-thin::before {
  content: "\ecef";
}

.ph-phone-outgoing-thin::before {
  content: "\ecf0";
}

.ph-phone-slash-thin::before {
  content: "\ecf1";
}

.ph-phone-thin::before {
  content: "\ecf2";
}

.ph-phone-x-thin::before {
  content: "\ecf3";
}

.ph-phosphor-logo-thin::before {
  content: "\ecf4";
}

.ph-piano-keys-thin::before {
  content: "\ecf5";
}

.ph-picture-in-picture-thin::before {
  content: "\ecf6";
}

.ph-pill-thin::before {
  content: "\ecf7";
}

.ph-pinterest-logo-thin::before {
  content: "\ecf8";
}

.ph-pinwheel-thin::before {
  content: "\ecf9";
}

.ph-pizza-thin::before {
  content: "\ecfa";
}

.ph-placeholder-thin::before {
  content: "\ecfb";
}

.ph-planet-thin::before {
  content: "\ecfc";
}

.ph-play-circle-thin::before {
  content: "\ecfd";
}

.ph-play-thin::before {
  content: "\ecfe";
}

.ph-playlist-thin::before {
  content: "\ecff";
}

.ph-plug-thin::before {
  content: "\ed00";
}

.ph-plugs-connected-thin::before {
  content: "\ed01";
}

.ph-plugs-thin::before {
  content: "\ed02";
}

.ph-plus-circle-thin::before {
  content: "\ed03";
}

.ph-plus-minus-thin::before {
  content: "\ed04";
}

.ph-plus-thin::before {
  content: "\ed05";
}

.ph-poker-chip-thin::before {
  content: "\ed06";
}

.ph-police-car-thin::before {
  content: "\ed07";
}

.ph-polygon-thin::before {
  content: "\ed08";
}

.ph-popcorn-thin::before {
  content: "\ed09";
}

.ph-power-thin::before {
  content: "\ed0a";
}

.ph-prescription-thin::before {
  content: "\ed0b";
}

.ph-presentation-chart-thin::before {
  content: "\ed0c";
}

.ph-presentation-thin::before {
  content: "\ed0d";
}

.ph-printer-thin::before {
  content: "\ed0e";
}

.ph-prohibit-inset-thin::before {
  content: "\ed0f";
}

.ph-prohibit-thin::before {
  content: "\ed10";
}

.ph-projector-screen-chart-thin::before {
  content: "\ed11";
}

.ph-projector-screen-thin::before {
  content: "\ed12";
}

.ph-push-pin-simple-slash-thin::before {
  content: "\ed13";
}

.ph-push-pin-simple-thin::before {
  content: "\ed14";
}

.ph-push-pin-slash-thin::before {
  content: "\ed15";
}

.ph-push-pin-thin::before {
  content: "\ed16";
}

.ph-puzzle-piece-thin::before {
  content: "\ed17";
}

.ph-qr-code-thin::before {
  content: "\ed18";
}

.ph-question-thin::before {
  content: "\ed19";
}

.ph-queue-thin::before {
  content: "\ed1a";
}

.ph-quotes-thin::before {
  content: "\ed1b";
}

.ph-radical-thin::before {
  content: "\ed1c";
}

.ph-radio-button-thin::before {
  content: "\ed1d";
}

.ph-radio-thin::before {
  content: "\ed1e";
}

.ph-rainbow-cloud-thin::before {
  content: "\ed1f";
}

.ph-rainbow-thin::before {
  content: "\ed20";
}

.ph-receipt-thin::before {
  content: "\ed21";
}

.ph-record-thin::before {
  content: "\ed22";
}

.ph-rectangle-thin::before {
  content: "\ed23";
}

.ph-recycle-thin::before {
  content: "\ed24";
}

.ph-reddit-logo-thin::before {
  content: "\ed25";
}

.ph-repeat-once-thin::before {
  content: "\ed26";
}

.ph-repeat-thin::before {
  content: "\ed27";
}

.ph-rewind-circle-thin::before {
  content: "\ed28";
}

.ph-rewind-thin::before {
  content: "\ed29";
}

.ph-robot-thin::before {
  content: "\ed2a";
}

.ph-rocket-launch-thin::before {
  content: "\ed2b";
}

.ph-rocket-thin::before {
  content: "\ed2c";
}

.ph-rows-thin::before {
  content: "\ed2d";
}

.ph-rss-simple-thin::before {
  content: "\ed2e";
}

.ph-rss-thin::before {
  content: "\ed2f";
}

.ph-rug-thin::before {
  content: "\ed30";
}

.ph-ruler-thin::before {
  content: "\ed31";
}

.ph-scales-thin::before {
  content: "\ed32";
}

.ph-scan-thin::before {
  content: "\ed33";
}

.ph-scissors-thin::before {
  content: "\ed34";
}

.ph-screencast-thin::before {
  content: "\ed35";
}

.ph-scribble-loop-thin::before {
  content: "\ed36";
}

.ph-scroll-thin::before {
  content: "\ed37";
}

.ph-selection-all-thin::before {
  content: "\ed38";
}

.ph-selection-background-thin::before {
  content: "\ed39";
}

.ph-selection-foreground-thin::before {
  content: "\ed3a";
}

.ph-selection-inverse-thin::before {
  content: "\ed3b";
}

.ph-selection-plus-thin::before {
  content: "\ed3c";
}

.ph-selection-slash-thin::before {
  content: "\ed3d";
}

.ph-selection-thin::before {
  content: "\ed3e";
}

.ph-share-network-thin::before {
  content: "\ed3f";
}

.ph-share-thin::before {
  content: "\ed40";
}

.ph-shield-check-thin::before {
  content: "\ed41";
}

.ph-shield-checkered-thin::before {
  content: "\ed42";
}

.ph-shield-chevron-thin::before {
  content: "\ed43";
}

.ph-shield-plus-thin::before {
  content: "\ed44";
}

.ph-shield-slash-thin::before {
  content: "\ed45";
}

.ph-shield-star-thin::before {
  content: "\ed46";
}

.ph-shield-thin::before {
  content: "\ed47";
}

.ph-shield-warning-thin::before {
  content: "\ed48";
}

.ph-shopping-bag-open-thin::before {
  content: "\ed49";
}

.ph-shopping-bag-thin::before {
  content: "\ed4a";
}

.ph-shopping-cart-simple-thin::before {
  content: "\ed4b";
}

.ph-shopping-cart-thin::before {
  content: "\ed4c";
}

.ph-shower-thin::before {
  content: "\ed4d";
}

.ph-shuffle-angular-thin::before {
  content: "\ed4e";
}

.ph-shuffle-simple-thin::before {
  content: "\ed4f";
}

.ph-shuffle-thin::before {
  content: "\ed50";
}

.ph-sidebar-simple-thin::before {
  content: "\ed51";
}

.ph-sidebar-thin::before {
  content: "\ed52";
}

.ph-sign-in-thin::before {
  content: "\ed53";
}

.ph-sign-out-thin::before {
  content: "\ed54";
}

.ph-signpost-thin::before {
  content: "\ed55";
}

.ph-sim-card-thin::before {
  content: "\ed56";
}

.ph-sketch-logo-thin::before {
  content: "\ed57";
}

.ph-skip-back-circle-thin::before {
  content: "\ed58";
}

.ph-skip-back-thin::before {
  content: "\ed59";
}

.ph-skip-forward-circle-thin::before {
  content: "\ed5a";
}

.ph-skip-forward-thin::before {
  content: "\ed5b";
}

.ph-skull-thin::before {
  content: "\ed5c";
}

.ph-slack-logo-thin::before {
  content: "\ed5d";
}

.ph-sliders-horizontal-thin::before {
  content: "\ed5e";
}

.ph-sliders-thin::before {
  content: "\ed5f";
}

.ph-smiley-blank-thin::before {
  content: "\ed60";
}

.ph-smiley-meh-thin::before {
  content: "\ed61";
}

.ph-smiley-nervous-thin::before {
  content: "\ed62";
}

.ph-smiley-sad-thin::before {
  content: "\ed63";
}

.ph-smiley-sticker-thin::before {
  content: "\ed64";
}

.ph-smiley-thin::before {
  content: "\ed65";
}

.ph-smiley-wink-thin::before {
  content: "\ed66";
}

.ph-smiley-x-eyes-thin::before {
  content: "\ed67";
}

.ph-snapchat-logo-thin::before {
  content: "\ed68";
}

.ph-snowflake-thin::before {
  content: "\ed69";
}

.ph-soccer-ball-thin::before {
  content: "\ed6a";
}

.ph-sort-ascending-thin::before {
  content: "\ed6b";
}

.ph-sort-descending-thin::before {
  content: "\ed6c";
}

.ph-spade-thin::before {
  content: "\ed6d";
}

.ph-sparkle-thin::before {
  content: "\ed6e";
}

.ph-speaker-high-thin::before {
  content: "\ed6f";
}

.ph-speaker-low-thin::before {
  content: "\ed70";
}

.ph-speaker-none-thin::before {
  content: "\ed71";
}

.ph-speaker-simple-high-thin::before {
  content: "\ed72";
}

.ph-speaker-simple-low-thin::before {
  content: "\ed73";
}

.ph-speaker-simple-none-thin::before {
  content: "\ed74";
}

.ph-speaker-simple-slash-thin::before {
  content: "\ed75";
}

.ph-speaker-simple-x-thin::before {
  content: "\ed76";
}

.ph-speaker-slash-thin::before {
  content: "\ed77";
}

.ph-speaker-x-thin::before {
  content: "\ed78";
}

.ph-spinner-gap-thin::before {
  content: "\ed79";
}

.ph-spinner-thin::before {
  content: "\ed7a";
}

.ph-spiral-thin::before {
  content: "\ed7b";
}

.ph-spotify-logo-thin::before {
  content: "\ed7c";
}

.ph-square-half-bottom-thin::before {
  content: "\ed7d";
}

.ph-square-half-thin::before {
  content: "\ed7e";
}

.ph-square-logo-thin::before {
  content: "\ed7f";
}

.ph-square-thin::before {
  content: "\ed80";
}

.ph-squares-four-thin::before {
  content: "\ed81";
}

.ph-stack-overflow-logo-thin::before {
  content: "\ed82";
}

.ph-stack-simple-thin::before {
  content: "\ed83";
}

.ph-stack-thin::before {
  content: "\ed84";
}

.ph-stamp-thin::before {
  content: "\ed85";
}

.ph-star-four-thin::before {
  content: "\ed86";
}

.ph-star-half-thin::before {
  content: "\ed87";
}

.ph-star-thin::before {
  content: "\ed88";
}

.ph-sticker-thin::before {
  content: "\ed89";
}

.ph-stop-circle-thin::before {
  content: "\ed8a";
}

.ph-stop-thin::before {
  content: "\ed8b";
}

.ph-storefront-thin::before {
  content: "\ed8c";
}

.ph-strategy-thin::before {
  content: "\ed8d";
}

.ph-stripe-logo-thin::before {
  content: "\ed8e";
}

.ph-student-thin::before {
  content: "\ed8f";
}

.ph-suitcase-simple-thin::before {
  content: "\ed90";
}

.ph-suitcase-thin::before {
  content: "\ed91";
}

.ph-sun-dim-thin::before {
  content: "\ed92";
}

.ph-sun-horizon-thin::before {
  content: "\ed93";
}

.ph-sun-thin::before {
  content: "\ed94";
}

.ph-sunglasses-thin::before {
  content: "\ed95";
}

.ph-swap-thin::before {
  content: "\ed96";
}

.ph-swatches-thin::before {
  content: "\ed97";
}

.ph-sword-thin::before {
  content: "\ed98";
}

.ph-syringe-thin::before {
  content: "\ed99";
}

.ph-t-shirt-thin::before {
  content: "\ed9a";
}

.ph-table-thin::before {
  content: "\ed9b";
}

.ph-tabs-thin::before {
  content: "\ed9c";
}

.ph-tag-chevron-thin::before {
  content: "\ed9d";
}

.ph-tag-simple-thin::before {
  content: "\ed9e";
}

.ph-tag-thin::before {
  content: "\ed9f";
}

.ph-target-thin::before {
  content: "\eda0";
}

.ph-taxi-thin::before {
  content: "\eda1";
}

.ph-telegram-logo-thin::before {
  content: "\eda2";
}

.ph-television-simple-thin::before {
  content: "\eda3";
}

.ph-television-thin::before {
  content: "\eda4";
}

.ph-tennis-ball-thin::before {
  content: "\eda5";
}

.ph-terminal-thin::before {
  content: "\eda6";
}

.ph-terminal-window-thin::before {
  content: "\eda7";
}

.ph-test-tube-thin::before {
  content: "\eda8";
}

.ph-text-aa-thin::before {
  content: "\eda9";
}

.ph-text-align-center-thin::before {
  content: "\edaa";
}

.ph-text-align-justify-thin::before {
  content: "\edab";
}

.ph-text-align-left-thin::before {
  content: "\edac";
}

.ph-text-align-right-thin::before {
  content: "\edad";
}

.ph-text-bolder-thin::before {
  content: "\edae";
}

.ph-text-h-five-thin::before {
  content: "\edaf";
}

.ph-text-h-four-thin::before {
  content: "\edb0";
}

.ph-text-h-one-thin::before {
  content: "\edb1";
}

.ph-text-h-six-thin::before {
  content: "\edb2";
}

.ph-text-h-thin::before {
  content: "\edb3";
}

.ph-text-h-three-thin::before {
  content: "\edb4";
}

.ph-text-h-two-thin::before {
  content: "\edb5";
}

.ph-text-indent-thin::before {
  content: "\edb6";
}

.ph-text-italic-thin::before {
  content: "\edb7";
}

.ph-text-outdent-thin::before {
  content: "\edb8";
}

.ph-text-strikethrough-thin::before {
  content: "\edb9";
}

.ph-text-t-thin::before {
  content: "\edba";
}

.ph-text-underline-thin::before {
  content: "\edbb";
}

.ph-textbox-thin::before {
  content: "\edbc";
}

.ph-thermometer-cold-thin::before {
  content: "\edbd";
}

.ph-thermometer-hot-thin::before {
  content: "\edbe";
}

.ph-thermometer-simple-thin::before {
  content: "\edbf";
}

.ph-thermometer-thin::before {
  content: "\edc0";
}

.ph-thumbs-down-thin::before {
  content: "\edc1";
}

.ph-thumbs-up-thin::before {
  content: "\edc2";
}

.ph-ticket-thin::before {
  content: "\edc3";
}

.ph-tiktok-logo-thin::before {
  content: "\edc4";
}

.ph-timer-thin::before {
  content: "\edc5";
}

.ph-toggle-left-thin::before {
  content: "\edc6";
}

.ph-toggle-right-thin::before {
  content: "\edc7";
}

.ph-toilet-paper-thin::before {
  content: "\edc8";
}

.ph-toilet-thin::before {
  content: "\edc9";
}

.ph-tote-simple-thin::before {
  content: "\edca";
}

.ph-tote-thin::before {
  content: "\edcb";
}

.ph-trademark-registered-thin::before {
  content: "\edcc";
}

.ph-traffic-cone-thin::before {
  content: "\edcd";
}

.ph-traffic-sign-thin::before {
  content: "\edce";
}

.ph-traffic-signal-thin::before {
  content: "\edcf";
}

.ph-train-regional-thin::before {
  content: "\edd0";
}

.ph-train-simple-thin::before {
  content: "\edd1";
}

.ph-train-thin::before {
  content: "\edd2";
}

.ph-translate-thin::before {
  content: "\edd3";
}

.ph-trash-simple-thin::before {
  content: "\edd4";
}

.ph-trash-thin::before {
  content: "\edd5";
}

.ph-tray-thin::before {
  content: "\edd6";
}

.ph-tree-evergreen-thin::before {
  content: "\edd7";
}

.ph-tree-structure-thin::before {
  content: "\edd8";
}

.ph-tree-thin::before {
  content: "\edd9";
}

.ph-trend-down-thin::before {
  content: "\edda";
}

.ph-trend-up-thin::before {
  content: "\eddb";
}

.ph-triangle-thin::before {
  content: "\eddc";
}

.ph-trophy-thin::before {
  content: "\eddd";
}

.ph-truck-thin::before {
  content: "\edde";
}

.ph-twitch-logo-thin::before {
  content: "\eddf";
}

.ph-twitter-logo-thin::before {
  content: "\ede0";
}

.ph-umbrella-simple-thin::before {
  content: "\ede1";
}

.ph-umbrella-thin::before {
  content: "\ede2";
}

.ph-upload-simple-thin::before {
  content: "\ede3";
}

.ph-upload-thin::before {
  content: "\ede4";
}

.ph-user-circle-gear-thin::before {
  content: "\ede5";
}

.ph-user-circle-minus-thin::before {
  content: "\ede6";
}

.ph-user-circle-plus-thin::before {
  content: "\ede7";
}

.ph-user-circle-thin::before {
  content: "\ede8";
}

.ph-user-focus-thin::before {
  content: "\ede9";
}

.ph-user-gear-thin::before {
  content: "\edea";
}

.ph-user-list-thin::before {
  content: "\edeb";
}

.ph-user-minus-thin::before {
  content: "\edec";
}

.ph-user-plus-thin::before {
  content: "\eded";
}

.ph-user-rectangle-thin::before {
  content: "\edee";
}

.ph-user-square-thin::before {
  content: "\edef";
}

.ph-user-switch-thin::before {
  content: "\edf0";
}

.ph-user-thin::before {
  content: "\edf1";
}

.ph-users-four-thin::before {
  content: "\edf2";
}

.ph-users-thin::before {
  content: "\edf3";
}

.ph-users-three-thin::before {
  content: "\edf4";
}

.ph-vault-thin::before {
  content: "\edf5";
}

.ph-vibrate-thin::before {
  content: "\edf6";
}

.ph-video-camera-slash-thin::before {
  content: "\edf7";
}

.ph-video-camera-thin::before {
  content: "\edf8";
}

.ph-vignette-thin::before {
  content: "\edf9";
}

.ph-voicemail-thin::before {
  content: "\edfa";
}

.ph-volleyball-thin::before {
  content: "\edfb";
}

.ph-wall-thin::before {
  content: "\edfc";
}

.ph-wallet-thin::before {
  content: "\edfd";
}

.ph-warning-circle-thin::before {
  content: "\edfe";
}

.ph-warning-octagon-thin::before {
  content: "\edff";
}

.ph-warning-thin::before {
  content: "\ee00";
}

.ph-watch-thin::before {
  content: "\ee01";
}

.ph-wave-sawtooth-thin::before {
  content: "\ee02";
}

.ph-wave-sine-thin::before {
  content: "\ee03";
}

.ph-wave-square-thin::before {
  content: "\ee04";
}

.ph-wave-triangle-thin::before {
  content: "\ee05";
}

.ph-waves-thin::before {
  content: "\ee06";
}

.ph-webcam-thin::before {
  content: "\ee07";
}

.ph-whatsapp-logo-thin::before {
  content: "\ee08";
}

.ph-wheelchair-thin::before {
  content: "\ee09";
}

.ph-wifi-high-thin::before {
  content: "\ee0a";
}

.ph-wifi-low-thin::before {
  content: "\ee0b";
}

.ph-wifi-medium-thin::before {
  content: "\ee0c";
}

.ph-wifi-none-thin::before {
  content: "\ee0d";
}

.ph-wifi-slash-thin::before {
  content: "\ee0e";
}

.ph-wifi-x-thin::before {
  content: "\ee0f";
}

.ph-wind-thin::before {
  content: "\ee10";
}

.ph-windows-logo-thin::before {
  content: "\ee11";
}

.ph-wine-thin::before {
  content: "\ee12";
}

.ph-wrench-thin::before {
  content: "\ee13";
}

.ph-x-circle-thin::before {
  content: "\ee14";
}

.ph-x-square-thin::before {
  content: "\ee15";
}

.ph-x-thin::before {
  content: "\ee16";
}

.ph-yin-yang-thin::before {
  content: "\ee17";
}

.ph-youtube-logo-thin::before {
  content: "\ee18";
}

.ph-activity-light::before {
  content: "\ee19";
}

.ph-address-book-light::before {
  content: "\ee1a";
}

.ph-airplane-in-flight-light::before {
  content: "\ee1b";
}

.ph-airplane-landing-light::before {
  content: "\ee1c";
}

.ph-airplane-light::before {
  content: "\ee1d";
}

.ph-airplane-takeoff-light::before {
  content: "\ee1e";
}

.ph-airplane-tilt-light::before {
  content: "\ee1f";
}

.ph-airplay-light::before {
  content: "\ee20";
}

.ph-alarm-light::before {
  content: "\ee21";
}

.ph-alien-light::before {
  content: "\ee22";
}

.ph-align-bottom-light::before {
  content: "\ee23";
}

.ph-align-bottom-simple-light::before {
  content: "\ee24";
}

.ph-align-center-horizontal-light::before {
  content: "\ee25";
}

.ph-align-center-horizontal-simple-light::before {
  content: "\ee26";
}

.ph-align-center-vertical-light::before {
  content: "\ee27";
}

.ph-align-center-vertical-simple-light::before {
  content: "\ee28";
}

.ph-align-left-light::before {
  content: "\ee29";
}

.ph-align-left-simple-light::before {
  content: "\ee2a";
}

.ph-align-right-light::before {
  content: "\ee2b";
}

.ph-align-right-simple-light::before {
  content: "\ee2c";
}

.ph-align-top-light::before {
  content: "\ee2d";
}

.ph-align-top-simple-light::before {
  content: "\ee2e";
}

.ph-anchor-light::before {
  content: "\ee2f";
}

.ph-anchor-simple-light::before {
  content: "\ee30";
}

.ph-android-logo-light::before {
  content: "\ee31";
}

.ph-angular-logo-light::before {
  content: "\ee32";
}

.ph-aperture-light::before {
  content: "\ee33";
}

.ph-app-store-logo-light::before {
  content: "\ee34";
}

.ph-app-window-light::before {
  content: "\ee35";
}

.ph-apple-logo-light::before {
  content: "\ee36";
}

.ph-apple-podcasts-logo-light::before {
  content: "\ee37";
}

.ph-archive-box-light::before {
  content: "\ee38";
}

.ph-archive-light::before {
  content: "\ee39";
}

.ph-archive-tray-light::before {
  content: "\ee3a";
}

.ph-armchair-light::before {
  content: "\ee3b";
}

.ph-arrow-arc-left-light::before {
  content: "\ee3c";
}

.ph-arrow-arc-right-light::before {
  content: "\ee3d";
}

.ph-arrow-bend-double-up-left-light::before {
  content: "\ee3e";
}

.ph-arrow-bend-double-up-right-light::before {
  content: "\ee3f";
}

.ph-arrow-bend-down-left-light::before {
  content: "\ee40";
}

.ph-arrow-bend-down-right-light::before {
  content: "\ee41";
}

.ph-arrow-bend-left-down-light::before {
  content: "\ee42";
}

.ph-arrow-bend-left-up-light::before {
  content: "\ee43";
}

.ph-arrow-bend-right-down-light::before {
  content: "\ee44";
}

.ph-arrow-bend-right-up-light::before {
  content: "\ee45";
}

.ph-arrow-bend-up-left-light::before {
  content: "\ee46";
}

.ph-arrow-bend-up-right-light::before {
  content: "\ee47";
}

.ph-arrow-circle-down-left-light::before {
  content: "\ee48";
}

.ph-arrow-circle-down-light::before {
  content: "\ee49";
}

.ph-arrow-circle-down-right-light::before {
  content: "\ee4a";
}

.ph-arrow-circle-left-light::before {
  content: "\ee4b";
}

.ph-arrow-circle-right-light::before {
  content: "\ee4c";
}

.ph-arrow-circle-up-left-light::before {
  content: "\ee4d";
}

.ph-arrow-circle-up-light::before {
  content: "\ee4e";
}

.ph-arrow-circle-up-right-light::before {
  content: "\ee4f";
}

.ph-arrow-clockwise-light::before {
  content: "\ee50";
}

.ph-arrow-counter-clockwise-light::before {
  content: "\ee51";
}

.ph-arrow-down-left-light::before {
  content: "\ee52";
}

.ph-arrow-down-light::before {
  content: "\ee53";
}

.ph-arrow-down-right-light::before {
  content: "\ee54";
}

.ph-arrow-elbow-down-left-light::before {
  content: "\ee55";
}

.ph-arrow-elbow-down-right-light::before {
  content: "\ee56";
}

.ph-arrow-elbow-left-down-light::before {
  content: "\ee57";
}

.ph-arrow-elbow-left-light::before {
  content: "\ee58";
}

.ph-arrow-elbow-left-up-light::before {
  content: "\ee59";
}

.ph-arrow-elbow-right-down-light::before {
  content: "\ee5a";
}

.ph-arrow-elbow-right-light::before {
  content: "\ee5b";
}

.ph-arrow-elbow-right-up-light::before {
  content: "\ee5c";
}

.ph-arrow-elbow-up-left-light::before {
  content: "\ee5d";
}

.ph-arrow-elbow-up-right-light::before {
  content: "\ee5e";
}

.ph-arrow-fat-down-light::before {
  content: "\ee5f";
}

.ph-arrow-fat-left-light::before {
  content: "\ee60";
}

.ph-arrow-fat-line-down-light::before {
  content: "\ee61";
}

.ph-arrow-fat-line-left-light::before {
  content: "\ee62";
}

.ph-arrow-fat-line-right-light::before {
  content: "\ee63";
}

.ph-arrow-fat-line-up-light::before {
  content: "\ee64";
}

.ph-arrow-fat-lines-down-light::before {
  content: "\ee65";
}

.ph-arrow-fat-lines-left-light::before {
  content: "\ee66";
}

.ph-arrow-fat-lines-right-light::before {
  content: "\ee67";
}

.ph-arrow-fat-lines-up-light::before {
  content: "\ee68";
}

.ph-arrow-fat-right-light::before {
  content: "\ee69";
}

.ph-arrow-fat-up-light::before {
  content: "\ee6a";
}

.ph-arrow-left-light::before {
  content: "\ee6b";
}

.ph-arrow-line-down-left-light::before {
  content: "\ee6c";
}

.ph-arrow-line-down-light::before {
  content: "\ee6d";
}

.ph-arrow-line-down-right-light::before {
  content: "\ee6e";
}

.ph-arrow-line-left-light::before {
  content: "\ee6f";
}

.ph-arrow-line-right-light::before {
  content: "\ee70";
}

.ph-arrow-line-up-left-light::before {
  content: "\ee71";
}

.ph-arrow-line-up-light::before {
  content: "\ee72";
}

.ph-arrow-line-up-right-light::before {
  content: "\ee73";
}

.ph-arrow-right-light::before {
  content: "\ee74";
}

.ph-arrow-square-down-left-light::before {
  content: "\ee75";
}

.ph-arrow-square-down-light::before {
  content: "\ee76";
}

.ph-arrow-square-down-right-light::before {
  content: "\ee77";
}

.ph-arrow-square-in-light::before {
  content: "\ee78";
}

.ph-arrow-square-left-light::before {
  content: "\ee79";
}

.ph-arrow-square-out-light::before {
  content: "\ee7a";
}

.ph-arrow-square-right-light::before {
  content: "\ee7b";
}

.ph-arrow-square-up-left-light::before {
  content: "\ee7c";
}

.ph-arrow-square-up-light::before {
  content: "\ee7d";
}

.ph-arrow-square-up-right-light::before {
  content: "\ee7e";
}

.ph-arrow-u-down-left-light::before {
  content: "\ee7f";
}

.ph-arrow-u-down-right-light::before {
  content: "\ee80";
}

.ph-arrow-u-left-down-light::before {
  content: "\ee81";
}

.ph-arrow-u-left-up-light::before {
  content: "\ee82";
}

.ph-arrow-u-right-down-light::before {
  content: "\ee83";
}

.ph-arrow-u-right-up-light::before {
  content: "\ee84";
}

.ph-arrow-u-up-left-light::before {
  content: "\ee85";
}

.ph-arrow-u-up-right-light::before {
  content: "\ee86";
}

.ph-arrow-up-left-light::before {
  content: "\ee87";
}

.ph-arrow-up-light::before {
  content: "\ee88";
}

.ph-arrow-up-right-light::before {
  content: "\ee89";
}

.ph-arrows-clockwise-light::before {
  content: "\ee8a";
}

.ph-arrows-counter-clockwise-light::before {
  content: "\ee8b";
}

.ph-arrows-down-up-light::before {
  content: "\ee8c";
}

.ph-arrows-horizontal-light::before {
  content: "\ee8d";
}

.ph-arrows-in-cardinal-light::before {
  content: "\ee8e";
}

.ph-arrows-in-light::before {
  content: "\ee8f";
}

.ph-arrows-in-line-horizontal-light::before {
  content: "\ee90";
}

.ph-arrows-in-line-vertical-light::before {
  content: "\ee91";
}

.ph-arrows-in-simple-light::before {
  content: "\ee92";
}

.ph-arrows-left-right-light::before {
  content: "\ee93";
}

.ph-arrows-out-cardinal-light::before {
  content: "\ee94";
}

.ph-arrows-out-light::before {
  content: "\ee95";
}

.ph-arrows-out-line-horizontal-light::before {
  content: "\ee96";
}

.ph-arrows-out-line-vertical-light::before {
  content: "\ee97";
}

.ph-arrows-out-simple-light::before {
  content: "\ee98";
}

.ph-arrows-vertical-light::before {
  content: "\ee99";
}

.ph-article-light::before {
  content: "\ee9a";
}

.ph-article-medium-light::before {
  content: "\ee9b";
}

.ph-article-ny-times-light::before {
  content: "\ee9c";
}

.ph-asterisk-light::before {
  content: "\ee9d";
}

.ph-asterisk-simple-light::before {
  content: "\ee9e";
}

.ph-at-light::before {
  content: "\ee9f";
}

.ph-atom-light::before {
  content: "\eea0";
}

.ph-baby-light::before {
  content: "\eea1";
}

.ph-backpack-light::before {
  content: "\eea2";
}

.ph-backspace-light::before {
  content: "\eea3";
}

.ph-bag-light::before {
  content: "\eea4";
}

.ph-bag-simple-light::before {
  content: "\eea5";
}

.ph-balloon-light::before {
  content: "\eea6";
}

.ph-bandaids-light::before {
  content: "\eea7";
}

.ph-bank-light::before {
  content: "\eea8";
}

.ph-barbell-light::before {
  content: "\eea9";
}

.ph-barcode-light::before {
  content: "\eeaa";
}

.ph-barricade-light::before {
  content: "\eeab";
}

.ph-baseball-light::before {
  content: "\eeac";
}

.ph-basketball-light::before {
  content: "\eead";
}

.ph-bathtub-light::before {
  content: "\eeae";
}

.ph-battery-charging-light::before {
  content: "\eeaf";
}

.ph-battery-charging-vertical-light::before {
  content: "\eeb0";
}

.ph-battery-empty-light::before {
  content: "\eeb1";
}

.ph-battery-full-light::before {
  content: "\eeb2";
}

.ph-battery-high-light::before {
  content: "\eeb3";
}

.ph-battery-low-light::before {
  content: "\eeb4";
}

.ph-battery-medium-light::before {
  content: "\eeb5";
}

.ph-battery-plus-light::before {
  content: "\eeb6";
}

.ph-battery-warning-light::before {
  content: "\eeb7";
}

.ph-battery-warning-vertical-light::before {
  content: "\eeb8";
}

.ph-bed-light::before {
  content: "\eeb9";
}

.ph-beer-bottle-light::before {
  content: "\eeba";
}

.ph-behance-logo-light::before {
  content: "\eebb";
}

.ph-bell-light::before {
  content: "\eebc";
}

.ph-bell-ringing-light::before {
  content: "\eebd";
}

.ph-bell-simple-light::before {
  content: "\eebe";
}

.ph-bell-simple-ringing-light::before {
  content: "\eebf";
}

.ph-bell-simple-slash-light::before {
  content: "\eec0";
}

.ph-bell-simple-z-light::before {
  content: "\eec1";
}

.ph-bell-slash-light::before {
  content: "\eec2";
}

.ph-bell-z-light::before {
  content: "\eec3";
}

.ph-bezier-curve-light::before {
  content: "\eec4";
}

.ph-bicycle-light::before {
  content: "\eec5";
}

.ph-binoculars-light::before {
  content: "\eec6";
}

.ph-bird-light::before {
  content: "\eec7";
}

.ph-bluetooth-connected-light::before {
  content: "\eec8";
}

.ph-bluetooth-light::before {
  content: "\eec9";
}

.ph-bluetooth-slash-light::before {
  content: "\eeca";
}

.ph-bluetooth-x-light::before {
  content: "\eecb";
}

.ph-boat-light::before {
  content: "\eecc";
}

.ph-book-bookmark-light::before {
  content: "\eecd";
}

.ph-book-light::before {
  content: "\eece";
}

.ph-book-open-light::before {
  content: "\eecf";
}

.ph-bookmark-light::before {
  content: "\eed0";
}

.ph-bookmark-simple-light::before {
  content: "\eed1";
}

.ph-bookmarks-light::before {
  content: "\eed2";
}

.ph-bookmarks-simple-light::before {
  content: "\eed3";
}

.ph-books-light::before {
  content: "\eed4";
}

.ph-bounding-box-light::before {
  content: "\eed5";
}

.ph-brackets-angle-light::before {
  content: "\eed6";
}

.ph-brackets-curly-light::before {
  content: "\eed7";
}

.ph-brackets-round-light::before {
  content: "\eed8";
}

.ph-brackets-square-light::before {
  content: "\eed9";
}

.ph-brain-light::before {
  content: "\eeda";
}

.ph-brandy-light::before {
  content: "\eedb";
}

.ph-briefcase-light::before {
  content: "\eedc";
}

.ph-briefcase-metal-light::before {
  content: "\eedd";
}

.ph-broadcast-light::before {
  content: "\eede";
}

.ph-browser-light::before {
  content: "\eedf";
}

.ph-browsers-light::before {
  content: "\eee0";
}

.ph-bug-beetle-light::before {
  content: "\eee1";
}

.ph-bug-droid-light::before {
  content: "\eee2";
}

.ph-bug-light::before {
  content: "\eee3";
}

.ph-buildings-light::before {
  content: "\eee4";
}

.ph-bus-light::before {
  content: "\eee5";
}

.ph-butterfly-light::before {
  content: "\eee6";
}

.ph-cactus-light::before {
  content: "\eee7";
}

.ph-cake-light::before {
  content: "\eee8";
}

.ph-calculator-light::before {
  content: "\eee9";
}

.ph-calendar-blank-light::before {
  content: "\eeea";
}

.ph-calendar-check-light::before {
  content: "\eeeb";
}

.ph-calendar-light::before {
  content: "\eeec";
}

.ph-calendar-plus-light::before {
  content: "\eeed";
}

.ph-calendar-x-light::before {
  content: "\eeee";
}

.ph-camera-light::before {
  content: "\eeef";
}

.ph-camera-rotate-light::before {
  content: "\eef0";
}

.ph-camera-slash-light::before {
  content: "\eef1";
}

.ph-campfire-light::before {
  content: "\eef2";
}

.ph-car-light::before {
  content: "\eef3";
}

.ph-car-simple-light::before {
  content: "\eef4";
}

.ph-cardholder-light::before {
  content: "\eef5";
}

.ph-cards-light::before {
  content: "\eef6";
}

.ph-caret-circle-double-down-light::before {
  content: "\eef7";
}

.ph-caret-circle-double-left-light::before {
  content: "\eef8";
}

.ph-caret-circle-double-right-light::before {
  content: "\eef9";
}

.ph-caret-circle-double-up-light::before {
  content: "\eefa";
}

.ph-caret-circle-down-light::before {
  content: "\eefb";
}

.ph-caret-circle-left-light::before {
  content: "\eefc";
}

.ph-caret-circle-right-light::before {
  content: "\eefd";
}

.ph-caret-circle-up-light::before {
  content: "\eefe";
}

.ph-caret-double-down-light::before {
  content: "\eeff";
}

.ph-caret-double-left-light::before {
  content: "\ef00";
}

.ph-caret-double-right-light::before {
  content: "\ef01";
}

.ph-caret-double-up-light::before {
  content: "\ef02";
}

.ph-caret-down-light::before {
  content: "\ef03";
}

.ph-caret-left-light::before {
  content: "\ef04";
}

.ph-caret-right-light::before {
  content: "\ef05";
}

.ph-caret-up-light::before {
  content: "\ef06";
}

.ph-cat-light::before {
  content: "\ef07";
}

.ph-cell-signal-full-light::before {
  content: "\ef08";
}

.ph-cell-signal-high-light::before {
  content: "\ef09";
}

.ph-cell-signal-low-light::before {
  content: "\ef0a";
}

.ph-cell-signal-medium-light::before {
  content: "\ef0b";
}

.ph-cell-signal-none-light::before {
  content: "\ef0c";
}

.ph-cell-signal-slash-light::before {
  content: "\ef0d";
}

.ph-cell-signal-x-light::before {
  content: "\ef0e";
}

.ph-chalkboard-light::before {
  content: "\ef0f";
}

.ph-chalkboard-simple-light::before {
  content: "\ef10";
}

.ph-chalkboard-teacher-light::before {
  content: "\ef11";
}

.ph-chart-bar-horizontal-light::before {
  content: "\ef12";
}

.ph-chart-bar-light::before {
  content: "\ef13";
}

.ph-chart-line-light::before {
  content: "\ef14";
}

.ph-chart-line-up-light::before {
  content: "\ef15";
}

.ph-chart-pie-light::before {
  content: "\ef16";
}

.ph-chart-pie-slice-light::before {
  content: "\ef17";
}

.ph-chat-centered-dots-light::before {
  content: "\ef18";
}

.ph-chat-centered-light::before {
  content: "\ef19";
}

.ph-chat-centered-text-light::before {
  content: "\ef1a";
}

.ph-chat-circle-dots-light::before {
  content: "\ef1b";
}

.ph-chat-circle-light::before {
  content: "\ef1c";
}

.ph-chat-circle-text-light::before {
  content: "\ef1d";
}

.ph-chat-dots-light::before {
  content: "\ef1e";
}

.ph-chat-light::before {
  content: "\ef1f";
}

.ph-chat-teardrop-dots-light::before {
  content: "\ef20";
}

.ph-chat-teardrop-light::before {
  content: "\ef21";
}

.ph-chat-teardrop-text-light::before {
  content: "\ef22";
}

.ph-chat-text-light::before {
  content: "\ef23";
}

.ph-chats-circle-light::before {
  content: "\ef24";
}

.ph-chats-light::before {
  content: "\ef25";
}

.ph-chats-teardrop-light::before {
  content: "\ef26";
}

.ph-check-circle-light::before {
  content: "\ef27";
}

.ph-check-light::before {
  content: "\ef28";
}

.ph-check-square-light::before {
  content: "\ef29";
}

.ph-check-square-offset-light::before {
  content: "\ef2a";
}

.ph-checks-light::before {
  content: "\ef2b";
}

.ph-circle-dashed-light::before {
  content: "\ef2c";
}

.ph-circle-half-light::before {
  content: "\ef2d";
}

.ph-circle-half-tilt-light::before {
  content: "\ef2e";
}

.ph-circle-light::before {
  content: "\ef2f";
}

.ph-circle-notch-light::before {
  content: "\ef30";
}

.ph-circle-wavy-check-light::before {
  content: "\ef31";
}

.ph-circle-wavy-light::before {
  content: "\ef32";
}

.ph-circle-wavy-question-light::before {
  content: "\ef33";
}

.ph-circle-wavy-warning-light::before {
  content: "\ef34";
}

.ph-circles-four-light::before {
  content: "\ef35";
}

.ph-circles-three-light::before {
  content: "\ef36";
}

.ph-circles-three-plus-light::before {
  content: "\ef37";
}

.ph-clipboard-light::before {
  content: "\ef38";
}

.ph-clipboard-text-light::before {
  content: "\ef39";
}

.ph-clock-afternoon-light::before {
  content: "\ef3a";
}

.ph-clock-clockwise-light::before {
  content: "\ef3b";
}

.ph-clock-counter-clockwise-light::before {
  content: "\ef3c";
}

.ph-clock-light::before {
  content: "\ef3d";
}

.ph-closed-captioning-light::before {
  content: "\ef3e";
}

.ph-cloud-arrow-down-light::before {
  content: "\ef3f";
}

.ph-cloud-arrow-up-light::before {
  content: "\ef40";
}

.ph-cloud-check-light::before {
  content: "\ef41";
}

.ph-cloud-fog-light::before {
  content: "\ef42";
}

.ph-cloud-light::before {
  content: "\ef43";
}

.ph-cloud-lightning-light::before {
  content: "\ef44";
}

.ph-cloud-moon-light::before {
  content: "\ef45";
}

.ph-cloud-rain-light::before {
  content: "\ef46";
}

.ph-cloud-slash-light::before {
  content: "\ef47";
}

.ph-cloud-snow-light::before {
  content: "\ef48";
}

.ph-cloud-sun-light::before {
  content: "\ef49";
}

.ph-club-light::before {
  content: "\ef4a";
}

.ph-coat-hanger-light::before {
  content: "\ef4b";
}

.ph-code-light::before {
  content: "\ef4c";
}

.ph-code-simple-light::before {
  content: "\ef4d";
}

.ph-codepen-logo-light::before {
  content: "\ef4e";
}

.ph-codesandbox-logo-light::before {
  content: "\ef4f";
}

.ph-coffee-light::before {
  content: "\ef50";
}

.ph-coin-light::before {
  content: "\ef51";
}

.ph-coin-vertical-light::before {
  content: "\ef52";
}

.ph-coins-light::before {
  content: "\ef53";
}

.ph-columns-light::before {
  content: "\ef54";
}

.ph-command-light::before {
  content: "\ef55";
}

.ph-compass-light::before {
  content: "\ef56";
}

.ph-computer-tower-light::before {
  content: "\ef57";
}

.ph-confetti-light::before {
  content: "\ef58";
}

.ph-cookie-light::before {
  content: "\ef59";
}

.ph-cooking-pot-light::before {
  content: "\ef5a";
}

.ph-copy-light::before {
  content: "\ef5b";
}

.ph-copy-simple-light::before {
  content: "\ef5c";
}

.ph-copyleft-light::before {
  content: "\ef5d";
}

.ph-copyright-light::before {
  content: "\ef5e";
}

.ph-corners-in-light::before {
  content: "\ef5f";
}

.ph-corners-out-light::before {
  content: "\ef60";
}

.ph-cpu-light::before {
  content: "\ef61";
}

.ph-credit-card-light::before {
  content: "\ef62";
}

.ph-crop-light::before {
  content: "\ef63";
}

.ph-crosshair-light::before {
  content: "\ef64";
}

.ph-crosshair-simple-light::before {
  content: "\ef65";
}

.ph-crown-light::before {
  content: "\ef66";
}

.ph-crown-simple-light::before {
  content: "\ef67";
}

.ph-cube-light::before {
  content: "\ef68";
}

.ph-currency-btc-light::before {
  content: "\ef69";
}

.ph-currency-circle-dollar-light::before {
  content: "\ef6a";
}

.ph-currency-cny-light::before {
  content: "\ef6b";
}

.ph-currency-dollar-light::before {
  content: "\ef6c";
}

.ph-currency-dollar-simple-light::before {
  content: "\ef6d";
}

.ph-currency-eth-light::before {
  content: "\ef6e";
}

.ph-currency-eur-light::before {
  content: "\ef6f";
}

.ph-currency-gbp-light::before {
  content: "\ef70";
}

.ph-currency-inr-light::before {
  content: "\ef71";
}

.ph-currency-jpy-light::before {
  content: "\ef72";
}

.ph-currency-krw-light::before {
  content: "\ef73";
}

.ph-currency-kzt-light::before {
  content: "\ef74";
}

.ph-currency-ngn-light::before {
  content: "\ef75";
}

.ph-currency-rub-light::before {
  content: "\ef76";
}

.ph-cursor-light::before {
  content: "\ef77";
}

.ph-cursor-text-light::before {
  content: "\ef78";
}

.ph-cylinder-light::before {
  content: "\ef79";
}

.ph-database-light::before {
  content: "\ef7a";
}

.ph-desktop-light::before {
  content: "\ef7b";
}

.ph-desktop-tower-light::before {
  content: "\ef7c";
}

.ph-detective-light::before {
  content: "\ef7d";
}

.ph-device-mobile-camera-light::before {
  content: "\ef7e";
}

.ph-device-mobile-light::before {
  content: "\ef7f";
}

.ph-device-mobile-speaker-light::before {
  content: "\ef80";
}

.ph-device-tablet-camera-light::before {
  content: "\ef81";
}

.ph-device-tablet-light::before {
  content: "\ef82";
}

.ph-device-tablet-speaker-light::before {
  content: "\ef83";
}

.ph-diamond-light::before {
  content: "\ef84";
}

.ph-diamonds-four-light::before {
  content: "\ef85";
}

.ph-dice-five-light::before {
  content: "\ef86";
}

.ph-dice-four-light::before {
  content: "\ef87";
}

.ph-dice-one-light::before {
  content: "\ef88";
}

.ph-dice-six-light::before {
  content: "\ef89";
}

.ph-dice-three-light::before {
  content: "\ef8a";
}

.ph-dice-two-light::before {
  content: "\ef8b";
}

.ph-disc-light::before {
  content: "\ef8c";
}

.ph-discord-logo-light::before {
  content: "\ef8d";
}

.ph-divide-light::before {
  content: "\ef8e";
}

.ph-dog-light::before {
  content: "\ef8f";
}

.ph-door-light::before {
  content: "\ef90";
}

.ph-dots-nine-light::before {
  content: "\ef91";
}

.ph-dots-six-light::before {
  content: "\ef92";
}

.ph-dots-six-vertical-light::before {
  content: "\ef93";
}

.ph-dots-three-circle-light::before {
  content: "\ef94";
}

.ph-dots-three-circle-vertical-light::before {
  content: "\ef95";
}

.ph-dots-three-light::before {
  content: "\ef96";
}

.ph-dots-three-outline-light::before {
  content: "\ef97";
}

.ph-dots-three-outline-vertical-light::before {
  content: "\ef98";
}

.ph-dots-three-vertical-light::before {
  content: "\ef99";
}

.ph-download-light::before {
  content: "\ef9a";
}

.ph-download-simple-light::before {
  content: "\ef9b";
}

.ph-dribbble-logo-light::before {
  content: "\ef9c";
}

.ph-drop-half-bottom-light::before {
  content: "\ef9d";
}

.ph-drop-half-light::before {
  content: "\ef9e";
}

.ph-drop-light::before {
  content: "\ef9f";
}

.ph-ear-light::before {
  content: "\efa0";
}

.ph-ear-slash-light::before {
  content: "\efa1";
}

.ph-egg-crack-light::before {
  content: "\efa2";
}

.ph-egg-light::before {
  content: "\efa3";
}

.ph-eject-light::before {
  content: "\efa4";
}

.ph-eject-simple-light::before {
  content: "\efa5";
}

.ph-envelope-light::before {
  content: "\efa6";
}

.ph-envelope-open-light::before {
  content: "\efa7";
}

.ph-envelope-simple-light::before {
  content: "\efa8";
}

.ph-envelope-simple-open-light::before {
  content: "\efa9";
}

.ph-equalizer-light::before {
  content: "\efaa";
}

.ph-equals-light::before {
  content: "\efab";
}

.ph-eraser-light::before {
  content: "\efac";
}

.ph-exam-light::before {
  content: "\efad";
}

.ph-export-light::before {
  content: "\efae";
}

.ph-eye-closed-light::before {
  content: "\efaf";
}

.ph-eye-light::before {
  content: "\efb0";
}

.ph-eye-slash-light::before {
  content: "\efb1";
}

.ph-eyedropper-light::before {
  content: "\efb2";
}

.ph-eyedropper-sample-light::before {
  content: "\efb3";
}

.ph-eyeglasses-light::before {
  content: "\efb4";
}

.ph-face-mask-light::before {
  content: "\efb5";
}

.ph-facebook-logo-light::before {
  content: "\efb6";
}

.ph-factory-light::before {
  content: "\efb7";
}

.ph-faders-horizontal-light::before {
  content: "\efb8";
}

.ph-faders-light::before {
  content: "\efb9";
}

.ph-fast-forward-circle-light::before {
  content: "\efba";
}

.ph-fast-forward-light::before {
  content: "\efbb";
}

.ph-figma-logo-light::before {
  content: "\efbc";
}

.ph-file-arrow-down-light::before {
  content: "\efbd";
}

.ph-file-arrow-up-light::before {
  content: "\efbe";
}

.ph-file-audio-light::before {
  content: "\efbf";
}

.ph-file-cloud-light::before {
  content: "\efc0";
}

.ph-file-code-light::before {
  content: "\efc1";
}

.ph-file-css-light::before {
  content: "\efc2";
}

.ph-file-csv-light::before {
  content: "\efc3";
}

.ph-file-doc-light::before {
  content: "\efc4";
}

.ph-file-dotted-light::before {
  content: "\efc5";
}

.ph-file-html-light::before {
  content: "\efc6";
}

.ph-file-image-light::before {
  content: "\efc7";
}

.ph-file-jpg-light::before {
  content: "\efc8";
}

.ph-file-js-light::before {
  content: "\efc9";
}

.ph-file-jsx-light::before {
  content: "\efca";
}

.ph-file-light::before {
  content: "\efcb";
}

.ph-file-lock-light::before {
  content: "\efcc";
}

.ph-file-minus-light::before {
  content: "\efcd";
}

.ph-file-pdf-light::before {
  content: "\efce";
}

.ph-file-plus-light::before {
  content: "\efcf";
}

.ph-file-png-light::before {
  content: "\efd0";
}

.ph-file-ppt-light::before {
  content: "\efd1";
}

.ph-file-rs-light::before {
  content: "\efd2";
}

.ph-file-search-light::before {
  content: "\efd3";
}

.ph-file-text-light::before {
  content: "\efd4";
}

.ph-file-ts-light::before {
  content: "\efd5";
}

.ph-file-tsx-light::before {
  content: "\efd6";
}

.ph-file-video-light::before {
  content: "\efd7";
}

.ph-file-vue-light::before {
  content: "\efd8";
}

.ph-file-x-light::before {
  content: "\efd9";
}

.ph-file-xls-light::before {
  content: "\efda";
}

.ph-file-zip-light::before {
  content: "\efdb";
}

.ph-files-light::before {
  content: "\efdc";
}

.ph-film-script-light::before {
  content: "\efdd";
}

.ph-film-slate-light::before {
  content: "\efde";
}

.ph-film-strip-light::before {
  content: "\efdf";
}

.ph-fingerprint-light::before {
  content: "\efe0";
}

.ph-fingerprint-simple-light::before {
  content: "\efe1";
}

.ph-finn-the-human-light::before {
  content: "\efe2";
}

.ph-fire-light::before {
  content: "\efe3";
}

.ph-fire-simple-light::before {
  content: "\efe4";
}

.ph-first-aid-kit-light::before {
  content: "\efe5";
}

.ph-first-aid-light::before {
  content: "\efe6";
}

.ph-fish-light::before {
  content: "\efe7";
}

.ph-fish-simple-light::before {
  content: "\efe8";
}

.ph-flag-banner-light::before {
  content: "\efe9";
}

.ph-flag-checkered-light::before {
  content: "\efea";
}

.ph-flag-light::before {
  content: "\efeb";
}

.ph-flame-light::before {
  content: "\efec";
}

.ph-flashlight-light::before {
  content: "\efed";
}

.ph-flask-light::before {
  content: "\efee";
}

.ph-floppy-disk-back-light::before {
  content: "\efef";
}

.ph-floppy-disk-light::before {
  content: "\eff0";
}

.ph-flow-arrow-light::before {
  content: "\eff1";
}

.ph-flower-light::before {
  content: "\eff2";
}

.ph-flower-lotus-light::before {
  content: "\eff3";
}

.ph-flying-saucer-light::before {
  content: "\eff4";
}

.ph-folder-dotted-light::before {
  content: "\eff5";
}

.ph-folder-light::before {
  content: "\eff6";
}

.ph-folder-lock-light::before {
  content: "\eff7";
}

.ph-folder-minus-light::before {
  content: "\eff8";
}

.ph-folder-notch-light::before {
  content: "\eff9";
}

.ph-folder-notch-minus-light::before {
  content: "\effa";
}

.ph-folder-notch-open-light::before {
  content: "\effb";
}

.ph-folder-notch-plus-light::before {
  content: "\effc";
}

.ph-folder-open-light::before {
  content: "\effd";
}

.ph-folder-plus-light::before {
  content: "\effe";
}

.ph-folder-simple-dotted-light::before {
  content: "\efff";
}

.ph-folder-simple-light::before {
  content: "\f000";
}

.ph-folder-simple-lock-light::before {
  content: "\f001";
}

.ph-folder-simple-minus-light::before {
  content: "\f002";
}

.ph-folder-simple-plus-light::before {
  content: "\f003";
}

.ph-folder-simple-star-light::before {
  content: "\f004";
}

.ph-folder-simple-user-light::before {
  content: "\f005";
}

.ph-folder-star-light::before {
  content: "\f006";
}

.ph-folder-user-light::before {
  content: "\f007";
}

.ph-folders-light::before {
  content: "\f008";
}

.ph-football-light::before {
  content: "\f009";
}

.ph-fork-knife-light::before {
  content: "\f00a";
}

.ph-frame-corners-light::before {
  content: "\f00b";
}

.ph-framer-logo-light::before {
  content: "\f00c";
}

.ph-function-light::before {
  content: "\f00d";
}

.ph-funnel-light::before {
  content: "\f00e";
}

.ph-funnel-simple-light::before {
  content: "\f00f";
}

.ph-game-controller-light::before {
  content: "\f010";
}

.ph-gas-pump-light::before {
  content: "\f011";
}

.ph-gauge-light::before {
  content: "\f012";
}

.ph-gear-light::before {
  content: "\f013";
}

.ph-gear-six-light::before {
  content: "\f014";
}

.ph-gender-female-light::before {
  content: "\f015";
}

.ph-gender-intersex-light::before {
  content: "\f016";
}

.ph-gender-male-light::before {
  content: "\f017";
}

.ph-gender-neuter-light::before {
  content: "\f018";
}

.ph-gender-nonbinary-light::before {
  content: "\f019";
}

.ph-gender-transgender-light::before {
  content: "\f01a";
}

.ph-ghost-light::before {
  content: "\f01b";
}

.ph-gif-light::before {
  content: "\f01c";
}

.ph-gift-light::before {
  content: "\f01d";
}

.ph-git-branch-light::before {
  content: "\f01e";
}

.ph-git-commit-light::before {
  content: "\f01f";
}

.ph-git-diff-light::before {
  content: "\f020";
}

.ph-git-fork-light::before {
  content: "\f021";
}

.ph-git-merge-light::before {
  content: "\f022";
}

.ph-git-pull-request-light::before {
  content: "\f023";
}

.ph-github-logo-light::before {
  content: "\f024";
}

.ph-gitlab-logo-light::before {
  content: "\f025";
}

.ph-gitlab-logo-simple-light::before {
  content: "\f026";
}

.ph-globe-hemisphere-east-light::before {
  content: "\f027";
}

.ph-globe-hemisphere-west-light::before {
  content: "\f028";
}

.ph-globe-light::before {
  content: "\f029";
}

.ph-globe-simple-light::before {
  content: "\f02a";
}

.ph-globe-stand-light::before {
  content: "\f02b";
}

.ph-google-chrome-logo-light::before {
  content: "\f02c";
}

.ph-google-logo-light::before {
  content: "\f02d";
}

.ph-google-photos-logo-light::before {
  content: "\f02e";
}

.ph-google-play-logo-light::before {
  content: "\f02f";
}

.ph-google-podcasts-logo-light::before {
  content: "\f030";
}

.ph-gradient-light::before {
  content: "\f031";
}

.ph-graduation-cap-light::before {
  content: "\f032";
}

.ph-graph-light::before {
  content: "\f033";
}

.ph-grid-four-light::before {
  content: "\f034";
}

.ph-hamburger-light::before {
  content: "\f035";
}

.ph-hand-eye-light::before {
  content: "\f036";
}

.ph-hand-fist-light::before {
  content: "\f037";
}

.ph-hand-grabbing-light::before {
  content: "\f038";
}

.ph-hand-light::before {
  content: "\f039";
}

.ph-hand-palm-light::before {
  content: "\f03a";
}

.ph-hand-pointing-light::before {
  content: "\f03b";
}

.ph-hand-soap-light::before {
  content: "\f03c";
}

.ph-hand-waving-light::before {
  content: "\f03d";
}

.ph-handbag-light::before {
  content: "\f03e";
}

.ph-handbag-simple-light::before {
  content: "\f03f";
}

.ph-hands-clapping-light::before {
  content: "\f040";
}

.ph-handshake-light::before {
  content: "\f041";
}

.ph-hard-drive-light::before {
  content: "\f042";
}

.ph-hard-drives-light::before {
  content: "\f043";
}

.ph-hash-light::before {
  content: "\f044";
}

.ph-hash-straight-light::before {
  content: "\f045";
}

.ph-headlights-light::before {
  content: "\f046";
}

.ph-headphones-light::before {
  content: "\f047";
}

.ph-headset-light::before {
  content: "\f048";
}

.ph-heart-break-light::before {
  content: "\f049";
}

.ph-heart-light::before {
  content: "\f04a";
}

.ph-heart-straight-break-light::before {
  content: "\f04b";
}

.ph-heart-straight-light::before {
  content: "\f04c";
}

.ph-heartbeat-light::before {
  content: "\f04d";
}

.ph-hexagon-light::before {
  content: "\f04e";
}

.ph-highlighter-circle-light::before {
  content: "\f04f";
}

.ph-horse-light::before {
  content: "\f050";
}

.ph-hourglass-high-light::before {
  content: "\f051";
}

.ph-hourglass-light::before {
  content: "\f052";
}

.ph-hourglass-low-light::before {
  content: "\f053";
}

.ph-hourglass-medium-light::before {
  content: "\f054";
}

.ph-hourglass-simple-high-light::before {
  content: "\f055";
}

.ph-hourglass-simple-light::before {
  content: "\f056";
}

.ph-hourglass-simple-low-light::before {
  content: "\f057";
}

.ph-hourglass-simple-medium-light::before {
  content: "\f058";
}

.ph-house-light::before {
  content: "\f059";
}

.ph-house-line-light::before {
  content: "\f05a";
}

.ph-house-simple-light::before {
  content: "\f05b";
}

.ph-identification-badge-light::before {
  content: "\f05c";
}

.ph-identification-card-light::before {
  content: "\f05d";
}

.ph-image-light::before {
  content: "\f05e";
}

.ph-image-square-light::before {
  content: "\f05f";
}

.ph-infinity-light::before {
  content: "\f060";
}

.ph-info-light::before {
  content: "\f061";
}

.ph-instagram-logo-light::before {
  content: "\f062";
}

.ph-intersect-light::before {
  content: "\f063";
}

.ph-jeep-light::before {
  content: "\f064";
}

.ph-kanban-light::before {
  content: "\f065";
}

.ph-key-light::before {
  content: "\f066";
}

.ph-key-return-light::before {
  content: "\f067";
}

.ph-keyboard-light::before {
  content: "\f068";
}

.ph-keyhole-light::before {
  content: "\f069";
}

.ph-knife-light::before {
  content: "\f06a";
}

.ph-ladder-light::before {
  content: "\f06b";
}

.ph-ladder-simple-light::before {
  content: "\f06c";
}

.ph-lamp-light::before {
  content: "\f06d";
}

.ph-laptop-light::before {
  content: "\f06e";
}

.ph-layout-light::before {
  content: "\f06f";
}

.ph-leaf-light::before {
  content: "\f070";
}

.ph-lifebuoy-light::before {
  content: "\f071";
}

.ph-lightbulb-filament-light::before {
  content: "\f072";
}

.ph-lightbulb-light::before {
  content: "\f073";
}

.ph-lightning-light::before {
  content: "\f074";
}

.ph-lightning-slash-light::before {
  content: "\f075";
}

.ph-line-segment-light::before {
  content: "\f076";
}

.ph-line-segments-light::before {
  content: "\f077";
}

.ph-link-break-light::before {
  content: "\f078";
}

.ph-link-light::before {
  content: "\f079";
}

.ph-link-simple-break-light::before {
  content: "\f07a";
}

.ph-link-simple-horizontal-break-light::before {
  content: "\f07b";
}

.ph-link-simple-horizontal-light::before {
  content: "\f07c";
}

.ph-link-simple-light::before {
  content: "\f07d";
}

.ph-linkedin-logo-light::before {
  content: "\f07e";
}

.ph-linux-logo-light::before {
  content: "\f07f";
}

.ph-list-bullets-light::before {
  content: "\f080";
}

.ph-list-checks-light::before {
  content: "\f081";
}

.ph-list-dashes-light::before {
  content: "\f082";
}

.ph-list-light::before {
  content: "\f083";
}

.ph-list-numbers-light::before {
  content: "\f084";
}

.ph-list-plus-light::before {
  content: "\f085";
}

.ph-lock-key-light::before {
  content: "\f086";
}

.ph-lock-key-open-light::before {
  content: "\f087";
}

.ph-lock-laminated-light::before {
  content: "\f088";
}

.ph-lock-laminated-open-light::before {
  content: "\f089";
}

.ph-lock-light::before {
  content: "\f08a";
}

.ph-lock-open-light::before {
  content: "\f08b";
}

.ph-lock-simple-light::before {
  content: "\f08c";
}

.ph-lock-simple-open-light::before {
  content: "\f08d";
}

.ph-magic-wand-light::before {
  content: "\f08e";
}

.ph-magnet-light::before {
  content: "\f08f";
}

.ph-magnet-straight-light::before {
  content: "\f090";
}

.ph-magnifying-glass-light::before {
  content: "\f091";
}

.ph-magnifying-glass-minus-light::before {
  content: "\f092";
}

.ph-magnifying-glass-plus-light::before {
  content: "\f093";
}

.ph-map-pin-light::before {
  content: "\f094";
}

.ph-map-pin-line-light::before {
  content: "\f095";
}

.ph-map-trifold-light::before {
  content: "\f096";
}

.ph-marker-circle-light::before {
  content: "\f097";
}

.ph-martini-light::before {
  content: "\f098";
}

.ph-mask-happy-light::before {
  content: "\f099";
}

.ph-mask-sad-light::before {
  content: "\f09a";
}

.ph-math-operations-light::before {
  content: "\f09b";
}

.ph-medal-light::before {
  content: "\f09c";
}

.ph-medium-logo-light::before {
  content: "\f09d";
}

.ph-megaphone-light::before {
  content: "\f09e";
}

.ph-megaphone-simple-light::before {
  content: "\f09f";
}

.ph-messenger-logo-light::before {
  content: "\f0a0";
}

.ph-microphone-light::before {
  content: "\f0a1";
}

.ph-microphone-slash-light::before {
  content: "\f0a2";
}

.ph-microphone-stage-light::before {
  content: "\f0a3";
}

.ph-microsoft-excel-logo-light::before {
  content: "\f0a4";
}

.ph-microsoft-powerpoint-logo-light::before {
  content: "\f0a5";
}

.ph-microsoft-teams-logo-light::before {
  content: "\f0a6";
}

.ph-microsoft-word-logo-light::before {
  content: "\f0a7";
}

.ph-minus-circle-light::before {
  content: "\f0a8";
}

.ph-minus-light::before {
  content: "\f0a9";
}

.ph-money-light::before {
  content: "\f0aa";
}

.ph-monitor-light::before {
  content: "\f0ab";
}

.ph-monitor-play-light::before {
  content: "\f0ac";
}

.ph-moon-light::before {
  content: "\f0ad";
}

.ph-moon-stars-light::before {
  content: "\f0ae";
}

.ph-mountains-light::before {
  content: "\f0af";
}

.ph-mouse-light::before {
  content: "\f0b0";
}

.ph-mouse-simple-light::before {
  content: "\f0b1";
}

.ph-music-note-light::before {
  content: "\f0b2";
}

.ph-music-note-simple-light::before {
  content: "\f0b3";
}

.ph-music-notes-light::before {
  content: "\f0b4";
}

.ph-music-notes-plus-light::before {
  content: "\f0b5";
}

.ph-music-notes-simple-light::before {
  content: "\f0b6";
}

.ph-navigation-arrow-light::before {
  content: "\f0b7";
}

.ph-needle-light::before {
  content: "\f0b8";
}

.ph-newspaper-clipping-light::before {
  content: "\f0b9";
}

.ph-newspaper-light::before {
  content: "\f0ba";
}

.ph-note-blank-light::before {
  content: "\f0bb";
}

.ph-note-light::before {
  content: "\f0bc";
}

.ph-note-pencil-light::before {
  content: "\f0bd";
}

.ph-notebook-light::before {
  content: "\f0be";
}

.ph-notepad-light::before {
  content: "\f0bf";
}

.ph-notification-light::before {
  content: "\f0c0";
}

.ph-number-circle-eight-light::before {
  content: "\f0c1";
}

.ph-number-circle-five-light::before {
  content: "\f0c2";
}

.ph-number-circle-four-light::before {
  content: "\f0c3";
}

.ph-number-circle-nine-light::before {
  content: "\f0c4";
}

.ph-number-circle-one-light::before {
  content: "\f0c5";
}

.ph-number-circle-seven-light::before {
  content: "\f0c6";
}

.ph-number-circle-six-light::before {
  content: "\f0c7";
}

.ph-number-circle-three-light::before {
  content: "\f0c8";
}

.ph-number-circle-two-light::before {
  content: "\f0c9";
}

.ph-number-circle-zero-light::before {
  content: "\f0ca";
}

.ph-number-eight-light::before {
  content: "\f0cb";
}

.ph-number-five-light::before {
  content: "\f0cc";
}

.ph-number-four-light::before {
  content: "\f0cd";
}

.ph-number-nine-light::before {
  content: "\f0ce";
}

.ph-number-one-light::before {
  content: "\f0cf";
}

.ph-number-seven-light::before {
  content: "\f0d0";
}

.ph-number-six-light::before {
  content: "\f0d1";
}

.ph-number-square-eight-light::before {
  content: "\f0d2";
}

.ph-number-square-five-light::before {
  content: "\f0d3";
}

.ph-number-square-four-light::before {
  content: "\f0d4";
}

.ph-number-square-nine-light::before {
  content: "\f0d5";
}

.ph-number-square-one-light::before {
  content: "\f0d6";
}

.ph-number-square-seven-light::before {
  content: "\f0d7";
}

.ph-number-square-six-light::before {
  content: "\f0d8";
}

.ph-number-square-three-light::before {
  content: "\f0d9";
}

.ph-number-square-two-light::before {
  content: "\f0da";
}

.ph-number-square-zero-light::before {
  content: "\f0db";
}

.ph-number-three-light::before {
  content: "\f0dc";
}

.ph-number-two-light::before {
  content: "\f0dd";
}

.ph-number-zero-light::before {
  content: "\f0de";
}

.ph-nut-light::before {
  content: "\f0df";
}

.ph-ny-times-logo-light::before {
  content: "\f0e0";
}

.ph-octagon-light::before {
  content: "\f0e1";
}

.ph-option-light::before {
  content: "\f0e2";
}

.ph-package-light::before {
  content: "\f0e3";
}

.ph-paint-brush-broad-light::before {
  content: "\f0e4";
}

.ph-paint-brush-household-light::before {
  content: "\f0e5";
}

.ph-paint-brush-light::before {
  content: "\f0e6";
}

.ph-paint-bucket-light::before {
  content: "\f0e7";
}

.ph-paint-roller-light::before {
  content: "\f0e8";
}

.ph-palette-light::before {
  content: "\f0e9";
}

.ph-paper-plane-light::before {
  content: "\f0ea";
}

.ph-paper-plane-right-light::before {
  content: "\f0eb";
}

.ph-paper-plane-tilt-light::before {
  content: "\f0ec";
}

.ph-paperclip-horizontal-light::before {
  content: "\f0ed";
}

.ph-paperclip-light::before {
  content: "\f0ee";
}

.ph-parachute-light::before {
  content: "\f0ef";
}

.ph-password-light::before {
  content: "\f0f0";
}

.ph-path-light::before {
  content: "\f0f1";
}

.ph-pause-circle-light::before {
  content: "\f0f2";
}

.ph-pause-light::before {
  content: "\f0f3";
}

.ph-paw-print-light::before {
  content: "\f0f4";
}

.ph-peace-light::before {
  content: "\f0f5";
}

.ph-pen-light::before {
  content: "\f0f6";
}

.ph-pen-nib-light::before {
  content: "\f0f7";
}

.ph-pen-nib-straight-light::before {
  content: "\f0f8";
}

.ph-pencil-circle-light::before {
  content: "\f0f9";
}

.ph-pencil-light::before {
  content: "\f0fa";
}

.ph-pencil-line-light::before {
  content: "\f0fb";
}

.ph-pencil-simple-light::before {
  content: "\f0fc";
}

.ph-pencil-simple-line-light::before {
  content: "\f0fd";
}

.ph-percent-light::before {
  content: "\f0fe";
}

.ph-person-light::before {
  content: "\f0ff";
}

.ph-person-simple-light::before {
  content: "\f100";
}

.ph-person-simple-run-light::before {
  content: "\f101";
}

.ph-person-simple-walk-light::before {
  content: "\f102";
}

.ph-perspective-light::before {
  content: "\f103";
}

.ph-phone-call-light::before {
  content: "\f104";
}

.ph-phone-disconnect-light::before {
  content: "\f105";
}

.ph-phone-incoming-light::before {
  content: "\f106";
}

.ph-phone-light::before {
  content: "\f107";
}

.ph-phone-outgoing-light::before {
  content: "\f108";
}

.ph-phone-slash-light::before {
  content: "\f109";
}

.ph-phone-x-light::before {
  content: "\f10a";
}

.ph-phosphor-logo-light::before {
  content: "\f10b";
}

.ph-piano-keys-light::before {
  content: "\f10c";
}

.ph-picture-in-picture-light::before {
  content: "\f10d";
}

.ph-pill-light::before {
  content: "\f10e";
}

.ph-pinterest-logo-light::before {
  content: "\f10f";
}

.ph-pinwheel-light::before {
  content: "\f110";
}

.ph-pizza-light::before {
  content: "\f111";
}

.ph-placeholder-light::before {
  content: "\f112";
}

.ph-planet-light::before {
  content: "\f113";
}

.ph-play-circle-light::before {
  content: "\f114";
}

.ph-play-light::before {
  content: "\f115";
}

.ph-playlist-light::before {
  content: "\f116";
}

.ph-plug-light::before {
  content: "\f117";
}

.ph-plugs-connected-light::before {
  content: "\f118";
}

.ph-plugs-light::before {
  content: "\f119";
}

.ph-plus-circle-light::before {
  content: "\f11a";
}

.ph-plus-light::before {
  content: "\f11b";
}

.ph-plus-minus-light::before {
  content: "\f11c";
}

.ph-poker-chip-light::before {
  content: "\f11d";
}

.ph-police-car-light::before {
  content: "\f11e";
}

.ph-polygon-light::before {
  content: "\f11f";
}

.ph-popcorn-light::before {
  content: "\f120";
}

.ph-power-light::before {
  content: "\f121";
}

.ph-prescription-light::before {
  content: "\f122";
}

.ph-presentation-chart-light::before {
  content: "\f123";
}

.ph-presentation-light::before {
  content: "\f124";
}

.ph-printer-light::before {
  content: "\f125";
}

.ph-prohibit-inset-light::before {
  content: "\f126";
}

.ph-prohibit-light::before {
  content: "\f127";
}

.ph-projector-screen-chart-light::before {
  content: "\f128";
}

.ph-projector-screen-light::before {
  content: "\f129";
}

.ph-push-pin-light::before {
  content: "\f12a";
}

.ph-push-pin-simple-light::before {
  content: "\f12b";
}

.ph-push-pin-simple-slash-light::before {
  content: "\f12c";
}

.ph-push-pin-slash-light::before {
  content: "\f12d";
}

.ph-puzzle-piece-light::before {
  content: "\f12e";
}

.ph-qr-code-light::before {
  content: "\f12f";
}

.ph-question-light::before {
  content: "\f130";
}

.ph-queue-light::before {
  content: "\f131";
}

.ph-quotes-light::before {
  content: "\f132";
}

.ph-radical-light::before {
  content: "\f133";
}

.ph-radio-button-light::before {
  content: "\f134";
}

.ph-radio-light::before {
  content: "\f135";
}

.ph-rainbow-cloud-light::before {
  content: "\f136";
}

.ph-rainbow-light::before {
  content: "\f137";
}

.ph-receipt-light::before {
  content: "\f138";
}

.ph-record-light::before {
  content: "\f139";
}

.ph-rectangle-light::before {
  content: "\f13a";
}

.ph-recycle-light::before {
  content: "\f13b";
}

.ph-reddit-logo-light::before {
  content: "\f13c";
}

.ph-repeat-light::before {
  content: "\f13d";
}

.ph-repeat-once-light::before {
  content: "\f13e";
}

.ph-rewind-circle-light::before {
  content: "\f13f";
}

.ph-rewind-light::before {
  content: "\f140";
}

.ph-robot-light::before {
  content: "\f141";
}

.ph-rocket-launch-light::before {
  content: "\f142";
}

.ph-rocket-light::before {
  content: "\f143";
}

.ph-rows-light::before {
  content: "\f144";
}

.ph-rss-light::before {
  content: "\f145";
}

.ph-rss-simple-light::before {
  content: "\f146";
}

.ph-rug-light::before {
  content: "\f147";
}

.ph-ruler-light::before {
  content: "\f148";
}

.ph-scales-light::before {
  content: "\f149";
}

.ph-scan-light::before {
  content: "\f14a";
}

.ph-scissors-light::before {
  content: "\f14b";
}

.ph-screencast-light::before {
  content: "\f14c";
}

.ph-scribble-loop-light::before {
  content: "\f14d";
}

.ph-scroll-light::before {
  content: "\f14e";
}

.ph-selection-all-light::before {
  content: "\f14f";
}

.ph-selection-background-light::before {
  content: "\f150";
}

.ph-selection-foreground-light::before {
  content: "\f151";
}

.ph-selection-inverse-light::before {
  content: "\f152";
}

.ph-selection-light::before {
  content: "\f153";
}

.ph-selection-plus-light::before {
  content: "\f154";
}

.ph-selection-slash-light::before {
  content: "\f155";
}

.ph-share-light::before {
  content: "\f156";
}

.ph-share-network-light::before {
  content: "\f157";
}

.ph-shield-check-light::before {
  content: "\f158";
}

.ph-shield-checkered-light::before {
  content: "\f159";
}

.ph-shield-chevron-light::before {
  content: "\f15a";
}

.ph-shield-light::before {
  content: "\f15b";
}

.ph-shield-plus-light::before {
  content: "\f15c";
}

.ph-shield-slash-light::before {
  content: "\f15d";
}

.ph-shield-star-light::before {
  content: "\f15e";
}

.ph-shield-warning-light::before {
  content: "\f15f";
}

.ph-shopping-bag-light::before {
  content: "\f160";
}

.ph-shopping-bag-open-light::before {
  content: "\f161";
}

.ph-shopping-cart-light::before {
  content: "\f162";
}

.ph-shopping-cart-simple-light::before {
  content: "\f163";
}

.ph-shower-light::before {
  content: "\f164";
}

.ph-shuffle-angular-light::before {
  content: "\f165";
}

.ph-shuffle-light::before {
  content: "\f166";
}

.ph-shuffle-simple-light::before {
  content: "\f167";
}

.ph-sidebar-light::before {
  content: "\f168";
}

.ph-sidebar-simple-light::before {
  content: "\f169";
}

.ph-sign-in-light::before {
  content: "\f16a";
}

.ph-sign-out-light::before {
  content: "\f16b";
}

.ph-signpost-light::before {
  content: "\f16c";
}

.ph-sim-card-light::before {
  content: "\f16d";
}

.ph-sketch-logo-light::before {
  content: "\f16e";
}

.ph-skip-back-circle-light::before {
  content: "\f16f";
}

.ph-skip-back-light::before {
  content: "\f170";
}

.ph-skip-forward-circle-light::before {
  content: "\f171";
}

.ph-skip-forward-light::before {
  content: "\f172";
}

.ph-skull-light::before {
  content: "\f173";
}

.ph-slack-logo-light::before {
  content: "\f174";
}

.ph-sliders-horizontal-light::before {
  content: "\f175";
}

.ph-sliders-light::before {
  content: "\f176";
}

.ph-smiley-blank-light::before {
  content: "\f177";
}

.ph-smiley-light::before {
  content: "\f178";
}

.ph-smiley-meh-light::before {
  content: "\f179";
}

.ph-smiley-nervous-light::before {
  content: "\f17a";
}

.ph-smiley-sad-light::before {
  content: "\f17b";
}

.ph-smiley-sticker-light::before {
  content: "\f17c";
}

.ph-smiley-wink-light::before {
  content: "\f17d";
}

.ph-smiley-x-eyes-light::before {
  content: "\f17e";
}

.ph-snapchat-logo-light::before {
  content: "\f17f";
}

.ph-snowflake-light::before {
  content: "\f180";
}

.ph-soccer-ball-light::before {
  content: "\f181";
}

.ph-sort-ascending-light::before {
  content: "\f182";
}

.ph-sort-descending-light::before {
  content: "\f183";
}

.ph-spade-light::before {
  content: "\f184";
}

.ph-sparkle-light::before {
  content: "\f185";
}

.ph-speaker-high-light::before {
  content: "\f186";
}

.ph-speaker-low-light::before {
  content: "\f187";
}

.ph-speaker-none-light::before {
  content: "\f188";
}

.ph-speaker-simple-high-light::before {
  content: "\f189";
}

.ph-speaker-simple-low-light::before {
  content: "\f18a";
}

.ph-speaker-simple-none-light::before {
  content: "\f18b";
}

.ph-speaker-simple-slash-light::before {
  content: "\f18c";
}

.ph-speaker-simple-x-light::before {
  content: "\f18d";
}

.ph-speaker-slash-light::before {
  content: "\f18e";
}

.ph-speaker-x-light::before {
  content: "\f18f";
}

.ph-spinner-gap-light::before {
  content: "\f190";
}

.ph-spinner-light::before {
  content: "\f191";
}

.ph-spiral-light::before {
  content: "\f192";
}

.ph-spotify-logo-light::before {
  content: "\f193";
}

.ph-square-half-bottom-light::before {
  content: "\f194";
}

.ph-square-half-light::before {
  content: "\f195";
}

.ph-square-light::before {
  content: "\f196";
}

.ph-square-logo-light::before {
  content: "\f197";
}

.ph-squares-four-light::before {
  content: "\f198";
}

.ph-stack-light::before {
  content: "\f199";
}

.ph-stack-overflow-logo-light::before {
  content: "\f19a";
}

.ph-stack-simple-light::before {
  content: "\f19b";
}

.ph-stamp-light::before {
  content: "\f19c";
}

.ph-star-four-light::before {
  content: "\f19d";
}

.ph-star-half-light::before {
  content: "\f19e";
}

.ph-star-light::before {
  content: "\f19f";
}

.ph-sticker-light::before {
  content: "\f1a0";
}

.ph-stop-circle-light::before {
  content: "\f1a1";
}

.ph-stop-light::before {
  content: "\f1a2";
}

.ph-storefront-light::before {
  content: "\f1a3";
}

.ph-strategy-light::before {
  content: "\f1a4";
}

.ph-stripe-logo-light::before {
  content: "\f1a5";
}

.ph-student-light::before {
  content: "\f1a6";
}

.ph-suitcase-light::before {
  content: "\f1a7";
}

.ph-suitcase-simple-light::before {
  content: "\f1a8";
}

.ph-sun-dim-light::before {
  content: "\f1a9";
}

.ph-sun-horizon-light::before {
  content: "\f1aa";
}

.ph-sun-light::before {
  content: "\f1ab";
}

.ph-sunglasses-light::before {
  content: "\f1ac";
}

.ph-swap-light::before {
  content: "\f1ad";
}

.ph-swatches-light::before {
  content: "\f1ae";
}

.ph-sword-light::before {
  content: "\f1af";
}

.ph-syringe-light::before {
  content: "\f1b0";
}

.ph-t-shirt-light::before {
  content: "\f1b1";
}

.ph-table-light::before {
  content: "\f1b2";
}

.ph-tabs-light::before {
  content: "\f1b3";
}

.ph-tag-chevron-light::before {
  content: "\f1b4";
}

.ph-tag-light::before {
  content: "\f1b5";
}

.ph-tag-simple-light::before {
  content: "\f1b6";
}

.ph-target-light::before {
  content: "\f1b7";
}

.ph-taxi-light::before {
  content: "\f1b8";
}

.ph-telegram-logo-light::before {
  content: "\f1b9";
}

.ph-television-light::before {
  content: "\f1ba";
}

.ph-television-simple-light::before {
  content: "\f1bb";
}

.ph-tennis-ball-light::before {
  content: "\f1bc";
}

.ph-terminal-light::before {
  content: "\f1bd";
}

.ph-terminal-window-light::before {
  content: "\f1be";
}

.ph-test-tube-light::before {
  content: "\f1bf";
}

.ph-text-aa-light::before {
  content: "\f1c0";
}

.ph-text-align-center-light::before {
  content: "\f1c1";
}

.ph-text-align-justify-light::before {
  content: "\f1c2";
}

.ph-text-align-left-light::before {
  content: "\f1c3";
}

.ph-text-align-right-light::before {
  content: "\f1c4";
}

.ph-text-bolder-light::before {
  content: "\f1c5";
}

.ph-text-h-five-light::before {
  content: "\f1c6";
}

.ph-text-h-four-light::before {
  content: "\f1c7";
}

.ph-text-h-light::before {
  content: "\f1c8";
}

.ph-text-h-one-light::before {
  content: "\f1c9";
}

.ph-text-h-six-light::before {
  content: "\f1ca";
}

.ph-text-h-three-light::before {
  content: "\f1cb";
}

.ph-text-h-two-light::before {
  content: "\f1cc";
}

.ph-text-indent-light::before {
  content: "\f1cd";
}

.ph-text-italic-light::before {
  content: "\f1ce";
}

.ph-text-outdent-light::before {
  content: "\f1cf";
}

.ph-text-strikethrough-light::before {
  content: "\f1d0";
}

.ph-text-t-light::before {
  content: "\f1d1";
}

.ph-text-underline-light::before {
  content: "\f1d2";
}

.ph-textbox-light::before {
  content: "\f1d3";
}

.ph-thermometer-cold-light::before {
  content: "\f1d4";
}

.ph-thermometer-hot-light::before {
  content: "\f1d5";
}

.ph-thermometer-light::before {
  content: "\f1d6";
}

.ph-thermometer-simple-light::before {
  content: "\f1d7";
}

.ph-thumbs-down-light::before {
  content: "\f1d8";
}

.ph-thumbs-up-light::before {
  content: "\f1d9";
}

.ph-ticket-light::before {
  content: "\f1da";
}

.ph-tiktok-logo-light::before {
  content: "\f1db";
}

.ph-timer-light::before {
  content: "\f1dc";
}

.ph-toggle-left-light::before {
  content: "\f1dd";
}

.ph-toggle-right-light::before {
  content: "\f1de";
}

.ph-toilet-light::before {
  content: "\f1df";
}

.ph-toilet-paper-light::before {
  content: "\f1e0";
}

.ph-tote-light::before {
  content: "\f1e1";
}

.ph-tote-simple-light::before {
  content: "\f1e2";
}

.ph-trademark-registered-light::before {
  content: "\f1e3";
}

.ph-traffic-cone-light::before {
  content: "\f1e4";
}

.ph-traffic-sign-light::before {
  content: "\f1e5";
}

.ph-traffic-signal-light::before {
  content: "\f1e6";
}

.ph-train-light::before {
  content: "\f1e7";
}

.ph-train-regional-light::before {
  content: "\f1e8";
}

.ph-train-simple-light::before {
  content: "\f1e9";
}

.ph-translate-light::before {
  content: "\f1ea";
}

.ph-trash-light::before {
  content: "\f1eb";
}

.ph-trash-simple-light::before {
  content: "\f1ec";
}

.ph-tray-light::before {
  content: "\f1ed";
}

.ph-tree-evergreen-light::before {
  content: "\f1ee";
}

.ph-tree-light::before {
  content: "\f1ef";
}

.ph-tree-structure-light::before {
  content: "\f1f0";
}

.ph-trend-down-light::before {
  content: "\f1f1";
}

.ph-trend-up-light::before {
  content: "\f1f2";
}

.ph-triangle-light::before {
  content: "\f1f3";
}

.ph-trophy-light::before {
  content: "\f1f4";
}

.ph-truck-light::before {
  content: "\f1f5";
}

.ph-twitch-logo-light::before {
  content: "\f1f6";
}

.ph-twitter-logo-light::before {
  content: "\f1f7";
}

.ph-umbrella-light::before {
  content: "\f1f8";
}

.ph-umbrella-simple-light::before {
  content: "\f1f9";
}

.ph-upload-light::before {
  content: "\f1fa";
}

.ph-upload-simple-light::before {
  content: "\f1fb";
}

.ph-user-circle-gear-light::before {
  content: "\f1fc";
}

.ph-user-circle-light::before {
  content: "\f1fd";
}

.ph-user-circle-minus-light::before {
  content: "\f1fe";
}

.ph-user-circle-plus-light::before {
  content: "\f1ff";
}

.ph-user-focus-light::before {
  content: "\f200";
}

.ph-user-gear-light::before {
  content: "\f201";
}

.ph-user-light::before {
  content: "\f202";
}

.ph-user-list-light::before {
  content: "\f203";
}

.ph-user-minus-light::before {
  content: "\f204";
}

.ph-user-plus-light::before {
  content: "\f205";
}

.ph-user-rectangle-light::before {
  content: "\f206";
}

.ph-user-square-light::before {
  content: "\f207";
}

.ph-user-switch-light::before {
  content: "\f208";
}

.ph-users-four-light::before {
  content: "\f209";
}

.ph-users-light::before {
  content: "\f20a";
}

.ph-users-three-light::before {
  content: "\f20b";
}

.ph-vault-light::before {
  content: "\f20c";
}

.ph-vibrate-light::before {
  content: "\f20d";
}

.ph-video-camera-light::before {
  content: "\f20e";
}

.ph-video-camera-slash-light::before {
  content: "\f20f";
}

.ph-vignette-light::before {
  content: "\f210";
}

.ph-voicemail-light::before {
  content: "\f211";
}

.ph-volleyball-light::before {
  content: "\f212";
}

.ph-wall-light::before {
  content: "\f213";
}

.ph-wallet-light::before {
  content: "\f214";
}

.ph-warning-circle-light::before {
  content: "\f215";
}

.ph-warning-light::before {
  content: "\f216";
}

.ph-warning-octagon-light::before {
  content: "\f217";
}

.ph-watch-light::before {
  content: "\f218";
}

.ph-wave-sawtooth-light::before {
  content: "\f219";
}

.ph-wave-sine-light::before {
  content: "\f21a";
}

.ph-wave-square-light::before {
  content: "\f21b";
}

.ph-wave-triangle-light::before {
  content: "\f21c";
}

.ph-waves-light::before {
  content: "\f21d";
}

.ph-webcam-light::before {
  content: "\f21e";
}

.ph-whatsapp-logo-light::before {
  content: "\f21f";
}

.ph-wheelchair-light::before {
  content: "\f220";
}

.ph-wifi-high-light::before {
  content: "\f221";
}

.ph-wifi-low-light::before {
  content: "\f222";
}

.ph-wifi-medium-light::before {
  content: "\f223";
}

.ph-wifi-none-light::before {
  content: "\f224";
}

.ph-wifi-slash-light::before {
  content: "\f225";
}

.ph-wifi-x-light::before {
  content: "\f226";
}

.ph-wind-light::before {
  content: "\f227";
}

.ph-windows-logo-light::before {
  content: "\f228";
}

.ph-wine-light::before {
  content: "\f229";
}

.ph-wrench-light::before {
  content: "\f22a";
}

.ph-x-circle-light::before {
  content: "\f22b";
}

.ph-x-light::before {
  content: "\f22c";
}

.ph-x-square-light::before {
  content: "\f22d";
}

.ph-yin-yang-light::before {
  content: "\f22e";
}

.ph-youtube-logo-light::before {
  content: "\f22f";
}

.ph-activity::before {
  content: "\f230";
}

.ph-address-book::before {
  content: "\f231";
}

.ph-airplane::before {
  content: "\f232";
}

.ph-airplane-in-flight::before {
  content: "\f233";
}

.ph-airplane-landing::before {
  content: "\f234";
}

.ph-airplane-takeoff::before {
  content: "\f235";
}

.ph-airplane-tilt::before {
  content: "\f236";
}

.ph-airplay::before {
  content: "\f237";
}

.ph-alarm::before {
  content: "\f238";
}

.ph-alien::before {
  content: "\f239";
}

.ph-align-bottom::before {
  content: "\f23a";
}

.ph-align-bottom-simple::before {
  content: "\f23b";
}

.ph-align-center-horizontal::before {
  content: "\f23c";
}

.ph-align-center-horizontal-simple::before {
  content: "\f23d";
}

.ph-align-center-vertical::before {
  content: "\f23e";
}

.ph-align-center-vertical-simple::before {
  content: "\f23f";
}

.ph-align-left::before {
  content: "\f240";
}

.ph-align-left-simple::before {
  content: "\f241";
}

.ph-align-right::before {
  content: "\f242";
}

.ph-align-right-simple::before {
  content: "\f243";
}

.ph-align-top::before {
  content: "\f244";
}

.ph-align-top-simple::before {
  content: "\f245";
}

.ph-anchor::before {
  content: "\f246";
}

.ph-anchor-simple::before {
  content: "\f247";
}

.ph-android-logo::before {
  content: "\f248";
}

.ph-angular-logo::before {
  content: "\f249";
}

.ph-aperture::before {
  content: "\f24a";
}

.ph-app-store-logo::before {
  content: "\f24b";
}

.ph-app-window::before {
  content: "\f24c";
}

.ph-apple-logo::before {
  content: "\f24d";
}

.ph-apple-podcasts-logo::before {
  content: "\f24e";
}

.ph-archive::before {
  content: "\f24f";
}

.ph-archive-box::before {
  content: "\f250";
}

.ph-archive-tray::before {
  content: "\f251";
}

.ph-armchair::before {
  content: "\f252";
}

.ph-arrow-arc-left::before {
  content: "\f253";
}

.ph-arrow-arc-right::before {
  content: "\f254";
}

.ph-arrow-bend-double-up-left::before {
  content: "\f255";
}

.ph-arrow-bend-double-up-right::before {
  content: "\f256";
}

.ph-arrow-bend-down-left::before {
  content: "\f257";
}

.ph-arrow-bend-down-right::before {
  content: "\f258";
}

.ph-arrow-bend-left-down::before {
  content: "\f259";
}

.ph-arrow-bend-left-up::before {
  content: "\f25a";
}

.ph-arrow-bend-right-down::before {
  content: "\f25b";
}

.ph-arrow-bend-right-up::before {
  content: "\f25c";
}

.ph-arrow-bend-up-left::before {
  content: "\f25d";
}

.ph-arrow-bend-up-right::before {
  content: "\f25e";
}

.ph-arrow-circle-down::before {
  content: "\f25f";
}

.ph-arrow-circle-down-left::before {
  content: "\f260";
}

.ph-arrow-circle-down-right::before {
  content: "\f261";
}

.ph-arrow-circle-left::before {
  content: "\f262";
}

.ph-arrow-circle-right::before {
  content: "\f263";
}

.ph-arrow-circle-up::before {
  content: "\f264";
}

.ph-arrow-circle-up-left::before {
  content: "\f265";
}

.ph-arrow-circle-up-right::before {
  content: "\f266";
}

.ph-arrow-clockwise::before {
  content: "\f267";
}

.ph-arrow-counter-clockwise::before {
  content: "\f268";
}

.ph-arrow-down::before {
  content: "\f269";
}

.ph-arrow-down-left::before {
  content: "\f26a";
}

.ph-arrow-down-right::before {
  content: "\f26b";
}

.ph-arrow-elbow-down-left::before {
  content: "\f26c";
}

.ph-arrow-elbow-down-right::before {
  content: "\f26d";
}

.ph-arrow-elbow-left::before {
  content: "\f26e";
}

.ph-arrow-elbow-left-down::before {
  content: "\f26f";
}

.ph-arrow-elbow-left-up::before {
  content: "\f270";
}

.ph-arrow-elbow-right::before {
  content: "\f271";
}

.ph-arrow-elbow-right-down::before {
  content: "\f272";
}

.ph-arrow-elbow-right-up::before {
  content: "\f273";
}

.ph-arrow-elbow-up-left::before {
  content: "\f274";
}

.ph-arrow-elbow-up-right::before {
  content: "\f275";
}

.ph-arrow-fat-down::before {
  content: "\f276";
}

.ph-arrow-fat-left::before {
  content: "\f277";
}

.ph-arrow-fat-line-down::before {
  content: "\f278";
}

.ph-arrow-fat-line-left::before {
  content: "\f279";
}

.ph-arrow-fat-line-right::before {
  content: "\f27a";
}

.ph-arrow-fat-line-up::before {
  content: "\f27b";
}

.ph-arrow-fat-lines-down::before {
  content: "\f27c";
}

.ph-arrow-fat-lines-left::before {
  content: "\f27d";
}

.ph-arrow-fat-lines-right::before {
  content: "\f27e";
}

.ph-arrow-fat-lines-up::before {
  content: "\f27f";
}

.ph-arrow-fat-right::before {
  content: "\f280";
}

.ph-arrow-fat-up::before {
  content: "\f281";
}

.ph-arrow-left::before {
  content: "\f282";
}

.ph-arrow-line-down::before {
  content: "\f283";
}

.ph-arrow-line-down-left::before {
  content: "\f284";
}

.ph-arrow-line-down-right::before {
  content: "\f285";
}

.ph-arrow-line-left::before {
  content: "\f286";
}

.ph-arrow-line-right::before {
  content: "\f287";
}

.ph-arrow-line-up::before {
  content: "\f288";
}

.ph-arrow-line-up-left::before {
  content: "\f289";
}

.ph-arrow-line-up-right::before {
  content: "\f28a";
}

.ph-arrow-right::before {
  content: "\f28b";
}

.ph-arrow-square-down::before {
  content: "\f28c";
}

.ph-arrow-square-down-left::before {
  content: "\f28d";
}

.ph-arrow-square-down-right::before {
  content: "\f28e";
}

.ph-arrow-square-in::before {
  content: "\f28f";
}

.ph-arrow-square-left::before {
  content: "\f290";
}

.ph-arrow-square-out::before {
  content: "\f291";
}

.ph-arrow-square-right::before {
  content: "\f292";
}

.ph-arrow-square-up::before {
  content: "\f293";
}

.ph-arrow-square-up-left::before {
  content: "\f294";
}

.ph-arrow-square-up-right::before {
  content: "\f295";
}

.ph-arrow-u-down-left::before {
  content: "\f296";
}

.ph-arrow-u-down-right::before {
  content: "\f297";
}

.ph-arrow-u-left-down::before {
  content: "\f298";
}

.ph-arrow-u-left-up::before {
  content: "\f299";
}

.ph-arrow-u-right-down::before {
  content: "\f29a";
}

.ph-arrow-u-right-up::before {
  content: "\f29b";
}

.ph-arrow-u-up-left::before {
  content: "\f29c";
}

.ph-arrow-u-up-right::before {
  content: "\f29d";
}

.ph-arrow-up::before {
  content: "\f29e";
}

.ph-arrow-up-left::before {
  content: "\f29f";
}

.ph-arrow-up-right::before {
  content: "\f2a0";
}

.ph-arrows-clockwise::before {
  content: "\f2a1";
}

.ph-arrows-counter-clockwise::before {
  content: "\f2a2";
}

.ph-arrows-down-up::before {
  content: "\f2a3";
}

.ph-arrows-horizontal::before {
  content: "\f2a4";
}

.ph-arrows-in::before {
  content: "\f2a5";
}

.ph-arrows-in-cardinal::before {
  content: "\f2a6";
}

.ph-arrows-in-line-horizontal::before {
  content: "\f2a7";
}

.ph-arrows-in-line-vertical::before {
  content: "\f2a8";
}

.ph-arrows-in-simple::before {
  content: "\f2a9";
}

.ph-arrows-left-right::before {
  content: "\f2aa";
}

.ph-arrows-out::before {
  content: "\f2ab";
}

.ph-arrows-out-cardinal::before {
  content: "\f2ac";
}

.ph-arrows-out-line-horizontal::before {
  content: "\f2ad";
}

.ph-arrows-out-line-vertical::before {
  content: "\f2ae";
}

.ph-arrows-out-simple::before {
  content: "\f2af";
}

.ph-arrows-vertical::before {
  content: "\f2b0";
}

.ph-article::before {
  content: "\f2b1";
}

.ph-article-medium::before {
  content: "\f2b2";
}

.ph-article-ny-times::before {
  content: "\f2b3";
}

.ph-asterisk::before {
  content: "\f2b4";
}

.ph-asterisk-simple::before {
  content: "\f2b5";
}

.ph-at::before {
  content: "\f2b6";
}

.ph-atom::before {
  content: "\f2b7";
}

.ph-baby::before {
  content: "\f2b8";
}

.ph-backpack::before {
  content: "\f2b9";
}

.ph-backspace::before {
  content: "\f2ba";
}

.ph-bag::before {
  content: "\f2bb";
}

.ph-bag-simple::before {
  content: "\f2bc";
}

.ph-balloon::before {
  content: "\f2bd";
}

.ph-bandaids::before {
  content: "\f2be";
}

.ph-bank::before {
  content: "\f2bf";
}

.ph-barbell::before {
  content: "\f2c0";
}

.ph-barcode::before {
  content: "\f2c1";
}

.ph-barricade::before {
  content: "\f2c2";
}

.ph-baseball::before {
  content: "\f2c3";
}

.ph-basketball::before {
  content: "\f2c4";
}

.ph-bathtub::before {
  content: "\f2c5";
}

.ph-battery-charging::before {
  content: "\f2c6";
}

.ph-battery-charging-vertical::before {
  content: "\f2c7";
}

.ph-battery-empty::before {
  content: "\f2c8";
}

.ph-battery-full::before {
  content: "\f2c9";
}

.ph-battery-high::before {
  content: "\f2ca";
}

.ph-battery-low::before {
  content: "\f2cb";
}

.ph-battery-medium::before {
  content: "\f2cc";
}

.ph-battery-plus::before {
  content: "\f2cd";
}

.ph-battery-warning::before {
  content: "\f2ce";
}

.ph-battery-warning-vertical::before {
  content: "\f2cf";
}

.ph-bed::before {
  content: "\f2d0";
}

.ph-beer-bottle::before {
  content: "\f2d1";
}

.ph-behance-logo::before {
  content: "\f2d2";
}

.ph-bell::before {
  content: "\f2d3";
}

.ph-bell-ringing::before {
  content: "\f2d4";
}

.ph-bell-simple::before {
  content: "\f2d5";
}

.ph-bell-simple-ringing::before {
  content: "\f2d6";
}

.ph-bell-simple-slash::before {
  content: "\f2d7";
}

.ph-bell-simple-z::before {
  content: "\f2d8";
}

.ph-bell-slash::before {
  content: "\f2d9";
}

.ph-bell-z::before {
  content: "\f2da";
}

.ph-bezier-curve::before {
  content: "\f2db";
}

.ph-bicycle::before {
  content: "\f2dc";
}

.ph-binoculars::before {
  content: "\f2dd";
}

.ph-bird::before {
  content: "\f2de";
}

.ph-bluetooth::before {
  content: "\f2df";
}

.ph-bluetooth-connected::before {
  content: "\f2e0";
}

.ph-bluetooth-slash::before {
  content: "\f2e1";
}

.ph-bluetooth-x::before {
  content: "\f2e2";
}

.ph-boat::before {
  content: "\f2e3";
}

.ph-book::before {
  content: "\f2e4";
}

.ph-book-bookmark::before {
  content: "\f2e5";
}

.ph-book-open::before {
  content: "\f2e6";
}

.ph-bookmark::before {
  content: "\f2e7";
}

.ph-bookmark-simple::before {
  content: "\f2e8";
}

.ph-bookmarks::before {
  content: "\f2e9";
}

.ph-bookmarks-simple::before {
  content: "\f2ea";
}

.ph-books::before {
  content: "\f2eb";
}

.ph-bounding-box::before {
  content: "\f2ec";
}

.ph-brackets-angle::before {
  content: "\f2ed";
}

.ph-brackets-curly::before {
  content: "\f2ee";
}

.ph-brackets-round::before {
  content: "\f2ef";
}

.ph-brackets-square::before {
  content: "\f2f0";
}

.ph-brain::before {
  content: "\f2f1";
}

.ph-brandy::before {
  content: "\f2f2";
}

.ph-briefcase::before {
  content: "\f2f3";
}

.ph-briefcase-metal::before {
  content: "\f2f4";
}

.ph-broadcast::before {
  content: "\f2f5";
}

.ph-browser::before {
  content: "\f2f6";
}

.ph-browsers::before {
  content: "\f2f7";
}

.ph-bug::before {
  content: "\f2f8";
}

.ph-bug-beetle::before {
  content: "\f2f9";
}

.ph-bug-droid::before {
  content: "\f2fa";
}

.ph-buildings::before {
  content: "\f2fb";
}

.ph-bus::before {
  content: "\f2fc";
}

.ph-butterfly::before {
  content: "\f2fd";
}

.ph-cactus::before {
  content: "\f2fe";
}

.ph-cake::before {
  content: "\f2ff";
}

.ph-calculator::before {
  content: "\f300";
}

.ph-calendar::before {
  content: "\f301";
}

.ph-calendar-blank::before {
  content: "\f302";
}

.ph-calendar-check::before {
  content: "\f303";
}

.ph-calendar-plus::before {
  content: "\f304";
}

.ph-calendar-x::before {
  content: "\f305";
}

.ph-camera::before {
  content: "\f306";
}

.ph-camera-rotate::before {
  content: "\f307";
}

.ph-camera-slash::before {
  content: "\f308";
}

.ph-campfire::before {
  content: "\f309";
}

.ph-car::before {
  content: "\f30a";
}

.ph-car-simple::before {
  content: "\f30b";
}

.ph-cardholder::before {
  content: "\f30c";
}

.ph-cards::before {
  content: "\f30d";
}

.ph-caret-circle-double-down::before {
  content: "\f30e";
}

.ph-caret-circle-double-left::before {
  content: "\f30f";
}

.ph-caret-circle-double-right::before {
  content: "\f310";
}

.ph-caret-circle-double-up::before {
  content: "\f311";
}

.ph-caret-circle-down::before {
  content: "\f312";
}

.ph-caret-circle-left::before {
  content: "\f313";
}

.ph-caret-circle-right::before {
  content: "\f314";
}

.ph-caret-circle-up::before {
  content: "\f315";
}

.ph-caret-double-down::before {
  content: "\f316";
}

.ph-caret-double-left::before {
  content: "\f317";
}

.ph-caret-double-right::before {
  content: "\f318";
}

.ph-caret-double-up::before {
  content: "\f319";
}

.ph-caret-down::before {
  content: "\f31a";
}

.ph-caret-left::before {
  content: "\f31b";
}

.ph-caret-right::before {
  content: "\f31c";
}

.ph-caret-up::before {
  content: "\f31d";
}

.ph-cat::before {
  content: "\f31e";
}

.ph-cell-signal-full::before {
  content: "\f31f";
}

.ph-cell-signal-high::before {
  content: "\f320";
}

.ph-cell-signal-low::before {
  content: "\f321";
}

.ph-cell-signal-medium::before {
  content: "\f322";
}

.ph-cell-signal-none::before {
  content: "\f323";
}

.ph-cell-signal-slash::before {
  content: "\f324";
}

.ph-cell-signal-x::before {
  content: "\f325";
}

.ph-chalkboard::before {
  content: "\f326";
}

.ph-chalkboard-simple::before {
  content: "\f327";
}

.ph-chalkboard-teacher::before {
  content: "\f328";
}

.ph-chart-bar::before {
  content: "\f329";
}

.ph-chart-bar-horizontal::before {
  content: "\f32a";
}

.ph-chart-line::before {
  content: "\f32b";
}

.ph-chart-line-up::before {
  content: "\f32c";
}

.ph-chart-pie::before {
  content: "\f32d";
}

.ph-chart-pie-slice::before {
  content: "\f32e";
}

.ph-chat::before {
  content: "\f32f";
}

.ph-chat-centered::before {
  content: "\f330";
}

.ph-chat-centered-dots::before {
  content: "\f331";
}

.ph-chat-centered-text::before {
  content: "\f332";
}

.ph-chat-circle::before {
  content: "\f333";
}

.ph-chat-circle-dots::before {
  content: "\f334";
}

.ph-chat-circle-text::before {
  content: "\f335";
}

.ph-chat-dots::before {
  content: "\f336";
}

.ph-chat-teardrop::before {
  content: "\f337";
}

.ph-chat-teardrop-dots::before {
  content: "\f338";
}

.ph-chat-teardrop-text::before {
  content: "\f339";
}

.ph-chat-text::before {
  content: "\f33a";
}

.ph-chats::before {
  content: "\f33b";
}

.ph-chats-circle::before {
  content: "\f33c";
}

.ph-chats-teardrop::before {
  content: "\f33d";
}

.ph-check::before {
  content: "\f33e";
}

.ph-check-circle::before {
  content: "\f33f";
}

.ph-check-square::before {
  content: "\f340";
}

.ph-check-square-offset::before {
  content: "\f341";
}

.ph-checks::before {
  content: "\f342";
}

.ph-circle::before {
  content: "\f343";
}

.ph-circle-dashed::before {
  content: "\f344";
}

.ph-circle-half::before {
  content: "\f345";
}

.ph-circle-half-tilt::before {
  content: "\f346";
}

.ph-circle-notch::before {
  content: "\f347";
}

.ph-circle-wavy::before {
  content: "\f348";
}

.ph-circle-wavy-check::before {
  content: "\f349";
}

.ph-circle-wavy-question::before {
  content: "\f34a";
}

.ph-circle-wavy-warning::before {
  content: "\f34b";
}

.ph-circles-four::before {
  content: "\f34c";
}

.ph-circles-three::before {
  content: "\f34d";
}

.ph-circles-three-plus::before {
  content: "\f34e";
}

.ph-clipboard::before {
  content: "\f34f";
}

.ph-clipboard-text::before {
  content: "\f350";
}

.ph-clock::before {
  content: "\f351";
}

.ph-clock-afternoon::before {
  content: "\f352";
}

.ph-clock-clockwise::before {
  content: "\f353";
}

.ph-clock-counter-clockwise::before {
  content: "\f354";
}

.ph-closed-captioning::before {
  content: "\f355";
}

.ph-cloud::before {
  content: "\f356";
}

.ph-cloud-arrow-down::before {
  content: "\f357";
}

.ph-cloud-arrow-up::before {
  content: "\f358";
}

.ph-cloud-check::before {
  content: "\f359";
}

.ph-cloud-fog::before {
  content: "\f35a";
}

.ph-cloud-lightning::before {
  content: "\f35b";
}

.ph-cloud-moon::before {
  content: "\f35c";
}

.ph-cloud-rain::before {
  content: "\f35d";
}

.ph-cloud-slash::before {
  content: "\f35e";
}

.ph-cloud-snow::before {
  content: "\f35f";
}

.ph-cloud-sun::before {
  content: "\f360";
}

.ph-club::before {
  content: "\f361";
}

.ph-coat-hanger::before {
  content: "\f362";
}

.ph-code::before {
  content: "\f363";
}

.ph-code-simple::before {
  content: "\f364";
}

.ph-codepen-logo::before {
  content: "\f365";
}

.ph-codesandbox-logo::before {
  content: "\f366";
}

.ph-coffee::before {
  content: "\f367";
}

.ph-coin::before {
  content: "\f368";
}

.ph-coin-vertical::before {
  content: "\f369";
}

.ph-coins::before {
  content: "\f36a";
}

.ph-columns::before {
  content: "\f36b";
}

.ph-command::before {
  content: "\f36c";
}

.ph-compass::before {
  content: "\f36d";
}

.ph-computer-tower::before {
  content: "\f36e";
}

.ph-confetti::before {
  content: "\f36f";
}

.ph-cookie::before {
  content: "\f370";
}

.ph-cooking-pot::before {
  content: "\f371";
}

.ph-copy::before {
  content: "\f372";
}

.ph-copy-simple::before {
  content: "\f373";
}

.ph-copyleft::before {
  content: "\f374";
}

.ph-copyright::before {
  content: "\f375";
}

.ph-corners-in::before {
  content: "\f376";
}

.ph-corners-out::before {
  content: "\f377";
}

.ph-cpu::before {
  content: "\f378";
}

.ph-credit-card::before {
  content: "\f379";
}

.ph-crop::before {
  content: "\f37a";
}

.ph-crosshair::before {
  content: "\f37b";
}

.ph-crosshair-simple::before {
  content: "\f37c";
}

.ph-crown::before {
  content: "\f37d";
}

.ph-crown-simple::before {
  content: "\f37e";
}

.ph-cube::before {
  content: "\f37f";
}

.ph-currency-btc::before {
  content: "\f380";
}

.ph-currency-circle-dollar::before {
  content: "\f381";
}

.ph-currency-cny::before {
  content: "\f382";
}

.ph-currency-dollar::before {
  content: "\f383";
}

.ph-currency-dollar-simple::before {
  content: "\f384";
}

.ph-currency-eth::before {
  content: "\f385";
}

.ph-currency-eur::before {
  content: "\f386";
}

.ph-currency-gbp::before {
  content: "\f387";
}

.ph-currency-inr::before {
  content: "\f388";
}

.ph-currency-jpy::before {
  content: "\f389";
}

.ph-currency-krw::before {
  content: "\f38a";
}

.ph-currency-kzt::before {
  content: "\f38b";
}

.ph-currency-ngn::before {
  content: "\f38c";
}

.ph-currency-rub::before {
  content: "\f38d";
}

.ph-cursor::before {
  content: "\f38e";
}

.ph-cursor-text::before {
  content: "\f38f";
}

.ph-cylinder::before {
  content: "\f390";
}

.ph-database::before {
  content: "\f391";
}

.ph-desktop::before {
  content: "\f392";
}

.ph-desktop-tower::before {
  content: "\f393";
}

.ph-detective::before {
  content: "\f394";
}

.ph-device-mobile::before {
  content: "\f395";
}

.ph-device-mobile-camera::before {
  content: "\f396";
}

.ph-device-mobile-speaker::before {
  content: "\f397";
}

.ph-device-tablet::before {
  content: "\f398";
}

.ph-device-tablet-camera::before {
  content: "\f399";
}

.ph-device-tablet-speaker::before {
  content: "\f39a";
}

.ph-diamond::before {
  content: "\f39b";
}

.ph-diamonds-four::before {
  content: "\f39c";
}

.ph-dice-five::before {
  content: "\f39d";
}

.ph-dice-four::before {
  content: "\f39e";
}

.ph-dice-one::before {
  content: "\f39f";
}

.ph-dice-six::before {
  content: "\f3a0";
}

.ph-dice-three::before {
  content: "\f3a1";
}

.ph-dice-two::before {
  content: "\f3a2";
}

.ph-disc::before {
  content: "\f3a3";
}

.ph-discord-logo::before {
  content: "\f3a4";
}

.ph-divide::before {
  content: "\f3a5";
}

.ph-dog::before {
  content: "\f3a6";
}

.ph-door::before {
  content: "\f3a7";
}

.ph-dots-nine::before {
  content: "\f3a8";
}

.ph-dots-six::before {
  content: "\f3a9";
}

.ph-dots-six-vertical::before {
  content: "\f3aa";
}

.ph-dots-three::before {
  content: "\f3ab";
}

.ph-dots-three-circle::before {
  content: "\f3ac";
}

.ph-dots-three-circle-vertical::before {
  content: "\f3ad";
}

.ph-dots-three-outline::before {
  content: "\f3ae";
}

.ph-dots-three-outline-vertical::before {
  content: "\f3af";
}

.ph-dots-three-vertical::before {
  content: "\f3b0";
}

.ph-download::before {
  content: "\f3b1";
}

.ph-download-simple::before {
  content: "\f3b2";
}

.ph-dribbble-logo::before {
  content: "\f3b3";
}

.ph-drop::before {
  content: "\f3b4";
}

.ph-drop-half::before {
  content: "\f3b5";
}

.ph-drop-half-bottom::before {
  content: "\f3b6";
}

.ph-ear::before {
  content: "\f3b7";
}

.ph-ear-slash::before {
  content: "\f3b8";
}

.ph-egg::before {
  content: "\f3b9";
}

.ph-egg-crack::before {
  content: "\f3ba";
}

.ph-eject::before {
  content: "\f3bb";
}

.ph-eject-simple::before {
  content: "\f3bc";
}

.ph-envelope::before {
  content: "\f3bd";
}

.ph-envelope-open::before {
  content: "\f3be";
}

.ph-envelope-simple::before {
  content: "\f3bf";
}

.ph-envelope-simple-open::before {
  content: "\f3c0";
}

.ph-equalizer::before {
  content: "\f3c1";
}

.ph-equals::before {
  content: "\f3c2";
}

.ph-eraser::before {
  content: "\f3c3";
}

.ph-exam::before {
  content: "\f3c4";
}

.ph-export::before {
  content: "\f3c5";
}

.ph-eye::before {
  content: "\f3c6";
}

.ph-eye-closed::before {
  content: "\f3c7";
}

.ph-eye-slash::before {
  content: "\f3c8";
}

.ph-eyedropper::before {
  content: "\f3c9";
}

.ph-eyedropper-sample::before {
  content: "\f3ca";
}

.ph-eyeglasses::before {
  content: "\f3cb";
}

.ph-face-mask::before {
  content: "\f3cc";
}

.ph-facebook-logo::before {
  content: "\f3cd";
}

.ph-factory::before {
  content: "\f3ce";
}

.ph-faders::before {
  content: "\f3cf";
}

.ph-faders-horizontal::before {
  content: "\f3d0";
}

.ph-fast-forward::before {
  content: "\f3d1";
}

.ph-fast-forward-circle::before {
  content: "\f3d2";
}

.ph-figma-logo::before {
  content: "\f3d3";
}

.ph-file::before {
  content: "\f3d4";
}

.ph-file-arrow-down::before {
  content: "\f3d5";
}

.ph-file-arrow-up::before {
  content: "\f3d6";
}

.ph-file-audio::before {
  content: "\f3d7";
}

.ph-file-cloud::before {
  content: "\f3d8";
}

.ph-file-code::before {
  content: "\f3d9";
}

.ph-file-css::before {
  content: "\f3da";
}

.ph-file-csv::before {
  content: "\f3db";
}

.ph-file-doc::before {
  content: "\f3dc";
}

.ph-file-dotted::before {
  content: "\f3dd";
}

.ph-file-html::before {
  content: "\f3de";
}

.ph-file-image::before {
  content: "\f3df";
}

.ph-file-jpg::before {
  content: "\f3e0";
}

.ph-file-js::before {
  content: "\f3e1";
}

.ph-file-jsx::before {
  content: "\f3e2";
}

.ph-file-lock::before {
  content: "\f3e3";
}

.ph-file-minus::before {
  content: "\f3e4";
}

.ph-file-pdf::before {
  content: "\f3e5";
}

.ph-file-plus::before {
  content: "\f3e6";
}

.ph-file-png::before {
  content: "\f3e7";
}

.ph-file-ppt::before {
  content: "\f3e8";
}

.ph-file-rs::before {
  content: "\f3e9";
}

.ph-file-search::before {
  content: "\f3ea";
}

.ph-file-text::before {
  content: "\f3eb";
}

.ph-file-ts::before {
  content: "\f3ec";
}

.ph-file-tsx::before {
  content: "\f3ed";
}

.ph-file-video::before {
  content: "\f3ee";
}

.ph-file-vue::before {
  content: "\f3ef";
}

.ph-file-x::before {
  content: "\f3f0";
}

.ph-file-xls::before {
  content: "\f3f1";
}

.ph-file-zip::before {
  content: "\f3f2";
}

.ph-files::before {
  content: "\f3f3";
}

.ph-film-script::before {
  content: "\f3f4";
}

.ph-film-slate::before {
  content: "\f3f5";
}

.ph-film-strip::before {
  content: "\f3f6";
}

.ph-fingerprint::before {
  content: "\f3f7";
}

.ph-fingerprint-simple::before {
  content: "\f3f8";
}

.ph-finn-the-human::before {
  content: "\f3f9";
}

.ph-fire::before {
  content: "\f3fa";
}

.ph-fire-simple::before {
  content: "\f3fb";
}

.ph-first-aid::before {
  content: "\f3fc";
}

.ph-first-aid-kit::before {
  content: "\f3fd";
}

.ph-fish::before {
  content: "\f3fe";
}

.ph-fish-simple::before {
  content: "\f3ff";
}

.ph-flag::before {
  content: "\f400";
}

.ph-flag-banner::before {
  content: "\f401";
}

.ph-flag-checkered::before {
  content: "\f402";
}

.ph-flame::before {
  content: "\f403";
}

.ph-flashlight::before {
  content: "\f404";
}

.ph-flask::before {
  content: "\f405";
}

.ph-floppy-disk::before {
  content: "\f406";
}

.ph-floppy-disk-back::before {
  content: "\f407";
}

.ph-flow-arrow::before {
  content: "\f408";
}

.ph-flower::before {
  content: "\f409";
}

.ph-flower-lotus::before {
  content: "\f40a";
}

.ph-flying-saucer::before {
  content: "\f40b";
}

.ph-folder::before {
  content: "\f40c";
}

.ph-folder-dotted::before {
  content: "\f40d";
}

.ph-folder-lock::before {
  content: "\f40e";
}

.ph-folder-minus::before {
  content: "\f40f";
}

.ph-folder-notch::before {
  content: "\f410";
}

.ph-folder-notch-minus::before {
  content: "\f411";
}

.ph-folder-notch-open::before {
  content: "\f412";
}

.ph-folder-notch-plus::before {
  content: "\f413";
}

.ph-folder-open::before {
  content: "\f414";
}

.ph-folder-plus::before {
  content: "\f415";
}

.ph-folder-simple::before {
  content: "\f416";
}

.ph-folder-simple-dotted::before {
  content: "\f417";
}

.ph-folder-simple-lock::before {
  content: "\f418";
}

.ph-folder-simple-minus::before {
  content: "\f419";
}

.ph-folder-simple-plus::before {
  content: "\f41a";
}

.ph-folder-simple-star::before {
  content: "\f41b";
}

.ph-folder-simple-user::before {
  content: "\f41c";
}

.ph-folder-star::before {
  content: "\f41d";
}

.ph-folder-user::before {
  content: "\f41e";
}

.ph-folders::before {
  content: "\f41f";
}

.ph-football::before {
  content: "\f420";
}

.ph-fork-knife::before {
  content: "\f421";
}

.ph-frame-corners::before {
  content: "\f422";
}

.ph-framer-logo::before {
  content: "\f423";
}

.ph-function::before {
  content: "\f424";
}

.ph-funnel::before {
  content: "\f425";
}

.ph-funnel-simple::before {
  content: "\f426";
}

.ph-game-controller::before {
  content: "\f427";
}

.ph-gas-pump::before {
  content: "\f428";
}

.ph-gauge::before {
  content: "\f429";
}

.ph-gear::before {
  content: "\f42a";
}

.ph-gear-six::before {
  content: "\f42b";
}

.ph-gender-female::before {
  content: "\f42c";
}

.ph-gender-intersex::before {
  content: "\f42d";
}

.ph-gender-male::before {
  content: "\f42e";
}

.ph-gender-neuter::before {
  content: "\f42f";
}

.ph-gender-nonbinary::before {
  content: "\f430";
}

.ph-gender-transgender::before {
  content: "\f431";
}

.ph-ghost::before {
  content: "\f432";
}

.ph-gif::before {
  content: "\f433";
}

.ph-gift::before {
  content: "\f434";
}

.ph-git-branch::before {
  content: "\f435";
}

.ph-git-commit::before {
  content: "\f436";
}

.ph-git-diff::before {
  content: "\f437";
}

.ph-git-fork::before {
  content: "\f438";
}

.ph-git-merge::before {
  content: "\f439";
}

.ph-git-pull-request::before {
  content: "\f43a";
}

.ph-github-logo::before {
  content: "\f43b";
}

.ph-gitlab-logo::before {
  content: "\f43c";
}

.ph-gitlab-logo-simple::before {
  content: "\f43d";
}

.ph-globe::before {
  content: "\f43e";
}

.ph-globe-hemisphere-east::before {
  content: "\f43f";
}

.ph-globe-hemisphere-west::before {
  content: "\f440";
}

.ph-globe-simple::before {
  content: "\f441";
}

.ph-globe-stand::before {
  content: "\f442";
}

.ph-google-chrome-logo::before {
  content: "\f443";
}

.ph-google-logo::before {
  content: "\f444";
}

.ph-google-photos-logo::before {
  content: "\f445";
}

.ph-google-play-logo::before {
  content: "\f446";
}

.ph-google-podcasts-logo::before {
  content: "\f447";
}

.ph-gradient::before {
  content: "\f448";
}

.ph-graduation-cap::before {
  content: "\f449";
}

.ph-graph::before {
  content: "\f44a";
}

.ph-grid-four::before {
  content: "\f44b";
}

.ph-hamburger::before {
  content: "\f44c";
}

.ph-hand::before {
  content: "\f44d";
}

.ph-hand-eye::before {
  content: "\f44e";
}

.ph-hand-fist::before {
  content: "\f44f";
}

.ph-hand-grabbing::before {
  content: "\f450";
}

.ph-hand-palm::before {
  content: "\f451";
}

.ph-hand-pointing::before {
  content: "\f452";
}

.ph-hand-soap::before {
  content: "\f453";
}

.ph-hand-waving::before {
  content: "\f454";
}

.ph-handbag::before {
  content: "\f455";
}

.ph-handbag-simple::before {
  content: "\f456";
}

.ph-hands-clapping::before {
  content: "\f457";
}

.ph-handshake::before {
  content: "\f458";
}

.ph-hard-drive::before {
  content: "\f459";
}

.ph-hard-drives::before {
  content: "\f45a";
}

.ph-hash::before {
  content: "\f45b";
}

.ph-hash-straight::before {
  content: "\f45c";
}

.ph-headlights::before {
  content: "\f45d";
}

.ph-headphones::before {
  content: "\f45e";
}

.ph-headset::before {
  content: "\f45f";
}

.ph-heart::before {
  content: "\f460";
}

.ph-heart-break::before {
  content: "\f461";
}

.ph-heart-straight::before {
  content: "\f462";
}

.ph-heart-straight-break::before {
  content: "\f463";
}

.ph-heartbeat::before {
  content: "\f464";
}

.ph-hexagon::before {
  content: "\f465";
}

.ph-highlighter-circle::before {
  content: "\f466";
}

.ph-horse::before {
  content: "\f467";
}

.ph-hourglass::before {
  content: "\f468";
}

.ph-hourglass-high::before {
  content: "\f469";
}

.ph-hourglass-low::before {
  content: "\f46a";
}

.ph-hourglass-medium::before {
  content: "\f46b";
}

.ph-hourglass-simple::before {
  content: "\f46c";
}

.ph-hourglass-simple-high::before {
  content: "\f46d";
}

.ph-hourglass-simple-low::before {
  content: "\f46e";
}

.ph-hourglass-simple-medium::before {
  content: "\f46f";
}

.ph-house::before {
  content: "\f470";
}

.ph-house-line::before {
  content: "\f471";
}

.ph-house-simple::before {
  content: "\f472";
}

.ph-identification-badge::before {
  content: "\f473";
}

.ph-identification-card::before {
  content: "\f474";
}

.ph-image::before {
  content: "\f475";
}

.ph-image-square::before {
  content: "\f476";
}

.ph-infinity::before {
  content: "\f477";
}

.ph-info::before {
  content: "\f478";
}

.ph-instagram-logo::before {
  content: "\f479";
}

.ph-intersect::before {
  content: "\f47a";
}

.ph-jeep::before {
  content: "\f47b";
}

.ph-kanban::before {
  content: "\f47c";
}

.ph-key::before {
  content: "\f47d";
}

.ph-key-return::before {
  content: "\f47e";
}

.ph-keyboard::before {
  content: "\f47f";
}

.ph-keyhole::before {
  content: "\f480";
}

.ph-knife::before {
  content: "\f481";
}

.ph-ladder::before {
  content: "\f482";
}

.ph-ladder-simple::before {
  content: "\f483";
}

.ph-lamp::before {
  content: "\f484";
}

.ph-laptop::before {
  content: "\f485";
}

.ph-layout::before {
  content: "\f486";
}

.ph-leaf::before {
  content: "\f487";
}

.ph-lifebuoy::before {
  content: "\f488";
}

.ph-lightbulb::before {
  content: "\f489";
}

.ph-lightbulb-filament::before {
  content: "\f48a";
}

.ph-lightning::before {
  content: "\f48b";
}

.ph-lightning-slash::before {
  content: "\f48c";
}

.ph-line-segment::before {
  content: "\f48d";
}

.ph-line-segments::before {
  content: "\f48e";
}

.ph-link::before {
  content: "\f48f";
}

.ph-link-break::before {
  content: "\f490";
}

.ph-link-simple::before {
  content: "\f491";
}

.ph-link-simple-break::before {
  content: "\f492";
}

.ph-link-simple-horizontal::before {
  content: "\f493";
}

.ph-link-simple-horizontal-break::before {
  content: "\f494";
}

.ph-linkedin-logo::before {
  content: "\f495";
}

.ph-linux-logo::before {
  content: "\f496";
}

.ph-list::before {
  content: "\f497";
}

.ph-list-bullets::before {
  content: "\f498";
}

.ph-list-checks::before {
  content: "\f499";
}

.ph-list-dashes::before {
  content: "\f49a";
}

.ph-list-numbers::before {
  content: "\f49b";
}

.ph-list-plus::before {
  content: "\f49c";
}

.ph-lock::before {
  content: "\f49d";
}

.ph-lock-key::before {
  content: "\f49e";
}

.ph-lock-key-open::before {
  content: "\f49f";
}

.ph-lock-laminated::before {
  content: "\f4a0";
}

.ph-lock-laminated-open::before {
  content: "\f4a1";
}

.ph-lock-open::before {
  content: "\f4a2";
}

.ph-lock-simple::before {
  content: "\f4a3";
}

.ph-lock-simple-open::before {
  content: "\f4a4";
}

.ph-magic-wand::before {
  content: "\f4a5";
}

.ph-magnet::before {
  content: "\f4a6";
}

.ph-magnet-straight::before {
  content: "\f4a7";
}

.ph-magnifying-glass::before {
  content: "\f4a8";
}

.ph-magnifying-glass-minus::before {
  content: "\f4a9";
}

.ph-magnifying-glass-plus::before {
  content: "\f4aa";
}

.ph-map-pin::before {
  content: "\f4ab";
}

.ph-map-pin-line::before {
  content: "\f4ac";
}

.ph-map-trifold::before {
  content: "\f4ad";
}

.ph-marker-circle::before {
  content: "\f4ae";
}

.ph-martini::before {
  content: "\f4af";
}

.ph-mask-happy::before {
  content: "\f4b0";
}

.ph-mask-sad::before {
  content: "\f4b1";
}

.ph-math-operations::before {
  content: "\f4b2";
}

.ph-medal::before {
  content: "\f4b3";
}

.ph-medium-logo::before {
  content: "\f4b4";
}

.ph-megaphone::before {
  content: "\f4b5";
}

.ph-megaphone-simple::before {
  content: "\f4b6";
}

.ph-messenger-logo::before {
  content: "\f4b7";
}

.ph-microphone::before {
  content: "\f4b8";
}

.ph-microphone-slash::before {
  content: "\f4b9";
}

.ph-microphone-stage::before {
  content: "\f4ba";
}

.ph-microsoft-excel-logo::before {
  content: "\f4bb";
}

.ph-microsoft-powerpoint-logo::before {
  content: "\f4bc";
}

.ph-microsoft-teams-logo::before {
  content: "\f4bd";
}

.ph-microsoft-word-logo::before {
  content: "\f4be";
}

.ph-minus::before {
  content: "\f4bf";
}

.ph-minus-circle::before {
  content: "\f4c0";
}

.ph-money::before {
  content: "\f4c1";
}

.ph-monitor::before {
  content: "\f4c2";
}

.ph-monitor-play::before {
  content: "\f4c3";
}

.ph-moon::before {
  content: "\f4c4";
}

.ph-moon-stars::before {
  content: "\f4c5";
}

.ph-mountains::before {
  content: "\f4c6";
}

.ph-mouse::before {
  content: "\f4c7";
}

.ph-mouse-simple::before {
  content: "\f4c8";
}

.ph-music-note::before {
  content: "\f4c9";
}

.ph-music-note-simple::before {
  content: "\f4ca";
}

.ph-music-notes::before {
  content: "\f4cb";
}

.ph-music-notes-plus::before {
  content: "\f4cc";
}

.ph-music-notes-simple::before {
  content: "\f4cd";
}

.ph-navigation-arrow::before {
  content: "\f4ce";
}

.ph-needle::before {
  content: "\f4cf";
}

.ph-newspaper::before {
  content: "\f4d0";
}

.ph-newspaper-clipping::before {
  content: "\f4d1";
}

.ph-note::before {
  content: "\f4d2";
}

.ph-note-blank::before {
  content: "\f4d3";
}

.ph-note-pencil::before {
  content: "\f4d4";
}

.ph-notebook::before {
  content: "\f4d5";
}

.ph-notepad::before {
  content: "\f4d6";
}

.ph-notification::before {
  content: "\f4d7";
}

.ph-number-circle-eight::before {
  content: "\f4d8";
}

.ph-number-circle-five::before {
  content: "\f4d9";
}

.ph-number-circle-four::before {
  content: "\f4da";
}

.ph-number-circle-nine::before {
  content: "\f4db";
}

.ph-number-circle-one::before {
  content: "\f4dc";
}

.ph-number-circle-seven::before {
  content: "\f4dd";
}

.ph-number-circle-six::before {
  content: "\f4de";
}

.ph-number-circle-three::before {
  content: "\f4df";
}

.ph-number-circle-two::before {
  content: "\f4e0";
}

.ph-number-circle-zero::before {
  content: "\f4e1";
}

.ph-number-eight::before {
  content: "\f4e2";
}

.ph-number-five::before {
  content: "\f4e3";
}

.ph-number-four::before {
  content: "\f4e4";
}

.ph-number-nine::before {
  content: "\f4e5";
}

.ph-number-one::before {
  content: "\f4e6";
}

.ph-number-seven::before {
  content: "\f4e7";
}

.ph-number-six::before {
  content: "\f4e8";
}

.ph-number-square-eight::before {
  content: "\f4e9";
}

.ph-number-square-five::before {
  content: "\f4ea";
}

.ph-number-square-four::before {
  content: "\f4eb";
}

.ph-number-square-nine::before {
  content: "\f4ec";
}

.ph-number-square-one::before {
  content: "\f4ed";
}

.ph-number-square-seven::before {
  content: "\f4ee";
}

.ph-number-square-six::before {
  content: "\f4ef";
}

.ph-number-square-three::before {
  content: "\f4f0";
}

.ph-number-square-two::before {
  content: "\f4f1";
}

.ph-number-square-zero::before {
  content: "\f4f2";
}

.ph-number-three::before {
  content: "\f4f3";
}

.ph-number-two::before {
  content: "\f4f4";
}

.ph-number-zero::before {
  content: "\f4f5";
}

.ph-nut::before {
  content: "\f4f6";
}

.ph-ny-times-logo::before {
  content: "\f4f7";
}

.ph-octagon::before {
  content: "\f4f8";
}

.ph-option::before {
  content: "\f4f9";
}

.ph-package::before {
  content: "\f4fa";
}

.ph-paint-brush::before {
  content: "\f4fb";
}

.ph-paint-brush-broad::before {
  content: "\f4fc";
}

.ph-paint-brush-household::before {
  content: "\f4fd";
}

.ph-paint-bucket::before {
  content: "\f4fe";
}

.ph-paint-roller::before {
  content: "\f4ff";
}

.ph-palette::before {
  content: "\f500";
}

.ph-paper-plane::before {
  content: "\f501";
}

.ph-paper-plane-right::before {
  content: "\f502";
}

.ph-paper-plane-tilt::before {
  content: "\f503";
}

.ph-paperclip::before {
  content: "\f504";
}

.ph-paperclip-horizontal::before {
  content: "\f505";
}

.ph-parachute::before {
  content: "\f506";
}

.ph-password::before {
  content: "\f507";
}

.ph-path::before {
  content: "\f508";
}

.ph-pause::before {
  content: "\f509";
}

.ph-pause-circle::before {
  content: "\f50a";
}

.ph-paw-print::before {
  content: "\f50b";
}

.ph-peace::before {
  content: "\f50c";
}

.ph-pen::before {
  content: "\f50d";
}

.ph-pen-nib::before {
  content: "\f50e";
}

.ph-pen-nib-straight::before {
  content: "\f50f";
}

.ph-pencil::before {
  content: "\f510";
}

.ph-pencil-circle::before {
  content: "\f511";
}

.ph-pencil-line::before {
  content: "\f512";
}

.ph-pencil-simple::before {
  content: "\f513";
}

.ph-pencil-simple-line::before {
  content: "\f514";
}

.ph-percent::before {
  content: "\f515";
}

.ph-person::before {
  content: "\f516";
}

.ph-person-simple::before {
  content: "\f517";
}

.ph-person-simple-run::before {
  content: "\f518";
}

.ph-person-simple-walk::before {
  content: "\f519";
}

.ph-perspective::before {
  content: "\f51a";
}

.ph-phone::before {
  content: "\f51b";
}

.ph-phone-call::before {
  content: "\f51c";
}

.ph-phone-disconnect::before {
  content: "\f51d";
}

.ph-phone-incoming::before {
  content: "\f51e";
}

.ph-phone-outgoing::before {
  content: "\f51f";
}

.ph-phone-slash::before {
  content: "\f520";
}

.ph-phone-x::before {
  content: "\f521";
}

.ph-phosphor-logo::before {
  content: "\f522";
}

.ph-piano-keys::before {
  content: "\f523";
}

.ph-picture-in-picture::before {
  content: "\f524";
}

.ph-pill::before {
  content: "\f525";
}

.ph-pinterest-logo::before {
  content: "\f526";
}

.ph-pinwheel::before {
  content: "\f527";
}

.ph-pizza::before {
  content: "\f528";
}

.ph-placeholder::before {
  content: "\f529";
}

.ph-planet::before {
  content: "\f52a";
}

.ph-play::before {
  content: "\f52b";
}

.ph-play-circle::before {
  content: "\f52c";
}

.ph-playlist::before {
  content: "\f52d";
}

.ph-plug::before {
  content: "\f52e";
}

.ph-plugs::before {
  content: "\f52f";
}

.ph-plugs-connected::before {
  content: "\f530";
}

.ph-plus::before {
  content: "\f531";
}

.ph-plus-circle::before {
  content: "\f532";
}

.ph-plus-minus::before {
  content: "\f533";
}

.ph-poker-chip::before {
  content: "\f534";
}

.ph-police-car::before {
  content: "\f535";
}

.ph-polygon::before {
  content: "\f536";
}

.ph-popcorn::before {
  content: "\f537";
}

.ph-power::before {
  content: "\f538";
}

.ph-prescription::before {
  content: "\f539";
}

.ph-presentation::before {
  content: "\f53a";
}

.ph-presentation-chart::before {
  content: "\f53b";
}

.ph-printer::before {
  content: "\f53c";
}

.ph-prohibit::before {
  content: "\f53d";
}

.ph-prohibit-inset::before {
  content: "\f53e";
}

.ph-projector-screen::before {
  content: "\f53f";
}

.ph-projector-screen-chart::before {
  content: "\f540";
}

.ph-push-pin::before {
  content: "\f541";
}

.ph-push-pin-simple::before {
  content: "\f542";
}

.ph-push-pin-simple-slash::before {
  content: "\f543";
}

.ph-push-pin-slash::before {
  content: "\f544";
}

.ph-puzzle-piece::before {
  content: "\f545";
}

.ph-qr-code::before {
  content: "\f546";
}

.ph-question::before {
  content: "\f547";
}

.ph-queue::before {
  content: "\f548";
}

.ph-quotes::before {
  content: "\f549";
}

.ph-radical::before {
  content: "\f54a";
}

.ph-radio::before {
  content: "\f54b";
}

.ph-radio-button::before {
  content: "\f54c";
}

.ph-rainbow::before {
  content: "\f54d";
}

.ph-rainbow-cloud::before {
  content: "\f54e";
}

.ph-receipt::before {
  content: "\f54f";
}

.ph-record::before {
  content: "\f550";
}

.ph-rectangle::before {
  content: "\f551";
}

.ph-recycle::before {
  content: "\f552";
}

.ph-reddit-logo::before {
  content: "\f553";
}

.ph-repeat::before {
  content: "\f554";
}

.ph-repeat-once::before {
  content: "\f555";
}

.ph-rewind::before {
  content: "\f556";
}

.ph-rewind-circle::before {
  content: "\f557";
}

.ph-robot::before {
  content: "\f558";
}

.ph-rocket::before {
  content: "\f559";
}

.ph-rocket-launch::before {
  content: "\f55a";
}

.ph-rows::before {
  content: "\f55b";
}

.ph-rss::before {
  content: "\f55c";
}

.ph-rss-simple::before {
  content: "\f55d";
}

.ph-rug::before {
  content: "\f55e";
}

.ph-ruler::before {
  content: "\f55f";
}

.ph-scales::before {
  content: "\f560";
}

.ph-scan::before {
  content: "\f561";
}

.ph-scissors::before {
  content: "\f562";
}

.ph-screencast::before {
  content: "\f563";
}

.ph-scribble-loop::before {
  content: "\f564";
}

.ph-scroll::before {
  content: "\f565";
}

.ph-selection::before {
  content: "\f566";
}

.ph-selection-all::before {
  content: "\f567";
}

.ph-selection-background::before {
  content: "\f568";
}

.ph-selection-foreground::before {
  content: "\f569";
}

.ph-selection-inverse::before {
  content: "\f56a";
}

.ph-selection-plus::before {
  content: "\f56b";
}

.ph-selection-slash::before {
  content: "\f56c";
}

.ph-share::before {
  content: "\f56d";
}

.ph-share-network::before {
  content: "\f56e";
}

.ph-shield::before {
  content: "\f56f";
}

.ph-shield-check::before {
  content: "\f570";
}

.ph-shield-checkered::before {
  content: "\f571";
}

.ph-shield-chevron::before {
  content: "\f572";
}

.ph-shield-plus::before {
  content: "\f573";
}

.ph-shield-slash::before {
  content: "\f574";
}

.ph-shield-star::before {
  content: "\f575";
}

.ph-shield-warning::before {
  content: "\f576";
}

.ph-shopping-bag::before {
  content: "\f577";
}

.ph-shopping-bag-open::before {
  content: "\f578";
}

.ph-shopping-cart::before {
  content: "\f579";
}

.ph-shopping-cart-simple::before {
  content: "\f57a";
}

.ph-shower::before {
  content: "\f57b";
}

.ph-shuffle::before {
  content: "\f57c";
}

.ph-shuffle-angular::before {
  content: "\f57d";
}

.ph-shuffle-simple::before {
  content: "\f57e";
}

.ph-sidebar::before {
  content: "\f57f";
}

.ph-sidebar-simple::before {
  content: "\f580";
}

.ph-sign-in::before {
  content: "\f581";
}

.ph-sign-out::before {
  content: "\f582";
}

.ph-signpost::before {
  content: "\f583";
}

.ph-sim-card::before {
  content: "\f584";
}

.ph-sketch-logo::before {
  content: "\f585";
}

.ph-skip-back::before {
  content: "\f586";
}

.ph-skip-back-circle::before {
  content: "\f587";
}

.ph-skip-forward::before {
  content: "\f588";
}

.ph-skip-forward-circle::before {
  content: "\f589";
}

.ph-skull::before {
  content: "\f58a";
}

.ph-slack-logo::before {
  content: "\f58b";
}

.ph-sliders::before {
  content: "\f58c";
}

.ph-sliders-horizontal::before {
  content: "\f58d";
}

.ph-smiley::before {
  content: "\f58e";
}

.ph-smiley-blank::before {
  content: "\f58f";
}

.ph-smiley-meh::before {
  content: "\f590";
}

.ph-smiley-nervous::before {
  content: "\f591";
}

.ph-smiley-sad::before {
  content: "\f592";
}

.ph-smiley-sticker::before {
  content: "\f593";
}

.ph-smiley-wink::before {
  content: "\f594";
}

.ph-smiley-x-eyes::before {
  content: "\f595";
}

.ph-snapchat-logo::before {
  content: "\f596";
}

.ph-snowflake::before {
  content: "\f597";
}

.ph-soccer-ball::before {
  content: "\f598";
}

.ph-sort-ascending::before {
  content: "\f599";
}

.ph-sort-descending::before {
  content: "\f59a";
}

.ph-spade::before {
  content: "\f59b";
}

.ph-sparkle::before {
  content: "\f59c";
}

.ph-speaker-high::before {
  content: "\f59d";
}

.ph-speaker-low::before {
  content: "\f59e";
}

.ph-speaker-none::before {
  content: "\f59f";
}

.ph-speaker-simple-high::before {
  content: "\f5a0";
}

.ph-speaker-simple-low::before {
  content: "\f5a1";
}

.ph-speaker-simple-none::before {
  content: "\f5a2";
}

.ph-speaker-simple-slash::before {
  content: "\f5a3";
}

.ph-speaker-simple-x::before {
  content: "\f5a4";
}

.ph-speaker-slash::before {
  content: "\f5a5";
}

.ph-speaker-x::before {
  content: "\f5a6";
}

.ph-spinner::before {
  content: "\f5a7";
}

.ph-spinner-gap::before {
  content: "\f5a8";
}

.ph-spiral::before {
  content: "\f5a9";
}

.ph-spotify-logo::before {
  content: "\f5aa";
}

.ph-square::before {
  content: "\f5ab";
}

.ph-square-half::before {
  content: "\f5ac";
}

.ph-square-half-bottom::before {
  content: "\f5ad";
}

.ph-square-logo::before {
  content: "\f5ae";
}

.ph-squares-four::before {
  content: "\f5af";
}

.ph-stack::before {
  content: "\f5b0";
}

.ph-stack-overflow-logo::before {
  content: "\f5b1";
}

.ph-stack-simple::before {
  content: "\f5b2";
}

.ph-stamp::before {
  content: "\f5b3";
}

.ph-star::before {
  content: "\f5b4";
}

.ph-star-four::before {
  content: "\f5b5";
}

.ph-star-half::before {
  content: "\f5b6";
}

.ph-sticker::before {
  content: "\f5b7";
}

.ph-stop::before {
  content: "\f5b8";
}

.ph-stop-circle::before {
  content: "\f5b9";
}

.ph-storefront::before {
  content: "\f5ba";
}

.ph-strategy::before {
  content: "\f5bb";
}

.ph-stripe-logo::before {
  content: "\f5bc";
}

.ph-student::before {
  content: "\f5bd";
}

.ph-suitcase::before {
  content: "\f5be";
}

.ph-suitcase-simple::before {
  content: "\f5bf";
}

.ph-sun::before {
  content: "\f5c0";
}

.ph-sun-dim::before {
  content: "\f5c1";
}

.ph-sun-horizon::before {
  content: "\f5c2";
}

.ph-sunglasses::before {
  content: "\f5c3";
}

.ph-swap::before {
  content: "\f5c4";
}

.ph-swatches::before {
  content: "\f5c5";
}

.ph-sword::before {
  content: "\f5c6";
}

.ph-syringe::before {
  content: "\f5c7";
}

.ph-t-shirt::before {
  content: "\f5c8";
}

.ph-table::before {
  content: "\f5c9";
}

.ph-tabs::before {
  content: "\f5ca";
}

.ph-tag::before {
  content: "\f5cb";
}

.ph-tag-chevron::before {
  content: "\f5cc";
}

.ph-tag-simple::before {
  content: "\f5cd";
}

.ph-target::before {
  content: "\f5ce";
}

.ph-taxi::before {
  content: "\f5cf";
}

.ph-telegram-logo::before {
  content: "\f5d0";
}

.ph-television::before {
  content: "\f5d1";
}

.ph-television-simple::before {
  content: "\f5d2";
}

.ph-tennis-ball::before {
  content: "\f5d3";
}

.ph-terminal::before {
  content: "\f5d4";
}

.ph-terminal-window::before {
  content: "\f5d5";
}

.ph-test-tube::before {
  content: "\f5d6";
}

.ph-text-aa::before {
  content: "\f5d7";
}

.ph-text-align-center::before {
  content: "\f5d8";
}

.ph-text-align-justify::before {
  content: "\f5d9";
}

.ph-text-align-left::before {
  content: "\f5da";
}

.ph-text-align-right::before {
  content: "\f5db";
}

.ph-text-bolder::before {
  content: "\f5dc";
}

.ph-text-h::before {
  content: "\f5dd";
}

.ph-text-h-five::before {
  content: "\f5de";
}

.ph-text-h-four::before {
  content: "\f5df";
}

.ph-text-h-one::before {
  content: "\f5e0";
}

.ph-text-h-six::before {
  content: "\f5e1";
}

.ph-text-h-three::before {
  content: "\f5e2";
}

.ph-text-h-two::before {
  content: "\f5e3";
}

.ph-text-indent::before {
  content: "\f5e4";
}

.ph-text-italic::before {
  content: "\f5e5";
}

.ph-text-outdent::before {
  content: "\f5e6";
}

.ph-text-strikethrough::before {
  content: "\f5e7";
}

.ph-text-t::before {
  content: "\f5e8";
}

.ph-text-underline::before {
  content: "\f5e9";
}

.ph-textbox::before {
  content: "\f5ea";
}

.ph-thermometer::before {
  content: "\f5eb";
}

.ph-thermometer-cold::before {
  content: "\f5ec";
}

.ph-thermometer-hot::before {
  content: "\f5ed";
}

.ph-thermometer-simple::before {
  content: "\f5ee";
}

.ph-thumbs-down::before {
  content: "\f5ef";
}

.ph-thumbs-up::before {
  content: "\f5f0";
}

.ph-ticket::before {
  content: "\f5f1";
}

.ph-tiktok-logo::before {
  content: "\f5f2";
}

.ph-timer::before {
  content: "\f5f3";
}

.ph-toggle-left::before {
  content: "\f5f4";
}

.ph-toggle-right::before {
  content: "\f5f5";
}

.ph-toilet::before {
  content: "\f5f6";
}

.ph-toilet-paper::before {
  content: "\f5f7";
}

.ph-tote::before {
  content: "\f5f8";
}

.ph-tote-simple::before {
  content: "\f5f9";
}

.ph-trademark-registered::before {
  content: "\f5fa";
}

.ph-traffic-cone::before {
  content: "\f5fb";
}

.ph-traffic-sign::before {
  content: "\f5fc";
}

.ph-traffic-signal::before {
  content: "\f5fd";
}

.ph-train::before {
  content: "\f5fe";
}

.ph-train-regional::before {
  content: "\f5ff";
}

.ph-train-simple::before {
  content: "\f600";
}

.ph-translate::before {
  content: "\f601";
}

.ph-trash::before {
  content: "\f602";
}

.ph-trash-simple::before {
  content: "\f603";
}

.ph-tray::before {
  content: "\f604";
}

.ph-tree::before {
  content: "\f605";
}

.ph-tree-evergreen::before {
  content: "\f606";
}

.ph-tree-structure::before {
  content: "\f607";
}

.ph-trend-down::before {
  content: "\f608";
}

.ph-trend-up::before {
  content: "\f609";
}

.ph-triangle::before {
  content: "\f60a";
}

.ph-trophy::before {
  content: "\f60b";
}

.ph-truck::before {
  content: "\f60c";
}

.ph-twitch-logo::before {
  content: "\f60d";
}

.ph-twitter-logo::before {
  content: "\f60e";
}

.ph-umbrella::before {
  content: "\f60f";
}

.ph-umbrella-simple::before {
  content: "\f610";
}

.ph-upload::before {
  content: "\f611";
}

.ph-upload-simple::before {
  content: "\f612";
}

.ph-user::before {
  content: "\f613";
}

.ph-user-circle::before {
  content: "\f614";
}

.ph-user-circle-gear::before {
  content: "\f615";
}

.ph-user-circle-minus::before {
  content: "\f616";
}

.ph-user-circle-plus::before {
  content: "\f617";
}

.ph-user-focus::before {
  content: "\f618";
}

.ph-user-gear::before {
  content: "\f619";
}

.ph-user-list::before {
  content: "\f61a";
}

.ph-user-minus::before {
  content: "\f61b";
}

.ph-user-plus::before {
  content: "\f61c";
}

.ph-user-rectangle::before {
  content: "\f61d";
}

.ph-user-square::before {
  content: "\f61e";
}

.ph-user-switch::before {
  content: "\f61f";
}

.ph-users::before {
  content: "\f620";
}

.ph-users-four::before {
  content: "\f621";
}

.ph-users-three::before {
  content: "\f622";
}

.ph-vault::before {
  content: "\f623";
}

.ph-vibrate::before {
  content: "\f624";
}

.ph-video-camera::before {
  content: "\f625";
}

.ph-video-camera-slash::before {
  content: "\f626";
}

.ph-vignette::before {
  content: "\f627";
}

.ph-voicemail::before {
  content: "\f628";
}

.ph-volleyball::before {
  content: "\f629";
}

.ph-wall::before {
  content: "\f62a";
}

.ph-wallet::before {
  content: "\f62b";
}

.ph-warning::before {
  content: "\f62c";
}

.ph-warning-circle::before {
  content: "\f62d";
}

.ph-warning-octagon::before {
  content: "\f62e";
}

.ph-watch::before {
  content: "\f62f";
}

.ph-wave-sawtooth::before {
  content: "\f630";
}

.ph-wave-sine::before {
  content: "\f631";
}

.ph-wave-square::before {
  content: "\f632";
}

.ph-wave-triangle::before {
  content: "\f633";
}

.ph-waves::before {
  content: "\f634";
}

.ph-webcam::before {
  content: "\f635";
}

.ph-whatsapp-logo::before {
  content: "\f636";
}

.ph-wheelchair::before {
  content: "\f637";
}

.ph-wifi-high::before {
  content: "\f638";
}

.ph-wifi-low::before {
  content: "\f639";
}

.ph-wifi-medium::before {
  content: "\f63a";
}

.ph-wifi-none::before {
  content: "\f63b";
}

.ph-wifi-slash::before {
  content: "\f63c";
}

.ph-wifi-x::before {
  content: "\f63d";
}

.ph-wind::before {
  content: "\f63e";
}

.ph-windows-logo::before {
  content: "\f63f";
}

.ph-wine::before {
  content: "\f640";
}

.ph-wrench::before {
  content: "\f641";
}

.ph-x::before {
  content: "\f642";
}

.ph-x-circle::before {
  content: "\f643";
}

.ph-x-square::before {
  content: "\f644";
}

.ph-yin-yang::before {
  content: "\f645";
}

.ph-youtube-logo::before {
  content: "\f646";
}

.ph-activity-bold::before {
  content: "\f647";
}

.ph-address-book-bold::before {
  content: "\f648";
}

.ph-airplane-bold::before {
  content: "\f649";
}

.ph-airplane-in-flight-bold::before {
  content: "\f64a";
}

.ph-airplane-landing-bold::before {
  content: "\f64b";
}

.ph-airplane-takeoff-bold::before {
  content: "\f64c";
}

.ph-airplane-tilt-bold::before {
  content: "\f64d";
}

.ph-airplay-bold::before {
  content: "\f64e";
}

.ph-alarm-bold::before {
  content: "\f64f";
}

.ph-alien-bold::before {
  content: "\f650";
}

.ph-align-bottom-bold::before {
  content: "\f651";
}

.ph-align-bottom-simple-bold::before {
  content: "\f652";
}

.ph-align-center-horizontal-bold::before {
  content: "\f653";
}

.ph-align-center-horizontal-simple-bold::before {
  content: "\f654";
}

.ph-align-center-vertical-bold::before {
  content: "\f655";
}

.ph-align-center-vertical-simple-bold::before {
  content: "\f656";
}

.ph-align-left-bold::before {
  content: "\f657";
}

.ph-align-left-simple-bold::before {
  content: "\f658";
}

.ph-align-right-bold::before {
  content: "\f659";
}

.ph-align-right-simple-bold::before {
  content: "\f65a";
}

.ph-align-top-bold::before {
  content: "\f65b";
}

.ph-align-top-simple-bold::before {
  content: "\f65c";
}

.ph-anchor-bold::before {
  content: "\f65d";
}

.ph-anchor-simple-bold::before {
  content: "\f65e";
}

.ph-android-logo-bold::before {
  content: "\f65f";
}

.ph-angular-logo-bold::before {
  content: "\f660";
}

.ph-aperture-bold::before {
  content: "\f661";
}

.ph-app-store-logo-bold::before {
  content: "\f662";
}

.ph-app-window-bold::before {
  content: "\f663";
}

.ph-apple-logo-bold::before {
  content: "\f664";
}

.ph-apple-podcasts-logo-bold::before {
  content: "\f665";
}

.ph-archive-bold::before {
  content: "\f666";
}

.ph-archive-box-bold::before {
  content: "\f667";
}

.ph-archive-tray-bold::before {
  content: "\f668";
}

.ph-armchair-bold::before {
  content: "\f669";
}

.ph-arrow-arc-left-bold::before {
  content: "\f66a";
}

.ph-arrow-arc-right-bold::before {
  content: "\f66b";
}

.ph-arrow-bend-double-up-left-bold::before {
  content: "\f66c";
}

.ph-arrow-bend-double-up-right-bold::before {
  content: "\f66d";
}

.ph-arrow-bend-down-left-bold::before {
  content: "\f66e";
}

.ph-arrow-bend-down-right-bold::before {
  content: "\f66f";
}

.ph-arrow-bend-left-down-bold::before {
  content: "\f670";
}

.ph-arrow-bend-left-up-bold::before {
  content: "\f671";
}

.ph-arrow-bend-right-down-bold::before {
  content: "\f672";
}

.ph-arrow-bend-right-up-bold::before {
  content: "\f673";
}

.ph-arrow-bend-up-left-bold::before {
  content: "\f674";
}

.ph-arrow-bend-up-right-bold::before {
  content: "\f675";
}

.ph-arrow-circle-down-bold::before {
  content: "\f676";
}

.ph-arrow-circle-down-left-bold::before {
  content: "\f677";
}

.ph-arrow-circle-down-right-bold::before {
  content: "\f678";
}

.ph-arrow-circle-left-bold::before {
  content: "\f679";
}

.ph-arrow-circle-right-bold::before {
  content: "\f67a";
}

.ph-arrow-circle-up-bold::before {
  content: "\f67b";
}

.ph-arrow-circle-up-left-bold::before {
  content: "\f67c";
}

.ph-arrow-circle-up-right-bold::before {
  content: "\f67d";
}

.ph-arrow-clockwise-bold::before {
  content: "\f67e";
}

.ph-arrow-counter-clockwise-bold::before {
  content: "\f67f";
}

.ph-arrow-down-bold::before {
  content: "\f680";
}

.ph-arrow-down-left-bold::before {
  content: "\f681";
}

.ph-arrow-down-right-bold::before {
  content: "\f682";
}

.ph-arrow-elbow-down-left-bold::before {
  content: "\f683";
}

.ph-arrow-elbow-down-right-bold::before {
  content: "\f684";
}

.ph-arrow-elbow-left-bold::before {
  content: "\f685";
}

.ph-arrow-elbow-left-down-bold::before {
  content: "\f686";
}

.ph-arrow-elbow-left-up-bold::before {
  content: "\f687";
}

.ph-arrow-elbow-right-bold::before {
  content: "\f688";
}

.ph-arrow-elbow-right-down-bold::before {
  content: "\f689";
}

.ph-arrow-elbow-right-up-bold::before {
  content: "\f68a";
}

.ph-arrow-elbow-up-left-bold::before {
  content: "\f68b";
}

.ph-arrow-elbow-up-right-bold::before {
  content: "\f68c";
}

.ph-arrow-fat-down-bold::before {
  content: "\f68d";
}

.ph-arrow-fat-left-bold::before {
  content: "\f68e";
}

.ph-arrow-fat-line-down-bold::before {
  content: "\f68f";
}

.ph-arrow-fat-line-left-bold::before {
  content: "\f690";
}

.ph-arrow-fat-line-right-bold::before {
  content: "\f691";
}

.ph-arrow-fat-line-up-bold::before {
  content: "\f692";
}

.ph-arrow-fat-lines-down-bold::before {
  content: "\f693";
}

.ph-arrow-fat-lines-left-bold::before {
  content: "\f694";
}

.ph-arrow-fat-lines-right-bold::before {
  content: "\f695";
}

.ph-arrow-fat-lines-up-bold::before {
  content: "\f696";
}

.ph-arrow-fat-right-bold::before {
  content: "\f697";
}

.ph-arrow-fat-up-bold::before {
  content: "\f698";
}

.ph-arrow-left-bold::before {
  content: "\f699";
}

.ph-arrow-line-down-bold::before {
  content: "\f69a";
}

.ph-arrow-line-down-left-bold::before {
  content: "\f69b";
}

.ph-arrow-line-down-right-bold::before {
  content: "\f69c";
}

.ph-arrow-line-left-bold::before {
  content: "\f69d";
}

.ph-arrow-line-right-bold::before {
  content: "\f69e";
}

.ph-arrow-line-up-bold::before {
  content: "\f69f";
}

.ph-arrow-line-up-left-bold::before {
  content: "\f6a0";
}

.ph-arrow-line-up-right-bold::before {
  content: "\f6a1";
}

.ph-arrow-right-bold::before {
  content: "\f6a2";
}

.ph-arrow-square-down-bold::before {
  content: "\f6a3";
}

.ph-arrow-square-down-left-bold::before {
  content: "\f6a4";
}

.ph-arrow-square-down-right-bold::before {
  content: "\f6a5";
}

.ph-arrow-square-in-bold::before {
  content: "\f6a6";
}

.ph-arrow-square-left-bold::before {
  content: "\f6a7";
}

.ph-arrow-square-out-bold::before {
  content: "\f6a8";
}

.ph-arrow-square-right-bold::before {
  content: "\f6a9";
}

.ph-arrow-square-up-bold::before {
  content: "\f6aa";
}

.ph-arrow-square-up-left-bold::before {
  content: "\f6ab";
}

.ph-arrow-square-up-right-bold::before {
  content: "\f6ac";
}

.ph-arrow-u-down-left-bold::before {
  content: "\f6ad";
}

.ph-arrow-u-down-right-bold::before {
  content: "\f6ae";
}

.ph-arrow-u-left-down-bold::before {
  content: "\f6af";
}

.ph-arrow-u-left-up-bold::before {
  content: "\f6b0";
}

.ph-arrow-u-right-down-bold::before {
  content: "\f6b1";
}

.ph-arrow-u-right-up-bold::before {
  content: "\f6b2";
}

.ph-arrow-u-up-left-bold::before {
  content: "\f6b3";
}

.ph-arrow-u-up-right-bold::before {
  content: "\f6b4";
}

.ph-arrow-up-bold::before {
  content: "\f6b5";
}

.ph-arrow-up-left-bold::before {
  content: "\f6b6";
}

.ph-arrow-up-right-bold::before {
  content: "\f6b7";
}

.ph-arrows-clockwise-bold::before {
  content: "\f6b8";
}

.ph-arrows-counter-clockwise-bold::before {
  content: "\f6b9";
}

.ph-arrows-down-up-bold::before {
  content: "\f6ba";
}

.ph-arrows-horizontal-bold::before {
  content: "\f6bb";
}

.ph-arrows-in-bold::before {
  content: "\f6bc";
}

.ph-arrows-in-cardinal-bold::before {
  content: "\f6bd";
}

.ph-arrows-in-line-horizontal-bold::before {
  content: "\f6be";
}

.ph-arrows-in-line-vertical-bold::before {
  content: "\f6bf";
}

.ph-arrows-in-simple-bold::before {
  content: "\f6c0";
}

.ph-arrows-left-right-bold::before {
  content: "\f6c1";
}

.ph-arrows-out-bold::before {
  content: "\f6c2";
}

.ph-arrows-out-cardinal-bold::before {
  content: "\f6c3";
}

.ph-arrows-out-line-horizontal-bold::before {
  content: "\f6c4";
}

.ph-arrows-out-line-vertical-bold::before {
  content: "\f6c5";
}

.ph-arrows-out-simple-bold::before {
  content: "\f6c6";
}

.ph-arrows-vertical-bold::before {
  content: "\f6c7";
}

.ph-article-bold::before {
  content: "\f6c8";
}

.ph-article-medium-bold::before {
  content: "\f6c9";
}

.ph-article-ny-times-bold::before {
  content: "\f6ca";
}

.ph-asterisk-bold::before {
  content: "\f6cb";
}

.ph-asterisk-simple-bold::before {
  content: "\f6cc";
}

.ph-at-bold::before {
  content: "\f6cd";
}

.ph-atom-bold::before {
  content: "\f6ce";
}

.ph-baby-bold::before {
  content: "\f6cf";
}

.ph-backpack-bold::before {
  content: "\f6d0";
}

.ph-backspace-bold::before {
  content: "\f6d1";
}

.ph-bag-bold::before {
  content: "\f6d2";
}

.ph-bag-simple-bold::before {
  content: "\f6d3";
}

.ph-balloon-bold::before {
  content: "\f6d4";
}

.ph-bandaids-bold::before {
  content: "\f6d5";
}

.ph-bank-bold::before {
  content: "\f6d6";
}

.ph-barbell-bold::before {
  content: "\f6d7";
}

.ph-barcode-bold::before {
  content: "\f6d8";
}

.ph-barricade-bold::before {
  content: "\f6d9";
}

.ph-baseball-bold::before {
  content: "\f6da";
}

.ph-basketball-bold::before {
  content: "\f6db";
}

.ph-bathtub-bold::before {
  content: "\f6dc";
}

.ph-battery-charging-bold::before {
  content: "\f6dd";
}

.ph-battery-charging-vertical-bold::before {
  content: "\f6de";
}

.ph-battery-empty-bold::before {
  content: "\f6df";
}

.ph-battery-full-bold::before {
  content: "\f6e0";
}

.ph-battery-high-bold::before {
  content: "\f6e1";
}

.ph-battery-low-bold::before {
  content: "\f6e2";
}

.ph-battery-medium-bold::before {
  content: "\f6e3";
}

.ph-battery-plus-bold::before {
  content: "\f6e4";
}

.ph-battery-warning-bold::before {
  content: "\f6e5";
}

.ph-battery-warning-vertical-bold::before {
  content: "\f6e6";
}

.ph-bed-bold::before {
  content: "\f6e7";
}

.ph-beer-bottle-bold::before {
  content: "\f6e8";
}

.ph-behance-logo-bold::before {
  content: "\f6e9";
}

.ph-bell-bold::before {
  content: "\f6ea";
}

.ph-bell-ringing-bold::before {
  content: "\f6eb";
}

.ph-bell-simple-bold::before {
  content: "\f6ec";
}

.ph-bell-simple-ringing-bold::before {
  content: "\f6ed";
}

.ph-bell-simple-slash-bold::before {
  content: "\f6ee";
}

.ph-bell-simple-z-bold::before {
  content: "\f6ef";
}

.ph-bell-slash-bold::before {
  content: "\f6f0";
}

.ph-bell-z-bold::before {
  content: "\f6f1";
}

.ph-bezier-curve-bold::before {
  content: "\f6f2";
}

.ph-bicycle-bold::before {
  content: "\f6f3";
}

.ph-binoculars-bold::before {
  content: "\f6f4";
}

.ph-bird-bold::before {
  content: "\f6f5";
}

.ph-bluetooth-bold::before {
  content: "\f6f6";
}

.ph-bluetooth-connected-bold::before {
  content: "\f6f7";
}

.ph-bluetooth-slash-bold::before {
  content: "\f6f8";
}

.ph-bluetooth-x-bold::before {
  content: "\f6f9";
}

.ph-boat-bold::before {
  content: "\f6fa";
}

.ph-book-bold::before {
  content: "\f6fb";
}

.ph-book-bookmark-bold::before {
  content: "\f6fc";
}

.ph-book-open-bold::before {
  content: "\f6fd";
}

.ph-bookmark-bold::before {
  content: "\f6fe";
}

.ph-bookmark-simple-bold::before {
  content: "\f6ff";
}

.ph-bookmarks-bold::before {
  content: "\f700";
}

.ph-bookmarks-simple-bold::before {
  content: "\f701";
}

.ph-books-bold::before {
  content: "\f702";
}

.ph-bounding-box-bold::before {
  content: "\f703";
}

.ph-brackets-angle-bold::before {
  content: "\f704";
}

.ph-brackets-curly-bold::before {
  content: "\f705";
}

.ph-brackets-round-bold::before {
  content: "\f706";
}

.ph-brackets-square-bold::before {
  content: "\f707";
}

.ph-brain-bold::before {
  content: "\f708";
}

.ph-brandy-bold::before {
  content: "\f709";
}

.ph-briefcase-bold::before {
  content: "\f70a";
}

.ph-briefcase-metal-bold::before {
  content: "\f70b";
}

.ph-broadcast-bold::before {
  content: "\f70c";
}

.ph-browser-bold::before {
  content: "\f70d";
}

.ph-browsers-bold::before {
  content: "\f70e";
}

.ph-bug-beetle-bold::before {
  content: "\f70f";
}

.ph-bug-bold::before {
  content: "\f710";
}

.ph-bug-droid-bold::before {
  content: "\f711";
}

.ph-buildings-bold::before {
  content: "\f712";
}

.ph-bus-bold::before {
  content: "\f713";
}

.ph-butterfly-bold::before {
  content: "\f714";
}

.ph-cactus-bold::before {
  content: "\f715";
}

.ph-cake-bold::before {
  content: "\f716";
}

.ph-calculator-bold::before {
  content: "\f717";
}

.ph-calendar-blank-bold::before {
  content: "\f718";
}

.ph-calendar-bold::before {
  content: "\f719";
}

.ph-calendar-check-bold::before {
  content: "\f71a";
}

.ph-calendar-plus-bold::before {
  content: "\f71b";
}

.ph-calendar-x-bold::before {
  content: "\f71c";
}

.ph-camera-bold::before {
  content: "\f71d";
}

.ph-camera-rotate-bold::before {
  content: "\f71e";
}

.ph-camera-slash-bold::before {
  content: "\f71f";
}

.ph-campfire-bold::before {
  content: "\f720";
}

.ph-car-bold::before {
  content: "\f721";
}

.ph-car-simple-bold::before {
  content: "\f722";
}

.ph-cardholder-bold::before {
  content: "\f723";
}

.ph-cards-bold::before {
  content: "\f724";
}

.ph-caret-circle-double-down-bold::before {
  content: "\f725";
}

.ph-caret-circle-double-left-bold::before {
  content: "\f726";
}

.ph-caret-circle-double-right-bold::before {
  content: "\f727";
}

.ph-caret-circle-double-up-bold::before {
  content: "\f728";
}

.ph-caret-circle-down-bold::before {
  content: "\f729";
}

.ph-caret-circle-left-bold::before {
  content: "\f72a";
}

.ph-caret-circle-right-bold::before {
  content: "\f72b";
}

.ph-caret-circle-up-bold::before {
  content: "\f72c";
}

.ph-caret-double-down-bold::before {
  content: "\f72d";
}

.ph-caret-double-left-bold::before {
  content: "\f72e";
}

.ph-caret-double-right-bold::before {
  content: "\f72f";
}

.ph-caret-double-up-bold::before {
  content: "\f730";
}

.ph-caret-down-bold::before {
  content: "\f731";
}

.ph-caret-left-bold::before {
  content: "\f732";
}

.ph-caret-right-bold::before {
  content: "\f733";
}

.ph-caret-up-bold::before {
  content: "\f734";
}

.ph-cat-bold::before {
  content: "\f735";
}

.ph-cell-signal-full-bold::before {
  content: "\f736";
}

.ph-cell-signal-high-bold::before {
  content: "\f737";
}

.ph-cell-signal-low-bold::before {
  content: "\f738";
}

.ph-cell-signal-medium-bold::before {
  content: "\f739";
}

.ph-cell-signal-none-bold::before {
  content: "\f73a";
}

.ph-cell-signal-slash-bold::before {
  content: "\f73b";
}

.ph-cell-signal-x-bold::before {
  content: "\f73c";
}

.ph-chalkboard-bold::before {
  content: "\f73d";
}

.ph-chalkboard-simple-bold::before {
  content: "\f73e";
}

.ph-chalkboard-teacher-bold::before {
  content: "\f73f";
}

.ph-chart-bar-bold::before {
  content: "\f740";
}

.ph-chart-bar-horizontal-bold::before {
  content: "\f741";
}

.ph-chart-line-bold::before {
  content: "\f742";
}

.ph-chart-line-up-bold::before {
  content: "\f743";
}

.ph-chart-pie-bold::before {
  content: "\f744";
}

.ph-chart-pie-slice-bold::before {
  content: "\f745";
}

.ph-chat-bold::before {
  content: "\f746";
}

.ph-chat-centered-bold::before {
  content: "\f747";
}

.ph-chat-centered-dots-bold::before {
  content: "\f748";
}

.ph-chat-centered-text-bold::before {
  content: "\f749";
}

.ph-chat-circle-bold::before {
  content: "\f74a";
}

.ph-chat-circle-dots-bold::before {
  content: "\f74b";
}

.ph-chat-circle-text-bold::before {
  content: "\f74c";
}

.ph-chat-dots-bold::before {
  content: "\f74d";
}

.ph-chat-teardrop-bold::before {
  content: "\f74e";
}

.ph-chat-teardrop-dots-bold::before {
  content: "\f74f";
}

.ph-chat-teardrop-text-bold::before {
  content: "\f750";
}

.ph-chat-text-bold::before {
  content: "\f751";
}

.ph-chats-bold::before {
  content: "\f752";
}

.ph-chats-circle-bold::before {
  content: "\f753";
}

.ph-chats-teardrop-bold::before {
  content: "\f754";
}

.ph-check-bold::before {
  content: "\f755";
}

.ph-check-circle-bold::before {
  content: "\f756";
}

.ph-check-square-bold::before {
  content: "\f757";
}

.ph-check-square-offset-bold::before {
  content: "\f758";
}

.ph-checks-bold::before {
  content: "\f759";
}

.ph-circle-bold::before {
  content: "\f75a";
}

.ph-circle-dashed-bold::before {
  content: "\f75b";
}

.ph-circle-half-bold::before {
  content: "\f75c";
}

.ph-circle-half-tilt-bold::before {
  content: "\f75d";
}

.ph-circle-notch-bold::before {
  content: "\f75e";
}

.ph-circle-wavy-bold::before {
  content: "\f75f";
}

.ph-circle-wavy-check-bold::before {
  content: "\f760";
}

.ph-circle-wavy-question-bold::before {
  content: "\f761";
}

.ph-circle-wavy-warning-bold::before {
  content: "\f762";
}

.ph-circles-four-bold::before {
  content: "\f763";
}

.ph-circles-three-bold::before {
  content: "\f764";
}

.ph-circles-three-plus-bold::before {
  content: "\f765";
}

.ph-clipboard-bold::before {
  content: "\f766";
}

.ph-clipboard-text-bold::before {
  content: "\f767";
}

.ph-clock-afternoon-bold::before {
  content: "\f768";
}

.ph-clock-bold::before {
  content: "\f769";
}

.ph-clock-clockwise-bold::before {
  content: "\f76a";
}

.ph-clock-counter-clockwise-bold::before {
  content: "\f76b";
}

.ph-closed-captioning-bold::before {
  content: "\f76c";
}

.ph-cloud-arrow-down-bold::before {
  content: "\f76d";
}

.ph-cloud-arrow-up-bold::before {
  content: "\f76e";
}

.ph-cloud-bold::before {
  content: "\f76f";
}

.ph-cloud-check-bold::before {
  content: "\f770";
}

.ph-cloud-fog-bold::before {
  content: "\f771";
}

.ph-cloud-lightning-bold::before {
  content: "\f772";
}

.ph-cloud-moon-bold::before {
  content: "\f773";
}

.ph-cloud-rain-bold::before {
  content: "\f774";
}

.ph-cloud-slash-bold::before {
  content: "\f775";
}

.ph-cloud-snow-bold::before {
  content: "\f776";
}

.ph-cloud-sun-bold::before {
  content: "\f777";
}

.ph-club-bold::before {
  content: "\f778";
}

.ph-coat-hanger-bold::before {
  content: "\f779";
}

.ph-code-bold::before {
  content: "\f77a";
}

.ph-code-simple-bold::before {
  content: "\f77b";
}

.ph-codepen-logo-bold::before {
  content: "\f77c";
}

.ph-codesandbox-logo-bold::before {
  content: "\f77d";
}

.ph-coffee-bold::before {
  content: "\f77e";
}

.ph-coin-bold::before {
  content: "\f77f";
}

.ph-coin-vertical-bold::before {
  content: "\f780";
}

.ph-coins-bold::before {
  content: "\f781";
}

.ph-columns-bold::before {
  content: "\f782";
}

.ph-command-bold::before {
  content: "\f783";
}

.ph-compass-bold::before {
  content: "\f784";
}

.ph-computer-tower-bold::before {
  content: "\f785";
}

.ph-confetti-bold::before {
  content: "\f786";
}

.ph-cookie-bold::before {
  content: "\f787";
}

.ph-cooking-pot-bold::before {
  content: "\f788";
}

.ph-copy-bold::before {
  content: "\f789";
}

.ph-copy-simple-bold::before {
  content: "\f78a";
}

.ph-copyleft-bold::before {
  content: "\f78b";
}

.ph-copyright-bold::before {
  content: "\f78c";
}

.ph-corners-in-bold::before {
  content: "\f78d";
}

.ph-corners-out-bold::before {
  content: "\f78e";
}

.ph-cpu-bold::before {
  content: "\f78f";
}

.ph-credit-card-bold::before {
  content: "\f790";
}

.ph-crop-bold::before {
  content: "\f791";
}

.ph-crosshair-bold::before {
  content: "\f792";
}

.ph-crosshair-simple-bold::before {
  content: "\f793";
}

.ph-crown-bold::before {
  content: "\f794";
}

.ph-crown-simple-bold::before {
  content: "\f795";
}

.ph-cube-bold::before {
  content: "\f796";
}

.ph-currency-btc-bold::before {
  content: "\f797";
}

.ph-currency-circle-dollar-bold::before {
  content: "\f798";
}

.ph-currency-cny-bold::before {
  content: "\f799";
}

.ph-currency-dollar-bold::before {
  content: "\f79a";
}

.ph-currency-dollar-simple-bold::before {
  content: "\f79b";
}

.ph-currency-eth-bold::before {
  content: "\f79c";
}

.ph-currency-eur-bold::before {
  content: "\f79d";
}

.ph-currency-gbp-bold::before {
  content: "\f79e";
}

.ph-currency-inr-bold::before {
  content: "\f79f";
}

.ph-currency-jpy-bold::before {
  content: "\f7a0";
}

.ph-currency-krw-bold::before {
  content: "\f7a1";
}

.ph-currency-kzt-bold::before {
  content: "\f7a2";
}

.ph-currency-ngn-bold::before {
  content: "\f7a3";
}

.ph-currency-rub-bold::before {
  content: "\f7a4";
}

.ph-cursor-bold::before {
  content: "\f7a5";
}

.ph-cursor-text-bold::before {
  content: "\f7a6";
}

.ph-cylinder-bold::before {
  content: "\f7a7";
}

.ph-database-bold::before {
  content: "\f7a8";
}

.ph-desktop-bold::before {
  content: "\f7a9";
}

.ph-desktop-tower-bold::before {
  content: "\f7aa";
}

.ph-detective-bold::before {
  content: "\f7ab";
}

.ph-device-mobile-bold::before {
  content: "\f7ac";
}

.ph-device-mobile-camera-bold::before {
  content: "\f7ad";
}

.ph-device-mobile-speaker-bold::before {
  content: "\f7ae";
}

.ph-device-tablet-bold::before {
  content: "\f7af";
}

.ph-device-tablet-camera-bold::before {
  content: "\f7b0";
}

.ph-device-tablet-speaker-bold::before {
  content: "\f7b1";
}

.ph-diamond-bold::before {
  content: "\f7b2";
}

.ph-diamonds-four-bold::before {
  content: "\f7b3";
}

.ph-dice-five-bold::before {
  content: "\f7b4";
}

.ph-dice-four-bold::before {
  content: "\f7b5";
}

.ph-dice-one-bold::before {
  content: "\f7b6";
}

.ph-dice-six-bold::before {
  content: "\f7b7";
}

.ph-dice-three-bold::before {
  content: "\f7b8";
}

.ph-dice-two-bold::before {
  content: "\f7b9";
}

.ph-disc-bold::before {
  content: "\f7ba";
}

.ph-discord-logo-bold::before {
  content: "\f7bb";
}

.ph-divide-bold::before {
  content: "\f7bc";
}

.ph-dog-bold::before {
  content: "\f7bd";
}

.ph-door-bold::before {
  content: "\f7be";
}

.ph-dots-nine-bold::before {
  content: "\f7bf";
}

.ph-dots-six-bold::before {
  content: "\f7c0";
}

.ph-dots-six-vertical-bold::before {
  content: "\f7c1";
}

.ph-dots-three-bold::before {
  content: "\f7c2";
}

.ph-dots-three-circle-bold::before {
  content: "\f7c3";
}

.ph-dots-three-circle-vertical-bold::before {
  content: "\f7c4";
}

.ph-dots-three-outline-bold::before {
  content: "\f7c5";
}

.ph-dots-three-outline-vertical-bold::before {
  content: "\f7c6";
}

.ph-dots-three-vertical-bold::before {
  content: "\f7c7";
}

.ph-download-bold::before {
  content: "\f7c8";
}

.ph-download-simple-bold::before {
  content: "\f7c9";
}

.ph-dribbble-logo-bold::before {
  content: "\f7ca";
}

.ph-drop-bold::before {
  content: "\f7cb";
}

.ph-drop-half-bold::before {
  content: "\f7cc";
}

.ph-drop-half-bottom-bold::before {
  content: "\f7cd";
}

.ph-ear-bold::before {
  content: "\f7ce";
}

.ph-ear-slash-bold::before {
  content: "\f7cf";
}

.ph-egg-bold::before {
  content: "\f7d0";
}

.ph-egg-crack-bold::before {
  content: "\f7d1";
}

.ph-eject-bold::before {
  content: "\f7d2";
}

.ph-eject-simple-bold::before {
  content: "\f7d3";
}

.ph-envelope-bold::before {
  content: "\f7d4";
}

.ph-envelope-open-bold::before {
  content: "\f7d5";
}

.ph-envelope-simple-bold::before {
  content: "\f7d6";
}

.ph-envelope-simple-open-bold::before {
  content: "\f7d7";
}

.ph-equalizer-bold::before {
  content: "\f7d8";
}

.ph-equals-bold::before {
  content: "\f7d9";
}

.ph-eraser-bold::before {
  content: "\f7da";
}

.ph-exam-bold::before {
  content: "\f7db";
}

.ph-export-bold::before {
  content: "\f7dc";
}

.ph-eye-bold::before {
  content: "\f7dd";
}

.ph-eye-closed-bold::before {
  content: "\f7de";
}

.ph-eye-slash-bold::before {
  content: "\f7df";
}

.ph-eyedropper-bold::before {
  content: "\f7e0";
}

.ph-eyedropper-sample-bold::before {
  content: "\f7e1";
}

.ph-eyeglasses-bold::before {
  content: "\f7e2";
}

.ph-face-mask-bold::before {
  content: "\f7e3";
}

.ph-facebook-logo-bold::before {
  content: "\f7e4";
}

.ph-factory-bold::before {
  content: "\f7e5";
}

.ph-faders-bold::before {
  content: "\f7e6";
}

.ph-faders-horizontal-bold::before {
  content: "\f7e7";
}

.ph-fast-forward-bold::before {
  content: "\f7e8";
}

.ph-fast-forward-circle-bold::before {
  content: "\f7e9";
}

.ph-figma-logo-bold::before {
  content: "\f7ea";
}

.ph-file-arrow-down-bold::before {
  content: "\f7eb";
}

.ph-file-arrow-up-bold::before {
  content: "\f7ec";
}

.ph-file-audio-bold::before {
  content: "\f7ed";
}

.ph-file-bold::before {
  content: "\f7ee";
}

.ph-file-cloud-bold::before {
  content: "\f7ef";
}

.ph-file-code-bold::before {
  content: "\f7f0";
}

.ph-file-css-bold::before {
  content: "\f7f1";
}

.ph-file-csv-bold::before {
  content: "\f7f2";
}

.ph-file-doc-bold::before {
  content: "\f7f3";
}

.ph-file-dotted-bold::before {
  content: "\f7f4";
}

.ph-file-html-bold::before {
  content: "\f7f5";
}

.ph-file-image-bold::before {
  content: "\f7f6";
}

.ph-file-jpg-bold::before {
  content: "\f7f7";
}

.ph-file-js-bold::before {
  content: "\f7f8";
}

.ph-file-jsx-bold::before {
  content: "\f7f9";
}

.ph-file-lock-bold::before {
  content: "\f7fa";
}

.ph-file-minus-bold::before {
  content: "\f7fb";
}

.ph-file-pdf-bold::before {
  content: "\f7fc";
}

.ph-file-plus-bold::before {
  content: "\f7fd";
}

.ph-file-png-bold::before {
  content: "\f7fe";
}

.ph-file-ppt-bold::before {
  content: "\f7ff";
}

.ph-file-rs-bold::before {
  content: "\f800";
}

.ph-file-search-bold::before {
  content: "\f801";
}

.ph-file-text-bold::before {
  content: "\f802";
}

.ph-file-ts-bold::before {
  content: "\f803";
}

.ph-file-tsx-bold::before {
  content: "\f804";
}

.ph-file-video-bold::before {
  content: "\f805";
}

.ph-file-vue-bold::before {
  content: "\f806";
}

.ph-file-x-bold::before {
  content: "\f807";
}

.ph-file-xls-bold::before {
  content: "\f808";
}

.ph-file-zip-bold::before {
  content: "\f809";
}

.ph-files-bold::before {
  content: "\f80a";
}

.ph-film-script-bold::before {
  content: "\f80b";
}

.ph-film-slate-bold::before {
  content: "\f80c";
}

.ph-film-strip-bold::before {
  content: "\f80d";
}

.ph-fingerprint-bold::before {
  content: "\f80e";
}

.ph-fingerprint-simple-bold::before {
  content: "\f80f";
}

.ph-finn-the-human-bold::before {
  content: "\f810";
}

.ph-fire-bold::before {
  content: "\f811";
}

.ph-fire-simple-bold::before {
  content: "\f812";
}

.ph-first-aid-bold::before {
  content: "\f813";
}

.ph-first-aid-kit-bold::before {
  content: "\f814";
}

.ph-fish-bold::before {
  content: "\f815";
}

.ph-fish-simple-bold::before {
  content: "\f816";
}

.ph-flag-banner-bold::before {
  content: "\f817";
}

.ph-flag-bold::before {
  content: "\f818";
}

.ph-flag-checkered-bold::before {
  content: "\f819";
}

.ph-flame-bold::before {
  content: "\f81a";
}

.ph-flashlight-bold::before {
  content: "\f81b";
}

.ph-flask-bold::before {
  content: "\f81c";
}

.ph-floppy-disk-back-bold::before {
  content: "\f81d";
}

.ph-floppy-disk-bold::before {
  content: "\f81e";
}

.ph-flow-arrow-bold::before {
  content: "\f81f";
}

.ph-flower-bold::before {
  content: "\f820";
}

.ph-flower-lotus-bold::before {
  content: "\f821";
}

.ph-flying-saucer-bold::before {
  content: "\f822";
}

.ph-folder-bold::before {
  content: "\f823";
}

.ph-folder-dotted-bold::before {
  content: "\f824";
}

.ph-folder-lock-bold::before {
  content: "\f825";
}

.ph-folder-minus-bold::before {
  content: "\f826";
}

.ph-folder-notch-bold::before {
  content: "\f827";
}

.ph-folder-notch-minus-bold::before {
  content: "\f828";
}

.ph-folder-notch-open-bold::before {
  content: "\f829";
}

.ph-folder-notch-plus-bold::before {
  content: "\f82a";
}

.ph-folder-open-bold::before {
  content: "\f82b";
}

.ph-folder-plus-bold::before {
  content: "\f82c";
}

.ph-folder-simple-bold::before {
  content: "\f82d";
}

.ph-folder-simple-dotted-bold::before {
  content: "\f82e";
}

.ph-folder-simple-lock-bold::before {
  content: "\f82f";
}

.ph-folder-simple-minus-bold::before {
  content: "\f830";
}

.ph-folder-simple-plus-bold::before {
  content: "\f831";
}

.ph-folder-simple-star-bold::before {
  content: "\f832";
}

.ph-folder-simple-user-bold::before {
  content: "\f833";
}

.ph-folder-star-bold::before {
  content: "\f834";
}

.ph-folder-user-bold::before {
  content: "\f835";
}

.ph-folders-bold::before {
  content: "\f836";
}

.ph-football-bold::before {
  content: "\f837";
}

.ph-fork-knife-bold::before {
  content: "\f838";
}

.ph-frame-corners-bold::before {
  content: "\f839";
}

.ph-framer-logo-bold::before {
  content: "\f83a";
}

.ph-function-bold::before {
  content: "\f83b";
}

.ph-funnel-bold::before {
  content: "\f83c";
}

.ph-funnel-simple-bold::before {
  content: "\f83d";
}

.ph-game-controller-bold::before {
  content: "\f83e";
}

.ph-gas-pump-bold::before {
  content: "\f83f";
}

.ph-gauge-bold::before {
  content: "\f840";
}

.ph-gear-bold::before {
  content: "\f841";
}

.ph-gear-six-bold::before {
  content: "\f842";
}

.ph-gender-female-bold::before {
  content: "\f843";
}

.ph-gender-intersex-bold::before {
  content: "\f844";
}

.ph-gender-male-bold::before {
  content: "\f845";
}

.ph-gender-neuter-bold::before {
  content: "\f846";
}

.ph-gender-nonbinary-bold::before {
  content: "\f847";
}

.ph-gender-transgender-bold::before {
  content: "\f848";
}

.ph-ghost-bold::before {
  content: "\f849";
}

.ph-gif-bold::before {
  content: "\f84a";
}

.ph-gift-bold::before {
  content: "\f84b";
}

.ph-git-branch-bold::before {
  content: "\f84c";
}

.ph-git-commit-bold::before {
  content: "\f84d";
}

.ph-git-diff-bold::before {
  content: "\f84e";
}

.ph-git-fork-bold::before {
  content: "\f84f";
}

.ph-git-merge-bold::before {
  content: "\f850";
}

.ph-git-pull-request-bold::before {
  content: "\f851";
}

.ph-github-logo-bold::before {
  content: "\f852";
}

.ph-gitlab-logo-bold::before {
  content: "\f853";
}

.ph-gitlab-logo-simple-bold::before {
  content: "\f854";
}

.ph-globe-bold::before {
  content: "\f855";
}

.ph-globe-hemisphere-east-bold::before {
  content: "\f856";
}

.ph-globe-hemisphere-west-bold::before {
  content: "\f857";
}

.ph-globe-simple-bold::before {
  content: "\f858";
}

.ph-globe-stand-bold::before {
  content: "\f859";
}

.ph-google-chrome-logo-bold::before {
  content: "\f85a";
}

.ph-google-logo-bold::before {
  content: "\f85b";
}

.ph-google-photos-logo-bold::before {
  content: "\f85c";
}

.ph-google-play-logo-bold::before {
  content: "\f85d";
}

.ph-google-podcasts-logo-bold::before {
  content: "\f85e";
}

.ph-gradient-bold::before {
  content: "\f85f";
}

.ph-graduation-cap-bold::before {
  content: "\f860";
}

.ph-graph-bold::before {
  content: "\f861";
}

.ph-grid-four-bold::before {
  content: "\f862";
}

.ph-hamburger-bold::before {
  content: "\f863";
}

.ph-hand-bold::before {
  content: "\f864";
}

.ph-hand-eye-bold::before {
  content: "\f865";
}

.ph-hand-fist-bold::before {
  content: "\f866";
}

.ph-hand-grabbing-bold::before {
  content: "\f867";
}

.ph-hand-palm-bold::before {
  content: "\f868";
}

.ph-hand-pointing-bold::before {
  content: "\f869";
}

.ph-hand-soap-bold::before {
  content: "\f86a";
}

.ph-hand-waving-bold::before {
  content: "\f86b";
}

.ph-handbag-bold::before {
  content: "\f86c";
}

.ph-handbag-simple-bold::before {
  content: "\f86d";
}

.ph-hands-clapping-bold::before {
  content: "\f86e";
}

.ph-handshake-bold::before {
  content: "\f86f";
}

.ph-hard-drive-bold::before {
  content: "\f870";
}

.ph-hard-drives-bold::before {
  content: "\f871";
}

.ph-hash-bold::before {
  content: "\f872";
}

.ph-hash-straight-bold::before {
  content: "\f873";
}

.ph-headlights-bold::before {
  content: "\f874";
}

.ph-headphones-bold::before {
  content: "\f875";
}

.ph-headset-bold::before {
  content: "\f876";
}

.ph-heart-bold::before {
  content: "\f877";
}

.ph-heart-break-bold::before {
  content: "\f878";
}

.ph-heart-straight-bold::before {
  content: "\f879";
}

.ph-heart-straight-break-bold::before {
  content: "\f87a";
}

.ph-heartbeat-bold::before {
  content: "\f87b";
}

.ph-hexagon-bold::before {
  content: "\f87c";
}

.ph-highlighter-circle-bold::before {
  content: "\f87d";
}

.ph-horse-bold::before {
  content: "\f87e";
}

.ph-hourglass-bold::before {
  content: "\f87f";
}

.ph-hourglass-high-bold::before {
  content: "\f880";
}

.ph-hourglass-low-bold::before {
  content: "\f881";
}

.ph-hourglass-medium-bold::before {
  content: "\f882";
}

.ph-hourglass-simple-bold::before {
  content: "\f883";
}

.ph-hourglass-simple-high-bold::before {
  content: "\f884";
}

.ph-hourglass-simple-low-bold::before {
  content: "\f885";
}

.ph-hourglass-simple-medium-bold::before {
  content: "\f886";
}

.ph-house-bold::before {
  content: "\f887";
}

.ph-house-line-bold::before {
  content: "\f888";
}

.ph-house-simple-bold::before {
  content: "\f889";
}

.ph-identification-badge-bold::before {
  content: "\f88a";
}

.ph-identification-card-bold::before {
  content: "\f88b";
}

.ph-image-bold::before {
  content: "\f88c";
}

.ph-image-square-bold::before {
  content: "\f88d";
}

.ph-infinity-bold::before {
  content: "\f88e";
}

.ph-info-bold::before {
  content: "\f88f";
}

.ph-instagram-logo-bold::before {
  content: "\f890";
}

.ph-intersect-bold::before {
  content: "\f891";
}

.ph-jeep-bold::before {
  content: "\f892";
}

.ph-kanban-bold::before {
  content: "\f893";
}

.ph-key-bold::before {
  content: "\f894";
}

.ph-key-return-bold::before {
  content: "\f895";
}

.ph-keyboard-bold::before {
  content: "\f896";
}

.ph-keyhole-bold::before {
  content: "\f897";
}

.ph-knife-bold::before {
  content: "\f898";
}

.ph-ladder-bold::before {
  content: "\f899";
}

.ph-ladder-simple-bold::before {
  content: "\f89a";
}

.ph-lamp-bold::before {
  content: "\f89b";
}

.ph-laptop-bold::before {
  content: "\f89c";
}

.ph-layout-bold::before {
  content: "\f89d";
}

.ph-leaf-bold::before {
  content: "\f89e";
}

.ph-lifebuoy-bold::before {
  content: "\f89f";
}

.ph-lightbulb-bold::before {
  content: "\f8a0";
}

.ph-lightbulb-filament-bold::before {
  content: "\f8a1";
}

.ph-lightning-bold::before {
  content: "\f8a2";
}

.ph-lightning-slash-bold::before {
  content: "\f8a3";
}

.ph-line-segment-bold::before {
  content: "\f8a4";
}

.ph-line-segments-bold::before {
  content: "\f8a5";
}

.ph-link-bold::before {
  content: "\f8a6";
}

.ph-link-break-bold::before {
  content: "\f8a7";
}

.ph-link-simple-bold::before {
  content: "\f8a8";
}

.ph-link-simple-break-bold::before {
  content: "\f8a9";
}

.ph-link-simple-horizontal-bold::before {
  content: "\f8aa";
}

.ph-link-simple-horizontal-break-bold::before {
  content: "\f8ab";
}

.ph-linkedin-logo-bold::before {
  content: "\f8ac";
}

.ph-linux-logo-bold::before {
  content: "\f8ad";
}

.ph-list-bold::before {
  content: "\f8ae";
}

.ph-list-bullets-bold::before {
  content: "\f8af";
}

.ph-list-checks-bold::before {
  content: "\f8b0";
}

.ph-list-dashes-bold::before {
  content: "\f8b1";
}

.ph-list-numbers-bold::before {
  content: "\f8b2";
}

.ph-list-plus-bold::before {
  content: "\f8b3";
}

.ph-lock-bold::before {
  content: "\f8b4";
}

.ph-lock-key-bold::before {
  content: "\f8b5";
}

.ph-lock-key-open-bold::before {
  content: "\f8b6";
}

.ph-lock-laminated-bold::before {
  content: "\f8b7";
}

.ph-lock-laminated-open-bold::before {
  content: "\f8b8";
}

.ph-lock-open-bold::before {
  content: "\f8b9";
}

.ph-lock-simple-bold::before {
  content: "\f8ba";
}

.ph-lock-simple-open-bold::before {
  content: "\f8bb";
}

.ph-magic-wand-bold::before {
  content: "\f8bc";
}

.ph-magnet-bold::before {
  content: "\f8bd";
}

.ph-magnet-straight-bold::before {
  content: "\f8be";
}

.ph-magnifying-glass-bold::before {
  content: "\f8bf";
}

.ph-magnifying-glass-minus-bold::before {
  content: "\f8c0";
}

.ph-magnifying-glass-plus-bold::before {
  content: "\f8c1";
}

.ph-map-pin-bold::before {
  content: "\f8c2";
}

.ph-map-pin-line-bold::before {
  content: "\f8c3";
}

.ph-map-trifold-bold::before {
  content: "\f8c4";
}

.ph-marker-circle-bold::before {
  content: "\f8c5";
}

.ph-martini-bold::before {
  content: "\f8c6";
}

.ph-mask-happy-bold::before {
  content: "\f8c7";
}

.ph-mask-sad-bold::before {
  content: "\f8c8";
}

.ph-math-operations-bold::before {
  content: "\f8c9";
}

.ph-medal-bold::before {
  content: "\f8ca";
}

.ph-medium-logo-bold::before {
  content: "\f8cb";
}

.ph-megaphone-bold::before {
  content: "\f8cc";
}

.ph-megaphone-simple-bold::before {
  content: "\f8cd";
}

.ph-messenger-logo-bold::before {
  content: "\f8ce";
}

.ph-microphone-bold::before {
  content: "\f8cf";
}

.ph-microphone-slash-bold::before {
  content: "\f8d0";
}

.ph-microphone-stage-bold::before {
  content: "\f8d1";
}

.ph-microsoft-excel-logo-bold::before {
  content: "\f8d2";
}

.ph-microsoft-powerpoint-logo-bold::before {
  content: "\f8d3";
}

.ph-microsoft-teams-logo-bold::before {
  content: "\f8d4";
}

.ph-microsoft-word-logo-bold::before {
  content: "\f8d5";
}

.ph-minus-bold::before {
  content: "\f8d6";
}

.ph-minus-circle-bold::before {
  content: "\f8d7";
}

.ph-money-bold::before {
  content: "\f8d8";
}

.ph-monitor-bold::before {
  content: "\f8d9";
}

.ph-monitor-play-bold::before {
  content: "\f8da";
}

.ph-moon-bold::before {
  content: "\f8db";
}

.ph-moon-stars-bold::before {
  content: "\f8dc";
}

.ph-mountains-bold::before {
  content: "\f8dd";
}

.ph-mouse-bold::before {
  content: "\f8de";
}

.ph-mouse-simple-bold::before {
  content: "\f8df";
}

.ph-music-note-bold::before {
  content: "\f8e0";
}

.ph-music-note-simple-bold::before {
  content: "\f8e1";
}

.ph-music-notes-bold::before {
  content: "\f8e2";
}

.ph-music-notes-plus-bold::before {
  content: "\f8e3";
}

.ph-music-notes-simple-bold::before {
  content: "\f8e4";
}

.ph-navigation-arrow-bold::before {
  content: "\f8e5";
}

.ph-needle-bold::before {
  content: "\f8e6";
}

.ph-newspaper-bold::before {
  content: "\f8e7";
}

.ph-newspaper-clipping-bold::before {
  content: "\f8e8";
}

.ph-note-blank-bold::before {
  content: "\f8e9";
}

.ph-note-bold::before {
  content: "\f8ea";
}

.ph-note-pencil-bold::before {
  content: "\f8eb";
}

.ph-notebook-bold::before {
  content: "\f8ec";
}

.ph-notepad-bold::before {
  content: "\f8ed";
}

.ph-notification-bold::before {
  content: "\f8ee";
}

.ph-number-circle-eight-bold::before {
  content: "\f8ef";
}

.ph-number-circle-five-bold::before {
  content: "\f8f0";
}

.ph-number-circle-four-bold::before {
  content: "\f8f1";
}

.ph-number-circle-nine-bold::before {
  content: "\f8f2";
}

.ph-number-circle-one-bold::before {
  content: "\f8f3";
}

.ph-number-circle-seven-bold::before {
  content: "\f8f4";
}

.ph-number-circle-six-bold::before {
  content: "\f8f5";
}

.ph-number-circle-three-bold::before {
  content: "\f8f6";
}

.ph-number-circle-two-bold::before {
  content: "\f8f7";
}

.ph-number-circle-zero-bold::before {
  content: "\f8f8";
}

.ph-number-eight-bold::before {
  content: "\f8f9";
}

.ph-number-five-bold::before {
  content: "\f8fa";
}

.ph-number-four-bold::before {
  content: "\f8fb";
}

.ph-number-nine-bold::before {
  content: "\f8fc";
}

.ph-number-one-bold::before {
  content: "\f8fd";
}

.ph-number-seven-bold::before {
  content: "\f8fe";
}

.ph-number-six-bold::before {
  content: "\f8ff";
}

.ph-number-square-eight-bold::before {
  content: "豈";
}

.ph-number-square-five-bold::before {
  content: "更";
}

.ph-number-square-four-bold::before {
  content: "車";
}

.ph-number-square-nine-bold::before {
  content: "賈";
}

.ph-number-square-one-bold::before {
  content: "滑";
}

.ph-number-square-seven-bold::before {
  content: "串";
}

.ph-number-square-six-bold::before {
  content: "句";
}

.ph-number-square-three-bold::before {
  content: "龜";
}

.ph-number-square-two-bold::before {
  content: "龜";
}

.ph-number-square-zero-bold::before {
  content: "契";
}

.ph-number-three-bold::before {
  content: "金";
}

.ph-number-two-bold::before {
  content: "喇";
}

.ph-number-zero-bold::before {
  content: "奈";
}

.ph-nut-bold::before {
  content: "懶";
}

.ph-ny-times-logo-bold::before {
  content: "癩";
}

.ph-octagon-bold::before {
  content: "羅";
}

.ph-option-bold::before {
  content: "蘿";
}

.ph-package-bold::before {
  content: "螺";
}

.ph-paint-brush-bold::before {
  content: "裸";
}

.ph-paint-brush-broad-bold::before {
  content: "邏";
}

.ph-paint-brush-household-bold::before {
  content: "樂";
}

.ph-paint-bucket-bold::before {
  content: "洛";
}

.ph-paint-roller-bold::before {
  content: "烙";
}

.ph-palette-bold::before {
  content: "珞";
}

.ph-paper-plane-bold::before {
  content: "落";
}

.ph-paper-plane-right-bold::before {
  content: "酪";
}

.ph-paper-plane-tilt-bold::before {
  content: "駱";
}

.ph-paperclip-bold::before {
  content: "亂";
}

.ph-paperclip-horizontal-bold::before {
  content: "卵";
}

.ph-parachute-bold::before {
  content: "欄";
}

.ph-password-bold::before {
  content: "爛";
}

.ph-path-bold::before {
  content: "蘭";
}

.ph-pause-bold::before {
  content: "鸞";
}

.ph-pause-circle-bold::before {
  content: "嵐";
}

.ph-paw-print-bold::before {
  content: "濫";
}

.ph-peace-bold::before {
  content: "藍";
}

.ph-pen-bold::before {
  content: "襤";
}

.ph-pen-nib-bold::before {
  content: "拉";
}

.ph-pen-nib-straight-bold::before {
  content: "臘";
}

.ph-pencil-bold::before {
  content: "蠟";
}

.ph-pencil-circle-bold::before {
  content: "廊";
}

.ph-pencil-line-bold::before {
  content: "朗";
}

.ph-pencil-simple-bold::before {
  content: "浪";
}

.ph-pencil-simple-line-bold::before {
  content: "狼";
}

.ph-percent-bold::before {
  content: "郎";
}

.ph-person-bold::before {
  content: "來";
}

.ph-person-simple-bold::before {
  content: "冷";
}

.ph-person-simple-run-bold::before {
  content: "勞";
}

.ph-person-simple-walk-bold::before {
  content: "擄";
}

.ph-perspective-bold::before {
  content: "櫓";
}

.ph-phone-bold::before {
  content: "爐";
}

.ph-phone-call-bold::before {
  content: "盧";
}

.ph-phone-disconnect-bold::before {
  content: "老";
}

.ph-phone-incoming-bold::before {
  content: "蘆";
}

.ph-phone-outgoing-bold::before {
  content: "虜";
}

.ph-phone-slash-bold::before {
  content: "路";
}

.ph-phone-x-bold::before {
  content: "露";
}

.ph-phosphor-logo-bold::before {
  content: "魯";
}

.ph-piano-keys-bold::before {
  content: "鷺";
}

.ph-picture-in-picture-bold::before {
  content: "碌";
}

.ph-pill-bold::before {
  content: "祿";
}

.ph-pinterest-logo-bold::before {
  content: "綠";
}

.ph-pinwheel-bold::before {
  content: "菉";
}

.ph-pizza-bold::before {
  content: "錄";
}

.ph-placeholder-bold::before {
  content: "鹿";
}

.ph-planet-bold::before {
  content: "論";
}

.ph-play-bold::before {
  content: "壟";
}

.ph-play-circle-bold::before {
  content: "弄";
}

.ph-playlist-bold::before {
  content: "籠";
}

.ph-plug-bold::before {
  content: "聾";
}

.ph-plugs-bold::before {
  content: "牢";
}

.ph-plugs-connected-bold::before {
  content: "磊";
}

.ph-plus-bold::before {
  content: "賂";
}

.ph-plus-circle-bold::before {
  content: "雷";
}

.ph-plus-minus-bold::before {
  content: "壘";
}

.ph-poker-chip-bold::before {
  content: "屢";
}

.ph-police-car-bold::before {
  content: "樓";
}

.ph-polygon-bold::before {
  content: "淚";
}

.ph-popcorn-bold::before {
  content: "漏";
}

.ph-power-bold::before {
  content: "累";
}

.ph-prescription-bold::before {
  content: "縷";
}

.ph-presentation-bold::before {
  content: "陋";
}

.ph-presentation-chart-bold::before {
  content: "勒";
}

.ph-printer-bold::before {
  content: "肋";
}

.ph-prohibit-bold::before {
  content: "凜";
}

.ph-prohibit-inset-bold::before {
  content: "凌";
}

.ph-projector-screen-bold::before {
  content: "稜";
}

.ph-projector-screen-chart-bold::before {
  content: "綾";
}

.ph-push-pin-bold::before {
  content: "菱";
}

.ph-push-pin-simple-bold::before {
  content: "陵";
}

.ph-push-pin-simple-slash-bold::before {
  content: "讀";
}

.ph-push-pin-slash-bold::before {
  content: "拏";
}

.ph-puzzle-piece-bold::before {
  content: "樂";
}

.ph-qr-code-bold::before {
  content: "諾";
}

.ph-question-bold::before {
  content: "丹";
}

.ph-queue-bold::before {
  content: "寧";
}

.ph-quotes-bold::before {
  content: "怒";
}

.ph-radical-bold::before {
  content: "率";
}

.ph-radio-bold::before {
  content: "異";
}

.ph-radio-button-bold::before {
  content: "北";
}

.ph-rainbow-bold::before {
  content: "磻";
}

.ph-rainbow-cloud-bold::before {
  content: "便";
}

.ph-receipt-bold::before {
  content: "復";
}

.ph-record-bold::before {
  content: "不";
}

.ph-rectangle-bold::before {
  content: "泌";
}

.ph-recycle-bold::before {
  content: "數";
}

.ph-reddit-logo-bold::before {
  content: "索";
}

.ph-repeat-bold::before {
  content: "參";
}

.ph-repeat-once-bold::before {
  content: "塞";
}

.ph-rewind-bold::before {
  content: "省";
}

.ph-rewind-circle-bold::before {
  content: "葉";
}

.ph-robot-bold::before {
  content: "說";
}

.ph-rocket-bold::before {
  content: "殺";
}

.ph-rocket-launch-bold::before {
  content: "辰";
}

.ph-rows-bold::before {
  content: "沈";
}

.ph-rss-bold::before {
  content: "拾";
}

.ph-rss-simple-bold::before {
  content: "若";
}

.ph-rug-bold::before {
  content: "掠";
}

.ph-ruler-bold::before {
  content: "略";
}

.ph-scales-bold::before {
  content: "亮";
}

.ph-scan-bold::before {
  content: "兩";
}

.ph-scissors-bold::before {
  content: "凉";
}

.ph-screencast-bold::before {
  content: "梁";
}

.ph-scribble-loop-bold::before {
  content: "糧";
}

.ph-scroll-bold::before {
  content: "良";
}

.ph-selection-all-bold::before {
  content: "諒";
}

.ph-selection-background-bold::before {
  content: "量";
}

.ph-selection-bold::before {
  content: "勵";
}

.ph-selection-foreground-bold::before {
  content: "呂";
}

.ph-selection-inverse-bold::before {
  content: "女";
}

.ph-selection-plus-bold::before {
  content: "廬";
}

.ph-selection-slash-bold::before {
  content: "旅";
}

.ph-share-bold::before {
  content: "濾";
}

.ph-share-network-bold::before {
  content: "礪";
}

.ph-shield-bold::before {
  content: "閭";
}

.ph-shield-check-bold::before {
  content: "驪";
}

.ph-shield-checkered-bold::before {
  content: "麗";
}

.ph-shield-chevron-bold::before {
  content: "黎";
}

.ph-shield-plus-bold::before {
  content: "力";
}

.ph-shield-slash-bold::before {
  content: "曆";
}

.ph-shield-star-bold::before {
  content: "歷";
}

.ph-shield-warning-bold::before {
  content: "轢";
}

.ph-shopping-bag-bold::before {
  content: "年";
}

.ph-shopping-bag-open-bold::before {
  content: "憐";
}

.ph-shopping-cart-bold::before {
  content: "戀";
}

.ph-shopping-cart-simple-bold::before {
  content: "撚";
}

.ph-shower-bold::before {
  content: "漣";
}

.ph-shuffle-angular-bold::before {
  content: "煉";
}

.ph-shuffle-bold::before {
  content: "璉";
}

.ph-shuffle-simple-bold::before {
  content: "秊";
}

.ph-sidebar-bold::before {
  content: "練";
}

.ph-sidebar-simple-bold::before {
  content: "聯";
}

.ph-sign-in-bold::before {
  content: "輦";
}

.ph-sign-out-bold::before {
  content: "蓮";
}

.ph-signpost-bold::before {
  content: "連";
}

.ph-sim-card-bold::before {
  content: "鍊";
}

.ph-sketch-logo-bold::before {
  content: "列";
}

.ph-skip-back-bold::before {
  content: "劣";
}

.ph-skip-back-circle-bold::before {
  content: "咽";
}

.ph-skip-forward-bold::before {
  content: "烈";
}

.ph-skip-forward-circle-bold::before {
  content: "裂";
}

.ph-skull-bold::before {
  content: "說";
}

.ph-slack-logo-bold::before {
  content: "廉";
}

.ph-sliders-bold::before {
  content: "念";
}

.ph-sliders-horizontal-bold::before {
  content: "捻";
}

.ph-smiley-blank-bold::before {
  content: "殮";
}

.ph-smiley-bold::before {
  content: "簾";
}

.ph-smiley-meh-bold::before {
  content: "獵";
}

.ph-smiley-nervous-bold::before {
  content: "令";
}

.ph-smiley-sad-bold::before {
  content: "囹";
}

.ph-smiley-sticker-bold::before {
  content: "寧";
}

.ph-smiley-wink-bold::before {
  content: "嶺";
}

.ph-smiley-x-eyes-bold::before {
  content: "怜";
}

.ph-snapchat-logo-bold::before {
  content: "玲";
}

.ph-snowflake-bold::before {
  content: "瑩";
}

.ph-soccer-ball-bold::before {
  content: "羚";
}

.ph-sort-ascending-bold::before {
  content: "聆";
}

.ph-sort-descending-bold::before {
  content: "鈴";
}

.ph-spade-bold::before {
  content: "零";
}

.ph-sparkle-bold::before {
  content: "靈";
}

.ph-speaker-high-bold::before {
  content: "領";
}

.ph-speaker-low-bold::before {
  content: "例";
}

.ph-speaker-none-bold::before {
  content: "禮";
}

.ph-speaker-simple-high-bold::before {
  content: "醴";
}

.ph-speaker-simple-low-bold::before {
  content: "隸";
}

.ph-speaker-simple-none-bold::before {
  content: "惡";
}

.ph-speaker-simple-slash-bold::before {
  content: "了";
}

.ph-speaker-simple-x-bold::before {
  content: "僚";
}

.ph-speaker-slash-bold::before {
  content: "寮";
}

.ph-speaker-x-bold::before {
  content: "尿";
}

.ph-spinner-bold::before {
  content: "料";
}

.ph-spinner-gap-bold::before {
  content: "樂";
}

.ph-spiral-bold::before {
  content: "燎";
}

.ph-spotify-logo-bold::before {
  content: "療";
}

.ph-square-bold::before {
  content: "蓼";
}

.ph-square-half-bold::before {
  content: "遼";
}

.ph-square-half-bottom-bold::before {
  content: "龍";
}

.ph-square-logo-bold::before {
  content: "暈";
}

.ph-squares-four-bold::before {
  content: "阮";
}

.ph-stack-bold::before {
  content: "劉";
}

.ph-stack-overflow-logo-bold::before {
  content: "杻";
}

.ph-stack-simple-bold::before {
  content: "柳";
}

.ph-stamp-bold::before {
  content: "流";
}

.ph-star-bold::before {
  content: "溜";
}

.ph-star-four-bold::before {
  content: "琉";
}

.ph-star-half-bold::before {
  content: "留";
}

.ph-sticker-bold::before {
  content: "硫";
}

.ph-stop-bold::before {
  content: "紐";
}

.ph-stop-circle-bold::before {
  content: "類";
}

.ph-storefront-bold::before {
  content: "六";
}

.ph-strategy-bold::before {
  content: "戮";
}

.ph-stripe-logo-bold::before {
  content: "陸";
}

.ph-student-bold::before {
  content: "倫";
}

.ph-suitcase-bold::before {
  content: "崙";
}

.ph-suitcase-simple-bold::before {
  content: "淪";
}

.ph-sun-bold::before {
  content: "輪";
}

.ph-sun-dim-bold::before {
  content: "律";
}

.ph-sun-horizon-bold::before {
  content: "慄";
}

.ph-sunglasses-bold::before {
  content: "栗";
}

.ph-swap-bold::before {
  content: "率";
}

.ph-swatches-bold::before {
  content: "隆";
}

.ph-sword-bold::before {
  content: "利";
}

.ph-syringe-bold::before {
  content: "吏";
}

.ph-t-shirt-bold::before {
  content: "履";
}

.ph-table-bold::before {
  content: "易";
}

.ph-tabs-bold::before {
  content: "李";
}

.ph-tag-bold::before {
  content: "梨";
}

.ph-tag-chevron-bold::before {
  content: "泥";
}

.ph-tag-simple-bold::before {
  content: "理";
}

.ph-target-bold::before {
  content: "痢";
}

.ph-taxi-bold::before {
  content: "罹";
}

.ph-telegram-logo-bold::before {
  content: "裏";
}

.ph-television-bold::before {
  content: "裡";
}

.ph-television-simple-bold::before {
  content: "里";
}

.ph-tennis-ball-bold::before {
  content: "離";
}

.ph-terminal-bold::before {
  content: "匿";
}

.ph-terminal-window-bold::before {
  content: "溺";
}

.ph-test-tube-bold::before {
  content: "吝";
}

.ph-text-aa-bold::before {
  content: "燐";
}

.ph-text-align-center-bold::before {
  content: "璘";
}

.ph-text-align-justify-bold::before {
  content: "藺";
}

.ph-text-align-left-bold::before {
  content: "隣";
}

.ph-text-align-right-bold::before {
  content: "鱗";
}

.ph-text-bolder-bold::before {
  content: "麟";
}

.ph-text-h-bold::before {
  content: "林";
}

.ph-text-h-five-bold::before {
  content: "淋";
}

.ph-text-h-four-bold::before {
  content: "臨";
}

.ph-text-h-one-bold::before {
  content: "立";
}

.ph-text-h-six-bold::before {
  content: "笠";
}

.ph-text-h-three-bold::before {
  content: "粒";
}

.ph-text-h-two-bold::before {
  content: "狀";
}

.ph-text-indent-bold::before {
  content: "炙";
}

.ph-text-italic-bold::before {
  content: "識";
}

.ph-text-outdent-bold::before {
  content: "什";
}

.ph-text-strikethrough-bold::before {
  content: "茶";
}

.ph-text-t-bold::before {
  content: "刺";
}

.ph-text-underline-bold::before {
  content: "切";
}

.ph-textbox-bold::before {
  content: "度";
}

.ph-thermometer-bold::before {
  content: "拓";
}

.ph-thermometer-cold-bold::before {
  content: "糖";
}

.ph-thermometer-hot-bold::before {
  content: "宅";
}

.ph-thermometer-simple-bold::before {
  content: "洞";
}

.ph-thumbs-down-bold::before {
  content: "暴";
}

.ph-thumbs-up-bold::before {
  content: "輻";
}

.ph-ticket-bold::before {
  content: "行";
}

.ph-tiktok-logo-bold::before {
  content: "降";
}

.ph-timer-bold::before {
  content: "見";
}

.ph-toggle-left-bold::before {
  content: "廓";
}

.ph-toggle-right-bold::before {
  content: "兀";
}

.ph-toilet-bold::before {
  content: "嗀";
}

.ph-toilet-paper-bold::before {
  content: "﨎";
}

.ph-tote-bold::before {
  content: "﨏";
}

.ph-tote-simple-bold::before {
  content: "塚";
}

.ph-trademark-registered-bold::before {
  content: "﨑";
}

.ph-traffic-cone-bold::before {
  content: "晴";
}

.ph-traffic-sign-bold::before {
  content: "﨓";
}

.ph-traffic-signal-bold::before {
  content: "﨔";
}

.ph-train-bold::before {
  content: "凞";
}

.ph-train-regional-bold::before {
  content: "猪";
}

.ph-train-simple-bold::before {
  content: "益";
}

.ph-translate-bold::before {
  content: "礼";
}

.ph-trash-bold::before {
  content: "神";
}

.ph-trash-simple-bold::before {
  content: "祥";
}

.ph-tray-bold::before {
  content: "福";
}

.ph-tree-bold::before {
  content: "靖";
}

.ph-tree-evergreen-bold::before {
  content: "精";
}

.ph-tree-structure-bold::before {
  content: "羽";
}

.ph-trend-down-bold::before {
  content: "﨟";
}

.ph-trend-up-bold::before {
  content: "蘒";
}

.ph-triangle-bold::before {
  content: "﨡";
}

.ph-trophy-bold::before {
  content: "諸";
}

.ph-truck-bold::before {
  content: "﨣";
}

.ph-twitch-logo-bold::before {
  content: "﨤";
}

.ph-twitter-logo-bold::before {
  content: "逸";
}

.ph-umbrella-bold::before {
  content: "都";
}

.ph-umbrella-simple-bold::before {
  content: "﨧";
}

.ph-upload-bold::before {
  content: "﨨";
}

.ph-upload-simple-bold::before {
  content: "﨩";
}

.ph-user-bold::before {
  content: "飯";
}

.ph-user-circle-bold::before {
  content: "飼";
}

.ph-user-circle-gear-bold::before {
  content: "館";
}

.ph-user-circle-minus-bold::before {
  content: "鶴";
}

.ph-user-circle-plus-bold::before {
  content: "郞";
}

.ph-user-focus-bold::before {
  content: "隷";
}

.ph-user-gear-bold::before {
  content: "侮";
}

.ph-user-list-bold::before {
  content: "僧";
}

.ph-user-minus-bold::before {
  content: "免";
}

.ph-user-plus-bold::before {
  content: "勉";
}

.ph-user-rectangle-bold::before {
  content: "勤";
}

.ph-user-square-bold::before {
  content: "卑";
}

.ph-user-switch-bold::before {
  content: "喝";
}

.ph-users-bold::before {
  content: "嘆";
}

.ph-users-four-bold::before {
  content: "器";
}

.ph-users-three-bold::before {
  content: "塀";
}

.ph-vault-bold::before {
  content: "墨";
}

.ph-vibrate-bold::before {
  content: "層";
}

.ph-video-camera-bold::before {
  content: "屮";
}

.ph-video-camera-slash-bold::before {
  content: "悔";
}

.ph-vignette-bold::before {
  content: "慨";
}

.ph-voicemail-bold::before {
  content: "憎";
}

.ph-volleyball-bold::before {
  content: "懲";
}

.ph-wall-bold::before {
  content: "敏";
}

.ph-wallet-bold::before {
  content: "既";
}

.ph-warning-bold::before {
  content: "暑";
}

.ph-warning-circle-bold::before {
  content: "梅";
}

.ph-warning-octagon-bold::before {
  content: "海";
}

.ph-watch-bold::before {
  content: "渚";
}

.ph-wave-sawtooth-bold::before {
  content: "漢";
}

.ph-wave-sine-bold::before {
  content: "煮";
}

.ph-wave-square-bold::before {
  content: "爫";
}

.ph-wave-triangle-bold::before {
  content: "琢";
}

.ph-waves-bold::before {
  content: "碑";
}

.ph-webcam-bold::before {
  content: "社";
}

.ph-whatsapp-logo-bold::before {
  content: "祉";
}

.ph-wheelchair-bold::before {
  content: "祈";
}

.ph-wifi-high-bold::before {
  content: "祐";
}

.ph-wifi-low-bold::before {
  content: "祖";
}

.ph-wifi-medium-bold::before {
  content: "祝";
}

.ph-wifi-none-bold::before {
  content: "禍";
}

.ph-wifi-slash-bold::before {
  content: "禎";
}

.ph-wifi-x-bold::before {
  content: "穀";
}

.ph-wind-bold::before {
  content: "突";
}

.ph-windows-logo-bold::before {
  content: "節";
}

.ph-wine-bold::before {
  content: "練";
}

.ph-wrench-bold::before {
  content: "縉";
}

.ph-x-bold::before {
  content: "繁";
}

.ph-x-circle-bold::before {
  content: "署";
}

.ph-x-square-bold::before {
  content: "者";
}

.ph-yin-yang-bold::before {
  content: "臭";
}

.ph-youtube-logo-bold::before {
  content: "艹";
}

.ph-activity-fill::before {
  content: "艹";
}

.ph-address-book-fill::before {
  content: "著";
}

.ph-airplane-fill::before {
  content: "褐";
}

.ph-airplane-in-flight-fill::before {
  content: "視";
}

.ph-airplane-landing-fill::before {
  content: "謁";
}

.ph-airplane-takeoff-fill::before {
  content: "謹";
}

.ph-airplane-tilt-fill::before {
  content: "賓";
}

.ph-airplay-fill::before {
  content: "贈";
}

.ph-alarm-fill::before {
  content: "辶";
}

.ph-alien-fill::before {
  content: "逸";
}

.ph-align-bottom-fill::before {
  content: "難";
}

.ph-align-bottom-simple-fill::before {
  content: "響";
}

.ph-align-center-horizontal-fill::before {
  content: "頻";
}

.ph-align-center-horizontal-simple-fill::before {
  content: "恵";
}

.ph-align-center-vertical-fill::before {
  content: "𤋮";
}

.ph-align-center-vertical-simple-fill::before {
  content: "舘";
}

.ph-align-left-fill::before {
  content: "﩮";
}

.ph-align-left-simple-fill::before {
  content: "﩯";
}

.ph-align-right-fill::before {
  content: "並";
}

.ph-align-right-simple-fill::before {
  content: "况";
}

.ph-align-top-fill::before {
  content: "全";
}

.ph-align-top-simple-fill::before {
  content: "侀";
}

.ph-anchor-fill::before {
  content: "充";
}

.ph-anchor-simple-fill::before {
  content: "冀";
}

.ph-android-logo-fill::before {
  content: "勇";
}

.ph-angular-logo-fill::before {
  content: "勺";
}

.ph-aperture-fill::before {
  content: "喝";
}

.ph-app-store-logo-fill::before {
  content: "啕";
}

.ph-app-window-fill::before {
  content: "喙";
}

.ph-apple-logo-fill::before {
  content: "嗢";
}

.ph-apple-podcasts-logo-fill::before {
  content: "塚";
}

.ph-archive-box-fill::before {
  content: "墳";
}

.ph-archive-fill::before {
  content: "奄";
}

.ph-archive-tray-fill::before {
  content: "奔";
}

.ph-armchair-fill::before {
  content: "婢";
}

.ph-arrow-arc-left-fill::before {
  content: "嬨";
}

.ph-arrow-arc-right-fill::before {
  content: "廒";
}

.ph-arrow-bend-double-up-left-fill::before {
  content: "廙";
}

.ph-arrow-bend-double-up-right-fill::before {
  content: "彩";
}

.ph-arrow-bend-down-left-fill::before {
  content: "徭";
}

.ph-arrow-bend-down-right-fill::before {
  content: "惘";
}

.ph-arrow-bend-left-down-fill::before {
  content: "慎";
}

.ph-arrow-bend-left-up-fill::before {
  content: "愈";
}

.ph-arrow-bend-right-down-fill::before {
  content: "憎";
}

.ph-arrow-bend-right-up-fill::before {
  content: "慠";
}

.ph-arrow-bend-up-left-fill::before {
  content: "懲";
}

.ph-arrow-bend-up-right-fill::before {
  content: "戴";
}

.ph-arrow-circle-down-fill::before {
  content: "揄";
}

.ph-arrow-circle-down-left-fill::before {
  content: "搜";
}

.ph-arrow-circle-down-right-fill::before {
  content: "摒";
}

.ph-arrow-circle-left-fill::before {
  content: "敖";
}

.ph-arrow-circle-right-fill::before {
  content: "晴";
}

.ph-arrow-circle-up-fill::before {
  content: "朗";
}

.ph-arrow-circle-up-left-fill::before {
  content: "望";
}

.ph-arrow-circle-up-right-fill::before {
  content: "杖";
}

.ph-arrow-clockwise-fill::before {
  content: "歹";
}

.ph-arrow-counter-clockwise-fill::before {
  content: "殺";
}

.ph-arrow-down-fill::before {
  content: "流";
}

.ph-arrow-down-left-fill::before {
  content: "滛";
}

.ph-arrow-down-right-fill::before {
  content: "滋";
}

.ph-arrow-elbow-down-left-fill::before {
  content: "漢";
}

.ph-arrow-elbow-down-right-fill::before {
  content: "瀞";
}

.ph-arrow-elbow-left-down-fill::before {
  content: "煮";
}

.ph-arrow-elbow-left-fill::before {
  content: "瞧";
}

.ph-arrow-elbow-left-up-fill::before {
  content: "爵";
}

.ph-arrow-elbow-right-down-fill::before {
  content: "犯";
}

.ph-arrow-elbow-right-fill::before {
  content: "猪";
}

.ph-arrow-elbow-right-up-fill::before {
  content: "瑱";
}

.ph-arrow-elbow-up-left-fill::before {
  content: "甆";
}

.ph-arrow-elbow-up-right-fill::before {
  content: "画";
}

.ph-arrow-fat-down-fill::before {
  content: "瘝";
}

.ph-arrow-fat-left-fill::before {
  content: "瘟";
}

.ph-arrow-fat-line-down-fill::before {
  content: "益";
}

.ph-arrow-fat-line-left-fill::before {
  content: "盛";
}

.ph-arrow-fat-line-right-fill::before {
  content: "直";
}

.ph-arrow-fat-line-up-fill::before {
  content: "睊";
}

.ph-arrow-fat-lines-down-fill::before {
  content: "着";
}

.ph-arrow-fat-lines-left-fill::before {
  content: "磌";
}

.ph-arrow-fat-lines-right-fill::before {
  content: "窱";
}

.ph-arrow-fat-lines-up-fill::before {
  content: "節";
}

.ph-arrow-fat-right-fill::before {
  content: "类";
}

.ph-arrow-fat-up-fill::before {
  content: "絛";
}

.ph-arrow-left-fill::before {
  content: "練";
}

.ph-arrow-line-down-fill::before {
  content: "缾";
}

.ph-arrow-line-down-left-fill::before {
  content: "者";
}

.ph-arrow-line-down-right-fill::before {
  content: "荒";
}

.ph-arrow-line-left-fill::before {
  content: "華";
}

.ph-arrow-line-right-fill::before {
  content: "蝹";
}

.ph-arrow-line-up-fill::before {
  content: "襁";
}

.ph-arrow-line-up-left-fill::before {
  content: "覆";
}

.ph-arrow-line-up-right-fill::before {
  content: "視";
}

.ph-arrow-right-fill::before {
  content: "調";
}

.ph-arrow-square-down-fill::before {
  content: "諸";
}

.ph-arrow-square-down-left-fill::before {
  content: "請";
}

.ph-arrow-square-down-right-fill::before {
  content: "謁";
}

.ph-arrow-square-in-fill::before {
  content: "諾";
}

.ph-arrow-square-left-fill::before {
  content: "諭";
}

.ph-arrow-square-out-fill::before {
  content: "謹";
}

.ph-arrow-square-right-fill::before {
  content: "變";
}

.ph-arrow-square-up-fill::before {
  content: "贈";
}

.ph-arrow-square-up-left-fill::before {
  content: "輸";
}

.ph-arrow-square-up-right-fill::before {
  content: "遲";
}

.ph-arrow-u-down-left-fill::before {
  content: "醙";
}

.ph-arrow-u-down-right-fill::before {
  content: "鉶";
}

.ph-arrow-u-left-down-fill::before {
  content: "陼";
}

.ph-arrow-u-left-up-fill::before {
  content: "難";
}

.ph-arrow-u-right-down-fill::before {
  content: "靖";
}

.ph-arrow-u-right-up-fill::before {
  content: "韛";
}

.ph-arrow-u-up-left-fill::before {
  content: "響";
}

.ph-arrow-u-up-right-fill::before {
  content: "頋";
}

.ph-arrow-up-fill::before {
  content: "頻";
}

.ph-arrow-up-left-fill::before {
  content: "鬒";
}

.ph-arrow-up-right-fill::before {
  content: "龜";
}

.ph-arrows-clockwise-fill::before {
  content: "𢡊";
}

.ph-arrows-counter-clockwise-fill::before {
  content: "𢡄";
}

.ph-arrows-down-up-fill::before {
  content: "𣏕";
}

.ph-arrows-horizontal-fill::before {
  content: "㮝";
}

.ph-arrows-in-cardinal-fill::before {
  content: "䀘";
}

.ph-arrows-in-fill::before {
  content: "䀹";
}

.ph-arrows-in-line-horizontal-fill::before {
  content: "𥉉";
}

.ph-arrows-in-line-vertical-fill::before {
  content: "𥳐";
}

.ph-arrows-in-simple-fill::before {
  content: "𧻓";
}

.ph-arrows-left-right-fill::before {
  content: "齃";
}

.ph-arrows-out-cardinal-fill::before {
  content: "龎";
}

.ph-arrows-out-fill::before {
  content: "﫚";
}

.ph-arrows-out-line-horizontal-fill::before {
  content: "﫛";
}

.ph-arrows-out-line-vertical-fill::before {
  content: "﫜";
}

.ph-arrows-out-simple-fill::before {
  content: "﫝";
}

.ph-arrows-vertical-fill::before {
  content: "﫞";
}

.ph-article-fill::before {
  content: "﫟";
}

.ph-article-medium-fill::before {
  content: "﫠";
}

.ph-article-ny-times-fill::before {
  content: "﫡";
}

.ph-asterisk-fill::before {
  content: "﫢";
}

.ph-asterisk-simple-fill::before {
  content: "﫣";
}

.ph-at-fill::before {
  content: "﫤";
}

.ph-atom-fill::before {
  content: "﫥";
}

.ph-baby-fill::before {
  content: "﫦";
}

.ph-backpack-fill::before {
  content: "﫧";
}

.ph-backspace-fill::before {
  content: "﫨";
}

.ph-bag-fill::before {
  content: "﫩";
}

.ph-bag-simple-fill::before {
  content: "﫪";
}

.ph-balloon-fill::before {
  content: "﫫";
}

.ph-bandaids-fill::before {
  content: "﫬";
}

.ph-bank-fill::before {
  content: "﫭";
}

.ph-barbell-fill::before {
  content: "﫮";
}

.ph-barcode-fill::before {
  content: "﫯";
}

.ph-barricade-fill::before {
  content: "﫰";
}

.ph-baseball-fill::before {
  content: "﫱";
}

.ph-basketball-fill::before {
  content: "﫲";
}

.ph-bathtub-fill::before {
  content: "﫳";
}

.ph-battery-charging-fill::before {
  content: "﫴";
}

.ph-battery-charging-vertical-fill::before {
  content: "﫵";
}

.ph-battery-empty-fill::before {
  content: "﫶";
}

.ph-battery-full-fill::before {
  content: "﫷";
}

.ph-battery-high-fill::before {
  content: "﫸";
}

.ph-battery-low-fill::before {
  content: "﫹";
}

.ph-battery-medium-fill::before {
  content: "﫺";
}

.ph-battery-plus-fill::before {
  content: "﫻";
}

.ph-battery-warning-fill::before {
  content: "﫼";
}

.ph-battery-warning-vertical-fill::before {
  content: "﫽";
}

.ph-bed-fill::before {
  content: "﫾";
}

.ph-beer-bottle-fill::before {
  content: "﫿";
}

.ph-behance-logo-fill::before {
  content: "ﬀ";
}

.ph-bell-fill::before {
  content: "ﬁ";
}

.ph-bell-ringing-fill::before {
  content: "ﬂ";
}

.ph-bell-simple-fill::before {
  content: "ﬃ";
}

.ph-bell-simple-ringing-fill::before {
  content: "ﬄ";
}

.ph-bell-simple-slash-fill::before {
  content: "ﬅ";
}

.ph-bell-simple-z-fill::before {
  content: "ﬆ";
}

.ph-bell-slash-fill::before {
  content: "﬇";
}

.ph-bell-z-fill::before {
  content: "﬈";
}

.ph-bezier-curve-fill::before {
  content: "﬉";
}

.ph-bicycle-fill::before {
  content: "﬊";
}

.ph-binoculars-fill::before {
  content: "﬋";
}

.ph-bird-fill::before {
  content: "﬌";
}

.ph-bluetooth-connected-fill::before {
  content: "﬍";
}

.ph-bluetooth-fill::before {
  content: "﬎";
}

.ph-bluetooth-slash-fill::before {
  content: "﬏";
}

.ph-bluetooth-x-fill::before {
  content: "﬐";
}

.ph-boat-fill::before {
  content: "﬑";
}

.ph-book-bookmark-fill::before {
  content: "﬒";
}

.ph-book-fill::before {
  content: "ﬓ";
}

.ph-book-open-fill::before {
  content: "ﬔ";
}

.ph-bookmark-fill::before {
  content: "ﬕ";
}

.ph-bookmark-simple-fill::before {
  content: "ﬖ";
}

.ph-bookmarks-fill::before {
  content: "ﬗ";
}

.ph-bookmarks-simple-fill::before {
  content: "﬘";
}

.ph-books-fill::before {
  content: "﬙";
}

.ph-bounding-box-fill::before {
  content: "﬚";
}

.ph-brackets-angle-fill::before {
  content: "﬛";
}

.ph-brackets-curly-fill::before {
  content: "﬜";
}

.ph-brackets-round-fill::before {
  content: "יִ";
}

.ph-brackets-square-fill::before {
  content: "ﬞ";
}

.ph-brain-fill::before {
  content: "ײַ";
}

.ph-brandy-fill::before {
  content: "ﬠ";
}

.ph-briefcase-fill::before {
  content: "ﬡ";
}

.ph-briefcase-metal-fill::before {
  content: "ﬢ";
}

.ph-broadcast-fill::before {
  content: "ﬣ";
}

.ph-browser-fill::before {
  content: "ﬤ";
}

.ph-browsers-fill::before {
  content: "ﬥ";
}

.ph-bug-beetle-fill::before {
  content: "ﬦ";
}

.ph-bug-droid-fill::before {
  content: "ﬧ";
}

.ph-bug-fill::before {
  content: "ﬨ";
}

.ph-buildings-fill::before {
  content: "﬩";
}

.ph-bus-fill::before {
  content: "שׁ";
}

.ph-butterfly-fill::before {
  content: "שׂ";
}

.ph-cactus-fill::before {
  content: "שּׁ";
}

.ph-cake-fill::before {
  content: "שּׂ";
}

.ph-calculator-fill::before {
  content: "אַ";
}

.ph-calendar-blank-fill::before {
  content: "אָ";
}

.ph-calendar-check-fill::before {
  content: "אּ";
}

.ph-calendar-fill::before {
  content: "בּ";
}

.ph-calendar-plus-fill::before {
  content: "גּ";
}

.ph-calendar-x-fill::before {
  content: "דּ";
}

.ph-camera-fill::before {
  content: "הּ";
}

.ph-camera-rotate-fill::before {
  content: "וּ";
}

.ph-camera-slash-fill::before {
  content: "זּ";
}

.ph-campfire-fill::before {
  content: "﬷";
}

.ph-car-fill::before {
  content: "טּ";
}

.ph-car-simple-fill::before {
  content: "יּ";
}

.ph-cardholder-fill::before {
  content: "ךּ";
}

.ph-cards-fill::before {
  content: "כּ";
}

.ph-caret-circle-double-down-fill::before {
  content: "לּ";
}

.ph-caret-circle-double-left-fill::before {
  content: "﬽";
}

.ph-caret-circle-double-right-fill::before {
  content: "מּ";
}

.ph-caret-circle-double-up-fill::before {
  content: "﬿";
}

.ph-caret-circle-down-fill::before {
  content: "נּ";
}

.ph-caret-circle-left-fill::before {
  content: "סּ";
}

.ph-caret-circle-right-fill::before {
  content: "﭂";
}

.ph-caret-circle-up-fill::before {
  content: "ףּ";
}

.ph-caret-double-down-fill::before {
  content: "פּ";
}

.ph-caret-double-left-fill::before {
  content: "﭅";
}

.ph-caret-double-right-fill::before {
  content: "צּ";
}

.ph-caret-double-up-fill::before {
  content: "קּ";
}

.ph-caret-down-fill::before {
  content: "רּ";
}

.ph-caret-left-fill::before {
  content: "שּ";
}

.ph-caret-right-fill::before {
  content: "תּ";
}

.ph-caret-up-fill::before {
  content: "וֹ";
}

.ph-cat-fill::before {
  content: "בֿ";
}

.ph-cell-signal-full-fill::before {
  content: "כֿ";
}

.ph-cell-signal-high-fill::before {
  content: "פֿ";
}

.ph-cell-signal-low-fill::before {
  content: "ﭏ";
}

.ph-cell-signal-medium-fill::before {
  content: "ﭐ";
}

.ph-cell-signal-none-fill::before {
  content: "ﭑ";
}

.ph-cell-signal-slash-fill::before {
  content: "ﭒ";
}

.ph-cell-signal-x-fill::before {
  content: "ﭓ";
}

.ph-chalkboard-fill::before {
  content: "ﭔ";
}

.ph-chalkboard-simple-fill::before {
  content: "ﭕ";
}

.ph-chalkboard-teacher-fill::before {
  content: "ﭖ";
}

.ph-chart-bar-fill::before {
  content: "ﭗ";
}

.ph-chart-bar-horizontal-fill::before {
  content: "ﭘ";
}

.ph-chart-line-fill::before {
  content: "ﭙ";
}

.ph-chart-line-up-fill::before {
  content: "ﭚ";
}

.ph-chart-pie-fill::before {
  content: "ﭛ";
}

.ph-chart-pie-slice-fill::before {
  content: "ﭜ";
}

.ph-chat-centered-dots-fill::before {
  content: "ﭝ";
}

.ph-chat-centered-fill::before {
  content: "ﭞ";
}

.ph-chat-centered-text-fill::before {
  content: "ﭟ";
}

.ph-chat-circle-dots-fill::before {
  content: "ﭠ";
}

.ph-chat-circle-fill::before {
  content: "ﭡ";
}

.ph-chat-circle-text-fill::before {
  content: "ﭢ";
}

.ph-chat-dots-fill::before {
  content: "ﭣ";
}

.ph-chat-fill::before {
  content: "ﭤ";
}

.ph-chat-teardrop-dots-fill::before {
  content: "ﭥ";
}

.ph-chat-teardrop-fill::before {
  content: "ﭦ";
}

.ph-chat-teardrop-text-fill::before {
  content: "ﭧ";
}

.ph-chat-text-fill::before {
  content: "ﭨ";
}

.ph-chats-circle-fill::before {
  content: "ﭩ";
}

.ph-chats-fill::before {
  content: "ﭪ";
}

.ph-chats-teardrop-fill::before {
  content: "ﭫ";
}

.ph-check-circle-fill::before {
  content: "ﭬ";
}

.ph-check-fill::before {
  content: "ﭭ";
}

.ph-check-square-fill::before {
  content: "ﭮ";
}

.ph-check-square-offset-fill::before {
  content: "ﭯ";
}

.ph-checks-fill::before {
  content: "ﭰ";
}

.ph-circle-dashed-fill::before {
  content: "ﭱ";
}

.ph-circle-fill::before {
  content: "ﭲ";
}

.ph-circle-half-fill::before {
  content: "ﭳ";
}

.ph-circle-half-tilt-fill::before {
  content: "ﭴ";
}

.ph-circle-notch-fill::before {
  content: "ﭵ";
}

.ph-circle-wavy-check-fill::before {
  content: "ﭶ";
}

.ph-circle-wavy-fill::before {
  content: "ﭷ";
}

.ph-circle-wavy-question-fill::before {
  content: "ﭸ";
}

.ph-circle-wavy-warning-fill::before {
  content: "ﭹ";
}

.ph-circles-four-fill::before {
  content: "ﭺ";
}

.ph-circles-three-fill::before {
  content: "ﭻ";
}

.ph-circles-three-plus-fill::before {
  content: "ﭼ";
}

.ph-clipboard-fill::before {
  content: "ﭽ";
}

.ph-clipboard-text-fill::before {
  content: "ﭾ";
}

.ph-clock-afternoon-fill::before {
  content: "ﭿ";
}

.ph-clock-clockwise-fill::before {
  content: "ﮀ";
}

.ph-clock-counter-clockwise-fill::before {
  content: "ﮁ";
}

.ph-clock-fill::before {
  content: "ﮂ";
}

.ph-closed-captioning-fill::before {
  content: "ﮃ";
}

.ph-cloud-arrow-down-fill::before {
  content: "ﮄ";
}

.ph-cloud-arrow-up-fill::before {
  content: "ﮅ";
}

.ph-cloud-check-fill::before {
  content: "ﮆ";
}

.ph-cloud-fill::before {
  content: "ﮇ";
}

.ph-cloud-fog-fill::before {
  content: "ﮈ";
}

.ph-cloud-lightning-fill::before {
  content: "ﮉ";
}

.ph-cloud-moon-fill::before {
  content: "ﮊ";
}

.ph-cloud-rain-fill::before {
  content: "ﮋ";
}

.ph-cloud-slash-fill::before {
  content: "ﮌ";
}

.ph-cloud-snow-fill::before {
  content: "ﮍ";
}

.ph-cloud-sun-fill::before {
  content: "ﮎ";
}

.ph-club-fill::before {
  content: "ﮏ";
}

.ph-coat-hanger-fill::before {
  content: "ﮐ";
}

.ph-code-fill::before {
  content: "ﮑ";
}

.ph-code-simple-fill::before {
  content: "ﮒ";
}

.ph-codepen-logo-fill::before {
  content: "ﮓ";
}

.ph-codesandbox-logo-fill::before {
  content: "ﮔ";
}

.ph-coffee-fill::before {
  content: "ﮕ";
}

.ph-coin-fill::before {
  content: "ﮖ";
}

.ph-coin-vertical-fill::before {
  content: "ﮗ";
}

.ph-coins-fill::before {
  content: "ﮘ";
}

.ph-columns-fill::before {
  content: "ﮙ";
}

.ph-command-fill::before {
  content: "ﮚ";
}

.ph-compass-fill::before {
  content: "ﮛ";
}

.ph-computer-tower-fill::before {
  content: "ﮜ";
}

.ph-confetti-fill::before {
  content: "ﮝ";
}

.ph-cookie-fill::before {
  content: "ﮞ";
}

.ph-cooking-pot-fill::before {
  content: "ﮟ";
}

.ph-copy-fill::before {
  content: "ﮠ";
}

.ph-copy-simple-fill::before {
  content: "ﮡ";
}

.ph-copyleft-fill::before {
  content: "ﮢ";
}

.ph-copyright-fill::before {
  content: "ﮣ";
}

.ph-corners-in-fill::before {
  content: "ﮤ";
}

.ph-corners-out-fill::before {
  content: "ﮥ";
}

.ph-cpu-fill::before {
  content: "ﮦ";
}

.ph-credit-card-fill::before {
  content: "ﮧ";
}

.ph-crop-fill::before {
  content: "ﮨ";
}

.ph-crosshair-fill::before {
  content: "ﮩ";
}

.ph-crosshair-simple-fill::before {
  content: "ﮪ";
}

.ph-crown-fill::before {
  content: "ﮫ";
}

.ph-crown-simple-fill::before {
  content: "ﮬ";
}

.ph-cube-fill::before {
  content: "ﮭ";
}

.ph-currency-btc-fill::before {
  content: "ﮮ";
}

.ph-currency-circle-dollar-fill::before {
  content: "ﮯ";
}

.ph-currency-cny-fill::before {
  content: "ﮰ";
}

.ph-currency-dollar-fill::before {
  content: "ﮱ";
}

.ph-currency-dollar-simple-fill::before {
  content: "﮲";
}

.ph-currency-eth-fill::before {
  content: "﮳";
}

.ph-currency-eur-fill::before {
  content: "﮴";
}

.ph-currency-gbp-fill::before {
  content: "﮵";
}

.ph-currency-inr-fill::before {
  content: "﮶";
}

.ph-currency-jpy-fill::before {
  content: "﮷";
}

.ph-currency-krw-fill::before {
  content: "﮸";
}

.ph-currency-kzt-fill::before {
  content: "﮹";
}

.ph-currency-ngn-fill::before {
  content: "﮺";
}

.ph-currency-rub-fill::before {
  content: "﮻";
}

.ph-cursor-fill::before {
  content: "﮼";
}

.ph-cursor-text-fill::before {
  content: "﮽";
}

.ph-cylinder-fill::before {
  content: "﮾";
}

.ph-database-fill::before {
  content: "﮿";
}

.ph-desktop-fill::before {
  content: "﯀";
}

.ph-desktop-tower-fill::before {
  content: "﯁";
}

.ph-detective-fill::before {
  content: "﯂";
}

.ph-device-mobile-camera-fill::before {
  content: "﯃";
}

.ph-device-mobile-fill::before {
  content: "﯄";
}

.ph-device-mobile-speaker-fill::before {
  content: "﯅";
}

.ph-device-tablet-camera-fill::before {
  content: "﯆";
}

.ph-device-tablet-fill::before {
  content: "﯇";
}

.ph-device-tablet-speaker-fill::before {
  content: "﯈";
}

.ph-diamond-fill::before {
  content: "﯉";
}

.ph-diamonds-four-fill::before {
  content: "﯊";
}

.ph-dice-five-fill::before {
  content: "﯋";
}

.ph-dice-four-fill::before {
  content: "﯌";
}

.ph-dice-one-fill::before {
  content: "﯍";
}

.ph-dice-six-fill::before {
  content: "﯎";
}

.ph-dice-three-fill::before {
  content: "﯏";
}

.ph-dice-two-fill::before {
  content: "﯐";
}

.ph-disc-fill::before {
  content: "﯑";
}

.ph-discord-logo-fill::before {
  content: "﯒";
}

.ph-divide-fill::before {
  content: "ﯓ";
}

.ph-dog-fill::before {
  content: "ﯔ";
}

.ph-door-fill::before {
  content: "ﯕ";
}

.ph-dots-nine-fill::before {
  content: "ﯖ";
}

.ph-dots-six-fill::before {
  content: "ﯗ";
}

.ph-dots-six-vertical-fill::before {
  content: "ﯘ";
}

.ph-dots-three-circle-fill::before {
  content: "ﯙ";
}

.ph-dots-three-circle-vertical-fill::before {
  content: "ﯚ";
}

.ph-dots-three-fill::before {
  content: "ﯛ";
}

.ph-dots-three-outline-fill::before {
  content: "ﯜ";
}

.ph-dots-three-outline-vertical-fill::before {
  content: "ﯝ";
}

.ph-dots-three-vertical-fill::before {
  content: "ﯞ";
}

.ph-download-fill::before {
  content: "ﯟ";
}

.ph-download-simple-fill::before {
  content: "ﯠ";
}

.ph-dribbble-logo-fill::before {
  content: "ﯡ";
}

.ph-drop-fill::before {
  content: "ﯢ";
}

.ph-drop-half-bottom-fill::before {
  content: "ﯣ";
}

.ph-drop-half-fill::before {
  content: "ﯤ";
}

.ph-ear-fill::before {
  content: "ﯥ";
}

.ph-ear-slash-fill::before {
  content: "ﯦ";
}

.ph-egg-crack-fill::before {
  content: "ﯧ";
}

.ph-egg-fill::before {
  content: "ﯨ";
}

.ph-eject-fill::before {
  content: "ﯩ";
}

.ph-eject-simple-fill::before {
  content: "ﯪ";
}

.ph-envelope-fill::before {
  content: "ﯫ";
}

.ph-envelope-open-fill::before {
  content: "ﯬ";
}

.ph-envelope-simple-fill::before {
  content: "ﯭ";
}

.ph-envelope-simple-open-fill::before {
  content: "ﯮ";
}

.ph-equalizer-fill::before {
  content: "ﯯ";
}

.ph-equals-fill::before {
  content: "ﯰ";
}

.ph-eraser-fill::before {
  content: "ﯱ";
}

.ph-exam-fill::before {
  content: "ﯲ";
}

.ph-export-fill::before {
  content: "ﯳ";
}

.ph-eye-closed-fill::before {
  content: "ﯴ";
}

.ph-eye-fill::before {
  content: "ﯵ";
}

.ph-eye-slash-fill::before {
  content: "ﯶ";
}

.ph-eyedropper-fill::before {
  content: "ﯷ";
}

.ph-eyedropper-sample-fill::before {
  content: "ﯸ";
}

.ph-eyeglasses-fill::before {
  content: "ﯹ";
}

.ph-face-mask-fill::before {
  content: "ﯺ";
}

.ph-facebook-logo-fill::before {
  content: "ﯻ";
}

.ph-factory-fill::before {
  content: "ﯼ";
}

.ph-faders-fill::before {
  content: "ﯽ";
}

.ph-faders-horizontal-fill::before {
  content: "ﯾ";
}

.ph-fast-forward-circle-fill::before {
  content: "ﯿ";
}

.ph-fast-forward-fill::before {
  content: "ﰀ";
}

.ph-figma-logo-fill::before {
  content: "ﰁ";
}

.ph-file-arrow-down-fill::before {
  content: "ﰂ";
}

.ph-file-arrow-up-fill::before {
  content: "ﰃ";
}

.ph-file-audio-fill::before {
  content: "ﰄ";
}

.ph-file-cloud-fill::before {
  content: "ﰅ";
}

.ph-file-code-fill::before {
  content: "ﰆ";
}

.ph-file-css-fill::before {
  content: "ﰇ";
}

.ph-file-csv-fill::before {
  content: "ﰈ";
}

.ph-file-doc-fill::before {
  content: "ﰉ";
}

.ph-file-dotted-fill::before {
  content: "ﰊ";
}

.ph-file-fill::before {
  content: "ﰋ";
}

.ph-file-html-fill::before {
  content: "ﰌ";
}

.ph-file-image-fill::before {
  content: "ﰍ";
}

.ph-file-jpg-fill::before {
  content: "ﰎ";
}

.ph-file-js-fill::before {
  content: "ﰏ";
}

.ph-file-jsx-fill::before {
  content: "ﰐ";
}

.ph-file-lock-fill::before {
  content: "ﰑ";
}

.ph-file-minus-fill::before {
  content: "ﰒ";
}

.ph-file-pdf-fill::before {
  content: "ﰓ";
}

.ph-file-plus-fill::before {
  content: "ﰔ";
}

.ph-file-png-fill::before {
  content: "ﰕ";
}

.ph-file-ppt-fill::before {
  content: "ﰖ";
}

.ph-file-rs-fill::before {
  content: "ﰗ";
}

.ph-file-search-fill::before {
  content: "ﰘ";
}

.ph-file-text-fill::before {
  content: "ﰙ";
}

.ph-file-ts-fill::before {
  content: "ﰚ";
}

.ph-file-tsx-fill::before {
  content: "ﰛ";
}

.ph-file-video-fill::before {
  content: "ﰜ";
}

.ph-file-vue-fill::before {
  content: "ﰝ";
}

.ph-file-x-fill::before {
  content: "ﰞ";
}

.ph-file-xls-fill::before {
  content: "ﰟ";
}

.ph-file-zip-fill::before {
  content: "ﰠ";
}

.ph-files-fill::before {
  content: "ﰡ";
}

.ph-film-script-fill::before {
  content: "ﰢ";
}

.ph-film-slate-fill::before {
  content: "ﰣ";
}

.ph-film-strip-fill::before {
  content: "ﰤ";
}

.ph-fingerprint-fill::before {
  content: "ﰥ";
}

.ph-fingerprint-simple-fill::before {
  content: "ﰦ";
}

.ph-finn-the-human-fill::before {
  content: "ﰧ";
}

.ph-fire-fill::before {
  content: "ﰨ";
}

.ph-fire-simple-fill::before {
  content: "ﰩ";
}

.ph-first-aid-fill::before {
  content: "ﰪ";
}

.ph-first-aid-kit-fill::before {
  content: "ﰫ";
}

.ph-fish-fill::before {
  content: "ﰬ";
}

.ph-fish-simple-fill::before {
  content: "ﰭ";
}

.ph-flag-banner-fill::before {
  content: "ﰮ";
}

.ph-flag-checkered-fill::before {
  content: "ﰯ";
}

.ph-flag-fill::before {
  content: "ﰰ";
}

.ph-flame-fill::before {
  content: "ﰱ";
}

.ph-flashlight-fill::before {
  content: "ﰲ";
}

.ph-flask-fill::before {
  content: "ﰳ";
}

.ph-floppy-disk-back-fill::before {
  content: "ﰴ";
}

.ph-floppy-disk-fill::before {
  content: "ﰵ";
}

.ph-flow-arrow-fill::before {
  content: "ﰶ";
}

.ph-flower-fill::before {
  content: "ﰷ";
}

.ph-flower-lotus-fill::before {
  content: "ﰸ";
}

.ph-flying-saucer-fill::before {
  content: "ﰹ";
}

.ph-folder-dotted-fill::before {
  content: "ﰺ";
}

.ph-folder-fill::before {
  content: "ﰻ";
}

.ph-folder-lock-fill::before {
  content: "ﰼ";
}

.ph-folder-minus-fill::before {
  content: "ﰽ";
}

.ph-folder-notch-fill::before {
  content: "ﰾ";
}

.ph-folder-notch-minus-fill::before {
  content: "ﰿ";
}

.ph-folder-notch-open-fill::before {
  content: "ﱀ";
}

.ph-folder-notch-plus-fill::before {
  content: "ﱁ";
}

.ph-folder-open-fill::before {
  content: "ﱂ";
}

.ph-folder-plus-fill::before {
  content: "ﱃ";
}

.ph-folder-simple-dotted-fill::before {
  content: "ﱄ";
}

.ph-folder-simple-fill::before {
  content: "ﱅ";
}

.ph-folder-simple-lock-fill::before {
  content: "ﱆ";
}

.ph-folder-simple-minus-fill::before {
  content: "ﱇ";
}

.ph-folder-simple-plus-fill::before {
  content: "ﱈ";
}

.ph-folder-simple-star-fill::before {
  content: "ﱉ";
}

.ph-folder-simple-user-fill::before {
  content: "ﱊ";
}

.ph-folder-star-fill::before {
  content: "ﱋ";
}

.ph-folder-user-fill::before {
  content: "ﱌ";
}

.ph-folders-fill::before {
  content: "ﱍ";
}

.ph-football-fill::before {
  content: "ﱎ";
}

.ph-fork-knife-fill::before {
  content: "ﱏ";
}

.ph-frame-corners-fill::before {
  content: "ﱐ";
}

.ph-framer-logo-fill::before {
  content: "ﱑ";
}

.ph-function-fill::before {
  content: "ﱒ";
}

.ph-funnel-fill::before {
  content: "ﱓ";
}

.ph-funnel-simple-fill::before {
  content: "ﱔ";
}

.ph-game-controller-fill::before {
  content: "ﱕ";
}

.ph-gas-pump-fill::before {
  content: "ﱖ";
}

.ph-gauge-fill::before {
  content: "ﱗ";
}

.ph-gear-fill::before {
  content: "ﱘ";
}

.ph-gear-six-fill::before {
  content: "ﱙ";
}

.ph-gender-female-fill::before {
  content: "ﱚ";
}

.ph-gender-intersex-fill::before {
  content: "ﱛ";
}

.ph-gender-male-fill::before {
  content: "ﱜ";
}

.ph-gender-neuter-fill::before {
  content: "ﱝ";
}

.ph-gender-nonbinary-fill::before {
  content: "ﱞ";
}

.ph-gender-transgender-fill::before {
  content: "ﱟ";
}

.ph-ghost-fill::before {
  content: "ﱠ";
}

.ph-gif-fill::before {
  content: "ﱡ";
}

.ph-gift-fill::before {
  content: "ﱢ";
}

.ph-git-branch-fill::before {
  content: "ﱣ";
}

.ph-git-commit-fill::before {
  content: "ﱤ";
}

.ph-git-diff-fill::before {
  content: "ﱥ";
}

.ph-git-fork-fill::before {
  content: "ﱦ";
}

.ph-git-merge-fill::before {
  content: "ﱧ";
}

.ph-git-pull-request-fill::before {
  content: "ﱨ";
}

.ph-github-logo-fill::before {
  content: "ﱩ";
}

.ph-gitlab-logo-fill::before {
  content: "ﱪ";
}

.ph-gitlab-logo-simple-fill::before {
  content: "ﱫ";
}

.ph-globe-fill::before {
  content: "ﱬ";
}

.ph-globe-hemisphere-east-fill::before {
  content: "ﱭ";
}

.ph-globe-hemisphere-west-fill::before {
  content: "ﱮ";
}

.ph-globe-simple-fill::before {
  content: "ﱯ";
}

.ph-globe-stand-fill::before {
  content: "ﱰ";
}

.ph-google-chrome-logo-fill::before {
  content: "ﱱ";
}

.ph-google-logo-fill::before {
  content: "ﱲ";
}

.ph-google-photos-logo-fill::before {
  content: "ﱳ";
}

.ph-google-play-logo-fill::before {
  content: "ﱴ";
}

.ph-google-podcasts-logo-fill::before {
  content: "ﱵ";
}

.ph-gradient-fill::before {
  content: "ﱶ";
}

.ph-graduation-cap-fill::before {
  content: "ﱷ";
}

.ph-graph-fill::before {
  content: "ﱸ";
}

.ph-grid-four-fill::before {
  content: "ﱹ";
}

.ph-hamburger-fill::before {
  content: "ﱺ";
}

.ph-hand-eye-fill::before {
  content: "ﱻ";
}

.ph-hand-fill::before {
  content: "ﱼ";
}

.ph-hand-fist-fill::before {
  content: "ﱽ";
}

.ph-hand-grabbing-fill::before {
  content: "ﱾ";
}

.ph-hand-palm-fill::before {
  content: "ﱿ";
}

.ph-hand-pointing-fill::before {
  content: "ﲀ";
}

.ph-hand-soap-fill::before {
  content: "ﲁ";
}

.ph-hand-waving-fill::before {
  content: "ﲂ";
}

.ph-handbag-fill::before {
  content: "ﲃ";
}

.ph-handbag-simple-fill::before {
  content: "ﲄ";
}

.ph-hands-clapping-fill::before {
  content: "ﲅ";
}

.ph-handshake-fill::before {
  content: "ﲆ";
}

.ph-hard-drive-fill::before {
  content: "ﲇ";
}

.ph-hard-drives-fill::before {
  content: "ﲈ";
}

.ph-hash-fill::before {
  content: "ﲉ";
}

.ph-hash-straight-fill::before {
  content: "ﲊ";
}

.ph-headlights-fill::before {
  content: "ﲋ";
}

.ph-headphones-fill::before {
  content: "ﲌ";
}

.ph-headset-fill::before {
  content: "ﲍ";
}

.ph-heart-break-fill::before {
  content: "ﲎ";
}

.ph-heart-fill::before {
  content: "ﲏ";
}

.ph-heart-straight-break-fill::before {
  content: "ﲐ";
}

.ph-heart-straight-fill::before {
  content: "ﲑ";
}

.ph-heartbeat-fill::before {
  content: "ﲒ";
}

.ph-hexagon-fill::before {
  content: "ﲓ";
}

.ph-highlighter-circle-fill::before {
  content: "ﲔ";
}

.ph-horse-fill::before {
  content: "ﲕ";
}

.ph-hourglass-fill::before {
  content: "ﲖ";
}

.ph-hourglass-high-fill::before {
  content: "ﲗ";
}

.ph-hourglass-low-fill::before {
  content: "ﲘ";
}

.ph-hourglass-medium-fill::before {
  content: "ﲙ";
}

.ph-hourglass-simple-fill::before {
  content: "ﲚ";
}

.ph-hourglass-simple-high-fill::before {
  content: "ﲛ";
}

.ph-hourglass-simple-low-fill::before {
  content: "ﲜ";
}

.ph-hourglass-simple-medium-fill::before {
  content: "ﲝ";
}

.ph-house-fill::before {
  content: "ﲞ";
}

.ph-house-line-fill::before {
  content: "ﲟ";
}

.ph-house-simple-fill::before {
  content: "ﲠ";
}

.ph-identification-badge-fill::before {
  content: "ﲡ";
}

.ph-identification-card-fill::before {
  content: "ﲢ";
}

.ph-image-fill::before {
  content: "ﲣ";
}

.ph-image-square-fill::before {
  content: "ﲤ";
}

.ph-infinity-fill::before {
  content: "ﲥ";
}

.ph-info-fill::before {
  content: "ﲦ";
}

.ph-instagram-logo-fill::before {
  content: "ﲧ";
}

.ph-intersect-fill::before {
  content: "ﲨ";
}

.ph-jeep-fill::before {
  content: "ﲩ";
}

.ph-kanban-fill::before {
  content: "ﲪ";
}

.ph-key-fill::before {
  content: "ﲫ";
}

.ph-key-return-fill::before {
  content: "ﲬ";
}

.ph-keyboard-fill::before {
  content: "ﲭ";
}

.ph-keyhole-fill::before {
  content: "ﲮ";
}

.ph-knife-fill::before {
  content: "ﲯ";
}

.ph-ladder-fill::before {
  content: "ﲰ";
}

.ph-ladder-simple-fill::before {
  content: "ﲱ";
}

.ph-lamp-fill::before {
  content: "ﲲ";
}

.ph-laptop-fill::before {
  content: "ﲳ";
}

.ph-layout-fill::before {
  content: "ﲴ";
}

.ph-leaf-fill::before {
  content: "ﲵ";
}

.ph-lifebuoy-fill::before {
  content: "ﲶ";
}

.ph-lightbulb-filament-fill::before {
  content: "ﲷ";
}

.ph-lightbulb-fill::before {
  content: "ﲸ";
}

.ph-lightning-fill::before {
  content: "ﲹ";
}

.ph-lightning-slash-fill::before {
  content: "ﲺ";
}

.ph-line-segment-fill::before {
  content: "ﲻ";
}

.ph-line-segments-fill::before {
  content: "ﲼ";
}

.ph-link-break-fill::before {
  content: "ﲽ";
}

.ph-link-fill::before {
  content: "ﲾ";
}

.ph-link-simple-break-fill::before {
  content: "ﲿ";
}

.ph-link-simple-fill::before {
  content: "ﳀ";
}

.ph-link-simple-horizontal-break-fill::before {
  content: "ﳁ";
}

.ph-link-simple-horizontal-fill::before {
  content: "ﳂ";
}

.ph-linkedin-logo-fill::before {
  content: "ﳃ";
}

.ph-linux-logo-fill::before {
  content: "ﳄ";
}

.ph-list-bullets-fill::before {
  content: "ﳅ";
}

.ph-list-checks-fill::before {
  content: "ﳆ";
}

.ph-list-dashes-fill::before {
  content: "ﳇ";
}

.ph-list-fill::before {
  content: "ﳈ";
}

.ph-list-numbers-fill::before {
  content: "ﳉ";
}

.ph-list-plus-fill::before {
  content: "ﳊ";
}

.ph-lock-fill::before {
  content: "ﳋ";
}

.ph-lock-key-fill::before {
  content: "ﳌ";
}

.ph-lock-key-open-fill::before {
  content: "ﳍ";
}

.ph-lock-laminated-fill::before {
  content: "ﳎ";
}

.ph-lock-laminated-open-fill::before {
  content: "ﳏ";
}

.ph-lock-open-fill::before {
  content: "ﳐ";
}

.ph-lock-simple-fill::before {
  content: "ﳑ";
}

.ph-lock-simple-open-fill::before {
  content: "ﳒ";
}

.ph-magic-wand-fill::before {
  content: "ﳓ";
}

.ph-magnet-fill::before {
  content: "ﳔ";
}

.ph-magnet-straight-fill::before {
  content: "ﳕ";
}

.ph-magnifying-glass-fill::before {
  content: "ﳖ";
}

.ph-magnifying-glass-minus-fill::before {
  content: "ﳗ";
}

.ph-magnifying-glass-plus-fill::before {
  content: "ﳘ";
}

.ph-map-pin-fill::before {
  content: "ﳙ";
}

.ph-map-pin-line-fill::before {
  content: "ﳚ";
}

.ph-map-trifold-fill::before {
  content: "ﳛ";
}

.ph-marker-circle-fill::before {
  content: "ﳜ";
}

.ph-martini-fill::before {
  content: "ﳝ";
}

.ph-mask-happy-fill::before {
  content: "ﳞ";
}

.ph-mask-sad-fill::before {
  content: "ﳟ";
}

.ph-math-operations-fill::before {
  content: "ﳠ";
}

.ph-medal-fill::before {
  content: "ﳡ";
}

.ph-medium-logo-fill::before {
  content: "ﳢ";
}

.ph-megaphone-fill::before {
  content: "ﳣ";
}

.ph-megaphone-simple-fill::before {
  content: "ﳤ";
}

.ph-messenger-logo-fill::before {
  content: "ﳥ";
}

.ph-microphone-fill::before {
  content: "ﳦ";
}

.ph-microphone-slash-fill::before {
  content: "ﳧ";
}

.ph-microphone-stage-fill::before {
  content: "ﳨ";
}

.ph-microsoft-excel-logo-fill::before {
  content: "ﳩ";
}

.ph-microsoft-powerpoint-logo-fill::before {
  content: "ﳪ";
}

.ph-microsoft-teams-logo-fill::before {
  content: "ﳫ";
}

.ph-microsoft-word-logo-fill::before {
  content: "ﳬ";
}

.ph-minus-circle-fill::before {
  content: "ﳭ";
}

.ph-minus-fill::before {
  content: "ﳮ";
}

.ph-money-fill::before {
  content: "ﳯ";
}

.ph-monitor-fill::before {
  content: "ﳰ";
}

.ph-monitor-play-fill::before {
  content: "ﳱ";
}

.ph-moon-fill::before {
  content: "ﳲ";
}

.ph-moon-stars-fill::before {
  content: "ﳳ";
}

.ph-mountains-fill::before {
  content: "ﳴ";
}

.ph-mouse-fill::before {
  content: "ﳵ";
}

.ph-mouse-simple-fill::before {
  content: "ﳶ";
}

.ph-music-note-fill::before {
  content: "ﳷ";
}

.ph-music-note-simple-fill::before {
  content: "ﳸ";
}

.ph-music-notes-fill::before {
  content: "ﳹ";
}

.ph-music-notes-plus-fill::before {
  content: "ﳺ";
}

.ph-music-notes-simple-fill::before {
  content: "ﳻ";
}

.ph-navigation-arrow-fill::before {
  content: "ﳼ";
}

.ph-needle-fill::before {
  content: "ﳽ";
}

.ph-newspaper-clipping-fill::before {
  content: "ﳾ";
}

.ph-newspaper-fill::before {
  content: "ﳿ";
}

.ph-note-blank-fill::before {
  content: "ﴀ";
}

.ph-note-fill::before {
  content: "ﴁ";
}

.ph-note-pencil-fill::before {
  content: "ﴂ";
}

.ph-notebook-fill::before {
  content: "ﴃ";
}

.ph-notepad-fill::before {
  content: "ﴄ";
}

.ph-notification-fill::before {
  content: "ﴅ";
}

.ph-number-circle-eight-fill::before {
  content: "ﴆ";
}

.ph-number-circle-five-fill::before {
  content: "ﴇ";
}

.ph-number-circle-four-fill::before {
  content: "ﴈ";
}

.ph-number-circle-nine-fill::before {
  content: "ﴉ";
}

.ph-number-circle-one-fill::before {
  content: "ﴊ";
}

.ph-number-circle-seven-fill::before {
  content: "ﴋ";
}

.ph-number-circle-six-fill::before {
  content: "ﴌ";
}

.ph-number-circle-three-fill::before {
  content: "ﴍ";
}

.ph-number-circle-two-fill::before {
  content: "ﴎ";
}

.ph-number-circle-zero-fill::before {
  content: "ﴏ";
}

.ph-number-eight-fill::before {
  content: "ﴐ";
}

.ph-number-five-fill::before {
  content: "ﴑ";
}

.ph-number-four-fill::before {
  content: "ﴒ";
}

.ph-number-nine-fill::before {
  content: "ﴓ";
}

.ph-number-one-fill::before {
  content: "ﴔ";
}

.ph-number-seven-fill::before {
  content: "ﴕ";
}

.ph-number-six-fill::before {
  content: "ﴖ";
}

.ph-number-square-eight-fill::before {
  content: "ﴗ";
}

.ph-number-square-five-fill::before {
  content: "ﴘ";
}

.ph-number-square-four-fill::before {
  content: "ﴙ";
}

.ph-number-square-nine-fill::before {
  content: "ﴚ";
}

.ph-number-square-one-fill::before {
  content: "ﴛ";
}

.ph-number-square-seven-fill::before {
  content: "ﴜ";
}

.ph-number-square-six-fill::before {
  content: "ﴝ";
}

.ph-number-square-three-fill::before {
  content: "ﴞ";
}

.ph-number-square-two-fill::before {
  content: "ﴟ";
}

.ph-number-square-zero-fill::before {
  content: "ﴠ";
}

.ph-number-three-fill::before {
  content: "ﴡ";
}

.ph-number-two-fill::before {
  content: "ﴢ";
}

.ph-number-zero-fill::before {
  content: "ﴣ";
}

.ph-nut-fill::before {
  content: "ﴤ";
}

.ph-ny-times-logo-fill::before {
  content: "ﴥ";
}

.ph-octagon-fill::before {
  content: "ﴦ";
}

.ph-option-fill::before {
  content: "ﴧ";
}

.ph-package-fill::before {
  content: "ﴨ";
}

.ph-paint-brush-broad-fill::before {
  content: "ﴩ";
}

.ph-paint-brush-fill::before {
  content: "ﴪ";
}

.ph-paint-brush-household-fill::before {
  content: "ﴫ";
}

.ph-paint-bucket-fill::before {
  content: "ﴬ";
}

.ph-paint-roller-fill::before {
  content: "ﴭ";
}

.ph-palette-fill::before {
  content: "ﴮ";
}

.ph-paper-plane-fill::before {
  content: "ﴯ";
}

.ph-paper-plane-right-fill::before {
  content: "ﴰ";
}

.ph-paper-plane-tilt-fill::before {
  content: "ﴱ";
}

.ph-paperclip-fill::before {
  content: "ﴲ";
}

.ph-paperclip-horizontal-fill::before {
  content: "ﴳ";
}

.ph-parachute-fill::before {
  content: "ﴴ";
}

.ph-password-fill::before {
  content: "ﴵ";
}

.ph-path-fill::before {
  content: "ﴶ";
}

.ph-pause-circle-fill::before {
  content: "ﴷ";
}

.ph-pause-fill::before {
  content: "ﴸ";
}

.ph-paw-print-fill::before {
  content: "ﴹ";
}

.ph-peace-fill::before {
  content: "ﴺ";
}

.ph-pen-fill::before {
  content: "ﴻ";
}

.ph-pen-nib-fill::before {
  content: "ﴼ";
}

.ph-pen-nib-straight-fill::before {
  content: "ﴽ";
}

.ph-pencil-circle-fill::before {
  content: "﴾";
}

.ph-pencil-fill::before {
  content: "﴿";
}

.ph-pencil-line-fill::before {
  content: "﵀";
}

.ph-pencil-simple-fill::before {
  content: "﵁";
}

.ph-pencil-simple-line-fill::before {
  content: "﵂";
}

.ph-percent-fill::before {
  content: "﵃";
}

.ph-person-fill::before {
  content: "﵄";
}

.ph-person-simple-fill::before {
  content: "﵅";
}

.ph-person-simple-run-fill::before {
  content: "﵆";
}

.ph-person-simple-walk-fill::before {
  content: "﵇";
}

.ph-perspective-fill::before {
  content: "﵈";
}

.ph-phone-call-fill::before {
  content: "﵉";
}

.ph-phone-disconnect-fill::before {
  content: "﵊";
}

.ph-phone-fill::before {
  content: "﵋";
}

.ph-phone-incoming-fill::before {
  content: "﵌";
}

.ph-phone-outgoing-fill::before {
  content: "﵍";
}

.ph-phone-slash-fill::before {
  content: "﵎";
}

.ph-phone-x-fill::before {
  content: "﵏";
}

.ph-phosphor-logo-fill::before {
  content: "ﵐ";
}

.ph-piano-keys-fill::before {
  content: "ﵑ";
}

.ph-picture-in-picture-fill::before {
  content: "ﵒ";
}

.ph-pill-fill::before {
  content: "ﵓ";
}

.ph-pinterest-logo-fill::before {
  content: "ﵔ";
}

.ph-pinwheel-fill::before {
  content: "ﵕ";
}

.ph-pizza-fill::before {
  content: "ﵖ";
}

.ph-placeholder-fill::before {
  content: "ﵗ";
}

.ph-planet-fill::before {
  content: "ﵘ";
}

.ph-play-circle-fill::before {
  content: "ﵙ";
}

.ph-play-fill::before {
  content: "ﵚ";
}

.ph-playlist-fill::before {
  content: "ﵛ";
}

.ph-plug-fill::before {
  content: "ﵜ";
}

.ph-plugs-connected-fill::before {
  content: "ﵝ";
}

.ph-plugs-fill::before {
  content: "ﵞ";
}

.ph-plus-circle-fill::before {
  content: "ﵟ";
}

.ph-plus-fill::before {
  content: "ﵠ";
}

.ph-plus-minus-fill::before {
  content: "ﵡ";
}

.ph-poker-chip-fill::before {
  content: "ﵢ";
}

.ph-police-car-fill::before {
  content: "ﵣ";
}

.ph-polygon-fill::before {
  content: "ﵤ";
}

.ph-popcorn-fill::before {
  content: "ﵥ";
}

.ph-power-fill::before {
  content: "ﵦ";
}

.ph-prescription-fill::before {
  content: "ﵧ";
}

.ph-presentation-chart-fill::before {
  content: "ﵨ";
}

.ph-presentation-fill::before {
  content: "ﵩ";
}

.ph-printer-fill::before {
  content: "ﵪ";
}

.ph-prohibit-fill::before {
  content: "ﵫ";
}

.ph-prohibit-inset-fill::before {
  content: "ﵬ";
}

.ph-projector-screen-chart-fill::before {
  content: "ﵭ";
}

.ph-projector-screen-fill::before {
  content: "ﵮ";
}

.ph-push-pin-fill::before {
  content: "ﵯ";
}

.ph-push-pin-simple-fill::before {
  content: "ﵰ";
}

.ph-push-pin-simple-slash-fill::before {
  content: "ﵱ";
}

.ph-push-pin-slash-fill::before {
  content: "ﵲ";
}

.ph-puzzle-piece-fill::before {
  content: "ﵳ";
}

.ph-qr-code-fill::before {
  content: "ﵴ";
}

.ph-question-fill::before {
  content: "ﵵ";
}

.ph-queue-fill::before {
  content: "ﵶ";
}

.ph-quotes-fill::before {
  content: "ﵷ";
}

.ph-radical-fill::before {
  content: "ﵸ";
}

.ph-radio-button-fill::before {
  content: "ﵹ";
}

.ph-radio-fill::before {
  content: "ﵺ";
}

.ph-rainbow-cloud-fill::before {
  content: "ﵻ";
}

.ph-rainbow-fill::before {
  content: "ﵼ";
}

.ph-receipt-fill::before {
  content: "ﵽ";
}

.ph-record-fill::before {
  content: "ﵾ";
}

.ph-rectangle-fill::before {
  content: "ﵿ";
}

.ph-recycle-fill::before {
  content: "ﶀ";
}

.ph-reddit-logo-fill::before {
  content: "ﶁ";
}

.ph-repeat-fill::before {
  content: "ﶂ";
}

.ph-repeat-once-fill::before {
  content: "ﶃ";
}

.ph-rewind-circle-fill::before {
  content: "ﶄ";
}

.ph-rewind-fill::before {
  content: "ﶅ";
}

.ph-robot-fill::before {
  content: "ﶆ";
}

.ph-rocket-fill::before {
  content: "ﶇ";
}

.ph-rocket-launch-fill::before {
  content: "ﶈ";
}

.ph-rows-fill::before {
  content: "ﶉ";
}

.ph-rss-fill::before {
  content: "ﶊ";
}

.ph-rss-simple-fill::before {
  content: "ﶋ";
}

.ph-rug-fill::before {
  content: "ﶌ";
}

.ph-ruler-fill::before {
  content: "ﶍ";
}

.ph-scales-fill::before {
  content: "ﶎ";
}

.ph-scan-fill::before {
  content: "ﶏ";
}

.ph-scissors-fill::before {
  content: "﶐";
}

.ph-screencast-fill::before {
  content: "﶑";
}

.ph-scribble-loop-fill::before {
  content: "ﶒ";
}

.ph-scroll-fill::before {
  content: "ﶓ";
}

.ph-selection-all-fill::before {
  content: "ﶔ";
}

.ph-selection-background-fill::before {
  content: "ﶕ";
}

.ph-selection-fill::before {
  content: "ﶖ";
}

.ph-selection-foreground-fill::before {
  content: "ﶗ";
}

.ph-selection-inverse-fill::before {
  content: "ﶘ";
}

.ph-selection-plus-fill::before {
  content: "ﶙ";
}

.ph-selection-slash-fill::before {
  content: "ﶚ";
}

.ph-share-fill::before {
  content: "ﶛ";
}

.ph-share-network-fill::before {
  content: "ﶜ";
}

.ph-shield-check-fill::before {
  content: "ﶝ";
}

.ph-shield-checkered-fill::before {
  content: "ﶞ";
}

.ph-shield-chevron-fill::before {
  content: "ﶟ";
}

.ph-shield-fill::before {
  content: "ﶠ";
}

.ph-shield-plus-fill::before {
  content: "ﶡ";
}

.ph-shield-slash-fill::before {
  content: "ﶢ";
}

.ph-shield-star-fill::before {
  content: "ﶣ";
}

.ph-shield-warning-fill::before {
  content: "ﶤ";
}

.ph-shopping-bag-fill::before {
  content: "ﶥ";
}

.ph-shopping-bag-open-fill::before {
  content: "ﶦ";
}

.ph-shopping-cart-fill::before {
  content: "ﶧ";
}

.ph-shopping-cart-simple-fill::before {
  content: "ﶨ";
}

.ph-shower-fill::before {
  content: "ﶩ";
}

.ph-shuffle-angular-fill::before {
  content: "ﶪ";
}

.ph-shuffle-fill::before {
  content: "ﶫ";
}

.ph-shuffle-simple-fill::before {
  content: "ﶬ";
}

.ph-sidebar-fill::before {
  content: "ﶭ";
}

.ph-sidebar-simple-fill::before {
  content: "ﶮ";
}

.ph-sign-in-fill::before {
  content: "ﶯ";
}

.ph-sign-out-fill::before {
  content: "ﶰ";
}

.ph-signpost-fill::before {
  content: "ﶱ";
}

.ph-sim-card-fill::before {
  content: "ﶲ";
}

.ph-sketch-logo-fill::before {
  content: "ﶳ";
}

.ph-skip-back-circle-fill::before {
  content: "ﶴ";
}

.ph-skip-back-fill::before {
  content: "ﶵ";
}

.ph-skip-forward-circle-fill::before {
  content: "ﶶ";
}

.ph-skip-forward-fill::before {
  content: "ﶷ";
}

.ph-skull-fill::before {
  content: "ﶸ";
}

.ph-slack-logo-fill::before {
  content: "ﶹ";
}

.ph-sliders-fill::before {
  content: "ﶺ";
}

.ph-sliders-horizontal-fill::before {
  content: "ﶻ";
}

.ph-smiley-blank-fill::before {
  content: "ﶼ";
}

.ph-smiley-fill::before {
  content: "ﶽ";
}

.ph-smiley-meh-fill::before {
  content: "ﶾ";
}

.ph-smiley-nervous-fill::before {
  content: "ﶿ";
}

.ph-smiley-sad-fill::before {
  content: "ﷀ";
}

.ph-smiley-sticker-fill::before {
  content: "ﷁ";
}

.ph-smiley-wink-fill::before {
  content: "ﷂ";
}

.ph-smiley-x-eyes-fill::before {
  content: "ﷃ";
}

.ph-snapchat-logo-fill::before {
  content: "ﷄ";
}

.ph-snowflake-fill::before {
  content: "ﷅ";
}

.ph-soccer-ball-fill::before {
  content: "ﷆ";
}

.ph-sort-ascending-fill::before {
  content: "ﷇ";
}

.ph-sort-descending-fill::before {
  content: "﷈";
}

.ph-spade-fill::before {
  content: "﷉";
}

.ph-sparkle-fill::before {
  content: "﷊";
}

.ph-speaker-high-fill::before {
  content: "﷋";
}

.ph-speaker-low-fill::before {
  content: "﷌";
}

.ph-speaker-none-fill::before {
  content: "﷍";
}

.ph-speaker-simple-high-fill::before {
  content: "﷎";
}

.ph-speaker-simple-low-fill::before {
  content: "﷏";
}

.ph-speaker-simple-none-fill::before {
  content: "﷐";
}

.ph-speaker-simple-slash-fill::before {
  content: "﷑";
}

.ph-speaker-simple-x-fill::before {
  content: "﷒";
}

.ph-speaker-slash-fill::before {
  content: "﷓";
}

.ph-speaker-x-fill::before {
  content: "﷔";
}

.ph-spinner-fill::before {
  content: "﷕";
}

.ph-spinner-gap-fill::before {
  content: "﷖";
}

.ph-spiral-fill::before {
  content: "﷗";
}

.ph-spotify-logo-fill::before {
  content: "﷘";
}

.ph-square-fill::before {
  content: "﷙";
}

.ph-square-half-bottom-fill::before {
  content: "﷚";
}

.ph-square-half-fill::before {
  content: "﷛";
}

.ph-square-logo-fill::before {
  content: "﷜";
}

.ph-squares-four-fill::before {
  content: "﷝";
}

.ph-stack-fill::before {
  content: "﷞";
}

.ph-stack-overflow-logo-fill::before {
  content: "﷟";
}

.ph-stack-simple-fill::before {
  content: "﷠";
}

.ph-stamp-fill::before {
  content: "﷡";
}

.ph-star-fill::before {
  content: "﷢";
}

.ph-star-four-fill::before {
  content: "﷣";
}

.ph-star-half-fill::before {
  content: "﷤";
}

.ph-sticker-fill::before {
  content: "﷥";
}

.ph-stop-circle-fill::before {
  content: "﷦";
}

.ph-stop-fill::before {
  content: "﷧";
}

.ph-storefront-fill::before {
  content: "﷨";
}

.ph-strategy-fill::before {
  content: "﷩";
}

.ph-stripe-logo-fill::before {
  content: "﷪";
}

.ph-student-fill::before {
  content: "﷫";
}

.ph-suitcase-fill::before {
  content: "﷬";
}

.ph-suitcase-simple-fill::before {
  content: "﷭";
}

.ph-sun-dim-fill::before {
  content: "﷮";
}

.ph-sun-fill::before {
  content: "﷯";
}

.ph-sun-horizon-fill::before {
  content: "ﷰ";
}

.ph-sunglasses-fill::before {
  content: "ﷱ";
}

.ph-swap-fill::before {
  content: "ﷲ";
}

.ph-swatches-fill::before {
  content: "ﷳ";
}

.ph-sword-fill::before {
  content: "ﷴ";
}

.ph-syringe-fill::before {
  content: "ﷵ";
}

.ph-t-shirt-fill::before {
  content: "ﷶ";
}

.ph-table-fill::before {
  content: "ﷷ";
}

.ph-tabs-fill::before {
  content: "ﷸ";
}

.ph-tag-chevron-fill::before {
  content: "ﷹ";
}

.ph-tag-fill::before {
  content: "ﷺ";
}

.ph-tag-simple-fill::before {
  content: "ﷻ";
}

.ph-target-fill::before {
  content: "﷼";
}

.ph-taxi-fill::before {
  content: "﷽";
}

.ph-telegram-logo-fill::before {
  content: "﷾";
}

.ph-television-fill::before {
  content: "﷿";
}

.ph-television-simple-fill::before {
  content: "︀";
}

.ph-tennis-ball-fill::before {
  content: "︁";
}

.ph-terminal-fill::before {
  content: "︂";
}

.ph-terminal-window-fill::before {
  content: "︃";
}

.ph-test-tube-fill::before {
  content: "︄";
}

.ph-text-aa-fill::before {
  content: "︅";
}

.ph-text-align-center-fill::before {
  content: "︆";
}

.ph-text-align-justify-fill::before {
  content: "︇";
}

.ph-text-align-left-fill::before {
  content: "︈";
}

.ph-text-align-right-fill::before {
  content: "︉";
}

.ph-text-bolder-fill::before {
  content: "︊";
}

.ph-text-h-fill::before {
  content: "︋";
}

.ph-text-h-five-fill::before {
  content: "︌";
}

.ph-text-h-four-fill::before {
  content: "︍";
}

.ph-text-h-one-fill::before {
  content: "︎";
}

.ph-text-h-six-fill::before {
  content: "️";
}

.ph-text-h-three-fill::before {
  content: "︐";
}

.ph-text-h-two-fill::before {
  content: "︑";
}

.ph-text-indent-fill::before {
  content: "︒";
}

.ph-text-italic-fill::before {
  content: "︓";
}

.ph-text-outdent-fill::before {
  content: "︔";
}

.ph-text-strikethrough-fill::before {
  content: "︕";
}

.ph-text-t-fill::before {
  content: "︖";
}

.ph-text-underline-fill::before {
  content: "︗";
}

.ph-textbox-fill::before {
  content: "︘";
}

.ph-thermometer-cold-fill::before {
  content: "︙";
}

.ph-thermometer-fill::before {
  content: "︚";
}

.ph-thermometer-hot-fill::before {
  content: "︛";
}

.ph-thermometer-simple-fill::before {
  content: "︜";
}

.ph-thumbs-down-fill::before {
  content: "︝";
}

.ph-thumbs-up-fill::before {
  content: "︞";
}

.ph-ticket-fill::before {
  content: "︟";
}

.ph-tiktok-logo-fill::before {
  content: "︠";
}

.ph-timer-fill::before {
  content: "︡";
}

.ph-toggle-left-fill::before {
  content: "︢";
}

.ph-toggle-right-fill::before {
  content: "︣";
}

.ph-toilet-fill::before {
  content: "︤";
}

.ph-toilet-paper-fill::before {
  content: "︥";
}

.ph-tote-fill::before {
  content: "︦";
}

.ph-tote-simple-fill::before {
  content: "︧";
}

.ph-trademark-registered-fill::before {
  content: "︨";
}

.ph-traffic-cone-fill::before {
  content: "︩";
}

.ph-traffic-sign-fill::before {
  content: "︪";
}

.ph-traffic-signal-fill::before {
  content: "︫";
}

.ph-train-fill::before {
  content: "︬";
}

.ph-train-regional-fill::before {
  content: "︭";
}

.ph-train-simple-fill::before {
  content: "︮";
}

.ph-translate-fill::before {
  content: "︯";
}

.ph-trash-fill::before {
  content: "︰";
}

.ph-trash-simple-fill::before {
  content: "︱";
}

.ph-tray-fill::before {
  content: "︲";
}

.ph-tree-evergreen-fill::before {
  content: "︳";
}

.ph-tree-fill::before {
  content: "︴";
}

.ph-tree-structure-fill::before {
  content: "︵";
}

.ph-trend-down-fill::before {
  content: "︶";
}

.ph-trend-up-fill::before {
  content: "︷";
}

.ph-triangle-fill::before {
  content: "︸";
}

.ph-trophy-fill::before {
  content: "︹";
}

.ph-truck-fill::before {
  content: "︺";
}

.ph-twitch-logo-fill::before {
  content: "︻";
}

.ph-twitter-logo-fill::before {
  content: "︼";
}

.ph-umbrella-fill::before {
  content: "︽";
}

.ph-umbrella-simple-fill::before {
  content: "︾";
}

.ph-upload-fill::before {
  content: "︿";
}

.ph-upload-simple-fill::before {
  content: "﹀";
}

.ph-user-circle-fill::before {
  content: "﹁";
}

.ph-user-circle-gear-fill::before {
  content: "﹂";
}

.ph-user-circle-minus-fill::before {
  content: "﹃";
}

.ph-user-circle-plus-fill::before {
  content: "﹄";
}

.ph-user-fill::before {
  content: "﹅";
}

.ph-user-focus-fill::before {
  content: "﹆";
}

.ph-user-gear-fill::before {
  content: "﹇";
}

.ph-user-list-fill::before {
  content: "﹈";
}

.ph-user-minus-fill::before {
  content: "﹉";
}

.ph-user-plus-fill::before {
  content: "﹊";
}

.ph-user-rectangle-fill::before {
  content: "﹋";
}

.ph-user-square-fill::before {
  content: "﹌";
}

.ph-user-switch-fill::before {
  content: "﹍";
}

.ph-users-fill::before {
  content: "﹎";
}

.ph-users-four-fill::before {
  content: "﹏";
}

.ph-users-three-fill::before {
  content: "﹐";
}

.ph-vault-fill::before {
  content: "﹑";
}

.ph-vibrate-fill::before {
  content: "﹒";
}

.ph-video-camera-fill::before {
  content: "﹓";
}

.ph-video-camera-slash-fill::before {
  content: "﹔";
}

.ph-vignette-fill::before {
  content: "﹕";
}

.ph-voicemail-fill::before {
  content: "﹖";
}

.ph-volleyball-fill::before {
  content: "﹗";
}

.ph-wall-fill::before {
  content: "﹘";
}

.ph-wallet-fill::before {
  content: "﹙";
}

.ph-warning-circle-fill::before {
  content: "﹚";
}

.ph-warning-fill::before {
  content: "﹛";
}

.ph-warning-octagon-fill::before {
  content: "﹜";
}

.ph-watch-fill::before {
  content: "﹝";
}

.ph-wave-sawtooth-fill::before {
  content: "﹞";
}

.ph-wave-sine-fill::before {
  content: "﹟";
}

.ph-wave-square-fill::before {
  content: "﹠";
}

.ph-wave-triangle-fill::before {
  content: "﹡";
}

.ph-waves-fill::before {
  content: "﹢";
}

.ph-webcam-fill::before {
  content: "﹣";
}

.ph-whatsapp-logo-fill::before {
  content: "﹤";
}

.ph-wheelchair-fill::before {
  content: "﹥";
}

.ph-wifi-high-fill::before {
  content: "﹦";
}

.ph-wifi-low-fill::before {
  content: "﹧";
}

.ph-wifi-medium-fill::before {
  content: "﹨";
}

.ph-wifi-none-fill::before {
  content: "﹩";
}

.ph-wifi-slash-fill::before {
  content: "﹪";
}

.ph-wifi-x-fill::before {
  content: "﹫";
}

.ph-wind-fill::before {
  content: "﹬";
}

.ph-windows-logo-fill::before {
  content: "﹭";
}

.ph-wine-fill::before {
  content: "﹮";
}

.ph-wrench-fill::before {
  content: "﹯";
}

.ph-x-circle-fill::before {
  content: "ﹰ";
}

.ph-x-fill::before {
  content: "ﹱ";
}

.ph-x-square-fill::before {
  content: "ﹲ";
}

.ph-yin-yang-fill::before {
  content: "ﹳ";
}

.ph-youtube-logo-fill::before {
  content: "ﹴ";
}